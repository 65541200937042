<lv-flex-box class="lv-convertible-bond-terms-coupon">
    <lv-flex-box direction="row" data-cy="DM-5438">
      <lv-flex-box class="lv-coupon-first-col">
        <lv-flex-box direction="row" data-cy="DM-24">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponTypeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Coupon Type
            </span>
            <lv-xl-label [field]="'CPN_TYPE'"></lv-xl-label>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
              lvId="couponCouponType"
              name="terms-general-coupon-coupon-type"
              [data]="couponTypeLookup.items"
              valueField="id"
              textField="text"
              valuePrimitive="true"
              [popupSettings]="{ width: 120 }"
              [(ngModel)]="model.type"
              (ngModelChange)="couponTypeChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <ng-container *ngIf="!isZeroCouponSelected">
          <lv-flex-box direction="row" data-cy="DM-376">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="couponDayCountSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Coupon Day Count
              </span>
              <lv-xl-label [field]="'CPN_DAY_COUNT'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist
                lvId="couponDayCountCmb"
                name="terms-general-coupon-coupon-day-count"
                [data]="couponDayCountLookup.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [(ngModel)]="model.dayCount"
                (ngModelChange)="onCouponDaysOrFrequencyChanged()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-373">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="couponFrequencySourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Coupon Frequency
              </span>
              <lv-xl-label [field]="'CPN_FQ'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-dropdownlist name="terms-general-coupon-coupon-frequency"
                lvId="couponFrequencyCmb"
                [data]="couponFrequencyLookup.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [(ngModel)]="model.frequency"
                (ngModelChange)="onCouponDaysOrFrequencyChanged()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-973">
            <div class="lv-label lv-label-field lv-label-before-checkbox"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="accrualStartDateSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Accrual Start Date
              </span>
              <lv-xl-label [field]="'CPN_ACC_START_DATE'"></lv-xl-label>
            </div>
            <lv-datepicker
              name="accrualStartDate"
              [checkBoxLvId] = "dpAccStartDateCheckBoxId"
              [datePickerLvId] = "dpAccStartDatePickerId"
              [(ngModel)]="accrualStartDate"
              [checked]="accrualStartDateEnabled"
              (didCheckboxValueChange)="accrualStartDateCheckboxChanged($event)"
              (ngModelChange)="onModelChange()">
            </lv-datepicker>
          </lv-flex-box>
          <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-974">
            <div class="lv-label lv-label-field lv-label-before-checkbox"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="accrualEndDateSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Accrual End Date
              </span>
              <lv-xl-label [field]="'CPN_ACC_END_DATE'"></lv-xl-label>
            </div>
            <lv-datepicker
              name="accrualEndDate"
              [checkBoxLvId] = "dpAccEndDateCheckBoxId"
              [datePickerLvId] = "dpAccEndDatePickerId"
              [(ngModel)]="accrualEndDate"
              [checked]="accrualEndDateEnabled"
              (didCheckboxValueChange)="accrualEndDateCheckboxChanged($event)"
              (ngModelChange)="onModelChange()">
            </lv-datepicker>
          </lv-flex-box>
          <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-375">
            <div class="lv-label lv-label-field lv-label-before-checkbox"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="firstPaymentDateSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                First Payment Date
              </span>
              <lv-xl-label [field]="'CPN_FRST'"></lv-xl-label>
            </div>
            <lv-datepicker
              name="firstCouponPaymentDate"
              [checkBoxLvId] = "dpFirstPaymentDateCheckBoxId"
              [datePickerLvId] = "dpFirstPaymentDatePickerId"
              [(ngModel)]="firstCouponPaymentDate"
              [checked]="firstCouponPaymentDateEnabled"
              (didCheckboxValueChange)="firstCouponPaymentDateCheckboxChanged($event)"
              (ngModelChange)="onModelChange()">
            </lv-datepicker>
          </lv-flex-box>
          <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-975">
            <div class="lv-label lv-label-field lv-label-before-checkbox"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="penPaymentDateSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Pen. Payment Date
              </span>
              <lv-xl-label [field]="'CPN_PEN'"></lv-xl-label>
            </div>
            <lv-datepicker
              name="penultimateCouponPaymentDate"
              [checkBoxLvId] = "dpPenPaymentDateCheckBoxId"
              [datePickerLvId] = "dpPenPaymentDatePickerId"
              [(ngModel)]="penultimateCouponPaymentDate"
              [checked]="penultimateCouponPaymentDateEnabled"
              (didCheckboxValueChange)="penultimateCouponPaymentDateCheckboxChanged($event)"
              (ngModelChange)="onPenultimateCouponPaymentDateChaged()">
            </lv-datepicker>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-976">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="couponDatesEOMSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Coupon Dates EOM
              </span>
              <lv-xl-label [field]="'CPN_DATE_EOM'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input class="k-checkbox"
                type="checkbox"
                [attr.id]="couponDatesEomCheckboxId"
                name="terms-general-coupon-coupon-dates-eom"
                [(ngModel)]="model.couponDatesEOM"
                (ngModelChange)="onModelChange()">
              <label class="k-checkbox-label"
                [attr.for]="couponDatesEomCheckboxId">
              </label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" class="lv-coupon-date" *ngIf="typeChangeDateEnabled" data-cy="DM-988">
            <div class="lv-label lv-label-field lv-label-before-checkbox"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="couponChangedDateSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Coupon Change Date
              </span>
              <lv-xl-label [field]="'CPN_TYPE_CHNG_DATE'"></lv-xl-label>
            </div>
            <lv-datepicker
              name="couponChangedDate"
              [checkBoxLvId] = "dpCouponChangedDateCheckBoxId"
              [datePickerLvId] = "dpCouponChangedDatePickerId"
              [(ngModel)]="couponChangedDate"
              [checked]="couponChangedDateEnabled"
              (didCheckboxValueChange)="couponChangedDateCheckboxChanged($event)"
              (ngModelChange)="onModelChange()">
            </lv-datepicker>
          </lv-flex-box>
        </ng-container>
      </lv-flex-box>
      <lv-flex-box class="lv-coupon-second-col" *ngIf="!isZeroCouponSelected">
        <lv-flex-box direction="row">
          <div class="lv-label lv-label-field">&nbsp;</div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-378">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="accrualMethodSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Accrual Method
            </span>
            <lv-xl-label [field]="'CPN_ACC_METHOD'"></lv-xl-label>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
              lvId="couponAccrualMethodCmb"
              name="terms-general-coupon-accrual-method"
              [data]="accrualMethodLookup.items"
              valueField="id"
              textField="text"
              valuePrimitive="true"
              [popupSettings]="{ width: 235 }"
              [(ngModel)]="model.accrualMethod"
              (ngModelChange)="onModelChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-971">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="exCouponPeriodSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Ex Coupon Period
            </span>
            <lv-xl-label [field]="'CPN_EX_PERIOD'"></lv-xl-label>
          </div>
          <lv-numeric-text-box
            class="lv-input-field"
            lvId="exCouponPeriod"
            [format]="numberFormat"
            [decimals]="numberOfDecimals"
            name="terms-general-coupon-ex-coupon-per"
            [(ngModel)]="model.exCouponPeriod"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-972">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="exCouponPeriodDaysTypeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span *ngIf="!isOpenedFromExcel">
              Ex Coupon Period (days type)
            </span>
            <span *ngIf="isOpenedFromExcel">
              Ex Cpn. Period (days type)
            </span>
            <lv-xl-label [field]="'CPN_EX_DATE_TYPE'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
            <kendo-dropdownlist name="terms-general-coupon-ex-coupon-per-dd"
              lvId="exCouponPeriodDays"
              [data]="exCouponPerLookup.items"
              valueField="id"
              name="terms-general-coupon-ex-coupon-per-dd"
              textField="text"
              valuePrimitive="true"
              [(ngModel)]="model.exCouponPeriodType"
              (ngModelChange)="onModelChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-977">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="guaranteedCouponsSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Guaranteed Coupons
            </span>
            <lv-xl-label [field]="'CPN_GRNTED'"></lv-xl-label>
          </div>
          <div class="lv-field lv-field--checkbox">
            <input class="k-checkbox"
              type="checkbox"
              [attr.id]="guaranteedCouponsCheckboxId"
              name="terms-general-coupon-guaranteed-coupons"
              [(ngModel)]="model.isGuaranteed"
              (ngModelChange)="onModelChange()">
            <label class="k-checkbox-label"
              [attr.for]="guaranteedCouponsCheckboxId">
            </label>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-coupon-date" *ngIf="model.isGuaranteed" data-cy="DM-978">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="guaranteedStartDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Guaranteed Start Date
            </span>
            <lv-xl-label [field]="'CPN_GRNTED_FRST'"></lv-xl-label>
          </div>
          <lv-datepicker
            name="guaranteedStartDateEnabled"
            [datePickerLvId] = "dpGuaranteedStartDateId"
            [(ngModel)]="guaranteedStartDate"
            [checked]="guaranteedStartDateEnabled"
            (didCheckboxValueChange)="guaranteedStartCheckBoxChange($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-coupon-date" *ngIf="model.isGuaranteed" data-cy="DM-979">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="guaranteedEndDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Guaranteed End Date
            </span>
            <lv-xl-label [field]="'CPN_GRNTED_LAST'"></lv-xl-label>
          </div>
          <lv-datepicker
            name="guaranteedEndDate"
            [datePickerLvId] = "dpGuaranteedEndDateId"
            [(ngModel)]="guaranteedEndDate"
            [checked]="guaranteedEndDateEnabled"
            (didCheckboxValueChange)="guaranteedEndCheckBoxChange($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box *ngIf="!isZeroCouponSelected">
      <lv-flex-box class="lv-coupon-fieldset lv-bond-coupon-fieldset" *ngIf="isFixedTypeVisible" direction="row">
        <lv-fieldset-panel legend="FIXED COUPON">
          <lv-bond-fixed-coupon
            [model]="model.fixed"
            (didFixedCouponModelChange)="onModelChange()">
          </lv-bond-fixed-coupon>
        </lv-fieldset-panel>
      </lv-flex-box>
      <lv-flex-box class="lv-coupon-fieldset lv-bond-coupon-fieldset" *ngIf="isFloatingTypeVisible" direction="row">
        <lv-fieldset-panel legend="FLOATING COUPON">
            <lv-bond-floating-coupon #floatingComponent
            [model]="model.floating"
            [couponType]=model.type
            (didFloatingCouponChange)="onModelChange()">
          </lv-bond-floating-coupon>
        </lv-fieldset-panel>
      </lv-flex-box>
      <lv-flex-box class="lv-coupon-fieldset lv-bond-coupon-fieldset" *ngIf="isPikTypeVisible" direction="row">
        <lv-fieldset-panel legend="PIK COUPON">
          <lv-bond-pik-coupon #pikComponent
          [model]="model.pik"
          (didPikCouponChange)="onModelChange()">
        </lv-bond-pik-coupon>
        </lv-fieldset-panel>
      </lv-flex-box>
      <lv-flex-box showAfter="1500" class="lv-coupon-fieldset" 
      *ngIf="isCustomTypeVisible" 
      kendoTooltip 
      [tooltipTemplate]="customCouponScheduleTooltip"
      filter=".lv-coupon-fieldset" direction="row">
      <lv-fieldset-panel legend="CUSTOM COUPON SCHEDULE">
        <lv-bond-custom-coupon
          [model]="model.custom"
          (didCustomCouponChange)="onModelChange()">
        </lv-bond-custom-coupon>
      </lv-fieldset-panel>
    </lv-flex-box>
    </lv-flex-box>
</lv-flex-box>


<ng-template #couponTypeSourceTooltip>
  <lv-tooltip dm="dM-24"></lv-tooltip>
</ng-template>
<ng-template #couponDayCountSourceTooltip>
  <lv-tooltip dm="dM-376"></lv-tooltip>
</ng-template>
<ng-template #couponFrequencySourceTooltip>
  <lv-tooltip dm="dM-373"></lv-tooltip>
</ng-template>
<ng-template #accrualStartDateSourceTooltip>
  <lv-tooltip dm="dM-973"></lv-tooltip>
</ng-template>
<ng-template #accrualEndDateSourceTooltip>
  <lv-tooltip dm="dM-974"></lv-tooltip>
</ng-template>
<ng-template #firstPaymentDateSourceTooltip>
  <lv-tooltip dm="dM-375"></lv-tooltip>
</ng-template>
<ng-template #penPaymentDateSourceTooltip>
  <lv-tooltip dm="dM-975"></lv-tooltip>
</ng-template>
<ng-template #couponDatesEOMSourceTooltip>
  <lv-tooltip dm="dM-976"></lv-tooltip>
</ng-template>
<ng-template #couponChangedDateSourceTooltip>
  <lv-tooltip dm="dM-988"></lv-tooltip>
</ng-template>
<ng-template #accrualMethodSourceTooltip>
  <lv-tooltip dm="dM-378"></lv-tooltip>
</ng-template>
<ng-template #exCouponPeriodSourceTooltip>
  <lv-tooltip dm="dM-971"></lv-tooltip>
</ng-template>
<ng-template #exCouponPeriodDaysTypeSourceTooltip>
  <lv-tooltip dm="dM-972"></lv-tooltip>
</ng-template>
<ng-template #guaranteedCouponsSourceTooltip>
  <lv-tooltip dm="dM-977"></lv-tooltip>
</ng-template>
<ng-template #guaranteedStartDateSourceTooltip>
  <lv-tooltip dm="dM-978"></lv-tooltip>
</ng-template>
<ng-template #guaranteedEndDateSourceTooltip>
  <lv-tooltip dm="dM-979"></lv-tooltip>
</ng-template>
<ng-template #hasCoPaySourceTooltip>
  <lv-tooltip dm="dM-377"></lv-tooltip>
</ng-template>
<ng-template #customCouponScheduleTooltip>
  <lv-tooltip dm="dM-981"></lv-tooltip>
</ng-template>
