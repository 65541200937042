import { Inject, Injectable } from '@angular/core';
import { ICurrency } from '../../models/market-data/dividends/currency';
import { HttpClient } from '@angular/common/http';
import { LvAnalyticsError } from '../../models/errors';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Common data service.
 */
@Injectable()
export class CommonDataService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/analytics/commonData');
  }

  /**
   * Gets all currencies.
   * @returns List of ICurrency objects.
   */
  async getAllCurrencies(): Promise<ICurrency[]> {
    try {
      return await this.getAsync<ICurrency[]>(null, '/currencies');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
}
