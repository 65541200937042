/**
 * EquityIdentifierType enum.
 */
export enum EquityIdentifierType {
  ISIN = 'ISIN',
  CUSIP = 'CUSIP',
  FIGI = 'FIGI',
  LeversysLocalID = 'LeversysLocalID', 
  LeversysId = 'LeversysId',
}

/**
 * EquityIdentifierTypeDescription enum.
 */
export enum EquityIdentifierTypeDescription {
  ISIN = 'ISIN',
  CUSIP = 'CUSIP',
  FIGI = 'FIGI',
  LeversysLocalID = 'Leversys ID',
  LeversysId = 'Leversys Global ID',
}