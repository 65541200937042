import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CallTriggerPeriodTypeDescription, ConvertibleBondSubType } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { LvExcelService } from '@lv-excel/services';
import { Optional } from '@angular/core';
import { LvDateService } from '@lv-core-ui/services';
import { Call, CallMakeWholeData, CallTriggerTypeDescription, CallValueType, CallValueTypeDescription, CurrencyType, DayCountDescription,
  FrequencyDescription, InterpolationType, IssueAndRedemption, NoticePeriod, SoftCallData, CleanUpCall, Coupon, CouponType } from '@lv-instrument-common/index';

export class LvCallsView {

  get isYieldFieldVisible(): boolean {
    return this.call?.valueType === CallValueType.GrossYield
      || this.call?.valueType === CallValueType.NetYield;
  }

  get isPeps(): boolean {
    return this.issueAndRedemption?.subType === ConvertibleBondSubType.PEPS;
  }

  get isPepsType(): boolean {
    return this.call?.valueType === CallValueType.PepsMaximumRatio || this.call?.valueType === CallValueType.PepsMinimumRatio  ||
           this.call?.valueType === CallValueType.PepsVariableRatio || this.call?.valueType === CallValueType.PerOfPar ||
           this.call?.valueType === CallValueType.PepsThresholdDependentRatio;
  }

  call: Call;
  cleanUpCall: CleanUpCall;
  issueAndRedemption: IssueAndRedemption;

  callValueTypeLookup: LvLookupEnum;
  callYieldFrequencyLookup: LvLookupEnum;
  callYieldDayCountLookup: LvLookupEnum;
  callNoticeLookup: LvLookupEnum;
  interpolationTypeLookup: LvLookupEnum;
  triggerTypeLookup: LvLookupEnum;
  currencyOfTriggerLookup: LvLookupEnum;
  triggerPeriodTypeLookup: LvLookupEnum;

  keepAccruedRedemptionId: string;
  keepAccruedConversionId: string;
  forfeitCouponId: string;
  noticeGivenId: string;
  callableId: string;
  cleanUpCallId: string;
  callMakeWholeId: string;
  includeCacheInCallTrigger: string;

  numberOfDecimalsPercentage = '4';
  numberFormat = '#,###.####';
  numberOfZeroDecimals = '0';
  numberZeroFormat = 'n0';

  isZeroCouponType: boolean;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    private _lvDateService: LvDateService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.callValueTypeLookup = new LvLookupEnum(CallValueTypeDescription);
    this.callYieldFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.callYieldDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.callNoticeLookup = new LvLookupEnum(NoticePeriod);
    this.interpolationTypeLookup = new LvLookupEnum(InterpolationType);
    this.triggerTypeLookup = new LvLookupEnum(CallTriggerTypeDescription);
    this.currencyOfTriggerLookup = new LvLookupEnum(CurrencyType);
    this.triggerPeriodTypeLookup = new LvLookupEnum(CallTriggerPeriodTypeDescription);

    this.keepAccruedRedemptionId = v4();
    this.keepAccruedConversionId = v4();
    this.forfeitCouponId = v4();
    this.noticeGivenId = v4();
    this.callableId = v4();
    this.cleanUpCallId = v4();
    this.callMakeWholeId =  v4();
    this.includeCacheInCallTrigger = v4();

    this.isZeroCouponType = false;

    this.call = {
      softCall: {} as SoftCallData,
      callMakeWhole: {} as CallMakeWholeData,
      scheduleItems: []
    } as Call;
      
    this.init(this.call, {} as CleanUpCall, {} as IssueAndRedemption, {} as Coupon);
  }

  async init(
    call?: Call,
    cleanUpCall?: CleanUpCall,
    issueAndRedemption?: IssueAndRedemption,
    coupon?: Coupon
  ) {
    this.call = call ?? this._lvConvertibleBondTermsService.convertibleBondDocument.call;

    const callSettings = this._lvConvertibleBondTermsService?.callSettings;

    if (!!issueAndRedemption.subType && issueAndRedemption.subType !== this.issueAndRedemption?.subType) {
      if (issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
        if (!this.isPepsType) {
          this.call.valueType = callSettings?.valueTypePeps;
        }
      }
      else {
        if (this.isPepsType && this.call?.valueType !== CallValueType.PerOfPar) {
          this.call.valueType = callSettings?.valueTypeRegular;
        }
      }
    }

    if (!this.call.yieldFrequency) {
      this.call.yieldFrequency = callSettings?.yieldFrequency;
    }

    if (!this.call.yieldDayCount) {
      this.call.yieldDayCount = callSettings?.yieldDayCount;
    }

    this.cleanUpCall = cleanUpCall ?? this._lvConvertibleBondTermsService.convertibleBondDocument.cleanUpCall;

    this.issueAndRedemption = { ... issueAndRedemption};

    this.setDefaultValues();
    if (coupon && coupon.type) {
      this.changeOnCouponType(coupon);
    }

    this.callValueTypeLookup.setFilterFn(item => {
      if (!this.isPeps && (item.id === CallValueType.PepsMaximumRatio || item.id === CallValueType.PepsMinimumRatio
          || item.id === CallValueType.PepsThresholdDependentRatio || item.id === CallValueType.PepsVariableRatio)) {

        return false;
      }
      else if (this.isPeps && (item.id === CallValueType.NetYield || item.id === CallValueType.GrossYield
                            || item.id === CallValueType.AccretedValue)) {
        return false;
      }

      return true;
    });

    this.recalculateReadOnlyDates();
  }

  /**
   * Init make whole data.
   */
  initMakeWholeData(call?: Call) {
    this.call.recieveMakeWhole = call.recieveMakeWhole;
    this.call.callMakeWhole = call.callMakeWhole;
  }

  /**
   * Set variable isZeroCouponType on true if Coupon Type equal Zero Coupon
   */
  changeOnCouponType(coupon: Coupon) {
    this.isZeroCouponType = coupon.type === CouponType.ZeroCoupon;
  }

  setDefaultValues() {
    if (this.call) {
      this.setCallDate();
    }
  }

  cleanUpCallChanged() {
    if (this.issueAndRedemption.isCleanUpCall) {
      if (!this.cleanUpCall) {
        this.cleanUpCall = new CleanUpCall();
      }
      this.cleanUpCall.isCleanUpCall = this.issueAndRedemption.isCleanUpCall;
    } else {
      this.cleanUpCall = null;
    }
  }

  onCallChange() {
    if (this.issueAndRedemption.isCallable) {
      this.setDefaultValues();
    }
  }

  /**
   * Calculated based on following logic:
   * First Call Consideration Date:
   * First Call Date from Call Schedule
   * minus Call Notice days (max) (business or calendar depending on Call Notice Days (business/calendar) value)
   * minus Consideration days (max) (business or calendar depending on Consideration Days (bus/cal) value)
   *
   * Call Trigger Observation Start Date:
   * First Call Date from Call Schedule
   * minus Call Notice days (max) (business or calendar depending on Call Notice Days (business/calendar) value)
   * minus Consideration days (max) (business or calendar depending on Consideration Days (bus/cal) value)
   * minus Out of Days (use business days)
   */
  recalculateReadOnlyDates() {
    if (this.call) {
      // tslint:disable-next-line: max-line-length
      const firstCallScheduleDate = this.call.scheduleItems && this.call.scheduleItems.length > 0 ? this.call.scheduleItems[0].startDate : null;

      if (firstCallScheduleDate && this.call.callNoticeDaysMax > 0) {
        // tslint:disable-next-line: max-line-length
        const dateAdjustedNoticedDays = this.calculateReadOnlyDate(firstCallScheduleDate, this.call.callNoticeDaysMax, this.call.noticePeriod);
        // tslint:disable-next-line: max-line-length
        const dateAdjustedConsiderationDays = this.calculateReadOnlyDate(dateAdjustedNoticedDays, this.call.considerationDaysMax, this.call.considerationDaysBusinessCalendar);

        this.call.callFirstCallConsiderationDate = dateAdjustedConsiderationDays;

        const outOfDay = this.call.softCall && this.call.softCall.outOfDays ? this.call.softCall.outOfDays : 0;

        if (outOfDay > 0) {
        // tslint:disable-next-line: max-line-length
        this.call.callTriggerObservationStartDate = this.calculateReadOnlyDate(dateAdjustedConsiderationDays, outOfDay, NoticePeriod.Business);
        }
        else {
                  // tslint:disable-next-line: max-line-length
        this.call.callTriggerObservationStartDate = null;
        }

      }
      else {
        this.call.callFirstCallConsiderationDate = null;
        this.call.callTriggerObservationStartDate = null;
      }
    }
  }

  /**
   * Auxiliary function for calculating date depending on the NoticePeriod:
   * If NoticePeriod == Calendar function return date =  First Call Date from Call Schedule - Call Notice days (max)
   * If NoticePeriod == Business function return date from calculateDateForBusinessDate() function
   */
  calculateReadOnlyDate(firstCallScheduleDate: Date, callNoticeDaysMax: number, noticePeriod: NoticePeriod): Date {
    const result = this.getNoticePeriodDays(noticePeriod, firstCallScheduleDate, callNoticeDaysMax);
    return result;
  }

  private getNoticePeriodDays(noticePeriod: NoticePeriod, firstCallScheduleDate: Date, callNoticeDaysMax: number) {
    let mFirst: Date;
    let mSecond: Date;

    if (noticePeriod === NoticePeriod.Calendar) {
        mFirst = new Date(firstCallScheduleDate);
        mFirst.setDate(mFirst.getDate() - callNoticeDaysMax);
    } else {
        mSecond = this.calculateDateForBusinessDate(firstCallScheduleDate, -1 * callNoticeDaysMax);
        const tempDate = new Date(mSecond);
        tempDate.setDate(tempDate.getDate() + 1);
        mFirst = this.calculateDateForBusinessDate(tempDate, -1);
    }

    return mFirst;
  }

  /**
   * Auxiliary function does not take the weekend into account when calculating date. Always use NoticePeriod == Business
   * function return date = FirstCallConsideration Date - OutOfDays
   * numberToAdjust = Out Of Day from Call Trigger section
   */
  calculateDateForBusinessDate(dateForAdjust: Date, numberToAdjust: number) {
    const endDate = new Date(dateForAdjust);
    const addDays = numberToAdjust > 0 ? 1 : -1;
    numberToAdjust = Math.abs(numberToAdjust);

    while (numberToAdjust > 0) {
        endDate.setDate(endDate.getDate() + addDays);

        const dayOfWeek = endDate.getDay();
        if (!(dayOfWeek === 0 /* Sunday */ || dayOfWeek === 6 /* Saturday */)) {
            --numberToAdjust;
        }
    }

    return endDate;
  }

  /**
   * Method sets this.call.callDate for given instrument.
   * If this.call.callDate does not exist and this.call.callNoticeGiven flag is true, call date is set to today's date,
   * otherwise it is set to null.
   */
  setCallDate() {
    this.call.callDate  = this.call.callDate ? new Date(this.call.callDate) : this.call.callNoticeGiven ? new Date() : null;
  }
}
