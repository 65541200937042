import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LvAnalyticsError } from '../../models/errors';
import { v4 } from 'uuid';

// tslint:disable-next-line:max-line-length
import { ISaveInstrumentEvironmentSectionSettingsRequest } from '../../models/company-and-user-settings/save-instrument-environment-settings-request';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Environment settings service.
 */
@Injectable()
export class EnvironmentSettingsService extends HttpClientBase {

  get svcInstanceId(): string {
    return this._svcInstanceId;
  }

  private _svcInstanceId: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/analytics/instrumentEnvironmentSettings');
    this._svcInstanceId = v4();
  }

  /**
   * Saves instrument environment section settings.
   * @param request ISaveInstrumentEvironmentSectionSettingsRequest object.
   */
  async saveInstrumentEnvironmentSectionSettings(request: ISaveInstrumentEvironmentSectionSettingsRequest): Promise<void> {
    try {
      request.sourceId = this._svcInstanceId;
      await this.postAsync<void>(request, '/saveSectionSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }
}
