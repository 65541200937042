import { FloatingCustomDatesSchedule } from "@lv-instrument-common/FloatingCustomDatesSchedule";

export class LvFloatingCustomDatesScheduleView {

  public floatingCustomDates: FloatingCustomDatesSchedule[];
  public customDatesModel: Date[];

  getFloatingCustomDatesSchedule(customDatesSchedule: Date[]): FloatingCustomDatesSchedule[] {
    this.floatingCustomDates = [];
    customDatesSchedule.forEach(a => {
      this.floatingCustomDates.push({
        date: a
      });
    });

    return this.floatingCustomDates;
  }

  getFloatingCustomDatesModel(customDatesSchedule: FloatingCustomDatesSchedule[]): Date[] {
    this.customDatesModel = [];
    customDatesSchedule.forEach(a => {
      this.customDatesModel.push(
        a.date
      );
    });

    return this.customDatesModel;
  }
}
