import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { IEquityDividends } from '@lv-analytics/models';
// import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { DividendFrequencyEnum, DividendTypeEnum, DividendTypeEnumDescription, EquityDividendEnum } from '@lv-analytics/models/market-data';
import { LvMinDateDirective } from '@lv-core-ui/directives/date-formatting/lv-date-formatting.directive';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-equity-dividends-parameters',
  templateUrl: './lv-equity-dividends-parameters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityDividendsParametersComponent {
  @ViewChild(LvMinDateDirective) dateDirective: LvMinDateDirective;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  @Input() dividendsSource:IEquityDividends;
  @Input() isEditable: boolean;

  get isContinousYieldDividendType() {
    return this.dividendsSource.dividendParameters.dividendType === DividendTypeEnum.ContinuousYield;
  }

  get dividendValueLabel() {
    if (this.dividendsSource.dividendParameters &&
      this.dividendsSource.dividendParameters.dividendType === DividendTypeEnum.DiscreteYield) {
        return 'Dividend Value (annualized)';
      }

    return 'Dividend Value (per period)';
  }

  get dividendValueSuffix() {
    if (this.dividendsSource.dividendParameters.dividendType === DividendTypeEnum.DiscreteYield) {
      return '%';
    } else {
      return this.dividendsSource.dividendsCcy;
    }
  }

  get dateFormated(): string {
    if (!(this.dividendsSource && this.dividendsSource.dividendParameters &&
          this.dividendsSource.dividendParameters.firstDividendDate) || (this.dateDirective && !this.dateDirective.onValueChanged())) {
      return '';
    }
   else {
      return new Date(this.dividendsSource.dividendParameters.firstDividendDate).toLocaleDateString();
    }
  }

  get displayNonEditableDividendType(): string {
    switch (this.dividendsSource.dividendParameters.dividendType) {
      case DividendTypeEnum.ProportionalCash:
        return DividendTypeEnumDescription.ProportionalCash;
      case  DividendTypeEnum.ContinuousYield:
        return DividendTypeEnumDescription.ContinuousYield;
      case  DividendTypeEnum.DiscreteYield:
        return DividendTypeEnumDescription.DiscreteYield;
      case DividendTypeEnum.Cash:
        return DividendTypeEnumDescription.Cash;
    }
  }

  get displayNonEditableDividendFrequency(): string {
    if (this.dividendsSource.dividendParameters.frequency === EquityDividendEnum.SemiAnnual ) {
      return DividendFrequencyEnum.SemiAnnual;
    }
    return this.dividendsSource.dividendParameters.frequency;
  }

  dividendTypeSource: LvLookupEnum;
  dividendFrequencySource: LvLookupEnum;

  fourNumberOfDecimals = '4';
  fourNumberFormat = '#.####';
  minDatePickerDate: Date;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    this.didSessionUpdatedEvent = new EventEmitter();
    this.dividendTypeSource = new LvLookupEnum(DividendTypeEnumDescription);
    this.dividendFrequencySource = new LvLookupEnum(DividendFrequencyEnum);

    this.dividendsSource = {
        dividendsCcy: null,
        dividendParameters: {
          dividendType: null,
          dividendValue: null,
          frequency: null,
          dividendGrowth: null,
          firstDividendDate:null,
          fixedDividendStartTime: null,
        },
        dividendsSchedule:{
          dividendSchedule: [{
            date: null,
            value: null,
            type: null,
            netFactor: null
          }]
        }
    } as IEquityDividends;
    this.isEditable= true;
  }

  @HostBinding('class.lv-equity-dividends-parameters')
    get isLvEquityDividendsParametersComponent() {
      return true;
    }
   /**
   * Occurs on dividend parameters change.
   */
   onDividendParametersChange() {
    this.isFirstDividendDateValid();
    this.didSessionUpdatedEvent.next();
    this.detectChanges();
  }

  /**
   * Detect changes.
   *
   * @memberof LvEquityDividendsParametersComponent
   */
  detectChanges(): void {
    this._changeDetectorRef.detectChanges();
  }

   /**
   * method checks if entered date is valid
   * if date is incomplete, in order to reset its value, it is necessary to recreate date object to set its value  back to null
   * if date is less than min date, firstDividendDate is set to null
   */
   private isFirstDividendDateValid(): void {
    if (this.dividendsSource.dividendParameters.firstDividendDate === null) {
      this.dividendsSource.dividendParameters.firstDividendDate = new Date();
      this.detectChanges();
      this.dividendsSource.dividendParameters.firstDividendDate = null;
    }
    else if (!this.dateFormated) {
      this.dividendsSource.dividendParameters.firstDividendDate = null;
    }
  }
}
