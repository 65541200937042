import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, HostBinding, ElementRef } from '@angular/core';
import { IInterestRatesSettings, IInterestRatesSectionSettings } from '@lv-analytics/models';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';

/**
 * Interest rates settings component.
 */
@Component({
  selector: 'lv-interest-rates-settings',
  templateUrl: './lv-interest-rates-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvInterestRatesSettingsComponent {
  @Input() instrumentType: string;
  @Input() settings: IInterestRatesSettings;
  @Input() systemYieldCurvesSettingsData: ISystemYieldCurvesSettingsView;

  constructor() {
    this.instrumentType = '';
    this.settings = {
      systemDefaults: {
        yieldCurveSettings: []
      } as IInterestRatesSectionSettings,
      environments: []
    } as IInterestRatesSettings;

    this.systemYieldCurvesSettingsData = {} as ISystemYieldCurvesSettingsView;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

  @HostBinding('class.lv-interest-rates-settings')
  get isLvInterestRatesSettingsComponent() {
    return true;
  }
}
