import { LvDateService } from "@lv-core-ui/services";
import { Coupon, Identifiers } from "@lv-instrument-common/index";
import { BondGeneral } from "./BondGeneral";
import { BondPut } from "./BondPut";
import { BondAccretion } from "./BondAccretion";
import { BondCleanUpCall } from "./BondCleanUpCall";
import { BondCall, CallMakeWholeData } from "./BondCall";
import { BondCoupon } from "./BondCoupon";

export class BondTermsDocument {
    general: BondGeneral;
    accretion: BondAccretion;
    call: BondCall;
    cleanUpCall: BondCleanUpCall;
    put: BondPut;
    coupon: BondCoupon;
    identifiers: Identifiers[];

    /**
     * Check if bond is ready to be draft.
     * @returns flag that describes whether bond is ready to be draft.
     */
    static isDraftReady = (terms: BondTermsDocument): boolean => {
        return !!terms.general.name && (!!terms.general.maturityDate || terms.general.isPerpetual);
    }
}

export const getDefaultBondDocument = (_lvDateService: LvDateService): BondTermsDocument => {
    return {
        general: {
            firstSettlementDate: _lvDateService.getUtcDate(new Date()),
            sinkingFundSchedule: []
        },
        accretion: {} as BondAccretion,
        call: {
            scheduleItems: [],
            callMakeWhole: {} as CallMakeWholeData
        },
        put: {
            scheduleItems: []
        },
        cleanUpCall: {} as BondCleanUpCall,
        coupon: {
            fixed: {
                stepUpCouponSchedule: []
            },
            floating: {
                fixingHistoryItems: [],
                floatingCustomDates: [],
                floatingSpreadSchedule: []
            },
            pik: {
                pikCustomSchedule: []
            },
            custom: {
                schedule: []
            }
        },
        identifiers: []
    } as BondTermsDocument
}