import {
  Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy,
  OnDestroy, ViewChild, ElementRef, Optional
} from '@angular/core';
import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LvCallsView } from './lv-calls.view';
import { LvCallsScheduleComponent } from './lv-calls-schedule/lv-calls-schedule.component';
import { ConvertibleBondTermsSectionEvent } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { LvDateService } from '@lv-core-ui/services';


@Component({
  selector: 'lv-calls',
  templateUrl: './lv-calls.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCallsComponent implements OnInit, OnDestroy {

  @ViewChild('callSchedule') callSchedule: LvCallsScheduleComponent;

  view: LvCallsView;
  private subscriptions: Subscription[];
  dpCallFirstCallConsiderationDatePickerId: string;
  dpCallTriggerObservationStartDatePickerId: string;

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get includeCashLabel(): string {
    return this.isOpenedFromExcel ? 'Incl. Cash in Call Trigger' : 'Include Cash in Call Trigger';
  }

  get keepAccruedConversionLabel(): string {
    return this.isOpenedFromExcel ? 'Keep Accr. (conversion)' : 'Keep Accrued (conversion)';
  }

  get keepAccruedRedemptionLabel(): string {
    return this.isOpenedFromExcel ? 'Keep Accr. (redemption)' : 'Keep Accrued (redemption)';
  }

  constructor(
    private _presenter: LvConvertibleBondTermsPresenter,
    private _changeDetectorRef: ChangeDetectorRef,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    private _lvDateService: LvDateService,
    @Optional() private _excelSvc: LvExcelService) {

    this.view = new LvCallsView(_lvConvertibleBondTermsService, _lvDateService, _excelSvc);
    this.dpCallFirstCallConsiderationDatePickerId = 'callFirstCallConsiderationDatePickerId';
    this.dpCallTriggerObservationStartDatePickerId = 'callTriggerObservationStartDatePickerId';
  }

  ngOnInit() {
    this.subscriptions = [
      this._presenter.onModelUpdated
        .pipe(
          filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.CallsEvent &&
            event.eventId !== ConvertibleBondTermsSectionEvent.CleanUpCallEvent &&
            event.eventId !== ConvertibleBondTermsSectionEvent.CallIssueAndRedemptionEvent &&
            event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
        )
        .subscribe(model => {
          if (model.eventId === ConvertibleBondTermsSectionEvent.MWInitCallsEvent) {
            this.view.initMakeWholeData(model.data.fullTerms.call);
          }
          else {
            if (model.data) {
              this.view.init(model.data.fullTerms.call, model.data.fullTerms.cleanUpCall,
                model.data.fullTerms.issueAndRedemption, model.data.fullTerms.coupon);
              this._changeDetectorRef.detectChanges();
            }
          }
        }),
    ];

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;

      this.view.init(fullTerms.call,
        fullTerms.cleanUpCall,
        fullTerms.issueAndRedemption,
        fullTerms.coupon);

      this._changeDetectorRef.detectChanges();
    }
  }

  onCallValueTypeChanges() {
    if (!!this.callSchedule) {
      this.callSchedule.resetPriceCallSchedule(this.view.call.valueType);
    }

    this.onModelChange();
  }

  onCleanUpCallChanged() {
    this.onIssueAndRedemptionChange();
    this.view.cleanUpCallChanged();

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.CleanUpCallEvent,
      data: this.view.cleanUpCall,
      sourceOfUpdate: 'other'
    });
  }

  onCallChanged() {
    this.onIssueAndRedemptionChange();
    this.view.onCallChange();
    this.onModelChange();
  }

  applyScheduleChanges() {
    if (this.view.call && this.callSchedule) {
      this.callSchedule.applyAdvancedGridChanges();
    }
  }

  onModelChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.CallsEvent,
      data: this.view.call,
      sourceOfUpdate: 'other'
    });
  }

  onIssueAndRedemptionChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.CallIssueAndRedemptionEvent,
      data: this.view.issueAndRedemption,
      sourceOfUpdate: 'other'
    }, false);
  }

  /**
   * Function for Recalculate readonly dates - First Call Consideration Date and Call Trigger Obs. Start Date.
   */
  recalculateReadOnlyDates() {
    this.view.recalculateReadOnlyDates();
    this._changeDetectorRef.detectChanges();
    this.onModelChange();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(a => a.unsubscribe());
  }

  getCallTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  onCallNoticeGivenChange() {
    this.view.setCallDate();
    this.onModelChange();
    this._changeDetectorRef.detectChanges();
  }
}


