
/**
 * Terms changed event type.
 *
 * @export
 * @enum {number}
 */
export enum TermsChangedEvent {
    PriorityFields,
    CalculationAffectingFields,
    Other
}