import { CurrencyType, Frequency } from '@lv-instrument-common/index';
import { IDividendProtectionSectionSettings } from '../dividend-protection-settings/dividend-protection-section-settings';
import { DividendProtectionType, DividendProtectionTresholdType, DividendProtectionTresholdFormulaType,
  MinAdjustmentType, AdjustmentFormulaType, CashDistributionDateType,
  SpotLevelAtAdjustmentFormulaType} from '@lv-convertible-bond/models';

export const getDefaultDividendProtectionSectionSettings = (): IDividendProtectionSectionSettings => {
  return {
    type: DividendProtectionType.ConversionRatioAdjustment,
    minAdjustmentType: MinAdjustmentType.None,
    conversionRatioAdjustment: {
        formulaType: AdjustmentFormulaType.Type2,
        spotLevelAtAdjustmentFormula: SpotLevelAtAdjustmentFormulaType.BeforeDividendExDate,
        twoWayConversionPriceAdjustment: false
    },
    cashDistribution: {
        dateType: CashDistributionDateType.AtConversion,
        percentOfPassThrough: 100,
    },
    tresholdType: DividendProtectionTresholdType.FullProtection,
    tresholdPeriodFrequency: Frequency.Annual,
    cashTresholdCurrencyType: CurrencyType.Underlying,
    tresholdFormulaType: DividendProtectionTresholdFormulaType.Single,
    addCarryForwardOnConversion: true,
    endDate: null,
    startDate: null,
    includeTrigger: false,
    useDividendProtection: false
  };
};
