import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpClientBase } from '@lv-core-ui/api';
import { IWidget, LvInstrumentMonitorError } from '@lv-instrument-monitor/models';
import { LvDateService } from '@lv-core-ui/services';

@Injectable()
export class WidgetService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/instrumentMonitor/widgets');
  }

  async getWidget(widgetId: string, widgetName: string): Promise<IWidget> {
    try {
      const result: IWidget = await this.getAsync<IWidget>({
        widgetId: widgetId,
        widgetName: widgetName || 'widget'
      }, '/load');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async updateWidget(widget: IWidget): Promise<void> {
    try {
      await this.postAsync<any>({
        ownerId: widget.ownerId,
        widgetId: widget.widgetId,
        widgetName: widget.widgetName,
        state: widget.state
      });
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async deleteWidget(widget: IWidget): Promise<void> {
    try {
      await this.deleteAsync<any>(`/${widget.widgetId}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }
}
