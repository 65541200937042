import { IField } from "@lv-excel/models/field";

/**
 * Helper class for a stock reference fields and save back to excel logic
 */
export class StockRefSaveBackToExcelHelper {

  private static readonly VALUE = 'VALUE';
  private static readonly EXIST = 'EXIST';
  private static readonly NOT_EXIST = 'NOT_EXIST';

  /**
   * Custom save back to excel logic for stock references fields
   * @param dataFields Fields that application is opened with.
   * @param fields Fields that will be saved back to excel.
   * @returns Filtered list of fields that will be saved back to excel.
   */
  static customReverseMapStockRefFields(dataFields: IField[], fields: IField[], isV31: boolean): IField[]{
    
    const STK_REF: string = !isV31 ? 'STK_REF' : 'UND_PRICE';
    const STK_REF_CBCCY: string = !isV31 ? 'STK_REF_CBCCY' : 'UND_PRICE_INST_CCY';
    const STK_REF_CPCCY: string = !isV31 ? 'STK_REF_CPCCY' : 'UND_PRICE_CP_CCY';
    const STK_REF_EQCCY: string = !isV31 ? 'STK_REF_EQCCY' : 'UND_PRICE_UND_CCY';
    const FX: string = 'FX';
    const stockRefFields: string[] = [STK_REF, STK_REF_CPCCY, STK_REF_EQCCY, STK_REF_CBCCY];

    let fieldsToExclude: string[] = [];

    if (!this.getPopulatedStockRefField(dataFields)
      && this.getAliasStateInFieldsArray(dataFields, FX) !== this.VALUE
      && this.getAliasStateInFieldsArray(fields, STK_REF) === this.VALUE) {
      fieldsToExclude = [STK_REF_CBCCY, STK_REF_CPCCY, STK_REF_EQCCY, FX];
    }
    else if (!!this.getPopulatedStockRefField(dataFields)
      && this.getAliasStateInFieldsArray(dataFields, FX) === this.EXIST) {
        const populatedStockRefField = this.getPopulatedStockRefField(dataFields);
        fieldsToExclude = stockRefFields.filter(x => x !== populatedStockRefField);
        fieldsToExclude.push(FX);
    }
    else if (this.getAliasStateInFieldsArray(dataFields, STK_REF) !== this.NOT_EXIST
      && this.getAliasStateInFieldsArray(dataFields, FX) === this.EXIST
      && this.getAliasStateInFieldsArray(fields, STK_REF) === this.VALUE) {
      fieldsToExclude = [STK_REF_CPCCY, STK_REF_EQCCY, STK_REF_CBCCY];
    }
    else if (!this.getPopulatedStockRefField(dataFields)
      && this.getAliasStateInFieldsArray(dataFields, FX) === this.VALUE) {

        if (this.getAliasStateInFieldsArray(dataFields, STK_REF) === this.EXIST) {
          fieldsToExclude = [STK_REF_CBCCY, STK_REF_CPCCY, STK_REF_EQCCY];
        }
        else if (this.getAliasStateInFieldsArray(dataFields, STK_REF_CBCCY) === this.EXIST) {
          fieldsToExclude = [STK_REF, STK_REF_CPCCY, STK_REF_EQCCY];
        }
        else if (this.getAliasStateInFieldsArray(dataFields, STK_REF_EQCCY) === this.EXIST) {
          fieldsToExclude = [STK_REF, STK_REF_CPCCY, STK_REF_CBCCY];
        }
        else if (this.getAliasStateInFieldsArray(dataFields, STK_REF_CPCCY) === this.EXIST) {
          fieldsToExclude = [STK_REF, STK_REF_EQCCY, STK_REF_CBCCY];
        }
    }
    else if (!!this.getPopulatedStockRefField(dataFields)
      && this.getAliasStateInFieldsArray(dataFields, FX) === this.VALUE) {
        const populatedStockRefField = this.getPopulatedStockRefField(dataFields);
        fieldsToExclude = stockRefFields.filter(x => x !== populatedStockRefField);
    }
    else if (this.getAliasStateInFieldsArray(dataFields, STK_REF_CBCCY) === this.EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF_EQCCY) === this.EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF_CPCCY) === this.EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF) === this.NOT_EXIST &&
    this.getAliasStateInFieldsArray(dataFields, FX) === this.EXIST &&
    this.getAliasStateInFieldsArray(fields, STK_REF_CBCCY) === this.VALUE) {
      fieldsToExclude = [STK_REF_EQCCY, STK_REF_CPCCY, STK_REF, FX];
    }
    else if (this.getAliasStateInFieldsArray(dataFields, STK_REF_CBCCY) === this.NOT_EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF_EQCCY) === this.EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF) === this.NOT_EXIST &&
    this.getAliasStateInFieldsArray(dataFields, FX) !== this.VALUE &&
    this.getAliasStateInFieldsArray(fields, STK_REF_EQCCY) === this.VALUE) {
      fieldsToExclude = [STK_REF_CBCCY, STK_REF_CPCCY, STK_REF, FX];
    }
    else if (this.getAliasStateInFieldsArray(dataFields, STK_REF_CBCCY) === this.NOT_EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF_EQCCY) === this.NOT_EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF_CPCCY) === this.EXIST &&
    this.getAliasStateInFieldsArray(dataFields, STK_REF) === this.NOT_EXIST &&
    this.getAliasStateInFieldsArray(dataFields, FX) !== this.VALUE &&
    this.getAliasStateInFieldsArray(fields, STK_REF_CPCCY) === this.VALUE) {
      fieldsToExclude = [STK_REF_CBCCY, STK_REF_EQCCY, STK_REF, FX];
    }
    
    if (this.getAliasStateInFieldsArray(dataFields, FX) === this.EXIST) {
      fieldsToExclude.push(FX);
    }

    fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);

    if (dataFields.find(x => x.alias === STK_REF_CBCCY)?.value
    && !dataFields.find(x => x.alias === STK_REF_EQCCY)?.value) {
      fields = fields.filter(x => x.alias !== STK_REF_EQCCY);
    }

    if (dataFields.find(x => x.alias === STK_REF_EQCCY)?.value
    && !dataFields.find(x => x.alias === STK_REF_CBCCY)?.value) {
      fields = fields.filter(x => x.alias !== STK_REF_CBCCY);
    }

    if (!dataFields.find(x => x.alias === STK_REF_EQCCY)?.value
      && !!dataFields.find(x => x.alias === STK_REF_EQCCY)
      && !!dataFields.find(x => x.alias === STK_REF_CBCCY)
      && !dataFields.find(x => x.alias === STK_REF_CBCCY)?.value) {
      fields = fields.filter(x => x.alias !== STK_REF_EQCCY);
    }

    return fields;
  }

  /**
   * Get state of an alias in fields array. Field can be value, exist and not exist.
   * @param dataFields List of fields.
   * @param alias Alias value.
   * @returns Status of alias field.
   */
  private static getAliasStateInFieldsArray(dataFields: IField[], alias: string): string {
    if (dataFields.findIndex(x => x.alias === alias && !!x.value) > -1) {
      return this.VALUE;
    }
    else if (dataFields.findIndex(x => x.alias === alias && !x.value) > -1) {
      return this.EXIST;
    }

    return this.NOT_EXIST;
  }

  /**
   * Get populated stock ref field from list of fields. Or return undefined if there is not any.
   * @param dataFields List of fields.
   * @returns Populated stock ref field or undefined.
   */
  private static getPopulatedStockRefField(dataFields: IField[]) {
    return dataFields.find(x => (x.alias.startsWith('STK_REF') || x.alias.startsWith('UND_PRICE')) && !!x.value)?.alias;
  }
}