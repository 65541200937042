import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LvAnalyticsError } from '../../models/errors';
import { IOutput } from '../../models/outputs/output';
import { SaveUserDefaultOutputsRequest } from '../../models/outputs/save-user-default-outputs-request';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Outputs service.
 */
@Injectable()
export class OutputsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/analytics/outputs');
  }

  /**
   * Save user outputs state
   * @param {IOutput[]} userDefaultOutputs default outputs
   * @param {boolean} isOpenedFromExcel is application opened from excel
   */
  async saveUserDefaultOutputs(userDefaultOutputs: IOutput[], isOpenedFromExcel: boolean, instrumentType: string) {
    try {

      const saveUserDefaultOutputsRequest = {
        outputs: userDefaultOutputs,
        isOpenedFromExcel: isOpenedFromExcel,
        instrumentType: instrumentType,
      } as SaveUserDefaultOutputsRequest;

      await this.postAsync<any>(saveUserDefaultOutputsRequest, '/saveUserDefaultOutputs');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
}
