
<lv-flex-box class="lv-market-data-bond-settings-container">
  <kendo-tabstrip class="lv-tabstrip marketData-settings-tabstrip lv-settings-content"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="Credit"
    cssClass="DM-4732">
    <ng-template kendoTabContent>
      <lv-credit-bond-settings
      [settings]="settings.creditSettings"
      data-cy="DM-4732">
      </lv-credit-bond-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Withholding Tax"
    cssClass="DM-4733">
    <ng-template kendoTabContent>
      <lv-withholding-tax-bond-settings
        [settings]="settings.withholdingTaxSettings"
        data-cy="DM-4733">
      </lv-withholding-tax-bond-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Interest Rates"
    cssClass="DM-4734">
    <ng-template kendoTabContent>
      <lv-interest-rates-bond-settings
      [settings]="settings.interestRatesSettings"
      [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData"
      data-cy="DM-4734"> 
      </lv-interest-rates-bond-settings>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<lv-flex-box class="lv-settings-footer" direction="row">
    <button kendoButton 
      class= "cancel-btn"
      [primary]="true"
      lvId="cancel-market-data-bond-settings"
      (click)="onCancel()">
      Cancel
    </button>
    <div class="lv-settings-footer-divider">&nbsp;</div>
    <button kendoButton
     class="reset-button"
     [primary]="true"
     lvId="reset-to-default-market-data-bond-settings"
     (click)="onResetToDefault()">
     Reset To Default
    </button>
   <button kendoButton
     [primary]="true"
     [class.save-settings-btn-disabled]="!isChanged()"
     lvId="save-market-data-bond-settings"
     (click)="onSave()">
     Save
   </button>  
</lv-flex-box>
</lv-flex-box>
