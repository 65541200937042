import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IEquityVolatilitySettingsContent } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-volatility-settings/equity-volatility-settings-content/equity-volatility-settings-content';

@Component({
  selector: 'lv-volatility-equity-settings',
  templateUrl: './lv-volatility-equity-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvVolatilityEquitySettingsComponent {
  @Input() settings: IEquityVolatilitySettingsContent;


  @HostBinding('class.lv-volatility-equity-settings')
  get isLvVolatilityEquitySettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns flag- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
