import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LvTakeoverProtectionView } from './lv-takeover-protection.view';
import { LvConvertibleBondTermsPresenter, UpdateRatioCommand } from '../lv-convertible-bond-terms.presenter';
import { ConvertibleBondTermsSectionEvent, TakeoverCompensationType } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

@Component({
    selector: 'lv-takeover-protection',
    templateUrl: './lv-takeover-protection.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class LvTakeoverProtectionComponent implements OnInit, OnDestroy {

  view: LvTakeoverProtectionView;
  private subscriptions: Subscription[];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    private presenter: LvConvertibleBondTermsPresenter) {

    this.view = new LvTakeoverProtectionView(_lvConvertibleBondTermsService);
  }

  async ngOnInit() {
    this.subscriptions = [
      this.presenter.onModelUpdated
        .pipe(
          filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.TakeoverProtectionEvent &&
                          event.eventId !== ConvertibleBondTermsSectionEvent.TakeOverCallMWEvent &&
                          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
                          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
        )
        .subscribe(event => {
          if (event.data) {
            this.view.init(event.data.fullTerms.takeoverProtection, event.data.fullTerms.issueAndRedemption,
                          event.data.fullTerms.conversion, event.data.fullTerms.call, this.presenter.ratio);

            this.changeDetector.detectChanges();
          }
        }),

      this.presenter.onCommandExecuted.subscribe(command => {
        if (command instanceof UpdateRatioCommand) {
          this.view.updateRatio(this.presenter.ratio);

          this.changeDetector.detectChanges();
        }
      })
    ];

    if (this.presenter.isModelLoaded()) {
      const fullTerms = this.presenter.getModel().fullTerms;
      this.view.init(fullTerms.takeoverProtection,
                      fullTerms.issueAndRedemption,
                      fullTerms.conversion,
                      fullTerms.call,
                      this.presenter.ratio);
      this.changeDetector.detectChanges();
    }
  }

  takeOverCompensationChange() {
    this.onModelChange();
  }

  takeOverPutChange() {
    this.onModelChange();
  }

  onOfferValueTypeChange() {
    this.view.setCustomOfferValueType();
    this.onModelChange();
    this.changeDetector.detectChanges();
  }

  onModelChange() {
    this.view.setFieldsVisibility();
    if (this.view.call && this.view.call.callMakeWhole &&  
        this.view.model.takeoverCompensationType === TakeoverCompensationType.RatchetMatrix && 
        this.view.model.ratchetMatrixMembers) {
      this.view.call.callMakeWhole.ratchetMatrixMembers = this.view.model.ratchetMatrixMembers;
      this.presenter.updateModel({
        eventId: ConvertibleBondTermsSectionEvent.TakeOverCallMWEvent,
        data: this.view.call.callMakeWhole,
        sourceOfUpdate: 'other'
      }, false);
    }

    this.presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.TakeoverProtectionEvent,
      data: this.view.model,
        sourceOfUpdate: 'other'
    });
  }

  /**
   * Protection start date checkbox changed.
   * @param value Model value.
   */
  protectionStartDateCheckBoxChange(value: boolean): void {
    this.view.protectionStartDateCheckBoxChange(value);
    this.onModelChange();
  }

  /**
   * Protection end date checkbox changed.
   * @param value Model value.
   */
  protectionEndDateCheckBoxChange(value: boolean): void {
    this.view.protectionEndDateCheckBoxChange(value);
    this.onModelChange();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(a => a.unsubscribe());
  }

  getTakeoverProtectionTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
