<lv-flex-box class="lv-dividends-equity-settings-container">
  <lv-dividends-equity-settings
      [settings]="settings">
  </lv-dividends-equity-settings>

  <lv-flex-box class="lv-settings-footer" direction="row">
    <button kendoButton
      [primary]="true"
      lvId="cancel-equity-dividends-settings"
      (click)="onCancel()">
        Cancel
    </button>
    <div class="lv-settings-footer-divider">&nbsp;</div>

    <button kendoButton
      class="reset-button"
      [primary]="true"
      lvId="reset-to-default-equity-dividends-settings"
      (click)="onResetToDefault()">
      Reset To Default
    </button>
    <button kendoButton
      [primary]="true"
      lvId="save-equity-dividends-settings"
      [class.save-settings-btn-disabled]="!isChanged()"
      (click)="onSave()">
        Save
    </button>
  </lv-flex-box>
