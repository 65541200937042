import { Component, OnInit, Input, ViewChild, ViewEncapsulation, Output,
  EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ViewRef} from '@angular/core';

import { DialogRef } from '@progress/kendo-angular-dialog';

import { LvMultiSelectComponent } from '@lv-core-ui/components';
import { LvErrorService } from '@lv-core-ui/services';
import { IOutput } from '@lv-analytics/models/outputs/output';
import { AnalyticsService } from '@lv-analytics/analytics.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { ICustomOutputsTab } from '@lv-analytics/models/outputs/custom-outputs-tab';

/**
 * Outputs dialog component.
 */
@Component({
  selector: 'lv-outputs-dialog',
  templateUrl: './lv-outputs-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvOutputsDialogComponent implements OnInit {

  @ViewChild('outputsMultiSelect',  {read: LvMultiSelectComponent }) outputsMultiSelect: LvMultiSelectComponent;
  @ViewChild('tabularMultiSelect', {read: LvMultiSelectComponent}) tabularMultiSelect: LvMultiSelectComponent;

  @Input() tabularOutputs: ICustomOutputsTab;
  @Input() outputs: ICustomOutputsTab;
  @Input() instrumentType: string;

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<IOutput[]>;
  @Output() didSelectedItemsChanged: EventEmitter<boolean[]>;

  get isConvertibleBond() : boolean {
    return this.instrumentType === 'ConvertibleBond';
  }

  isLoading: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private dialogRef: DialogRef,
    private errorService: LvErrorService,
    private service: AnalyticsService
  ) {
    this.isLoading = false;
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<any>();
    this.didSelectedItemsChanged = new EventEmitter<boolean[]>();

    this.tabularOutputs = {
      sessionId: '',
      availableItems: [],
      selectedItems: [],
      tooltips: null,
    }

    this.outputs = {
      sessionId: '',
      availableItems: [],
      selectedItems: [],
      tooltips: null,
    }
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    // Removes grid outputs form UI, because this outputs are only related to EXCEL and API.
    // https://leversys.atlassian.net/browse/SYSTEM-5113
    this.outputs.availableItems = this.outputs.availableItems.filter(x => x.type !== 'theoValueGrid' && x.type !== 'deltaGrid' && x.type !== 'ivDeltaGrid');
  }

  /**
   * Closes dialog.
   */
  onCancel() {
    if (!this.isLoading) {
      this.dialogRef.close();

      this.didCancel.emit();
    }
  }

  /**
   * Saves outputs.
   */
  async onSave() {
    try {
      const updatedOutputs: IOutput[] = [];
      this.isLoading = true;
      this._changeDetectorRef.detectChanges();

      const mappedOutputs = this.outputsMultiSelect.getItemsMap();
   
      const mappedTabularOutputs = this.tabularMultiSelect.getItemsMap();

          Object.values(mappedOutputs.selectedItems).forEach(a => {
            updatedOutputs.push({
              type: a.item.type,
              isSelected: true,
              order: a.order,
              isTabular : false
            });
          });
    
          Object.values(mappedOutputs.availableItems).forEach(a => {
            updatedOutputs.push({
              type: a.item.type,
              isSelected: false,
              order: a.order,
              isTabular: false
            });
          });

          Object.values(mappedTabularOutputs.selectedItems).forEach(a => {
            updatedOutputs.push({
              type: a.item.type,
              isSelected: true,
              order: a.order,
              isTabular : true
            });
          });

          Object.values(mappedTabularOutputs.availableItems).forEach(a => {
            updatedOutputs.push({
              type: a.item.type,
              isSelected: false,
              order: a.order,
              isTabular: true
              });
          });

        const isSelectedOutputsItemsListEmpty = updatedOutputs.filter(el => el.isTabular === false).every(el => el.isSelected === false);
        const isSelectedTabularOutputsItemsListEmpty = updatedOutputs.filter(el => el.isSelected === false).every(el => el.isTabular === true);

        this.didSelectedItemsChanged.emit([isSelectedOutputsItemsListEmpty, isSelectedTabularOutputsItemsListEmpty]);

        await this.service.valuationSessionService.saveCustomOutputs(this.outputs.sessionId, this.instrumentType, updatedOutputs);

        this.errorService.toastrService.success(LvDataMaster.getInfo('dM-3388',
        {'value': 'Output Configuration'}), 'Output Configuration');
    
        this.didSave.emit(updatedOutputs);
        this.dialogRef.close(); 
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      if (!(this._changeDetectorRef as ViewRef).destroyed) {
        this._changeDetectorRef.detectChanges();
      }    
    }
  }
}
