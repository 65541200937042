import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IAssetSwapTermsSectionSettings } from '../../models/asset-swap-terms-settings/asset-swap-terms-section-settings';
import { IBaseAssetSwapTermsSettings } from '../../models/asset-swap-terms-settings/base-asset-swap-terms-settings';

@Component({
  selector: 'lv-asset-swap-terms-settings',
  templateUrl: './lv-asset-swap-terms-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvAssetSwapTermsSettingsComponent implements OnInit{
  @Input() systemDefaults: IBaseAssetSwapTermsSettings<IAssetSwapTermsSectionSettings>;
  @Input() myDefaults: IBaseAssetSwapTermsSettings<IAssetSwapTermsSectionSettings>;

  constructor() {
  }

  ngOnInit(): void {
    this.myDefaults = {
      overrideSystemDefaults: true,
      settings: {},
    } as IBaseAssetSwapTermsSettings<IAssetSwapTermsSectionSettings>
    
    this.systemDefaults = {
      overrideSystemDefaults: false,
      settings: {},
    } as IBaseAssetSwapTermsSettings<IAssetSwapTermsSectionSettings>
  }

    /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
    getTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
      return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
    }
}
