import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvAdvancedGridColumn, LvAdvancedGridComponent, LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvExcelService } from '@lv-excel/services';
import { SinkingFundScheduleItem } from '@lv-instrument-common/SinkingFundScheduleItem';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lv-bond-general-sinking-schedule',
  templateUrl: './lv-bond-general-sinking-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBondGeneralSinkingScheduleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() sinkingSchedule: SinkingFundScheduleItem[];
  @Output() didSetSchedule: EventEmitter<SinkingFundScheduleItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  scheduleItems: SinkingFundScheduleItem[];
  parseFn: any;
  excelFieldAlias = 'SNK_FUND_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelService?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelService?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelService: LvExcelService
  ) {
      this.initColumns();
      this.parseFn = this.parserFunction.bind(this);
      this.scheduleItems = [];

      this.didSetSchedule = new EventEmitter<SinkingFundScheduleItem[]>();
   }

  ngOnInit() {
    this._modelSubscription = [
      this.advancedGrid.didDataChange.subscribe((records: SinkingFundScheduleItem[]) => this.onScheduleChange(records)),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.init();
  }

  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
    this.init();
  }

  doEmitSchedule() {
    this.didSetSchedule.next(this.sinkingSchedule);
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'sinkingDate': new FormControl(args.isNew ? new Date() : args.dataItem.sinkingDate, Validators.required),
      'redemption': new FormControl(args.dataItem.redemption, Validators.required),
    });
  }

  private init() {
    this.scheduleItems = this.sinkingSchedule.map(a => ({ ...a }));
  }

  private initColumns() {
    this.columns = [];

    const sinkingDateColumn = new LvAdvancedGridDateColumn();
    sinkingDateColumn.title = 'End Date';
    sinkingDateColumn.field = 'sinkingDate';
    sinkingDateColumn.width = 103;
    sinkingDateColumn.dmKey = 'DM-2257';

    const redemptionColumn = new LvAdvancedGridNumericColumn();
    redemptionColumn.title = 'Redemption (%)';
    redemptionColumn.field = 'redemption';
    redemptionColumn.width = 89;
    redemptionColumn.dmKey = 'DM-2258';

    this.columns.push(sinkingDateColumn);
    this.columns.push(redemptionColumn);
  }

  private parserFunction(pastedDataRecords: string[]): SinkingFundScheduleItem[] {
    const scheduleItems: SinkingFundScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const sinkingDateValue = items[0];
      const redemptionValue = items[1];

      const sinkingDate = MarketDataClipboard.parseDateValue(sinkingDateValue, 'Date');
      const redemption = MarketDataClipboard.parseNumberValue(redemptionValue, 'Redemption (%)');

      scheduleItems.push({
        sinkingDate: sinkingDate,
        redemption: redemption
      } as SinkingFundScheduleItem);
    });

    return scheduleItems;
  }

  private onScheduleChange(scheduleItems: SinkingFundScheduleItem[]) {
    this.sinkingSchedule.splice(0, this.sinkingSchedule.length);
    this.sinkingSchedule.push(...scheduleItems);
    this.doEmitSchedule();
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }
}
