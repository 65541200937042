<lv-asset-swap-terms-settings
class="lv-asset-swap-terms-settings"
[systemDefaults]="settings.system.termsSettings"
[myDefaults]="settings.my.termsSettings">  
</lv-asset-swap-terms-settings>

<lv-flex-box class="lv-settings-footer" direction="row">
  <button kendoButton
    data-cy="DM-4064"
    [primary]="true"
    lvId="cancel-asset-swap-terms-settings"
    (click)="onCancel()">
      Cancel
  </button>
  <div class="lv-settings-footer-divider">&nbsp;</div>

  <button kendoButton
    data-cy="DM-4081"
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-asset-swap-terms-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    data-cy="DM-4211"
    [primary]="true"
    lvId="save-asset-swap-terms-settings"
    [class.save-settings-btn-disabled]="!isChanged()"
    (click)="onSave()">
      Save
  </button> 
</lv-flex-box>
