import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ViewChild,
  ViewChildren, QueryList, OnDestroy, OnChanges } from '@angular/core';

import { GridsterComponent, GridsterConfig } from 'angular-gridster2';

import * as _ from 'lodash';
import { LvSidePanelComponent } from '@lv-core-ui/components';
import { ComponentLocationDirective } from '@lv-core-ui/directives';
import { IInstrumentWidgetState, DefaultGridsterConfigFactory, IInstrumentWidgetVisibility } from '..';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'analytics',
  templateUrl: './analytics.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsComponent implements OnInit, OnChanges {

  get gridsterWidgets(): IInstrumentWidgetState[] {
    return this.widgets.filter(a => !!a.gridsterItemConfig);
  }

  id: string;

  constructor() {
    this.widgets = [];
    this.editable = false;

    this.gridsterConfig = DefaultGridsterConfigFactory.getDefaultGristerConfig();
  }

  @ViewChildren(ComponentLocationDirective) componentLocations: QueryList<ComponentLocationDirective>;
  @ViewChild(LvSidePanelComponent) sidePanelSettings: LvSidePanelComponent;
  @ViewChild('gridster') gridster: GridsterComponent;

  @Input() editable: boolean;
  @Input() widgets: IInstrumentWidgetState[];
  @Input() instrumentType: string;

  gridsterConfig: GridsterConfig;

  ngOnInit() {
  }

  ngOnChanges() {
    // Enable gridster editing
    this.gridsterConfig.draggable.enabled = this.editable;
    this.gridsterConfig.resizable.enabled = this.editable;
    this.gridsterConfig.pushItems = this.editable;

    if (this.gridsterConfig.api && this.gridsterConfig.api.optionsChanged) {
      this.gridsterConfig.api.optionsChanged();
    }
  }

  shouldShowWidget(widget: IInstrumentWidgetState, name: string): boolean {
    return widget.id === name && widget.visibility === IInstrumentWidgetVisibility.AS_WIDGET;
  }

  isWidgetVisible(widget: IInstrumentWidgetState) {
    return widget.visibility === IInstrumentWidgetVisibility.AS_WIDGET;
  }

  /**
   * Should section be visible.
   * @param instrumentTypeForm Instrument Type value from form.
   * @returns Flag that describes whether the section should be visible.
   */
  shouldDisplaySection(instrumentTypeForm): boolean {
    if (!instrumentTypeForm) {
      return true;
    }

    return instrumentTypeForm === this.instrumentType;
  }
 }
