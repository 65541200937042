import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { LvAdvancedGridColumn, LvAdvancedGridEnumColumn, LvAdvancedGridTextColumn } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { LvAdvancedGridComponent } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid.component';
import { LvError, LvErrorType } from '@lv-core-ui/models/lv-error';
import { IAswIdentifiers } from '../../../models/asset-swap-terms-settings/enums/asset-swap-identifiers';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { AswIdentifierType, AswIdentifierTypeDescription } from '../../../models/asset-swap-terms-settings/enums/asset-swap-identifiers-enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid/editing-directives/create-form-group-args.interface';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import _ from 'lodash';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lv-asw-identifiers',
  templateUrl: './lv-asw-identifiers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvAswIdentifiersComponent  implements OnInit {

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  columns: LvAdvancedGridColumn[];
  identifiersItems: IAswIdentifiers[];
  originalValue: IAswIdentifiers[];
  parseFn: any;
  leversysId: string;

  private _destroyRef = inject(DestroyRef)

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _analyticsPresenter: LvAnalyticsPresenter,
  ) {
    this.identifiersItems = [];
    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);
    this.identifiersItems = [];
  }

  /**
   * Method handles process of record applying in schedule.
   */
  applyScheduleChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Method handles creating form group.
   * @param args CreateFormGroupArgs interface
   * @returns FormGroup class.
   */
  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'identifierType': new FormControl(args.dataItem.identifierType, Validators.required),
      'identifier': new FormControl(args.dataItem.identifier, Validators.required),
    });
  }

  /**
   * Method check for identifier type duplicates in schedule
   * @param propertyName identifierType.
   * @param inputArray input array.
   * @returns boolean.
   */
  checkDuplicateInObject(propertyName, inputArray) {
      let seenDuplicate = false;
      const testObject = {};

      inputArray.map(item => {
        const itemPropertyName = item[propertyName];
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
        }
        else {
          testObject[itemPropertyName] = item;
          delete item.duplicate;
        }
      });

      return seenDuplicate;
  }

  /**
   *  Method removes schedule duplicates.
   * @param scheduleItems IAswIdentifiers array.
   * @returns schedule items without duplicates.
   */
  removeDuplicateItem(scheduleItems: IAswIdentifiers[]) {
    return scheduleItems.reduce((acc, current) => {
      const x = acc.find(item => item.identifierType === current.identifierType);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  /**
   * Method maps identifier type to corresponds description appropriate for displaying.
   * @param identifierType identifier type.
   * @returns identifier value
   */
  mapIdentifierTypeToIdentifierTypeDesciption(identifierType: string ) {
    return AswIdentifierTypeDescription[identifierType];
  }

  onSave() {
    try {
    //to do: api call for save identifiers.
    this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388',
    {'value': 'Identifiers'}));
   } catch(error) {
    this._errorService.toastrService.error(LvDataMaster.getError('dM-3385'));
    }

  }

  /**
   * Reload asw identifiers.
   */
  onReload () {
    this.identifiersItems = [];
    //to do: api call for reload identifiers.
  }

  /**
   * Handles component initialization 
   */
  ngOnInit(): void {
    this.loadAswIdentifiers();

    this.advancedGrid.didDataChange
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe((records: IAswIdentifiers[]) => this.onScheduleChange(records)),
    this.advancedGrid.doReload
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe(() => this.onScheduleReload()),
    this.advancedGrid.didError
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe((error: LvError) => this.onError(error))
  }

  /**
   * Load asw terms identifiers.
   */
  loadAswIdentifiers() {
    // to do: remove setTimeout once valuation session object is fetched via api call.
    setTimeout(() => {
      if (!!this._analyticsPresenter?.aswSession?.terms?.identifiers) {
        this.identifiersItems = this._analyticsPresenter.aswSession.terms.identifiers;
        this._changeDetectorRef.detectChanges();
      }
    }, 1000)
  }
  
  /**
   * Sets section state changed flag.
   * @param sessionStateChange A flag indicating if session state is changed.
   */
  setSectionStateChanged() {
    this._analyticsPresenter.overrideAswInSession(aswValuationSession => {
      aswValuationSession.terms.identifiers = this.identifiersItems;
    });
    if (!_.isEqual(this.identifiersItems, this.originalValue)) {
      this.originalValue = _.cloneDeep(this.identifiersItems);
    }
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Initialize advance grid columns.
   */
  private initColumns() {
    this.columns = [];

    const identifierColumn = new LvAdvancedGridTextColumn();
    identifierColumn.title = 'IDENTIFIER';
    identifierColumn.field = 'identifier';
    identifierColumn.placeholder = '';
    identifierColumn.editable =   true;
    identifierColumn.width = 103;

    const identifierTypeColumn = new LvAdvancedGridEnumColumn();
    identifierTypeColumn.enumDescription = AswIdentifierTypeDescription;
    identifierTypeColumn.title = 'IDENTIFIER TYPE';
    identifierTypeColumn.field = 'identifierType';
    identifierTypeColumn.displayField = 'text';
    identifierTypeColumn.valueField = 'id';
    identifierTypeColumn.valuePrimitive = true;
    identifierTypeColumn.data = new LvLookupEnum(AswIdentifierTypeDescription).items;
    identifierTypeColumn.width = 138;

    this.columns.push(identifierTypeColumn);
    this.columns.push(identifierColumn);
  }

  /**
   * Method parses pasted data records and populated schedule list.
   * @param pastedDataRecords IAswIdentifiers object.
   * @returns list of schedule items.
   */
  private parserFunction(pastedDataRecords: string[]): IAswIdentifiers[] {
    const scheduleItems: IAswIdentifiers[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const identifierTypeValue = items[0];
      const identifierValue = items[1];

      const identifierType = this.parseIdentifierType(identifierTypeValue);
      const identifier = identifierValue;


      scheduleItems.push({
        identifierType: identifierType,
        identifier: identifier
      } as IAswIdentifiers);
    });

    return scheduleItems;
  }

  /**
   * Method parses identifierTypeValue.
   * @param identifierTypeValue identifierTypeValue
   * @returns AswIdentifierType object.
   */
  private parseIdentifierType(identifierTypeValue: string): AswIdentifierType {

    if (MarketDataClipboard.isEmpty(identifierTypeValue)) {
      throw new LvError(`Value should not be empty`);
    }

    const identifierTypeEnum = AswIdentifierType[identifierTypeValue];
    if (!identifierTypeEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{value}', identifierTypeValue], ['{field}', 'Identifier Type']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return identifierTypeEnum;
  }

  /**
   * Method handles errors.
   * @param error LvError class
   */
  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Method handles schedule reload.
   */
  private onScheduleReload() {
    this.identifiersItems = this.identifiersItems.map(a => ({ ...a }));
  }

  /**
   * Method handle duplicate entries.
   * @param scheduleItems IAswIdentifiers object array.
   */
  private onScheduleChange(scheduleItems: IAswIdentifiers[]) {
    if (!this.checkDuplicateInObject('identifierType', scheduleItems)) {
      this.applyRecords(scheduleItems);
      this.setSectionStateChanged();
    } else {
      this._errorService.toastrService.warning(LvDataMaster.getWarning('dM-1860'));

      const filteredArr = this.removeDuplicateItem(scheduleItems);
      this.applyRecords(filteredArr);
      this.identifiersItems = this.identifiersItems.map(a => ({ ...a }));
      this._changeDetectorRef.detectChanges();
    }
  }

   /**
   * Method handled applying schedule records.
   * @param records record array.
   */
   private applyRecords(records: any[]) {
    this.identifiersItems.splice(0, this.identifiersItems.length);
    this.identifiersItems.push(...records);
  }
}
