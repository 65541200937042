import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, OnInit, Optional, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MarketDataComponentDirective } from '@lv-analytics/directives/market-data-component/market-data-component.directive';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { SaveBondMarketDataRequest } from '@lv-analytics/models/bond/market-data/save-bond-market-data-request';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvExcelService } from '@lv-excel/services';
import { filter } from 'rxjs';

@Component({
  selector: 'lv-bond-market-data',
  templateUrl: './lv-bond-market-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LvBondMarketDataComponent implements OnInit {
  @ViewChildren(MarketDataComponentDirective) marketDataComponents: QueryList<MarketDataComponentDirective>;

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get isDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get isSessionStateChanged(): boolean {
    return this.modelsChangedCounter !== 0;
  }

  isLoading: boolean;
  isModelStateChanged: boolean;
  modelsChangedCounter: number;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _errorService: LvErrorService,
    private _destroyRef: DestroyRef,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.modelsChangedCounter = 0;
  }

  @HostBinding('class.lv-market-data')
  get isLvMarktetData(): boolean {
    return true;
  }

  ngOnInit(): void {
    this._analyticsPresenter.termsChanged
    .pipe(takeUntilDestroyed(this._destroyRef))
    .pipe(filter(event => event === TermsChangedEvent.PriorityFields))
    .subscribe(async event => {
      await this.onReloadAll();
    });
  }

  /**
   * Save market data.
   */
  async onMarketDataSaveAll() {
    try {
      this.setLoading(true);
      const saveMarketDataRequest = new SaveBondMarketDataRequest ();
      saveMarketDataRequest.sessionId = this._analyticsPresenter.bondSession.sessionId;
      saveMarketDataRequest.leversysLocalId = this._analyticsPresenter.bondSession.leversysLocalId;

      this.marketDataComponents.forEach(d => saveMarketDataRequest.applyCurrentChanges(d.marketDataComponent));
      this.marketDataComponents.forEach(d => saveMarketDataRequest.applyMarketDataSection(d.marketDataComponent));
      await this._analyticsPresenter.saveBondMarketData(saveMarketDataRequest);
      this.modelsChangedCounter = 0;
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1819'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoading(false);
    }
  }

  async onReloadAll() {
    try {
      this.setLoading(true);

      await this._analyticsPresenter.reloadBondMarketData();  
      this.modelsChangedCounter = 0;  
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoading(false);
    }
  }

  /**
   * Sets section state changed flag.
   * @param sessionStateChange A flag indicating if session state is changed.
   */
  setSectionStateChanged(): void {
    this.modelsChangedCounter = this.modelsChangedCounter + 1;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Resets section state changed flag.
   */
  setSectionStateLoaded(): void {
    if (this.modelsChangedCounter > 0) {
      this.modelsChangedCounter = this.modelsChangedCounter - 1;
    }

    this._changeDetectorRef.detectChanges();
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;
    this._changeDetectorRef.detectChanges();
  }
}
