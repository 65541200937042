import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { IExchangeRatesPrice } from '@lv-market-data/models/exchange-rates-price';
import { LvMarketDataError } from '@lv-market-data/models/errors';
import { LvSharedMarketDataService } from '@lv-shared/services/shared-market-data.service';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Market data service for public api
 */
@Injectable()
export class MarketDataService extends HttpClientBase implements LvSharedMarketDataService {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/marketData');
  }

  /**
   * Get fx rate for provided currencies
   * @param ccy1 First currency
   * @param ccy2 Second currency
   * @returns Fx rate value
   */
  async getFxRate(ccy1: string, ccy2: string): Promise<number> {
    try {
      const allFxRatesPrices = await this.getAsync<IExchangeRatesPrice[]>(null, '/exchangeratesprices');

      ccy1 = ccy1.toLocaleUpperCase();
      ccy2 = ccy2.toLocaleUpperCase();

      const usdCode = 'USD';

      let ccy1Rate = 1;

      if (ccy1 !== usdCode) {
        const ccy1exchangeRate = allFxRatesPrices.find(x => x.exchangeRateId === `${usdCode}${ccy1}`);
        ccy1Rate = ccy1exchangeRate?.rate;
      }

      let ccy2Rate = 1;

      if (ccy2 !== usdCode) {
        const ccy2exchangeRate = allFxRatesPrices.find(x => x.exchangeRateId === `${usdCode}${ccy2}`);

        if (ccy2exchangeRate) {
          ccy2Rate = ccy2exchangeRate.rate;
        }
      }

      if (!ccy1Rate || !ccy2Rate) {
        return null;
      }

      return ccy1Rate / ccy2Rate;
    }
    catch (error) {
      throw this.handleError(error, e => new LvMarketDataError(e.message));
    }
  }
}
