import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LvAnalyticsError } from '../../models/errors';

import { IPricingEnvrionment } from '../../models/company-and-user-settings/pricing-environment';
import { INewIssueSidePanelSettings, ISaveNewIssueSettingsRequest } from '../../models/company-and-user-settings/new-issue-settings';
import { ISaveEstimatesSidePanelSettingsRequest, IEstimatePanelSettings } from '../../models/company-and-user-settings/estimates-settings';
import { IMarketDataSettings } from '../../models/market-data-settings/market-data-settings';
import { ISaveValuationSettingsRequest } from '../../models/request/company-and-user-settings/save-valuation-settings-request';
import { IValuationSettingsResponse } from '../../models/response/company-and-user-settings/valuation-settings-response';
import { IModelCustomizationSettings } from '../../models/model-customization-settings/model-customization-settings';
// tslint:disable-next-line:max-line-length
import { ISaveModelCustomizationSettingsRequest } from '../../models/model-customization-settings/save-model-customization-settings-request';
import { INewIssueSettings } from '../../models/pricing-settings/new-issue-settings/new-issue-settings';
import { IEstimatesSettings } from '../../models/pricing-settings/estimates-settings/estimates-settings';
import { ISaveEstimatesSettingsRequest } from '../../models/pricing-settings/estimates-settings/save-estimates-settings-request';
import { HttpClientBase } from '@lv-core-ui/api';
import { IOtherSettings } from '../../models/company-and-user-settings/other-settings/other-settings';
import { v4 } from 'uuid';
import { ISaveOtherSettingsRequest } from '../../models/request/company-and-user-settings/save-other-settings-request';
import { IOtherEnvironmentSettings } from '../../models/company-and-user-settings/other-settings/other-environment-settings';
import { IPricingEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings';
import { IPricingEnvironmentOrderSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings/setup-settings/pricing-evironment-order-settings';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';
import { LvDateService } from '@lv-core-ui/services';
import { IValuationEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/valuation-environment-settings';
import { ISaveInstrumentSourceSettingsRequest } from '@lv-analytics/models/request/company-and-user-settings/save-instrument-source-settings-request';
import { IBondModelCustomizationSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/model-customization-settings';
import { ISaveBondModelCustomizationSettingsRequest } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/save-model-customization-settings-request';
import { IInstrumentSourceSettings } from '@lv-analytics/models/company-and-user-settings/instrument-source-settings/instrument-source-settings';
import { IEquityVolatilitySettingsContent } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-volatility-settings/equity-volatility-settings-content/equity-volatility-settings-content';
import { ITenor } from '@lv-analytics/models/market-data/tenor';
import { IEquityVolatilitySettingsSaveRequest } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-volatility-settings/equity-volatility-settings';
import { IEquityDividendsSettingsContent } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-dividends-settings/equity-dividends-settings-content/equity-dividends-settings-content';
import { IEquityDividendsSettingsSaveRequest } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-dividends-settings/equity-dividends-settings';
import { IMarketDataBondSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-settings';
import { ISystemYieldCurvesBondSettingsView } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-interest-rates-settings/system-yield-curves-bond-settings-view';

const COMPANY_MARKET_DATA_SETTINGS_PATH = '/marketDataSettings';
const COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH = '/modelCustomizationSettings';
const COMPANY_EQUITY_SETTINGS_PATH = '/equity';
const COMPANY_BOND_SETTINGS_PATH = '/bond';

/**
 * Company and user settings service.
 */
@Injectable()
export class CompanyAndUserSettingsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/analytics/companySettings');
    this._svcInstanceId = v4();
  }

  get instanceId(): string {
    return this._svcInstanceId;
  }

  private _svcInstanceId: string;

  /**
   * Gets available environments.
   * @returns List of IPricingEnvrionment objects.
   */
  async getAvailableEnvrionments(_instrumentType: string): Promise<IPricingEnvrionment[]> {
    try {
      return await this.getAsync<IPricingEnvrionment[]>(null, '/availablePricingEnvironments');            
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets new issue side panel settings.
   * @returns INewIssueSidePanelSettings object.
   */
  async getNewIssueSidePanelSettings(): Promise<INewIssueSidePanelSettings> {
    try {
      const result = await this.getAsync<INewIssueSidePanelSettings>(null, '/newIssueSettings');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets new issue settings.
   * @returns INewIssueSettings object.
   */
  async getNewIssueSettings(): Promise<INewIssueSettings> {
    try {
      const result = await this.getAsync<INewIssueSettings>(null, '/settings/newIssueSettings');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets estimates side panel settings.
   * @param leversysLocalId LWS identifier.
   * @returns IEstimatePanelSettings object.
   */
  async getEstimatesSidePanelSettings(leversysLocalId: string): Promise<IEstimatePanelSettings> {
    try {
      const result = await this.getAsync<IEstimatePanelSettings>(null, `/estimatesSettings/${leversysLocalId}`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets estimates settings.
   * @returns IEstimatesSettings object.
   */
  async getEstimatesSettings(): Promise<IEstimatesSettings> {
    try {
      const result = await this.getAsync<IEstimatesSettings>(null, `/settings/estimatesSettings`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves new issue settings.
   * @param request ISaveNewIssueSettingsRequest object.
   */
  async saveNewIssueSettings(request: ISaveNewIssueSettingsRequest) {
    try {
      await this.postAsync(request, '/newIssueSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Reset new issue settings.
   * @returns INewIssueSettings object.
   */
  async resetNewIssueSettings(): Promise<INewIssueSettings> {
    try {
      const result = await this.getAsync<INewIssueSettings>(null, '/settings/newIssueSettings/default');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves estimates side panel settings.
   * @param request ISaveEstimatesSidePanelSettingsRequest
   */
  async saveEstimatesSidePanelSettings(request: ISaveEstimatesSidePanelSettingsRequest) {
    try {
      await this.postAsync(request, '/estimatesSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves estimates settings.
   * @param request ISaveEstimatesSettingsRequest object.
   */
  async saveEstimatesSettings(request: ISaveEstimatesSettingsRequest) {
    try {
      await this.postAsync(request, '/settings/estimatesSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Resets estimates settings.
   * @returns IEstimatesSettings object.
   */
  async resetEstimatesSettings(): Promise<IEstimatesSettings> {
    try {
      const result = await this.getAsync<IEstimatesSettings>(null, `/settings/estimatesSettings/default`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets market data settings.
   * @returns IMarketDataSettings objects.
   */
  async getMarketDataSettings(): Promise<IMarketDataSettings> {
    try {
      const result = await this.getAsync<IMarketDataSettings>(null, `${COMPANY_MARKET_DATA_SETTINGS_PATH}`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves market data settings.
   * @param request IMarketDataSettings object.
   */
  async saveMarketDataSettings(request: IMarketDataSettings): Promise<void> {
    try {
      await this.postAsync(request, `${COMPANY_MARKET_DATA_SETTINGS_PATH}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets market data settings.
   * @returns IMarketDataSettings object.
   */
  async resetMarketDataSettings(): Promise<IMarketDataSettings> {
    try {
      const result = await this.getAsync<IMarketDataSettings>(null, `${COMPANY_MARKET_DATA_SETTINGS_PATH}/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets model customization settings.
   * @returns IModelCustomizationSettings object.
   */
  async getModelCustomizationSettings(): Promise<IModelCustomizationSettings> {    
    try {
      const result = await this.getAsync<IModelCustomizationSettings>(null, `${COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH}`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save model customization settings.
   * @param request ISaveModelCustomizationSettingsRequest object.
   */
  async saveModelCustomizationSettings(request: ISaveModelCustomizationSettingsRequest): Promise<void> {
    try {
      await this.postAsync(request, `${COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Reset model customization settings.
   * @returns IModelCustomizationSettings object.
   */
  async resetModelCustomizationSettings(): Promise<IModelCustomizationSettings> {
    try {
      const result = await this.getAsync<IModelCustomizationSettings>(null, `${COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH}/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets valuation settings.
   * @returns IValuationSettingsResponse object.
   */
  async getValuationSettings(): Promise<IValuationSettingsResponse> {
    try {
      const result = await this.getAsync<IValuationSettingsResponse>(null, '/valuationSettings');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get valuation environment settings.
   * @returns 
   */
  async getValuationEnvironmentSettings(): Promise<IValuationEnvironmentSettings> {
    try {
      return await this.getAsync<IValuationEnvironmentSettings>(null, '/valuationEnvironmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves valuation settings.
   * @param request ISaveValuationSettingsRequest object.
   */
  async saveValuationSettings(request: ISaveValuationSettingsRequest): Promise<void> {
    try {
      await this.postAsync(request, '/valuationSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets valuation settings.
   * @returns IValuationSettingsResponse object.
   */
  async resetValuationSettings(): Promise<IValuationSettingsResponse> {
    try {
      const result = await this.getAsync<IValuationSettingsResponse>(null, '/valuationSettings/default');
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get other settings from server
   */
  async getOtherSettings(): Promise<IOtherSettings> {
    try {
      return await this.getAsync<IOtherSettings>(null, '/otherSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get instrument source settings from server
   */
  async getInstrumentSourceSettings(): Promise<IInstrumentSourceSettings> {
    try {
      return await this.getAsync<IInstrumentSourceSettings>(null, '/instrumentSourceSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets other settings.
   * @returns IOtherSettings object.
   */
  async resetOtherSettings(): Promise<IOtherSettings> {
    try {
      return await this.getAsync<IOtherSettings>(null, '/otherSettings/default');
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets instrument source settings.
   * @returns Instrument source object.
   */
  async resetInstrumentSourceSettings(): Promise<IInstrumentSourceSettings> {
    try {
      return await this.getAsync<IInstrumentSourceSettings>(null, '/instrumentSourceSettings/default');
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
  /**
   * Get other section settings from server
   */
  async getOtherEnvironmentSettings(): Promise<IOtherEnvironmentSettings> {
    try {
      return await this.getAsync<IOtherEnvironmentSettings>(null, '/otherEnvironmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Send save request and sets sourceId value
   * @param request Save other settings request
   */
  async saveOtherSettings(request: ISaveOtherSettingsRequest): Promise<void> {
    try {
      request.sourceId = this.instanceId;
      await this.postAsync(request, '/otherSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Send save request and sets sourceId value
   * @param request Save instrument source settings request
   */
  async saveInstrumentSourceSettings(request: ISaveInstrumentSourceSettingsRequest): Promise<void> {
    try {
      request.sourceId = this.instanceId;
      await this.postAsync(request, '/instrumentSourceSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get all pricing environments settings
   * @returns Array of pricing enviroments settings
   */
  async getEnvironmentsSettings(): Promise<IPricingEnvironmentSettings[]> {
    try {
      return await this.getAsync<IPricingEnvironmentSettings[]>(null, '/environmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save pricing environment settings
   * @param request Save pricing environment settings request
   */
  async savePricingEnvironmentSettings(settings: IPricingEnvironmentSettings): Promise<void> {
    try {
      await this.putAsync(settings, '/environmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save pricing environment order settings
   * @param request Save pricing environment order settings request
   */
  async savePricingEnvironmentOrderSettings(settings: IPricingEnvironmentOrderSettings): Promise<void> {
    try {
      await this.putAsync(settings, '/environmentSettings/order');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets yield curve settings data.
   * @returns ISystemYieldCurvesSettingsView object.
   */
  async getYieldCurveSettingsData(): Promise<ISystemYieldCurvesSettingsView> {
    try {
      return await this.getAsync<ISystemYieldCurvesSettingsView>(null, '/systemYieldCurveSettingsData');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets all tenors.
   * @returns List of all tenors.
   */
  async getAllTenors(): Promise<ITenor[]> {
    try {
      return await this.getAsync<ITenor[]>(null, '/tenors');
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets Equity Volatility settings.
   * @returns IEquityVolatilitySettingsContent objects.
   */
  async getEquityVolatilitySettings(): Promise<IEquityVolatilitySettingsContent> {
    try {
      const result = await this.getAsync<IEquityVolatilitySettingsContent>(null, `${COMPANY_EQUITY_SETTINGS_PATH}/volatility`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets Equity Volatility settings.
   * @returns IEquityVolatilitySettingsContent object.
   */
  async resetEquityVolatilitySettings(): Promise<IEquityVolatilitySettingsContent> {
    try {
      const result = await this.getAsync<IEquityVolatilitySettingsContent>(null, `${COMPANY_EQUITY_SETTINGS_PATH}/volatility/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves Equity Volatility settings.
   * @param request IEquityVolatilitySettings object.
   */
  async saveEquityVolatilitySettings(request: IEquityVolatilitySettingsContent): Promise<void> {
    try {
      const saveRequest  = {
        settings:{
          systemDefaults: request.systemDefaults,
          environments: request.environments
        }
      } as IEquityVolatilitySettingsSaveRequest;

      await this.postAsync(saveRequest, `${COMPANY_EQUITY_SETTINGS_PATH}/volatility`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets Equity Dividends settings.
   * @returns IEquityDividendsSettingsContent object.
   */
  async getEquityDividendsSettings(): Promise<IEquityDividendsSettingsContent> {
    try {
      const result = await this.getAsync<IEquityDividendsSettingsContent>(null, `${COMPANY_EQUITY_SETTINGS_PATH}/dividends`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets Equity Dividends settings.
   * @returns IEquityDividendsSettings object.
   */
  async resetEquityDividendsSettings(): Promise<IEquityDividendsSettingsContent> {
    try {
      const result = await this.getAsync<IEquityDividendsSettingsContent>(null, `${COMPANY_EQUITY_SETTINGS_PATH}/dividends/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

   /**
   * Saves Equity Volatility settings.
   * @param request IEquityDividendsSettings object.
   */
   async saveEquityDividendsSettings(request: IEquityDividendsSettingsContent): Promise<void> {
    try {
      const saveRequest  = {
        settings:{
          systemDefaults: request.systemDefaults,
          environments: request.environments
        }
      } as IEquityDividendsSettingsSaveRequest;

      await this.postAsync(saveRequest, `${COMPANY_EQUITY_SETTINGS_PATH}/dividends`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets market data bond settings.
   * @returns IMarketDataBondSettings object.
   */
  async resetMarketDataBondSettings(): Promise<IMarketDataBondSettings> {
    try {
      const result = await this.getAsync<IMarketDataBondSettings>(null, `${COMPANY_BOND_SETTINGS_PATH}/marketDataSettings/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets bond market data settings.
   * @returns IMarketDataBondSettings objects.
   */
  async getMarketDataBondSettings(): Promise<IMarketDataBondSettings> {
    try {
      const result = await this.getAsync<IMarketDataBondSettings>(null, `${COMPANY_BOND_SETTINGS_PATH}/marketDataSettings`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves bond market data settings.
   * @param request IMarketDataSettings object.
   */
  async saveMarketDataBondSettings(request: IMarketDataBondSettings): Promise<void> {
    try {
      await this.postAsync(request, `${COMPANY_BOND_SETTINGS_PATH}/marketDataSettings`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets bond yield curve settings data.
   * @returns ISystemYieldCurvesBondSettingsView object.
   */
  async getBondYieldCurveSettingsData(): Promise<ISystemYieldCurvesBondSettingsView> {
    try {
      return await this.getAsync<ISystemYieldCurvesBondSettingsView>(null, `${COMPANY_BOND_SETTINGS_PATH}/systemYieldCurveSettingsData`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets model customization bond settings.
   * @returns IBondModelCustomizationSettings object.
   */
   async getModelCustomizationBondSettings(): Promise<IBondModelCustomizationSettings> {
    try {
      const result = await this.getAsync<IBondModelCustomizationSettings>(null, `${COMPANY_BOND_SETTINGS_PATH}/modelCustomizationSettings`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save model customization bond settings.
   * @param request ISaveBondModelCustomizationSettingsRequest object.
   */
  async saveModelCustomizationBondSettings(request: ISaveBondModelCustomizationSettingsRequest): Promise<void> {
    try {
      await this.postAsync(request, `${COMPANY_BOND_SETTINGS_PATH}/modelCustomizationSettings`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Reset model customization bond settings.
   * @returns IModelCustomizationSettings object.
   */
  async resetModelCustomizationBondSettings(): Promise<IBondModelCustomizationSettings> {
    try {
      const result = await this.getAsync<IBondModelCustomizationSettings>(null, `${COMPANY_BOND_SETTINGS_PATH}/modelCustomizationSettings/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets Asw market data settings.
   * @returns IMarketDataSettings objects.
   */
  async getMarketDataAswSettings(): Promise<IMarketDataSettings> {
    try {
      const result = await this.getAsync<IMarketDataSettings>(null, `/asw/marketDataSettings`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves Asw market data settings.
   * @param request IMarketDataSettings object.
   */
  async saveMarketDataAswSettings(request: IMarketDataSettings): Promise<void> {
    try {
      await this.postAsync(request, `/asw/marketDataSettings`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets Asw market data settings.
   * @returns IMarketDataSettings object.
   */
  async resetMarketDataAswSettings(): Promise<IMarketDataSettings> {
    try {
      const result = await this.getAsync<IMarketDataSettings>(null, `/asw/marketDataSettings/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  
  /**
   * Gets Asw model customization settings.
   * @returns IModelCustomizationSettings object.
   */
  async getModelCustomizationAswSettings(): Promise<IModelCustomizationSettings> {    
    try {
      const result = await this.getAsync<IModelCustomizationSettings>(null, `/asw/modelCustomizationSettings`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save Asw model customization settings.
   * @param request ISaveModelCustomizationSettingsRequest object.
   */
  async saveModelCustomizationAswSettings(request: ISaveModelCustomizationSettingsRequest): Promise<void> {
    try {
      await this.postAsync(request, `/asw/modelCustomizationSettings`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Reset Asw model customization settings.
   * @returns IModelCustomizationSettings object.
   */
  async resetModelCustomizationAswSettings(): Promise<IModelCustomizationSettings> {
    try {
      const result = await this.getAsync<IModelCustomizationSettings>(null, `/asw/modelCustomizationSettings/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets Asw new issue settings.
   * @returns INewIssueSettings object.
   */
  async getNewIssueAswSettings(): Promise<INewIssueSettings> {
    try {
      const result = await this.getAsync<INewIssueSettings>(null, '/asw/settings/newIssueSettings');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves Asw new issue settings.
   * @param request ISaveNewIssueSettingsRequest object.
   */
  async saveNewIssueAswSettings(request: ISaveNewIssueSettingsRequest) {
    try {
      await this.postAsync(request, '/asw/newIssueSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Reset Asw new issue settings.
   * @returns INewIssueSettings object.
   */
  async resetNewIssueAswSettings(): Promise<INewIssueSettings> {
    try {
      const result = await this.getAsync<INewIssueSettings>(null, '/asw/settings/newIssueSettings/default');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets Asw estimates settings.
   * @returns IEstimatesSettings object.
   */
  async getEstimatesAswSettings(): Promise<IEstimatesSettings> {
    try {
      const result = await this.getAsync<IEstimatesSettings>(null, `/asw/settings/estimatesSettings`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves Asw estimates settings.
   * @param request ISaveEstimatesSettingsRequest object.
   */
  async saveEstimatesAswSettings(request: ISaveEstimatesSettingsRequest) {
    try {
      await this.postAsync(request, '/asw/settings/estimatesSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Resets Asw estimates settings.
   * @returns IEstimatesSettings object.
   */
  async resetEstimatesAswSettings(): Promise<IEstimatesSettings> {
    try {
      const result = await this.getAsync<IEstimatesSettings>(null, `/asw/settings/estimatesSettings/default`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }
}