<lv-flex-box class="lv-coupon-floating">
    <lv-flex-box direction="row" >
      <lv-flex-box class="lv-floating-coupon-first-col">
        <lv-flex-box direction="row" *ngIf="isfloatingToFixPrFixToFloatingCouponType" data-cy="DM-961">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponFloatingDayCountSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Coupon Day Count
            </span>
            <lv-xl-label [field]="'CPN_FLOAT_DAY_COUNT'"></lv-xl-label>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
              name="terms-general-coupon-flaoting-coupon-day-count"
              lvId="floatingCouponDayCount"
              [data]="couponDayCount.items"
              valueField="id"
              textField="text"
              valuePrimitive="true"
              [(ngModel)]="model.dayCount"
              (ngModelChange)="onFloatingCouponChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" *ngIf="isfloatingToFixPrFixToFloatingCouponType" data-cy="DM-962">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="couponFloatingFrequencySourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Coupon Frequency
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_FQ'"></lv-xl-label>
            </div>
            <div class="lv-input-field ">
              <kendo-dropdownlist
                name="terms-general-coupon-floating-frequency"
                [data]="couponFrequency.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [(ngModel)]="model.frequency"
                (ngModelChange)="onFloatingCouponChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-2387">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="couponIndexSourceTooltip"
              filter="span">
              <span>
                Coupon Index
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_INDEX'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-input-field--full">
              <lv-text-box
                class="lv-floating-coupon-index"
                name="terms-general-coupon-floating-index"
                [(ngModel)]="model.index.name"
                (didKeyDownEnter)="onFloatingCouponChange()"
                (didBlur)="onFloatingCouponChange()">
              </lv-text-box>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-963">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="referenceTenorSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Reference Tenor
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_REF_TENOR'"></lv-xl-label>
            </div>
            <div class="lv-input-field ">
              <kendo-dropdownlist
                name="terms-general-coupon-floating-reference-tenor"
                lvId="referenceTenor"
                [data]="referenceTenor.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [(ngModel)]="model.referenceTenor"
                (ngModelChange)="onFloatingCouponChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-964">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fixingDelaySourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Fixing Delay
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_REF_FIXNG_DLY'"></lv-xl-label>
            </div>
            <lv-numeric-text-box
              class="lv-input-field"
              [format]="formatZero"
              [decimals]="decimalsZero"
              name="terms-general-coupon-floating-fixing-delay"
              lvId="fixingDelay"
              [(ngModel)]="model.fixingDelay"
              (didBlur)="onFloatingCouponChange()"
              (didKeyDownEnter)="onFloatingCouponChange()">
            </lv-numeric-text-box>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-965">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="multiplierSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Multiplier
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_MLTPLR'"></lv-xl-label>
            </div>
            <lv-numeric-text-box
              class="lv-input-field"
              [format]="formatFour"
              [decimals]="decimalsFour"
              name="terms-general-coupon-floating-multiplier"
              [(ngModel)]="model.multiplier"
              (didBlur)="onFloatingCouponChange()"
              (didKeyDownEnter)="onFloatingCouponChange()">
            </lv-numeric-text-box>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-966">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="spreadSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Spread
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_SPRD'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="terms-general-coupon-floating-spread"
                [format]="formatFour"
                [decimals]="decimalsFour"
                [(ngModel)]="model.spread"
                (didBlur)="onFloatingCouponChange()"
                (didKeyDownEnter)="onFloatingCouponChange()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-967">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="floorSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Floor
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_FLOOR'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
            <lv-numeric-text-box
              name="terms-general-coupon-floating-floor"
              [format]="formatFour"
              [decimals]="decimalsFour"
              [(ngModel)]="model.floor"
              (didBlur)="onFloatingCouponChange()"
              (didKeyDownEnter)="onFloatingCouponChange()">
            </lv-numeric-text-box>
            <span class="lv-sufix-label">%</span>
          </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-968">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="capSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Cap
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_CAP'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
            <lv-numeric-text-box
              name="terms-general-coupon-floating-cap"
              [format]="formatFour"
              [decimals]="decimalsFour"
              [(ngModel)]="model.cap"
              (didBlur)="onFloatingCouponChange()"
              (didKeyDownEnter)="onFloatingCouponChange()">
            </lv-numeric-text-box>
            <span class="lv-sufix-label">%</span>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-970">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="useSpreadScheduleSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Use Spread Schedule
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_SPREAD_SCHED'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input class="k-checkbox"
                type="checkbox"
                [attr.id]="floatingSpreadScheduleId"
                name="terms-general-coupon-floating-use-spread-schedule"
                [(ngModel)]="model.useFloatingSpreadSchedule"
                (ngModelChange)="onFloatingCouponChange()">
              <label class="k-checkbox-label"
                [attr.for]="floatingSpreadScheduleId">
              </label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-969">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="useCustomDatesSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Use Custom Dates
              </span>
              <lv-xl-label [field]="'CPN_FLOAT_DAYS_SCHED'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input class="k-checkbox"
                type="checkbox"
                [attr.id]="floatingCustomDatesId"
                name="terms-general-floating-coupon-use-custom-dates"
                [(ngModel)]="model.useFloatingCustomDates"
                (ngModelChange)="onFloatingCouponChange()">
              <label class="k-checkbox-label"
                [attr.for]="floatingCustomDatesId">
              </label>
            </div>
          </lv-flex-box>
      </lv-flex-box>
      <lv-flex-box>
        <lv-flex-box direction="row">
          <div showAfter="1500"
            kendoTooltip 
            [tooltipTemplate]="fixingHistoryScheduleTooltip" 
            class="lv-label lv-label-field lv-label--title"
            filter=".lv-label">FIXING HISTORY</div>
        </lv-flex-box>
        <lv-flex-box direction="row">
          <lv-bond-floating-fixing-history-schedule #floatingFixingHistorySchedule
            [model]="model.fixingHistoryItems"
            (didFixingHistoryScheduleChange)="onFloatingCouponChange()">
          </lv-bond-floating-fixing-history-schedule>
        </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box class="lv-floating-bottom-section" direction="row">
        <lv-flex-box class="lv-floating-spread-schedule" *ngIf="model.useFloatingSpreadSchedule">
          <lv-flex-box direction="row">
            <div showAfter="1500"
            kendoTooltip 
            [tooltipTemplate]="spreadScheduleTooltip"
            class="lv-label lv-label-field lv-label--title"
            filter=".lv-label">SPREAD SCHEDULE</div>
          </lv-flex-box>
          <lv-bond-floating-spread-schedule #floatingSpreadSchedule
            [model]="model.floatingSpreadSchedule"
            (didFloatingSpreadScheduleChange)="onFloatingCouponChange()">
          </lv-bond-floating-spread-schedule>
        </lv-flex-box>
        <lv-flex-box  *ngIf="model.useFloatingCustomDates">
          <lv-flex-box direction="row">
            <div showAfter="1500"
            kendoTooltip 
            [tooltipTemplate]="customDatesScheduleTooltip"
            class="lv-label lv-label-field lv-label--title"
            filter=".lv-label">CUSTOM DATES</div>
          </lv-flex-box>
          <lv-bond-floating-custom-dates-schedule #floatingCustomDatesSchedule
            [model]="model.floatingCustomDates"
            (didFloatingCustomDatesScheduleChange)="onFloatingCouponChange()">
          </lv-bond-floating-custom-dates-schedule>
        </lv-flex-box>
      </lv-flex-box>
</lv-flex-box>

<ng-template #couponFloatingDayCountSourceTooltip>
    <lv-tooltip dm="dM-961"></lv-tooltip>
  </ng-template>
  <ng-template #couponFloatingFrequencySourceTooltip>
    <lv-tooltip dm="dM-962"></lv-tooltip>
  </ng-template>
  <ng-template #couponIndexSourceTooltip>
    <lv-tooltip dm="dM-2387"></lv-tooltip>
  </ng-template>
  <ng-template #referenceTenorSourceTooltip>
    <lv-tooltip dm="dM-963"></lv-tooltip>
  </ng-template>
  <ng-template #fixingDelaySourceTooltip>
    <lv-tooltip dm="dM-964"></lv-tooltip>
  </ng-template>
  <ng-template #multiplierSourceTooltip>
    <lv-tooltip dm="dM-965"></lv-tooltip>
  </ng-template>
  <ng-template #spreadSourceTooltip>
    <lv-tooltip dm="dM-966"></lv-tooltip>
  </ng-template>
  <ng-template #floorSourceTooltip>
    <lv-tooltip dm="dM-967"></lv-tooltip>
  </ng-template>
  <ng-template #capSourceTooltip>
    <lv-tooltip dm="dM-968"></lv-tooltip>
  </ng-template>
  <ng-template #useSpreadScheduleSourceTooltip>
    <lv-tooltip dm="dM-970"></lv-tooltip>
  </ng-template>
  <ng-template #useCustomDatesSourceTooltip>
    <lv-tooltip dm="dM-969"></lv-tooltip>
  </ng-template>
  <ng-template #fixingHistoryScheduleTooltip>
    <lv-tooltip dm="dM-984"></lv-tooltip>
  </ng-template>
  <ng-template #spreadScheduleTooltip>
    <lv-tooltip dm="dM-983"></lv-tooltip>
  </ng-template>
  <ng-template #customDatesScheduleTooltip>
    <lv-tooltip dm="dM-985"></lv-tooltip>
  </ng-template>
  
