import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { ITenor } from '@lv-analytics/models';
import { IBucketingSectionSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/bucketing/bucketing-section-settings';
import { IBucketingSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/bucketing/bucketing-settings';

/**
 * Bucketing settings component.
 */
@Component({
  selector: 'lv-bucketing-settings',
  templateUrl: './lv-bucketing-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBucketingSettingsComponent {

  @Input() settings: IBucketingSettings;
  @Input() tenors: ITenor[];
  @Output() listChanged: EventEmitter<void>;

  hasEnvironmentSettings: boolean;

  constructor () {
    this.settings = {
      systemDefaults: {
        bucketList: [],
      } as IBucketingSectionSettings,
      environments: []
    };
    this.hasEnvironmentSettings = false;
    this.tenors = [];
    this.listChanged = new EventEmitter();
  }

  @HostBinding('class.lv-bucketing-settings')
  get isLvBucketingSettingsComponent() {
    return true;
  }

  onListChanged() {
    this.listChanged.next();
  }
}
