import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, Input, OnInit, Optional, ViewEncapsulation, inject } from '@angular/core';
import { CallMakeWholeType, CallMakeWholeTypeBondDescription, DiscountRateType } from '@lv-instrument-common/index';
import { v4 } from 'uuid';
import { LvBondTermsPresenter } from '../lv-bond-terms.presenter';
import { Subscription, filter } from 'rxjs';
import { BondTermsSectionEvent } from '../../../models/bond-terms/enums/bond-terms-section-events';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BondTermsDocument } from '../../../models/bond-terms/BondTermsDocument';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';
import { BondTermsService } from '../../../services/bond-terms.services';
import { BondGeneral } from '../../../models';
import { CallMakeWholeData } from '../../../models/bond-terms/BondCall';

@Component({
  selector: 'lv-bond-make-whole',
  templateUrl: './lv-bond-make-whole.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBondMakeWholeComponent implements OnInit {

  get callMakeWholeCheckBoxEnabled() {
    return !this._presenter.isMakeWholeEnabled();
  }

  get couponsIncludedUntil(): Date {
    return this._couponsIncludedUntil;
  }

  set couponsIncludedUntil(value: Date) {
    this._couponsIncludedUntil = value;

    if (this.model && this.couponsIncludedUntilEnabled) {
      this.model.couponsIncludedUntil = value;
    }
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;

    if (this.model && this.startDateEnabled) {
      this.model.startDate = value;
    }
  }

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;

    if (this.model && this.endDateEnabled) {
      this.model.endDate = value;
    }
  }

  get isExcelInstrument(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get couponIncludedUntil(): string {
    return this.isExcelInstrument ? 'Coupons Incl. Until' : 'Coupons Included Until';
  }

  formatZero = 'n0';
  decimalsZero = '0';
  formatNumber = '#,###.###';
  decimalsNumber = '3';
  makeWholeTypeLookup: LvLookupEnum;
  discountRateTypeLookup: LvLookupEnum;
  couponsIncludedUntilEnabled: boolean;
  startDateEnabled: boolean;
  endDateEnabled: boolean;
  couponsIncludedUntilVisible: boolean;
  discountFieldsVisible: boolean;
  initialPremiumVisible: boolean;
  callMakeWholeCheckBoxId: string;
  dpStartDateCheckBoxId: string;
  dpStartDatePickerId: string;
  dpEndDateCheckBoxId: string;
  dpEndDatePickerId: string;

  private _couponsIncludedUntil: Date;
  private _startDate: Date;
  private _endDate: Date;
  private _modelSubscription: Subscription;
  private _destroyRef = inject(DestroyRef)

  @Input() model: CallMakeWholeData;
  @Input() recieveMakeWhole: boolean;
  @Input() isCallable: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,
    private _lvBondTermsService: BondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.callMakeWholeCheckBoxId = v4();
    this.dpEndDateCheckBoxId = 'cmwEndDateCheckBoxId';
    this.dpEndDatePickerId = 'cmwEndDatePickerId';
    this.dpStartDateCheckBoxId = 'cmwStartDateCheckBoxId';
    this.dpStartDatePickerId = 'cmwStartDatePickerId';
    this.couponsIncludedUntilEnabled = false;
    this.startDateEnabled = false;
    this.endDateEnabled = false;
    this.makeWholeTypeLookup = new LvLookupEnum(CallMakeWholeTypeBondDescription);
    this.discountRateTypeLookup = new LvLookupEnum(DiscountRateType);
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef)).pipe(
      filter(event => event === BondTermsSectionEvent.CallIssueAndRedemptionEvent ||
        event === BondTermsSectionEvent.TermsGeneralDatesEvent || event === BondTermsSectionEvent.ModelLoadedEvent)
    ).subscribe(event => {
      this.model = this._presenter.getModel().call?.callMakeWhole;
      this.recieveMakeWhole = this._presenter.getModel().call?.recieveMakeWhole;
      this.setMWDates(this._presenter.getModel().general);
      this.setFieldsVisibility();
      this._changeDetectorRef.detectChanges();
    });
    this.setMWDates(this._presenter.getModel().general);
    this.setFieldsVisibility();
  }

  /**
   * Fired when call make whole model changed
   */
  callMakeWholeChange() {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.call.callMakeWhole = this.model;
      terms.call.recieveMakeWhole = this.recieveMakeWhole;
    }, BondTermsSectionEvent.MWCallsEvent,
      'other');
  }

  /**
   * Set dates based on First Settlement and Maturity dates
   */
  setMWDates(issueAndRedemption: BondGeneral) {

    const makeWholeSettings = this._lvBondTermsService.makeWholeSettings;

    if (!this.model.couponsIncludedUntil) {
      this._couponsIncludedUntil = issueAndRedemption.maturityDate;
      this.couponsIncludedUntilEnabled = false;
    } else {
      this._couponsIncludedUntil = this.model.couponsIncludedUntil;
      this.couponsIncludedUntilEnabled = true;
    }

    if (!this.model.startDate) {
      this._startDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, makeWholeSettings?.startDate);
      this.startDateEnabled = false;
    } else {
      this._startDate = this.model.startDate;
      this.startDateEnabled = true;
    }

    if (!this.model.endDate) {
      this._endDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -makeWholeSettings?.endDate);
      this.endDateEnabled = false;
    } else {
      this._endDate = this.model.endDate;
      this.endDateEnabled = true;
    }
  }

  /**
 * Fired when startDateCheckbox changed
 * @param value
 */
  startDateCheckboxChange(value: boolean): void {
    this.startDateEnabled = value;

    if (this.model) {
      this.model.startDate = value ? this._startDate : null;
    }

    this.onMakeWholeChange();
  }

  /**
   * Fired when endDateCheckbox changed
   * @param value
   */
  endDateCheckboxChange(value: boolean): void {
    this.endDateEnabled = value;

    if (this.model) {
      this.model.endDate = value ? this._endDate : null;
    }

    this.onMakeWholeChange();
  }

  /**
   * Fired when couponsIncludedUntilCheckbox changed
   * @param value
   */
  couponsIncludedUntilCheckboxChange(value: boolean): void {
    this.couponsIncludedUntilEnabled = value;
    if (this.model) {
      this.model.couponsIncludedUntil = value ? this._couponsIncludedUntil : null;
    }
    this.onMakeWholeChange();
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
  getCallMakeWholeTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  /**
   * Fired when MakeWhole model changed
   */
  onMakeWholeChange() {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.call.callMakeWhole = this.model;
      terms.call.recieveMakeWhole = this.recieveMakeWhole;
    }, BondTermsSectionEvent.MakeWholeEvent,
      'other');
  }

  /**
   * Fired when MakeWhole Type changed
   */
  makeWholeTypeChanged() {
    this.setFieldsVisibility();
    this.onMakeWholeChange();
  }

  /**
   * Set fields visibility based on coupon type
   */
  private setFieldsVisibility() {
    if (this.model && this.model.type) {
      this.couponsIncludedUntilVisible = this.model.type === CallMakeWholeType.Coupon ||
        this.model.type === CallMakeWholeType.CouponPV;
      this.discountFieldsVisible = this.model.type === CallMakeWholeType.CouponPV;
      this.initialPremiumVisible = this.model.type === CallMakeWholeType.Premium ||
        this.model.type === CallMakeWholeType.PremiumLinDecreasing;
    }
  }
}

