import { ChangeDetectionStrategy, Component, HostBinding, ElementRef, OnInit, ViewEncapsulation,
  ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { IInstrumentSourceSectionSettings, IInstrumentSourceSettings } from '@lv-analytics/models';
import { ISaveInstrumentSourceSettingsRequest } from '@lv-analytics/models/request/company-and-user-settings/save-instrument-source-settings-request';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import * as _ from 'lodash';

/**
 * Instrument source settings component.
 */
@Component({
  selector: 'lv-instrument-source-settings',
  templateUrl: './lv-instrument-source-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentSourceSettingsComponent implements OnInit, IDiscardable {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;

  settings: IInstrumentSourceSettings;
  originalSettings: IInstrumentSourceSettings;

  constructor(
    private _discardChangesService: DiscardChangesService,
    private _errorService: LvErrorService,
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {} as IInstrumentSourceSectionSettings,
      environments: []
    } as IInstrumentSourceSettings;
    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('ISIN', this);
  }

  @HostBinding('class.lv-section-settings-instrument-source')
  get isSectionSettingsAdditionalPaddingBottom() {
    return true;
  }

  @HostBinding('class.lv-section-settings-instrument-source-settings-padding-margin')
  get isSectionSettingsAdditionalPaddingTop() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit(): void {
    this.loadSettings();
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * Save instrument source settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next();

      await this._companyAndUserSettingsService.saveInstrumentSourceSettings({
        settings: this.settings
      } as ISaveInstrumentSourceSettingsRequest);
      this.originalSettings = _.cloneDeep(this.settings);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'ISIN'}));

      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Resets other settings.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();

      const response = await this._companyAndUserSettingsService.resetInstrumentSourceSettings();
      this.settings = response;

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'ISIN'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Loads settings.
   */
  private async loadSettings() {
    try {
      this.startLoading.next();

      const response = await this._companyAndUserSettingsService.getInstrumentSourceSettings();
      this.settings = response;
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
  getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }
}
