import { DividendsSourceEnum } from 'src/app/modules/analytics/models/market-data/dividends/enum/dividends-source-enum';
import { VolatilityType } from 'src/app/modules/analytics/models/market-data/volatility/volatility-enums';
import { BorrowSource } from 'src/app/modules/analytics/models/market-data/borrow/borrow-enum';
import { CreditSource } from 'src/app/modules/analytics/models/market-data/credit/enums/credit-source';
import { InterestRateSource } from 'src/app/modules/analytics/models/market-data/interest-rates/interest-rates-enum';

export const marketData = {
  borrowSource: BorrowSource.Flat,
  creditSource: CreditSource.FlatSpread,
  dividendSource: DividendsSourceEnum.NoDividends,
  downsideVol: null,
  flatBorrow: null,
  flatCreditSpread: null,
  flatDividendYield: 20,
  flatInterestRate: null,
  flatVolatility: null,
  interestRateSource: InterestRateSource.YieldCurve,
  upsideVol: null,
  volatilitySource: VolatilityType.Flat
};
