<lv-flex-box class="lv-convertible-bond-terms-identifiers">
	<ng-container>
		<lv-flex-box class="lv-convertible-bond-terms-identifiers-read-only">
			<lv-flex-box direction="row"
				showAfter="1500"
				kendoTooltip
				[tooltipTemplate]="leversysIDTooltip"
				filter=".lv-convertible-bond-terms-identifiers-read-only-description">
				<label class="lv-convertible-bond-terms-identifiers-read-only-label
                    lv-bond-terms-identifiers-read-only-label
					lv-convertible-bond-terms-identifiers-read-only-description">
					Leversys ID
				</label>
				<label class="lv-convertible-bond-terms-identifiers-read-only-label
                    lv-bond-terms-identifiers-read-only-label
					lv-convertible-bond-terms-identifiers-read-only-value">
					{{leversysId}}
				</label>
			</lv-flex-box>
		</lv-flex-box>
		<lv-flex-box direction="row" class="lv-bond-identifiers">
			<lv-advanced-grid
				data-cy="DM-537"
				[columns]="columns"
				[records]="identifiersItems"
				[createFormGroup]="createFormGroup"
				[parseFn]="parseFn"
				lvId="lvIdentifiersSchedule"
				class="lv-identifiers-schedule">
			</lv-advanced-grid>
		</lv-flex-box>
	</ng-container>
</lv-flex-box>

<ng-template #leversysIDTooltip>
    <lv-tooltip dm="dM-336"></lv-tooltip>
</ng-template>
