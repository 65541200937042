import { v4 } from 'uuid';
// tslint:disable-next-line:max-line-length
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ElementRef, ViewChild, Input, DestroyRef } from '@angular/core';

import { filter, Subscription } from 'rxjs';

import { DifferentDeltaExecutionView } from '../../views/different-delta-execution';
import { LvAnalyticsPresenter } from '../../lv-analytics.presenter';
import { LvDateService, ResizeHandlerService } from '@lv-core-ui/services';
import { IDifferentDeltaExecutionState, AnalyticsEvents, AnalyticsCommands, AnalyticsSettingsEvents } from '@lv-analytics/models';
import { SectionUsed, IToolsLogEvent } from '@lv-shared/models';
import { LvSharedUserActivityService } from '@lv-shared/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Different delta execution component.
 */
@Component({
  selector: 'lv-different-delta-execution',
  templateUrl: './lv-different-delta-execution.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDifferentDeltaExecutionComponent implements OnInit, OnDestroy {

  @ViewChild('differentDeltaExecution', { static: true }) differentDeltaExecutionElement: ElementRef;

  @Input()
  set state(value: IDifferentDeltaExecutionState) {
    this._state = value;
  }
  _state: IDifferentDeltaExecutionState;

  bpCommision: string;
  perShareCommision: string;
  view: DifferentDeltaExecutionView;
  isLoading: boolean;
  isNarrow: boolean;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvAnalyticsPresenter,
    private _resizeHandlerService: ResizeHandlerService,
    private _userActivityService: LvSharedUserActivityService,
    private _lvDateService: LvDateService,
    private _destroyRef: DestroyRef,
  ) {
      this.state = {} as IDifferentDeltaExecutionState;
      this.view = new DifferentDeltaExecutionView(_lvDateService);
      this.isLoading = false;
      this.bpCommision = v4();
      this.perShareCommision = v4();
      this.isNarrow = false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._presenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
            this.view.init(this._presenter.getModelData());
        }
        else {
          this.view.init(null);
        }

        this._changeDetectorRef.detectChanges();
      }),

      this._presenter.onPricingModelUpdated.subscribe(evt => {
        if (evt) {
            this.view.onPricingUpdated(evt.data.pricing);
            this.onDifferentDeltaFieldChange(true);
            this._changeDetectorRef.detectChanges();
          }
      }),

      this._presenter.onEventPublished.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsEvents.ValuationCompleted) {
            this.view.onValuationUpdate(evt.data);
            this._changeDetectorRef.detectChanges();
          }
        }
      }),

      this._presenter.onCommandExecuted.subscribe(command => {
        if (command === AnalyticsCommands.ReloadPricingAndTools
          || command === AnalyticsCommands.ReloadAssumptionsAndToolsAndAnalysis) {
            this.view.init(this._presenter.getModelData());
          }
      }),
      this._presenter.onEventPublished.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsSettingsEvents.OtherSettingsUpdated) {
            this.view.init(this._presenter.getModelData());
          }
        }
      })
    ];

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(async event => {
        this.view.init(this._presenter.getModelData());
        this._changeDetectorRef.detectChanges();
      });

    this._presenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event?.eventId === 'ValuationSettingsModelUpdated'))
      .subscribe(evt => {
        this.view.init(this._presenter.getModelData());
        this._changeDetectorRef.detectChanges();
      });

    if (this._presenter.isConvertibleModelLoaded()) {
      this.view.init(this._presenter.getModelData());
      this._changeDetectorRef.detectChanges();
    }

    this._resizeHandlerService.listenTo(this.differentDeltaExecutionElement, elementRect => {
      this.isNarrow = elementRect.width < 473;
      this._changeDetectorRef.detectChanges();
    });
  }

  /**
   * Occurs on different delta field change and calculates different delta execution.
   * @param isFromEvent A flag indicating if event should be logged in user activity.
   */
  onDifferentDeltaFieldChange(isFromEvent: boolean = false) {
    this.view.calculateDifferentDeltaExecution();

    if (!isFromEvent) {
      this.logEventToUsetActivity();
    }
  }

  /**
   * Occurs on stock ref EQ change.
   */
  onStockRefEQChange() {
    this.view.stockRefEQChange();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on stock ref CB change.
   */
  onStockRefCBChange() {
    this.view.stockRefCBChange();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on live price EQ change.
   */
  onLivePriceEQChange() {
    this.view.livePriceEQChange();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on live price CB change.
   */
  onLivePriceCBChange() {
    this.view.livePriceCBChange();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on cross FXT0 change.
   */
  onCrossFXT0Change() {
    this.view.crossFXT0Change();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on cross FXT1 change.
   */
  onCrossFXT1Change() {
    this.view.crossFXT1Change();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on commision change.
   */
  onCommisionChange() {
    this.view.calculateDifferentDeltaExecution();
    this.logEventToUsetActivity();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Logs event to user activity.
   */
  private logEventToUsetActivity() {
    this._userActivityService.logToolsEvent({
      sectionUsed: SectionUsed.DIFFERENT_DELTA_EXECUTION,
      bondCountry: this.view.convertibleCountryCode,
      bondType: this.view.convertibleStatus,
      isPrivateInstrument: this.view.isPrivate
    } as IToolsLogEvent);
  }
}
