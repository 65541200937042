export const pricing = {
  crEstimateSettings: {useEstimatedRatio: false, showConversionPrice: false, selectedCurrency: 'ConvertibleCurrency'},
  crossFx: 1,
  crossFxDate: null,
  dateUpdated: null,
  newIssueAssumptions: null,
  price: null,
  stockPriceCbCcy: null,
  stockPriceUndCcy: null,
  valuationDate: new Date('Thu Sep 10 2020 13:12:05 GMT+0200')
};
