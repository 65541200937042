import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { ILeversysDataSyncingSettings } from '@lv-reference-data/models';
import { LeversysReferenceDataSettingsService } from '@lv-reference-data/services/reference-data-settings/reference-settings.service';
import _ from 'lodash';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-leversys-data-syncing-settings',
  templateUrl: './lv-leversys-data-syncing-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvLeversysDataSyncingSettingsComponent implements OnInit, IDiscardable {
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  syncAllEquityData: ILeversysDataSyncingSettings;
  originalSyncAllEquityData: ILeversysDataSyncingSettings;

  syncAllEquityDataCheckboxId: string;

  constructor (private _leversysReferenceDataService: LeversysReferenceDataSettingsService,
               private _errorService: LvErrorService,
               private _changeDetectorRef: ChangeDetectorRef,
               private _discardChangesService: DiscardChangesService,
              ) {
    
      this.startLoading = new EventEmitter<void>();
      this.endLoading = new EventEmitter<void>();
      this.didCancel = new EventEmitter<void>();          
      this.syncAllEquityDataCheckboxId = v4();

    this.syncAllEquityData = {
      syncAllEquityData: false
    }as ILeversysDataSyncingSettings;

    this._discardChangesService.register('Leversys Data Syncing', this);
  }

  @HostBinding('class.lv-leversys-data-syncing-settings')
  get isLvLeversysDataSyncingComponent() {
    return true;
  }

  ngOnInit(): void {
    this.getAllLeversysDataSync();
  }
  
   /**
   * Occurs on cancel.
   */
   onCancel() {
    this.didCancel.next();
  }

  /**
   * Get all Leversys Data Sync
   */
  async getAllLeversysDataSync() {
    try {
      this.startLoading.next();
      this.syncAllEquityData = await this._leversysReferenceDataService.getAllLeversysDataSyncing();
      this.originalSyncAllEquityData = _.cloneDeep(this.syncAllEquityData);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

   /**
   * Resets All Leversys Data Sync to default.
   */
   async onResetToDefault() {
    try {
      this.startLoading.next();
      this.syncAllEquityData = await this._leversysReferenceDataService.resetAllLeversysDataSyncing();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Leversys Data Syncing'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

   /**
   * Occurs on save and saves All Leversys Data Sync.
   */
   async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
      await this._leversysReferenceDataService.saveAllLeversysDataSyncing(this.syncAllEquityData)
      this.originalSyncAllEquityData = _.cloneDeep(this.syncAllEquityData);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'Leversys Data Syncing'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.syncAllEquityData, this.originalSyncAllEquityData);
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.syncAllEquityData = _.cloneDeep(this.originalSyncAllEquityData);
    this._changeDetectorRef.detectChanges();
  }

}
