import { ConvertibleBondSubType, EquityDataSectionSettings, ExchangeableType, IGeneralSectionSettings, SetupStatusQuickTerms } from '@lv-convertible-bond/models';
import { RecoveryUponDefault } from '@lv-instrument-common/index';

export const getDefaultGeneralSectionSettings = (): IGeneralSectionSettings => {
  return {
    underlyingEquity: {
      currencyCode : 'USD',
    } as EquityDataSectionSettings,
    subType: ConvertibleBondSubType.Regular,
    currencyCode: 'EUR',
    maturingCurrencyCode: 'EUR',
    nominalValue: 100,
    issueValue: 100,
    redemptionValue: 100,
    isPriceAsPar: true,
    isQuotedClean: true,
    issueValueIsPercentOfNominal: true,
    redemptionValueIsPercentOfNominal: true,
    isAccruedUponDefault: false,
    recoveryUponDefault: RecoveryUponDefault.AtPar,
    percCoveredWarrants: 100,
    daysToSettle: 0,
    exhangeableParameters: {
        exhangeableType: ExchangeableType.NonExchangeable,
        percentOfPledgedShares: 100,
    },
    status: SetupStatusQuickTerms.NewIssue
  };
};
