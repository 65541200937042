import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { PikCouponData } from '@lv-instrument-common/PIKCouponData';
import { LvLookupEnum } from '@lv-core-ui/util';
import { PikCouponType, PikCouponTypeDescription } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-pik-coupon',
  templateUrl: './lv-pik-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPikCouponComponent implements OnInit {

  @Input() model: PikCouponData;
  @Output() didPikCouponChange: EventEmitter<PikCouponData>;

  formatFour = '#,###.####';
  decimalsFour = '4';

  get isCustomPikCouponType(): boolean {
    return this.model.pikCouponType === PikCouponType.Custom;
  }

  get isPikOnlyPikCouponType(): boolean {
    return this.model.pikCouponType === PikCouponType.PIKOnly;
  }

  get isNonePikCouponType(): boolean {
    return this.model.pikCouponType === PikCouponType.None;
  }

  pikCouponTypeLookup: LvLookupEnum;

  constructor() {
    this.pikCouponTypeLookup = new LvLookupEnum(PikCouponTypeDescription);
    this.didPikCouponChange = new EventEmitter<PikCouponData>();
  }

  ngOnInit() {
  }

  onPikCouponChange() {
    this.didPikCouponChange.next(this.model);
  }

  getPIKCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
