import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild,
  Output } from '@angular/core';

import { Subject } from 'rxjs';

import { DialogService } from '@progress/kendo-angular-dialog';

import { LvSidePanelComponent } from '@lv-core-ui/components';
import { IInstrumentSelectedEvent } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { IWidget, IWatchListPanelDialogEvent, IWidgetState } from '@lv-instrument-monitor/models';
import { WidgetService } from '@lv-instrument-monitor/services';
import { LvInstrumentMonitorComponent, LvInstrumentMonitorFilterComponent, LvWatchListPanelDialogComponent } from '..';

export interface ILvInstrumentMonitorPanelState {
  id: string;
  title: string;
}

@Component({
  selector: 'lv-instrument-monitor-panel',
  templateUrl: './lv-instrument-monitor-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentMonitorPanelComponent implements OnInit {

  @ViewChild(LvInstrumentMonitorComponent, { static: true }) instrumentMonitor: LvInstrumentMonitorComponent;
  @ViewChild(LvInstrumentMonitorFilterComponent, { static: true }) instrumentMonitorFilter: LvInstrumentMonitorFilterComponent;
  @ViewChild(LvSidePanelComponent, { static: true }) settingsPanel: LvSidePanelComponent;

  @Input() state: ILvInstrumentMonitorPanelState;

  @Output() didSelectInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doOpenInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doOpenDocuments: Subject<IInstrumentSelectedEvent>;
  @Output() doOpenBasicTerms: Subject<IInstrumentSelectedEvent>;
  @Output() doCreateNewIssueFromExistingInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doCopyInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doDeleteInstrument: Subject<IInstrumentSelectedEvent>;

  widgetData: IWidget;
  isLoading: boolean;

  gridFilter: IWidgetState;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private errorService: LvErrorService,
    private widgetService: WidgetService
  ) {
    this.isLoading = true;

    this.didSelectInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doOpenInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doOpenDocuments = new Subject<IInstrumentSelectedEvent>();
    this.doOpenBasicTerms = new Subject<IInstrumentSelectedEvent>();
    this.doCreateNewIssueFromExistingInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doCopyInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doDeleteInstrument = new Subject<IInstrumentSelectedEvent>();
  }

  ngOnInit() {
    this.refresh();
  }

  async refresh(): Promise<void> {
    try {
      this.isLoading = true;
      this.changeDetectorRef.detectChanges();

      this.widgetData = await this.widgetService.getWidget(this.state.id, this.state.title);
      this.gridFilter = {
        ...this.widgetData.state,
        quick: [...this.widgetData.state.quick],
        selected: [...this.widgetData.state.selected]
      };

      // had to do this here because of the loader
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    }
    catch (error) {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();

      this.errorService.handleError(error);
    }
  }

  onFilterChanged(widgetState: IWidgetState) {
    this.widgetData.state = {
      ...widgetState,
      quick: [...widgetState.quick],
      selected: [...widgetState.selected]
    };

    this.gridFilter = {
      ...widgetState,
      quick: [...widgetState.quick],
      selected: [...widgetState.selected]
    };

    this.updateWidget();

    this.changeDetectorRef.detectChanges();
  }

  onSelectInstrument(event: IInstrumentSelectedEvent) {
    this.didSelectInstrument.next(event);
  }

  onOpenInstrument(event: IInstrumentSelectedEvent) {
    this.doOpenInstrument.next(event);
  }

  onOpenDocuments(event: IInstrumentSelectedEvent) {
    this.doOpenDocuments.next(event);
  }


  onCreateNewIssueFromExistingInstrument(record: IInstrumentSelectedEvent) {
    this.doCreateNewIssueFromExistingInstrument.next(record);
  }

  onCopyInstrument(record: IInstrumentSelectedEvent) {
    this.doCopyInstrument.next(record);
  }

  onDeleteInstrument(record: IInstrumentSelectedEvent) {
    this.doDeleteInstrument.next(record);
  }

  onShowSettings() {
    if (!this.isLoading) {
      this.settingsPanel.togglePanel();
    }
  }

  onEditWatchLists() {
    const dialogRef = this.dialogService.open({
      title: 'Watchlists',
      content: LvWatchListPanelDialogComponent
    });

    const dialog = dialogRef.content.instance as LvWatchListPanelDialogComponent;

    const onEditWatchList = (panelEvent: IWatchListPanelDialogEvent) => {
      if (panelEvent.deleted.length > 0 || panelEvent.updated.length > 0) {
        this.instrumentMonitorFilter.updateFilter(panelEvent);
      }
      else {
        this.instrumentMonitor.refresh();
      }

      this.changeDetectorRef.detectChanges();
    };

    dialog.didCancel.subscribe(onEditWatchList);
    dialog.didSave.subscribe(onEditWatchList);
  }

  deleteWidget() {
    this.widgetService.deleteWidget(this.widgetData);
  }

  private async updateWidget() {
    try {
      await this.widgetService.updateWidget(this.widgetData);
    }
    catch (error) {
      this.errorService.handleError(error);
    }
  }
}
