import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

/**
 * Market data widget component.
 */
@Component({
  selector: 'lv-market-data-widget',
  templateUrl: './lv-market-data-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvMarketDataWidgetComponent{

  @HostBinding('class.lv-market-data-widget')
  get isLvMarketDataWidget(): boolean {
    return true;
  }
}
