<div class="analytics" data-cy="DM-1202">
  <div class="analytics-content">
    <ng-template *ngIf="shouldDisplaySection('ConvertibleBond')"
      [ngTemplateOutlet]="convertibleBond">
    </ng-template>
    <ng-template *ngIf="shouldDisplaySection('Bond')"
      [ngTemplateOutlet]="bond">
    </ng-template>
  </div>
  <lv-xignite></lv-xignite>
</div>

<ng-template #convertibleBond>
  <gridster id="id" class="lv-gridster"
    [options]="gridsterConfig"> 
    <ng-container *ngFor="let w of gridsterWidgets">
      <gridster-item *ngIf="isWidgetVisible(w)" 
        [item]="w.gridsterItemConfig">
        <div class="lv-gridster-item"
          [class.lv-gridster-item--editable]="editable">
          <lv-pricing *ngIf="shouldShowWidget(w,'pricing')"></lv-pricing>
          <lv-market-data-widget *ngIf="shouldShowWidget(w,'market_data')"></lv-market-data-widget>        
          <lv-model-customization *ngIf="shouldShowWidget(w,'model_customization')"></lv-model-customization>
          <lv-outputs-widget *ngIf="shouldShowWidget(w,'outputs')" instrumentType='ConvertibleBond'></lv-outputs-widget>
          <lv-basic-terms *ngIf="shouldShowWidget(w,'instrument_info')" instrumentTypeValue='ConvertibleBond'></lv-basic-terms>
          <lv-tools-widget *ngIf="shouldShowWidget(w,'tools')"></lv-tools-widget>
        </div>
      </gridster-item>
    </ng-container>
  </gridster>
</ng-template>

<ng-template #bond>
  <gridster id="id" class="lv-gridster"
    [options]="gridsterConfig"> 
    <ng-container *ngFor="let w of gridsterWidgets">
      <gridster-item *ngIf="isWidgetVisible(w)" 
        [item]="w.gridsterItemConfig">
        <div class="lv-gridster-item"
          [class.lv-gridster-item--editable]="editable">
          <lv-bond-pricing *ngIf="shouldShowWidget(w,'bond_pricing')"></lv-bond-pricing>
          <lv-bond-market-data-widget *ngIf="shouldShowWidget(w,'bond_market_data')"></lv-bond-market-data-widget>
          <lv-outputs-widget *ngIf="shouldShowWidget(w,'outputs')" instrumentType='Bond'></lv-outputs-widget>
          <lv-bond-model-customization *ngIf="shouldShowWidget(w,'bond_model_customization')"></lv-bond-model-customization>
          <lv-basic-terms *ngIf="shouldShowWidget(w,'instrument_info')" instrumentTypeValue='Bond'></lv-basic-terms>
        </div>
      </gridster-item>  
    </ng-container>
  </gridster>
</ng-template>