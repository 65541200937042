<lv-flex-box class="lv-funding">
  <lv-environment-settings
    [section]="fundingSection"
    instrumentType="ConvertibleBond"
    lvId="marketDataFundingEnvironmentSettings"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-funding-content lv-market-data-section-content">
    <lv-flex-box data-cy="DM-320" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="fundingSpreadTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.funding-tooltip-id]="'fundingSpreadTooltip'">
            Funding Spread
          </span>
          <lv-xl-label [field]="'FNDG_SPREAD'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="fundingSpread"
          [format]="numberFormatBps"
          [decimals]="numberOfDecimalsBps"
          [disabled]="isFundingSectionDisabled"
          [(ngModel)]="fundingSettings.fundingSpread"
          (didKeyDownEnter)="onFundingSectionChange()"
          (didBlur)="onFundingSectionChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">bps</span>
      </div>
    </lv-flex-box>

    <lv-flex-box data-cy="DM-321" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="fundingMarginTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.funding-tooltip-id]="'fundingMarginTooltip'">
            Funding Margin
          </span>
          <lv-xl-label [field]="'FNDG_MARGIN'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="fundingMargin"
          [format]="numberFormatPercentage"
          [decimals]="numberOfDecimalsPercentage"
          [disabled]="isFundingSectionDisabled"
          [(ngModel)]="fundingSettings.fundingMargin"
          (didKeyDownEnter)="onFundingSectionChange()"
          (didBlur)="onFundingSectionChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #fundingSpreadTooltipTemplate>
  <lv-tooltip dm="dM-320"></lv-tooltip>
</ng-template>
<ng-template #fundingMarginTooltipTemplate>
  <lv-tooltip dm="dM-321"></lv-tooltip>
</ng-template>
