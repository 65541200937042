import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, HostBinding, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { IVolatilityTermStructureSchedule, IVolatilityTermStructureScheduleItem } from '@lv-analytics/models/market-data/volatility/volatility-term-structure-schedule-item';
import { LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { LvAdvancedGridComponent } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid.component';
import { LvError } from '@lv-core-ui/models/lv-error';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvExcelService } from '@lv-excel/services';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid/editing-directives/create-form-group-args.interface';

@Component({
  selector: 'lv-equity-volatility-terms-structure',
  templateUrl: './lv-equity-volatility-terms-structure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityVolatilityTermsStructureComponent implements OnInit {
  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;
  @Input() volatilityTermStructureSchedule: IVolatilityTermStructureScheduleItem[];
  @Input() isEditable: boolean;
  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get isGridDisabled (): boolean {
    return !this.isEditable;
  }

  get addStripedGridClass (): string {
   return !this.isEditable ? 'lv-advanced-grid-stripped' : '';
  }

  excelFieldAlias = 'VOL_SCHED_RANGE';
  columns: LvAdvancedGridColumn[];
  parseFn: any;

  private _destroyRef = inject(DestroyRef)

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.volatilityTermStructureSchedule = [];
    this.didSessionUpdatedEvent = new EventEmitter<void>();
    this.parseFn = this.parsePastedData.bind(this);
    this.isEditable = true;
  }

  @HostBinding ('class.lv-equity-volatility-terms-structure')
  get isLvEquityMarketDataVolatilityComponent (){
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.advancedGrid.didDataChange
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((records: IVolatilityTermStructureScheduleItem[]) => this.onDataChange(records)),
    this.advancedGrid.doReload
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.onReload()),
    this.advancedGrid.didError
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((error: LvError) => this.onError(error))

    this.initColumns();
  }


  /**
   * Occurs on data change.
   * @param records List of IVolatilityTermStructureScheduleItem object.
   */
  onDataChange(records: IVolatilityTermStructureScheduleItem[]) {
    this.applyRecords(records);
    this.didSessionUpdatedEvent.next();
  }

  /**
   * Applies advanced grid changes.
   */
  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on reload component.
   */
  onReload() {
    this.volatilityTermStructureSchedule = this.volatilityTermStructureSchedule.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required),
    });
  }

  /**
   * Columns initialization.
   */
  initColumns() {
    this.columns = [];

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'date';
    endDateColumn.dmKey = 'DM-2343';
    endDateColumn.width = this.isEditable ? 104 : 120

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Volatility';
    valueColumn.field = 'value';
    valueColumn.dmKey = 'DM-2344';
    valueColumn.width = this.isEditable ? 104 : 118;

    this.columns.push(endDateColumn);
    this.columns.push(valueColumn);
  }

  /**
   * Parse pasted data.
   * @param pastedDataRecords Pasted data records.
   * @returns List of IVolatilityTermStructureSchedule objects.
   */
  parsePastedData(pastedDataRecords: string[]): IVolatilityTermStructureSchedule[] {
    const volatilityTermStructureScheduleItems: IVolatilityTermStructureSchedule[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const dateValue = items[0];
      const valueValue = items[1];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'End Date');
      const value = MarketDataClipboard.parseNumberValue(valueValue, 'Volatility');

      volatilityTermStructureScheduleItems.push({
        date: date,
        value: value
      });
    });

    return volatilityTermStructureScheduleItems;
  }

  /**
   * Applies records.
   * @param records List of IVolatilityTermStructureScheduleItem objects.
   */
  private applyRecords(records: any[]) {
    this.volatilityTermStructureSchedule.splice(0, this.volatilityTermStructureSchedule.length);
    this.volatilityTermStructureSchedule.push(...records);
  }

}
