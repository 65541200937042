import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IEquityDividendsSectionSettingsContent, IEquityDividendsSettingsContent } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-dividends-settings';
import { CompanyAndUserSettingsService } from '@lv-analytics/services/company-settings/company-settings.service';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services';
import _ from 'lodash';

@Component({
  selector: 'lv-equity-dividends-settings',
  templateUrl: './lv-equity-dividends-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityDividendsSettingsComponent implements OnInit, IDiscardable {
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  settings: IEquityDividendsSettingsContent;
  originalSettings: IEquityDividendsSettingsContent;

  constructor(private _companyAndUserSettingsService: CompanyAndUserSettingsService,
              private _errorService: LvErrorService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _discardChangesService: DiscardChangesService) {

    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {} as IEquityDividendsSectionSettingsContent,
      environments: []
    } as IEquityDividendsSettingsContent

    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('Dividends', this);
  }

  @HostBinding('class.lv-equity-dividends-settings')
  get isLvEquityVolatilitySettingsComponent() {
    return true;
  }


  ngOnInit(): void {
    this.getEquityDividendsSettings();
  }

   /**
   * Occurs on cancel.
   */
   onCancel() {
    this.didCancel.next();
  }

  /**
    * Gets EquityDividends settings.
    */
  async getEquityDividendsSettings() {
    try {
      this.startLoading.next();
      this.settings = await this._companyAndUserSettingsService.getEquityDividendsSettings();
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves term settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
      await this._companyAndUserSettingsService.saveEquityDividendsSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'Dividends'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Resets equity settings to default.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();
      this.settings = await this._companyAndUserSettingsService.resetEquityDividendsSettings();

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Dividends'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }
}
