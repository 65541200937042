<lv-subscriber-panel
  [state]="state"
  [accessScope]="state.accessScope"
  (didReceiveMessage)="enqueueMessage($event)"
  (didStateChange)="onStateChange($event)">
  <ng-container header>
    <lv-flex-box kendoTooltip
      class="instrument-header"
      direction="row">
      <lv-instrument-search [instrumentName]="state.instrumentName" [readonly]="defaultStateOnly"
        (didSelectInstrument)="onInstrumentSelect($event)">
      </lv-instrument-search>
      <lv-flex-box flex="1"></lv-flex-box>
      <lv-flex-box *ngIf="instrumentReloadVisible"
        class="instrument-header-options"
        direction="row"
        alignItems="center">
        <div class="reload-info">{{ hubReloadEventMessage }}</div>
        <div class="lv-icon-square"
          [class.lv-icon-square--disabled]="isLoading"
          title="Dismiss"
          lvId="instrument-dismiss-reload"
          (click)="onDismissInstrumentReload()">
          <i class="k-icon k-i-non-recurrence"></i>
        </div>
        <div class="lv-icon-square"
          [class.lv-icon-square--disabled]="isLoading"
          title="Reload"
          lvId="instrument-reload"
          (click)="onReloadInstrument()">
          <i class="k-icon k-i-reload"></i>
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </ng-container>
  <ng-container content>

    <!-- todo fix authorization of instrument -->

    <instrument-authorization *ngIf="notAuthorized"
      [instrumentName]="state.instrumentName">
    </instrument-authorization>

    <ng-container *ngIf="!notAuthorized && !isEquity && !isAssetSwap">
      <kendo-tabstrip class="instrument-tab" #tabStrip
      [class.instrument-two-rows]="instrumentUtil.hasInstrumentTwoRows"
      [class.instrument-three-rows]="instrumentUtil.hasInstrumentThreeRows"
      [class.instrument-four-rows]="instrumentUtil.hasInstrumentFourRows"
      [class.instrument-five-rows]="instrumentUtil.hasInstrumentFiveRows"
      [class.instrument-six-rows]="instrumentUtil.hasInstrumentSixRows"
      [class.instrument-seven-rows]="instrumentUtil.hasInstrumentSevenRows"
      [keepTabContent]="keepTabContent"
      (tabSelect)="onTabSelect($event)">

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('valuation') && showAnalyticsWidgets"
          title="Valuation"
          cssClass="DM-1202">
          <ng-template kendoTabContent>
            <analytics
              [editable]="model.editCustomView"
              [widgets]="view.widgets"
              [instrumentType]="state.instrumentType">
            </analytics>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('market_data') && showAnalyticsWidgets"
          title="Market Data">
            <ng-template kendoTabContent>
              <lv-market-data-widget></lv-market-data-widget>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('bond_market_data') && showAnalyticsWidgets"
          title="Market Data">
            <ng-template kendoTabContent>
              <lv-bond-market-data-widget></lv-bond-market-data-widget>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('model_customization') && showAnalyticsWidgets"
          title="Model Customization"
          cssClass="DM-1214">
            <ng-template kendoTabContent>
              <lv-model-customization
                data-cy="DM-1214">
              </lv-model-customization>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('bond_model_customization') && showAnalyticsWidgets"
          title="Model Customization"
          cssClass="DM-5449">
            <ng-template kendoTabContent>
              <lv-bond-model-customization
                data-cy="DM-5449">
              </lv-bond-model-customization>
            </ng-template>
        </kendo-tabstrip-tab>
        
        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('tools') && showAnalyticsWidgets"
          title="Tools">
            <ng-template kendoTabContent>
              <lv-tools-widget></lv-tools-widget>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('terms') && isConvertibleBond"
          title="Terms"
          cssClass="terms-class DM-1220">
          <ng-template kendoTabContent>
            <ng-container *ngIf="isConvertibleBond">
              <ng-container *ngIf="hasFullTermsAccess || state.isPrivateInstrument;else showTermsSummary">
                <ng-container *ngTemplateOutlet="fullTerms;"></ng-container>
              </ng-container>
            </ng-container>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('terms') && isBond"
          title="Terms"
          cssClass="terms-class DM-4960">
          <ng-template kendoTabContent>
            <ng-container *ngTemplateOutlet="bond;"></ng-container>
        </ng-template>
      </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('sensitivity_analysis') && showAnalyticsWidgets"
          title="Sensitivity Analysis">
          <ng-template kendoTabContent>
            <lv-sensitivity-analysis-panel
              [state]="state.sensitivityAnalysisState"
              (didUpdateState)="onSensitivityAnalysisStateUpdated($event)">
            </lv-sensitivity-analysis-panel>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('documents') && showDocuments"
          title="Documents">
          <ng-template kendoTabContent>
            <lv-documents-sliding-panel
              [lwsIdentifier]="state.instrumentLwsIdentifier"
              [isin]="state.instrumentIsin"
              [instrumentName]="state.instrumentName">
            </lv-documents-sliding-panel>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- To do: tab visibility as per specification, when backend part is implemented. -->
        <ng-container *ngIf="isUseDummyEquitySelected">
          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('underlying') && !isDraftFromExcel"
            title="Underlying"
            cssClass="DM-4869">
            <ng-template kendoTabContent>
              <underlying
                [instrumentName]="underlyingEquityName"
                (didEquitySaved)="onEquitySaved()">
              </underlying>
            </ng-template>
        </kendo-tabstrip-tab>
        </ng-container>
      </kendo-tabstrip>

      <div class="instrument-options" *ngIf="!defaultStateOnly || isOpenedFromExcel">
        <i class="k-icon k-i-gear"
          (click)="onShowSettings()">
        </i>
      </div>

      <lv-side-panel class="instrument-options-panel"
        [width]="getSidePanelWidth(model.editCustomView)"
        position="right"
        [floating]="true">

        <ng-container *ngIf="!model.editCustomView">
          <div  *ngIf="!isOpenedFromExcel"
            class="lv-side-panel-link-item"
            lvId="lv-tools-display-default-view"
            (click)="onDisplayDefaultView()">
            <span>Display Default View</span>
            <i *ngIf="state.useDefaultView"
              class="k-icon k-i-check"></i>
          </div>

          <div *ngIf="!isOpenedFromExcel"
            class="lv-side-panel-link-item lv-side-panel-link-item--separator"
            lvId="lv-tools-display-custom-view"
            (click)="onDisplayCustomView()">
            <span>Display Custom View</span>
            <i *ngIf="!state.useDefaultView"
              class="k-icon k-i-check"></i>
          </div>

          <div class="lv-side-panel-link-item"
            [class.lv-side-panel-link-item--separator]="state.useDefaultView && shouldShowEditAccessScopeLink"
            lvId="lv-tools-edit-custom-view"
            (click)="onEditCustomView($event)">
            <span>{{editWidgetButtonText}}</span>
          </div>

          <div *ngIf="!state.useDefaultView"
            class="lv-side-panel-link-item"
            [class.lv-side-panel-link-item--separator]="shouldShowEditAccessScopeLink"
            lvId="lv-tools-save-as-default-view"
            (click)="onSaveAsDefaultView()">
            <span>{{saveWidgetButtonText}}</span>
          </div>
          <div *ngIf="shouldShowEditAccessScopeLink"
            class="lv-side-panel-link-item"
            lvId="lv-tools-edit-instrument-access-rights"
            (click)="onEditInstrumentAccessRights()">
            <span>Edit Instrument Access Rights</span>
          </div>
        </ng-container>

        <ng-container *ngIf="model.editCustomView">
          <div *ngFor="let w of view.widgets"
            class="instrument-options-panel-item">
            <div class="instrument-options-panel-item-title"
              [class.instrument-options-panel-item--only-widget]="view.isOnlyWidget(w)">
              {{w.title}}
            </div>

            <kendo-buttongroup selection="single">
              <button *ngFor="let vo of w.visibilityOptions"
                kendoButton
                [disabled]="isOpenedFromExcel && disableFieldsInEditCustomView(w.id)"
                [lvId]="view.getVisibilityLvId(vo)"
                [iconClass]="view.getVisibilityIconClass(vo)"
                [toggleable]="true"
                [selected]="view.getIsVisibilityOptionSelected(vo, w)"
                (click)="onSetWidgetVisibility(vo, w)">
              </button>
            </kendo-buttongroup>
          </div>

          <lv-flex-box class="instrument-options-panel-edit-custom-fotter"
            direction="row">
            <button kendoButton
              [primary]="true"
              (click)="onCancelEditCustomView($event)">
              Cancel
            </button>

            <button kendoButton
              [primary]="true"
              (click)="onApplyEditCustomView($event)">
              Apply
            </button>
          </lv-flex-box>
        </ng-container>
      </lv-side-panel>
    </ng-container>

    <ng-container *ngIf="isEquity">
      <ng-container *ngTemplateOutlet="equity;">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isAssetSwap">
      <ng-container *ngTemplateOutlet="assetSwap;">
      </ng-container>
    </ng-container>

  </ng-container>
</lv-subscriber-panel>

<ng-template #fullTerms>
  <lv-custom-instrument
    data-cy="DM-1220"    
    [leversysId]="state.leversysId"   
    [isPrivateInstrument]="state.isPrivateInstrument"
    [hasFullTermsAccess] ="hasFullTermsAccess"
    [region]="state.region"
    (didPrivateInstrumentChange)="onPrivateInstrumentChanged($event)"
    (didPrivateInstrumentLoad)="onPrivateInstrumentLoaded($event)"
    (didPrivateInstrumentReload)="onPrivateInstrumentReloaded()"   
    (didInstrumentNotFoundReturned)="onPrivateInstrumentDeleted()"
    (didInstrumentNameChanged)="onConvertibleBondDraftSet($event)">
  </lv-custom-instrument>
</ng-template>

<ng-template #showTermsSummary>
  <ng-container *ngIf="showAnalyticsWidgets && isPermissionChecked">
    <lv-terms-summary
      [lwsIdentifier]="state.instrumentLwsIdentifier"
      [leversysLocalId]="state.leversysId">
    </lv-terms-summary>
  </ng-container>
</ng-template>

<ng-template #bond>
  <lv-bond-terms
    data-cy="DM-4960"
    (didInstrumentNameChanged)="onBondDraftSet($event)">
  </lv-bond-terms>
</ng-template>

<ng-template #equity>
  <equity
    data-cy="DM-4869"
    (didEquityNameSet)="onEquityNameSet($event)"
  ></equity>
</ng-template>

<ng-template #assetSwap>
  <asset-swap></asset-swap>
</ng-template>
