import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LvCreditTermsStructureComponent } from '@lv-analytics/components/market-data/lv-market-data/lv-credit/lv-credit-parameters/lv-credit-terms-structure/lv-credit-terms-structure.component';
import { AccruedOnDefaultDescription, CreditModelFactorDisplay, CreditSource, DateAdjustment, DayCountDescription, PeriodFrequencyDescription } from '@lv-analytics/models';
import { IBondCreditParameters } from '@lv-analytics/models/bond/market-data/credit/bond-credit-parameters';
import { LvLookupEnum } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-bond-credit-parameters',
  templateUrl: './lv-bond-credit-parameters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LvBondCreditParametersComponent {

  @Input() creditParameters: IBondCreditParameters;
  @Input() creditSource: CreditSource;

  @Output() didCreditParametersChanged: EventEmitter<IBondCreditParameters>;
  @ViewChild(LvCreditTermsStructureComponent) creditTermStructureComponent: LvCreditTermsStructureComponent;

  get IsNotTermStructure(): boolean {
    return !(this.creditSource === CreditSource.TermStructure);
  }

  get IsNotSurProb(): boolean {
    return this.creditSource !== CreditSource.SurvivalProbabilityTermStructure;
  }

  get IsFlatSpread(): boolean {
    return this.creditSource === CreditSource.FlatSpread;
  }

  get IsDefaultRateFlat(): boolean {
    return this.creditSource === CreditSource.DefaultRateFlat;
  }

  get IsCds(): boolean {
    return this.creditSource === CreditSource.CDSFlat ||
      this.creditSource === CreditSource.CDSTermStructure;
  }

  get IsCdsFlat(): boolean {
    return this.creditSource === CreditSource.CDSFlat;
  }

  get IsTermStructure(): boolean {
    return this.creditSource === CreditSource.TermStructure;
  }

  get IsCDSTermStructure(): boolean {
    return this.creditSource === CreditSource.CDSTermStructure;
  }

  get IsDefaultRateTermStructure(): boolean {
    return this.creditSource === CreditSource.DefaultRateTermStructure;
  }

  get IsSurvivalProbabilityTermStructure(): boolean {
    return this.creditSource === CreditSource.SurvivalProbabilityTermStructure;
  }

  get IsRecoveryBondVisible(): boolean {
    return this.creditSource !== CreditSource.FlatSpread;
  }

  get isXlLabelOnTermsStructureVisible(): boolean {
    return !!this._excelSvc?.containsField('CREDIT_SCHED_RANGE');
  }

  get isXlLabelOnCDSTermsStructureVisible(): boolean {
    return !!this._excelSvc?.containsField('CREDIT_CDS_SCHED_RANGE');
  }

  get isXlLabelOnDefaultRateTermsStructureVisible(): boolean {
    return !!this._excelSvc?.containsField('CREDIT_DFLT_SCHED_RANGE');
  }

  get isXlLabelOnSurvivalProbabilityTermsStructureVisible(): boolean {
    return !!this._excelSvc?.containsField('CREDIT_SRVPROB_SCHED_RANGE');
  }

  numberOfDecimalsPercentage = '2';
  numberOfDecimalsBps = '0';
  numberFormat = '###,#.##';
  numberFormatBps = 'n0';

  creditAccuredOnDefaultSource: LvLookupEnum;
  creditFrequencySource: LvLookupEnum;
  creditModelFactor: LvLookupEnum;
  creditBasisSource: LvLookupEnum;
  badDayConversion: LvLookupEnum;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.creditParameters = {
      creditTermStructure: [],
      creditCDSTermStructure: [],
      creditDefaultRateTermStructure: [],
      creditSurvivalProbabilityTermStructure: [],
    } as IBondCreditParameters;

    this.creditAccuredOnDefaultSource = new LvLookupEnum(AccruedOnDefaultDescription);
    this.creditFrequencySource = new LvLookupEnum(PeriodFrequencyDescription);
    this.creditModelFactor = new LvLookupEnum(CreditModelFactorDisplay);
    this.creditBasisSource = new LvLookupEnum(DayCountDescription);
    this.badDayConversion = new LvLookupEnum(DateAdjustment);
    this.didCreditParametersChanged = new EventEmitter<IBondCreditParameters>();
  }

  /**
   * On credit parameters change.
   */
  onCreditParametersChange() {
    this.didCreditParametersChanged.next(this.creditParameters);
    this._changeDetectorRef.detectChanges();
  }
}
