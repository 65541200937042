import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IPricingEnvironmentOverrides } from '@lv-analytics/models/company-and-user-settings/pricing-environment-overrides';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IEquityBorrow } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-borrow/equity-borrow';
import { IEquityDividends } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-dividends/equity-dividends';
import { IEquityVolatility } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-volatility.ts/equity-volatility';
import { LvAnalyticsError } from '@lv-analytics/models/errors';
import { BorrowMapper } from '@lv-analytics/models/market-data/borrow/borrow-mapper';
import { DividendsMapper } from '@lv-analytics/models/market-data/dividends/dividends-mapper';
import { VolatilityMapper } from '@lv-analytics/models/market-data/volatility/volatility-mapper';
import { IEquityValuationSession } from '@lv-analytics/models/valuation-session/equity-valuation-session';
import { HttpClientBase } from '@lv-core-ui/api/http-client-base';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { ILvError } from '@lv-core-ui/models/lv-error/base';
import { LvErrorType } from '@lv-core-ui/models/lv-error/error-type';
import { LvDateService } from '@lv-core-ui/services/lv-date/lv-date.service';
import { IEquity } from '@lv-reference-data/models';
import { v4 } from 'uuid';

@Injectable({
  providedIn: "root",
})
export class EquityService extends HttpClientBase {
  private _svcInstanceId: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
    private borrowMapper: BorrowMapper,
    private dividendMapper: DividendsMapper,
    private volatilityMapper: VolatilityMapper
  ) {
    super(lvDateService, http, "/analytics/equity");
    this._svcInstanceId = v4();
  }

  /**
   * Create valuation session if there isn't one
   * @param {string} pricingEnvironmentCode pricing environment
   * @param {IPricingEnvironmentOverrides} pricingEnvironmentOverrides Override
   * @param {boolean} isOpenedFromExcel is application opened from excel
   * @param {string} leversysLocalId is leversys local identifier.
   * @returns promise based IValuationSession
   */
  async createValuationSessionForEquity(
    pricingEnvironmentCode: string = "",
    pricingEnvironmentOverrides: IPricingEnvironmentOverrides = null,
    isOpenedFromExcel: boolean,
    leversysLocalId: string
  ): Promise<IEquityValuationSession> {
    try {
      const result = await this.postAsync<IEquityValuationSession>(
        {
          pricingEnvironmentCode: pricingEnvironmentCode,
          pricingEnvironmentOverrides: pricingEnvironmentOverrides,
          isOpenedFromExcel: isOpenedFromExcel,
          leversysLocalId: leversysLocalId,
          instrumentType: "Equity",
        },
        "/createSession"
      );

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3025'));
      }

      if (result.marketData.volatility) {
        if (result.marketData.volatility.volatilitySurface) {
          result.marketData.volatility.volatilitySurface =
            this.volatilityMapper.mapVolatilitySurfaceToUi(
              result.marketData.volatility.volatilitySurface
            );
        }
        if (result.marketData.volatility.volatilityTermStructureSchedule) {
          //
        }
      }
      if (result.marketData.dividends) {
        result.marketData.dividends = this.dividendMapper.mapEquityDividendsToUi(
          result.marketData.dividends
        );
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Load equity Borrow.
   * @param sessionId session id
   * @param leversysLocalId leversysLocalId
   * @param environmentId environmentId
   * @returns IEquityBorrow
   */
  async loadEquityBorrow(
    sessionId: string,
    leversysLocalId: string,
    environmentId: string
  ): Promise<IEquityBorrow> {
    const result = await this.postAsync<IEquityBorrow>(null, "/loadBorrow", {
      sessionId: sessionId,
      leversysLocalId: leversysLocalId,
      environmentId: environmentId,
    });

     return this.borrowMapper.mapEquityBorrowToUi(result)
  }

  /**
   * Load equity Dividends.
   * @param sessionId session id
   * @param leversysLocalId leversysLocalId
   * @param environmentId environmentId
   * @returns IEquityDividends
   */
  async loadEquityDividends(
    sessionId: string,
    leversysLocalId: string,
    environmentId: string
  ): Promise<IEquityDividends> {
    const result = await this.postAsync<IEquityDividends>(
      null,
      "/loadDividends",
      {
        sessionId: sessionId,
        leversysLocalId: leversysLocalId,
        environmentId: environmentId,
      }
    );

    return this.dividendMapper.mapEquityDividendsToUi(result);
  }

  /**
   * Load equity Volatility.
   * @param sessionId session id
   * @param leversysLocalId leversysLocalId
   * @param environmentId environmentId
   * @returns IEquityVolatility
   */
  async loadEquityVolatility(
    sessionId: string,
    leversysLocalId: string,
    environmentId: string
  ): Promise<IEquityVolatility> {
    const result = await this.postAsync<IEquityVolatility>(
      null,
      "/loadVolatility",
      {
        sessionId: sessionId,
        leversysLocalId: leversysLocalId,
        environmentId: environmentId,
      }
    );

    return this.volatilityMapper.mapEquityVolatilityToUi(result);
  }

  async saveBorrow(
    borrowSettings: IEquityBorrow,
    environment: string,
    leversysLocalId: string,
    sourceId: string,
  ) {
    try {
      const mappedToAPi =
        this.borrowMapper.mapEquityBorrowToApi(borrowSettings);

      const equityBorrowData: SaveEquityBorrowRequest = {
        leversysLocalId: leversysLocalId,
        pricingEnvironmentId: environment,
        data: mappedToAPi,
        sourceId: sourceId,
      };

      const response = await this.postAsync<string>(
        equityBorrowData,
        "/saveBorrow"
      );

      return response;
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(error.message));
    }
  }

  async saveVolatility(
    volatility: IEquityVolatility,
    environment: string,
    leversysLocalId: string,
    sourceId: string,
  ) {
    try {
      const mappedToAPi =
        this.volatilityMapper.mapEquityVolatiltiyToAPI(volatility);

      const request = {
        leversysLocalId: leversysLocalId,
        pricingEnvironmentId: environment,
        data: mappedToAPi,
        sourceId: sourceId,
      };

      const response = await this.postAsync<string>(request, "/saveVolatility");

      return response;
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(error.message));
    }
  }

  async saveDividends(
    dividend: IEquityDividends,
    environment: string,
    leversysLocalId: string,
    sourceId: string,
  ) {
    try {
      const mappedToAPi = this.dividendMapper.mapEquityDividendToApi(dividend);

      const request = {
        leversysLocalId: leversysLocalId,
        pricingEnvironmentId: environment,
        data: mappedToAPi,
        sourceId: sourceId,
      };

      const response = await this.postAsync<string>(request, "/saveDividends");

      return response;
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(error.message));
    }
  }

  async saveEnvironmentSettingsForEquity(
    serviceinstanceId: string,
    leversysLocalId: string,
    section: PricingEnvironmentSections,
    environmentIds: string[]
  ) {
    try {
      const request = {
        leversysLocalId: leversysLocalId,
        section: section,
        environmentIds: environmentIds,
        sourceId: serviceinstanceId,
      };
      await this.postAsync<void>(request, "/saveInstrumentEnvironmentSettings");
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(error.message));
    }
  }

  async loadEquityTerms(leversysLocalId: string) {
    try {
      const result = await this.postAsync<IEquity>({         
        leversysLocalId: leversysLocalId,
        instrumentType: "Equity",
      }, '/loadInstrumentDataForSession');

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Handles unauthorizes error.
   * @param e ILvError object.
   * @returns LvAnalyticsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvAnalyticsError {
    const error = new LvAnalyticsError(e.message);

    if (e.type === LvErrorType.AUTHORIZATION) {
      error.type = e.type;
    }

    return error;
  }
}

interface SaveEquityBorrowRequest {
  leversysLocalId: string;
  pricingEnvironmentId: string;
  data: IEquityBorrow;
  sourceId: string;
}
