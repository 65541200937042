<kendo-tabstrip class="lv-tabstrip other-settings-tabstrip lv-settings-content"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="Quantity"
    cssClass="DM-1260">
    <ng-template kendoTabContent>
      <lv-quantity-settings
        [settings]="settings.quantitySettings"
        data-cy="DM-1260"
        ></lv-quantity-settings>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
  title="Copy & Paste">
  <ng-template kendoTabContent>
    <lv-copy-and-paste-settings [settings]="settings.copyAndPasteSettings"></lv-copy-and-paste-settings>
  </ng-template>
 </kendo-tabstrip-tab>

</kendo-tabstrip>
<lv-flex-box class="lv-settings-footer" direction="row">
  <div class="lv-settings-footer-divider">&nbsp;</div>
  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-quantity-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    [class.save-settings-btn-disabled]="!isChanged()"
    lvId="save-other-settings"
    (click)="onSave()">
    Save
  </button>
</lv-flex-box>
