import { Component, OnInit, ViewEncapsulation, OnDestroy,
  ChangeDetectorRef, HostListener, ChangeDetectionStrategy, Optional, inject, DestroyRef } from '@angular/core';

import { Router } from '@angular/router';

import { Subscription ,  Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { HubConnectionState } from '@microsoft/signalr';
import { LvGoldenLayoutService } from '@lv-golden-layout/services';
import { ILvMenuItem, LvStateModalMode } from '@lv-core-ui/components';
import { LvErrorType } from '@lv-core-ui/models';
import { LvErrorService, LvPermissionService } from '@lv-core-ui/services';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { SecurityService } from '@lv-security/security.service';
import { AppComponent, ApplicationMode } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { InternalComponentService } from '.';
import { OnlinePlatformHubService } from '@lv-services/online-platform-hub/online-platform-hub.service';

import { Permissions as InstrumentMonitorPermissions } from '@lv-instrument-monitor/instrument-monitor.permissions';
import { Permissions as AnalyticsPermissions } from '@lv-analytics/analytics.permissions';
import { Permissions as PrivateInstrumentPermissions } from '@lv-custom-instruments/custom-instrument.permissions';
import { DefaultComponentStateService } from './instrument/instrument.state';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { LvExcelService } from '@lv-excel/services';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvCreateInstrumentModalComponent } from '@lv-custom-instruments/components/lv-custom-instrument/lv-create-instrument-modal/lv-create-instrument-modal/lv-create-instrument-modal.component';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InstrumentTypeDescription } from '@lv-custom-instruments/models/instrument-type';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'internal',
  templateUrl: './internal.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    InternalComponentService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalComponent implements OnInit, OnDestroy {

  get hubConnectionStatus(): string {
    return this._onlinePlatformHubService.connectionState;
  }

  get hubConnectionStatusClass(): string {
    return this._onlinePlatformHubService.connectionState.toLowerCase();
  }

  get shouldEstablishRealTimeConnection(): boolean {
    return this._appComponent.shouldEstablishRealTimeConnection;
  }

  get manualReconnectVisible(): boolean {
    return !this._appComponent.signalrModal.isOpened
      && this._onlinePlatformHubService.connectionState === HubConnectionState.Disconnected;
  }

  get isInstrumentOptionDisabled(): boolean {
    return !(this._permissionService.hasPermission(AnalyticsPermissions.MODULE, AnalyticsPermissions.INSTRUMENT_VALUATION)
    || this._permissionService.hasPermission('TERMS', 'VIEW_FULL_TERMS'));
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelService?.isInitialized();
  }

  get hasBetaFeaturesAccess(): boolean {
    return this._permissionService.hasPermission('BETA', 'VIEW');
  }

  searchQuery: Subject<string>;
  menuItems: ILvMenuItem[];
  cogMenuItems: ILvMenuItem[];
  menuVisible: boolean;
  cogMenuVisible: boolean;

  applicationStateInitialized: boolean;

  private _subscriptions: Subscription[];
  private _destroyRef = inject(DestroyRef);

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _lvGoldenLayoutService: LvGoldenLayoutService,
    private _errorService: LvErrorService,
    private _permissionService: LvPermissionService,
    private _securitySerivice: SecurityService,
    private _applicationSettingsService: ApplicationSettingsService,
    private _internalCompnentService: InternalComponentService,
    private _onlinePlatformHubService: OnlinePlatformHubService,
    private _appComponent: AppComponent,
    private _instrumentStateService: DefaultComponentStateService,
    private _authorizationService: AuthorizationService,
    private _dialogService: DialogService,
    private toastrService: ToastrService,
    @Optional() private _excelService: LvExcelService
  ) {
    this.menuItems = this.getMenuItems();
    this.cogMenuItems = this.getCogMenuItems();

    this.applicationStateInitialized = false;

    this.searchQuery = new Subject<string>();

    this.menuVisible = true;
    this.cogMenuVisible = true;
  }

  @HostListener('keydown.shift.f1', ['$event'])
  onShiftF1(event: KeyboardEvent) {
    event.preventDefault();
    this.onOpenSupportCenter();
  }

  async ngOnInit() {
    await this._authorizationService.getUserAccessRightsData();
    this.menuItems = this.getMenuItems();

    if (this._appComponent.mode === ApplicationMode.SingleInstrument) {
      this.menuVisible = false;
      this.cogMenuVisible = false;
      this._changeDetectorRef.detectChanges();
    }

    this._subscriptions = [
      this.searchQuery
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe(query => {
          this._errorService.toastrService.warning(query, 'Not Implemented');
        }),

      this._internalCompnentService.menuToggled.subscribe(visible => {
        this.menuVisible = visible;
        this._changeDetectorRef.detectChanges();
      }),

      this._applicationSettingsService.didError.subscribe(error => {
        if (error.type !== LvErrorType.AUTHENTICATION &&
          error.type !== LvErrorType.CONNECTION &&
          !this._appComponent.appVersionModal.isOpened &&
          !this._appComponent.authenticationModal.isOpened &&
          !this._appComponent.errorModal.isOpened &&
          !this._appComponent.signalrModal.isOpened &&
          !this._appComponent.stateModal.isOpened) {
          const isConflict = error.type === LvErrorType.CONFLICT;
          this._appComponent.stateModal.open(isConflict ? LvStateModalMode.CONFLICT : LvStateModalMode.ERROR);
        }
        else {
          this._errorService.handleError(error);
        }
      }),

      ...(this._appComponent.shouldEstablishRealTimeConnection ? [
        this._onlinePlatformHubService.didConnectionStateChange.subscribe(() => {
          this._changeDetectorRef.detectChanges();
        })
      ] : [])
    ];

    this.initializeApplicationState();
  }

  onQueryChange(event: KeyboardEvent, queryString: string) {
    this.searchQuery.next(queryString);
  }

  async onLogOut() {
    try {
      await this._applicationSettingsService.saveCurrentState();
      await this._securitySerivice.tokenService.logout();

      this._appComponent.onLogInAgain();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  onDarkTheme() {
    this._errorService.toastrService.warning('Not Implemented');
  }

  onOpenSupportCenter() {
    window.open(environment.supportCenterUrl, '_blank');
  }

  onManualReconnectHub() {
    this._appComponent.startHubConnection();
  }

  /**
   * Method manages instantiation of LvCreateInstrumentModalComponent
   */
  openCreateInstrumentWindowDialog() {
    const dialogRef = this._dialogService.open({
      title: 'Create Instrument',
      content: LvCreateInstrumentModalComponent,
      width: 458,
      height: this.hasBetaFeaturesAccess ? 217 : 192,
    });

    const instance = dialogRef.content.instance as  LvCreateInstrumentModalComponent;

    instance.hasBetaAccessPermission = this.hasBetaFeaturesAccess;

    instance.didCreate
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(el => {
        if (el === InstrumentTypeDescription.Convertible) {
          this.addWidget('Instrument', 'analytics', this._instrumentStateService.getDefaultInstrumentComponentState('ConvertibleBond', state => {
            state.isPrivateInstrument = true;
          }));
        }
        else if (el === InstrumentTypeDescription.Equity) {
          this.addWidget('Instrument', 'equity', this._instrumentStateService.getDefaultInstrumentComponentState('Equity'));
        }                
        else if (el === InstrumentTypeDescription.Bond) {
          this.addWidget('Instrument', 'bond', this._instrumentStateService.getDefaultInstrumentComponentState('Bond'));
        }
        else if (el === InstrumentTypeDescription.AssetSwap) {
          this.addWidget('Instrument', 'asw', this._instrumentStateService.getDefaultInstrumentComponentState('ASW'));
        }
        else {
          this.toastrService.warning(
           'Instrument Type not supported.'
          );
        }
      dialogRef.close();
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-create-instrument-modal-container');

    if (!this.hasBetaFeaturesAccess) {
      dialogRef.dialog.location.nativeElement.classList.add('lv-create-instrument-modal-container-shrink');
    }

    this._subscriptions.push(instance.didCancel.subscribe(() => {
      dialogRef.close();
    }));
  }

  ngOnDestroy() {
    if (this._subscriptions) {
      this._subscriptions.forEach(a => a.unsubscribe());
    }
  }

  private async initializeApplicationState(): Promise<void> {
    this.applicationStateInitialized = await this._applicationSettingsService.initialize(this.isOpenedFromExcel);
    this._changeDetectorRef.detectChanges();
  }

  private addWidget(name: string, componentName: string, componentState: any = {}) {
    try {
      this._lvGoldenLayoutService.addComponent(name, componentName, componentState);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  private getCogMenuItems(): ILvMenuItem[] {
    return [{
      text: 'User Profile',
      hidden: !this._permissionService.hasPermission('AccountManagement', 'EditProfile'),
      action: () => {
        this._appComponent.redirectTo(environment.identityServer.userUrl);
      }
    }, {
      text: 'Account Profile',
      hidden: !this._permissionService.hasPermission('AccountManagement', 'EditCompany'),
      action: () => {
        this._appComponent.redirectTo(environment.identityServer.accountUrl);
      }
    }, {
      text: 'Settings',
      lvId: 'si-settings',
      action: () => {
        this._router.navigate(['/dashboard/settings']);
      }
    }, {
      text: 'Log Out',
      iconClass: 'k-icon k-i-logout',
      action: this.onLogOut.bind(this)
    }
  ];
  }

  private getMenuItems(): ILvMenuItem[] {
    return [{
      text: 'Instrument Monitor',
      lvId: 'mi-instrument-monitor',
      hidden: !this._permissionService.hasPermission(InstrumentMonitorPermissions.MODULE,
                                                     InstrumentMonitorPermissions.INSTRUMENT_MONITOR_VIEW),
      action: () => {
        this.addWidget('Instrument Monitor', 'instrument-monitor');
      }
    }, {
      text: 'Instrument',
      lvId: 'mi-instrument',
      disabled: this.isInstrumentOptionDisabled,
      action: () => {
        this.addWidget('Instrument', 'analytics', this._instrumentStateService.getDefaultInstrumentComponentState());
      }
    },
    {
        text: 'Create Instrument',
        lvId: 'mi-private-instrument',
        hidden: !this._permissionService.hasPermission(PrivateInstrumentPermissions.MODULE,
                                                       PrivateInstrumentPermissions.CREATE_PRIVATE_INSTRUMENT),
        action: () => {
          this.openCreateInstrumentWindowDialog();
        }
    }, {
      text: 'Log Out',
      lvId: 'mi-logout',
      iconClass: 'k-icon k-i-logout',
      action: this.onLogOut.bind(this)
    }];
  }
}
