import { v4 } from 'uuid';

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LvAnalyticsError } from '../../models/errors';

import { ISavePricingRequest } from '../../models/pricing/pricing-request';
import { ISaveSectionRequest } from '../../models/save-section-request';
import { IDataEntitlement } from '../../../shared/interfaces/data-entitlement';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Pricing service.
 */
@Injectable()
export class PricingService extends HttpClientBase {

  get instanceId(): string {
    return this._svcInstanceId;
  }

  private _svcInstanceId: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/analytics/pricing');

    this._svcInstanceId = v4();
  }

  /**
   * Saves pricing.
   * @param request ISavePricingRequest object.
   */
  async savePricing(request: ISavePricingRequest) {
    try {
      this.applyDataEntitlement(request);
      await this.postAsync<ISavePricingRequest>(request, '/savePricing');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Applies data entitlement.
   * @param request ISaveSectionRequest or IDataEntitlement object.
   */
  private applyDataEntitlement(request: ISaveSectionRequest | IDataEntitlement) {
    request.sourceId = this._svcInstanceId;
  }
}
