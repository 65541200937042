import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { CreditSource, CreditSourceDescription, PricingEnvironmentSections } from '@lv-analytics/models';
import { IBondCredit } from '@lv-analytics/models/bond/market-data/credit/bond-credit';
import { IBondCreditParameters } from '@lv-analytics/models/bond/market-data/credit/bond-credit-parameters';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum } from '@lv-core-ui/util';
import * as _ from 'lodash';
import { LvBondCreditParametersComponent } from './lv-bond-credit-parameters/lv-bond-credit-parameters.component';
import { ISaveBondCreditRequest } from '@lv-analytics/models/bond/market-data/credit/save-bond-credit-request';
import { filter } from 'rxjs';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';

@Component({
  selector: 'lv-bond-credit',
  templateUrl: './lv-bond-credit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LvBondCreditComponent implements OnInit {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild('bondCreditParameters') issuerCreditParameters: LvBondCreditParametersComponent;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;
  @Output() didSessionDataLoadedEvent: EventEmitter<void>;
  @Output() isSectionLoading: EventEmitter<boolean>;

  get sectionMinWidth(): number {
    switch (this.credit?.creditSource) {
      case CreditSource.CDSTermStructure:
        return 345;
      case CreditSource.DefaultRateTermStructure:
        return 375;
      case CreditSource.SurvivalProbabilityTermStructure:
        return 410;
      default:
        return 315;
    }
  }

  get isCreditSectionDisabled(): boolean {
    return !this._analyticsPresenter?.bondLoaded;
  }

  credit: IBondCredit;
  creditSource: LvLookupEnum;
  originalValue: IBondCredit;

  creditSection = PricingEnvironmentSections.Credit;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _destroyRef: DestroyRef,
    private _errorService: LvErrorService,
  ) {
    this.credit = {
      issuerCreditParameters: {
        creditTermStructure: [],
        creditCDSTermStructure: [],
        creditDefaultRateTermStructure: [],
        creditSurvivalProbabilityTermStructure: []
      },
    } as IBondCredit;

    this.creditSource = new LvLookupEnum(CreditSourceDescription);
    this.didSessionUpdatedEvent = new EventEmitter();
    this.didSessionDataLoadedEvent = new EventEmitter();
    this.isSectionLoading = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this._analyticsPresenter.onModelLoading.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(isLoading => this.setLoadingState(isLoading));

    if (!!this._analyticsPresenter.getModelData()?.bondValuationSession?.marketData?.credit) {
      this.credit = this._analyticsPresenter.getModelData().bondValuationSession?.marketData?.credit
      this.originalValue = _.cloneDeep(this.credit);
    }

    this._analyticsPresenter.onAnalyticsSettingsUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        if(!!this._analyticsPresenter.getModelData()?.bondValuationSession){
          this.setCredit();
        }
      });

    this._analyticsPresenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        this.setCredit();
      });
  }

  /**
   * Update market data value in bond valuation session.
   */
  onCreditSectionChanged() {
    this._analyticsPresenter.overrideBondInSession(bondValuationSession => {
      bondValuationSession.marketData.credit = this.credit;
    }, true);

    if (!_.isEqual(this.credit, this.originalValue)) {
      this.didSessionUpdatedEvent.next();
      this.originalValue = _.cloneDeep(this.credit);
    }

    this._changeDetectorRef.detectChanges();
  }

  /**
   * On credit parameters change update credit section and value in valuation session.
   * @param creditParameters 
   */
  onCreditParametersChange(creditParameters: IBondCreditParameters) {
    this.credit.issuerCreditParameters = creditParameters;
    this.onCreditSectionChanged();
  }

  /**
  * Gets section.
  * @returns PricingEnvironmentSections object.
  */
  getSection(): PricingEnvironmentSections {
    return this.creditSection;
  }

  /**
  * Applies current changes.
  */
  applyCurrentChanges() {
    if (!!this.issuerCreditParameters
      && (this.credit.creditSource === CreditSource.CDSTermStructure
        || this.credit.creditSource === CreditSource.TermStructure
        || this.credit.creditSource === CreditSource.DefaultRateTermStructure
        || this.credit.creditSource === CreditSource.SurvivalProbabilityTermStructure)) {
      this.issuerCreditParameters.creditTermStructureComponent.applyAdvancedGridChanges();
    }
  }

  /**
 * Gets settings.
 * @returns ISaveBondCreditRequest object.
 */
  getSettings(): ISaveBondCreditRequest {
    return this.getCreditSaveRequest();
  }

  /**
   * Set credit model.
   *
   * @private
   * @memberof LvBondCreditComponent
   */
  private setCredit() {
    if (!!this._analyticsPresenter.getModelData()?.bondValuationSession?.marketData?.credit) {
      this.credit = this._analyticsPresenter.getModelData().bondValuationSession?.marketData?.credit;
    }

    this.originalValue = _.cloneDeep(this.credit);
    this._changeDetectorRef.detectChanges();
  }

  /**
  * Gets credit save request.
  * @returns ISaveBondCreditRequest object.
  */
  private getCreditSaveRequest(): ISaveBondCreditRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      environmentId: env.id,
      credit: this.credit
    } as ISaveBondCreditRequest;
  }

  /**
   * Occurs on change environment and loads credit.
   * @param environment IEnvironmentSettingsItem object.
   */
  async onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    await this.loadCredit(environment);
    this.didSessionDataLoadedEvent.next();
  }

  async loadCredit(environment: IEnvironmentSettingsItem) {
    this._analyticsPresenter.loadBondCredit(environment);
    try {
      this.setLoadingState(true);

      this._analyticsPresenter.loadBondCredit(environment);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
      this.isSectionLoading.next(false);
    }
  }

  /**
   * Gets selected environment ID.
   * @returns Selected environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();

    if (env) {
      return env.id;
    }

    return null;
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this.isSectionLoading.next(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
