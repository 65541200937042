import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LvAnalyticsError } from '../../models/errors';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvErrorType, ILvError, LvDataMaster } from '@lv-core-ui/models';
import { LvDateService } from '@lv-core-ui/services';
import { ConvertibleBondTermsDocument } from '@lv-convertible-bond/models';
import { IValuationSession } from '@lv-analytics/models';
import { ITermsChangesTracker } from '@lv-analytics/models/terms-changed-priority';

/**
 * Convertibles service.
 */
@Injectable()
export class ConvertiblesSessionService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/analytics/valuationSession');
  }

  /**
   * Load draft in session.
   * @param terms Convertible bond draft terms.
   * @param sessionId Current session identifier.
   * @returns Populated session object.
   */
  async loadDraftInSession(   
    terms: ConvertibleBondTermsDocument,
    sessionId: string,
    ): Promise<IValuationSession> {
    try {
      const result = await this.postAsync<IValuationSession>({         
        terms: terms,
        sessionId: sessionId,
      }, '/loadDraftInSession');

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  async overrideTermsInSession (   
    terms: ConvertibleBondTermsDocument,
    sessionId: string,
    termsChangesTracker: ITermsChangesTracker,
    ): Promise<IValuationSession> {
    try {
      const result = await this.postAsync<IValuationSession>({         
        terms: terms,
        sessionId: sessionId,
        termsChangesTracker: termsChangesTracker,
      }, '/overrideTermsInSession');      

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Handles unauthorized error.
   * @param e ILvError object.
   * @returns LvAnalyticsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvAnalyticsError {
    const error = new LvAnalyticsError(e.message);

    if (e.type === LvErrorType.AUTHORIZATION) {
      error.type = e.type;
    }

    return error;
  }
}
