import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input,
  Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PopupSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { InstrumentMonitorService } from '@lv-instrument-monitor/instrument-monitor.service';
import { IInstrumentLookup } from '@lv-instrument-monitor/models';


@Component({
  selector: 'lv-instrument-search',
  templateUrl: './lv-instrument-search.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentSearchComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('autoCompleteWidthElement', { static: true }) autoCompleteWidthElement: ElementRef;
  @ViewChild(AutoCompleteComponent, { static: true }) autoComplete: AutoCompleteComponent;

  @Input() instrumentName: string;
  @Input() readonly: boolean;

  @Output() didSelectInstrument: EventEmitter<IInstrumentLookup>;

  get autoCompleteWidth(): number {
    if (!this.selectedInstrument) {
      return 330;
    }

    const hiddenEl: HTMLElement = this.autoCompleteWidthElement.nativeElement;

    if (hiddenEl.clientWidth < 1) {
      return 260;
    }

    const width = hiddenEl.clientWidth + 40;

    return width;
  }

  get isInstrumentSelected(): boolean {
    return !!this.selectedInstrument;
  }

  data: IInstrumentLookup[];
  selectedInstrument: string;
  internalChange: boolean;

  popupSettings: PopupSettings;

  private _autoCompleteSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private service: InstrumentMonitorService
  ) {
    this.didSelectInstrument = new EventEmitter<IInstrumentLookup>();
    this.data = [];

    this.popupSettings = {
      height: 300,
      width: 660
    };

    this.internalChange = false;
  }

  ngOnInit() {
    this._autoCompleteSubscription = this.autoComplete.filterChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(
        async (value: string) => {
          try {
            this.data = [];

            if (value) {
              const query = (value || '').trim().toLowerCase();
              this.data = await this.service.lookupService.getInstrumentLookup(query, 0, 100);
            }
            else {
              this.selectedInstrument = null;
            }

            this.changeDetectorRef.detectChanges();
          }
          catch (error) {
            console.log(error);
          }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.instrumentName.previousValue !== changes.instrumentName.currentValue && !this.internalChange) {
      this.selectedInstrument = this.instrumentName;
    }
    else {
      this.internalChange = false;
    }
  }

  onValueChange(value: string) {
    this.internalChange = true;

    const query = (value || '').trim().toLowerCase();
    let found = null;

    if (query) {
      found =  this.data.find(a => {
        const name = (a.text || '').trim().toLowerCase();
        return name === query;
      });
    }

    if (found) {
      this.selectedInstrument = found.text;
    }

    this.didSelectInstrument.emit(found || {} as IInstrumentLookup);
  }

  ngOnDestroy() {
    if (this._autoCompleteSubscription) {
      this._autoCompleteSubscription.unsubscribe();
    }
  }

  onInstrumentSearchFieldClick() {
    this.autoComplete.searchbar.input.nativeElement.select();
  }
}
