import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { IBondWithholdingTaxSectionSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-withholding-tax-settings/market-data-bond-withholding-tax-section';

@Component({
  selector: 'lv-withholding-tax-section',
  templateUrl: './lv-withholding-tax-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvWithholdingTaxBondSectionComponent {
  @Input() settings: IBondWithholdingTaxSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  constructor () {
    this.overrideSystemDefaults = true;
  }
}
