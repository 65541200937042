import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBaseBondTermsSettings } from '../../../models/bond-terms-settings';
import { IBondGeneralSectionSettings } from '../../../models/bond-terms-settings/bond-general-settings/bond-general-section-settings';

@Component({
  selector: 'lv-bond-general-settings',
  templateUrl: './lv-bond-general-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondGeneralSettingsComponent {

  @Input() systemDefaults: IBaseBondTermsSettings<IBondGeneralSectionSettings>;
  @Input() myDefaults: IBaseBondTermsSettings<IBondGeneralSectionSettings>;

  constructor() {
  }

  @HostBinding('class.lv-general-settings')
  get isLvGeneralSettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
  getTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
