import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IBondCallAdjustmentModelSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/call-adjustment-model-settings';
import { ICreditModelSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/credit-model-settings';
import { IBondModelCustomizationSectionSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/model-customization-section-settings';
import { IBondModelCustomizationSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/model-customization-settings';
import { ISaveBondModelCustomizationSettingsRequest } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/save-model-customization-settings-request';
import { CompanyAndUserSettingsService } from '@lv-analytics/services/company-settings/company-settings.service';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import _ from 'lodash';

@Component({
  selector: 'lv-model-customization-bond-settings',
  templateUrl: './lv-model-customization-bond-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvModelCustomizationBondSettingsComponent implements OnInit, IDiscardable {

  @Output() didCancel: EventEmitter<void>;
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;

  settings: IBondModelCustomizationSettings;
  originalSettings: IBondModelCustomizationSettings;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _discardChangesService: DiscardChangesService
  ) {
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {
        creditModelSettings: {} as ICreditModelSettings,
        callAdjustmentModelSettings: {} as IBondCallAdjustmentModelSettings
      } as IBondModelCustomizationSectionSettings,
      environments: []
    } as IBondModelCustomizationSettings;
    
    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('ModelCustomization', this);
  }

  @HostBinding('class.lv-flex-box')
    get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
    get isFlexColumnComponent() {
    return true;
  }

   @HostBinding('class.lv-model-customization-bond-settings')
    get isLvModelCustomizationSettingsComponent() {
    return true;
  }
  @HostBinding('class.lv-section-height')
    get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getModelCustomizationSettings();
  }

  /**
   * Gets model customization settings.
   */
  async getModelCustomizationSettings() {
    try {
      this.startLoading.next();
      this.settings = await this._companyAndUserSettingsService.getModelCustomizationBondSettings();
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves model customization settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next();
      await this._companyAndUserSettingsService.saveModelCustomizationBondSettings({
        settings: this.settings
      } as ISaveBondModelCustomizationSettingsRequest);

      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'Model Customization'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * Resets model customization settings.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();
      this.settings = await this._companyAndUserSettingsService.resetModelCustomizationBondSettings();

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'Model Customization'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
   this.didCancel.next();
  }

  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }

}
