import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { IssueAndRedemption } from '@lv-instrument-common/IssueAndRedemption';
import { Currency, IsQuotedClean, RecoveryUponDefaultDescription } from '@lv-instrument-common/index';
import { Subscription, filter } from 'rxjs';
import { LvBondTermsPresenter } from '../lv-bond-terms.presenter';
import { SinkingFundScheduleItem } from '@lv-instrument-common/SinkingFundScheduleItem';
import { BondTermsSectionEvent } from '../../../models/bond-terms/enums/bond-terms-section-events';
import { BondTermsDocument } from '../../../models/bond-terms/BondTermsDocument';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { v4 } from 'uuid';
import { BondGeneral } from '../../../models';


@Component({
  selector: 'lv-bond-general',
  templateUrl: './lv-bond-general.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvBondGeneralComponent implements OnInit, OnDestroy {
  @Input() model: BondGeneral;

  get dateFormated(): string {
    if (!(this.model && this.model.maturityDate)) {
      return '';
    }

    return new Date(this.model.maturityDate).toLocaleDateString('en-GB');
  }

  instrumentType: string;
  currencyLookup: LvLookupEnum;
  isQuotedCleanLookup: LvLookupEnum;
  recoveryUponDefaultLookup: LvLookupEnum;
  maturityDatePerpetualCheckboxId: string;
  issueValueNominalCheckboxId: string;
  issueValueRedemptionCheckboxId: string;
  priceAsParCheckboxId: string;
  accruedUponDefaultCheckboxId: string;
  sinkableCheckboxId: string;
  quotedValue: string;
  numberOfDecimalsPercentageAssumption = '3';
  numberFormatAssumption = '#,###.###';
  decimalsFour = '4';
  formatFour = '#,###.####';
  decimalsTwo = '2';
  formatTwo = '#,###.##';
  issueAndRedemption: IssueAndRedemption;

  private _modelSubscription: Subscription;
  private _destroyRef = inject(DestroyRef)

  get isPerpetual(): boolean {
    return this.model.isPerpetual;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,
  ) {
    this.instrumentType = 'Bond';
    this.currencyLookup = new LvLookupEnum(Currency);
    this.isQuotedCleanLookup = new LvLookupEnum(IsQuotedClean);
    this.recoveryUponDefaultLookup = new LvLookupEnum(RecoveryUponDefaultDescription);
    this.maturityDatePerpetualCheckboxId = v4();
    this.issueValueNominalCheckboxId = v4();
    this.issueValueRedemptionCheckboxId = v4();
    this.priceAsParCheckboxId = v4();
    this.accruedUponDefaultCheckboxId = v4();
    this.sinkableCheckboxId = v4();
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef)).pipe(
      filter(event => event === BondTermsSectionEvent.CallIssueAndRedemptionEvent || event === BondTermsSectionEvent.TermsCleanUpCallEvent
        || event === BondTermsSectionEvent.PutIssueAndRedemptionEvent || event === BondTermsSectionEvent.ModelLoadedEvent)
    ).subscribe(event => {
      this.model = this._presenter.getModel().general;
      this.quotedValue = this.model.isQuotedClean ? IsQuotedClean.Clean : IsQuotedClean.Dirty;
      this._changeDetectorRef.detectChanges();
    });

    this.quotedValue = this.model.isQuotedClean ? IsQuotedClean.Clean : IsQuotedClean.Dirty;
    this._changeDetectorRef.detectChanges();
  }


  /**
   * Fired when general model changed
   */
  doModelChange(sourceOfUpdate: string = 'other') {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.general = this.model;
    }, BondTermsSectionEvent.TermsGeneralEvent,
      sourceOfUpdate);
  }

  /**
 * Fired when general model dates changed
 */
  doModelDatesChange(sourceOfUpdate: string) {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.general = this.model;
    }, BondTermsSectionEvent.TermsGeneralDatesEvent,
      sourceOfUpdate);
  }

  /**
 * Fired when general CCY changed
 */
  onCCYChange() {
    this.doModelChange('DM-379');
  }

  /**
 * Fired when general First Settlement dates changed
 */
  onFirstSettlementDateChange() {
    if (!!this.model.firstSettlementDate) {
      this.doModelDatesChange('DM-370');
    }
  }

  /**
 * Fired when general Maturity changed
 */
  onMaturityDateChange() {
    if (!!this.model.maturityDate) {
      this.doModelDatesChange('DM-371');
    }
  }

  /**
   * Fired when general Price as Par changed
   */
  onPriceAsParChange() {
    this.doModelChange('DM-382');
  }

  /**
 * Fired when general quoted changed
 */
  quotedValueChange(value: IsQuotedClean) {
    this.model.isQuotedClean = value === IsQuotedClean.Clean;
    this.doModelChange();
  }

  /**
   * Set Sinking schedule
   */
  doSetSchedule(schedule: SinkingFundScheduleItem[]) {
    this.model.sinkingFundSchedule = schedule;
    this.doModelChange();
  }

  ngOnDestroy(): void {
    this._modelSubscription.unsubscribe();
  }
}
