import { IConvertible } from '../models/convertible/convertible';
import { ConvertibleSubType } from '../models/convertible/enum/convertible-sub-type';
import { ConvertibleSetupStatus } from '../models/convertible/enum/convertible-setup-status';
import { CouponType } from '../models/convertible/enum/coupon-type';
import { ICrEstimateSettings } from '../models/company-and-user-settings/estimates-settings';
import { IPriceTalk } from '../models/convertible/price-talk';
import { CashDistributionDateType, ConversionSettlement, DividendProtectionType, ExchangeableType} from '@lv-convertible-bond/models';
import { ContingentConversionPeriodType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';
import { LvDateService } from '@lv-core-ui/services';
import { AccretionType, CallMakeWholeType, CallTriggerPeriodType, PutValueType } from '@lv-instrument-common/index';

export const NOT_AVAILABLE = 'N/A';
export const NOT_AVAILABLE_DATE = new Date();

/**
 * Convertible helper.
 */
export class ConvertibleHelper {
  
  public convertible: IConvertible;

  constructor(
    private _lvDateService: LvDateService
  ) {
    this.convertible = null;
  }

  /**
   * Initializes convertible.
   * @param convertible IConvertible object.
   */
  init(convertible?: IConvertible) {
    this.convertible = convertible;
  }

  get isAccreated(): boolean {
    if (!this.convertible) {
      return null;
    }

    return (this.convertible.accretionType === AccretionType.UseRedemptionPrice && this.issueValue !== this.redemptionValue)
        || (this.convertible.accretionType === AccretionType.Fixed && this.convertible.fixedAccretionRate > 0);
  }

  get useAcquisitionSharesSettlement(): boolean {
    if (!this.convertible) {
      return null;
    }

    return !!this.convertible.useAcquisitionSharesSettlement;
  }

  get isValid(): boolean {
    return !!this.convertible;
  }

  get priceTalk(): IPriceTalk {
    if (!this.isValid) {
      return null;
    }

    return this.convertible.priceTalk;
  }

  

  get exchangeableType(): ExchangeableType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.exchangeableType;
  }

  get conversionSettlement(): ConversionSettlement {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.conversionSettlement;
  }

  get isRebate(): boolean {
    if (!this.convertible) {
      return false;
    }

    return !!this.convertible.rebate;
  }

  get isCallable(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isCallable;
  }

  get callTriggerPeriodType(): CallTriggerPeriodType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.callTriggerPeriodType;
  }

  get contingentConversionPeriodType(): ContingentConversionPeriodType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.contingentConversionPeriodType;
  }

  get optionalReset(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.optionalReset;
  }

  get puttable(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.puttable;
  }

  get dividendProtection(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.dividendProtection;
  }

  get contingentConversion(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.contingentConversion;
  }

  get resettable(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.resettable;
  }

  get variableConversion(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.variableConversion;
  }

  get isCrossFx(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isCrossFx;
  }

  get dividendProtectionType(): DividendProtectionType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.dividendProtectionType;
  }

  get cashDistributionDateType(): CashDistributionDateType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.cashDistributionDate;
  }

  get isMandatoryConversion(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.mandatoryConversion;
  }

  get redemptionValue(): number {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.redemptionValue;
  }

  get issueValue(): number {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.issueValue;
  }

  get isIssueAndRedemptionDifferent(): boolean {
    if (!this.redemptionValue || !this.issueValue) {
      return false;
    }

    return this.redemptionValue === this.issueValue;
  }

  /**
   * Get Put Value Type.
   */
  get putValueType(): PutValueType {
    if (!this.convertible) {
      return;
    }

    return this.convertible.putValueType;
  }

    /**
   * Get Partial Put.
   */
  get partialPut(): boolean {
    if (!this.convertible) {
      return;
    }

    return this.convertible.partialPut;
  }

  /**
   * Gets safe nominal.
   * @returns Safe nominal.
   */
  getSafeNominal(): number {
    if (!this.convertible) {
      return null;
    }
    return this.convertible.nominal !== 0 ? this.convertible.nominal : 1;
  }

  get isNewIssue(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.status === ConvertibleSetupStatus.NewIssue;
  }

  get isPeps(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.subType === ConvertibleSubType.PEPS;
  }

  get isPercs(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.subType === ConvertibleSubType.PERCS;
  }

  get isRegular(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.subType === ConvertibleSubType.Regular;
  }

  get isDetachableWarrant(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.subType === ConvertibleSubType.ConvertibleWithDetachableWarrant;
  }

  get isDeltaNeutral(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isDeltaNeutral;
  }

  get isFloatingCoupon(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.couponType === CouponType.Floating;
  }

  get isZeroCoupon(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.couponType === CouponType.ZeroCoupon;
  }

  get isRatchetMatrixCallMakeWhole(): boolean {
    if (!this.convertible || this.convertible.callMakeWholeType === null) {
      return false;
    }

    return this.convertible.callMakeWholeType === CallMakeWholeType.RatchetMatrix;
  }

  get isRatchetMatrixConversionMakeWhole(): boolean {
    if (!this.convertible || this.convertible.conversionMakeWholeType === null) {
      return false;
    }

    return this.convertible.conversionMakeWholeType === CallMakeWholeType.RatchetMatrix;
  }

  get isCallMakeWhole(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isCallMakeWhole;
  }

  get isConversionMakeWhole(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isConversionMakeWhole;
  }

  get isCallTriggerNotZero(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isCallTriggerNotZero;
  }

  get isPriceAsPar(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isPriceAsPar;
  }

  get isExchangeable(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isExchangeable;
  }

  get firstSettlementDate(): Date {
    if (!this.convertible) {
      return NOT_AVAILABLE_DATE;
    }

    return new Date(this.convertible.firstSettlementDate);
  }

  get callMakeWholeType(): CallMakeWholeType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.callMakeWholeType;
  }

  get conversionMakeWholeType(): CallMakeWholeType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.conversionMakeWholeType;
  }

  get currencyCode(): string {
    if (!this.convertible) {
      return NOT_AVAILABLE;
    }

    return this.convertible.currencyCode;
  }

  get underlyingCurrencyCode(): string {
    if (!this.convertible) {
      return NOT_AVAILABLE;
    }

    return this.convertible.underlying.currency;
  }

  get faceValueFactor(): number {
    if (!this.convertible) {
      return null;
    }

    return this.isPriceAsPar ? this.convertible.nominal : 1;
  }

  get priceAsParFactor(): number {
    return this.isPriceAsPar ? 0.01 : 1;
  }

  get hasDeltaNeutralConversionAmount(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.deltaNeutralConversionAmount;
  }

  get fixedFx(): number {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.fixedFxRate;
  }

  get accessScope(): AccessScopeType {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.accessScope;
  }

  get isOwner(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isOwner;
  }

  get isUnderlyingRebate(): boolean {
    if (!this.convertible) {
      return null;
    }
    return  this.convertible.rebateCCYID === -2;
   } 

  /**
   * Gets conversion ratio.
   * @param creSettings ICrEstimateSettings object.
   * @returns Conversion ratio value.
   */
  getConversionRatio(creSettings: ICrEstimateSettings, valuationDate: Date): number {
    if (!this.convertible) {
      return null;
    }

    if (!creSettings) {
      return this.convertible.conversionRatio;
    }

    if (this.convertible.expectedCR && this.convertible.expectedCrEffectiveDate) {
      //we need additionally inspect valuation date because in case of manually typing valuation date, we have situation that ngOnChanges are fired after we type first number of year
      //for example 02/02/2, this is actually 02/02/0002 and this is valid date but for this condition above about effective date we must has complete date in valuation date field
      if (this.isValuationDateLessThanCrEffectiveDate(valuationDate) && new Date(valuationDate) > new Date(new Date(1900, 0, 1))) {
        creSettings.useEstimatedRatio = false;
      }

      return creSettings.useEstimatedRatio ? this.convertible.expectedCR : this.convertible.conversionRatio;
    }

    return this.convertible.conversionRatio;
  }

  /**
   * Checks if CR effective date is in future and if it is less than valuation date.
   * @param valuationDate Valuation date.
   * @returns A flag indicating if CR effective date is in future and if it is less than valuation date.
   */
  isCrEffectiveDateInFutureAndIsLessThanValuationDate(valuationDate: Date) {
    if (!this.convertible || !this.convertible.expectedCrEffectiveDate) {
      return false;
    }
    const crEfectiveDate = this._lvDateService.getStartOfDay(this.convertible.expectedCrEffectiveDate);

    if (!valuationDate) {
      return false;
    }

    const valuationDateDate = this._lvDateService.getStartOfDay(valuationDate);

    // check is valuation date is after 1970-01-01 becouse if you are typing valuation date and enter 20 or 200
    // this will be a valid date far in the past
    const minDate = this._lvDateService.getStartOfDay(new Date(0));
    if (this._lvDateService.isFirstDateBeforeSecond(valuationDateDate, minDate)) {
      return true;
    }

    return !this._lvDateService.isFirstDateAfterSecond(crEfectiveDate, valuationDateDate);
  }

  /**
   * Checks if valuation date is less than CR effective date.
   * @param valuationDate Valuation date.
   * @returns A flag indicating if valuation date is less than CR effective date.
   */
   isValuationDateLessThanCrEffectiveDate(valuationDate: Date) {
    if (!this.convertible || !this.convertible.expectedCrEffectiveDate) {
      return false;
    }
    const crEfectiveDate = this._lvDateService.getStartOfDay(this.convertible.expectedCrEffectiveDate);

    if (!valuationDate) {
      return false;
    }
    const valuationDateDate = this._lvDateService.getStartOfDay(valuationDate);

    return this._lvDateService.isFirstDateAfterSecond(crEfectiveDate, valuationDateDate);
  }

  
}
