import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { IBorrowScheduleItem } from '@lv-analytics/models/market-data/borrow/borrow-schedule-item';
import { LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { LvAdvancedGridComponent } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid.component';
import { LvError } from '@lv-core-ui/models/lv-error';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'lv-equity-borrow-term-structure',
  templateUrl: './lv-equity-borrow-term-structure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityBorrowTermStructureComponent implements OnInit {
  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() scheduleItems: IBorrowScheduleItem[];
  @Input() isEditable: boolean;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get isGridDisabled (): boolean {
    return !this.isEditable;
  }

  get addStripedGridClass (): string {
    return !this.isEditable ? 'lv-advanced-grid-stripped' : '';
  }

  columns: LvAdvancedGridColumn[];
  parseFn: any;
  excelFieldAlias = 'BRW_SCHED_RANGE';
  
  private _destroyRef = inject(DestroyRef)


  constructor (
    private _errorService: LvErrorService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.scheduleItems = [];
    this.parseFn = this.parsePastedData.bind(this);
    this.didSessionUpdatedEvent = new EventEmitter();
    this.isEditable= true;
  }

  @HostBinding('class.lv-equity-borrow-term-structure')
  get isLvEquityBorrowTermStructureComponent(): boolean {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.advancedGrid.didDataChange
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe((records: IBorrowScheduleItem[]) => this.onDataChange(records)),
    this.advancedGrid.doReload
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe(() => this.onReload()),
    this.advancedGrid.didError
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe((error: LvError) => this.onError(error))
    this.initColumns();
  }

  /**
   * Occurs on data change.
   * @param records List of IBorrowScheduleItem objects.
   */
  public onDataChange(records: IBorrowScheduleItem[]) {
    this.applyRecords(records);
    this.didSessionUpdatedEvent.next();
  }

  /**
   * Applies advanced grid changes.
   */
  public applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on reload and reloads schedule items.
   */
  public onReload() {
    this.scheduleItems = this.scheduleItems.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

   /**
   * Create form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
   public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate, Validators.required),
      'borrowFee': new FormControl(args.dataItem.borrowFee, Validators.required)
    });
  }
    
  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.scheduleItems.splice(0, this.scheduleItems.length);
    this.scheduleItems.push(...records);
  }

  /**
   * Column initialization.
   */
  private initColumns() {
    this.columns = [];
    const borrowEndDateColumn = new LvAdvancedGridDateColumn();
    borrowEndDateColumn.title = 'End Date';
    borrowEndDateColumn.field = 'endDate';
    borrowEndDateColumn.width =  this.isEditable ? 104 : 121;
    borrowEndDateColumn.dmKey = 'DM-631';
    borrowEndDateColumn.editable = this.isEditable;

    const borrowFeeColumn = new LvAdvancedGridNumericColumn();
    borrowFeeColumn.title = 'Borrow Fee (bps)';
    borrowFeeColumn.field = 'borrowFee';
    borrowFeeColumn.decimals = '0';
    borrowFeeColumn.format = 'n0';
    borrowFeeColumn.width = this.isEditable ? 104 : 116;
    borrowFeeColumn.dmKey = 'DM-632';
    borrowFeeColumn.editable = this.isEditable;

    this.columns.push(borrowEndDateColumn);
    this.columns.push(borrowFeeColumn);
  }

   /**
   * Parse pasted data.
   * @param pastedDataRecords Pasted data records.
   * @returns IBorrowScheduleItem object.
   */
   private parsePastedData(pastedDataRecords: string[]): IBorrowScheduleItem[] {
    const borrowScheduleItems: IBorrowScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const endDateValue = items[0];
      const borrowFeeValue = items[1];

      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
      const borrowFee = MarketDataClipboard.parseNumberValue(borrowFeeValue, 'Borrow Fee');

      borrowScheduleItems.push({
        endDate: endDate,
        borrowFee: borrowFee
      });
    });

    return borrowScheduleItems;
  }
}
