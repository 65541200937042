
export enum CallTriggerType {
	AsPerOfPar = 'AsPerOfPar',
	AsPerOfYieldCalculatedValue = 'AsPerOfYieldCalculatedValue',
	AsPerOfAccretedValue = 'AsPerOfAccretedValue',
	AbsoluteUnderlyingPrice = 'AbsoluteUnderlyingPrice'
}

export enum CallTriggerTypeDescription {
	AsPerOfPar = 'As % of Par',
	AsPerOfYieldCalculatedValue = 'As % of Yield Calculated Value',
	AsPerOfAccretedValue = 'As % of Accreted Value',
	AbsoluteUnderlyingPrice = 'Absolute Underlying Price'
}