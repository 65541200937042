<lv-flex-box
  data-cy="DM-286"
  direction="row"
  class="lv-equity-volatility">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="volatilityFlatTooltipTemplate"
    filter="span:not(lv-xl-label span)">
    <span>
      Volatility
    </span>
    <lv-xl-label [field]="'VOL_FLAT'"></lv-xl-label>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      *ngIf="isEditable"
      name="volatility"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="volatilityFlat.flatVol"
      (didBlur)="onVolatilityChange()"
      (didKeyDownEnter)="onVolatilityChange()">
    </lv-numeric-text-box>
    <span *ngIf="!isEditable">
      {{volatilityFlat.flatVol}}
    </span>
    <span
      class="lv-sufix-label"
      lvId="volatilitySuffix"
      [class.lv-suffix-no-value]="!volatilityFlat.flatVol">%</span>
  </div>
</lv-flex-box>

<ng-template #volatilityFlatTooltipTemplate>
  <lv-tooltip dm="dM-286"></lv-tooltip>
</ng-template>
