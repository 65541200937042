import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy,
  ChangeDetectorRef, ElementRef, ViewChild, Optional } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { LvPutsView } from './lv-puts.view';
import { LvPutsPutScheduleComponent } from './lv-puts-put-schedule/lv-puts-put-schedule.component';
import { ConvertibleBondTermsSectionEvent } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

@Component({
  selector: 'lv-puts',
  templateUrl: './lv-puts.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPutsComponent implements OnInit, OnDestroy {

  @ViewChild('putSchedule') putSchedule: LvPutsPutScheduleComponent;

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  public view: LvPutsView;
  private _modelSubscription: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.view = new LvPutsView(_lvConvertibleBondTermsService);
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated
      .pipe(
        filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.PutsEvent &&
                        event.eventId !== ConvertibleBondTermsSectionEvent.PutIssueAndRedemptionEvent &&
                        event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
                        event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
      )
      .subscribe(event => {
        if (event.data) {
          this.view.init(
            event.data.fullTerms.put,
            event.data.fullTerms.issueAndRedemption,
            event.data.fullTerms.coupon,
            this.isOpenedFromExcel);

          this._changeDetectorRef.detectChanges();
        }
      });

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;

      this.view.init(fullTerms.put,
        { ... fullTerms.issueAndRedemption},
        fullTerms.coupon,
        this.isOpenedFromExcel);

      this._changeDetectorRef.detectChanges();
    }
  }

  onPutChange() {
    this.view.putChange(this.isOpenedFromExcel);
    this.onModelChange(false);

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.PutIssueAndRedemptionEvent,
      data: this.view.issueAndRedemption,
      sourceOfUpdate: 'other'
    });
  }

  onPutValueTypeChange() {
    if (!!this.putSchedule) {
      this.putSchedule.resetPutPriceSchedule(this.view.model.valueType);
    }

    this.onModelChange();
  }

  onModelChange(publishExternalEvent: boolean = true) {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.PutsEvent,
      data: this.view.model,
      sourceOfUpdate: 'other'
    }, publishExternalEvent);
  }

  applyScheduleChanges() {
    if (this.view.model && this.putSchedule) {
      this.putSchedule.applyAdvancedGridChanges();
    }
  }

  /**
   * Method updates TotalTranchePct field every time Tranche % column sum changes
   * @param trancheSum Sum of all values in Tranche % column from put schedule.
   */
  onTotalTranchePctSumChanged(trancheSum: number) {
    this.view.model = {...this.view.model, totalTranchePct: trancheSum};
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this._modelSubscription.unsubscribe();
  }

  getPuttableTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
