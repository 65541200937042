<lv-flex-box class="lv-syncing-equity-container"> 
  <lv-flex-box
    class="lv-sync-all-equity-data"
    data-cy="DM-4794"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="syncAllEquityDataTooltipTemplate"
      filter="span">
      <span> Sync All Equity Data</span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [attr.id]="syncAllEquityDataCheckboxId"
        name="sync-all-equity-data"
        [(ngModel)]="syncAllEquityData.syncAllEquityData">
      <label class="k-checkbox-label"
        lvId="syncAllEquityData"
        [attr.for]="syncAllEquityDataCheckboxId">
      </label>
    </div>
  </lv-flex-box>

  <lv-flex-box class="lv-settings-footer" direction="row">    
    <button kendoButton
      [primary]="true"
      lvId="sync-all-equity-data"
      (click)="onCancel()">
        Cancel
    </button>
    <div class="lv-settings-footer-divider">&nbsp;</div>

    <button kendoButton
      class="reset-button"
      [primary]="true"
      lvId="reset-to-default-sync-all-equity-data"
      (click)="onResetToDefault()">
      Reset To Default
    </button>
    <button kendoButton
      [primary]="true"
      [class.save-settings-btn-disabled]="!isChanged()"
      lvId="save-sync-all-equity-data"
      (click)="onSave()">
        Save
    </button>
  </lv-flex-box>
</lv-flex-box>

<ng-template #syncAllEquityDataTooltipTemplate>
  <lv-tooltip dm="dM-4794"></lv-tooltip>
</ng-template>

