
export enum CallValueType {
	PerOfPar = 'PerOfPar',
	GrossYield = 'GrossYield',
	NetYield = 'NetYield',
	AccretedValue = 'AccretedValue',
	PepsMinimumRatio = 'PepsMinimumRatio',
	PepsMaximumRatio = 'PepsMaximumRatio',
	PepsVariableRatio = 'PepsVariableRatio',
	PepsThresholdDependentRatio = 'PepsThresholdDependentRatio',
	AcquisitionNotice = 'AcquisitionNotice'
}

export enum CallValueTypeDescription {
	PerOfPar = '% of Par',
	GrossYield = 'Gross Yield',
	NetYield = 'Net Yield',
	AccretedValue = 'Accreted Value',
	PepsMinimumRatio = 'PEPS - Minimum Ratio',
	PepsMaximumRatio = 'PEPS - Maximum Ratio',
	PepsVariableRatio = 'PEPS - Variable Ratio',
	PepsThresholdDependentRatio = 'PEPS - Threshold Dependent Ratio'
  }

  export enum CallValueTypeBondDescription {
	PerOfPar = '% of Par',
	GrossYield = 'Gross Yield',
	NetYield = 'Net Yield',
	AccretedValue = 'Accreted Value',
  }
  