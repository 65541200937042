import { marketData } from './marketDataMock';
import { selectedEnvironments } from './selectedEnvironmentsMock';
import { pricing } from './pricingMock';
import { modelCustomization } from './modelCustomizationMock';
import { instrumentSettings } from './instrumentSettingsMock';

export const valuationSession = {
  instrumentSettings: instrumentSettings,
  isPrivateInstrument: false,
  lwsIdentifier: '7f4c242f-8d1d-415d-a948-803f37c10560',
  modelCustomization: modelCustomization,
  outputsConfiguration: {useDefault: true, outputs: [], customOutputs: []},
  pricing: pricing,
  selectedEnvironments: selectedEnvironments,
  sessionId: 'da42ef6d-f0c3-4e97-ae87-ad7efdeff87e',
  valuationSettings: {marketData: marketData, lwsIdentifier: null, sessionId: null}
};
