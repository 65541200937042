import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBondInterestRatesSectionSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-interest-rates-settings/market-data-bond-interest-rates-section-settings';
import { IBondInterestRatesSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-interest-rates-settings/market-data-bond-interest-rates-settings';
import { ISystemYieldCurvesBondSettingsView } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-interest-rates-settings/system-yield-curves-bond-settings-view';

@Component({
  selector: 'lv-interest-rates-bond-settings',
  templateUrl: './lv-interest-rates-bond-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvInterestRatesBondSettingsComponent {
  @Input() settings: IBondInterestRatesSettings;
  @Input() systemYieldCurvesSettingsData: ISystemYieldCurvesBondSettingsView;

  constructor() {
    this.settings = {
      systemDefaults: {
        yieldCurveSettings: []
      } as IBondInterestRatesSectionSettings,
      environments: []
    } as IBondInterestRatesSettings;

    this.systemYieldCurvesSettingsData = {} as ISystemYieldCurvesBondSettingsView;
  }

  @HostBinding('class.lv-interest-rates-bond-settings')
  get isLvInterestRatesBondSettingsComponent () {
    return true;
  }

}
