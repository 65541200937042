import { AccrualMethod, CouponReferenceTenor, CouponType, DayCount, ExCouponPeriodType, Frequency, IndexType, PikCouponType, PikType } from "@lv-instrument-common/index";

export class BondCoupon {
    type: CouponType;
    dayCount: DayCount;
    accrualMethod: AccrualMethod;
    frequency: Frequency;
    firstCouponPaymentDate: Date;
    penultimateCouponPaymentDate: Date;
    accrualStartDate: Date;
    accrualEndDate: Date;
    isGuaranteed: boolean;
    guaranteedStartDate: Date;
    guaranteedStartEndDate: Date;
    exCouponPeriodType: ExCouponPeriodType;
    exCouponPeriod: number;
    couponDatesEOM: boolean;
    typeChangeDate: Date;
    flatTraded: boolean;
    fixed: FixedCouponData;
    floating: FloatingCouponData;
    custom: CustomCouponData;
    pik: PikCouponData;
}

export class PikCouponData {
    pikCouponType: PikCouponType;
    pikCouponRate: number;
    cashCouponRate: number;
    pikNominalAdjustment: number;
    pikEndDate: Date;
    pikCustomSchedule: PikCustomScheduleItem[];
}

export class PikCustomScheduleItem {
    date: Date;
    pikCouponOne: number;
    pikCouponTwo: number;
    cashCouponOne: number;
    cashCouponTwo: number;
    pikType: PikType;
}

export class FixedCouponData {
    rate: number;
    useStepupCouponSchedule: boolean;
    stepUpCouponSchedule: StepUpCouponScheduleItem[];
}

export class StepUpCouponScheduleItem {
    endDate: Date;
    cashCoupon: number;
}

export class FloatingCouponData {
    dayCount: DayCount;
    frequency: Frequency;
    index: Index;
    referenceTenor: CouponReferenceTenor;
    fixingDelay: number;
    multiplier: number;
    spread: number;
    floor: number;
    cap: number;
    useFloatingSpreadSchedule: boolean;
    useFloatingCustomDates: boolean;
    floatingSpreadSchedule: FloatingSpreadScheduleItem[];
    fixingHistoryItems: FixingHistoryItem[];
    floatingCustomDates: Date[];
}

export class FloatingSpreadScheduleItem {
    endDate: Date;
    spread: number;
    floor: number;
    cap: number;
}

export class FixingHistoryItem {
    date: Date;
    value: number;
}


export class Index {
    name: string;
    type: IndexType;
    currencyCode: string;
    bloombergTicker: string;
    bloombergUniqueId: string;
}

export class CustomCouponData {
    schedule: CustomCouponScheduleItem[];
}

export class CustomCouponScheduleItem {
    date: Date;
    value: number;
}