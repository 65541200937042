import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '../../application-settings.service';
import * as _ from 'lodash';
import { Subject, Observable } from 'rxjs';
import { DefaultWidgetType, IDefaultWidgetState } from '@lv-shared/models';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';

/**
 * Widget state manager class that handles widget interaction
 */
@Injectable()
export class WidgetStateManagerService {

  dashboardState: any;
  dashboardStateId: string;
  dashboardWidgetType: string;

  getWidgetStateFunc?: (dashboardState: any) => any;

  private _initialWidgetStates: Map<DefaultWidgetType, any>;

  private _customViewUsed: Subject<boolean>;
  private _saveAsDefaultAll: Subject<boolean>;

  public get onCustomViewUsed(): Observable<boolean> {
    return this._customViewUsed.asObservable();
  }
  public get onSaveAsDefaultAll(): Observable<boolean> {
    return this._saveAsDefaultAll.asObservable();
  }

  constructor(
    private _applicationSettingsService: ApplicationSettingsService,
    private _defaultWidgetStateService: LvSharedDefaultWidgetStateService
  ) {
    this._customViewUsed = new Subject<boolean>();
    this._saveAsDefaultAll = new Subject<boolean>();
    this._initialWidgetStates = new Map<DefaultWidgetType, any>();
  }

  /**
   * Sets dashboard state
   * @param state Dashboard state
   * @param {string} dashboardWidgetType Type of a widget
   */
  setDashboardState(state: any, dashboardWidgetType: string ) {
    this.dashboardState = state;
    this.dashboardWidgetType = dashboardWidgetType;
  }

  /**
   * Sets dashboard state and state function
   * @param state Dashboard state
   * @param {string} dashboardWidgetType Type of a widget
   * @param getWidgetStateFunc Callback function that accepts dashboardState as argument
   */
  setDashboardStateAndSetStateFunction(state: any, dashboardWidgetType: string, getWidgetStateFunc: (dashboardState: any) => any) {
    this.getWidgetStateFunc = getWidgetStateFunc;
    this.dashboardState = state;
    this.dashboardWidgetType = dashboardWidgetType;
  }

  /**
   * Reference to observable that holds send save default widget type event
   * @returns Observable of DefaultWidgetType
   */
  didDefaultStateUpdated(): Subject<DefaultWidgetType> {
    return this._applicationSettingsService.didSavedWidgetDefault;
  }

  /**
   * Reference to observable that holds send select default widget state event
   * @returns Observable of DefaultWidgetType
   */
  didSelectDefaultWidgetState(): Subject<void> {
    return this._applicationSettingsService.didSelectDefaultWidgetState;
  }

  /**
   * Sets state id
   * @param {string} stateId Id of a state
   */
  setStateId(stateId: string) {
    this.dashboardStateId = stateId;
  }

  /**
   * Sets widget into state
   * @param {DefaultWidgetType} widgetType Type of a widget
   * @param initialState Widget state
   */
  registerWidget(widgetType: DefaultWidgetType, initialState: any) {
    this._initialWidgetStates.set(widgetType, initialState);
  }

  /**
   * Get widget state based on widget type
   * @param {DefaultWidgetType} widgetType Type of a widget
   * @returns Widget state
   */
  getWidgetState(widgetType: DefaultWidgetType, instrumentType: string) {
    const wgt = this.getWidgetByType(widgetType);
    // When Instrument form is opened, and widget (e.g. Instrument info) is edited and saved in Edit custom view,
    // this change should persist as long as form is opened, even if user is logged out and then logged in or app is reloaded.
    // Bug was discovered by failing tests https://leversys.atlassian.net/browse/SYSTEM-5001, because below
    // condition always passed and default view was displayed.
    // Condition is extended with check if widget state is populated - !!wgt?.state.instrumentTypes,
    // which ensures, last saved custom view is displayed in currently opened Instrument form.
    if (!wgt.state || (!!wgt?.state.instrumentType && wgt?.state.instrumentType !== instrumentType)) {
      const dfState = this.getDefaultWidgetState(widgetType, instrumentType);
      wgt.state = _.cloneDeep(dfState);
    }
    return wgt.state;
  }

  /**
   * Reload dashboard state
   * @returns Boolean wether dashboard state is refreshed or not
   */
  refreshDashboardState(): boolean {
    const wgt = this._applicationSettingsService.getWidget(this.dashboardState.id);
    if (wgt) {
      this.dashboardState = { ...wgt.state } as any;
      return true;
    }
    return false;
  }

  /**
   * Sets all widgets to use default view
   */
  setAllWidgetsToUseDefaultView() {
    this._applicationSettingsService.publishDidSelectDefaultWidgetState();
  }

  /**
   * Get default widget state based on widget type
   * @param {DefaultWidgetType} widgetType Type of widget
   * @returns Widget state
   */
  getDefaultWidgetState(widgetType: DefaultWidgetType, instrumentType: string): any {
    const defaultWidgetState = this._defaultWidgetStateService.getDefaultWidgetState(widgetType, instrumentType);
    if (defaultWidgetState) {
      return _.cloneDeep(defaultWidgetState).state;
    }
    else {
      return _.cloneDeep(this._initialWidgetStates.get(widgetType));
    }
  }

  /**
   * Saves widget state
   * @param {DefaultWidgetType} type Type of widget
   * @param widgetState widget state
   */
  saveWidgetState(type: DefaultWidgetType,  widgetState: any) {
    const wg = this.getWidgetByType(type);
    wg.state = widgetState;
    this._applicationSettingsService.updateWidget(this.dashboardStateId, wgt => {
      wgt.state = this.dashboardState;
    });
  }

  /**
   * Saves widget state if it doesn't exist
   * @param {DefaultWidgetType} type Type of widget
   * @param widgetState widget state
   */
  saveWidgetStateIfNotExist(type: DefaultWidgetType,  widgetState: any) {
    if (!this.dashboardStateId) {
      return;
    }
    const dashboardFromLocalStorage = this._applicationSettingsService.getWidget(this.dashboardStateId);
    if (!!dashboardFromLocalStorage.state.widgets) {
      const wgtState = dashboardFromLocalStorage.state.widgets.find(x => x.id === type);

      if (!wgtState) {
        this.saveWidgetState(type, widgetState);
      }
    }
  }

  /**
   * Save widget settings
   * @param {DefaultWidgetType} type Type of a widget
   * @param widgetState Default widget state
   */
  saveAsDefaultView(type: DefaultWidgetType, widgetState: any) {
    const defaultState = {
      widgetType: type,
      state: widgetState,
      instrumentType: widgetState.instrumentType,
      isOpenedFromExcel: widgetState.isOpenedFromExcel
    } as IDefaultWidgetState;

    this._defaultWidgetStateService.saveDefaultWidgetState(defaultState);
    this.useDefaultView(type);
    this.updateApplicatonStateForExistingWidgets(type, widgetState);
  }

  /**
   * Use default widget view
   * @param {DefaultWidgetType} type Type of a widget
   */
  useDefaultView(type: DefaultWidgetType) {
    const wg = this.getWidgetByType(type);
    wg.state.useDefaultView = true;
    this._applicationSettingsService.updateWidget(this.dashboardStateId, wgt => {
      wgt.state = this.dashboardState;
    });
  }

  /**
   * Use custom widget view
   * @param {DefaultWidgetType} type Type of a widget
   */
  useCustomView(type: DefaultWidgetType) {
    const wg = this.getWidgetByType(type);
    wg.state.useDefaultView = false;
    this._customViewUsed.next(true);
    this._applicationSettingsService.updateWidget(this.dashboardStateId, wgt => {
      wgt.state = this.dashboardState;
    });
  }

  /**
   * Send event upon using custom view
   */
  publishCustomViewUsed() {
    this._customViewUsed.next(true);
  }

  /**
   * Saves current view as default
   */
  saveAsDefaultViewAll(instrumentType: string) {
    const registeredWidgts = Array.from(this._initialWidgetStates.keys());
    registeredWidgts.forEach(rv => {

      const currentWidgetState = this.getWidgetByType(rv);

      if (!!currentWidgetState) {
        if (currentWidgetState.state && !currentWidgetState.state.useDefaultView) {
          this.saveAsDefaultView(rv, currentWidgetState.state);
          this.useDefaultView(rv);
          this.updateApplicatonStateForExistingWidgets(rv, currentWidgetState);
        }
      }
    });
    this._saveAsDefaultAll.next(null);
  }

  /**
   * Get widget by widget type
   * @param type Widget type
   * @returns Widget state
   */
  private getWidgetByType(type: DefaultWidgetType) {
    if (this.getWidgetStateFunc) {
      return this.getWidgetStateFunc(this.dashboardState);
    }
    let result = this.dashboardState.widgets.find(a => a.id === type);

    if(!result && type === DefaultWidgetType.Underlying){
      this.dashboardState.widgets.push({
        id: 'underlying',
        title: 'Underlying',
        visibility: 'as_tab',
        visibilityOptions: ['as_tab','none'],
        state: '',
        gridsterItemConfig: {
          widgetId: 'underlying',
          maxItemRows: 78,
          maxItemCols: 78,
          rows: 39,
          cols: 66,
          x: 55,
          y: 11
        }
      })
      result = this.dashboardState.widgets.find(a => a.id === type);
    }
    
    return result;
  }

  /**
   * Updates application state for existing didgets
   * @param {DefaultWidgetType} rv Type of a widget
   * @param currentWidgetState widget state
   */
  private updateApplicatonStateForExistingWidgets(rv: DefaultWidgetType, currentWidgetState: any) {

    this._applicationSettingsService.updateOtherWidgets(this.dashboardWidgetType, this.dashboardState.id, (dw) => {
      let existingWidgetState = null;
      if (this.getWidgetStateFunc) {
        existingWidgetState = this.getWidgetStateFunc(dw.state);
      }
      else {
       existingWidgetState = dw.state.widgets.find(w => w.id === rv);
      }
      if (existingWidgetState && existingWidgetState.state && existingWidgetState.state.useDefaultView) {
        if (JSON.stringify(existingWidgetState.state) !== JSON.stringify(currentWidgetState.state)) {
          existingWidgetState.state.useDefaultView = false;
        }
      }
    });
    this._applicationSettingsService.publishDidSavedDefault(rv);
  }
}
