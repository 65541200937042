import { INewIssueAssumptions, IPricing } from '@lv-analytics/models';
import { FieldMapper } from '@lv-excel/helpers/field-mapper';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { IField } from '@lv-excel/models/field';
import '@lv-core-ui/util/extensions/string/string';

/**
 * Defines maps from excel and reverse maps to excel for pricing fields
 */
export class PricingExcelMapper extends FieldMapper<IPricing> {
  private _isCrossFx: boolean;
  private _isCrossFxOverridden: boolean;
  private _isConvertableDeltaNeutral: boolean;
  private _isConvertableDetachable: boolean;
  private _isNewIssue: boolean;
  private _isPeps: boolean;

  constructor() {
    super();
    this._isCrossFx = false;
  }

  /**
   * Sets a flag that indicated if value is cross FX.
   * @param isCrossFxValue A flag indicating if value is Cross FX.
   */
  setIsCrossFx(isCrossFxValue: boolean) {
    this._isCrossFx = isCrossFxValue;
  }

  setIsCrossFxOverridden(inputData: any[]) {
    const crossFx = inputData.find(x => x.key === 'FX');
    this._isCrossFxOverridden = crossFx && !!crossFx?.value;
  }

  /**
   * Sets a flag that indicated if instrument is PEPS.
   * @param isPeps A flag indicating if instrument is PEPS.
   */
  setIsPeps(isPeps: boolean): void {
    this._isPeps = isPeps;
  }

  /**
   * Sets a flag that indicated if convertible delta neutral.
   * @param value A flag indicating if convertible delta neutral.
   */
  setIsConvertableDeltaNeutral(value: boolean) {
    this._isConvertableDeltaNeutral = value;
  }

  /**
   * Sets a flag that indicated if convertible detachible.
   * @param value A flag indicating if convertible detachible.
   */
  setIsConvertableDetachable(value: boolean) {
    this._isConvertableDetachable = value;
  }

  /**
   * Sets a flag that indicated if convertible status is new issue.
   * @param value  A flag indicating if convertible status is new issue.
   */
  setIsNewIssue(value: boolean) {
    this._isNewIssue = value;
  }

  /**
   * Creates map.
   */
  createMap() {
    this._fieldDictionary['PRICE'] = {
      mapFn: (value: any) => {
        this._model.price = value;
      },
      reverseMapFn: () => {
        return this._model.price;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ISSUE_FX_REF'] = {
      mapFn: (value: any) => {
        this._model.newIssueAssumptions.fx = value;
      },
      reverseMapFn: () => {
        if (this._model.newIssueAssumptions.usedStockRef) {
          return null;
        }

        return this._model.newIssueAssumptions.fx;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ISSUE_STK_REF_CBCCY'] = {
      mapFn: (value: any) => {
        this._model.newIssueAssumptions.stockRefCBCcy = value;
      },
      reverseMapFn: () => {
        if (this._model.newIssueAssumptions.usedStockRef) {
          return null;
        }

        return this._model.newIssueAssumptions.stockRefCBCcy;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ISSUE_STK_REF_EQCCY'] = {
      mapFn: (value: any) => {
        this._model.newIssueAssumptions.stockRef = value;
      },
      reverseMapFn: () => {
        if (this._model.newIssueAssumptions.usedStockRef) {
          return null;
        }

        return this._model.newIssueAssumptions.stockRef;
      },
      type: ExcelFieldDataTypes.Number
    };
    
    this._fieldDictionary['VAL_DT'] = {
      mapFn: (value: any) => {
        this._model.valuationDate = new Date(value);
      },
      reverseMapFn: () => {
        return this._model.valuationDate;
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['STK_REF_CBCCY'] = {
      mapFn: (value: any) => {
        this._model.stockPriceCbCcy = value;
      },
      reverseMapFn: () => {
        return this._model.stockPriceCbCcy;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['UND_PRICE_INST_CCY'] = {
      mapFn: (value: any) => {
        this._model.stockPriceCbCcy = value;
      },
      reverseMapFn: () => {
        return this._model.stockPriceCbCcy;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['STK_REF_EQCCY'] = {
      mapFn: (value: any) => {
        this._model.stockPriceUndCcy = value;
      },
      reverseMapFn: () => {
        return this._model.stockPriceUndCcy;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['UND_PRICE_UND_CCY'] = {
      mapFn: (value: any) => {
        this._model.stockPriceUndCcy = value;
      },
      reverseMapFn: () => {
        return this._model.stockPriceUndCcy;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['STK_REF_CPCCY'] = {
      mapFn: (value: any) => {

      },
      reverseMapFn: () => {
        if (this._isConvertableDeltaNeutral) {
          return this._model.stockPriceUndCcy;
        }
        else {
          return this._model.stockPriceCbCcy;
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['UND_PRICE_CP_CCY'] = {
      mapFn: (value: any) => {

      },
      reverseMapFn: () => {
        if (this._isConvertableDeltaNeutral) {
          return this._model.stockPriceUndCcy;
        }
        else {
          return this._model.stockPriceCbCcy;
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    
    this._fieldDictionary['FX'] = {
      mapFn: (value: any) => {
        if (this._isCrossFx) {
          this._model.crossFx = value;
        }
      },
      reverseMapFn: () => {
        if (this._isCrossFx) {
          return this._model.crossFx;
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ASSUMED_CPN_RT'] = {
      mapFn: (value: any) => {
        
        if (!this._model.newIssueAssumptions) {
          this._model.newIssueAssumptions = {} as INewIssueAssumptions;
        }

        this._model.newIssueAssumptions.coupon = value;
      },
      reverseMapFn: () => {
        return this._model.newIssueAssumptions.coupon;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ASSUMED_ISSUE_PX'] = {
      mapFn: (value: any) => {
        
        if (!this._model.newIssueAssumptions) {
          this._model.newIssueAssumptions = {} as INewIssueAssumptions;
        }

        this._model.newIssueAssumptions.issuePrice = value;
      },
      reverseMapFn: () => {
        return this._model.newIssueAssumptions.issuePrice;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ASSUMED_REDEM_PX'] = {
      mapFn: (value: any) => {
        
        if (!this._model.newIssueAssumptions) {
          this._model.newIssueAssumptions = {} as INewIssueAssumptions;
        }

        this._model.newIssueAssumptions.redemptionValue = value;
      },
      reverseMapFn: () => {
        return this._model.newIssueAssumptions.redemptionValue;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ASSUMED_YLD'] = {
      mapFn: (value: any) => {
        
        if (!this._model.newIssueAssumptions) {
          this._model.newIssueAssumptions = {} as INewIssueAssumptions;
        }

        this._model.newIssueAssumptions.issueYield = value;
      },
      reverseMapFn: () => {
        return this._model.newIssueAssumptions.issueYield;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ASSUMED_PREM_PRC'] = {
      mapFn: (value: any) => {

        if (!this._model.newIssueAssumptions) {
          this._model.newIssueAssumptions = {} as INewIssueAssumptions;
        }

        if (this._isPeps) {
          this._model.newIssueAssumptions.higherStrikePremium = value;
        }
        else {
          this._model.newIssueAssumptions.premium = value;
        }
      },
      reverseMapFn: () => {
        if (this._isPeps) {
          return this._model.newIssueAssumptions.higherStrikePremium;
        }
        else {
          return this._model.newIssueAssumptions.premium;
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['PRICE_TALK'] = {
        mapFn: (value: any) => {
        if (this._model.newIssueAssumptions)
        {
          switch (value.toLowerCase()) {
            case 'best':
            case 'b':
              value = 'Best';
              break;
            case 'mid':
            case 'm':
              value = 'Mid';
              break;
            case 'assumed':
            case 'a':
              value = 'Assumed';
              break;
            case 'worst':
            case 'w':
              value = 'Worst';
              break;
          }

          this._model.newIssueAssumptions.pricingTypeSelected = value.toString().toLowerCase();
        }
      },
      reverseMapFn: () => {
        return this._model.newIssueAssumptions && this._isNewIssue ?
               this._model.newIssueAssumptions.pricingTypeSelected.toTitleCase() : '';
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['STK_REF'] = {
      mapFn: (value: any) => {

      },
      reverseMapFn: () => {
        if (this._isCrossFx) {
          if (this._isCrossFxOverridden) {
            return this._model.stockPriceUndCcy;
          }
          else {
            return this._model.stockPriceCbCcy;
          }
        }
        else {
          return this._model.stockPriceCbCcy;
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['UND_PRICE'] = {
      mapFn: (value: any) => {

      },
      reverseMapFn: () => {
        if (this._isCrossFx) {
          if (this._isCrossFxOverridden) {
            return this._model.stockPriceUndCcy;
          }
          else {
            return this._model.stockPriceCbCcy;
          }
        }
        else {
          return this._model.stockPriceCbCcy;
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['EST_USE'] = {
      mapFn: (value: any) => {
        this._model.crEstimateSettings.useEstimatedRatio = value;
      },
      reverseMapFn: () => {
        return this._model.crEstimateSettings.useEstimatedRatio ? 'Estimate' : 'Current';
      },
      type: ExcelFieldDataTypes.String
    };
  }

  /**
   * Maps enum type.
   */
  mappEnumType() {

  }

  /**
   * Maps enum type V3.
   */
  mappEnumTypeV3() {

  }

  /**
   * Custom mapping.
   * @param inputData Input data.
   */
  customMapp(inputData: any[], isV31: boolean) {
    const stockRef = inputData.find(x => x.key === (!isV31 ? 'STK_REF' : 'UND_PRICE'));
    const crossFx = inputData.find(x => x.key === 'FX');

    if (stockRef && stockRef?.value !== null && stockRef?.value !== undefined) {
      if (this._isCrossFx) {
        if (crossFx && !!crossFx?.value) {
          this._model.stockPriceCbCcy = stockRef.value / this._model.crossFx;
        }
        else {
          this._model.stockPriceCbCcy = stockRef.value;
        }
      }
      else {
        this._model.stockPriceCbCcy = stockRef.value;
      }
      this._model.stockPriceUndCcy = null;
    }

    const stockRefUnd = inputData.find(x => x.key === (!isV31 ? 'STK_REF_EQCCY' : 'UND_PRICE_UND_CCY'));
    const stockRefCb = inputData.find(x => x.key === (!isV31 ? 'STK_REF_CBCCY' : 'UND_PRICE_INST_CCY'));
    const stockRefCp = inputData.find(x => x.key === (!isV31 ? 'STK_REF_CPCCY' : 'UND_PRICE_CP_CCY'));

    if (!stockRefCb?.value && !this._isCrossFx && !!stockRefUnd?.value) {
      this._model.stockPriceCbCcy = stockRefUnd.value;
    }

    if (!stockRefCb?.value && !stockRefUnd?.value && !!stockRefCp?.value) {
      if (this._isCrossFx && (this._isConvertableDeltaNeutral || this._isConvertableDetachable)) {
        this._model.stockPriceUndCcy = stockRefCp.value;
        /**
         * fix problem if only und stock ref is sent from excel and we have
         * previously saved value for stock ref cb ccy
         */
        this._model.stockPriceCbCcy = this._model.stockPriceUndCcy / this._model.crossFx;
      }
      else {
        this._model.stockPriceCbCcy = stockRefCp.value;
      }
    }

    /**
     * fix problem if only und stock ref is sent from excel and we have
     * previously saved value for stock ref cb ccy
     */
    if (this._isCrossFx && !!stockRefUnd?.value && !stockRefCb?.value) {
      this._model.stockPriceCbCcy = this._model.stockPriceUndCcy / this._model.crossFx;
    }

    /** 
     * New issue stock ref and assumed fields
     */
    const issueStockRefCb = inputData.find(x => x.key === 'ISSUE_STK_REF_CBCCY');
    const issueStockRefEq = inputData.find(x => x.key === 'ISSUE_STK_REF_EQCCY');
    const issueFx = inputData.find(x => x.key === 'ISSUE_FX_REF');

    let usedCrossFx = 0;

    if (!!issueFx?.value) {
      usedCrossFx = issueFx?.value;
    }
    else if (!!this._model.newIssueAssumptions?.fx) {
      usedCrossFx = this._model.newIssueAssumptions.fx;
    }
    else {
      usedCrossFx = this._model.crossFx;
    }
 
    if (!issueStockRefCb?.value && !issueStockRefEq?.value) {
      if (!!this._model.newIssueAssumptions?.stockRef) {
        this._model.newIssueAssumptions.stockRefCBCcy = this._model.newIssueAssumptions.stockRef / usedCrossFx;
      }
      else if (!!this._model.newIssueAssumptions?.stockRefCBCcy) {
        this._model.newIssueAssumptions.stockRef = this._model.newIssueAssumptions.stockRefCBCcy * usedCrossFx;
      }     
    }
    else if (!!issueStockRefEq?.value) {
      if (!this._model.newIssueAssumptions) {
        this._model.newIssueAssumptions = {} as INewIssueAssumptions;
      }
      this._model.newIssueAssumptions.stockRefCBCcy = issueStockRefEq?.value / usedCrossFx;
    }
    else if (!!issueStockRefCb?.value) {
      if (!this._model.newIssueAssumptions) {
        this._model.newIssueAssumptions = {} as INewIssueAssumptions;
      }
      this._model.newIssueAssumptions.stockRef = issueStockRefCb?.value * usedCrossFx;
    }
  }
}

