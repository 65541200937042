import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'instrumentTypeFullName'
})
export class InstrumentTypeFullNamePipe implements PipeTransform {
  private instrumentFullNameMap = new Map<string, string>([
    ['ConvertibleBond', 'Convertible Bond'],
    ['Equity', 'Equity'],
    ['AssetSwap', 'Asset Swap'],
    ['CDS', 'CDS'],
    ['EquityOptions', 'Equity Options'],
    ['Bond', 'Bond']
  ]);

  /**
   * Add proper abbreviations for instrument type to instrument name.
   * @param instrumentName Instrument name.
   * @param instrumentType Instrument type.
   * @returns Modified instrument name.
   */
  transform(instrumentName: string): string {
    return this.instrumentFullNameMap.get(instrumentName);
  }
}
