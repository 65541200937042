import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IBondCallAdjustmentModelSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/call-adjustment-model-settings';
import { IBondModelCustomizationSectionSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/model-customization-section-settings';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-model-customization-bond-section',
  templateUrl: './lv-model-customization-bond-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvModelCustomizationBondSectionComponent {

  @Input() settings: IBondModelCustomizationSectionSettings;
  @Input() overrideSystemDefaults: boolean;


  checkBoxId: string;

  constructor() {
    this.overrideSystemDefaults = true;

    this.checkBoxId = v4();
  }

  @HostBinding('class.lv-model-customization-bond-section')
    get isLvModelCustomizationBondSectionComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--row')
  get isFlexColumnComponent() {
    return false;
  }

  /**
   * Gets checkbox ID..
   * @param name Checkbox name.
   * @returns Checkbox ID.
   */
  getCheckBoxId(name: string): string {
    return `${name}-${this.checkBoxId}`;
  }
}
