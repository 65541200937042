import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, HostBinding, inject, Input, OnChanges, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvAdvancedGridComponent } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid.component';
import { LvError } from '@lv-core-ui/models/lv-error';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvExcelService } from '@lv-excel/services/excel-service';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { constants } from '@lv-core-ui/util';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { IFloatingLegSchedule } from '../../../../models/aswFloatingLegSchedule';

@Component({
  selector: 'lv-asw-floating-leg-schedule',
  templateUrl: './lv-asw-floating-leg-schedule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvAswFloatingLegScheduleComponent implements OnInit, OnChanges {

  @Input() model: IFloatingLegSchedule[];
  @Output() didFloatingLegScheduleChange: EventEmitter<IFloatingLegSchedule[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  columns: LvAdvancedGridColumn[];
  floatingLegScheduleItems: IFloatingLegSchedule[];
  parseFn: any;
  excelFieldAlias = 'FLOAT_LEG_SCHED';

  private _destroyRef = inject(DestroyRef);

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {
      this.initColumns();
      this.parseFn = this.parserFunction.bind(this);

      this.floatingLegScheduleItems = [];
      this.didFloatingLegScheduleChange = new EventEmitter<IFloatingLegSchedule[]>();
    }

    @HostBinding('class.lv-asw-floating-leg-schedule')
    get isLvAswFloatingLegScheduleComponent() {
      return true;
    }

    ngOnInit() {  
      this.advancedGrid.didDataChange.pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((records: IFloatingLegSchedule[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((error: LvError) => this.onError(error))
    }

    ngOnChanges() {
      if (this.hasScheduleInExcelOverride) {
        this.initColumns();
      }
      this.floatingLegScheduleItems = this.model.map(a => ({ ...a }));
      this._changeDetectorRef.detectChanges();
    }

    /**
     * Method for creation of FormGroup object.
     * @param args CreateFormGroupArgs
     * @returns FormGroup object
     */
    createFormGroup(args: CreateFormGroupArgs): FormGroup {
      return  new FormGroup({
        'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
        'value': new FormControl(args.dataItem.value, Validators.required)
      });
    }

    /**
     * Method handles applying schedule changes.
     */
    applyAdvancedGridChanges() {
      this.advancedGrid.applyChanges(records => this.onScheduleChange(records));
    }

    /**
     * Method handles schedule columns initialization.
     */
    private initColumns() {
      this.columns = [];

      const dateColumn = new LvAdvancedGridDateColumn();
      dateColumn.title = 'End Date';
      dateColumn.field = 'date';
      dateColumn.dmKey = 'DM-4922';
      dateColumn.width = 103;

      const valueColumn = new LvAdvancedGridNumericColumn();
      valueColumn.title = 'Coupon (%)';
      valueColumn.field = 'value';
      valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
      valueColumn.format = '#,###.####';
      valueColumn.decimals = '4';
      valueColumn.dmKey = 'DM-4922';
      valueColumn.width = 102;

      this.columns.push(dateColumn);
      this.columns.push(valueColumn);
    }

    /**
     * Method for parsing schedule data.
     * @param pastedDataRecords Put Schedule Items
     * @returns Parsed schedule items.
     */
    private parserFunction(pastedDataRecords: string[]): IFloatingLegSchedule[] {
      const scheduleItems: IFloatingLegSchedule[] = [];

      pastedDataRecords.forEach(r => {
        const items = r.split('\t');
        const dateValue = items[0];
        const valueValue = items[1];

        const date = MarketDataClipboard.parseDateValue(dateValue, 'End Date');
        const value = MarketDataClipboard.parseNumberValue(valueValue, 'Coupon (%)');

        scheduleItems.push({
          date: date,
          value: value
        } as IFloatingLegSchedule);
      });

      return scheduleItems;
    }

    /**
     * Method triggers on schedule change.
     * @param scheduleItems schedule items.
     */
    private onScheduleChange(scheduleItems: IFloatingLegSchedule[]) {
      this.model.splice(0, this.model.length);
      this.model.push(...scheduleItems);
      this.didFloatingLegScheduleChange.next(this.model);
    }

    private onError(error: LvError) {
      this._errorService.handleError(error);
    }

   /**
    * Method handles schedule reload.
    */
    private onScheduleReload() {
      this.floatingLegScheduleItems = this.floatingLegScheduleItems.map(a => ({ ...a }));
    }
}
