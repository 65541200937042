<lv-flex-box direction="row">
  <lv-flex-box class="lv-equity-terms-section-settings">
    <lv-flex-box
      data-cy="DM-4801"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="countryEquitySettingsTooltip"
        filter="span">
        <span> Country </span>
      </div>
      <div class="lv-input-field lv-input-field--auto lv-equity-country">
        <kendo-dropdownlist
          name="equity-country"
          [popupSettings]="{ width: 128 }"
          [disabled]="!overrideDefaults"
          [data]="countries"
          [(ngModel)]="country"
          (ngModelChange)="setCountryChanged()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-4802"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="riskCountryEquitySettingsTooltip"
        filter="span">
        <span> Risk Country </span>
      </div>
      <div class="lv-input-field lv-input-field--auto lv-equity-country">
        <kendo-dropdownlist
          name="risk-equity-country"
          [popupSettings]="{ width: 128 }"
          [disabled]="!overrideDefaults"
          [data]="countries"
          [(ngModel)]="riskCountry"
          (ngModelChange)="setRiskCountryChanged()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-4803"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="exchangeEquitySettingsTooltip"
        filter="span">
        <span> Exchange </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="exchange-equity-country"
          [disabled]="!overrideDefaults"
          [data]="exchanges"
          [(ngModel)]="settings.settings.exchange">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-1517"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="ccyEquitySettingsTooltip"
        filter="span">
        <span> CCY </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="exchange-equity-currency"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [disabled]="!overrideDefaults"
          [data]="currencyLookup.items"
          [(ngModel)]="settings.settings.currency">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      direction="row"
      data-cy="DM-4804">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="priceScaleEquityTooltip"
        filter="span">
        <span>
          Price Scale
        </span>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="price-scale-equity"
          [disabled]="!overrideDefaults"
          [format]="formatTwo"
          [decimals]="decimalsTwo"
          [(ngModel)]="settings.settings.priceScale">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box
      direction="row"
      data-cy="DM-1530">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="daysToSettleEquityTooltip"
        filter="span">
        <span>
          Days to Settle
        </span>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="days-to-settle-equity"
          [disabled]="!overrideDefaults"
          [format]="formatZero"
          [decimals]="decimalsZero"
          [(ngModel)]="settings.settings.daysToSettle">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #countryEquitySettingsTooltip>
    <lv-tooltip dm="dM-4801"></lv-tooltip>
</ng-template>
<ng-template #riskCountryEquitySettingsTooltip>
  <lv-tooltip dm="dM-4802"></lv-tooltip>
</ng-template>
<ng-template #exchangeEquitySettingsTooltip>
  <lv-tooltip dm="dM-4803"></lv-tooltip>
</ng-template>
<ng-template #ccyEquitySettingsTooltip>
  <lv-tooltip dm="dM-1517"></lv-tooltip>
</ng-template>
<ng-template #priceScaleEquityTooltip>
  <lv-tooltip dm="dM-4804"></lv-tooltip>
</ng-template>
<ng-template #daysToSettleEquityTooltip>
  <lv-tooltip dm="dM-1530"></lv-tooltip>
</ng-template>
