import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CouponType, DayCountDescription, FrequencyDescription } from '@lv-analytics/models';
import { LvFloatingFixingHistoryScheduleComponent } from './lv-floating-fixing-history-schedule/lv-floating-fixing-history-schedule.component';
import { LvFloatingSpreadScheduleComponent } from './lv-floating-spread-schedule/lv-floating-spread-schedule.component';
import { LvFloatingCustomDatesScheduleComponent } from './lv-floating-custom-dates-schedule/lv-floating-custom-dates-schedule.component';
import { Index, IndexType, FloatingCouponData, CouponReferenceTenorDescription } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-floating-coupon',
  templateUrl: './lv-floating-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFloatingCouponComponent implements OnInit {

  @ViewChild('floatingFixingHistorySchedule', { static: false }) floatingFixingHistorySchedule: LvFloatingFixingHistoryScheduleComponent;
  @ViewChild('floatingCustomDatesSchedule', { static: false }) floatingCustomDatesSchedule: LvFloatingCustomDatesScheduleComponent;
  @ViewChild('floatingSpreadSchedule', { static: false }) floatingSpreadSchedule: LvFloatingSpreadScheduleComponent;

  @Input() model: FloatingCouponData;
  @Input() couponType: CouponType;
  @Output() didFloatingCouponChange: EventEmitter<FloatingCouponData>;

  get isfloatingToFixPrFixToFloatingCouponType(): boolean {
    return (this.couponType === CouponType.FixToFloating || this.couponType === CouponType.FloatingToFix);
  }

  floatingSpreadScheduleId: string;
  floatingCustomDatesId: string;

  couponDayCount: LvLookupEnum;
  couponFrequency: LvLookupEnum;
  couponIndex: LvLookupEnum;
  referenceTenor: LvLookupEnum;

  decimalsZero = '0';
  formatZero = 'n0';
  formatFour = '#,###.####';
  decimalsFour = '4';

  constructor() {
    this.floatingCustomDatesId = v4();
    this.floatingSpreadScheduleId = v4();

    this.couponDayCount = new LvLookupEnum(DayCountDescription);
    this.couponFrequency = new LvLookupEnum(FrequencyDescription);
    this.couponIndex = new LvLookupEnum(IndexType);
    this.referenceTenor = new LvLookupEnum(CouponReferenceTenorDescription);

    this.didFloatingCouponChange = new EventEmitter<FloatingCouponData>();
  }

  ngOnInit() {
    if (!this.model.index) {
      this.model.index = new Index();
    }
  }

  onFloatingCouponChange() {
    this.didFloatingCouponChange.next(this.model);
  }

  getFloatingCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
