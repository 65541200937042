<lv-flex-box direction="row" class="lv-volatility-grid-title">
  <div showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="volatilityTermStructureTooltipTemplate"
    filter=".lv-label" 
    class="lv-label lv-label-field lv-label--title">
    <span>
      VOLATILITY TERM STRUCTURE
    </span> 
  </div>
</lv-flex-box>
<lv-advanced-grid
  class="lv-equity-volatility-terms-structure-grid"
  data-cy="DM-293"
  lvId="gridVolatilityTermStructure"
  [columns]="columns"
  [records]="volatilityTermStructureSchedule"
  [createFormGroup]="createFormGroup"
  [excelFieldAlias]="excelFieldAlias"
  [parseFn]="parseFn"
  sortBy="date"
  [ngClass]="addStripedGridClass"
  [editDisabled]="isGridDisabled">
</lv-advanced-grid>

<ng-template #volatilityTermStructureTooltipTemplate>
  <lv-tooltip dm="dM-293"></lv-tooltip>
</ng-template>
