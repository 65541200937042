import { Injectable } from '@angular/core';
import { IDiscardable } from '../models/discardable';

/**
 * Discard changes service manages iDiscardable components and their state.
 */
@Injectable({
  providedIn: 'root'
})
export class DiscardChangesService {

  components: Record<string, IDiscardable>;

  constructor() {
    this.components = {};
  }

  /**
   * Register component to a service.
   * @param name Component name.
   * @param component Component instance.
   */
  register(name: string, component: IDiscardable) {
    this.components[name] = component;
  }

  /**
   * Get registered component.
   * @param name Component name.
   * @returns Registered component.
   */
  getComponent(name: string): IDiscardable {
    return this.components[name];
  }
}
