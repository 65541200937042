import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges,
  ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings, IMakeWholeSectionSettings, OfferAndCompensation} from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CallMakeWholeTypeDescription, DiscountRateType, RatchetMatrixCompensationValueTypeDescription, RatchetMatrixOfferValueTypeDescription } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-make-whole-section-settings',
  templateUrl: './lv-make-whole-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvMakeWholeSectionSettingsComponent implements OnInit, OnChanges {

  @Input() settings: IBaseTermsSettings<IMakeWholeSectionSettings>;
  @Input() overrideDefaults: boolean;
  @Input() takeOverOfferAndCompensation: OfferAndCompensation;

  callMakeWholeCheckBoxId: string;
  conversionMakeWholeCheckBoxId: string;

  offerValueTypeLookup: LvLookupEnum;
  compensationValueTypeLookup: LvLookupEnum;
  makeWholeTypeLookup: LvLookupEnum;
  discountRateTypeLookup: LvLookupEnum;

  decimalsZero = '0';
  formatZero = 'n0';

  constructor() {
    this.callMakeWholeCheckBoxId = v4();
    this.conversionMakeWholeCheckBoxId = v4();

    this.offerValueTypeLookup = new LvLookupEnum(RatchetMatrixOfferValueTypeDescription);
    this.compensationValueTypeLookup = new LvLookupEnum(RatchetMatrixCompensationValueTypeDescription);
    this.makeWholeTypeLookup = new LvLookupEnum(CallMakeWholeTypeDescription);
    this.discountRateTypeLookup = new LvLookupEnum(DiscountRateType);
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.takeOverOfferAndCompensation && !changes.takeOverOfferAndCompensation.firstChange &&
      // tslint:disable-next-line: max-line-length
      (changes.takeOverOfferAndCompensation.currentValue.offerValueType !== changes.takeOverOfferAndCompensation.previousValue.offerValueType
      // tslint:disable-next-line: max-line-length
      || changes.takeOverOfferAndCompensation.currentValue.compensationValueType !== changes.takeOverOfferAndCompensation.previousValue.compensationValueType)) {
        this.settings.settings.ratchetMatrixOfferValueType = this.takeOverOfferAndCompensation.offerValueType;
        this.settings.settings.ratchetMatrixCompensationValueType = this.takeOverOfferAndCompensation.compensationValueType;
      }
  }

  getMakeWholeSectionSettingsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
