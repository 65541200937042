<lv-flex-box class="lv-model-customization-bond-section-container">
  <lv-flex-box>
    <lv-model-type-bond
      [settings]="settings.creditModelSettings"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-model-type-bond>

    <lv-call-adjustment-bond
      [settings]="settings.callAdjustmentModelSettings"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-call-adjustment-bond>
  </lv-flex-box>

  <lv-flex-box class="lv-model-customization-bond-section-other">
    <div class="lv-label lv-label-field lv-label--title">
      Other Parameters
    </div>
    <lv-flex-box
      class="lv-input-field"
      direction="row"
      data-cy="DM-1456">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="expectedLifeExclDefaultSettingsTooltipTemplate"
        filter="div">
          Expected Life Excl. Default
      </div>
      <div class="lv-field lv-field--checkbox">
        <input type="checkbox"
          class="k-checkbox"
          [disabled]="!overrideSystemDefaults"
          name="modelCustomizationSettingsExpectedLifeExclDefault"
          [attr.id]="getCheckBoxId('expected-life-excl-default')"
          [(ngModel)]="settings.expLifeExcludesDefault"/>
        <label class="k-checkbox-label"
          [attr.for]="getCheckBoxId('expected-life-excl-default')"></label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #expectedLifeExclDefaultSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1456"></lv-tooltip>
</ng-template>
