import { v4 } from 'uuid';
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation,
  ChangeDetectionStrategy, OnDestroy, Input, 
  DestroyRef} from '@angular/core';
  import { filter, Subscription } from 'rxjs';

import { BreakEvenView } from '../../views/break-even';
import { LvAnalyticsPresenter } from '../../lv-analytics.presenter';
import { LvDateService, LvErrorService } from '@lv-core-ui/services';
import { SectionUsed, IToolsLogEvent } from '@lv-shared/models';
import { LvSharedUserActivityService } from '@lv-shared/services';
import { AnalyticsCommands, AnalyticsEvents, AnalyticsSettingsEvents, IBreakEvenState, LvAnalyticsError } from '@lv-analytics/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * BreakEven component.
 */
@Component({
  selector: 'lv-break-even',
  templateUrl: './lv-break-even.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBreakEvenComponent implements OnInit, OnDestroy {

  @Input()
  set state(value: IBreakEvenState) {
    this._state = value;
  }

  _state: IBreakEvenState;

  accuredInterestCbId: string;
  view: BreakEvenView;
  isLoading: boolean;

  private _subscriptions: Subscription[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private errorService: LvErrorService,
    private _presenter: LvAnalyticsPresenter,
    private _userActivityService: LvSharedUserActivityService,
    private _lvDateService: LvDateService,
    private _destroyRef: DestroyRef,
  ) {
    this.state = {} as IBreakEvenState;
    this.view = new BreakEvenView(_lvDateService);
    this.isLoading = false;
    this.accuredInterestCbId = v4();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._presenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._presenter.onModelUpdated.subscribe(evt => {
          if (evt) {
            if (evt.eventId === AnalyticsSettingsEvents.ModelCustomizationUpdated) {
              this.view.calculateBreakEvenAnalysis();
            }
            else if (evt?.eventId === 'ValuationSettingsModelUpdated') {
              this.view.init();
            }
          }
          else {
            this.view.init(null);
          }
          this.changeDetectorRef.detectChanges();
      }),

      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
          this.view.init(this._presenter.getModelData());
          this.view.calculateAll();
        }
        else {
          this.view.init(null);
        }
        this.changeDetectorRef.detectChanges();

      }),
      this._presenter.onPricingModelUpdated.subscribe(evt => {
        if (evt) {
            this.view.onPricingUpdated(evt.data.pricing);
            this.onDeltaChange(true);
            this.changeDetectorRef.detectChanges();
        }
      }),

      this._presenter.onEventPublished.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsEvents.ValuationCompleted) {
            this.view.onValuationUpdate(evt.data);
            this.view.calculateStockPosition();
            this.view.calculateBreakEvenAnalysis();
            this.changeDetectorRef.detectChanges();
          }
        }
      }),

      this._presenter.onCommandExecuted.subscribe(command => {
        if (command === AnalyticsCommands.ReloadPricingAndTools
          || command === AnalyticsCommands.ReloadAssumptionsAndToolsAndAnalysis) {
            this.init();
          }
      }),
      this._presenter.onEventPublished.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsSettingsEvents.OtherSettingsUpdated) {
            this.view.init(this._presenter.getModelData());
            this.changeDetectorRef.detectChanges();
          }
        }
      })
    ];

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(async event => {
        this.view.init();
        this.changeDetectorRef.detectChanges();
      });

    this.init();
  }

  /**
   * Occurs on delta change and calculates stock position and breakeven analysis.
   * @param isFromEvent A flag indicating if event should be logged in user activity.
   */
  onDeltaChange(isFromEvent: boolean = false) {
    try {
      this.view.calculateStockPosition();
      this.view.calculateBreakEvenAnalysis();

      if (!isFromEvent) {
        this.logEventToUsetActivity();
      }
    }
    catch (error) {
      this.errorService.handleError(new LvAnalyticsError(error));
    }
  }

  /**
   * Occurs on stock position change and calculates delta hedge and breakeven analysis.
   */
  onStockPositionChange() {
    try {
      this.view.calculateDeltaHedge();
      this.view.calculateBreakEvenAnalysis();
      this.logEventToUsetActivity();
    }
    catch (error) {
      this.errorService.handleError(new LvAnalyticsError(error));
    }
  }

  /**
   * Occurs on use accrued interest change and calculates breakeven analysis.
   * @param isFromEvent A flag indicating if accured interest is used.
   */
  onUseAccruedInterestChange(isChecked: boolean) {
    this.view.input.useAccruedInterest = isChecked;
    this.view.calculateBreakEvenAnalysis();
    this.logEventToUsetActivity();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Sets loading state.
   * @param isLoading Loaging state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Initializes view.
   */
  private init() {
    if (this._presenter.isConvertibleModelLoaded()) {
      this.view.init(this._presenter.getModelData());
      this.view.calculateAll();
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Logs event to user activity.
   */
  private logEventToUsetActivity() {
    this._userActivityService.logToolsEvent({
      sectionUsed: SectionUsed.BREAK_EVEN,
      bondCountry: this.view.convertibleCountryCode,
      bondType: this.view.convertibleStatus,
      isPrivateInstrument: this.view.isPrivate
    } as IToolsLogEvent);
  }
}
