<lv-loader *ngIf="isLoading"></lv-loader>
<lv-environment-settings
  [section]="volatilitySection"
  instrumentType="Equity"
  [showControls]="true"
  (didChangeEnvironment)="onChangedEnvironment($event)"
  (didReorderEnvironments)="onReorderEnvironments($event)"
  (doSave)="onSaveSection()"
  (doReload)="onReloadSection()">
</lv-environment-settings>
<lv-flex-box data-cy="DM-292"
  direction="row"
  class="lv-equity-risky-risk-free">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="riskyTooltipTemplate"
    filter="span:not(lv-xl-label span)"
    lvId="lbCbRisk">
    <span>
      Risky / Risk Free
    </span>
    <lv-xl-label [field]="'VOL_RISK_TYPE'"></lv-xl-label>
  </div>
  <div class="lv-input-field">
    <kendo-dropdownlist
      name="cbRisk"
      textField="text"
      valueField="id"
      [data]="riskyType.items"
      [disabled]="isVolatilitySectionDisabled"
      [valuePrimitive]="true"
      [(ngModel)]="volatility.riskyType"
      (ngModelChange)="updateEquityVolatilityModel()">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

  <kendo-tabstrip class="lv-equity-market-data-tabstrip"
  [keepTabContent]="true"
  #tabstrip>
  <kendo-tabstrip-tab
    title="Flat"
    cssClass="DM-4852"
    [selected]="true">
    <ng-template kendoTabContent>
      <lv-equity-volatility-flat
      data-cy="DM-4852"
      [volatilityFlat]="volatility"
      (didSessionUpdatedEvent)="updateEquityVolatilityModel()">
      </lv-equity-volatility-flat>
     
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab 
    title="Term Structure"
    cssClass="DM-4853">
    <ng-template kendoTabContent>
      <lv-equity-volatility-terms-structure
        data-cy="DM-4853"
        [volatilityTermStructureSchedule]="volatility.volatilityTermStructureSchedule"
        (didSessionUpdatedEvent)="updateEquityVolatilityModel()">
      </lv-equity-volatility-terms-structure>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab 
    title="Surface"
    cssClass="DM-4854">
    <ng-template kendoTabContent>
    <lv-equity-volatility-surface
    data-cy="DM-4854"
    [volatilitySurface]="volatility.volatilitySurface"
    [ccyCodeChanged]="ccyCodeChanged"
    (didSessionUpdatedEvent)="updateEquityVolatilityModel()">
    </lv-equity-volatility-surface>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<ng-template #riskyTooltipTemplate>
  <lv-tooltip dm="dM-292"></lv-tooltip>
</ng-template>
