import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
   ChangeDetectorRef, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Optional, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LvFloatingCustomDatesScheduleView } from './lv-floating-custom-dates-schedule.view';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvExcelService } from '@lv-excel/services';
import { FloatingCustomDatesSchedule } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-floating-custom-dates-schedule',
  templateUrl: './lv-floating-custom-dates-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFloatingCustomDatesScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: Date[];
  @Output() didFloatingCustomDatesScheduleChange: EventEmitter<Date[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  view: LvFloatingCustomDatesScheduleView;

  columns: LvAdvancedGridColumn[];
  floatingCustomDatesScheduleItems: FloatingCustomDatesSchedule[];
  parseFn: any;
  excelFieldAlias = 'CPN_SCHED_CUSTOM_DATES';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {

      this.initColumns();
      this.parseFn = this.parserFunction.bind(this);
      this.view = new LvFloatingCustomDatesScheduleView();

      this.floatingCustomDatesScheduleItems = [];
      this._modelSubscription = [];
      this.didFloatingCustomDatesScheduleChange = new EventEmitter<Date[]>();
  }

  ngOnInit() {
    this._modelSubscription = [
        this.advancedGrid.didDataChange.subscribe((records: FloatingCustomDatesSchedule[]) => this.onScheduleChange(records)),
        this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
        this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.floatingCustomDatesScheduleItems.splice(0, this.floatingCustomDatesScheduleItems.length);
    this.floatingCustomDatesScheduleItems.push(...this.view.getFloatingCustomDatesSchedule(this.model));

    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
    this.onScheduleReload();
    this.floatingCustomDatesScheduleItems.splice(0, this.floatingCustomDatesScheduleItems.length);
    this.floatingCustomDatesScheduleItems.push(...this.view.getFloatingCustomDatesSchedule(this.model));
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required)
    });
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.onScheduleChange(records));
  }

  private initColumns() {
    this.columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.title = 'Date';
    dateColumn.field = 'date';
    dateColumn.dmKey = 'DM-2269';

    this.columns.push(dateColumn);
  }

  private parserFunction(pastedDataRecords: string[]): FloatingCustomDatesSchedule[] {
    const scheduleItems: FloatingCustomDatesSchedule[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const dateValue = items[0];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'Date');

      scheduleItems.push({
        date: date
      } as FloatingCustomDatesSchedule);
    });

    return scheduleItems;
  }

  private onScheduleChange(scheduleItems: FloatingCustomDatesSchedule[]) {
    this.model.splice(0, this.model.length);
    this.model.push(...this.view.getFloatingCustomDatesModel(scheduleItems));
    this.didFloatingCustomDatesScheduleChange.next(this.model);
  }

  private onScheduleReload() {
    this.floatingCustomDatesScheduleItems = this.floatingCustomDatesScheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

}
