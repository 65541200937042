import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LvAnalyticsError } from '../../../models/errors';
import { ICalculateScenarioRequest } from '../../../models/sensitivity-analysis/calculation/calculate-scenario-request';
import { ICalculateScenarioResponse } from '../../../models/sensitivity-analysis/calculation/calculate-scenario-response';
// tslint:disable-next-line:max-line-length
import { ICalculateScenarioOutput, ICalculateScenarioOutputVector } from '../../../models/sensitivity-analysis/calculation/calculate-scenario-output';
import { LvScenarioInputsMapper } from '../../../models/sensitivity-analysis/scenario/scenario-inputs-mapper';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Scenario calculation service.
 */
@Injectable()
export class ScenarioCalculationService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/analytics/sensitivity/scenarioCalculation');
  }

  /**
   * Calculates one dimensional scenario.
   * @param request ICalculateScenarioRequest object.
   * @returns ICalculateScenarioOutput object.
   */
  async calculateOneDimensionalScenario(request: ICalculateScenarioRequest): Promise<ICalculateScenarioResponse<ICalculateScenarioOutput>> {
    try {
      LvScenarioInputsMapper.mapScenarioInputsToApi(request.scenario.firstDimension);

      const response = await this.postAsync<ICalculateScenarioResponse<ICalculateScenarioOutput>>(
        request,
        `/one-dimensional`,
        null,
        false,
        60 * 1000);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Calculates two dimensional scenario.
   * @param request ICalculateScenarioRequest object.
   * @returns ICalculateScenarioOutputVector object.
   */
  // tslint:disable-next-line:max-line-length
  async calculateTwoDimensionalScenario(request: ICalculateScenarioRequest): Promise<ICalculateScenarioResponse<ICalculateScenarioOutputVector>> {
    try {
      LvScenarioInputsMapper.mapScenarioInputsToApi(request.scenario.firstDimension);
      LvScenarioInputsMapper.mapScenarioInputsToApi(request.scenario.secondDimension);

      const response = await this.postAsync<ICalculateScenarioResponse<ICalculateScenarioOutputVector>>(
        request,
        `/two-dimensional`,
        null,
        false,
        60 * 1000);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
}
