/**
 * Types of borrow source.
 */
export enum BorrowSourceAsw {
  Flat = 'Flat',
  Rebate = 'Rebate',
  TermStructure = 'TermStructure',
  UnderlyingCBData = 'UnderlyingCBData',
  UnderlyingEquityDataFlat = 'UnderlyingEquityDataFlat',
  UnderlyingEquityDataRebate = 'UnderlyingEquityDataRebate',
  UnderlyingEquityDataTermStructure = 'UnderlyingEquityDataTermStructure',
}

/**
 * Borrow source descriptions.
 */
export enum BorrowSourceAswDescription {
  Flat = 'Flat',
  Rebate = 'Rebate',
  TermStructure = 'Term Structure',
  UnderlyingCBData = 'Underlying CB Data',
  UnderlyingEquityDataFlat  = 'Underlying Equity Data - Flat',
  UnderlyingEquityDataRebate = 'Underlying Equity Data - Rebate',
  UnderlyingEquityDataTermStructure = 'Underlying Equity Data - Term Structure',
}
