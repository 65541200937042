import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output,
  ChangeDetectorRef, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Optional} from '@angular/core';
import { Subscription } from 'rxjs';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import * as _ from 'lodash';
import { IMarketDataComponent } from '../../market-data-component';
import { v4 } from 'uuid';
import { AveragingDataView } from './lv-averaging-data.view';
import { LvDateService, LvErrorService } from '@lv-core-ui/services';
import { IAveragingData, IAveragingDataSaveRequest } from '@lv-analytics/models/market-data';
import { IEnvironmentSettingsItem,
         LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { MarketDataService } from '@lv-analytics/services';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { AnalyticsCommands, AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { LvExcelService } from '@lv-excel/services';

/**
 * Averaging data component.
 */
@Component({
  selector: 'lv-averaging-data',
  templateUrl: './lv-averaging-data.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAveragingDataComponent implements OnInit, OnDestroy, IMarketDataComponent<IAveragingDataSaveRequest> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  averagingDataSection = PricingEnvironmentSections.AveragingData;
  averagingDataSettings: IAveragingData;
  originalValue: IAveragingData;
  view: AveragingDataView;

  private _subscriptions: Subscription[];
  lbLastCoCoTriggerSatisfiedId: string;
  formatTwo = '#,###.##';
  decimalsTwo = '2';
  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _marketDataService: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _lvDateService: LvDateService,
    @Optional() private _excelSvc: LvExcelService,
  ) {
    this.averagingDataSettings = {} as IAveragingData;
    this.didSessionUpdatedEvent = new EventEmitter<void>();
    this.lbLastCoCoTriggerSatisfiedId = v4();
    this.view = new AveragingDataView(_lvDateService);
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt && evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
          this.averagingDataSettings = evt.data.marketData?.averagingData;
          this.originalValue = _.cloneDeep(this.averagingDataSettings);

          this.view.init(this._analyticsPresenter.getModelData().convertible);
          this._changeDetectorRef.detectChanges();
        }
      }),

      this._analyticsPresenter.onCommandExecuted.subscribe(command => {
        if (command === AnalyticsCommands.ReloadInstrumentInfo) {
          this.view.init(this._analyticsPresenter.getModelData().convertible);
          this._changeDetectorRef.detectChanges();
        }
      })
    ];
  }

  /**
   * Occurs on averaging data section change.
   */
  onAveragingDataSectionChange() {
    this.overrideAveragingData();
  }

  /**
   * Occurs on changed environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
     this.loadAveragingData(environment);
  }

  /**
   * Gets selected environment ID.
   * @returns Selected environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns IAveragingDataSaveRequest object.
   */
  getSettings(): IAveragingDataSaveRequest {
    return this.getAveragingDataSaveRequest();
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {

  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections object.
   */
  getSection(): PricingEnvironmentSections {
    return this.averagingDataSection;
  }

  /**
   * Gets averaging data tootlip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Averaging data tooltip ID.
   */
  getAveragingDataTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('averaging-data-tooltip-id') === sectionId;
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Gets averaging data save request.
   * @returns IAveragingDataSaveRequest object.
   */
  private getAveragingDataSaveRequest(): IAveragingDataSaveRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      environmentId: env.id,
      averagingData: this.averagingDataSettings
    } as IAveragingDataSaveRequest;
  }

  /**
   * Loads averaging data.
   */
  private async loadAveragingData(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      this.averagingDataSettings = await this._marketDataService.loadAveragingData(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id
      );

      this._analyticsPresenter.updateModel({
        eventId: AnalyticsSettingsEvents.AveragingDataUpdated,
        data: this.averagingDataSection
      });
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Override averaging data.
   */
  private async overrideAveragingData() {
    try {
      await this._marketDataService.overrideAveragingData({
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        averagingData: this.averagingDataSettings
      });

      if (!_.isEqual(this.averagingDataSettings, this.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.originalValue = _.cloneDeep(this.averagingDataSettings);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);

    this._changeDetectorRef.detectChanges();
  }
}
