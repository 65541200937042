import { IEquity } from "@lv-reference-data/models/equity";
import { IEquityIdentifiers } from "@lv-reference-data/models/equity-identifiers";
import { EquityTypeEnum } from "@lv-reference-data/models/equity-type-enum";

/**
 * Equity Terms Document.
 */
export class EquityDocument {
  terms: IEquity;
  //identifiers: IEquityIdentifiers[];
}

export const getDefaultEquityDocument = (): EquityDocument => {
  return {
     terms: {
      name: '',
      bloombergTicker: '',
      issuerName: '',
      countryCode: '',
      iSIN: '',
      exchangeCode: '',
      currencyCode: null,
      tradingUnit: null,
      priceScale: null,
      riskCountryCode: '',
      type: EquityTypeEnum.Equity,
      cusip: '',
      fIGI: ''
     },
      // identifiers: [{
      //   identifierType: null,
      //   identifier: ''
      //   },
      //   {
      //     identifierType: null,
      //     identifier: ''
      //   },
      //   {
      //     identifierType: null,
      //     identifier: ''
      //   }]
  } as EquityDocument
}