<lv-flex-box class="lv-convertible-bond-terms-calls">
  <lv-flex-box
    direction="row"
    class="lv-convertible-bond-terms-calls-top-options"
    data-cy="DM-5440">
    <lv-flex-box direction="row" data-cy="DM-392">
      <div
        class="lv-label lv-label-field lv-label-callable"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="callableSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span> Callable </span>
        <lv-xl-label [field]="'CALLABLE'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox lv-checkbox-left">
        <input
          class="k-checkbox"
          type="checkbox"
          name="calls-callable"
          [id]="callableId"
          [(ngModel)]="issueAndRedemption.isCallable"
          (ngModelChange)="onCallChanged()"
        />
        <label
          lvId="callableCheckbox"
          class="k-checkbox-label"
          [for]="callableId">
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box class="lv-clean-up-margin" direction="row" data-cy="DM-1299">
      <div
        class="lv-label lv-label-field lv-label-clean-up"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="cleanUpCallSourceTooltip"
        filter="span">
        <span> Clean-Up Call </span>
      </div>
      <div class="lv-field lv-field--checkbox lv-checkbox-left">
        <input
          class="k-checkbox"
          type="checkbox"
          name="calls-clean-up-call"
          [attr.id]="cleanUpCallId"
          [(ngModel)]="issueAndRedemption.isCleanUpCall"
          (ngModelChange)="onCleanUpCallChanged()"
        />
        <label class="k-checkbox-label" [attr.for]="cleanUpCallId"></label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box class="lv-call-fieldset lv-bond-call-fieldset" *ngIf="issueAndRedemption.isCallable">
    <lv-fieldset-panel legend="CALL PARAMETERS">
        <lv-flex-box direction="row">
            <lv-flex-box class="lv-call-first-col lv-bond-call-first-col">
                <lv-flex-box direction="row" data-cy="DM-927">
                    <div class="lv-label lv-label-field"
                      showAfter="1500"
                      kendoTooltip
                      [tooltipTemplate]="callValueTypeSourceTooltip"
                      filter="span:not(lv-xl-label span)">
                      <span>
                        Call Value Type
                      </span>
                      <lv-xl-label [field]="'CALL_VALUE_TYPE'"></lv-xl-label>
                    </div>
                    <div class="lv-input-field lv-input-field--auto">
                      <kendo-dropdownlist
                        name="callValueType"
                        [data]="callValueTypeLookup.items"
                        valueField="id"
                        textField="text"
                        [valuePrimitive]="true"
                        [popupSettings]="{ width: 216 }"
                        [(ngModel)]="model.valueType"
                        (ngModelChange)="onCallValueTypeChanges()">
                      </kendo-dropdownlist>
                    </div>
                  </lv-flex-box>
                  <lv-flex-box *ngIf="isYieldFieldVisible" direction="row" data-cy="DM-928">
                    <div class="lv-label lv-label-field"
                      showAfter="1500"
                      kendoTooltip
                      [tooltipTemplate]="callYieldFrequencySourceTooltip"
                      filter="span:not(lv-xl-label span)">
                      <span>
                        Call Yield Frequency
                      </span>
                      <lv-xl-label [field]="'CALL_YLD_FRQ'"></lv-xl-label>
                    </div>
                    <div class="lv-input-field">
                      <kendo-dropdownlist
                        name="callYieldFrequency"
                        [data]="callYieldFrequencyLookup.items"
                        valueField="id"
                        textField="text"
                        [valuePrimitive]="true"
                        [(ngModel)]="model.yieldFrequency"
                        (ngModelChange)="onModelChange()">
                      </kendo-dropdownlist>
                    </div>
                  </lv-flex-box>
                  <lv-flex-box *ngIf="isYieldFieldVisible" direction="row" data-cy="DM-929">
                    <div class="lv-label lv-label-field"
                      showAfter="1500"
                      kendoTooltip
                      [tooltipTemplate]="callYieldDayCountSourceTooltip"
                      filter="span:not(lv-xl-label span)">
                      <span>
                        Call Yield Day Count
                      </span>
                      <lv-xl-label [field]="'CALL_DAY_COUNT'"></lv-xl-label>
                    </div>
                    <div class="lv-input-field">
                      <kendo-dropdownlist
                        name="callYieldDayCount"
                        [data]="callYieldDayCountLookup.items"
                        valueField="id"
                        textField="text"
                        [valuePrimitive]="true"
                        [(ngModel)]="model.yieldDayCount"
                        (ngModelChange)="onModelChange()">
                      </kendo-dropdownlist>
                    </div>
                  </lv-flex-box>
            </lv-flex-box>
            <lv-flex-box>
              <lv-flex-box direction="row" *ngIf="!isZeroCouponType" data-cy="DM-930">
                <div class="lv-label lv-label-field"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="keepAccruedRedemptionSourceTooltip"
                  filter="span:not(lv-xl-label span)">
                  <span>
                    Keep Accrued (redemption)
                  </span>
                  <lv-xl-label [field]="'CALL_KEEP_ACCRD_RDMP'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input
                    class="k-checkbox"
                    type="checkbox"
                    [attr.id]="keepAccruedRedemptionId"
                    [(ngModel)]="model.keepAccruedRedemption"
                    (ngModelChange)="onModelChange()">
                  <label class="k-checkbox-label" [attr.for]="keepAccruedRedemptionId"></label>
                </div>
              </lv-flex-box>
              <lv-flex-box direction="row" *ngIf="!isZeroCouponType" data-cy="DM-931">
                <div class="lv-label lv-label-field"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="forfeitCouponSourceTooltip"
                  filter="span:not(lv-xl-label span)">
                  <span>
                    Forfeit Coupon
                  </span>
                  <lv-xl-label [field]="'CALL_FRFT_CPN'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input
                    class="k-checkbox"
                    type="checkbox"
                    [attr.id]="forfeitCouponId"
                    [(ngModel)]="model.forfeitCoupon"
                    (ngModelChange)="onModelChange()">
                  <label class="k-checkbox-label" [attr.for]="forfeitCouponId"></label>
                </div>
              </lv-flex-box>
            </lv-flex-box>
        </lv-flex-box>
        <lv-flex-box class="lv-call-trigger-section">
          <div showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="callScheduleTooltip"
          filter=".lv-label"
          class="lv-label lv-label-field lv-label--title">CALL SCHEDULE</div>
        </lv-flex-box>
        <lv-flex-box direction="row">
          <lv-bond-calls-schedule #callSchedule
            [valueType]="model.valueType"
            [model]="model.scheduleItems"
            (didCallsScheduleChange)="recalculateReadOnlyDates()">
          </lv-bond-calls-schedule>
        </lv-flex-box>
        <lv-flex-box class="lv-call-trigger-section">
          <lv-flex-box>
            <div class="lv-label lv-label-field lv-label--title">CALL NOTICE</div>
          </lv-flex-box>
          <lv-flex-box direction="row" class="lv-convertible-bond-terms-calls-bottom-row">
            <lv-flex-box class="lv-call-first-col">
              <lv-flex-box direction="row" class="lv-input-field" data-cy="DM-920">
                <div class="lv-label lv-label-field"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="callNoticeDaysSourceTooltip"
                  filter="span:not(lv-xl-label span)">
                  <span>
                    Call Notice Days (min)
                  </span>
                  <lv-xl-label [field]="'CALL_NTC_MIN_DAYS'"></lv-xl-label>
                </div>
                  <lv-numeric-text-box
                    name="callNoticeDays"
                    [format]="numberZeroFormat"
                    [decimals]="numberOfZeroDecimals"
                    [(ngModel)]="model.notice"
                    (didBlur)="onModelChange()"
                    (didKeyDownEnter)="onModelChange()">
                  </lv-numeric-text-box>
              </lv-flex-box>
              <lv-flex-box direction="row" class="lv-input-field" data-cy="DM-921">
                <div class="lv-label lv-label-field"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="callNoticeDaysMaxSourceTooltip"
                  filter="span:not(lv-xl-label span)">
                  <span>
                    Call Notice Days (max)
                  </span>
                  <lv-xl-label [field]="'CALL_NTC_MAX_DAYS'"></lv-xl-label>
                </div>
                  <lv-numeric-text-box
                    name="callNoticeDaysMax"
                    [format]="numberZeroFormat"
                    [decimals]="numberOfZeroDecimals"
                    [(ngModel)]="model.callNoticeDaysMax"
                    (didBlur)="recalculateReadOnlyDates()"
                    (didKeyDownEnter)="recalculateReadOnlyDates()">
                  </lv-numeric-text-box>
              </lv-flex-box>
              <lv-flex-box direction="row" data-cy="DM-922">
                <div class="lv-label lv-label-field"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="callNoticeDaysTypeSourceTooltip"
                  filter="span:not(lv-xl-label span)">
                  <span>
                    Call Notice Days (type)
                  </span>
                  <lv-xl-label [field]="'CALL_NTC_DAYS_TYPE'"></lv-xl-label>
                </div>
                <div class="lv-input-field">
                  <kendo-dropdownlist
                    name="callNoticeBusinessCalendar"
                    [data]="callNoticeLookup.items"
                    valueField="id"
                    textField="text"
                    [valuePrimitive]="true"
                    [(ngModel)]="model.noticePeriod"
                    (ngModelChange)="recalculateReadOnlyDates()">
                  </kendo-dropdownlist>
              </div>
              </lv-flex-box>
            </lv-flex-box>
            <lv-flex-box>
              <lv-flex-box
              data-cy="DM-1301"
              direction="row"
              class="lv-call-readonly-date">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callFirstCallConsiderationDateTooltip"
                filter="span">
                <span>
                  First Call Consideration Date
                </span>
              </div>
              <lv-datepicker
                class="lv-datepicker-readonly"
                name="callFirstCallConsiderationDate"
                [datePickerLvId] = "dpCallFirstCallConsiderationDatePickerId"
                [(ngModel)]="model.callFirstCallConsiderationDate"
                [checked]="false">
              </lv-datepicker>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-925">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callNoticeGivenSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Call Notice Given
                </span>
                <lv-xl-label [field]="'CALL_NTC_GVN'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input class="k-checkbox"
                  type="checkbox"
                  [attr.id]="noticeGivenId"
                  [(ngModel)]="model.callNoticeGiven"
                  (ngModelChange)="onCallNoticeGivenChange()" />
                <label class="k-checkbox-label" [attr.for]="noticeGivenId"></label>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" *ngIf="model.callNoticeGiven" data-cy="DM-926">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callDateSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Call Date
                </span>
                <lv-xl-label [field]="'CALL_DATE'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-datepicker
                  [disabled]="!model.callNoticeGiven"
                  [(ngModel)]="model.callDate"
                  (ngModelChange)="onModelChange()">
                </kendo-datepicker>
              </div>
            </lv-flex-box>
            </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box
    class="lv-clean-up-section lv-bond-clean-up-section"
    *ngIf="issueAndRedemption.isCleanUpCall">
    <lv-fieldset-panel legend="CLEAN-UP CALL">
      <lv-flex-box direction="row" data-cy="DM-1300">
        <div
          class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="valuePercentageSourceTooltip"
          filter="span">
          <span> Value (%) </span>
        </div>
        <lv-numeric-text-box
          name="value"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercentage"
          [(ngModel)]="cleanUpCall.cleanUpCallValue"
          (didBlur)="onCleanUpCallChanged()"
          (didKeyDownEnter)="onCleanUpCallChanged()">
        </lv-numeric-text-box>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
</lv-flex-box>

<ng-template #callableSourceTooltip>
  <lv-tooltip dm="dM-392"></lv-tooltip>
</ng-template>
<ng-template #cleanUpCallSourceTooltip>
  <lv-tooltip dm="dM-1299"></lv-tooltip>
</ng-template>
<ng-template #callValueTypeSourceTooltip>
  <lv-tooltip dm="dM-927"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysSourceTooltip>
  <lv-tooltip dm="dM-920"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysMaxSourceTooltip>
  <lv-tooltip dm="dM-921"></lv-tooltip>
</ng-template>
<ng-template #considerationDaysMaxSourceTooltip>
  <lv-tooltip dm="dM-923"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysTypeSourceTooltip>
  <lv-tooltip dm="dM-922"></lv-tooltip>
</ng-template>
<ng-template #considerationDaysBusinessCalendarTooltip>
  <lv-tooltip dm="dM-924"></lv-tooltip>
</ng-template>
<ng-template #callFirstCallConsiderationDateTooltip>
  <lv-tooltip dm="dM-1301"></lv-tooltip>
</ng-template>
<ng-template #callTriggerObservationStartDateTooltip>
  <lv-tooltip dm="dM-1302"></lv-tooltip>
</ng-template>
<ng-template #callNoticeGivenSourceTooltip>
  <lv-tooltip dm="dM-925"></lv-tooltip>
</ng-template>
<ng-template #callDateSourceTooltip>
  <lv-tooltip dm="dM-926"></lv-tooltip>
</ng-template>
<ng-template #callYieldFrequencySourceTooltip>
  <lv-tooltip dm="dM-928"></lv-tooltip>
</ng-template>
<ng-template #callYieldDayCountSourceTooltip>
  <lv-tooltip dm="dM-929"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedRedemptionSourceTooltip>
  <lv-tooltip dm="dM-930"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedConversionSourceTooltip>
  <lv-tooltip dm="dM-933"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponSourceTooltip>
  <lv-tooltip dm="dM-931"></lv-tooltip>
</ng-template>
<ng-template #includeCashInCallTriggerSourceTooltip>
  <lv-tooltip dm="dM-916"></lv-tooltip>
</ng-template>
<ng-template #triggerTypeSourceTooltip>
  <lv-tooltip dm="dM-914"></lv-tooltip>
</ng-template>
<ng-template #currencyOfTriggerSourceTooltip>
  <lv-tooltip dm="dM-915"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodTypeSourceTooltip>
  <lv-tooltip dm="dM-917"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodDaysSourceTooltip>
  <lv-tooltip dm="dM-918"></lv-tooltip>
</ng-template>
<ng-template #outOfDaysSourceTooltip>
  <lv-tooltip dm="dM-919"></lv-tooltip>
</ng-template>
<ng-template #valuePercentageSourceTooltip>
  <lv-tooltip dm="dM-1300"></lv-tooltip>
</ng-template>
<ng-template #callScheduleTooltip>
  <lv-tooltip dm="dM-932"></lv-tooltip>
</ng-template>

<ng-template #callTooltipTemplate let-anchor>
  <div class="popup-call-content">

  </div>
</ng-template>
