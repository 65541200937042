<lv-flex-box class="lv-section-settings-system-defaults">
  <div class="lv-label-field lv-label lv-label--title lv-section-settings-system-defaults-title">SYSTEM DEFAULTS</div>

  <ng-template
    [ngTemplateOutlet]="systemDefaults?.templateRef"
    [ngTemplateOutletContext]="{
      systemDefaults: settings.systemDefaults,
      $implicit: settings.systemDefaults
    }">
  </ng-template>
</lv-flex-box>

<ng-container *ngIf="!showSingleEnvironment;else singleEnvironment">
  <lv-flex-box *ngIf="hasEnvironmentSettings" class="lv-section-settings-environment-settings">
    <div class="lv-label-field lv-label lv-label--title">ENVIRONMENT DEFAULTS</div>
    <kendo-tabstrip class="lv-tabstrip" [keepTabContent]="true">

      <ng-container *ngFor="let env of settings.environments">

        <kendo-tabstrip-tab *ngIf="!env.isDisabled"
          [title]="env.environmentName"
          [selected]="envDict[env.environmentCode].selected">
          <ng-template kendoTabContent>
  
            <lv-flex-box 
              class="lv-input-field"
              direction="row"
              data-cy="DM-1486">
              <div class="lv-label lv-label-field lv-section-settings-system-defaults-override-system-defaults"
                [attr.data-tooltip-id]="'overrideSystemDefaultsLabel'">
                  Override System Defaults
              </div>
              <div
                class="lv-field lv-field--checkbox">
                <input type="checkbox"
                  class="k-checkbox override-system-defaults"
                  [attr.id]="envDict[env.environmentCode].overrideSystemDefaultsCbId"
                  [(ngModel)]="env.overrideSystemDefaults"/>
                <label class="k-checkbox-label"
                  lvId="override-system-defaults-checkbox"
                  [attr.for]="envDict[env.environmentCode].overrideSystemDefaultsCbId"></label>
              </div>
            </lv-flex-box>
  
            <ng-template
              [ngTemplateOutlet]="environmentSettings?.templateRef"
              [ngTemplateOutletContext]="{
                settings: env.settings,
                overrideSystemDefaults: env.overrideSystemDefaults,
                $implicit: env.settings
              }">
            </ng-template>
  
          </ng-template>
        </kendo-tabstrip-tab>
      </ng-container>
    </kendo-tabstrip>
  </lv-flex-box>
</ng-container>

<ng-template #singleEnvironment>
  <ng-container *ngFor="let env of settings.environments">
    <lv-flex-box *ngIf="env.environmentCode === 'MY' && !env.isDisabled" class="lv-section-settings-environment-settings lv-section-settings-environment-settings-my">
      <div class="lv-label-field lv-label lv-label--title">MY DEFAULTS</div>
      <div class="lv-environment-content">
        <lv-flex-box
          class="lv-input-field"
          data-cy='DM-1123'
          direction="row">
          <div class="lv-label lv-label-field"
            [attr.data-tooltip-id]="'overrideSystemDefaultsLabel'">
            Override System Defaults
          </div>
          <div class="lv-field lv-field--checkbox">
            <input type="checkbox"
              class="k-checkbox override-system-defaults"
              [attr.id]="envDict[env.environmentCode].overrideSystemDefaultsCbId"
              [(ngModel)]="env.overrideSystemDefaults"/>
            <label class="k-checkbox-label"
              lvId="override-other-system-defaults-checkbox-my-defaults"
              [attr.for]="envDict[env.environmentCode].overrideSystemDefaultsCbId"></label>
          </div>
        </lv-flex-box>
        <ng-template
          [ngTemplateOutlet]="environmentSettings?.templateRef"
          [ngTemplateOutletContext]="{
            settings: env.settings,
            overrideSystemDefaults: env.overrideSystemDefaults,
            $implicit: env.settings
          }">
      </ng-template>
      </div>
    </lv-flex-box>
  </ng-container>
</ng-template>
