import elementResizeDetectorMaker from 'element-resize-detector';

import { Injectable, ElementRef } from '@angular/core';

import { v4 } from 'uuid';
import { ResizeHandler } from './resize-handler';
import { LvDataMaster } from '@lv-core-ui/models';

export type Listener = (element: HTMLElement) => void;

export interface IListener {
  previousWidth: number;
  breakingPoint?: number;
  listener: Listener;
}

@Injectable()
export class ResizeHandlerService {

  private _handler: elementResizeDetectorMaker.Erd;
  private _listeners: {
    [listenerId: string]: IListener;
  };

  constructor() {
    this._handler = elementResizeDetectorMaker({
      strategy: 'scroll'
    });

    this._listeners = {};
  }

  public getListener(id: string) {
    const l = this._listeners[id];

    if (!l) {
      throw new Error(LvDataMaster.getError('dM-1917'));
    }

    return l;
  }

  public listenTo(elementRef: ElementRef, onResize: (elementRect: ClientRect | DOMRect, listenerId: string) => void): string {
    return this.doListenTo(elementRef, onResize, null);
  }

  public breakOn(elementRef: ElementRef, breakingPoint: number, action: (brokeBelow: boolean) => void): string {
    return this.doListenTo(elementRef,
      (elementRect: ClientRect | DOMRect, listenerId: string) => {
        const l = this._listeners[listenerId];
        ResizeHandler.break(l, elementRect.width, action);
    }, breakingPoint);
  }

  public removeListener(listenerId: string) {
    delete this._listeners[listenerId];
  }

  public removeAllListeners(elementRef: ElementRef) {
    this._listeners = {};
    this._handler.removeAllListeners(elementRef.nativeElement);
  }

  // tslint:disable-next-line:max-line-length
  private doListenTo(elementRef: ElementRef, onResize: (elementRect: ClientRect | DOMRect, listenerId: string) => void, breakingPoint?: number) {
    const listenerId = v4();
    const orgRect = (elementRef?.nativeElement as HTMLElement)?.getBoundingClientRect();

    this._listeners[listenerId] = {
      previousWidth: orgRect?.width,
      breakingPoint: breakingPoint,
      listener: (element: HTMLElement) => {
        const l = this._listeners[listenerId];
        if (l) {
          const rect = element.getBoundingClientRect();
          onResize(rect, listenerId);
        }
      }
    } as IListener;

    if (elementRef) {
      this._handler.listenTo(elementRef.nativeElement, this._listeners[listenerId].listener);
    }

    return listenerId;
  }
}
