import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ICreditModelSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/credit-model-settings';
import { BondCreditModelTypeDescription } from '@lv-analytics/models/model-customization/model-customization-enum';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';

@Component({
  selector: 'lv-model-type-bond',
  templateUrl: './lv-model-type-bond.component.html',
 changeDetection: ChangeDetectionStrategy.OnPush,
 encapsulation: ViewEncapsulation.None
})
export class LvModelTypeBondSectionSettingsComponent {
  @Input() settings: ICreditModelSettings;
  @Input() overrideSystemDefaults: boolean;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  creditModelType: LvLookupEnum;

  constructor() {
    this.creditModelType = new LvLookupEnum(BondCreditModelTypeDescription);
  }
}
