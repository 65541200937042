import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { IBaseBondTermsSettings, IBondMakeWholeSectionSettings } from '../../../models/bond-terms-settings';

@Component({
  selector: 'lv-bond-make-whole-settings',
  templateUrl: './lv-bond-make-whole-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondMakeWholeSettingsComponent {

  @Input() systemDefaults: IBaseBondTermsSettings<IBondMakeWholeSectionSettings>;
  @Input() myDefaults: IBaseBondTermsSettings<IBondMakeWholeSectionSettings>;

  constructor() { }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

}
