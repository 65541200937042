import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IEquityDividends } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-dividends/equity-dividends';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import { Currency } from '@lv-instrument-common/enums/currency-enum';
import _ from 'lodash';
import { LvEquityDividendsScheduleComponent } from './lv-equity-dividends-schedule/lv-equity-dividends-schedule.component';
import { filter } from 'rxjs';
import { AnalyticsSettingsEvents } from '@lv-analytics/models';
import { LvEquityDividendsParametersComponent } from './lv-equity-dividends-parameters/lv-equity-dividends-parameters.component';

@Component({
  selector: 'lv-equity-market-data-dividends',
  templateUrl: './lv-equity-market-data-dividends.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityMarketDataDividendsComponent implements OnInit {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild(LvEquityDividendsScheduleComponent) dividendGrid: LvEquityDividendsScheduleComponent;
  @ViewChild(LvEquityDividendsParametersComponent) dividendParameters: LvEquityDividendsParametersComponent;

  dividendsSection = PricingEnvironmentSections.Dividends;

  dividends: IEquityDividends;
  originalValue: IEquityDividends;
  isLoading: boolean;
  dividendCurrencySource: LvLookupEnum;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _destroyRef: DestroyRef,
    private _errorService: LvErrorService,
  ) {
    this.dividendCurrencySource = new LvLookupEnum(Currency);

    this.dividends = {
      dividendParameters: {
      },
      dividendsSchedule:{
        dividendSchedule: []
      }
    } as IEquityDividends;
  }

  @HostBinding ('class.lv-equity-market-data-dividends')
  get isLvMarketDataEquityBorrowComponent (){
    return true;
  }

  ngOnInit(): void {
    this._analyticsPresenter.onModelLoading.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(isLoading => this.setLoadingState(isLoading));   

    this._analyticsPresenter.onAnalyticsSettingsUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.load();
    });

    this._analyticsPresenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event?.eventId === AnalyticsSettingsEvents.EquityInstrumentUpdated))
      .subscribe(() => {
        this.setDividendsCurrency();
    });
    
    this.load(); 
  }

  /**
   * Occurs on environment change and loads market data dividends.
   * @param environment IEnvironmentSettingsItem object.
   */
  async onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    await this.loadEquityDividends(environment);    
  }

   /**
   * Loads market data dividends
   * @param {IEnvironmentSettingsItem} environment pass environment settings
   */
  async loadEquityDividends(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      await this._analyticsPresenter.loadEquityDividends(environment);     
      this.load();
      this._changeDetectorRef.detectChanges();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on Dividend CCY change.
   */
  onDividendCCYChange() {
    this.dividends =  _.cloneDeep(this.dividends);
  }    

  /**
   * Save all dividends equity
   */
  async onSaveSection() {
    try {
      this.setLoadingState(true);
      this.dividendGrid.applyAdvancedGridChanges();
      await this._analyticsPresenter.saveEquityDividends(this.dividends, this.envSettings.getSelectedEnvironment().id);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388',{'value': 'Dividends'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }
  
  /**
   * Reload equity dividends
   */
  async onReloadSection() {
    this.loadEquityDividends(this.envSettings.getSelectedEnvironment());
  }

  updateMarketDataDividendsModel() {
      // this._analyticsPresenter.overrideEquityInSession((equitySession: IEquityValuationSession) => {
      //   equitySession.marketData.dividends = this.dividends;
      // });
    this._changeDetectorRef.detectChanges();
  }

  private load() {
    if (this._analyticsPresenter?.equitySession?.marketData) {
      this.dividends = _.cloneDeep(this._analyticsPresenter?.equitySession?.marketData?.dividends);

      this.setDividendsCurrency();
    }
  }

  /**
   * Set dividends currency when equity is saved and dividends ccy is empty.
   *
   * @private
   * @memberof LvEquityMarketDataDividendsComponent
   */
  private setDividendsCurrency() {
    if (!this.dividends.dividendsCcy || this.dividends.dividendsCcy === 'Underlying') {
      this.dividends.dividendsCcy = this._analyticsPresenter?.equitySession?.terms?.currencyCode;
    }

    this.originalValue = _.cloneDeep(this.dividends);
    this._changeDetectorRef.detectChanges();
    // force detect changes because input of child component did not changed reference.
    this.dividendParameters.detectChanges();
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.envSettings.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
