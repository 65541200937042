export const instrumentSettings = {
  averagingData: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  borrow: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  credit: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  dividends: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  funding: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  interestRates: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  modelSettings: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  pricing: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  volatility: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee'],
  witholdingTax: ['7485b383-9399-42e1-bc28-d46839aa2424', '4d4ecb43-06c4-40ec-b2dd-389222ce01ee']
};
