import { Inject, Injectable } from '@angular/core';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';
import { HttpClient } from '@angular/common/http';
import { LvReferenceDataError } from '@lv-reference-data/models/errors';
import { ILeversysDataSyncingSettings } from '@lv-reference-data/models/leversys-data-syncing';
import { IExchanges } from '@lv-reference-data/models/exchanges';

@Injectable({
  providedIn: 'root'
})
export class LeversysReferenceDataSettingsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/referencedata/companySettings');
  }

  /**
   * Get all equity data sync.
   * @returns ILeversysDataSyncingSettings object.
   */
  async getAllLeversysDataSyncing(): Promise<ILeversysDataSyncingSettings> {
    try {
      const result = await this.getAsync<ILeversysDataSyncingSettings>(null, '');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvReferenceDataError(e.message));
    }
  }

   /**
   * Reset all equity data sync.
   * @returns ILeversysDataSyncingSettings object.
   */
   async resetAllLeversysDataSyncing(): Promise<ILeversysDataSyncingSettings> {
    try {
      const result = await this.getAsync<ILeversysDataSyncingSettings>(null, '/default');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvReferenceDataError(e.message));
    }
  }

   /**
   * Save all equity data sync.
   * @returns ILeversysDataSyncingSettings object.
   */
   async saveAllLeversysDataSyncing(request:ILeversysDataSyncingSettings): Promise<void> {
    try {
     await this.postAsync<ILeversysDataSyncingSettings>(request, '');
    }
    catch (error) {
      throw this.handleError(error, e => new LvReferenceDataError(e.message));
    }
  }

  /**
   * Get all exchanges.
   * @returns ILeversysDataSyncingSettings object.
   */
  async getAllExchanges(): Promise<IExchanges[]> {
    try {
      const result = await this.getAsync<IExchanges[]>(null, '/exchanges');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvReferenceDataError(e.message));
    }
  }

}
