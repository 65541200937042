import { QuickAndFullTermsDocument } from '../../instrument/convertible-bond-terms/models/quick-and-full-terms-document';

/**
 * Custom Instrument.
 */
export class CustomInstrument {
  id: number;
  sessionId: string;
  privateInstrumentDocument: QuickAndFullTermsDocument; 
  originalInstrumentId: string;
  leversysId: string;
  underlyingId: number;
}
