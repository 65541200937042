<lv-advanced-grid
data-cy="DM-910"
[columns]="columns"
[records]="scheduleItems"
[createFormGroup]="createFormGroup"
[parseFn]="parseFn"
sortBy="startDate"
lvId="lvTermsPutsSchedule"
[showXlLabel]="hasScheduleInExcelOverride"
[excelFieldAlias]="excelFieldAlias"
[isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
class="lv-put-schedule"
(dataStateChange)="dataStateChange($event)"
[contextMenuItems]="contextMenuItems">
</lv-advanced-grid>
