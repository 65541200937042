import { Injectable } from '@angular/core';
import { IScheduledDividends } from './scheduled-dividends';
import { IDividendParameters } from './dividend-parameters';
import { IDividends } from './dividends';
import { CurrencyTypeEnum } from './enum/currency-type-enum';
import { IScheduledDividend } from './scheduled-dividend';
import { DateExtensions } from '@lv-core-ui/util';
import { IEquityDividendParameters, IEquityDividends, IEquityScheduledDividend, IEquityScheduledDividends } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-dividends';

/**
 * Dividends mapper.
 */
@Injectable()
export class DividendsMapper {  
  /**
   * Maps dividends to API.
   * @param item IDividends object.
   * @returns IDividends object.
   */
  mapDividendsToApi(item: IDividends): IDividends {
    const request = {
      ...item,
        dividendsCcy: (item.dividendCurrencySelected !== CurrencyTypeEnum.Convertible
        && item.dividendCurrencySelected !== CurrencyTypeEnum.Underlying)
          ? CurrencyTypeEnum.Underlying : item.dividendCurrencySelected,
    } as IDividends;

    request.dividendParameters = this.mapDividendParametersToApi(item.dividendParameters);
    request.dividendsSchedule = this.mapScheduledDividendsItemsToApi(item?.dividendsSchedule);

    return request;
  }

  /**
   * Maps dividends to UI.
   * @param item IDividends object.
   * @returns IDividends object.
   */
  mapDividendsToUi(item: IDividends): IDividends {
    const request = {
      ...item
    } as IDividends;

    request.dividendParameters = this.mapDividendParametersToUi(item.dividendParameters);
    request.dividendsSchedule = this.mapScheduledDividendsItemsToUi(item.dividendsSchedule);

    return request;
  }

  /**
   * Maps schedule dividends items to API.
   * @param scheduleItem IScheduledDividends object.
   * @returns  IScheduledDividends object.
   */
  private mapScheduledDividendsItemsToApi(scheduleItem: IScheduledDividends): IScheduledDividends {
    const scheduleItemsRequest = {
      ...scheduleItem,
      dividendSchedule: this.mapDividendScheduleToApi(scheduleItem?.dividendSchedule)
    } as IScheduledDividends;

    scheduleItemsRequest.currency = (scheduleItem.dividendCurrencySelected !== CurrencyTypeEnum.Convertible
      && scheduleItem.dividendCurrencySelected !== CurrencyTypeEnum.Underlying)
        ? CurrencyTypeEnum.Convertible : scheduleItem.dividendCurrencySelected;
    return scheduleItemsRequest;
  }

  /**
   * Maps schedule dividends item to UI.
   * @param scheduleItem  IScheduledDividends object.
   * @returns  IScheduledDividends object.
   */
  mapScheduledDividendsItemsToUi(scheduleItem: IScheduledDividends): IScheduledDividends {
    const scheduleItemsRequest = {
      ...scheduleItem,
      dividendSchedule: this.mapDividendScheduleToUi(scheduleItem.dividendSchedule)
    } as IScheduledDividends;

    return scheduleItemsRequest;
  }

  /**
   * Maps dividend schedule to API.
   * @param schedule List of IScheduledDividend objects.
   * @returns List of IScheduledDividend objects.
   */
  private mapDividendScheduleToApi(schedule: IScheduledDividend[]): IScheduledDividend[] {
    const scheduleRequest = schedule.map(a => ({
      ...a,
      date: DateExtensions.getTimeWithOffset(a.date as Date)
    }));

    return scheduleRequest;
  }

  /**
   * Maps dividend schedule to UI.
   * @param schedule List of IScheduledDividend objects.
   * @returns List of IScheduledDividend objects.
   */
  private mapDividendScheduleToUi(schedule: IScheduledDividend[]): IScheduledDividend[] {
    const scheduleRequest = schedule.map(a => ({
      ...a,
      date: DateExtensions.getDateWithoutOffset(a.date as number)
    }));

    return scheduleRequest;
  }

  /**
   * Maps dividend parameters to API.
   * @param schedule IDividendParameters object.
   * @returns IDividendParameters object.
   */
  private mapDividendParametersToApi(item: IDividendParameters): IDividendParameters {
    return {
      ...item,
      firstDividendDate: item.firstDividendDate ? DateExtensions.getTimeWithOffset(item.firstDividendDate as Date) : null
    } as IDividendParameters;
  }

  /**
   * Maps dividend parameters to UI.
   * @param schedule IDividendParameters object.
   * @returns IDividendParameters object.
   */
  private mapDividendParametersToUi(item: IDividendParameters): IDividendParameters {
    return {
      ...item,
      firstDividendDate: item.firstDividendDate ? DateExtensions.getDateWithoutOffset(item.firstDividendDate as number) : null
    } as IDividendParameters;
  }

  mapEquityDividendToApi(dividend: IEquityDividends) {
    const request = {
      ...dividend,
        dividendsCcy: (dividend.dividendsCcy !== CurrencyTypeEnum.Convertible
        && dividend.dividendsCcy !== CurrencyTypeEnum.Underlying)
          ? dividend.dividendsCcy : CurrencyTypeEnum.Underlying,
    } as IEquityDividends;

    request.dividendParameters = this.mapEquityDividendParametersToApi(dividend.dividendParameters);
    request.dividendsSchedule = this.mapEquityScheduledDividendsItemsToApi(dividend?.dividendsSchedule);

    return request;
  }

  private mapEquityDividendParametersToApi(item: IEquityDividendParameters): IEquityDividendParameters {
    return {
      ...item,
      firstDividendDate: item.firstDividendDate ? DateExtensions.getTimeWithOffset(item.firstDividendDate as Date) : null
    } as IEquityDividendParameters;
  }

  /**
   * Maps schedule dividends items to API.
   * @param scheduleItem IScheduledDividends object.
   * @returns  IScheduledDividends object.
   */
  private mapEquityScheduledDividendsItemsToApi(scheduleItem: IEquityScheduledDividends): IEquityScheduledDividends {
    const scheduleItemsRequest = {
      ...scheduleItem,
      dividendSchedule: this.mapEquityDividendScheduleToApi(scheduleItem?.dividendSchedule)
    } as IEquityScheduledDividends;
    return scheduleItemsRequest;
  }

  /**
   * Maps dividend schedule to API.
   * @param schedule List of IScheduledDividend objects.
   * @returns List of IScheduledDividend objects.
   */
  private mapEquityDividendScheduleToApi(schedule: IEquityScheduledDividend[]): IEquityScheduledDividend[] {
    const scheduleRequest = schedule.map(a => ({
      ...a,
      date: DateExtensions.getTimeWithOffset(a.date as Date)
    }));

    return scheduleRequest;
  }

  mapEquityDividendsToUi(item: IEquityDividends): IEquityDividends {
    const response = {
      ...item
    } as IEquityDividends;

    response.dividendParameters = this.mapEquityDividendParametersToUi(item.dividendParameters);
    response.dividendsSchedule = this.mapEquityScheduledDividendsItemsToUi(item.dividendsSchedule);
    return response;
  } 

  /**
   * Maps dividend parameters to UI.
   * @param schedule IDividendParameters object.
   * @returns IDividendParameters object.
   */
  private mapEquityDividendParametersToUi(item: IEquityDividendParameters): IEquityDividendParameters {
    return {
      ...item,
      firstDividendDate: item.firstDividendDate ? DateExtensions.getDateWithoutOffset(item.firstDividendDate as number) : null
    } as IEquityDividendParameters;
  }

    /**
   * Maps schedule dividends item to UI.
   * @param scheduleItem  IScheduledDividends object.
   * @returns  IScheduledDividends object.
   */
    mapEquityScheduledDividendsItemsToUi(scheduleItem: IEquityScheduledDividends): IEquityScheduledDividends {
      const scheduleItemsRequest = {
        ...scheduleItem,
        dividendSchedule: this.mapEquityDividendScheduleToUi(scheduleItem.dividendSchedule)
      } as IEquityScheduledDividends;
  
      return scheduleItemsRequest;
    }

    /**
   * Maps dividend schedule to UI.
   * @param schedule List of IScheduledDividend objects.
   * @returns List of IScheduledDividend objects.
   */
  private mapEquityDividendScheduleToUi(schedule: IEquityScheduledDividend[]): IEquityScheduledDividend[] {
    const scheduleRequest = schedule.map(a => ({
      ...a,
      date: DateExtensions.getDateWithoutOffset(a.date as number)
    }));

    return scheduleRequest;
  }

}
