import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { DiscardChangesService } from "@lv-common/common-data/services/discard-changes.service";
import { LvDataMaster } from "@lv-core-ui/models";
import { LvErrorService } from "@lv-core-ui/services";
import {
  IBaseBondTermsSettings,
  IBondCallSectionSettings,
  IBondCouponSectionSettings,
  IBondPutSectionSettings,
  IBondTermsSectionSettings,
  IBondTermsSettings,
} from "src/app/modules/instrument/bond-terms/models/bond-terms-settings";
import * as _ from "lodash";
import { IDiscardable } from "@lv-common/common-data/models/discardable";
import { IBondGeneralSectionSettings } from "src/app/modules/instrument/bond-terms/models/bond-terms-settings/bond-general-settings/bond-general-section-settings";
import { BondTermsSettingsService } from "@lv-custom-instruments/services/bond-terms-settings/bond-terms-settings.service";
import { IBondAccretionSectionSettings } from "src/app/modules/instrument/bond-terms/models/bond-terms-settings/bond-accreation-settings/bond-accreation-section-settings";
import { IBondMakeWholeSectionSettings } from "src/app/modules/instrument/bond-terms/models/bond-terms-settings/bond-make-whole-settings/bond-make-whole-section-settings";

@Component({
  selector: "lv-bond-settings",
  templateUrl: "./lv-bond-settings.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LvBondSettingsComponent implements OnInit, IDiscardable {
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  settings: IBondTermsSectionSettings;
  originalSettings: IBondTermsSectionSettings;

  constructor(
    private _errorService: LvErrorService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _discardChangesService: DiscardChangesService,
    private _bondTermsSettingsService: BondTermsSettingsService
  ) {
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {},
        } as IBaseBondTermsSettings<IBondGeneralSectionSettings>,
        couponSettings: {
          overrideSystemDefaults: false,
          settings: { 
            floating: {},
            pik: {}
          }
        } as IBaseBondTermsSettings<IBondCouponSectionSettings>,
        accretionSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseBondTermsSettings<IBondAccretionSectionSettings>,
        callSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseBondTermsSettings<IBondCallSectionSettings>,
        putSettings: {
          overrideSystemDefaults: false,
          settings: { }
        } as IBaseBondTermsSettings<IBondPutSectionSettings>,
        makeWholeSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseBondTermsSettings<IBondMakeWholeSectionSettings>
      } as IBondTermsSettings,
      myDefaults: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {},
        } as IBaseBondTermsSettings<IBondGeneralSectionSettings>,
        couponSettings: {
          overrideSystemDefaults: false,
          settings: { 
            floating: {},
            pik: {}
          }
        } as IBaseBondTermsSettings<IBondCouponSectionSettings>,
        accretionSettings: {
          overrideSystemDefaults: false,
          settings: {},
        } as IBaseBondTermsSettings<IBondAccretionSectionSettings>,
        callSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseBondTermsSettings<IBondCallSectionSettings>,
        putSettings: {
          overrideSystemDefaults: false,
          settings: { }
        } as IBaseBondTermsSettings<IBondPutSectionSettings>,
        makeWholeSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseBondTermsSettings<IBondMakeWholeSectionSettings>
      } as IBondTermsSettings,
    };

    this._discardChangesService.register("Terms", this);
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getBondTermsSettings();
  }

  @HostBinding("class.lv-flex-box")
  get isFlexComponent() {
    return true;
  }

  @HostBinding("class.lv-flex-box--column")
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding("class.lv-terms-settings")
  get isLvTermsSettingsComponent() {
    return true;
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.next();
  }

  /**
   * Gets terms settings.
   */
    async getBondTermsSettings() {
      try {
        this.startLoading.next();
        this.settings = await this._bondTermsSettingsService.getBondTermsSettings();
        this.originalSettings = _.cloneDeep(this.settings);
      }
      catch (error) {
        this._errorService.handleError(error);
      }
      finally {
        this.endLoading.next();
        this._changeDetectorRef.detectChanges();
      }
    }

  /**
   * Occurs on save and saves term settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next();
      await this._bondTermsSettingsService.saveBondTermsSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);

      this._errorService.toastrService.success(
        LvDataMaster.getInfo("dM-4751", { settings_section: "Terms" })
      );
    } catch (error) {
      this._errorService.handleError(error);
    } finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

/**
 * Resets settings to default.
 */
  async onResetToDefault() {
    try {
      this.startLoading.next();
      this.settings = await this._bondTermsSettingsService.resetBondTermsSettings();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'Terms'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  
/**
 * Check is settings changed.
 */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
 * Discard changes.
 */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }
}
