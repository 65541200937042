import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConvertibleBondTermsDocument, ITermsSectionSettings, LvConvertibleBondTermsError } from '@lv-convertible-bond/models';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Terms settings service.
 */
@Injectable({
  providedIn: 'root'
})
export class TermsSettingsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/privateInstruments/convertibleBondTermsSettings');
  }

  /**
   * Gets terms settings.
   * @returns ITermsSectionSettings object.
   */
  async getTermsSettings(): Promise<ITermsSectionSettings> {
    try {
      const result = await this.getAsync<ITermsSectionSettings>(null, '');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Gets terms settings Excel.
   * @param firstSettlement First settlement.
   * @param maturity Maturity.
   * @returns ConvertibleBondTermsDocument object.
   */
  async createTermsFromSettings(firstSettlement: number, maturity: number, isNewIssue: boolean): Promise<ConvertibleBondTermsDocument> {
    try {
      const result = await this.getAsync<ConvertibleBondTermsDocument>({
        firstSettlement: firstSettlement,
        maturity: maturity,
        isNewIssue: isNewIssue
      }, '/getTermsSettingsExcel');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Saves terms settings.
   * @param request ITermsSectionSettings object.
   */
  async saveTermsSettings(request: ITermsSectionSettings): Promise<void> {
    try {
      await this.postAsync(request, '/saveTermsSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Resets terms settings.
   * @returns ITermsSectionSettings object.
   */
  async resetTermsSettings(): Promise<ITermsSectionSettings> {
    try {
      const result = await this.getAsync<ITermsSectionSettings>(null, '/default');
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }
}
