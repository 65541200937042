// tslint:disable-next-line: max-line-length
import { HardCallModelType, SoftCallModelTypeDescription, TriggerConditionMonitoring } from 'src/app/modules/analytics/models/model-customization/model-customization-enum';

export const callAdjustmentModelSettings = {
  hardCallAdjustmentFactor: 100,
  hardCallModelType: HardCallModelType.BarrierModel,
  notCallableDate: null,
  notCallableParity: null,
  softCallAdjustmentFactor: 125,
  softCallModelType: SoftCallModelTypeDescription.SoftCallTrigger,
  triggerConditionMonitoring: TriggerConditionMonitoring.UseStockFixingMonitoring,
  useCallNotice: false
};
