

import { DiscountRateType } from '../../../common/enums/DiscountRateType';
import { TakeoverProtectionRatchetMatrixItem } from '../../../common/TakeoverProtectionRatchetMatrixItem';
import { CallMakeWholeType } from '../../../common/enums/CallEnums';
import { RatchetMatrixOfferValueType } from '../../../common/enums/RatchetMatrixOfferValueType';
import { RatchetMatrixCompensationValueType } from '../../../common/enums/RatchetMatrixCompensationValueType';

export class CallMakeWholeDataConversion {
    
    type: CallMakeWholeType;
    initialPremium: number;
    couponsIncludedUntil: Date;
    discountRateType: DiscountRateType;
    discountSpread: number;
    ratchetMatrixMembers: TakeoverProtectionRatchetMatrixItem[];
    remainingCouponMWPeriod: number;
    rollingMWPeriod: boolean;
    ratchetMatrixOfferValueType: RatchetMatrixOfferValueType;
    ratchetMatrixCompensationValueType: RatchetMatrixCompensationValueType;
}

