import * as _ from 'lodash';

import {
  Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef,
  ViewChild, OnDestroy, ElementRef, Output, EventEmitter, ViewRef, OnChanges, Optional,
  DestroyRef
} from '@angular/core';

import { Subscription } from 'rxjs';
import { IMarketDataComponent } from '../../market-data-component';
import { LvScheduleDividendsComponent } from './lv-schedule-dividends/lv-schedule-dividends.component';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvScheduleDividendsTableComponent } from './lv-schedule-dividends-table/lv-schedule-dividends-table.component';
import { LvDateService, LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum, DateExtensions } from '@lv-core-ui/util';
import { IDividendsRequest } from '@lv-analytics/models/market-data/dividends/requests/dividends-request';
import {
  IEnvironmentSettingsItem,
  LvEnvironmentSettingsComponent
} from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import {
  CurrencyTypeEnum, DividendsMapper, DividendsSourceEnum, DividendsSourceEnumDescription, DividendTypeEnum,
  ICalculateScheduledDividendsRequest, ICurrency, IDividends, IDividendsSessionRequest,
  IScheduledDividends
} from '@lv-analytics/models/market-data/dividends';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { MarketDataService } from '@lv-analytics/services/market-data/market-data.service';
import { CommonDataService } from '@lv-analytics/services';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { LvAnalyticsError } from '@lv-analytics/models/errors';
import { IDividendsCopyAndPasteSectionSettings, IEquityDividends } from '@lv-analytics/models';
import { LvExcelService } from '@lv-excel/services';
import { LvDataMaster } from '@lv-core-ui/models';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEquityValuationSession } from '@lv-analytics/models/valuation-session/equity-valuation-session';

/**
 * Dividends component.
 */
@Component({
  selector: 'lv-dividends',
  templateUrl: './lv-dividends.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDividendsComponent implements OnInit, OnDestroy, OnChanges, IMarketDataComponent<IDividendsRequest> {
  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild(LvScheduleDividendsComponent) scheduleDividendsComponent: LvScheduleDividendsComponent;

  get isNoDividendsIsSelected() {
    return this.dividendsSettings.dividendsSource === DividendsSourceEnum.NoDividends;
  }

  get isParameterDividendsSelected() {
    return this.dividendsSettings.dividendsSource === DividendsSourceEnum.Parameters;
  }

  get isScheduleDividendsSelected() {
    return this.dividendsSettings.dividendsSource === DividendsSourceEnum.Schedule;
  }

  get isDividendsSectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get isConvertibleCcySelectedOnParameters(): boolean {
    return this.dividendsSettings.dividendCurrencySelected === CurrencyTypeEnum.Convertible;
  }

  get isUnderlyingCcySelectedOnParameters(): boolean {
    return this.dividendsSettings.dividendCurrencySelected === CurrencyTypeEnum.Underlying;
  }

  get isConvertibleCcySelectedOnScheduled(): boolean {
    return this.dividendsSettings.dividendsSchedule.dividendCurrencySelected === CurrencyTypeEnum.Convertible;
  }

  get isUnderlyingCcySelectedOnScheduled(): boolean {
    return this.dividendsSettings.dividendsSchedule.dividendCurrencySelected === CurrencyTypeEnum.Underlying;
  }

  get currencyTypeVisible() {
    return this.dividendsSettings.dividendsSource === DividendsSourceEnum.Parameters &&
      this.dividendsSettings.dividendParameters.dividendType !== DividendTypeEnum.DiscreteYield &&
      this.dividendsSettings.dividendParameters.dividendType !== DividendTypeEnum.ContinuousYield;
  }

  get isUnderlyingDividendsParametersSelected (): boolean {
    return this.dividendsSettings.dividendsSource === DividendsSourceEnum.UnderlyingEquityDataParameters;
  }

  get isUnderlyingDividendsScheduleSelected (): boolean {
    return this.dividendsSettings.dividendsSource === DividendsSourceEnum.UnderlyingEquityDataSchedule;
  }

  get isUnderlyingEquity(): boolean {
    return this.dividendsSettings.dividendsSource === DividendsSourceEnum.UnderlyingEquityDataParameters ||
      this.dividendsSettings.dividendsSource === DividendsSourceEnum.UnderlyingEquityDataSchedule
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get sectionMinWidth(): number {
    switch (this.dividendsSettings.dividendsSource) {
      case DividendsSourceEnum.Parameters:
        return 325;
      case DividendsSourceEnum.Schedule:
        return 420;
      default:
        return 315;
    }
  }

  get hasDividendsParamRangeOverridedFromExcel(): boolean {
    return !!this._excelSvc?.hasDividendsParamRangeOverridedFromExcel();
  }

  dividendsSettings: IDividends;
  dividendsSource: LvLookupEnum;
  dividendCurrencySource: LvLookupEnum;
  scheduledDividend: IScheduledDividends;
  currencies: ICurrency[];

  originalValue: IDividends;
  fxRate: number;
  pricingDividendYield?: number;

  dividendCCY: any;
  popupSettings: PopupSettings;

  underlyingEquityDividends: IEquityDividends;
  originalValueUnderlyingEquityDividends: IEquityDividends

  dividendsSection = PricingEnvironmentSections.Dividends;

  dividendsCopyAndPasteSettings: IDividendsCopyAndPasteSectionSettings;
  isEditable: boolean;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private marketDataService: MarketDataService,
    private errorService: LvErrorService,
    private dividendMapper: DividendsMapper,
    private commonDataService: CommonDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _dialogService: DialogService,
    private _lvDateService: LvDateService,
    private _destroyRef: DestroyRef,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.dividendsSource = new LvLookupEnum(DividendsSourceEnumDescription);
    this.dividendsSource.setFilterFn(item => {
      if (!this._analyticsPresenter.asHelper.hasUnderlying() && item.id.startsWith('Underlying')) {
        return false;
      }

      return true;
    });

    this.dividendCurrencySource = new LvLookupEnum(CurrencyTypeEnum);

    this.scheduledDividend = {} as IScheduledDividends;

    this.currencies = [];

    this.pricingDividendYield = null;

    this.dividendsSettings = {
      dividendParameters: {}
    } as IDividends;

    this.underlyingEquityDividends = {
      dividendParameters: {},
      dividendsSchedule:{
        dividendSchedule: []
      }
    } as IEquityDividends;

    this.didSessionUpdatedEvent = new EventEmitter<void>();
    this.dividendsCopyAndPasteSettings = {} as IDividendsCopyAndPasteSectionSettings;
    this.popupSettings = {
      width: 231
    };

    this.isEditable = false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
            this.dividendsSettings = evt.data.marketData.dividends;
            this.originalValue = _.cloneDeep(this.dividendsSettings);
            this.dividendCCY = this.dividendsSettings.dividendCurrencySelected;
            this.loadSettings();         

            if(this._analyticsPresenter?.equitySession?.marketData){
              this.initUnderlyingData(this._analyticsPresenter.equitySession);
            }
            this._changeDetectorRef.detectChanges();
          }
        }
      }),

      this._analyticsPresenter.onOtherSettingsChanged.subscribe(() => {
        this.loadSettings();
      }),

      this._analyticsPresenter.onPricingModelUpdated.subscribe(evt => {
        if (evt) {
          this.pricingDividendYield = evt.data.viInputs.flatDividendYield;
          this._changeDetectorRef.detectChanges();
        }
      })
    ];

    this._analyticsPresenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(async() => {
      if(this._analyticsPresenter?.equitySession?.marketData){
        this.underlyingEquityDividends = this._analyticsPresenter?.equitySession?.marketData?.dividends;  
        this.originalValueUnderlyingEquityDividends = _.cloneDeep(this.underlyingEquityDividends);
        this._changeDetectorRef.detectChanges();
      }
    });

    if(this._analyticsPresenter?.equitySession?.marketData){
      this.initUnderlyingData(this._analyticsPresenter.equitySession);
    }


    if (this._analyticsPresenter.isConvertibleModelLoaded()) {
      this.loadSettings();
    }

    this.loadCurrencies();
    this._changeDetectorRef.detectChanges();
  }

  private initUnderlyingData(underlyingValuationSesesion: IEquityValuationSession) {
    this.underlyingEquityDividends =  underlyingValuationSesesion.marketData.dividends;
    this.originalValueUnderlyingEquityDividends = _.cloneDeep(this.underlyingEquityDividends);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    this.dividendCCY = this.dividendsSettings.dividendCurrencySelected;
  }

  /**
   * onGenerateSchedule - creating Dividend Schedule using data from Parameters
   * We get Dividend Schedule from Pricer
   */
  async onGenerateSchedule() {
    try {
      this.setLoadingState(true);

      const request = {} as ICalculateScheduledDividendsRequest;
      Object.assign(request, this.dividendsSettings.dividendParameters);
      this.applyValuationParameters(request);

      this.dividendsSettings.dividendsSchedule = await this.marketDataService.getScheduledDividends(request);

      this.dividendsSettings.dividendsSchedule.dividendCurrencySelected =
        this.dividendsSettings.dividendCurrencySelected;

      this.dividendsSettings = this.dividendMapper.mapDividendsToUi(this.dividendsSettings);

      this.dividendsSettings.dividendsSource = DividendsSourceEnum.Schedule;

      const requestOverride = {
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        dividends: this.dividendMapper.mapDividendsToApi(this.dividendsSettings)
      } as IDividendsSessionRequest;

      const underlyingSession = await this.marketDataService.overrideDividends(requestOverride);

      if(underlyingSession){
        this.initUnderlyingData(underlyingSession);
      }

      this._analyticsPresenter.updateDividends(this.dividendsSettings, underlyingSession);  

      // this._analyticsPresenter.updateModel({
      //   eventId: AnalyticsSettingsEvents.DividendsUpdated,
      //   data: this.dividendsSettings
      // });
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on dividend section change.
   * @param sourceChaged A flag indicating if source is changed.
   */
  async onDividendSectionChanged(sourceChaged?: boolean) {
    try {
      if (sourceChaged && !(!!this._excelSvc?.isInitialized)) {
        const dividendsSourceBackup = this.dividendsSettings.dividendsSource;
        const dividendsCCYSelected = this.dividendsSettings.dividendCurrencySelected;
        const loadResponse = await this.marketDataService.loadDividends(
          this._analyticsPresenter.asHelper.sessionId,
          this._analyticsPresenter.asHelper.lwsIdentifier,
          this.getSelectedEnvironmentId());

        loadResponse.dividends.dividendsSource = dividendsSourceBackup;
        loadResponse.dividends.dividendCurrencySelected = dividendsCCYSelected;

        this.dividendsSettings= loadResponse.dividends;
      }

      this.dividendCCY = this.dividendsSettings.dividendCurrencySelected;

      const request = {
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        dividends: this.dividendMapper.mapDividendsToApi(this.dividendsSettings)
      } as IDividendsSessionRequest;

     // const underlyingValSesion = await this.marketDataService.overrideDividends(request);

      const underlyingSession = await this.marketDataService.overrideDividends(request);

      if(underlyingSession){
        this.initUnderlyingData(underlyingSession);
      }

      this._analyticsPresenter.updateDividends(this.dividendsSettings, underlyingSession);  

      // this._analyticsPresenter.updateModel({
      //   eventId: AnalyticsSettingsEvents.DividendsUpdated,
      //   data: this.dividendsSettings
      // });

      if (!_.isEqual(this.dividendsSettings, this.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.originalValue = _.cloneDeep(this.dividendsSettings);
      }
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on change environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangeEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadDividends(environment);
  }

  /**
   * Loads settings.
   */
  loadSettings() {
    const modelData = this._analyticsPresenter.getModelData();

    if (this._analyticsPresenter.isConvertibleModelLoaded()
      && modelData.otherSettings
      && modelData.otherSettings.copyAndPasteSettings) {

      this.dividendsCopyAndPasteSettings = modelData.otherSettings.copyAndPasteSettings.dividends;
    }

    this._changeDetectorRef.detectChanges();
  }

  public applyValuationParameters = (request: ICalculateScheduledDividendsRequest) => {
    const pricing = this._analyticsPresenter.asHelper.pricing;
    if (!pricing || !pricing.stockPriceUndCcy) {
      throw new LvAnalyticsError(LvDataMaster.getError('dM-3383',
      {
        'fieldName': 'Underlying Price'
      }));
    }
    else if (!pricing || !pricing.valuationDate) {
      throw new LvAnalyticsError(LvDataMaster.getError('dM-3383',
      {
        'fieldName': 'Valuation Date'
      }));
    }
    else {
      request.underlyingPrice = pricing.stockPriceUndCcy;
      request.valuationDate = new Date(pricing.valuationDate);
      /* TODO maturity date should be provided by backend/api  */
      request.maturityDate = this.getMaturityDate(new Date(this._analyticsPresenter.cHelper.convertible.maturityDate));
      request.isin = this._analyticsPresenter.cHelper.convertible.isin;
      request.firstDividendDate = DateExtensions.getTimeWithOffset(request.firstDividendDate as Date);
      request.dividendGrowth = request.dividendGrowth / 100;

      request.dividendYieldOverride = this.pricingDividendYield;
      request.dividendSource = this.dividendsSettings.dividendsSource;
      request.sessionId = this._analyticsPresenter.asHelper.sessionId;
      request.lwsIdentifier = this._analyticsPresenter.asHelper.lwsIdentifier;

      if (request.dividendType === DividendTypeEnum.DiscreteYield) {
        request.dividendValue = request.dividendValue / 100;
      }
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Gets selected environment ID.
   * @returns Environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns IDividendsRequest object.
   */
  getSettings(): IDividendsRequest {
    return this.getDividendsSaveRequest();
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {
    if (this.dividendsSettings.dividendsSource === DividendsSourceEnum.Schedule) {
      this.scheduleDividendsComponent.tableComponent.applyAdvancedGridChanges();
    }
  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections object.
   */
  getSection(): PricingEnvironmentSections {
    return this.dividendsSection;
  }

  /**
   * Gets dividend tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Dividend tooltip ID.
   */
  getDividendTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('dividend-tooltip-id') === sectionId;
  }

  /**
   * Occurs on display dividends used.
   */
  async onDisplayDividendsUsed() {
    try {
      const request = {} as ICalculateScheduledDividendsRequest;
      Object.assign(request, this.dividendsSettings.dividendParameters);
      this.applyValuationParameters(request);

      let data = await this.marketDataService.getDividendsUsed(request);
      data = this.dividendMapper.mapScheduledDividendsItemsToUi(data);

      if (this.dividendsSettings.dividendsCcy === CurrencyTypeEnum.Convertible) {
        data.currencyValue = this._analyticsPresenter.cHelper.currencyCode;
      }
      else {
        data.currencyValue = this._analyticsPresenter.cHelper.underlyingCurrencyCode;
      }

      const isConvertibleCcySelected =
        this.dividendsSettings.dividendCurrencySelected === CurrencyTypeEnum.Convertible;
      const isUnderlyingCcySelected =
        this.dividendsSettings.dividendCurrencySelected === CurrencyTypeEnum.Underlying;

      data.dividendSchedule.forEach(item => {
        if (isConvertibleCcySelected) {
          item.dividendsCcy = this._analyticsPresenter.cHelper.currencyCode;
        }
        else if (isUnderlyingCcySelected) {
          item.dividendsCcy = this._analyticsPresenter.cHelper.underlyingCurrencyCode;
        }
        else {
          item.dividendsCcy = this.dividendsSettings.dividendCurrencySelected;
        }
      });

      const dialogRef = this._dialogService.open({
        title: 'Dividends Used',
        content: LvScheduleDividendsTableComponent
      });

      const dialog = dialogRef.content.instance as LvScheduleDividendsTableComponent;

      dialog.scheduleDividends = data.dividendSchedule.map(a => a);

      dialog.editEnabled = false;
      dialog.isParametersTable = true;
      dialog.pricingDividendYield = this.pricingDividendYield;
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Loads dividends.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadDividends(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

       const loadResponse = await this.marketDataService.loadDividends(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id);

      this.dividendsSettings = loadResponse.dividends;

      this._analyticsPresenter.updateDividends(this.dividendsSettings, loadResponse.underlyingValuationSession);  

      if(loadResponse.underlyingValuationSession){
        this.initUnderlyingData(loadResponse.underlyingValuationSession);
      }

      // this._analyticsPresenter.updateModel({
      //   eventId: AnalyticsSettingsEvents.DividendsUpdated,
      //   data: this.dividendsSettings
      // });
    }
    catch (e) {
      this.errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Gets dividends save request.
   * @returns IDividendsRequest object.
   */
  private getDividendsSaveRequest(): IDividendsRequest {
    const env = this.envSettings.getSelectedEnvironment();
    const request = {
      environmentId: env.id,
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      dividends: this.dividendsSettings
    } as IDividendsRequest;

    return request;
  }

  /**
   * Load currencies.
   */
  private async loadCurrencies() {
    try {
      this.setLoadingState(true);
      this.currencies = await this.commonDataService.getAllCurrencies();
      this.dividendCurrencySource.addItems(this.currencies, 'code', 'code');
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Gets maturity date.
   * @param maturity Maturity date.
   * @returns Maturity date.
   */
  private getMaturityDate(maturity: Date): Date {
    const today = new Date();
    let maturityPerpetual;
    
    if (this._analyticsPresenter.cHelper.convertible.isPerpetual) {
        maturityPerpetual = new Date(today.getTime() + 10 * 365 * 24 * 60 * 60 * 1000); // Adding 10 years
        return maturityPerpetual;
    } else {
        return maturity;
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Set dividends source based on underlying equity linked.
   */
  private dividendsTypeEquityFallbackLogic() {
    if (!this._analyticsPresenter.asHelper.hasUnderlying() && this.dividendsSettings.dividendsSource.toLowerCase().startsWith('underlying')) {

      switch (this.dividendsSettings.dividendsSource) {
        case DividendsSourceEnum.UnderlyingEquityDataParameters: {
          this.dividendsSettings.dividendsSource = DividendsSourceEnum.Parameters;
          break;
        }
        case DividendsSourceEnum.UnderlyingEquityDataSchedule: {
          this.dividendsSettings.dividendsSource = DividendsSourceEnum.Schedule;
          break;
        }
      }
    }
  }
}
