import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'instrumentTypeAbbreviation'
})
export class InstrumentTypeAbbreviationPipe implements PipeTransform {
  private instrumentAbbreviationMap = new Map<string, string>([
    ['ConvertibleBond', 'CB'],
    ['Equity', 'EQ'],
    ['Asset Swap', 'ASW'],
    ['CDS', 'CDS'],
    ['Equity Options', 'EQO'],
    ['Bond', 'BND']
  ]);

  /**
   * Add proper abbreviations for instrument type to instrument name.
   * @param instrumentName Instrument name.
   * @param instrumentType Instrument type.
   * @returns Modified instrument name.
   */
  transform(instrumentName: string, instrumentType: string): string {
    const abbreviation = this.instrumentAbbreviationMap.get(instrumentType) || '';
    return abbreviation ? `${abbreviation} - ${instrumentName}` : instrumentName;
  }
}
