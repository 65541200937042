
<lv-flex-box class="lv-volatility-equity-settings-container">
    <lv-volatility-equity-settings
      [settings]="settings">
    </lv-volatility-equity-settings>

    <lv-flex-box class="lv-settings-footer" direction="row">
      <button kendoButton
        [primary]="true"
        lvId="cancel-equity-volatility-settings"
        (click)="onCancel()">
          Cancel
      </button>
      <div class="lv-settings-footer-divider">&nbsp;</div>

      <button kendoButton
        class="reset-button"
        [primary]="true"
        lvId="reset-to-default-equity-volatility-settings"
        (click)="onResetToDefault()">
        Reset To Default
      </button>
      <button kendoButton
        [primary]="true"
        [class.save-settings-btn-disabled]="!isChanged()"
        lvId="save-equity-volatility-settings"
        (click)="onSave()">
          Save
      </button>
    </lv-flex-box>
</lv-flex-box>
