import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter,
  ChangeDetectorRef, HostBinding, 
  Input} from '@angular/core';
import { IMarketDataSettings, IBorrowSectionSettings, IBorrowSettings, IDividendsSectionSettings, IDividendsSettings,
         IInterestRatesSectionSettings, IInterestRatesSettings, ICreditSectionSettings, ICreditSettings, IFundingSectionSettings,
         IFundingSettings, IWithholdingTaxSectionSettings, IWithholdingTaxSettings, IVolatilitySectionSettings,
         IVolatilitySettings } from '@lv-analytics/models';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { InstrumentType } from '@lv-analytics/models/enum/instrument-type';
import * as _ from 'lodash';

/**
 * Market data settings component.
 */
@Component({
  selector: 'lv-market-data-settings',
  templateUrl: './lv-market-data-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvMarketDataSettingsComponent implements OnInit, IDiscardable {
  @Input() instrumentType: string;
  @Output() didCancel: EventEmitter<void>;
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;

  settings: IMarketDataSettings;
  originalSettings: IMarketDataSettings;

  systemYieldCurvesSettingsData: ISystemYieldCurvesSettingsView;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _discardChangesService: DiscardChangesService
  ) {
    this.instrumentType = '';
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      borrowSettings: {
        systemDefaults: {} as IBorrowSectionSettings,
        environments: []
      } as IBorrowSettings,
      dividendsSettings: {
        systemDefaults: {} as IDividendsSectionSettings,
        environments: []
      } as IDividendsSettings,
      interestRatesSettings: {
        systemDefaults: {
          yieldCurveSettings: []
        } as IInterestRatesSectionSettings,
        environments: []
      } as IInterestRatesSettings,
      creditSettings: {
        systemDefaults: {} as ICreditSectionSettings,
        environments: []
      } as ICreditSettings,
      fundingSettings: {
        systemDefaults: {} as IFundingSectionSettings,
        environments: []
      } as IFundingSettings,
      withholdingTaxSettings: {
        systemDefaults: {} as IWithholdingTaxSectionSettings,
        environments: []
      } as IWithholdingTaxSettings,
      volatilitySettings: {
        systemDefaults: {} as IVolatilitySectionSettings,
        environments: []
      } as IVolatilitySettings
    } as IMarketDataSettings;
    this.originalSettings = _.cloneDeep(this.settings);
    this._discardChangesService.register('MarketData', this);
    this.systemYieldCurvesSettingsData = {} as ISystemYieldCurvesSettingsView;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-market-data-settings')
  get isLvMarketDataSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-section-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.getMarketDataSettings();
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.getMarketDataAswSettings();
      }
      this.originalSettings = _.cloneDeep(this.settings);

      this.systemYieldCurvesSettingsData = await this._companyAndUserSettingsService.getYieldCurveSettingsData();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.next();
  }

  /**
   * Saves market data settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        await this._companyAndUserSettingsService.saveMarketDataSettings(this.settings);
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        await this._companyAndUserSettingsService.saveMarketDataAswSettings(this.settings);
      }
      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'Market Data'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Resets market data settings.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.resetMarketDataSettings();
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.resetMarketDataAswSettings();
      }
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'Market Data'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }
}
