import { Pipe, PipeTransform } from '@angular/core';
import { LvDateService } from '@lv-core-ui/services';

@Pipe({
  name: 'lvLocalDate'
})
export class LvLocalDatePipe implements PipeTransform {

  constructor(
    private _lvDateService: LvDateService
  ) {

  }

  transform(value?: any, defaultValue = null, withTime: boolean = false): any {
    if (!value) {
      return defaultValue;
    }

    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    return this._lvDateService.formatToLocaleDateTime(withTime, value);
  }
}
