import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IConvertibleBondInfo, LvInstrumentMonitorError } from '../../models';
import { HttpClientBase } from '@lv-core-ui/api';
import { IPaginatedResult } from '@lv-core-ui/models';
import { LvDateService } from '@lv-core-ui/services';

@Injectable()
export class ConvertibleInfoService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/instrumentMonitor/convertibleInfos');
  }

  // tslint:disable-next-line:max-line-length
  async queryAvailableConvertibles(query: string, watchListIds: number[], skip: number, take: number): Promise<IPaginatedResult<IConvertibleBondInfo>> {
    try {
      const result: IPaginatedResult<IConvertibleBondInfo> = await this.getAsync<IPaginatedResult<IConvertibleBondInfo>>({
        query: query || '',
        watchListIds: watchListIds,
        skip: skip,
        take: take
      }, '/available');

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }
}
