import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IEquityVolatility } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-volatility.ts/equity-volatility';

@Component({
  selector: 'lv-equity-volatility-flat',
  templateUrl: './lv-equity-volatility-flat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityVolatilityFlatComponent {
  @Input()volatilityFlat: IEquityVolatility;
  @Input() isEditable: boolean;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  numberOfDecimals = '2';
  numberFormat = '#.##';
  constructor() {
    this.didSessionUpdatedEvent = new EventEmitter();
    this.isEditable = true;
  }

  @HostBinding ('class.lv-equity-volatility-flat')
  get isLvEquityMarketDataVolatilityComponent (){
    return true;
  }

  /**
   * Method handles volatility flat change.
   */
  onVolatilityChange() {
    this.didSessionUpdatedEvent.next();
  }
}
