import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showAccessScopeIcon'
})
export class ShowAccessScopeIconPipe implements PipeTransform {

  /**
   * Should show the access scope icon for instrument.
   * @param isPrivateInstrument Is instrument private instrument.
   * @param instrumentType Instrument type.
   * @returns Flag that indicates whether access scope icon should be shown.
   */
  transform(isPrivateInstrument: boolean, instrumentType: string): boolean {
    if (instrumentType !== 'ConvertibleBond') {
      return false;
    }

    return isPrivateInstrument;
  }
}
