import { v4 } from 'uuid';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { FrequencyDescription } from '@lv-analytics/models';
import { DividendProtectionData, DividendProtectionType, DividendProtectionTresholdType,
         DividendProtectionTresholdFormulaType, DividendProtectionCustomData, DividendProtectionScheduleItem,
         DividendProtectionTypeDescription, AdjustmentFormulaTypeDescription, SpotLevelAtAdjustmentFormulaTypeDescription,
         CashDistributionDateTypeDescription, MinAdjustmentTypeDescripton,
         DividendProtectionTresholdTypeDescription as DividendProtectionThresholdTypeDescription } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { CurrencyType, IssueAndRedemption } from '@lv-instrument-common/index';

export class LvDividendProtectionView {

  get dividendStartDate(): Date {
    return this._dividendStartDate;
  }

  set dividendStartDate(value: Date) {
    this._dividendStartDate = value;

    if (this.dividendProtection && this.dividendStartDateEnabled) {
      this.dividendProtection.startDate = value;
    }
  }

  get dividendEndDate(): Date {
    return this._dividendEndDate;
  }

  set dividendEndDate(value: Date) {
    this._dividendEndDate = value;

    if (this.dividendProtection && this.dividendEndDateEnabled) {
      this.dividendProtection.endDate = value;
    }
  }

  dividendStartDateEnabled: boolean;
  dividendEndDateEnabled: boolean;

  get isCashDistribution(): boolean {
    return this.dividendProtection.type === DividendProtectionType.CashDistribution;
  }

  get isFullProtection(): boolean {
    return this.dividendProtection.tresholdType === DividendProtectionTresholdType.FullProtection;
  }

  _dividendStartDate: Date;
  _dividendEndDate: Date;
  dividendProtection: DividendProtectionData;
  issueAndRedemption: IssueAndRedemption;
  dividendProtectionCustom: DividendProtectionCustomData;

  protectionTypeLookup: LvLookupEnum;
  thresholdTypeLookup: LvLookupEnum;
  thresholdPeriodFrequencyLookup: LvLookupEnum;
  cashThresholdCurrencyTypeLookup: LvLookupEnum;
  thresholdFormulaTypeLookup: LvLookupEnum;
  adjustmentFormulaTypeLookup: LvLookupEnum;
  spotLevelAtAdjustmentFormulaLookup: LvLookupEnum;
  cashDistributionDateTypeLookup: LvLookupEnum;
  minimumAdjustmentType: LvLookupEnum;
  scheduleItems: DividendProtectionScheduleItem[];

  useDividendProtectionId: string;
  useDividendProtection: boolean;
  includeTriggerId: string;
  currentPeriodTriggerBreachedId: string;
  twoWayConversionPriceAdjustmentId: string;
  addCarryForwardOnConversionId: string;

  formatSix = '###,#.######';
  decimalsSix = '6';
  formatFour = '###,#.####';
  decimalsFour = '4';

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.useDividendProtectionId = v4();
    this.includeTriggerId = v4();
    this.currentPeriodTriggerBreachedId = v4();
    this.twoWayConversionPriceAdjustmentId = v4();
    this.addCarryForwardOnConversionId = v4();
    this.useDividendProtection = false;
    this.dividendStartDateEnabled = false;
    this.dividendEndDateEnabled = false;
    this.scheduleItems = [];

    this.protectionTypeLookup = new LvLookupEnum(DividendProtectionTypeDescription);
    this.thresholdTypeLookup = new LvLookupEnum(DividendProtectionThresholdTypeDescription);
    this.thresholdPeriodFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.cashThresholdCurrencyTypeLookup = new LvLookupEnum(CurrencyType);
    this.thresholdFormulaTypeLookup = new LvLookupEnum(DividendProtectionTresholdFormulaType);
    this.adjustmentFormulaTypeLookup = new LvLookupEnum(AdjustmentFormulaTypeDescription);
    this.spotLevelAtAdjustmentFormulaLookup = new LvLookupEnum(SpotLevelAtAdjustmentFormulaTypeDescription);
    this.cashDistributionDateTypeLookup = new LvLookupEnum(CashDistributionDateTypeDescription);
    this.minimumAdjustmentType = new LvLookupEnum(MinAdjustmentTypeDescripton);

    this.dividendProtectionCustom = new DividendProtectionCustomData();

    this.dividendProtection = {
      schedule: [],
      conversionRatioAdjustment: {},
      cashDistribution: {}
    } as DividendProtectionData;

    this.init(this.dividendProtection, {} as IssueAndRedemption);
  }

  init(dividendProtection?: DividendProtectionData, issueAndRedemption?: IssueAndRedemption) {

    this.dividendProtection = dividendProtection ?? this._lvConvertibleBondTermsService.convertibleBondDocument.dividendProtection;
    this.issueAndRedemption = issueAndRedemption;

    if (dividendProtection) {
      this.scheduleItems = dividendProtection.schedule.map(a => ({ ...a }));
      this.setDefaultDateValues(this.issueAndRedemption);
    }
  }

  setDefaultDateValues(issueAndRedemption: IssueAndRedemption) {
    if (this.dividendProtection && issueAndRedemption) {

      const dividendProtectionSettings = this._lvConvertibleBondTermsService.dividendProtectionSettings;

      if (!this.dividendProtection.startDate) {
        this._dividendStartDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, dividendProtectionSettings?.startDate);
        this.dividendStartDateEnabled = false;
      } else {
        this._dividendStartDate = this.dividendProtection.startDate;
        this.dividendStartDateEnabled = true;
      }

      if (!this.dividendProtection.endDate) {
        this._dividendEndDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -dividendProtectionSettings?.endDate);
        this.dividendEndDateEnabled = !!dividendProtectionSettings?.endDate;
      } else {
        this._dividendEndDate = this.dividendProtection.endDate;
        this.dividendEndDateEnabled = true;
      }
      this.setDividendProtectionCustom();
    }
  }

  dividendStartDateCheckBoxChanged(value: boolean): void {
    this.dividendStartDateEnabled = value;

    if (this.dividendProtection) {
      this.dividendProtection.startDate = value ? this._dividendStartDate : null;
    }
  }

  dividendEndDateCheckBoxChanged(value: boolean): void {
    this.dividendEndDateEnabled = value;

    if (this.dividendProtection) {
      this.dividendProtection.endDate = value ? this._dividendEndDate : null;
    }
  }

  setDividendProtectionCustom() {
    this.dividendProtectionCustom = new DividendProtectionCustomData();
    this.dividendProtectionCustom.includeTrigger = this.dividendProtection.includeTrigger;
    this.dividendProtectionCustom.tresholdFormulaType = this.dividendProtection.tresholdFormulaType;
  }

  dividendProtectionChange() {
    this.setDefaultDateValues(this.issueAndRedemption);
   }
}
