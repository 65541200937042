import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  Output, EventEmitter, ChangeDetectorRef, HostBinding, ElementRef, 
  Input} from '@angular/core';
import { INewIssueSettings, INewIssueSectionSettings, ISaveNewIssueSettingsRequest,
         INewIssueEnvironmentSidePanelSettings } from '@lv-analytics/models';
import { InstrumentType } from '@lv-analytics/models/enum/instrument-type';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import * as _ from 'lodash';

/**
 * New issue settings component.
 */
@Component({
  selector: 'lv-new-issue-settings',
  templateUrl: './lv-new-issue-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvNewIssueSettingsComponent implements OnInit, IDiscardable {
  @Input() instrumentType: string;

  @Output() didCancel: EventEmitter<void>;
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;

  settings: INewIssueSettings;
  originalSettings: INewIssueSettings;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _discardChangesService: DiscardChangesService
  ) {
    this.instrumentType = '';
    this.didCancel = new EventEmitter<void>();
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {} as INewIssueSectionSettings,
      environments: []
    } as INewIssueSettings;
    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('NewIssues', this);
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-new-issue-settings')
  get isLvNewIssueSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-section-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.getNewIssueSettings();
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.getNewIssueAswSettings();
      }
      
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves new issue settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        await this._companyAndUserSettingsService.saveNewIssueSettings(this.getRequest());
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        await this._companyAndUserSettingsService.saveNewIssueAswSettings(this.getRequest());
      }
      
      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1814'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.next();
   }

    /**
     * Retrieves element's id
     * @param element reference to given element
     * @param id element's id
     * @returns falg- true if element with given id is found
     */
    getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
      return element.nativeElement.getAttribute('data-tooltip-id') === id;
    }

   /**
    * Occurs on Reset to defaults and reset new issue settings.
    */
  async onResetToDefault() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.resetNewIssueSettings();
      }
      else if(this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.resetNewIssueAswSettings();
      }
      
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'New Issue Settings'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }


  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Gets save new issue settings request.
   * @returns ISaveNewIssueSettingsRequest object.
   */
  private getRequest(): ISaveNewIssueSettingsRequest {
    const request = {
      systemDefaults: {} as INewIssueEnvironmentSidePanelSettings,
      environments: []
    } as ISaveNewIssueSettingsRequest;

    request.systemDefaults.newIssueSetingsAssumptions = this.settings.systemDefaults.newIssueSetingsAssumptions;
    this.settings.environments.forEach(item => {
      const env = {
        environmentId: item.environmentId,
        environmentName: item.environmentName,
        overrideSystemDefaults: item.overrideSystemDefaults,
        newIssueSetingsAssumptions: item.settings.newIssueSetingsAssumptions

      } as INewIssueEnvironmentSidePanelSettings;

      request.environments.push(env);
    });

    return request;
  }
}
