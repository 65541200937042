<lv-settings-widget class="lv-pricing"
  data-cy="DM-4959"
  titleText="Pricing"
  [isLoading]="isLoading"
  [width]="230"
  sidePanelLvId="lv-pricing-side-panel"
  (didClosePanel)="detectChanges()"
  (didPanelSlide)="detectChanges()">

  <ng-container content>
    <div class="lv-pricing-content" #pricingWidget>

      <lv-environment-settings
        lvId="lvBondPricingEnvironmentSettings"
        [section]="pricingSection"
        [showControls]="true"
        [saveToExcel]="isOpenedFromExcel"
        instrumentType="Bond"
        (didChangeEnvironment)="onChangedEnvironment($event)"
        (didReorderEnvironments)="detectChanges()"
        (doSave)="onSaveSection()"
        (doReload)="onReloadSection()">
      </lv-environment-settings>

      <lv-flex-box class="lv-bond-pricing-inner-content">

        <form #bondValuationInputs="ngForm" class="lv-bond-valuation-inputs">
          <lv-flex-box [direction]="resizeHandlerView.pricingSetsDirection">
            <lv-flex-box class="lv-bond-valuation-inputs-row"
              [style.marginBottom.px]="resizeHandlerView.pricingSectionMarginBottom"
              [direction]="resizeHandlerView.valuationInputDirection">

              <lv-flex-box data-cy="DM-231"
                [direction]="resizeHandlerView.labelAndInputBoxDirection"
                [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                direction="row"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="pricingPriceTooltip"
                filter=".lv-label">
                <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                  <label lv-Id="lv-pricing-price-label" class="lv-label">Price ({{ priceSuffix }})</label>
                  <lv-xl-label [field]="'PRICE'"></lv-xl-label>
                </div>
                <lv-numeric-text-box
                  name="bondValuationInputsPrice"
                  lvId="bondValuationInputsPrice"
                  [decimals]="8"
                  [(ngModel)]="model.price"
                  [disabled]="isDisabled"
                  (didBlur)="updatePricingWithCalculation()"
                  (didKeyDownEnter)="updatePricingWithCalculation(true)">
                </lv-numeric-text-box>
              </lv-flex-box>
            
              <lv-flex-box class="lv-input-field lv-input-field--right lv-input-field--full"
                [direction]="resizeHandlerView.labelAndInputBoxDirection"
                [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                direction="row"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="pricingValuationDateTooltip"
                filter=".lv-label"
                data-cy="DM-229">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label" *ngIf="!isOpenedFromExcel">Valuation Date</label>
                    <label class="lv-label" *ngIf="isOpenedFromExcel">Val Date</label>
                    <lv-xl-label [field]="'VAL_DT'"></lv-xl-label>
                  </div>
                  <kendo-datepicker name="valuationInputsValuationDate" style="width: 104px"
                    tabindex="-1"
                    lvId= "bondValuationInputsValuationDate"
                    [min]="minValuationDate"
                    [disabled]="isDisabled"
                    [(ngModel)]="model.valuationDate"
                    (blur)="updatePricingWithCalculation()"
                    (keydown.enter)="updatePricingWithCalculation(true)"
                    required>
                  </kendo-datepicker>
              </lv-flex-box>
            </lv-flex-box>

            <lv-flex-box *ngIf="widgetState.displayMarketDataOverrides"
              class="lv-bond-valuation-inputs-row"
              [direction]="resizeHandlerView.valuationInputDirection">

              <lv-flex-box
                data-cy="DM-1057"
                [direction]="resizeHandlerView.labelAndInputBoxDirection"
                [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                direction="row"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="pricingCreditTooltip"
                filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label">Credit (bps)</label>
                  </div>
                  <lv-numeric-text-box
                    name="valuationInputsFlatCreditSpread"
                    [placeholder]="creditPlaceholder"
                    [disabled]="isDisabled"
                    [(ngModel)]="model.flatCreditSpread"
                    (ngModelChange)="detectChanges()"
                    [format]="'n0'"
                    [decimals]="'0'"
                    [lvDirty]="creditFieldPlaceholderValue"
                    (didBlur)="updatePricingWithCalculation()"
                    (didKeyDownEnter)="updatePricingWithCalculation(true)">
                  </lv-numeric-text-box>
              </lv-flex-box>

            </lv-flex-box>
          </lv-flex-box>
        </form>
      </lv-flex-box>

    </div>
  </ng-container>

  <ng-container settings>
    <div *ngIf="showSettings"
      class="lv-side-panel-link-item"
      lvId="lv-pricing-display-default-view"
      (click)="onDisplayDefaultView()">
      <span>Display Default View</span>
      <i *ngIf="widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>
  
    <div *ngIf="showSettings && !isOpenedFromExcel"
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-pricing-display-custom-view"
      (click)="onDisplayCustomView()">
      <span>Display Custom View</span>
      <i *ngIf="!widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>
  
    <div *ngIf="showSettings"
      class="lv-side-panel-link-item"
      lvId="lv-pricing-edit-custom-view"
      (click)="onShowEditCustomView()">
      <span>{{editWidgetButtonText}}</span>
    </div>
  
    <div class="lv-side-panel-link-item"
      *ngIf="!widgetState.useDefaultView"
      lvId="lv-pricing-save-as-default-view"
      (click)="onSaveAsDefaultView()">
      <span>{{saveWidgetButtonText}}</span>
    </div>
  </ng-container>
</lv-settings-widget>



<ng-template #pricingPriceTooltip>
  <lv-tooltip dm="dM-231"></lv-tooltip>
</ng-template>
<ng-template #pricingValuationDateTooltip>
  <lv-tooltip dm="dM-229"></lv-tooltip>
</ng-template>
<ng-template #pricingCreditTooltip>
  <lv-tooltip dm="dM-1057"></lv-tooltip>
</ng-template>