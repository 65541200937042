
export enum CouponType {
	ZeroCoupon = 'ZeroCoupon',
	Fixed = 'Fixed',
	Custom = 'Custom',
	Floating = 'Floating',
	FixToFloating = 'FixToFloating',
	FloatingToFix = 'FloatingToFix',
	PIK = 'PIK'
}

export enum CouponTypeDescription {
	ZeroCoupon = 'Zero Coupon',
	Fixed = 'Fixed',
	Custom = 'Custom Schedule',
	Floating = 'Floating',
	FixToFloating = 'Fix to Floating',
	FloatingToFix = 'Floating to Fix',
	PIK = 'PIK'
}