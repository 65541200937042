import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { v4 } from 'uuid';
import { FixedCouponData } from '../../../../models/bond-terms/BondCoupon';

@Component({
  selector: 'lv-bond-fixed-coupon',
  templateUrl: './lv-bond-fixed-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvBondFixedCouponComponent {
  @Input() model: FixedCouponData;
  @Output() didFixedCouponModelChange: EventEmitter<FixedCouponData>;

  get isDisabled(): boolean {
    return this.model.useStepupCouponSchedule;
  }

  useStepupCouponScheduleCheckBoxId: string;

  formatNumber = '#,###.####';
  decimalsNumber = '4';

  constructor() {
    this.useStepupCouponScheduleCheckBoxId = v4();
    this.didFixedCouponModelChange = new EventEmitter<FixedCouponData>();
  }

  onFixedCouponModelChange() {
    this.didFixedCouponModelChange.next(this.model);
  }

  getFixedCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
