 
 <lv-flex-box class="asset-swap-container">
  <kendo-tabstrip class="asset-swap-content"
    [keepTabContent]="true">
    <kendo-tabstrip-tab
      title="Valuation"
      cssClass="">
      <ng-template kendoTabContent>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      selected="true"
      title="Terms"
      cssClass="">
      <ng-template kendoTabContent>
        <lv-asw-terms>
        </lv-asw-terms>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      title="Identifiers"
      cssClass="">
      <ng-template kendoTabContent>
        <lv-asw-identifiers></lv-asw-identifiers>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
  <div class="asset-swap-cog">
    <i class="k-icon k-i-gear">
    </i>
  </div>
</lv-flex-box>

