<lv-settings-widget
  data-cy="DM-1213"
  class="lv-basic-terms"
  titleText="Instrument Info"
  [showSettings]="showSettings"
  [isLoading]="isLoading"
  [width]="225">
  <ng-container content>
    <lv-flex-box direction="row" class="lv-basic-terms-content">
      <lv-flex-box class="lv-basic-terms-top-section">

        <ng-container *ngFor="let field of basicTermsViewColumns[0].data">
          <lv-flex-box
            direction="row"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="basicTermsTooltipTemplate"
            filter=".lv-label">
            <div class="lv-label" [attr.terms-tooltip-id]="field.name">{{ field.label }}</div>
            <div class="lv-output">
              <span [title]="field.value" [lvId]="field.name">
                <ng-container *ngIf="field.type === 'date'">
                  {{ field.value | lvLocalDate }}
                </ng-container>
                <ng-container *ngIf="field.type === 'number'">
                  {{ field.value | number:constants.numberFormat.upToFourDigits }}
                </ng-container>
                <ng-container *ngIf="field.type === 'string'">
                  <ng-container>
                    <ng-container *ngIf="isPledgedShares(field.label)">
                     {{ field.value  | number:constants.numberFormat.upToFourDigits }}
                    </ng-container>
                    <ng-container *ngIf="isExchangable(field.label)">
                     {{displayExchangeableValues(field.value)}}
                    </ng-container>
                    <ng-container *ngIf="isDividentProtCRAdjustment(field.value)">
                     CR Adjustment
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!isPledgedShares(field.label) && !isExchangable(field.label)
                  && !isDividentProtCRAdjustment(field.value)">
                  {{ field.value }}
                  </ng-container>
                </ng-container>
              </span>
            </div>
          </lv-flex-box>
        </ng-container>
      </lv-flex-box>

      <lv-flex-box class="lv-basic-terms-bottom-section">
        <ng-container *ngFor="let field of basicTermsViewColumns[1].data">
            <lv-flex-box
            direction="row"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="basicTermsTooltipTemplate"
            filter=".lv-label">
            <div class="lv-label" [attr.terms-tooltip-id]="field.name">{{ field.label }}</div>
            <div class="lv-output">
              <span [title]="field.value" [lvId]="field.name">
                <ng-container *ngIf="field.type === 'date'">
                  {{ field.value | lvLocalDate }}
                </ng-container>
                <ng-container *ngIf="field.type === 'number'">
                  {{ field.value  | number:constants.numberFormat.upToFourDigits }}
                </ng-container>
                <ng-container *ngIf="field.type === 'string' ">
                  <ng-container>
                    <ng-container *ngIf="isPledgedShares(field.label)">
                      {{ field.value  | number:constants.numberFormat.upToFourDigits }}
                    </ng-container>
                    <ng-container *ngIf="isExchangable(field.label)">
                      {{displayExchangeableValues(field.value)}}
                    </ng-container>
                    <ng-container *ngIf="isDividentProtCRAdjustment(field.value)">
                    CR Adjustment
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!isPledgedShares(field.label) && !isExchangable(field.label)
                  && !isDividentProtCRAdjustment(field.value)">
                     {{ field.value }}
                  </ng-container>
                </ng-container>
              </span>
            </div>
          </lv-flex-box>
        </ng-container>
      </lv-flex-box>
    </lv-flex-box>
  </ng-container>

  <ng-container settings>

    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item"
      lvId="lv-basic-terms-display-default-view"
      (click)="onDisplayDefaultView()">
      <span>Display Default View</span>
      <i *ngIf="widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-basic-terms-display-custom-view"
      (click)="onDisplayCustomView()">
      <span>Display Custom View</span>
      <i *ngIf="!widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div class="lv-side-panel-link-item"
      lvId="lv-basic-terms-edit-custom-view"
      (click)="onEditCustomView()">
      <span>{{editWidgetButtonText}}</span>
    </div>

    <div class="lv-side-panel-link-item"
      lvId="lv-basic-terms-edit-save-default-view"
      *ngIf="!widgetState.useDefaultView"
      (click)="onSaveAsDefaultView()">
      <span>{{saveWidgetButtonText}}</span>
    </div>

  </ng-container>
</lv-settings-widget>

<ng-template #basicTermsTooltipTemplate let-anchor>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'issuerName'">
      <lv-tooltip dm="dM-348"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'underlying'">
      <lv-tooltip dm="dM-347"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'currencyCode'">
      <lv-tooltip dm="dM-379"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'nominal'">
      <lv-tooltip dm="dM-385"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'maturity'">
      <lv-tooltip dm="dM-371"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'couponType'">
      <lv-tooltip dm="dM-24"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'coupon'">
      <lv-tooltip dm="dM-372"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'couponFrequency'">
      <lv-tooltip dm="dM-373"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'call'">
      <lv-tooltip dm="dM-392"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'put'">
      <lv-tooltip dm="dM-387"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'conversionRatio'">
      <lv-tooltip dm="dM-362"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'expectedCR'">
      <lv-tooltip dm="dM-366"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'conversionPrice'">
      <lv-tooltip dm="dM-363"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'expectedCP'">
      <lv-tooltip dm="dM-367"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'mandatoryConversion'">
      <lv-tooltip dm="dM-403"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'variableConversion'">
      <lv-tooltip dm="dM-404"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'dividendProtection'">
      <lv-tooltip dm="dM-414"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'useTakeoverProtection'">
      <lv-tooltip dm="dM-420"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'issueSize'">
      <lv-tooltip dm="dM-386"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'region'">
      <lv-tooltip dm="dM-351"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'riskCountry'">
      <lv-tooltip dm="dM-350"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'initialPremium'">
      <lv-tooltip dm="dM-381"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'fixedFxRate'">
      <lv-tooltip dm="dM-355"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'shortName'">
      <lv-tooltip dm="dM-681"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'firstSettlement'">
      <lv-tooltip dm="dM-370"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'redemptionValue'">
      <lv-tooltip dm="dM-384"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'exchangeableType'">
      <lv-tooltip dm="dM-400"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'issueSeries'">
      <lv-tooltip dm="dM-354"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'sinkable'">
      <lv-tooltip dm="dM-402"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'protectionType'">
      <lv-tooltip dm="dM-415"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'accrualMethod'">
      <lv-tooltip dm="dM-378"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'firstCouponPaymentDate'">
      <lv-tooltip dm="dM-375"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'resettable'">
      <lv-tooltip dm="dM-407"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'contigentConversion'">
      <lv-tooltip dm="dM-411"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'isin'">
      <lv-tooltip dm="dM-337"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'bondCode'">
      <lv-tooltip dm="dM-4589"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'cusip'">
      <lv-tooltip dm="dM-339"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'underlyingCUSIP'">
      <lv-tooltip dm="dM-340"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'underlyingPermId'">
      <lv-tooltip dm="dM-345"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'permId'">
      <lv-tooltip dm="dM-342"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'figi'">
      <lv-tooltip dm="dM-341"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'underlyingFIGI'">
      <lv-tooltip dm="dM-344"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'countryCode'">
      <lv-tooltip dm="dM-349"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'issueValue'">
      <lv-tooltip dm="dM-383"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'percentOfPledgedShares'">
      <lv-tooltip dm="dM-401"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'minConversionPrice'">
      <lv-tooltip dm="dM-365"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'maxConversionRatio'">
      <lv-tooltip dm="dM-364"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'minExpectedConversionPrice'">
      <lv-tooltip dm="dM-369"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'maxExpectedConversionRatio'">
      <lv-tooltip dm="dM-368"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'leversysId'">
      <lv-tooltip dm="dM-336"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'hasCoPay'">
      <lv-tooltip dm="dM-377"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'couponDayCount'">
      <lv-tooltip dm="dM-376"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'underlyingIsin'">
      <lv-tooltip dm="dM-338"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'underlyingExchange'">
      <lv-tooltip dm="dM-346"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getTermsTootlipId(anchor) === 'underlyingBloombergTicker'">
      <lv-tooltip dm="dM-343"></lv-tooltip>
    </ng-container>
</ng-template>
