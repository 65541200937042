<lv-loader *ngIf="isLoading"></lv-loader>

<div class="lv-market-data-controls" data-cy="DM-4961" kendoTooltip>
  <div *ngIf="!isOpenedFromExcel"
    class="lv-icon-square"
    lvId="marketDataSaveAll"
    [class.lv-icon-square--state-changed]="isSessionStateChanged"
    title="Save all sections"
    [class.lv-icon-square--disabled]="isDisabled"
    (click)="onMarketDataSaveAll()">
    <i class="k-icon k-i-save"></i>
  </div>
  <div class="lv-icon-square"
    [class.lv-icon-square--state-changed]="isModelStateChanged"
    lvId="marketDataReloadAll"
    title="Reload all sections"
    [class.lv-icon-square--disabled]="isDisabled"
    (click)="onReloadAll()">
    <i class="k-icon k-i-reload"></i>
  </div>
</div>

<kendo-tabstrip class="lv-market-data-tabstrip"
  [keepTabContent]="true"
  #tabstrip>
  <kendo-tabstrip-tab title="Credit"
    cssClass="DM-5445"
    [selected]="true">
    <ng-template kendoTabContent>
      <lv-bond-credit #lvCredit
        data-cy="DM-5445"
        [marketDataComponent]="lvCredit"
        (didSessionUpdatedEvent)="setSectionStateChanged()"
        (didSessionDataLoadedEvent)="setSectionStateLoaded()"
        (isSectionLoading)="setLoading($event)">
      </lv-bond-credit>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Withholding Tax"
    cssClass="DM-5446">
    <ng-template kendoTabContent>
      <lv-bond-withholding-tax #lvWitholdingTax
        data-cy="DM-5446"
        [marketDataComponent]="lvWitholdingTax"
        (didSessionUpdatedEvent)="setSectionStateChanged()"
        (didSessionDataLoadedEvent)="setSectionStateLoaded()"
        (isSectionLoading)="setLoading($event)">
      </lv-bond-withholding-tax>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Interest Rates"
    cssClass="DM-5447">
    <ng-template kendoTabContent>
      <lv-bond-interest-rates #lvInterestRates
        data-cy="DM-5447"
        [marketDataComponent]="lvInterestRates"
        (didSessionUpdatedEvent)="setSectionStateChanged()"
        (didSessionDataLoadedEvent)="setSectionStateLoaded()"
        (isSectionLoading)="setLoading($event)">
      </lv-bond-interest-rates>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
