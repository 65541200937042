import { LvPricingComponent, LvOutputsWidgetComponent, LvBasicTermsComponent, LvMarketDataWidgetComponent,
         LvModelCustomizationComponent, LvToolsWidgetComponent } from '@lv-analytics/components';
import { IInstrumentWidgetVisibility, IValuationWidgetTypes, IInstrumentWidgetState } from '.';
import { LvBondPricingComponent } from '@lv-analytics/components/lv-bond/lv-bond-pricing/lv-bond-pricing.component';
import { LvBondMarketDataWidgetComponent } from '@lv-analytics/components/market-data/bond/lv-bond-market-data-widget/lv-bond-market-data-widget.component';
import { EquityComponent } from '../equity/equity.component';
import { LvBondModelCustomizationComponent } from '@lv-analytics/components/model-customization/bond/lv-bond-model-customization/lv-bond-model-customization.component';
import { AssetSwapComponent } from '../asset-swap/asset-swap.component';


export const asTab = [
  IInstrumentWidgetVisibility.AS_TAB,
  IInstrumentWidgetVisibility.NONE
];

export const asWidget = [
  IInstrumentWidgetVisibility.AS_WIDGET,
  IInstrumentWidgetVisibility.NONE
];

export const asTabAndWidget = [
  IInstrumentWidgetVisibility.AS_TAB,
  IInstrumentWidgetVisibility.AS_WIDGET,
  IInstrumentWidgetVisibility.NONE
];

export const widgetTypes: IValuationWidgetTypes = {
  'pricing': LvPricingComponent,
  'outputs': LvOutputsWidgetComponent,
  'instrument_info': LvBasicTermsComponent,
  'market_data': LvMarketDataWidgetComponent,
  'model_customization': LvModelCustomizationComponent,
  'tools': LvToolsWidgetComponent,
  'bond_pricing': LvBondPricingComponent,
  'bond_market_data': LvBondMarketDataWidgetComponent,
  'bond_outputs': LvOutputsWidgetComponent,
  'equity': EquityComponent,
  'bond_model_customization': LvBondModelCustomizationComponent,
  'asset_swap': AssetSwapComponent
};

export const defaultWidgetsConvertibleBond: IInstrumentWidgetState[] = [{
    id: 'valuation',
    title: 'Valuation',
    visibility: IInstrumentWidgetVisibility.AS_TAB,
    visibilityOptions: asTab,
    state: ''
  }, {
    id: 'pricing',
    title: 'Pricing',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'pricing',
      maxItemRows: 150,
      maxItemCols: 150,
      minItemCols: 26,
      rows: 11,
      cols: 121,
      x: 0,
      y: 0
    }
  }, {
    id: 'outputs',
    title: 'Outputs',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'outputs',
      maxItemRows: 150,
      maxItemCols: 150,
      minItemCols: 26,
      rows: 39,
      cols: 55,
      x: 0,
      y: 11
    }
  }, {
    id: 'instrument_info',
    title: 'Instrument Info',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'instrument_info',
      maxItemRows: 150,
      maxItemCols: 150,
      rows: 43,
      cols: 34,
      x: 0,
      y: 50
    },
  }, {
    id: 'market_data',
    title: 'Market Data',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asTabAndWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'market_data',
      maxItemRows: 78,
      maxItemCols: 78,
      rows: 39,
      cols: 66,
      x: 55,
      y: 11
    }
  }, {
    id: 'model_customization',
    title: 'Model Customization',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asTabAndWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'model_customization',
      maxItemRows: 115,
      maxItemCols: 115,
      minItemCols: 32,
      minItemRows: 17,
      rows: 43,
      cols: 34,
      x: 34,
      y: 50
    }
  }, {
    id: 'tools',
    title: 'Tools',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asTabAndWidget,
    state: null,
    gridsterItemConfig: {
      widgetId: 'tools',
      maxItemRows: 78,
      maxItemCols: 78,
      minItemCols: 36,
      minItemRows: 22,
      rows: 43,
      cols: 53,
      x: 66,
      y: 50
    }
  }, {
    id: 'terms',
    title: 'Terms',
    visibility: IInstrumentWidgetVisibility.AS_TAB,
    visibilityOptions: asTab,
    state: ''
  }, {
    id: 'sensitivity_analysis',
    title: 'Sensitivity Analysis',
    visibility: IInstrumentWidgetVisibility.AS_TAB,
    visibilityOptions: asTab,
    state: ''
  }, {
    id: 'documents',
    title: 'Documents',
    visibility: IInstrumentWidgetVisibility.AS_TAB,
    visibilityOptions: asTab,
    state: ''
  },{
    id: 'underlying',
    title: 'Underlying',
    visibility: IInstrumentWidgetVisibility.AS_TAB,
    visibilityOptions: asTab,
    state: '',
    gridsterItemConfig: {
      widgetId: 'underlying',
      maxItemRows: 78,
      maxItemCols: 78,
      rows: 39,
      cols: 66,
      x: 55,
      y: 11
    }
  }
];

export const defaultWidgetsBond: IInstrumentWidgetState[] = [{
    id: 'valuation',
    title: 'Valuation',
    visibility: IInstrumentWidgetVisibility.AS_TAB,
    visibilityOptions: asTab,
    state: ''
  }, {
    id: 'bond_pricing',
    title: 'Pricing',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'bond_pricing',
      maxItemRows: 150,
      maxItemCols: 150,
      minItemCols: 26,
      rows: 11,
      cols: 121,
      x: 0,
      y: 0
    }
  }, {
    id: 'outputs',
    title: 'Outputs',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'outputs',
      maxItemRows: 150,
      maxItemCols: 150,
      minItemCols: 26,
      rows: 39,
      cols: 55,
      x: 0,
      y: 11
    }
  }, {
    id: 'instrument_info',
    title: 'Instrument Info',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'instrument_info',
      maxItemRows: 150,
      maxItemCols: 150,
      rows: 43,
      cols: 34,
      x: 0,
      y: 50
    },
  }, {
    id: 'bond_market_data',
    title: 'Market Data',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asTabAndWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'bond_market_data',
      maxItemRows: 78,
      maxItemCols: 78,
      rows: 39,
      cols: 66,
      x: 55,
      y: 11
  }
  }, {
    id: 'bond_model_customization',
    title: 'Model Customization',
    visibility: IInstrumentWidgetVisibility.AS_WIDGET,
    visibilityOptions: asTabAndWidget,
    state: '',
    gridsterItemConfig: {
      widgetId: 'model_customization',
      maxItemRows: 115,
      maxItemCols: 115,
      minItemCols: 32,
      minItemRows: 17,
      rows: 43,
      cols: 34,
      x: 34,
      y: 50
    }
  }, {
    id: 'terms',
    title: 'Terms',
    visibility: IInstrumentWidgetVisibility.AS_TAB,
    visibilityOptions: asTab,
    state: ''
  }, 
];

export const defaultWidgetsEquity: IInstrumentWidgetState[] = [{
  id: 'underlying',
  title: 'Underlying',
  visibility: IInstrumentWidgetVisibility.AS_WIDGET,
  visibilityOptions: asWidget,
  state: '',
  gridsterItemConfig: {
    widgetId: 'underlying',
    maxItemRows: 78,
    maxItemCols: 78,
    rows: 39,
    cols: 66,
    x: 55,
    y: 11
  }
}];

export const defaultWidgetsAssetSwap: IInstrumentWidgetState[] = [ {
  id: 'asset_swap',
  title: 'Asset Swap',
  visibility: IInstrumentWidgetVisibility.AS_TAB,
  visibilityOptions: asTab,
  state: '',
  gridsterItemConfig: {
    widgetId: 'asset_swap',
    maxItemRows: 150,
    maxItemCols: 150,
    rows: 43,
    cols: 34,
    x: 0,
    y: 50
  }
}];