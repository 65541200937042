import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CoPayCouponTypeDescription, CoPayTriggerTypeDescription, IBaseTermsSettings } from '@lv-convertible-bond/models';
import { ICouponSectionSettings } from '@lv-convertible-bond/models/terms-settings/coupon-settings/coupon-section-settings';
import { DayCountDescription, FrequencyDescription, ExCouponPeriodType, CouponTypeDescription,
  AccrualMethodDescription, CouponReferenceTenorDescription, PikCouponTypeDescription } from '@lv-instrument-common/index';
import { LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-coupon-section-settings',
  templateUrl: './lv-coupon-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCouponSectionSettingsComponent implements OnInit {

  @Input() settings: IBaseTermsSettings<ICouponSectionSettings>;
  @Input() overrideDefaults: boolean;

  couponTypeLookup: LvLookupEnum;
  couponDayCountLookup: LvLookupEnum;
  couponFrequencyLookup: LvLookupEnum;
  accrualMethodLookup: LvLookupEnum;
  exCouponPerLookup: LvLookupEnum;
  referenceTenor: LvLookupEnum;
  pikCouponTypeLookup: LvLookupEnum;
  coPayFrequencyLookup: LvLookupEnum;
  coPayDayCountLookup: LvLookupEnum;
  coPayCouponTypeLookup: LvLookupEnum;
  coPayTriggerTypeLookup: LvLookupEnum;

  hasCoPayCheckboxId: string;
  couponDatesEomCheckboxId: string;
  guaranteedCouponsCheckboxId: string;

  decimalsZero = '0';
  formatZero = 'n0';
  formatFour = '#,###.####';
  decimalsFour = '4';

  constructor() {
    this.couponTypeLookup = new LvLookupEnum(CouponTypeDescription);
    this.couponDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.couponFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.accrualMethodLookup = new LvLookupEnum(AccrualMethodDescription);
    this.exCouponPerLookup = new LvLookupEnum(ExCouponPeriodType);
    this.referenceTenor = new LvLookupEnum(CouponReferenceTenorDescription);
    this.pikCouponTypeLookup = new LvLookupEnum(PikCouponTypeDescription);
    this.coPayFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.coPayDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.coPayCouponTypeLookup = new LvLookupEnum(CoPayCouponTypeDescription);
    this.coPayTriggerTypeLookup = new LvLookupEnum(CoPayTriggerTypeDescription);

    this.hasCoPayCheckboxId = v4();
    this.couponDatesEomCheckboxId = v4();
    this.guaranteedCouponsCheckboxId = v4();
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  ngOnInit() {
  }

  getCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
