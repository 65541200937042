import { AccruedOnDefault, AccruedOnDefaultDescription } from './../models/market-data/credit/enums/accrued-on-default';
import { BorrowSource } from './../models/market-data/borrow/borrow-enum';
import { CreditModelFactor, CreditModelFactorDisplay } from './../models/market-data/credit/credit-parameters';
import { CreditSource } from './../models/market-data/credit/enums/credit-source';
import { DateAdjustment, DateAdjustmentDescription } from './../models/market-data/credit/enums/date-adjustment';
import { DayCount, DayCountDescription } from './../models/market-data/credit/enums/day-count';
import { DividendFrequencyEnum } from './../models/market-data/dividends/enum/dividend-frequency-enum';
import { DividendsSourceEnum } from './../models/market-data/dividends/enum/dividends-source-enum';
import { DividendTypeEnum } from './../models/market-data/dividends/enum/dividend-type-enum';
import { EquityToCreditCalculationType, EquityToCreditCalculationTypeDescription } from './../models/market-data/credit/enums/equity-to-credit-calculation-type';
import { IMarketData } from './../models/market-data/market-data';
import { InterestRateSource, InterestRateSourceDescription } from './../models/market-data/interest-rates/interest-rates-enum';
import { ITermStructureItem } from './../models/market-data/yield-curve/yield-curve-term-structure';
import { PeriodFrequency, PeriodFrequencyDescription } from './../models/market-data/credit/enums/period-frequency';
import { VolatilitySurfaceRiskyType, VolatilitySurfaceRiskyTypeDescription, VolatilityType } from './../models/market-data/volatility/volatility-enums';

import { DateExtensions } from '@lv-core-ui/util';
import { FieldMapper } from '@lv-excel/helpers/field-mapper';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { mapCredit } from './mappers/credit-mapper';
import { mapDividends } from './mappers/dividends-mapper';
import { mapInterestRates } from './mappers/interest-rates-mapper';
import { mapVolatility } from './mappers/volatility-mapper';
import { IField } from '@lv-excel/models/field';
import { ISystemYieldCurveInfo } from '@lv-analytics/models/market-data/yield-curve/system-yield-curve-info';

/**
 * Market data excel mapper.
 */
export class MarketDataExcelMapper extends FieldMapper<IMarketData> {

  private _instrumentTermsStructure: ITermStructureItem[];
  private _underlyingTermsStructure: ITermStructureItem[];
  private _isExchangeable: boolean;
  private _systemYieldCurves: ISystemYieldCurveInfo[];

  constructor() {
    super();
    this._isExchangeable = false;
  }

  /**
   * Sets system yield curves.
   * @param systemYieldCurves List of ISystemYieldCurveInfo objects.
   */
  setSystemYieldCurves(systemYieldCurves: ISystemYieldCurveInfo[]) {
    this._systemYieldCurves = systemYieldCurves;
  }

  /**
   * Creates map.
   */
  createMap() {
    this._fieldDictionary['CREDIT_FLAT'] = {
      mapFn: (value: any) => {
        this._model.credit.issuerCreditParameters.flatCreditSpread = value;
      },
      reverseMapFn: () => {
        return this._model.credit.issuerCreditParameters.flatCreditSpread;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['BRW_FLAT'] = {
      mapFn: (value: any) => {
        this._model.borrow.borrow = value;
      },
      reverseMapFn: () => {
        return this._model.borrow.borrow;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['VOL_FLAT'] = {
      mapFn: (value: any) => {
        this._model.volatility.flatVol = value;
      },
      reverseMapFn: () => {
        return this._model.volatility.flatVol;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['VOL_UP'] = {
      mapFn: (value: any) => {
        this._model.volatility.upsideVol = value;
      },
      reverseMapFn: () => {
        return this._model.volatility.upsideVol;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['VOL_DOWN'] = {
      mapFn: (value: any) => {
        this._model.volatility.downsideVol = value;
      },
      reverseMapFn: () => {
        return this._model.volatility.downsideVol;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['DVD_SOURCE'] = {
      mapFn: (value: any) => {
        this._model.dividends.dividendsSource = value;
      },
      reverseMapFn: () => {
        return DividendsSourceEnum[this._model.dividends.dividendsSource];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DividendsSourceEnum
    };
    // Founding
    this._fieldDictionary['FNDG_SPREAD'] = {
      mapFn: (value: any) => {
        this._model.funding.fundingSpread = value;
      },
      reverseMapFn: () => {
        return this._model.funding.fundingSpread;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['FNDG_MARGIN'] = {
      mapFn: (value: any) => {
        this._model.funding.fundingMargin = value;
      },
      reverseMapFn: () => {
        return this._model.funding.fundingMargin;
      },
      type: ExcelFieldDataTypes.Number
    };
    // Withholding Tax
    this._fieldDictionary['NF_COUPON'] = {
      mapFn: (value: any) => {
        this._model.witholdingTax.netFactorInstrumentCCY = value;
      },
      reverseMapFn: () => {
        return this._model.witholdingTax.netFactorInstrumentCCY;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['NF_DVD'] = {
      mapFn: (value: any) => {
        this._model.witholdingTax.netFactorUnderlyingCCY = value;
      },
      reverseMapFn: () => {
        return this._model.witholdingTax.netFactorUnderlyingCCY;
      },
      type: ExcelFieldDataTypes.Number
    };
    // Borrow
    this._fieldDictionary['BRW_FLAT'] = {
      mapFn: (value: any) => {
        this._model.borrow.borrow = value;
      },
      reverseMapFn: () => {
        return this._model.borrow.borrow;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['BRW_REBATE'] = {
      mapFn: (value: any) => {
        this._model.borrow.rebateRate = value;
      },
      reverseMapFn: () => {
        return this._model.borrow.rebateRate;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['BRW_FUND_RATE'] = {
      mapFn: (value: any) => {
        this._model.borrow.fundingRate = value;
      },
      reverseMapFn: () => {
        return this._model.borrow.fundingRate;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['BRW_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        this._model.borrow.termStructureItems = value.map(x => {
          return {
            endDate: DateExtensions.getDateInUtcFromUtcString(x.endDate),
            borrowFee: x.borrowFee
          };
        });
      },
      reverseMapFn: () => {
        return this._model.borrow.termStructureItems.map(x => {
          return {
            date: DateExtensions.toOADate(x.endDate as Date),
            value: x.borrowFee
          };
        });
      },
      type: ExcelFieldDataTypes.Schedule
    };
    // Averaging Data
    // call
    this._fieldDictionary['CALL_DAYS_ABOVE'] = {
      mapFn: (value: any) => {
        this._model.averagingData.callDaysAboveTrigger = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.callDaysAboveTrigger;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['CALL_RUNNING_DAYS'] = {
      mapFn: (value: any) => {
        this._model.averagingData.callRunningPeriodDays = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.callRunningPeriodDays;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['CURR_STK_AVG_CALL'] = {
      mapFn: (value: any) => {
        this._model.averagingData.callCurrentStockAveraging = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.callCurrentStockAveraging;
      },
      type: ExcelFieldDataTypes.Number
    };
    // conversion
    this._fieldDictionary['CURR_STK_AVG'] = {
      mapFn: (value: any) => {
        this._model.averagingData.conversionCurrentStockAveraging = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.conversionCurrentStockAveraging;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['SPEC_CASH_AMT'] = {
      mapFn: (value: any) => {
        this._model.averagingData.conversionSpecifiedCashAmount = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.conversionSpecifiedCashAmount;
      },
      type: ExcelFieldDataTypes.Number
    };
    // co-co
    this._fieldDictionary['COCO_DAYS_ABOVE'] = {
      mapFn: (value: any) => {
        this._model.averagingData.cocoDaysAboveTrigger = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.cocoDaysAboveTrigger;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['COCO_RUNNING_DAYS'] = {
      mapFn: (value: any) => {
        this._model.averagingData.cocoRunningPeriodDays = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.cocoRunningPeriodDays;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['CURR_STK_AVG_COCO'] = {
      mapFn: (value: any) => {
        this._model.averagingData.cocoCurrentStockAveraging = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.cocoCurrentStockAveraging;
      },
      type: ExcelFieldDataTypes.Number
    };
    this._fieldDictionary['COCO_LAST_TRIGGER'] = {
      mapFn: (value: any) => {
        this._model.averagingData.lastCocoTriggerSatisfied = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.lastCocoTriggerSatisfied;
      },
      type: ExcelFieldDataTypes.String
    };

    // reset
    this._fieldDictionary['CURR_STK_AVG_RESET'] = {
      mapFn: (value: any) => {
        this._model.averagingData.resetCurrentStockAveraging = value;
      },
      reverseMapFn: () => {
        return this._model.averagingData.resetCurrentStockAveraging;
      },
      type: ExcelFieldDataTypes.Number
    };
    
    this._fieldDictionary['ISSUE_STK_SLIPPAGE'] = {
      mapFn: (value: any) => {
        this._model.other.stockSlippage = value;
      },
      reverseMapFn: () => {
        return this._model.other.stockSlippage;
      },
      type: ExcelFieldDataTypes.Number
    };

    mapCredit(this._fieldDictionary, this._model);
    mapVolatility(this._fieldDictionary, this._model);
    mapDividends(this._fieldDictionary, this._model);
    // tslint:disable-next-line: max-line-length
    mapInterestRates(this._fieldDictionary, this._model, this._instrumentTermsStructure, this._underlyingTermsStructure, this._systemYieldCurves);
  }

  /**
   * Maps enum type.
   * @param value Value.
   * @param enumType Enum type.
   * @returns Mapped value.
   */
  mappEnumType(value: any, enumType: any) {
    return value;
  }

  /**
   * Maps enum type V3.
   */
  mappEnumTypeV3(value: any, enumType: any) {
    return value;
  }

  /**
   * Custom mapping.
   */
  customMapp(inputData: any[], isV3: boolean, marketDataId: number = 0) {
    const dividendType = inputData.find(x => x.key === 'DVD_TYPE');
    const dividendValue = inputData.find(x => x.key === 'DVD_VALUE');
    const dividendFreq = inputData.find(x => x.key === 'DVD_FREQ');
    const dividendGrowth = inputData.find(x => x.key === 'DVD_GROWTH');
    const dividendFirstDate = inputData.find(x => x.key === 'DVD_FIRST_DATE');
    const dividendYldStart = inputData.find(x => x.key === 'DVD_YLD_START');
    const dividendSchedRange = inputData.find(x => x.key === 'DVD_SCHED_RANGE_1');
    const dividendSchedRange2 = inputData.find(x => x.key === 'DVD_SCHED_RANGE_2');
    const dividendParamRange = inputData.find(x => x.key === 'DVD_PARAM_RANGE');
    const creditEquityLinkedRange = inputData.find(x => x.key === 'CREDIT_EQLNKD_RANGE');
    const creditStochasticRange = inputData.find(x => x.key === 'CREDIT_STOCH_RANGE');

    if (dividendSchedRange && this.isNotNullOrUndefined(dividendSchedRange.value)
      || dividendSchedRange2 && this.isNotNullOrUndefined(dividendSchedRange2.value)) {
      this._model.dividends.dividendsSource = DividendsSourceEnum.Schedule;
    }
    else if ((dividendParamRange && this.isNotNullOrUndefined(dividendParamRange.value))
      || (dividendType && this.isNotNullOrUndefined(dividendType.value))
      || (dividendValue && this.isNotNullOrUndefined(dividendValue.value))
      || (dividendFreq && this.isNotNullOrUndefined(dividendFreq.value))
      || (dividendGrowth && this.isNotNullOrUndefined(dividendGrowth.value))
      || (dividendFirstDate && this.isNotNullOrUndefined(dividendFirstDate.value))
      || (dividendYldStart && this.isNotNullOrUndefined(dividendYldStart.value))) {
      this._model.dividends.dividendsSource = DividendsSourceEnum.Parameters;
    }

    const borrowFlat = inputData.find(x => x.key === 'BRW_FLAT')?.value;
    const borrowRebate = inputData.find(x => x.key === 'BRW_REBATE')?.value;
    const borrowScedRange = inputData.find(x => x.key === 'BRW_SCHED_RANGE');

    if (borrowFlat && this.isNotNullOrUndefined(borrowFlat)) {
      this._model.borrow.borrowSource = BorrowSource.Flat;
    }
    else if (borrowRebate && this.isNotNullOrUndefined(borrowRebate)) {
      this._model.borrow.borrowSource = BorrowSource.Rebate;
    }
    else if (borrowScedRange && this.isNotNullOrUndefined(borrowScedRange.value)) {
      this._model.borrow.borrowSource = BorrowSource.TermStructure;
    }

    const creditFlat = inputData.find(x => x.key === 'CREDIT_FLAT');
    const creditRecBond = inputData.find(x => x.key === 'CREDIT_REC_BOND');
    const creditCds = inputData.find(x => x.key === 'CREDIT_CDS');
    const creditCdsUpfront = inputData.find(x => x.key === 'CREDIT_CDS_UPFRONT');
    const creditRecCds = inputData.find(x => x.key === 'CREDIT_REC_CDS');
    const creditBadDay = inputData.find(x => x.key === 'CREDIT_BAD_DAY');
    const creditAccDflt = inputData.find(x => x.key === 'CREDIT_ACC_DFLT');
    const creditDfltFlat = inputData.find(x => x.key === 'CREDIT_DFLT_FLAT');
    const creditSchedRange = inputData.find(x => x.key === 'CREDIT_SCHED_RANGE');
    const creditCdsSchedRange = inputData.find(x => x.key === 'CREDIT_CDS_SCHED_RANGE');
    const creditDfltSchedRange = inputData.find(x => x.key === 'CREDIT_DFLT_SCHED_RANGE');
    const creditErvprobSchedRange = inputData.find(x => x.key === 'CREDIT_SRVPROB_SCHED_RANGE');
    const creditFrequency = inputData.find(x => x.key === 'CREDIT_FREQ');

    if (creditFlat && this.isNotNullOrUndefined(creditFlat.value)) {
      this._model.credit.creditSource = CreditSource.FlatSpread;
    }
    else if (creditSchedRange && this.isNotNullOrUndefined(creditSchedRange.value)) {
      this._model.credit.creditSource = CreditSource.TermStructure;
    }
    else if (creditCdsSchedRange && this.isNotNullOrUndefined(creditCdsSchedRange.value)) {
      this._model.credit.creditSource = CreditSource.CDSTermStructure;
    }
    else if (creditDfltSchedRange && this.isNotNullOrUndefined(creditDfltSchedRange.value)) {
      this._model.credit.creditSource = CreditSource.DefaultRateTermStructure;
    }
    else if (creditErvprobSchedRange && this.isNotNullOrUndefined(creditErvprobSchedRange.value)) {
      this._model.credit.creditSource = CreditSource.SurvivalProbabilityTermStructure;
    }
    else if (creditCdsUpfront && this.isNotNullOrUndefined(creditCdsUpfront.value)
      || creditRecCds && this.isNotNullOrUndefined(creditRecCds.value)
      || creditBadDay && this.isNotNullOrUndefined(creditBadDay.value)
      || creditAccDflt && this.isNotNullOrUndefined(creditAccDflt.value)
      || creditCds && this.isNotNullOrUndefined(creditCds.value)) {
      this._model.credit.creditSource = CreditSource.CDSFlat;

      if ((!creditFrequency || !this.isNotNullOrUndefined(creditFrequency.value)) && marketDataId === 0) {
        this._model.credit.issuerCreditParameters.frequency = PeriodFrequency.Quarterly;
      }
    }
    else if (creditDfltFlat && this.isNotNullOrUndefined(creditDfltFlat.value)) {
      this._model.credit.creditSource = CreditSource.DefaultRateFlat;
    }

    const issuerCreditFlat = inputData.find(x => x.key === 'CREDIT_FLAT_UND');
    const issuerCreditCds = inputData.find(x => x.key === 'CREDIT_CDS_UND');
    const issuerCreditCdsUpfront = inputData.find(x => x.key === 'CREDIT_CDS_UPFRONT_UND');
    const issuerCreditRecCds = inputData.find(x => x.key === 'CREDIT_REC_CDS_UND');
    const issuerCreditBadDay = inputData.find(x => x.key === 'CREDIT_BAD_DAY_UND');
    const issuerCreditAccDflt = inputData.find(x => x.key === 'CREDIT_ACC_DFLT_UND');
    const issuerCreditDfltFlat = inputData.find(x => x.key === 'CREDIT_DFLT_FLAT_UND');
    const issuerCreditSchedRange = inputData.find(x => x.key === 'CREDIT_SCHED_RANGE_UND');
    const issuerCreditCdsSchedRange = inputData.find(x => x.key === 'CREDIT_CDS_SCHED_RANGE_UND');
    const issuerCreditDfltSchedRange = inputData.find(x => x.key === 'CREDIT_DFLT_SCHED_RANGE_UND');
    const issuerCreditErvprobSchedRange = inputData.find(x => x.key === 'CREDIT_SRVPROB_SCHED_RANGE_UND');
    const creditFrequencyUnd = inputData.find(x => x.key === 'CREDIT_FREQ_UND');

    if (issuerCreditFlat && this.isNotNullOrUndefined(issuerCreditFlat.value)) {
      this._model.credit.underlyingCreditSource = CreditSource.FlatSpread;
    }
    else if (issuerCreditSchedRange && this.isNotNullOrUndefined(issuerCreditSchedRange.value)) {
      this._model.credit.underlyingCreditSource = CreditSource.TermStructure;
    }
    else if (issuerCreditCdsSchedRange && this.isNotNullOrUndefined(issuerCreditCdsSchedRange.value)) {
      this._model.credit.underlyingCreditSource = CreditSource.CDSTermStructure;
    }
    else if (issuerCreditDfltSchedRange && this.isNotNullOrUndefined(issuerCreditDfltSchedRange.value)) {
      this._model.credit.underlyingCreditSource = CreditSource.DefaultRateTermStructure;
    }
    else if (issuerCreditErvprobSchedRange && this.isNotNullOrUndefined(issuerCreditErvprobSchedRange.value)) {
      this._model.credit.underlyingCreditSource = CreditSource.SurvivalProbabilityTermStructure;
    }
    else if (issuerCreditCdsUpfront && this.isNotNullOrUndefined(issuerCreditCdsUpfront.value)
      || issuerCreditRecCds && this.isNotNullOrUndefined(issuerCreditRecCds.value)
      || issuerCreditBadDay && this.isNotNullOrUndefined(issuerCreditBadDay.value)
      || issuerCreditAccDflt && this.isNotNullOrUndefined(issuerCreditAccDflt.value)
      || issuerCreditCds && this.isNotNullOrUndefined(issuerCreditCds.value)) {
      this._model.credit.underlyingCreditSource = CreditSource.CDSFlat;

      if ((!creditFrequencyUnd || !this.isNotNullOrUndefined(creditFrequencyUnd.value)) && marketDataId === 0) {
        this._model.credit.underlyingCreditParameters.frequency = PeriodFrequency.Quarterly;
      }

    }
    else if (issuerCreditDfltFlat && this.isNotNullOrUndefined(issuerCreditDfltFlat.value)) {
      this._model.credit.underlyingCreditSource = CreditSource.DefaultRateFlat;
    }

    const creditUndDefJump = inputData.find(x => x.key === 'CREDIT_UND_DEF_JUMP');

    if (creditUndDefJump && this.isNotNullOrUndefined(creditUndDefJump.value) && !this._isExchangeable) {
      this._model.credit.issuerCreditParameters.jumpOnUnderlyingDefault = creditUndDefJump.value;
    }

    const creditParm = inputData.find(x => x.key === 'CREDIT_PARM');
    const creditRefSpot = inputData.find(x => x.key === 'CREDIT_REF_SPOT');
    const creditBondFloor = inputData.find(x => x.key === 'CREDIT_BOND_FLOOR');

    if ((creditEquityLinkedRange && this.isNotNullOrUndefined(creditEquityLinkedRange.value))
      || (creditParm && this.isNotNullOrUndefined(creditParm.value))
      || (creditRefSpot && this.isNotNullOrUndefined(creditRefSpot.value))
      || (creditBondFloor && this.isNotNullOrUndefined(creditBondFloor.value))) {
      this._model.credit.issuerCreditParameters.creditModelFactor = CreditModelFactor.Factor15;
      this._model.credit.equityLinkedCredit.equityToCreditModel = true;
    }

    const creditVolatility = inputData.find(x => x.key === 'CREDIT_VOL');
    const creditStockCorrelation = inputData.find(x => x.key === 'CREDIT_STCK_CORR');
    const creditRevertingIntensity = inputData.find(x => x.key === 'CREDIT_RVR_INTENSITY');
    const creditMeanValue = inputData.find(x => x.key === 'CREDIT_MEAN');

    if (
      (creditStochasticRange && this.isNotNullOrUndefined(creditStochasticRange.value))
      || (creditVolatility && this.isNotNullOrUndefined(creditVolatility.value))
      || (creditStockCorrelation && this.isNotNullOrUndefined(creditStockCorrelation.value))
      || (creditRevertingIntensity && this.isNotNullOrUndefined(creditRevertingIntensity.value))
      || (creditMeanValue && this.isNotNullOrUndefined(creditMeanValue.value))) {
      this._model.credit.issuerCreditParameters.creditModelFactor = CreditModelFactor.Factor2;
      this._model.credit.stochasticCreditModel.useStochasticCredit = true;
    }

    const volFlat = inputData.find(x => x.key === 'VOL_FLAT');
    const volSchedRange = inputData.find(x => x.key === 'VOL_SCHED_RANGE');
    const volSurfRange = inputData.find(x => x.key === 'VOL_SURF_RANGE');
    const volUpside = inputData.find(x => x.key === 'VOL_UP');
    const volDownside = inputData.find(x => x.key === 'VOL_DOWN');
    const volLlc = inputData.find(x => x.key === 'VOL_LCL');

    if (volFlat && this.isNotNullOrUndefined(volFlat.value)) {
      this._model.volatility.volType = VolatilityType.Flat;
    }
    else if (volSchedRange && this.isNotNullOrUndefined(volSchedRange.value)) {
      this._model.volatility.volType = VolatilityType.TermStructure;
    }
    else if (volSurfRange && this.isNotNullOrUndefined(volSurfRange.value)) {
      this._model.volatility.volType = VolatilityType.VolatilitySurface;
    }
    else if ((volUpside && this.isNotNullOrUndefined(volUpside.value))
      || volDownside && this.isNotNullOrUndefined(volDownside.value)
      || volLlc && this.isNotNullOrUndefined(volLlc.value)) {
      this._model.volatility.volType = VolatilityType.UpsideDownside;
    }
  }

  /**
   * Custom reverse mapping.
   * @param fields List of IField objects.
   * @returns List of IField objects.
   */
  customReverseMap(fields: IField[]): IField[] {
    return fields;
  }

  /**
   * Checks if value is not null or undefine.
   * @param value Value.
   * @returns True if value is not null and undefinef and false vice versa.
   */
  isNotNullOrUndefined(value: any): boolean {
    return value !== null && value !== undefined;
  }

  /**
   * Sets interest rates.
   * @param instrumentTermsStructure List of ITermStructureItem objects.
   * @param underlyingTermsStructure List of ITermStructureItem objects.
   */
  setInterestRates(instrumentTermsStructure: ITermStructureItem[], underlyingTermsStructure: ITermStructureItem[]) {
    this._model.interestRates.instrumentTermsStructure = instrumentTermsStructure;
    this._model.interestRates.underlyingTermsStructure = underlyingTermsStructure;
    // tslint:disable-next-line: max-line-length
    mapInterestRates(this._fieldDictionary, this._model, this._instrumentTermsStructure, this._underlyingTermsStructure, this._systemYieldCurves);
  }

  /**
   * Set is instrument exchangeable
   * @param value Flag that descirbes if instrument is exchangeable
   */
  setIsExchangeable(value: boolean) {
    this._isExchangeable = value;
  }
}
