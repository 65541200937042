import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { AnalyticsSettingsHelper, ConvertibleHelper } from '@lv-analytics/helpers';
import {
  IModelCustomization, HardCallModelTypeDescription, SoftCallModelTypeDescription, ConversionSettlement, ExchangeableType,
  CreditModelFactor, CreditModelTypeDescription, EquityModelTypeDescription, StochasticCreditModelTypeDescription, StochasticCreditBCType,
  DividendProtectionModelDescription, PepsParityConventionDescription, TriggerConditionMonitoring, IAnalyticsSettings,
  CreditModelType, IOverrideModelCustomizationRequest, ISaveModelCustomizationRequest,
  HardCallModelType, SoftCallModelType, PricerVersion
} from '@lv-analytics/models';
import { DividendProtectionType, CashDistributionDateType } from '@lv-convertible-bond/models';
import { IModelCustomizationState } from '..';
import { Direction } from '@lv-analytics/components';
import { LvExcelService } from '@lv-excel/services';
import { ModelCustomizationExcelMapper } from '@lv-analytics/helpers/model-customization-excel-mapper';
import { LvDateService } from '@lv-core-ui/services';
import { IBondModelCustomizationState } from '@lv-analytics/models/model-customization/bond/bond-model-customization-state';

/**
 * Model customization view config.
 */
export class ModelCustomizationViewConfig {

  private sectionLeftMargin: number;
  private sectionRightMargin: number;
  private scrollBarWidth: number;

  constructor(private state: IModelCustomizationState | IBondModelCustomizationState) {
    this.sectionLeftMargin = 8;
    this.sectionRightMargin = 9;
    this.scrollBarWidth = 16;
  }

  get excludeTermsWidth(): number {
    return 185;
  }

  get columnWidth(): number {
    return 315;
  }

  get excludeTermsModelTypeSectionMinWidth(): number {
    if (this.state.excludeTerms.isExcludeTerms && this.state.modelType.isModelType) {
      return this.excludeTermsWidth + this.columnWidth;
    } else if (this.state.excludeTerms && !this.state.modelType.isModelType) {
      return this.excludeTermsWidth;
    } else if (!this.state.excludeTerms && this.state.modelType.isModelType) {
      return this.columnWidth;
    } else {
      return 0;
    }
  }

  get callAveragingOtherMinSectionMinWidth(): number {
    if (this.state.callAdjustments.isCallAdjustments && this.state.otherParameters.isOtherParameters) {
      return this.columnWidth * 2;
    } else if (this.state.callAdjustments.isCallAdjustments && !this.state.otherParameters.isOtherParameters) {
      return this.columnWidth;
    } else if (!this.state.callAdjustments.isCallAdjustments && this.state.otherParameters.isOtherParameters) {
      return this.columnWidth;
    } else {
      return 0;
    }
  }

  get defaultModeWidth(): number {
    let firstColumnWidth = 0;
    if (this.state.excludeTerms.isExcludeTerms) {
      firstColumnWidth = this.excludeTermsWidth;
    }

    if (this.state.modelType.isModelType) {
      firstColumnWidth = this.columnWidth;
    }

    let secondColumnWidth = 0;
    if (this.state.callAdjustments.isCallAdjustments || this.state.otherParameters.isOtherParameters) {
      secondColumnWidth = this.columnWidth;
    }

    return firstColumnWidth + secondColumnWidth + 2 * this.sectionLeftMargin + 2 * this.sectionRightMargin + this.scrollBarWidth;
  }

  get wideModeWidth(): number {

    const columnsVisibility = [
      this.state.excludeTerms.isExcludeTerms,
      this.state.modelType.isModelType,
      this.state.callAdjustments.isCallAdjustments,
      this.state.otherParameters.isOtherParameters
    ];

    const numberOfVisibleColumns = columnsVisibility.filter(a => a).length;

    return this.excludeTermsModelTypeSectionMinWidth +
      this.callAveragingOtherMinSectionMinWidth +
      numberOfVisibleColumns * this.sectionLeftMargin +
      numberOfVisibleColumns * this.sectionRightMargin +
      this.scrollBarWidth - 20;
  }

  /**
   * Checks wide mode.
   * @param currentWidth Current width.
   * @returns A flag indicatin if wide mode is on.
   */
  isWideMode(currentWidth: number): boolean {
    return currentWidth >= this.wideModeWidth;
  }
}

export const getDefaultModel = (settings?: IModelCustomization): IModelCustomization => {
  let model = {
    creditModelSettings: {},
    callAdjustmentModelSettings: {}
  } as IModelCustomization;

  if (settings) {
    model = settings;
  }

  return model;
};

/**
 * Model customization view.
 */
export class ModelCustomizationView {
  // checkbox ids
  chExpectedLifeId: string;
  chPriceExchId: string;
  chDefaultOnLowParityId: string;
  chIncCashRebateId: string;
  chCallId: string;
  notCallableId: string;
  maturityDateOverrideId: string;
  chPutId: string;
  chDividendProtectionId: string;
  chContigentConversionId: string;
  chConversionPriceResetId: string;
  chVariableConversionId: string;
  chCurrentConversionAveragingId: string;
  chCurrentResetAveragingId: string;
  chUseNoticeCallId: string;
  chForceOptionalResetId: string;
  chApplyConversionAveraging: string;
  chFwdDividProtectionAdjst: string;
  chForwardConversionAveragingId: string;
  chForwardResetAveragingId: string;
  conversionNoticeGivenId: string;
  assumeDvdUntilMaturityId: string;

  creditModelTypeLookup: LvLookupEnum;
  equityModelTypeLookup: LvLookupEnum;
  underlyingModelTypeLookup: LvLookupEnum;
  stochasticCredit: LvLookupEnum;
  stochasticCreditBC: LvLookupEnum;
  dividendProtectionMode: LvLookupEnum;
  pepsParityConvention: LvLookupEnum;
  hardCallModelType: LvLookupEnum;
  softCallModelType: LvLookupEnum;
  triggerConditionMonitoring: LvLookupEnum;
  pricerVersion: LvLookupEnum;

  modelSettings: IModelCustomization;

  asHelper: AnalyticsSettingsHelper;
  cHelper: ConvertibleHelper;

  excludeTermsModelTypeDirection: Direction;
  callAveragingOtherDirection: Direction;
  excludeTermsModelTypeMinWidth: number;
  callAveragingOtherMinWidth: number;
  sectionRightPadding: number;

  baseColumnWidth: number;
  excludeTermsSectionWidth: number;
  defaultLeftSectionMargin: number;
  defaultColumnPadding: number;
  isNotCallableEnabled: boolean;

  modelCustomizationMapper: ModelCustomizationExcelMapper;


  get isModelCustomizationDisabled(): boolean {
    return !this.asHelper.lwsIdentifier &&
      !this.asHelper.settings?.valuationSession?.leversysLocalId &&
      this.asHelper?.convertible?.leversysId !== 'draft';
  }

  get isBarierModel(): boolean {
    if (!this.modelSettings.callAdjustmentModelSettings) {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    return this.modelSettings.callAdjustmentModelSettings.hardCallModelType === HardCallModelType.BarrierModel;
  }

  get isSoftCallTrigger(): boolean {
    if (!this.modelSettings.callAdjustmentModelSettings) {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    return this.modelSettings.callAdjustmentModelSettings.softCallModelType === SoftCallModelType.SoftCallTrigger;
  }

  get isExchangeable(): boolean {
    if (this.cHelper.isExchangeable === null) {
      return false;
    }
    return this.cHelper.isExchangeable;
  }

  get isPeps(): boolean {
    if (this.cHelper.isPeps === null) {
      return false;
    }
    return this.cHelper.isPeps;
  }

  get isRebate(): boolean {
    if (this.cHelper.isRebate === null) {
      return false;
    }
    return this.cHelper.isRebate;
  }

  get isVariableConversion(): boolean {
    if (this.cHelper.variableConversion === null) {
      return false;
    }
    return this.cHelper.variableConversion;
  }

  get isResettable(): boolean {
    if (this.cHelper.resettable === null) {
      return false;
    }
    return this.cHelper.resettable;
  }

  get isContingentConversion(): boolean {
    if (this.cHelper.contingentConversion === null) {
      return false;
    }
    return this.cHelper.contingentConversion;
  }

  get isDividendProtection(): boolean {
    if (this.cHelper.dividendProtection === null) {
      return false;
    }
    return this.cHelper.dividendProtection;
  }

  get isPuttable(): boolean {
    if (this.cHelper.puttable === null) {
      return false;
    }
    return this.cHelper.puttable;
  }

  get isCash(): boolean {
    if (this.cHelper.conversionSettlement === null) {
      return false;
    }
    return this.cHelper.conversionSettlement === ConversionSettlement.Cash ||
      this.cHelper.conversionSettlement === ConversionSettlement.CashOrShares ||
      this.cHelper.conversionSettlement === ConversionSettlement.SharesBasedOnAveraging;
  }

  get isOptionalReset(): boolean {
    if (this.cHelper.optionalReset === null) {
      return false;
    }
    return this.cHelper.optionalReset;
  }

  get isCallable(): boolean {
    if (this.cHelper.isCallable === null) {
      return false;
    }
    return this.cHelper.isCallable;
  }

  get isPledgedShares(): boolean {
    if (this.cHelper.exchangeableType === null) {
      return false;
    }
    return this.cHelper.exchangeableType.split(' ').join('') === ExchangeableType.PledgedShares;
  }

  get shouldShowExcludeTerms(): boolean {
    return (this.modelCustomizationState.excludeTerms.isCall && this.isCallable) ||
      (this.modelCustomizationState.excludeTerms.isPut && this.isPuttable) ||
      (this.modelCustomizationState.excludeTerms.isDividendProtection && this.isDividendProtection) ||
      (this.modelCustomizationState.excludeTerms.isContingentConversion && this.isContingentConversion) ||
      (this.modelCustomizationState.excludeTerms.isConversionPriceReset && this.isResettable) ||
      (this.modelCustomizationState.excludeTerms.isVariableConversion && this.isVariableConversion) ||
      (this.modelCustomizationState.excludeTerms.isCurrentConversionAveraging && this.isCash) ||
      (this.modelCustomizationState.excludeTerms.isCurrentResetAveraging && this.isResettable);
  }

  get shouldShowIncludeTerms(): boolean {
    return (this.modelCustomizationState.includeTerms.isForwardConversionAveraging &&
      (this.isCash || this.isAcquisitionSharesSettlement)) ||
      (this.modelCustomizationState.includeTerms.isForwardResetAveraging && this.isResettable);
  }

  get shouldShowModelType(): boolean {
    return this.modelCustomizationState.modelType.isCreditModelType ||
      (this.modelCustomizationState.modelType.isEquityModelType && !this.isExchangeable) ||
      (this.modelCustomizationState.modelType.isUnderlyingCreditModel && this.isExchangeable) ||
      this.modelCustomizationState.modelType.isStochasticCredit ||
      this.modelCustomizationState.modelType.isNumberOfCreditPoints ||
      this.modelCustomizationState.modelType.isStochasticCreditBC;
  }

  get shouldShowCallAdjustments(): boolean {
    return this.modelCustomizationState.callAdjustments.isHardCallModelType ||
      (this.modelCustomizationState.callAdjustments.isHardCallAdjustFactor && this.isBarierModel) ||
      this.modelCustomizationState.callAdjustments.isSoftCallModelType ||
      (this.modelCustomizationState.callAdjustments.isSoftCallAdjustFactor && this.isSoftCallTrigger) ||
      this.modelCustomizationState.callAdjustments.isUseCallNotice ||
      this.modelCustomizationState.callAdjustments.isNotCallableDate ||
      this.modelCustomizationState.callAdjustments.isNotCallableParity;
  }

  get shouldShowOtherParams(): boolean {
    return (this.modelCustomizationState.otherParameters.isDividendProtectionModel && this.isDividendProtection) ||
      (this.modelCustomizationState.otherParameters.isPEPSParityConvention && this.isPeps) ||
      this.modelCustomizationState.otherParameters.isExpectedLifeExcludingDefault ||
      (this.modelCustomizationState.otherParameters.isExchangeableAsConvertible && this.isExchangeable) ||
      (this.modelCustomizationState.otherParameters.isDefaultOnLowParity && this.isPledgedShares) ||
      (this.modelCustomizationState.otherParameters.isIncludeCashRebateInParity && this.isRebate) ||
      (this.modelCustomizationState.otherParameters.isForceOptionalReset && this.isOptionalReset) ||
      this.modelCustomizationState.otherParameters.isMaturityDateOverride ||
      this.modelCustomizationState.otherParameters.isFwdDividProtectionAdjst ||
      this.modelCustomizationState.otherParameters.isConversionNoticeGiven;
  }

  get isTwoFactorModel(): boolean {
    if (this.asHelper.marketData) {
      if (!this.asHelper.marketData.credit.issuerCreditParameters) {
        return false;
      }
      return this.asHelper.marketData.credit.issuerCreditParameters.creditModelFactor === CreditModelFactor.Factor2;
    }

    if (!this.asHelper.settings.valuationSession) {
      return false;
    }

    if (!this.asHelper.settings.valuationSession.valuationSettings) {
      return false;
    }

    //withoud this condition application thrown error on instrument unload
    if (!this.asHelper.settings.valuationSession.valuationSettings.marketData) {
      return false;
    }

    return this.asHelper.settings.valuationSession.valuationSettings.marketData.creditModelFactor === CreditModelFactor.Factor2;
  }

  get isCashDistribution(): boolean {
    if (this.cHelper.dividendProtectionType === null) {
      return false;
    }
    return this.cHelper.dividendProtectionType === DividendProtectionType.CashDistribution;
  }

  get isAtConversionCashDistribution(): boolean {
    if (this.cHelper.cashDistributionDateType === null) {
      return false;
    }
    return this.cHelper.cashDistributionDateType === CashDistributionDateType.AtConversion;
  }

  get isAcquisitionSharesSettlement(): boolean {
    if (this.cHelper.useAcquisitionSharesSettlement === null) {
      return false;
    }
    return this.cHelper.useAcquisitionSharesSettlement;
  }

  get fwdDividProtectionAdjstText(): string {
    return this._excelSvc?.isInitialized() ? 'Fwd. Divid. Prot. Adjst' : 'Fwd. Divid. Protection Adjst';
  }

  get softCallModelTypeText(): string {
    return this._excelSvc?.isInitialized() ? 'Soft Call Model Type' : 'Soft Call (Trigg.) Model Type';
  }

  get softCallAdjModelTypeText(): string {
    return this._excelSvc?.isInitialized() ? 'Soft Call Adj. Factor' : 'Soft Call (Trigg.) Adj. Factor';
  }

  get assumeDvdUntilMaturity(): string {
    return this._excelSvc?.isInitialized() ? 'Assume Dvd Until Mat' : 'Assume Dvd Until Maturity';
  }

  constructor(
    private modelCustomizationState: IModelCustomizationState,
    private _excelSvc: LvExcelService,
    private _lvDateService: LvDateService
  ) {
    this.creditModelTypeLookup = new LvLookupEnum(CreditModelTypeDescription);
    this.equityModelTypeLookup = new LvLookupEnum(EquityModelTypeDescription);
    this.underlyingModelTypeLookup = new LvLookupEnum(CreditModelTypeDescription);
    this.stochasticCredit = new LvLookupEnum(StochasticCreditModelTypeDescription);
    this.stochasticCreditBC = new LvLookupEnum(StochasticCreditBCType);
    this.dividendProtectionMode = new LvLookupEnum(DividendProtectionModelDescription);
    this.pepsParityConvention = new LvLookupEnum(PepsParityConventionDescription);
    this.hardCallModelType = new LvLookupEnum(HardCallModelTypeDescription);
    this.softCallModelType = new LvLookupEnum(SoftCallModelTypeDescription);
    this.triggerConditionMonitoring = new LvLookupEnum(TriggerConditionMonitoring);
    this.pricerVersion = new LvLookupEnum(PricerVersion);

    // checkbox ids
    this.chExpectedLifeId = v4();
    this.chPriceExchId = v4();
    this.chDefaultOnLowParityId = v4();
    this.chIncCashRebateId = v4();
    this.chCallId = v4();
    this.notCallableId = v4();
    this.maturityDateOverrideId = v4();
    this.chPutId = v4();
    this.chDividendProtectionId = v4();
    this.chContigentConversionId = v4();
    this.chConversionPriceResetId = v4();
    this.chVariableConversionId = v4();
    this.chCurrentConversionAveragingId = v4();
    this.chUseNoticeCallId = v4();
    this.chForceOptionalResetId = v4();
    this.chApplyConversionAveraging = v4();
    this.chFwdDividProtectionAdjst = v4();
    this.chForwardConversionAveragingId = v4();
    this.chCurrentResetAveragingId = v4();
    this.chForwardResetAveragingId = v4();
    this.conversionNoticeGivenId = v4();
    this.assumeDvdUntilMaturityId = v4();   

    this.asHelper = new AnalyticsSettingsHelper();
    this.cHelper = new ConvertibleHelper(_lvDateService);
    this.modelCustomizationMapper = new ModelCustomizationExcelMapper();


    this.baseColumnWidth = 318;
    this.excludeTermsSectionWidth = 185;
    this.defaultColumnPadding = 9;
    this.isNotCallableEnabled = true;
    this.setDefaultColumnsMode(new ModelCustomizationViewConfig(this.modelCustomizationState));
  }

  /**
   * Function for set columns mode (wide or default)
   * @param currentWidth current Width
   * @param modelCustomizationState modelCustomizationState
   */
  onResize(currentWidth: number, modelCustomizationState: IModelCustomizationState) {
    const viewConfig = new ModelCustomizationViewConfig(modelCustomizationState);
    if (viewConfig.isWideMode(currentWidth)) {
      this.setWideColumnsMode(viewConfig);
    } else {
      this.setDefaultColumnsMode(viewConfig);
    }
  }

  /**
   * Updates model customization state.
   * @param modelCustomizationState IModelCustomizationState object.
   */
  updateModelCustomizationState(modelCustomizationState: IModelCustomizationState): void {
    this.modelCustomizationState = modelCustomizationState;
    this.setDefaultColumnsMode(new ModelCustomizationViewConfig(modelCustomizationState));
  }

  /**
   * Does initialization.
   * @param as IAnalyticsSettings object.
   */
  init(as?: IAnalyticsSettings) {
    this.asHelper.init(as);
    this.modelSettings = getDefaultModel(this.asHelper.modelSettings);
    if (as) {
      this.cHelper.init(as.convertible);
      if (as.convertible) {
        this.setNotCallableFieldsStatus(as.convertible.isCallable);
      }
    }

    this.creditModelTypeLookup.setFilterFn(item => {
      if (this.isExchangeable && (item.id === CreditModelType.StructuralModel || item.id === CreditModelType.Hybrid)) {
        return false;
      }
      return true;
    });

    this.underlyingModelTypeLookup.setFilterFn(item => {
      if (this.isExchangeable && (item.id === CreditModelType.StructuralModel || item.id === CreditModelType.Hybrid)) {
        return false;
      }
      return true;
    });

    if (this._excelSvc?.isInitialized() && !!this.modelSettings) {
      if (!this.modelSettings.conversionNoticeGiven) {
        this.modelSettings.conversionNoticeDate = null;
      } else {
        this.modelSettings.conversionNoticeDate = new Date(this.modelSettings.conversionNoticeDate);
      }

      if (!this.modelSettings.isMaturityDateOverride) {
        this.modelSettings.maturityDateOverride = null;
      } else {
        this.modelSettings.maturityDateOverride = new Date(this.modelSettings.maturityDateOverride);
      }

      if (!this.modelSettings.isNotCallableDate) {
        this.modelSettings.callAdjustmentModelSettings.notCallableDate = null;
      } else {
        this.modelSettings.callAdjustmentModelSettings.notCallableDate = new Date(this.modelSettings.callAdjustmentModelSettings.notCallableDate);
      }
    }
  }

  /**
   * Updates model settings.
   */
  updateModelSettings(settings?: IModelCustomization) {
    this.modelSettings = getDefaultModel(settings);
  }

  /**
   * Sets not callable fields status.
   * @param isCallable Callable flag.
   */
  setNotCallableFieldsStatus(isCallable: boolean) {
    this.isNotCallableEnabled = isCallable;
  }

  /**
   * Gets override request.
   * @param sourceId Source ID.
   * @returns IOverrideModelCustomizationRequest object.
   */
  getOverrideRequest(sourceId: string): IOverrideModelCustomizationRequest {
    return {
      lwsIdentifier: this.asHelper.lwsIdentifier,
      sessionId: this.asHelper.sessionId,
      modelCustomization: {
        ...this.modelSettings,
        callAdjustmentModelSettings: { ...this.modelSettings.callAdjustmentModelSettings },
        creditModelSettings: { ...this.modelSettings.creditModelSettings }
      },
      sourceId: sourceId
    };
  }

  /**
   * Function to get save model customization request
   * @param environmentId Environment identifier
   * @param sourceId Source identifier
   * @returns ISaveModelCustomizationRequest object
   */
  getSaveRequest(environmentId: string, sourceId: string): ISaveModelCustomizationRequest {
    return {
      environmentId: environmentId,
      lwsIdentifier: this.asHelper.lwsIdentifier,
      isPrivateInstrument: this.asHelper.isPrivateInstrument,
      modelCustomization: {
        ...this.modelSettings,
        callAdjustmentModelSettings: { ...this.modelSettings.callAdjustmentModelSettings },
        creditModelSettings: { ...this.modelSettings.creditModelSettings }
      },
      sourceId: sourceId,
      leversysLocalId: this.asHelper.valuationSession.leversysLocalId,
    };
  }

  /**
   * Function for set default columns mode
   * @param viewConfig ModelCustomizationViewConfig
   */
  private setDefaultColumnsMode(viewConfig: ModelCustomizationViewConfig) {
    this.excludeTermsModelTypeDirection = 'column';
    this.callAveragingOtherDirection = 'column';
    this.excludeTermsModelTypeMinWidth = viewConfig.excludeTermsModelTypeSectionMinWidth;
    this.callAveragingOtherMinWidth = viewConfig.callAveragingOtherMinSectionMinWidth;
  }

  /**
   * Function for set wide columns mode
   * @param viewConfig ModelCustomizationViewConfig
   */
  private setWideColumnsMode(viewConfig: ModelCustomizationViewConfig) {
    this.excludeTermsModelTypeDirection = 'row';
    this.callAveragingOtherDirection = 'row';
    this.excludeTermsModelTypeMinWidth = viewConfig.excludeTermsModelTypeSectionMinWidth;
    this.callAveragingOtherMinWidth = viewConfig.callAveragingOtherMinSectionMinWidth;
  }
}
