<lv-flex-box class="lv-volatility-surface">
  <lv-flex-box
    direction="row"
    data-cy="DM-294"
    class="lv-equity-volatility-surface-reference-stock-level">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="referenceStockLevelTooltipTemplate"
      filter="span:not(lv-xl-label span)"
      lvId="lbPriceSurface">
      <span [attr.volatility-tooltip-id]="'referenceStockLevelTooltip'">
        Reference Stock Level
      </span>
      <lv-xl-label [field]="'VOL_SURF_PRICE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        *ngIf="isEditable"
        name="priceSurface"
        [(ngModel)]="volatilitySurface.refUnderlying"
        (didBlur)="onChangeSurfaceSetup()"
        (didKeyDownEnter)="onChangeSurfaceSetup()">
      </lv-numeric-text-box>
      <span *ngIf="!isEditable">
        {{volatilitySurface.refUnderlying}}
      </span>
      <span
        class="lv-sufix-label"
        lvId="priceSurfaceSuffix"
        [class.lv-suffix-no-value]="!volatilitySurface.refUnderlying">{{ currencyCode }}</span>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-295"
    class="lv-equity-volatility-surface-base-date">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="baseDateTooltipTemplate"
      filter="span:not(lv-xl-label span)"
      lvId="lbUpdateDate">
      <span [attr.volatility-tooltip-id]="'baseDateTooltip'">
        Base Date
      </span>
      <lv-xl-label [field]="'VOL_SURF_BASE_DT'"></lv-xl-label>
    </div>
    <div class="lv-input-field lv-datePicker-container">
      <kendo-datepicker
        *ngIf="isEditable"
        name="updateDate"
        [(ngModel)]="volatilitySurface.updateDate"
        (ngModelChange)="onChangeSurfaceSetup()"
        [attr.aria-valuetext]="dateFormated">
      </kendo-datepicker>
      <span *ngIf="!isEditable">
        {{dateFormated}}
      </span>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-296"
    class="lv-equity-volatility-surface-use-strike-vol">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="useStrikeVolTooltipTemplate"
      filter="span:not(lv-xl-label span)"
      lvId="lbUseStrikeVol">
      <span [attr.volatility-tooltip-id]="'useStrikeVolTooltip'">
        Use Strike Vol
      </span>
      <lv-xl-label [field]="'VOL_USE_STRIKE'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        [disabled]="!isEditable"
        name="useStrikeVol"
        class="k-checkbox"
        type="checkbox"
        [attr.id]="view.useStrikeVolId"
        [(ngModel)]="volatilitySurface.useStrikeVol"
        (ngModelChange)="onChangeSurfaceSetup()"/>
      <label class="k-checkbox-label"
        lvId="useStrikeVol"
        [attr.for]="view.useStrikeVolId">
      </label>
    </div>
  </lv-flex-box>
<lv-flex-box class="lv-volatility-surface-container">
  <lv-flex-box direction="row" class="lv-volatility-grid-title">
    <div showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="volatilitySurfaceScheduleTooltipTemplate"
      filter=".lv-label" 
      class="lv-label lv-label-field lv-label--title">
      <span [attr.volatility-tooltip-id]="'volatilitySurfaceScheduleTooltip'">
        VOLATILITY SURFACE
      </span> 
    </div>
  </lv-flex-box>
  <lv-advanced-grid
    data-cy="DM-297"
    [columns]="columns"
    [records]="volatilitySurfaceSchedule"
    lvId="lvMarketDataVolatilitySurfaceGrid"
    [createFormGroup]="createFormGroup"
    [skipRecordOnDeleteFn]="skipRecordOnDeleteFn"
    [parseFn]="parseFn"
    sortBy="date"
    [showXlLabel]="hasScheduleInExcelOverride"
    [excelFieldAlias]="excelFieldAlias"
    [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
    [ngClass]="addStripedGridClass"
    [editDisabled]="isGridDisabled">
  </lv-advanced-grid>
</lv-flex-box>


<ng-template #referenceStockLevelTooltipTemplate>
  <lv-tooltip dm="dM-294"></lv-tooltip>
</ng-template>
<ng-template #baseDateTooltipTemplate>
  <lv-tooltip dm="dM-295"></lv-tooltip>
</ng-template>
<ng-template #useStrikeVolTooltipTemplate>
  <lv-tooltip dm="dM-296"></lv-tooltip>
</ng-template>
<ng-template #volatilitySurfaceScheduleTooltipTemplate>
  <lv-tooltip dm="dM-297"></lv-tooltip>
</ng-template>


