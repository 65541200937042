
export enum RatchetMatrixCompensationValueType {	
	AdditionalSharesAbsolute = 'AdditionalSharesAbsolute',
	AdditionalSharesPercentOfCR = 'AdditionalSharesPercentOfCR',
	CashPremium = 'CashPremium'
}

export enum RatchetMatrixCompensationValueTypeDescription {	
	AdditionalSharesAbsolute = 'Additional Shares (Absolute)',
	AdditionalSharesPercentOfCR = 'Additional Shares (% of CR)',
	CashPremium = 'Redemption Price'
}