import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import { AswType, AswTypeDescription } from '../../../models/asset-swap-terms-settings/enums/asset-swap-type';
import { PayLegType } from '../../../models/asset-swap-terms-settings/enums/asset-swap-pay-leg-type';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { IAswTerms } from '../../../models/aswTerms';
import _ from 'lodash';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import {  StrikeTypeDescription } from '../../../models/asset-swap-terms-settings/enums/asset-swap-strike-type';
import { AssetSwapSettingsService } from '@lv-custom-instruments/services/asset-swap-settings/asset-swap-settings-service';

@Component({
  selector: 'lv-asw-terms',
  templateUrl: './lv-asw-terms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AssetSwapTermsComponent implements OnInit {

  get isAswTypeConvertible () {
    //to do: remove setTimeOut once override in session is called via API.
    setTimeout(() => {
    }, 1000);
    return this.aswTerms.aswType === AswType.Convertible;
  }

  get isStrikeTypeFixed () {
    //to do: remove setTimeOut once override in session is called via API.
    setTimeout(() => {
    }, 1000);
    return this.aswTerms.strikeType === StrikeTypeDescription.FixedStrike;
  }

  get isPayLegTypeFixed () {
    //to do: remove setTimeOut once override in session is called via API.
    setTimeout(() => {
    }, 1000);
    return this.aswTerms.payLegType === PayLegType.Fixed;
  }
  get isEarlyExerciseFeeApplicable () {
    //to do: remove setTimeOut once override in session is called via API.
    setTimeout(() => {
    }, 1000);
    return !!this.aswTerms.conversion.earlyExerciseFeeApplicable;
  }

  
  aswTerms: IAswTerms;
  originalValue: IAswTerms;
  instrumentType: string;
  isLoading: boolean;
  aswTypeLookup: LvLookupEnum;
  payLegTypeLookup: LvLookupEnum;
  strikeTypeLookup: LvLookupEnum;

  decimalsTwo = '2';
  formatTwo = '#,###.##';

  constructor(
    public _changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _errorService: LvErrorService,
    private _aswTermsSettingsService: AssetSwapSettingsService
  ) {
    this.isLoading = false;
    this.instrumentType = 'ASW';
    this.aswTerms = {
      instrumentType: '',
      name: '',
      shortName: '',
      underlying: '',
      aswType: null,
      effectiveDate: null,
      maturityDate: null,
      nominalValue: null,
      payLegType: null,
      strikeType: null,
      strikeTypeRedemption: null,
      strikeReferenceDate: null,
      recallSpread: null,
      conversion: {
        exerciseStartDate: null,
        exerciseEndDate: null,
        earlyExerciseFeeApplicable: null,
        earlyExerciseFee: null,
        earlyExerciseEndDate: null,
        feeFormulaType: null,
        daysInYear: null,
        maxIntervalDays: null,
        busDaysToEarlyRepayment: null
      },
      referenceIrSwap: {
        payLegFrequency: null,
        payLegDayCount: null,
        interestPaymentCommencementDate: null,
        additionalPaymentAtAswMaturity: null
      },
      floatingLegSchedule:  []
    }

    this.aswTypeLookup = new LvLookupEnum(AswTypeDescription);
    this.payLegTypeLookup = new LvLookupEnum(PayLegType);
    this.strikeTypeLookup = new LvLookupEnum(StrikeTypeDescription);
  }

  ngOnInit(): void {
   this.loadAswTerms();
  }

  onSave() {
    try {
    //to do: api call for save identifiers.
    this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388',
    {'value': 'Terms'}));
   } catch(error) {
    this._errorService.toastrService.error(LvDataMaster.getError('dM-3385'));
    }

  }

  /**
   * Reload asw terms.
   */
  onReload () {
    try {
      // await this._analyticsPresenter.loadAswSession();    
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Method handles loading ASW Terms.
   */
  loadAswTerms(): void {
    //to do: this method should be async once fetching val.session is implemented.
    try {
      // if(!this._analyticsPresenter.aswSession?.leversysLocalId) {
      //   const aswTermsSettings = await this._aswTermsSettingsService.getAssetSwapTermsSettings();
      //   this._lvAswTermsService.mapBondToUi(aswTermsSettings);
      // map all fields from terms to fetched fields from settings
      // else {
       //to do: remove setTimeout once one valuation session is fetched via api call.
          setTimeout(() => {
            if (!!this._analyticsPresenter?.aswSession?.terms?.terms) {
                this.aswTerms = this._analyticsPresenter.aswSession.terms.terms;
                // map all date fields to ui appropriate format
                // this._lvAswTermsService.mapBondToUi(aswTerms);
                this.originalValue = _.cloneDeep(this._analyticsPresenter.aswSession.terms.terms);
                this._changeDetectorRef.detectChanges();
            }
          },1000);
      // }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Update valuation session every time terms changes.
   */
  updateAswTerms() {
    this._analyticsPresenter.overrideAswInSession(aswValuationSession => {
      aswValuationSession.terms.terms = this.aswTerms;
    });

    if (!_.isEqual(this.aswTerms, this.originalValue)) {
      this.originalValue = _.cloneDeep(this.aswTerms);
    }

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Method sets loading state.
   * @param loading Loading state flag.
   */
  private setLoadingState(loading: boolean): void {
    this.isLoading = loading;
    this._changeDetectorRef.detectChanges();
  }
}
