import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { ConvertibleHelper } from '@lv-analytics/helpers';
import { ILvScenarioInputs, ScenarioInputsShift, ScenarioInputsShiftDescription, IConvertible, formatUnderlyingPrice,
         formatUnderlyingCBPrice } from '@lv-analytics/models';
import { LvDateService } from '@lv-core-ui/services';

export const getDefaultInputs = (inputs?: ILvScenarioInputs): ILvScenarioInputs => {
  return inputs || {
    shift: null,
    change: 'abs',
    changeRef: null,
    min: null,
    max: null,
    step: null
  };
};

/**
 * Scenario inputs view.
 */
export class LvScenarioInputsView {

  get absRadioDisabled(): boolean {
    return this.isValuationDateShiftSelected;
  }

  get changeRefVisible(): boolean {
    return this.model.change === 'pct';
  }

  get rangeSuffix(): string {
    return this.getRangeSuffix();
  }

  get isValuationDateShiftSelected(): boolean {
    return this.model.shift === ScenarioInputsShift.ValuationDate;
  }

  get stepMin(): number {
    if (this.isValuationDateShiftSelected) {
      return 1;
    }

    if (!this.model.max && !this.model.min) {
      return Number.MIN_SAFE_INTEGER;
    }

    return 0.0001;
  }

  get stepMax(): number {
    if (this.isValuationDateShiftSelected) {
      return this._lvDateService.getDifferenceInDaysBetweenTwoDates(this.model.min, this.model.max);
    }

    if (!this.model.max || !this.model.min) {
      return Number.MAX_SAFE_INTEGER;
    }

    return (this.model.max as number) - (this.model.min as number);
  }

  get maxDate(): Date {
    if (this._cHelper.convertible && this._cHelper.convertible.maturityDate) {
      let maxDate = this._cHelper.convertible.maturityDate;
      if (!(this._cHelper.convertible.maturityDate instanceof Date)) {
        maxDate = new Date(this._cHelper.convertible.maturityDate);
      }

      return this._lvDateService.getEndOfDate(maxDate);
    }

    return null;
  }

  get minDate(): Date {
    if (this._cHelper.convertible && this._cHelper.convertible.firstSettlementDate) {
      let minDate = this._cHelper.convertible.firstSettlementDate;
      if (!(this._cHelper.convertible.firstSettlementDate instanceof Date)) {
        minDate = new Date(this._cHelper.convertible.firstSettlementDate);
      }

      return  this._lvDateService.getEndOfDate(minDate);
    }

    return null;
  }

  shiftLookup: LvLookupEnum;

  model: ILvScenarioInputs;

  absRadioId: string;
  pctRadioId: string;

  originalMaxDate: Date;
  originalMinDate: Date;

  isNewIssueScenario: boolean;

  private _cHelper: ConvertibleHelper;

  constructor(
    private _lvDateService: LvDateService
  ) {
    this.shiftLookup = new LvLookupEnum(ScenarioInputsShiftDescription);

    this.absRadioId = v4();
    this.pctRadioId = v4();

    this._cHelper = new ConvertibleHelper(_lvDateService);

    this.init(false);
  }

  /**
   * Does initialization.
   */
  init(isNewIssueScenario: boolean, inputs?: ILvScenarioInputs, converible?: IConvertible) {
    this.isNewIssueScenario = isNewIssueScenario;

    this._cHelper.init(converible);

    this.model = getDefaultInputs(inputs);
    this.setOriginalValues();

    this.adjustShift();

    this.adjustValuationDates();

    this.shiftLookup.setFilterFn(item => {
      if (!this.isNewIssueScenario && ((item.id === ScenarioInputsShift.Coupon) || (item.id === ScenarioInputsShift.Premium))) {
        return false;
      }

      if (this._cHelper.currencyCode === this._cHelper.underlyingCurrencyCode && item.id === ScenarioInputsShift.UnderlyingPriceCB) {
        return false;
      }

      return true;
    });

    this.shiftLookup.updateItems(item => {
      if (item.id === ScenarioInputsShift.UnderlyingPrice) {
        item.text = formatUnderlyingPrice(this._cHelper.underlyingCurrencyCode);
      }

      if (item.id === ScenarioInputsShift.UnderlyingPriceCB) {
        item.text = formatUnderlyingCBPrice(this._cHelper.currencyCode);
      }
    });
  }

  /**
   * Resets view.
   */
  reset() {
    Object.assign(this.model, getDefaultInputs());
  }

  /**
   * Occurs on shift change.
   */
  onShiftChange() {
    // On shift change clear data
    const shift = this.model.shift;
    this.reset();
    this.model.shift = shift;

    if (this.model.shift === ScenarioInputsShift.ConvertiblePrice
      || this.model.shift === ScenarioInputsShift.UnderlyingPrice
      || this.model.shift === ScenarioInputsShift.CreditSpread
      || this.model.shift === ScenarioInputsShift.Borrow) {
        this.model.change = 'abs';
      }

    if (this.model.shift === ScenarioInputsShift.Coupon
      || this.model.shift === ScenarioInputsShift.Premium
      || this.model.shift === ScenarioInputsShift.InterestRate
      || this.model.shift === ScenarioInputsShift.DividendYield) {
      this.model.change = 'pct';
    }
  }

  /**
   * Sets original values.
   * @param isSave
   */
  setOriginalValues(isSave: boolean = false) {
    if (this.model.shift === ScenarioInputsShift.ValuationDate) {
      if (this.model.max && (isSave || !this.originalMaxDate)) {
        this.originalMaxDate = new Date(this.model.max);
      }

      if (this.model.min && !this.originalMinDate) {
        this.originalMinDate = new Date(this.model.min);
      }
    }
  }

  /**
   * Gets range suffix.
   * @returns Range suffix.
   */
  private getRangeSuffix(): string {
    if (this.model.change === 'pct') {
      return '%';
    }

    switch (this.model.shift) {
      case ScenarioInputsShift.ConvertiblePrice: {
        return this._cHelper.isPriceAsPar ? 'pts' : this._cHelper.underlyingCurrencyCode;
      }
      case ScenarioInputsShift.UnderlyingPrice: {
        return this._cHelper.underlyingCurrencyCode;
      }
      case ScenarioInputsShift.UnderlyingPriceCB: {
        return this._cHelper.currencyCode;
      }
      case ScenarioInputsShift.CreditSpread: {
        return 'bps';
      }
      case ScenarioInputsShift.Volatility: {
        return 'vol pts';
      }
      case ScenarioInputsShift.Borrow: {
        return 'bps';
      }
    }
  }

  /**
   * Adjusts shift.
   */
  private adjustShift() {
    if (this._cHelper.currencyCode === this._cHelper.underlyingCurrencyCode
      && this.model.shift === ScenarioInputsShift.UnderlyingPriceCB) {
      this.model.shift = ScenarioInputsShift.UnderlyingPrice;
    }
  }

  /**
   * Adjusts valuation dates.
   */
  private adjustValuationDates(): void {
    const maxDate = this.maxDate;
    const minDate = this.minDate;
    let previousMaxStep = this.stepMax;

    if (maxDate && minDate) {
      const difference = this._lvDateService.getDifferenceInDaysBetweenTwoDates(this.minDate, this.maxDate);

      if (difference < previousMaxStep) {
        previousMaxStep = difference;
      }
    }

    if (this.model.shift === ScenarioInputsShift.ValuationDate && minDate) {

      if (this.model.min) {
        this.model.min = minDate > this.originalMinDate ? minDate : this.originalMinDate;

        if (this.model.max) {
          this.model.max = this._lvDateService.addDatesToDate(this.model.min, previousMaxStep);
        }
      }
    }

    if (this.model.shift === ScenarioInputsShift.ValuationDate && maxDate) {

      if (this.model.max) {
        this.model.max = maxDate < this.originalMaxDate ? maxDate : this.originalMaxDate;

        if (this.model.min) {
          this.model.min = this._lvDateService.addDatesToDate(this.model.max, (-1) * previousMaxStep);
        }
      }
    }
  }
}
