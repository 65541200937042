import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvUserFriendlyError } from '@lv-core-ui/models';
import { IField } from '@lv-excel/models/field';
import { LvDateService } from '@lv-core-ui/services';
@Injectable()
export class ApiService extends HttpClientBase {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/fieldsmetadata');
  }

  async mapFromApi(fromExcel: IField[], version: string): Promise<IField[]> {
    const result: IField[] = [];

    return await this.mapFields(fromExcel, version);
  }

  async mapForSave(
    fields: string[],
    sessionId: string,
    lwsIdentifier: string,
    section: string,    
  ): Promise<IField[]> {
    try {
      return await this.postAsync<IField[]>(
        {
          fields: fields,
          lwsIdentifier: lwsIdentifier,
          sessionId: sessionId,
          section: section          
        },
        '/mapForSave'
      );
    } catch (error) {
      throw this.handleError(error, (e) => new LvUserFriendlyError(e.message));
    }
  }

  /**
   * Map excel fields api call.
   * @param request Request value.
   * @param version Excel version.
   * @returns 
   */
  async mapFields(request: IField[], version: string): Promise<IField[]> {
    try {
      return await this.postAsyncWithVersionProvided<IField[]>(version, { fields: request }, '/mapfields');
    } catch (error) {
      throw this.handleError(error, (e) => new LvUserFriendlyError(e.message));
    }
  }
}

export interface IMapFieldsForExcelFormRequest {
  fields: IField[];
}
