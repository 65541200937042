<lv-flex-box class="lv-dividends" [style.min-width.px]="sectionMinWidth">
  <lv-environment-settings
    [section]="dividendsSection"
    lvId="marketDataDividendsEnvironmentSettings"
    (didChangeEnvironment)="onChangeEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-dividends-content lv-market-data-section-content">
    <lv-flex-box data-cy="DM-319" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendSourceTooltipTemplate"
        filter="span">
        <span [attr.dividend-tooltip-id]="'dividendSourceTooltip'">
          Dividend Source
        </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist name="dividendesSource"
          [data]="dividendsSource.items"
          valueField="id"
          textField="text"
          [popupSettings]="popupSettings"
          [disabled]="isDividendsSectionDisabled"
          [(ngModel)]="dividendsSettings.dividendsSource"
          [valuePrimitive]="true"
          (ngModelChange)="onDividendSectionChanged(true)">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box *ngIf="isScheduleDividendsSelected" 
      data-cy="DM-314"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendCCYTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.dividend-tooltip-id]="'dividendCCYTooltip'">
          Dividend CCY
        </span>
        <lv-xl-label [field]="'DVD_CCY'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist name="dividendCurrency"
          [data]="dividendCurrencySource.items"
          valueField="id"
          textField="text"
          [(ngModel)]="dividendsSettings.dividendCurrencySelected"
          [valuePrimitive]="true"
          required
          (ngModelChange)="onDividendSectionChanged()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <div *ngIf="!isDividendsSectionDisabled">
      <div *ngIf="isUnderlyingEquity">

        <lv-flex-box *ngIf="isUnderlyingDividendsParametersSelected">
          <lv-equity-dividends-parameters
            [dividendsSource]="underlyingEquityDividends"
            [isEditable]="isEditable">
          </lv-equity-dividends-parameters>
        </lv-flex-box>

        <lv-flex-box
          data-cy="DM-314"
          direction="row"
          class="lv-equity-dividends-dividend-ccy">
          <div class="lv-label lv-label-field"
            [class.lv-underlying-source-schedule]="isUnderlyingDividendsScheduleSelected"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="dividendCCYTooltipTemplate"
            filter="span:not(lv-xl-label span)">
            <span>
              Dividend CCY
            </span>
            <lv-xl-label [field]="'DVD_CCY'"></lv-xl-label>
          </div>
          <div class="lv-input-field"
            [class.lv-underlying-source-schedule]="isUnderlyingDividendsScheduleSelected">
           <span>{{underlyingEquityDividends.dividendsCcy}}</span>
          </div>
        </lv-flex-box>

        <lv-flex-box *ngIf="isUnderlyingDividendsScheduleSelected">
          <lv-equity-dividends-schedule
            [scheduleDividends]="underlyingEquityDividends.dividendsSchedule.dividendSchedule"
            [isEditable]="isEditable">
          </lv-equity-dividends-schedule>
        </lv-flex-box>
      </div>
      <lv-parameters-dividends *ngIf="isParameterDividendsSelected"
        [dividendsSettings]="dividendsSettings"
        [dividendCCY]="dividendCCY"
        (didGenerateSchedule)="onGenerateSchedule()"
        (didDividendParametersChange)="onDividendSectionChanged()"
        (didDisplayDividendUseChange)="onDisplayDividendsUsed()">
      </lv-parameters-dividends>
      <lv-schedule-dividends *ngIf="isScheduleDividendsSelected"
        [dividendsSettings]="dividendsSettings"
        [dividendsCopyAndPasteSettings]="dividendsCopyAndPasteSettings"
        (didDividendScheduleChange)="onDividendSectionChanged()"
        (didDisplayDividendUseChange)="onDisplayDividendsUsed()">
      </lv-schedule-dividends>
      <lv-flex-box *ngIf="currencyTypeVisible"
        direction="row"
        data-cy="DM-314">
        <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendCCYTooltipTemplate"
        filter="span">
          <span [attr.dividend-tooltip-id]="'dividendCCYTooltip'">
            Dividend CCY
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_CCY'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist name="dividendCurrency"
            [data]="dividendCurrencySource.items"
            valueField="id"
            textField="text"
            [(ngModel)]="dividendsSettings.dividendCurrencySelected"
            [valuePrimitive]="true"
            required
            (ngModelChange)="onDividendSectionChanged()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" *ngIf="isParameterDividendsSelected" class="lv-dividends-source-controls">
        <button kendoButton
          lvId="marketDataDividendsGenerateSchedule"
          (click)="onGenerateSchedule()">
            Generate Schedule
        </button>
        <button kendoButton
          lvId="marketDataDividendsDisplayDividendsUsed"
          (click)="onDisplayDividendsUsed()">
            Display Dividends Used
        </button>
      </lv-flex-box>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #dividendSourceTooltipTemplate>
  <lv-tooltip dm="dM-319"></lv-tooltip>
</ng-template>
<ng-template #dividendCCYTooltipTemplate>
  <lv-tooltip dm="dM-314"></lv-tooltip>
</ng-template>
