import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IEquityBorrow } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-borrow/equity-borrow';

@Component({
  selector: 'lv-equity-borrow-rebate',
  templateUrl: './lv-equity-borrow-rebate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityBorrowRebateComponent {
  @Input() borrowSettings: IEquityBorrow;
  @Input() isEditable: boolean;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  numberOfDecimals = '2';
  numberFormat = '#.##';

  constructor() {
    this.didSessionUpdatedEvent = new EventEmitter();
    this.isEditable= true;
  }

  @HostBinding('class.lv-equity-borrow-rebate')
  get isLvEquityBorrowRebateComponent(): boolean {
    return true;
  }

  /**
   * Method emits event on every state change.
   */
  onRebateSectionChanged() {
    this.didSessionUpdatedEvent.next();
  }
}
