import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lv-bond-market-data-widget',
  templateUrl: './lv-bond-market-data-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LvBondMarketDataWidgetComponent {

  @HostBinding('class.lv-market-data-widget')
  get isLvMarketDataWidget(): boolean {
    return true;
  }
}
