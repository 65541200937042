import { v4 } from 'uuid';
import * as _ from 'lodash';
import { IVolatility, VolatilityType } from '@lv-analytics/models/market-data/volatility';
import { IEquityVolatility } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-volatility.ts/equity-volatility';

/**
 * Volatility view.
 */
export class VolatilityView {

  volatilitySettings: IVolatility;
  originalValue: IVolatility;
  underlyingEquityVolatility: IEquityVolatility;
  originalUnderlyingEquityVolatility: IEquityVolatility;

  isFlatVolatility: boolean;
  isUpsideDownside: boolean;
  isTermStructure: boolean;
  isSurface: boolean;
  isUnderlyingVolatilityFlat: boolean;
  isUnderlyingVolatilityTermStructure: boolean;
  isUnderlyingVolatilitySurface: boolean;
  useLocalVolFromTwoVolsId: string;

  numberOfDecimals = '2';
  numberFormat = '#.##';

  constructor() {
    this.volatilitySettings = {
      volatilityTermStructureSchedule: [],
      volatilitySurface: {}
    } as IVolatility;

    this.underlyingEquityVolatility = {
      flatVol: null,
      riskyType: null,
      volatilityTermStructureSchedule: [
        {
          date:null,
          value: null,
        }
      ],
      volatilitySurface: {
        moneynessStrikeType: null,
        dateTenorType: null,
        refUnderlying: null,
        updateDate: null,
        useStrikeVol: null,
        volatilitySurfacePoints: []
      }
    } as IEquityVolatility;

    this.useLocalVolFromTwoVolsId = v4();
  }

  /**
   * Does initialization.
   * @param volatilitySettings IVolatility object.
   */
  init(volatilitySettings: IVolatility) {
    this.volatilitySettings = volatilitySettings;
    this.originalValue = _.cloneDeep(volatilitySettings);
    this.onVolatilitySourceChange();
  }

  /**
   * Does  underlying equity initialization.
   * @param volatilitySettings IVolatility object.
   */
  initUnderlyingEquity(volatilitySettings: IEquityVolatility) {
    this.underlyingEquityVolatility = volatilitySettings;
    this.originalUnderlyingEquityVolatility = _.cloneDeep(this.underlyingEquityVolatility);
    this.onVolatilitySourceChange();
  }

  /**
   * Occurs on volatility source change.
   */
  onVolatilitySourceChange() {
    this.isFlatVolatility = this.volatilitySettings.volType === VolatilityType.Flat;
    this.isUpsideDownside = this.volatilitySettings.volType === VolatilityType.UpsideDownside;
    this.isTermStructure = this.volatilitySettings.volType === VolatilityType.TermStructure;
    this.isSurface = this.volatilitySettings.volType === VolatilityType.VolatilitySurface;
    this.isUnderlyingVolatilityFlat = this.volatilitySettings.volType === VolatilityType.UnderlyingEquityDataFlat;
    this.isUnderlyingVolatilityTermStructure = this.volatilitySettings.volType === VolatilityType.UnderlyingEquityDataTermStructure;
    this.isUnderlyingVolatilitySurface = this.volatilitySettings.volType === VolatilityType.UnderlyingEquityDataSurface;
  }
}
