import { IConvertibleBondInfo } from './convertible-bond-info';
import { map } from 'rxjs/operators';

export class InstrumentMonitorMapper {

  private emptyCouponTypeValue = {
    floating: 'Floating',
    pik: 'Pay In Kind',
    fixtofloating: 'Fix To Float',
    floatingtofix: 'Float To Fix',
    custom: 'Step-Up'
  };

  mapConvertibleBondInfoArrayToUI(convertibibleBondInfos: IConvertibleBondInfo[]): IConvertibleBondInfo[] {
    const mappedArray = [];
    convertibibleBondInfos.forEach(element => {
      mappedArray.push(this.mapConvertibleBondInfoToUI(element));
    });
    return mappedArray;
  }

  mapConvertibleBondInfoToUI(convertibleBondInfo: IConvertibleBondInfo): IConvertibleBondInfo {
    convertibleBondInfo.couponValue = this.mapEmptyCouponTypeValue(convertibleBondInfo.couponType, convertibleBondInfo.couponValue);
    return convertibleBondInfo;
  }

  private mapEmptyCouponTypeValue(couponType: string, couponValue: string): string {
    const couponTypeValue = couponType?.toLowerCase();

    if (couponValue) {
      return couponValue;
    }

    if (!Object.keys(this.emptyCouponTypeValue).find(key => key === couponTypeValue)) {
      return couponValue;
    }

    return this.emptyCouponTypeValue[couponTypeValue];
  }
}
