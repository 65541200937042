import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IInstrumentLookup, LvInstrumentMonitorError } from '../../models';
import { HttpClientBase } from '@lv-core-ui/api';
import { InstrumentSourceType } from '@lv-instrument-monitor/models/enums/instrument-source';
import { LvDateService } from '@lv-core-ui/services';

@Injectable()
export class LookupService extends HttpClientBase {

  private convertibles: IInstrumentLookup[];

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/instrumentMonitor/lookup');
  }

  /**
   * Retrieves list of instrumetns
   * @param query Searcg query for instrument select
   * @param skip Number of instruments to skip
   * @param take Number of instruments to retrive
   * @returns List of matching instruments
   */
  async getInstrumentLookup(query: string, skip: number, take: number): Promise<IInstrumentLookup[]> {
    try {
      const result: IInstrumentLookup[] = await this.getAsync<IInstrumentLookup[]>({
        query: query || '',
        skip: skip,
        take: take
      }, '/instrumentInfos');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  /**
   * Retrieves instrument for given query string.
   * @param query instrument identifier used to lookup instrument
   * @param instrumentSource Instrument source type
   */
  async getInstrument(query: string, instrumentSource: InstrumentSourceType): Promise<IInstrumentLookup> {
    try {
      const result: IInstrumentLookup = await this.getAsync<IInstrumentLookup>({
        query: query || '',
        instrumentSource: instrumentSource
      }, '/instrumentInfo');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }
}
