
/**
 * Terms changes tracker class.
 *
 * @export
 * @interface ITermsChangesTracker
 */
export interface ITermsChangesTracker {
    priorityFields: boolean;
    calculationAffectingFields: boolean;
    other: boolean;
}

/**
 * List of priority terms fields.
 */
export const priorityFields: Record<string, boolean> = {
    'DM-379': true,
    'DM-380': true,
    'DM-1403': true,
    'DM-335': true,
    'DM-999': true,
};

/**
 * List of calculation affecting terms fields.
 */
export const calculationAffectingFields: Record<string, boolean> = {
    'DM-897': true,
    'DM-362': true,
    'DM-839': true,
    'DM-841': true,
    'DM-355': true,
    'DM-4348': true,
    'DM-1304': true,
    'DM-1305': true,
    'DM-1306': true,
    'DM-4349': true,
    'DM-4618': true,
    'DM-4636': true,
    'DM-895': true,
    'DM-382': true,
    'DM-385': true,
    'DM-370': true,
    'DM-371': true,
    'DM-24': true,
    'DM-990': true,
};