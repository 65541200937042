import { Injectable } from "@angular/core";
import { BondTermsDocument } from "../../models/bond-terms/BondTermsDocument";
import { Subject } from "rxjs";
import { BondTermsSectionEvent } from "../../models/bond-terms/enums/bond-terms-section-events";

export interface IModelEvent<T> {
  eventId: T;
}

export interface IPresenter {
  onModelUpdated: Subject<BondTermsSectionEvent>;
  termsChanged: Subject<string>;
  getModel(): BondTermsDocument;
  updateModel(action: (terms: BondTermsDocument) => void, sectionEventType: BondTermsSectionEvent, sourceOfUpdate: string): void;
}

@Injectable()
export class LvBondTermsPresenter implements IPresenter {
  public onModelUpdated = new Subject<BondTermsSectionEvent>();
  public termsChanged: Subject<string>;

  private _model: BondTermsDocument;

  constructor() {
    this.termsChanged = new Subject<string>();
  }

  public updateModel(
    action: (terms: BondTermsDocument) => void,
    sectionEventType: BondTermsSectionEvent,
    sourceOfUpdate: string
  ): void {
    action(this._model);
    this.onModelUpdated.next(sectionEventType);

    if (sourceOfUpdate) {
      this.termsChanged.next(sourceOfUpdate);
    }
  }

  public getModel(): BondTermsDocument {
    return this._model;
  }

  public initModel(model: BondTermsDocument) {
    this._model = model;
  }

  public isModelLoaded() {
    return !!this._model;
  }

  public isMakeWholeEnabled() {
    return this._model?.general?.isCallable;
  }
}
