import { SetupStatus } from '@lv-instrument-common/index';
import { CurrencyType } from '../../../common/enums/CurrencyType';

export class ConversionCustomData {
  nominal: number;
  fixedFx: number;
  startDate: Date;
  endDate: Date;
  conversionPriceCurrency: CurrencyType;
  rebateCurrency: CurrencyType;
  currency: string;
  status: SetupStatus;
}