import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { AssetSwapSettingsService } from '@lv-custom-instruments/services/asset-swap-settings/asset-swap-settings-service';
import _ from 'lodash';
import { IAssetSwapTermsSettings } from 'src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/asset-swap-terms-settings';


@Component({
  selector: 'lv-asset-swap-settings',
  templateUrl: './lv-asset-swap-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class LvAssetSwapSettingsComponent implements OnInit, IDiscardable {

  
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  
  settings: IAssetSwapTermsSettings;
  originalSettings: IAssetSwapTermsSettings;
  constructor(private _errorService: LvErrorService,
              private _assetSwapTermsSettingsService: AssetSwapSettingsService, 
              private _changeDetectorRef: ChangeDetectorRef,               
              private _discardChangesService: DiscardChangesService)
  {
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      my: {},
      system: {},
    } as IAssetSwapTermsSettings

    this.originalSettings = {
      my: {},
      system: {},
    } as IAssetSwapTermsSettings
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }
  
  @HostBinding('class.lv-asset-swap-settings')
  get isLvAssetSwapSettingsComponent() {
    return true;
  }

  ngOnInit(): void 
  { 
    this.setSettings();

    this._discardChangesService.register('Terms', this);
  }
  
  /**
   * Gets Real Asset Swap terms settings from backend.
   */
  async setSettings()
  {
    try{
      this.startLoading.next();
      this.settings = (await this._assetSwapTermsSettingsService.getAssetSwapTermsSettings());
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves asset swap term settings.
   */
  async onSave()
  {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
      await this._assetSwapTermsSettingsService.saveAssetSwapTermsSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
      {'settings_section': 'Terms'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  async onCancel()
  {
    this.didCancel.next();
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
   * Resets assewt swap settings to default.
   */
  async onResetToDefault()
  {
    try {
      this.startLoading.next();
      this.settings = (await this._assetSwapTermsSettingsService.resetAssetSwapTermsSettings());
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Terms'}));
    } 
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }

}
