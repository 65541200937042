<lv-section-settings
    [settings]="settings"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="creditOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-credit-section-settings 
    [settings]="systemSettings"
    [instrumentType]="instrumentType">
    </lv-credit-section-settings>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-credit-section-settings
      [settings]="settings"
      [overrideSystemDefaults]="overrideSystemDefaults"
      [instrumentType]="instrumentType">
    </lv-credit-section-settings>
  </ng-template>
</lv-section-settings>

<ng-template #creditOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1486"></lv-tooltip>
  </ng-container>
</ng-template>
