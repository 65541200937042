import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CapExpressedAsDescription, FloorExpressedAsDescripton, ResetSectionSettings } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CurrencyType } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-reset-section-settings',
  templateUrl: './lv-reset-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvResetSectionSettingsComponent implements OnInit {

  @Input() resetSettings: ResetSectionSettings;
  @Input() overrideDefaults: boolean;

  conversionPriceCCYLookup: LvLookupEnum;
  floorExpressedRelativeToLookup: LvLookupEnum;
  capExpressedRelativeToLookup: LvLookupEnum;

  issuerOptionalResetId: string;

  decimalsZero = '0';
  formatZero = 'n0';
  formatThree = '#,###.###';
  decimalsThree = '3';

  constructor() {
    this.conversionPriceCCYLookup = new LvLookupEnum(CurrencyType);
    this.floorExpressedRelativeToLookup = new LvLookupEnum(FloorExpressedAsDescripton);
    this.capExpressedRelativeToLookup = new LvLookupEnum(CapExpressedAsDescription);

    this.issuerOptionalResetId = v4();
    this.overrideDefaults = true;
  }

  ngOnInit() {
  }

  getResetSettingsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
