<lv-section-settings
    [settings]="settings"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="withholdingTaxOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-withholding-tax-section
      [settings]="systemSettings">
    </lv-withholding-tax-section>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-withholding-tax-section
      [settings]="settings"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-withholding-tax-section>
  </ng-template>
</lv-section-settings>

<ng-template #withholdingTaxOverrideSystemDefaultsSettingsTooltip>
  <lv-tooltip dm="dM-1491"></lv-tooltip>
</ng-template>
