<lv-terms-equity-settings
  class="lv-terms-equity-settings"
  [systemDefaults]="settings.system.termsSettings"
  [myDefaults]="settings.my.termsSettings">
</lv-terms-equity-settings>

<lv-flex-box class="lv-settings-footer" direction="row">
  <button kendoButton
    [primary]="true"
    lvId="cancel-equity-terms-settings"
    (click)="onCancel()">
      Cancel
  </button>
  <div class="lv-settings-footer-divider">&nbsp;</div>

  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-equity-terms-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    lvId="save-equity-terms-settings"
    [class.save-settings-btn-disabled]="!isChanged()"
    (click)="onSave()">
      Save
  </button>
</lv-flex-box>
