<lv-loader *ngIf="isLoading"></lv-loader>

<lv-settings-widget class="lv-model-customization"
  data-cy="DM-5449"
  titleText="Model Customization"
  [isLoading]="isLoading"
  [showSettings]="showSettings">
  <ng-container content>

    <div class="lv-model-customization-content" #bondModelCustomizationContent>
      <lv-environment-settings
        [section]="modelCustomizationSection"
        [showControls]="true"
        lvId="lv-model-customization-environment-settings"
        instrumentType="Bond"
        (didChangeEnvironment)="onChangeEnvironment($event)"
        (doSave)="onSaveSection()"
        (doReload)="onReloadSection()">
      </lv-environment-settings>

      <lv-flex-box direction="row" class="lv-model-customization-content-scroll" style="flex: 1; overflow: auto; flex-wrap: wrap;">

        <lv-flex-box
          *ngIf="widgetState.excludeTerms.isExcludeTerms || widgetState.modelType.isModelType"

          [style.min-width]="view.excludeTermsModelTypeMinWidth"
          class="lv-model-customization-exclude-terms-model-type-section"
          [class.lv-model-customization-exclude-terms-model-type-section--column]="view.excludeTermsModelTypeDirection === 'column'"
          [class.lv-model-customization-exclude-terms-model-type-section--row]="view.excludeTermsModelTypeDirection === 'row'">

          <lv-flex-box
            class="lv-model-customization-exclude-terms-section"
            *ngIf="widgetState.excludeTerms.isExcludeTerms">

            <!-- EXCLUDE TERMS -->
            <div
              *ngIf="view.shouldShowExcludeTerms || isCallable || isPuttable" 
              class="lv-label lv-label-field lv-label--title"
              lvId="lv-model-customization-exclude-terms-section">
              EXCLUDE TERMS
            </div>

            <!-- Call -->
            <lv-flex-box
              *ngIf="widgetState.excludeTerms.isCall && isCallable"
              data-cy="DM-734"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="callTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span [attr.averaging-data-tooltip-id]= "'callTooltip'">
              Call
             </span>
                <lv-xl-label [field]="'MC_CALL'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="exCall"
                  type="checkbox"
                  [disabled]="isModelCustomizationDisabled"
                  [attr.id]="view.chCallId"
                  class="k-checkbox"
                  [(ngModel)]="view.model.excludeCall"
                  (change)="onModelCustomizationChange()" />
                <label class="k-checkbox-label"
                  lvId="exCall"
                  [attr.for]="view.chCallId"></label>
              </div>
            </lv-flex-box>

            <!-- Put -->
            <lv-flex-box
              *ngIf="widgetState.excludeTerms.isPut && isPuttable"
              data-cy="DM-735"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="putTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'putTooltip'">
                Put
              </span>
                <lv-xl-label [field]="'MC_PUT'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="exPut"
                  type="checkbox"
                  [attr.id]="view.chPutId"
                  [disabled]="isModelCustomizationDisabled"
                  class="k-checkbox"
                  [(ngModel)]="view.model.excludePut"
                  (change)="onModelCustomizationChange()"/>
                <label class="k-checkbox-label"
                  lvId="exPut"
                  [attr.for]="view.chPutId"></label>
              </div>
            </lv-flex-box>

          </lv-flex-box>

          <lv-flex-box
            *ngIf="widgetState.modelType.isModelType"
            class="lv-model-customization-model-type-section">

            <!-- MODEL TYPE -->
            <div
              *ngIf="view.shouldShowModelType"
              class="lv-label lv-label-field lv-label--title"
              lvId="lv-model-customization-model-type-section">
              MODEL TYPE
            </div>

            <!-- Pricer Version -->
            <lv-flex-box
              *ngIf="widgetState.modelType.isPricerVersion"
              data-cy="DM-1067"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="modelCustomizationPricerVersionTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'modelCustomizationPricerVersionTooltip'">
                Pricer Version
              </span>
              <lv-xl-label [field]="'MC_PRICER_VERSION'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbPricerVersion"
                  [data]="view.pricerVersion.items"
                  [disabled]="isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.model.pricerVersion"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 150
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Credit Model Type -->
            <lv-flex-box
              *ngIf="widgetState.modelType.isCreditModelType"
              data-cy="DM-165"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="creditModelTypeTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'creditModelTypeTooltip'">
                Credit Model Type
              </span>
                <lv-xl-label [field]="'MC_CREDIT_MOD'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbCreditModelType"
                  [data]="view.creditModelTypeLookup.items"
                  [disabled]="isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.model.creditModelSettings.creditModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 150
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>

        <lv-flex-box

          [style.min-width]="view.callAveragingOtherMinWidth"
          class="lv-model-customization-call-averaging-other-section"
          [class.lv-model-customization-call-averaging-other-section--column]="view.excludeTermsModelTypeDirection === 'column'"
          [class.lv-model-customization-call-averaging-other-section--row]="view.excludeTermsModelTypeDirection === 'row'"
          #callAveragingOtherSection>

          <lv-flex-box
          *ngIf="widgetState.callAdjustments.isCallAdjustments && isCallable"
          class="lv-model-customization-call-averaging-section">

            <!-- CALL ADJUSTMENTS -->
            <div
              *ngIf="view.shouldShowCallAdjustments"
              class="lv-label lv-label-field lv-label--title"
              lvId="lv-model-customization-call-adjustments-section">
              CALL ADJUSTMENTS
            </div>

            <!-- Hard Call Model Type -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isHardCallModelType"
              data-cy="DM-171"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="hardCallModelTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span [attr.averaging-data-tooltip-id]= "'hardCallModelTooltip'">
                Call Model Type
              </span>
                <lv-xl-label [field]="'MC_HC_MODEL'"></lv-xl-label>
              </div>
              <div class= "lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  name="caHardCallModelType"
                  [data]="view.hardCallModelType.items"
                  [disabled]="isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.model.callAdjustmentModelSettings.hardCallModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 160
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Hard Call Adjust Factor -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isHardCallAdjustFactor && view.isBarrierModel"
              data-cy="DM-172"
              format="n3"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="hardCallAdjustTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span  [attr.averaging-data-tooltip-id]= "'hardCallModelTooltip'">
                Call Adjust Factor
              </span>
                <lv-xl-label [field]="'MC_HC_ADJUST'"></lv-xl-label>
              </div>
              <div class="lv-label-field">
              <lv-numeric-text-box
                name="caHardCallAdjustFactor"
                [disabled]="isModelCustomizationDisabled || !view.isBarrierModel"
                format="n3"
                [(ngModel)]="view.model.callAdjustmentModelSettings.hardCallAdjustmentFactor"
                (didKeyDownEnter)="updateModelCustomization()"
                (didBlur)="updateModelCustomization()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
              </div>
            </lv-flex-box>

            <!-- Not Callable (Date) -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isNotCallableDate"
              data-cy="DM-183"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="useNotCallableDateTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'useNotCallableDateTooltipTemplate'">
                Not Callable (Date)
              </span>
                <lv-xl-label [field]="'MC_NOT_CALLABLE_DT'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-flex-box">
                <div class="lv-field lv-field--checkbox">
                  <input name="isNotCallableDate"
                    type="checkbox"
                    [attr.id]="view.notCallableId"
                    class="k-checkbox"
                    [(ngModel)]="view.model.isNotCallableDate"
                    (change)="updateModelCustomization()" />
                  <label class="k-checkbox-label"
                    lvId="isNotCallableDate"
                    [attr.for]="view.notCallableId"></label>
                </div>
                <div *ngIf="view.model.isNotCallableDate">
                  <kendo-datepicker
                    name="caNotCallableDate"
                    placeholder=""
                    [disabled]="!view.isNotCallableEnabled"
                    [(ngModel)]="view.model.callAdjustmentModelSettings.notCallableDate"
                    (blur)="onModelCustomizationChange()"
                    (keydown.enter)="onModelCustomizationChange()">
                  </kendo-datepicker>
                </div>
              </div>
            </lv-flex-box>
          </lv-flex-box>

          <lv-flex-box
            *ngIf="widgetState.otherParameters.isOtherParameters"
            class="lv-model-customization-other-section">

            <!-- OTHER PARAMETERS -->
            <div
              *ngIf="view.shouldShowOtherParams"
              class="lv-label lv-label-field lv-label--title"
              lvId="lv-model-customization-other-parameters-section">
              OTHER PARAMETERS
            </div>

            <!-- Expected Life Excl. Default -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isExpectedLifeExcludingDefault"
              data-cy="DM-177"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="expectedLifeTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span  [attr.averaging-data-tooltip-id]= "'expectedLifeTooltip'">
                Expected Life Excl. Default
                </span>
                  <lv-xl-label [field]="'MC_EXP_LIFE'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="expectedLifeExcluding"
                    class="k-checkbox"
                    type="checkbox"
                    [attr.id]="view.chExpectedLifeId"
                    [disabled]="isModelCustomizationDisabled"
                    [(ngModel)]="view.model.expLifeExcludesDefault"
                    (change)="onModelCustomizationChange()" />
                  <label class="k-checkbox-label"
                    lvId="expectedLifeExcluding"
                    [attr.for]="view.chExpectedLifeId"></label>
                </div>
              </lv-flex-box>

            <!-- Maturity Date Override -->
            <lv-flex-box
              data-cy="DM-185"
              *ngIf="widgetState.otherParameters.isMaturityDateOverride"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="maturityDateOverrideTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'maturityDateOverrideTooltip'">
                Maturity Date Override
              </span>
                <lv-xl-label [field]="'MC_MAT_OV'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-flex-box">
                <div class="lv-field lv-field--checkbox">
                  <input name="isMaturityDateOverride"
                    type="checkbox"
                    [attr.id]="view.maturityDateOverrideId"
                    class="k-checkbox"
                    [disabled]="isModelCustomizationDisabled"
                    [(ngModel)]="view.model.isMaturityDateOverride"
                    (change)="onModelCustomizationChange()" />
                  <label class="k-checkbox-label"
                    lvId="isMaturityDateOverride"
                    [attr.for]="view.maturityDateOverrideId"></label>
                  </div>
                <div *ngIf="view.model.isMaturityDateOverride">
                  <kendo-datepicker
                    placeholder=""
                    [disabled]="isModelCustomizationDisabled"
                    [(ngModel)]="view.model.maturityDateOverride"
                    (blur)="onModelCustomizationChange()"
                    (keydown.enter)="onModelCustomizationChange()"
                    name="caMaturityDateOverride">
                  </kendo-datepicker>
                </div>
              </div>
            </lv-flex-box>          
          </lv-flex-box>
        </lv-flex-box>

      </lv-flex-box>
    </div>

  </ng-container>

  <ng-container settings>
    <div
      class="lv-side-panel-link-item"
      lvId="lv-model-customization-display-default-view"
      (click)="onDisplayDefaultView()">
      <span>Display Default View</span>
      <i *ngIf="widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-model-customization-display-custom-view"
      (click)="onDisplayCustomView()">
      <span>Display Custom View</span>
      <i *ngIf="!widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div class="lv-side-panel-link-item"
      lvId="lv-model-customization-edit-custom-view"
      (click)="onEditCustomView()">
      <span>Edit Custom View</span>
    </div>

    <div
      class="lv-side-panel-link-item"
      *ngIf="!widgetState.useDefaultView"
      lvId="lv-model-customization-save-default-view"
      (click)="onSaveAsDefaultView()">
      <span>Save as Default View</span>
    </div>
  </ng-container>
</lv-settings-widget>


<ng-template #creditModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-165"></lv-tooltip>
</ng-template>
<ng-template #equityModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-166"></lv-tooltip>
</ng-template>
<ng-template #underlyingModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-167"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditTooltipTemplate>
  <lv-tooltip dm="dM-168"></lv-tooltip>
</ng-template>
<ng-template #noCreditPointsTooltipTemplate>
  <lv-tooltip dm="dM-169"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditBCTooltipTemplate>
  <lv-tooltip dm="dM-170"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionModeTooltipTemplate>
  <lv-tooltip dm="dM-175"></lv-tooltip>
</ng-template>
<ng-template #pepsParityConventionTooltipTemplate>
  <lv-tooltip dm="dM-176"></lv-tooltip>
</ng-template>
<ng-template #expectedLifeTooltipTemplate>
  <lv-tooltip dm="dM-177"></lv-tooltip>
</ng-template>
<ng-template #exchangeableAsConvertTooltipTemplate>
  <lv-tooltip dm="dM-178"></lv-tooltip>
</ng-template>
<ng-template #defaultOnLowParityTooltipTemplate>
  <lv-tooltip dm="dM-179"></lv-tooltip>
</ng-template>
<ng-template #includeCashInRebateTooltipTemplate>
  <lv-tooltip dm="dM-180"></lv-tooltip>
</ng-template>
<ng-template #callTooltipTemplate>
  <lv-tooltip dm="dM-734"></lv-tooltip>
</ng-template>
<ng-template #putTooltipTemplate>
  <lv-tooltip dm="dM-735"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionTooltipTemplate>
  <lv-tooltip dm="dM-736"></lv-tooltip>
</ng-template>
<ng-template #contigentConversionTooltipTemplate>
  <lv-tooltip dm="dM-740"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceResetTooltipTemplate>
  <lv-tooltip dm="dM-737"></lv-tooltip>
</ng-template>
<ng-template #variableConversionTooltipTemplate>
  <lv-tooltip dm="dM-738"></lv-tooltip>
</ng-template>
<ng-template #forceOptionalResetTooltipTemplate>
  <lv-tooltip dm="dM-181"></lv-tooltip>
</ng-template>
<ng-template #currentConversionAveragingTooltipTemplate>
  <lv-tooltip dm="dM-187"></lv-tooltip>
</ng-template>
<ng-template #currentResetAveragingTooltipTemplate>
  <lv-tooltip dm="dM-188"></lv-tooltip>
</ng-template>
<ng-template #forwardConversionAveragingTooltipTemplate>
  <lv-tooltip dm="dM-189"></lv-tooltip>
</ng-template>
<ng-template #forwardResetAveragingTooltipTemplate>
  <lv-tooltip dm="dM-190"></lv-tooltip>
</ng-template>
<ng-template #fwdDividProtectionAdjstTooltipTemplate>
  <lv-tooltip dm="dM-182"></lv-tooltip>
</ng-template>
<ng-template #maturityDateOverrideTooltipTemplate>
  <lv-tooltip dm="dM-185"></lv-tooltip>
</ng-template>
<ng-template #conversionNoticeGivenTooltipTemplate>
  <lv-tooltip dm="dM-186"></lv-tooltip>
</ng-template>
<ng-template #hardCallAdjustTooltipTemplate>
  <lv-tooltip dm="dM-172"></lv-tooltip>
</ng-template>
<ng-template #hardCallModelTooltipTemplate>
  <lv-tooltip dm="dM-171"></lv-tooltip>
</ng-template>
<ng-template #softCallModelTooltipTemplate>
  <lv-tooltip dm="dM-680"></lv-tooltip>
</ng-template>
<ng-template #softCallAdjustTooltipTemplate>
  <lv-tooltip dm="dM-173"></lv-tooltip>
</ng-template>
<ng-template #useNotCallableDateTooltipTemplate>
  <lv-tooltip dm="dM-183"></lv-tooltip>
</ng-template>
<ng-template #useNotCallableParityTooltipTemplate>
  <lv-tooltip dm="dM-184"></lv-tooltip>
</ng-template>
<ng-template #assumeDvdUntilMaturityTooltipTemplate>
  <lv-tooltip dm="dM-1005"></lv-tooltip>
</ng-template>
<ng-template #modelCustomizationPricerVersionTooltipTemplate>
  <lv-tooltip dm="dM-1067"></lv-tooltip>
</ng-template>