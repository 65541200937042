import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IInstrumentEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/instrument-environment-settings';
import { IPricingEnvironmentOverrides } from '@lv-analytics/models/company-and-user-settings/pricing-environment-overrides';
import { ISelectedEnvrionments } from '@lv-analytics/models/company-and-user-settings/selected-envrionments';
import { LvAnalyticsError } from '@lv-analytics/models/errors';
import { IAswValuationSession } from '@lv-analytics/models/valuation-session/asw-valuation-session';
import { HttpClientBase } from '@lv-core-ui/api/http-client-base';
import { ILvError } from '@lv-core-ui/models/lv-error/base';
import { LvErrorType } from '@lv-core-ui/models/lv-error/error-type';
import { LvDateService } from '@lv-core-ui/services/lv-date/lv-date.service';
import { DayCountDescription } from '@lv-instrument-common/enums/CouponDayCount';
import { FeeFormulaType } from 'src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/enums/asset-swap-fee-formula-type';
import { AswIdentifierType } from 'src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/enums/asset-swap-identifiers-enum';
import { PayLegFrequencyDescription } from 'src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/enums/asset-swap-pay-leg-frequency';
import { PayLegType } from 'src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/enums/asset-swap-pay-leg-type';
import { StrikeTypeDescription } from 'src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/enums/asset-swap-strike-type';
import { AswType } from 'src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/enums/asset-swap-type';

@Injectable({
  providedIn: 'root'
})
export class AswService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '');
   }

  /**
   * Create valuation session if there isn't one.
   * @param {string} pricingEnvironmentCode pricing environment
   * @param {IPricingEnvironmentOverrides} pricingEnvironmentOverrides Override
   * @param {boolean} isOpenedFromExcel is application opened from excel
   * @param {string} leversysLocalId is leversys local identifier.
   * @returns promise based IAswValuationSession
   */
  async createValuationSessionForAsw(
    pricingEnvironmentCode: string = '',
    pricingEnvironmentOverrides: IPricingEnvironmentOverrides = null,
    isOpenedFromExcel: boolean,
    leversysLocalId: string,
    ): Promise<IAswValuationSession> {
      try {
        return {
          leversysLocalId: '',
          instrumentSettings: {} as IInstrumentEnvironmentSettings,
          selectedEnvironments: {} as ISelectedEnvrionments,
          terms: {
            terms: {
              instrumentType: 'Asset Swap',
              name: 'Test Asw',
              shortName: 'Test Asw Short name',
              underlying: 'Underlying' ,
              aswType: AswType.Convertible,
              effectiveDate: new Date(),
              maturityDate: null,
              nominalValue: 1000,
              payLegType: PayLegType.Floating,
              strikeType: StrikeTypeDescription.DiscountedRedemption,
              strikeTypeRedemption: 100.467,
              strikeReferenceDate: null,
              recallSpread: 100,
              conversion: {
                exerciseStartDate: new Date(),
                exerciseEndDate:  new Date(),
                earlyExerciseFeeApplicable: true,
                earlyExerciseFee: 100,
                earlyExerciseEndDate: new Date(),
                feeFormulaType: FeeFormulaType.Decreasing,
                daysInYear: 365,
                maxIntervalDays: 365,
                busDaysToEarlyRepayment: 365
              },
              referenceIrSwap: {
                payLegFrequency: PayLegFrequencyDescription.SemiAnnual,
                payLegDayCount: DayCountDescription.DayCount_30_360,
                interestPaymentCommencementDate: new Date(),
                additionalPaymentAtAswMaturity: 100.588,
              },
              floatingLegSchedule : [{
                date: new Date(),
                value: 10.0625
              },
              {
                date: new Date(),
                value: 10.0625
              },
              {
                date: new Date(),
                value: 10.0625
              }]
             },
            identifiers: [ {
              identifierType: AswIdentifierType.ISIN,
              identifier: 'ISISTEST'
              },
              {
                identifierType: AswIdentifierType.CUSIP,
                identifier: 'CUSIPTEST'
              },
              {
                identifierType: AswIdentifierType.FIGI,
                identifier: 'FIGITEST'
            },]
          },
          sessionId: ''
        }
      }
      catch (error) {
        throw this.handleError(error, this.handleUnauthorizedError);
      }
    }

    /**
   * Create valuation session if there isn't one. 
   * @param {string} pricingEnvironmentCode pricing environment
   * @param {IPricingEnvironmentOverrides} pricingEnvironmentOverrides Override
   * @param {boolean} isOpenedFromExcel is application opened from excel
   * @param {string} leversysLocalId is leversys local identifier.
   * @returns promise based IAswValuationSession
   */
  async loadAswInSession(    
    leversysLocalId: string,
    sessionId: string
    ): Promise<IAswValuationSession> {
      return;
    // try {
    //   const result = await this.postAsync<IAswValuationSession>({         
    //     leversysLocalId: leversysLocalId,
    //     instrumentType: "ASW",
    //     sessionId: sessionId,
    //   }, '/');

    //   if (!result) {
    //     throw new LvAnalyticsError(LvDataMaster.getError('dM-3356',
    //       {
    //       'timezone': 'Valuation Session'
    //       }));
    //   }

    //   return result;
    // }
    // catch (error) {
    //   throw this.handleError(error, this.handleUnauthorizedError);
    // }
  }

   /**
   * Handles unauthorizes error.
   * @param e ILvError object.
   * @returns LvAnalyticsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvAnalyticsError {
    const error = new LvAnalyticsError(e.message);

    if (e.type === LvErrorType.AUTHORIZATION) {
      error.type = e.type;
    }

    return error;
  }
}
