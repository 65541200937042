import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LvConvertibleBondTermsError } from '@lv-convertible-bond/models';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';
import {
  AccretionGrossNet, AccretionType, AccrualMethod, CallMakeWholeType, CallValueType, CouponReferenceTenor, CouponType, DayCount,
  DiscountRateType, ExCouponPeriodType, Frequency, NoticePeriod, PikCouponType, PutValueType, RecoveryUponDefault
} from '@lv-instrument-common/index';
import { BondTermsDocument } from 'src/app/modules/instrument/bond-terms/models';
import { IBondTermsSectionSettings, IBondTermsSettings } from 'src/app/modules/instrument/bond-terms/models/bond-terms-settings';

@Injectable({
  providedIn: 'root'
})
export class BondTermsSettingsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/privateInstruments/bondTermsSettings');
  }

  /**
 * Gets terms settings.
 * @returns IBondTermsSectionSettings object.
 */
  async getBondTermsSettings(): Promise<IBondTermsSectionSettings> {
    try {
      const result = await this.getAsync<IBondTermsSectionSettings>(null, '');

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
 * Resets terms settings.
 * @returns IBondTermsSectionSettings object.
 */
  async resetBondTermsSettings(): Promise<IBondTermsSectionSettings> {
    try {
      const result = await this.getAsync<IBondTermsSectionSettings>(null, '/default');
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Saves terms settings.
   * @param request IBondTermsSectionSettings object.
   */
  async saveBondTermsSettings(request: IBondTermsSectionSettings): Promise<void> {
    try {
      await this.postAsync(request, '/saveTermsSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }


  /**
   * Gets terms settings Excel.
   * @param firstSettlement First settlement.
   * @param maturity Maturity.
   * @returns ConvertibleBondTermsDocument object.
   */
  async createTermsFromSettings(firstSettlement: number, maturity: number,): Promise<BondTermsDocument> {
    try {
      const result = await this.getAsync<BondTermsDocument>({
        firstSettlement: firstSettlement,
        maturity: maturity
      }, '/getTermsSettingsExcel');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  getDefaultBondTermsSettings(): IBondTermsSectionSettings {
    return {
      systemDefaults: {
        accretionSettings: {
          overrideSystemDefaults: false,
          settings: {
            type: AccretionType.UseRedemptionPrice,
            dayCount: DayCount.DayCount_ACT_ACT_ICMA,
            frequency: Frequency.SemiAnnual,
            grossNet: AccretionGrossNet.Gross,
            accretionEndDate: null,
            accretionStartDate: 2
          }
        },
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {
            currencyCode: 'EUR',
            maturingCurrencyCode: 'EUR',
            nominalValue: 100,
            issueValue: 100,
            redemptionValue: 100,
            isPriceAsPar: true,
            isQuotedClean: true,
            issueValueIsPercentOfNominal: true,
            redemptionValueIsPercentOfNominal: true,
            isAccruedUponDefault: false,
            recoveryUponDefault: RecoveryUponDefault.AtPar,
            percCoveredWarrants: 100,
            daysToSettle: 0,
          }
        },
        callSettings: {
          overrideSystemDefaults: false,
          settings: {
            valueTypeRegular: CallValueType.PerOfPar,
            noticePeriod: NoticePeriod.Calendar,
            yieldDayCount: DayCount.DayCount_ACT_ACT_ICMA,
            yieldFrequency: Frequency.SemiAnnual,
            callNoticeDaysMax: null,
            forfeitCoupon: false,
            notice: null
          }
        },
        putSettings: {
          overrideSystemDefaults: false,
          settings: {
            valueType: PutValueType.PerOfPar,
            yieldFrequency: Frequency.Annual,
            keepAccrued: true,
            forfeitCoupon: false,
            notice: null,
            noticePeriod: NoticePeriod.Calendar,
            yieldDaysCount: DayCount.DayCount_ACT_ACT_ICMA
          }
        },
        makeWholeSettings: {
          overrideSystemDefaults: false,
          settings: {
            callMakeWhole: false,
            type: CallMakeWholeType.None,
            discountRateType: DiscountRateType.Spread,
            endDate: null,
            startDate: 4
          }
        },
        couponSettings: {
          overrideSystemDefaults: false,
          settings: {
            type: CouponType.ZeroCoupon,
            dayCount: DayCount.DayCount_ACT_ACT_ICMA,
            frequency: Frequency.SemiAnnual,
            accrualMethod: AccrualMethod.Regular,
            exCouponPeriodType: ExCouponPeriodType.Business,
            exCouponPeriod: 15,
            couponDatesEOM: true,
            accrualEndDate: null,
            accrualStartDate: 3,
            guaranteedStartDate: 5,
            guaranteedStartEndDate: null,
            hasCoPay: false,
            isGuaranteed: false,
            floating: {
              referenceTenor: CouponReferenceTenor.SixMonth,
              multiplier: 1,
              fixingDelay: 0,
              floor: 0
            },
            pik: {
              pikCouponType: PikCouponType.None,
            },
          }
        }
      } as IBondTermsSettings,
      myDefaults: {
        accretionSettings: {
          overrideSystemDefaults: false,
          settings: {
            type: AccretionType.UseRedemptionPrice,
            dayCount: DayCount.DayCount_ACT_ACT_ICMA,
            frequency: Frequency.SemiAnnual,
            grossNet: AccretionGrossNet.Gross,
            accretionEndDate: null,
            accretionStartDate: 2
          }
        },
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {
            currencyCode: 'EUR',
            maturingCurrencyCode: 'EUR',
            nominalValue: 100,
            issueValue: 100,
            redemptionValue: 100,
            isPriceAsPar: true,
            isQuotedClean: true,
            issueValueIsPercentOfNominal: true,
            redemptionValueIsPercentOfNominal: true,
            isAccruedUponDefault: false,
            recoveryUponDefault: RecoveryUponDefault.AtPar,
            percCoveredWarrants: 100,
            daysToSettle: 0,
          }
        },
        callSettings: {
          overrideSystemDefaults: false,
          settings: {
            valueTypeRegular: CallValueType.PerOfPar,
            noticePeriod: NoticePeriod.Calendar,
            yieldDayCount: DayCount.DayCount_ACT_ACT_ICMA,
            yieldFrequency: Frequency.SemiAnnual,
            callNoticeDaysMax: null,
            forfeitCoupon: false,
            notice: null
          }
        },
        putSettings: {
          overrideSystemDefaults: false,
          settings: {
            valueType: PutValueType.PerOfPar,
            yieldFrequency: Frequency.Annual,
            keepAccrued: true,
            forfeitCoupon: false,
            notice: null,
            noticePeriod: NoticePeriod.Calendar,
            yieldDaysCount: DayCount.DayCount_ACT_ACT_ICMA
          }
        },
        makeWholeSettings: {
          overrideSystemDefaults: false,
          settings: {
            callMakeWhole: false,
            type: CallMakeWholeType.None,
            discountRateType: DiscountRateType.Spread,
            endDate: null,
            startDate: 4
          }
        },
        couponSettings: {
          overrideSystemDefaults: false,
          settings: {
            type: CouponType.ZeroCoupon,
            dayCount: DayCount.DayCount_ACT_ACT_ICMA,
            frequency: Frequency.SemiAnnual,
            accrualMethod: AccrualMethod.Regular,
            exCouponPeriodType: ExCouponPeriodType.Business,
            exCouponPeriod: 15,
            couponDatesEOM: true,
            accrualEndDate: null,
            accrualStartDate: 3,
            guaranteedStartDate: 4,
            guaranteedStartEndDate: null,
            hasCoPay: false,
            isGuaranteed: false,
            floating: {
              referenceTenor: CouponReferenceTenor.SixMonth,
              multiplier: 1,
              fixingDelay: 0,
              floor: 0
            },
            pik: {
              pikCouponType: PikCouponType.None,
            },
          }
        }
      } as IBondTermsSettings
    } as IBondTermsSectionSettings
  }
}