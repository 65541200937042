/**
 * Interest rate source description.
 */
export enum InterestRateSourceAswDescription {
    YieldCurve = 'Yield Curve',
    Flat = 'Flat Rate',
    UnderlyingCBData = 'Underlying CB Data',
}

/**
 * Interest rates source.
 */
export enum InterestRateSourceAsw {
    YieldCurve = 'YieldCurve',
    Flat = 'Flat',
    UnderlyingCBData = 'UnderlyingCBData',
}