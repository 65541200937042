<div class="lv-label-field lv-label lv-label--title"
  data-cy="DM-1152"
  showAfter="1500"
  kendoTooltip
  [tooltipTemplate]="environmentsTitleTooltipTemplate"
  filter="span">
    <span>ENVIRONMENTS</span>
  </div>

<div class="lv-environment-setup-settings-table"
  #target>
  <div class="lv-environment-setup-settings-table-row">
    <div class="lv-environment-setup-settings-table-row-cell"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="environmentNameTooltipTemplate"
        filter="label">
      <label class="lv-label">Environment Name</label>
    </div>
    <div class="lv-environment-setup-settings-table-row-cell"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="noteTooltipTemplate"
        filter="label">
      <label class="lv-label">Note</label>
    </div>
    <div class="lv-environment-setup-settings-table-row-cell"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="statusTooltipTemplate"
        filter="label">
      <label class="lv-label">Status</label>
    </div>
    <div class="lv-environment-setup-settings-table-row-cell"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="typeTooltipTemplate"
        filter="label">
      <label class="lv-label">Type</label>
    </div>
  </div>
  <ng-container *ngFor="let item of settings; let i = index">
    <div class="lv-environment-setup-settings-table-row lv-environment-setup-settings-table-row-data"
      [class.lv-environment-setup-settings-table-row-gray]="!(i % 2)"
      [attr.data-id]="item.id">
      <div class="lv-environment-setup-settings-table-row-cell lv-environment-setup-settings-table-row-data-cell">
        <label class="lv-label lv-environment-setup-settings-name">{{item.name}}</label>
      </div>
      <div class="lv-environment-setup-settings-table-row-cell lv-environment-setup-settings-table-row-data-cell">
        <label class="lv-label">{{item.note}}</label>
      </div>
      <div class="lv-environment-setup-settings-table-row-cell lv-environment-setup-settings-table-row-data-cell">
        <label class="lv-label">{{ getStatusDescription(item.isDisabled) }}</label>
      </div>
      <div class="lv-environment-setup-settings-table-row-cell lv-environment-setup-settings-table-row-data-cell">
        <label class="lv-label">{{item.scopeType}}</label>
      </div>
    </div>
  </ng-container>
</div>

<kendo-contextmenu
  [target]="target"
  [items]="contextMenuItems"
  filter=".lv-environment-setup-settings-table-row-data"
  (select)="onSelect($event)">
</kendo-contextmenu>

<ng-template #environmentNameTooltipTemplate>
  <lv-tooltip dm="dM-1145"></lv-tooltip>
</ng-template>
<ng-template #noteTooltipTemplate>
  <lv-tooltip dm="dM-1146"></lv-tooltip>
</ng-template>
<ng-template #statusTooltipTemplate>
  <lv-tooltip dm="dM-1147"></lv-tooltip>
</ng-template>
<ng-template #typeTooltipTemplate>
  <lv-tooltip dm="dM-1148"></lv-tooltip>
</ng-template>
<ng-template #environmentsTitleTooltipTemplate>
  <lv-tooltip dm="dM-1152"></lv-tooltip>
</ng-template>
