<lv-flex-box class="lv-convertible-bond-terms-terms-general">
  <lv-flex-box direction="row" data-cy="DM-5437">
    <lv-flex-box class="lv-convertible-bond-terms-general-issue">
      <lv-flex-box direction="row">
        <div
          class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="instrumentNameSourceTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'instrumentNameSourceTooltip'">
            Instrument Type
          </span>
        </div>
        <div class="lv-terms-general-output">
          {{ instrumentType }}
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-334">
        <div
          class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="instrumentNameSourceTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'instrumentNameSourceTooltip'">
            Instrument Name
          </span>
        </div>
        <div class="lv-input-field lv-input-field--full">
          <lv-text-box
            [(ngModel)]="model.name"
            class="lv-terms-general-instrument"
            (didKeyDownEnter)="doModelChange('other')"
            (didBlur)="doModelChange('other')"
            name="instrument-name-general">
          </lv-text-box>
        </div>
        <ng-template #lvsConvertibleName>
          <div class="lv-terms-general-output">
            {{ model.name }}
          </div>
        </ng-template>
      </lv-flex-box>
      <lv-flex-box direction="row">
        <lv-flex-box class="lv-general-terms-first-col">
          <lv-flex-box direction="row" data-cy="DM-681">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="instrumentShortNameSourceTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'instrumentShortNameSourceTooltip'">
                Instrument Short Name
              </span>
            </div>
            <div
              class="lv-input-field lv-input-field--full">
              <lv-text-box
                class="lv-terms-general-instrument-short"
                [(ngModel)]="model.shortName"
                (didKeyDownEnter)="doModelChange()"
                (didBlur)="doModelChange()"
                name="short-name">
              </lv-text-box>
            </div>
            <ng-template #lvsConvertibleShortname>
              <div class="lv-terms-general-output">
                {{ model.shortName }}
              </div>
            </ng-template>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-348">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="issuerNameSourceTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'issuerNameSourceTooltip'">
                Issuer Name
              </span>
            </div>
            <div
              class="lv-input-field lv-input-field--full">
              <lv-text-box
                class="lv-terms-general-instrument-issuer"
                [(ngModel)]="model.issuerName"
                (didBlur)="doModelChange()"
                (didKeyDownEnter)="doModelChange()"
                name="issuer-name-general">
              </lv-text-box>
            </div>
            <ng-template #lvsConvertibleIssuername>
              <div class="lv-terms-general-output">
                {{ model.issuerName }}
              </div>
            </ng-template>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-379">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="CCYSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'CCYSourceTooltip'"> CCY </span>
              <lv-xl-label data-cy="CCY" [field]="'CCY'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-dropdownlist
                lvId="lv-general-ccy"
                class="lv-terms-general-small-input"
                name="ccy"
                [data]="currencyLookup.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [(ngModel)]="model.currencyCode"
                (ngModelChange)="onCCYChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-989">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="maturityCCYSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'maturityCCYSourceTooltip'">
                Maturity CCY
              </span>
              <lv-xl-label [field]="'MAT_CCY'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-dropdownlist
                lvId="lv-general-maturity-ccy"
                class="lv-terms-general-small-input"
                name="ccy"
                [data]="currencyLookup.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [(ngModel)]="model.maturingCurrencyCode"
                (ngModelChange)="doModelChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-370">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="firstSettlementSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'firstSettlementSourceTooltip'">
                First Settlement
              </span>
              <lv-xl-label [field]="'FRST_STL'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-datepicker
                [(ngModel)]="model.firstSettlementDate"
                (blur)="onFirstSettlementDateChange()"
                name="first-settlement">
              </kendo-datepicker>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-371">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="maturityDateSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'maturityDateSourceTooltip'">
                Maturity Date
              </span>
              <lv-xl-label data-cy="MAT" [field]="'MAT'"></lv-xl-label>
            </div>
            <div class="lv-input-field" *ngIf="!model.isPerpetual">
              <kendo-datepicker
                placeholder=""
                [disabled]="model.isPerpetual"
                [(ngModel)]="model.maturityDate"
                (blur)="onMaturityDateChange()"
                [attr.aria-valuetext]="dateFormated"
                name="general-maturity-date">
              </kendo-datepicker>
            </div>
            <div
              data-cy="DM-990"
              class="lv-field lv-field--checkbox lv-terms-general-checkbox"
              *ngIf="!model.isPerpetual"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="perpetualTooltipTemplate"
              filter="label:not(lv-xl-label label)">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="maturityDatePerpetualCheckboxId"
                [(ngModel)]="model.isPerpetual"
                (ngModelChange)="doModelChange('DM-990')"
                name="maturity-date-perpetual"/>
              <label
                class="k-checkbox-label xl-label"
                lvId="perpetual-general"
                [attr.for]="maturityDatePerpetualCheckboxId">
                Perpetual
              </label>
              <lv-xl-label [field]="'PERP'"></lv-xl-label>
            </div>
            <div
              data-cy="DM-990"
              class="lv-field lv-field--checkbox"
              *ngIf="model.isPerpetual"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="perpetualTooltipTemplate"
              filter="label:not(lv-xl-label label)">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="maturityDatePerpetualCheckboxId"
                [(ngModel)]="model.isPerpetual"
                (ngModelChange)="doModelChange('DM-990')"
                name="maturity-date-perpetual"/>
              <label
                class="k-checkbox-label xl-label"
                lvId="perpetual-general"
                [attr.for]="maturityDatePerpetualCheckboxId">
                Perpetual
              </label>
              <lv-xl-label [field]="'PERP'"></lv-xl-label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-385">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="nominalValueSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'nominalValueSourceTooltip'">
                Nominal Value
              </span>
              <lv-xl-label [field]="'NOM'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                [format]="formatFour"
                [decimals]="decimalsFour"
                [(ngModel)]="model.nominalValue"
                (didBlur)="doModelChange('DM-385')"
                (didKeyDownEnter)="doModelChange('DM-385')"
                name="nominal-value-general">
              </lv-numeric-text-box>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-383">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="issueValueSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'issueValueSourceTooltip'">
                Issue Price
              </span>
              <lv-xl-label [field]="'ISSUE_PX'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                [format]="formatTwo"
                [decimals]="decimalsTwo"
                [(ngModel)]="model.issueValue"
                (didBlur)="doModelChange()"
                (didKeyDownEnter)="doModelChange()"
                name="issue-value-general">
              </lv-numeric-text-box>
            </div>
            <div
              data-cy="DM-991"
              class="lv-field lv-field--checkbox lv-terms-general-checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="issueValueNominalCheckboxId"
                [(ngModel)]="model.issuePriceAsPar"
                (ngModelChange)="doModelChange()"
                name="issue-value-nominal"/>
              <label
                class="k-checkbox-label xl-label"
                lvId="percentOfParIssueValueGeneral"
                [attr.for]="issueValueNominalCheckboxId">
                % of Par
              </label>
              <lv-xl-label [field]="'ISSUE_PX_AS_PAR'"></lv-xl-label>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="!isPerpetual"
            data-cy="DM-384"
            direction="row">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="redemptionValueSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'redemptionValueSourceTooltip'">
                Redemption Value
              </span>
              <lv-xl-label [field]="'REDEM_PX'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                [format]="formatTwo"
                [decimals]="decimalsTwo"
                [(ngModel)]="model.redemptionValue"
                (didBlur)="doModelChange()"
                (didKeyDownEnter)="doModelChange()"
                name="redemption-value">
              </lv-numeric-text-box>
            </div>
            <div
              data-cy="DM-992"
              class="lv-field lv-field--checkbox lv-terms-general-checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="issueValueRedemptionCheckboxId"
                [(ngModel)]="model.redemptionValueAsPar"
                (ngModelChange)="doModelChange()"
                name="issue-value-redemption"/>
              <label
                class="k-checkbox-label xl-label"
                lvId="percentOfParRedemptionGeneral"
                [attr.for]="issueValueRedemptionCheckboxId">
                % of Par
              </label>
              <lv-xl-label [field]="'REDEM_PX_AS_PAR'"></lv-xl-label>
            </div>
          </lv-flex-box>
        </lv-flex-box>
        <lv-flex-box class="lv-general-terms-second-col">
          <lv-flex-box direction="row">
            <div class="lv-label lv-label-field">&nbsp;</div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-374">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="quotedSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'quotedSourceTooltip'">
                Quoted
              </span>
              <lv-xl-label [field]="'QUOTED'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-dropdownlist
                lvId="lv-general-quoted"
                name="quoted"
                [data]="isQuotedCleanLookup.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [(ngModel)]="quotedValue"
                (valueChange)="quotedValueChange($event)">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-382">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="priceAsParSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'priceAsParSourceTooltip'">
                Price as Par
              </span>
              <lv-xl-label [field]="'PRICED_AS_PAR'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="priceAsParCheckboxId"
                [(ngModel)]="model.isPriceAsPar"
                (ngModelChange)="onPriceAsParChange()"
                name="price-as-par"/>
              <label
                class="k-checkbox-label"
                lvId="priceAsParGeneral"
                [attr.for]="priceAsParCheckboxId">
              </label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-998">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="accruedUponDefaultSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'accruedUponDefaultSourceTooltip'">
                Accrued Upon Default
              </span>
              <lv-xl-label [field]="'ACCR_DEFAULT'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="accruedUponDefaultCheckboxId"
                [(ngModel)]="model.isAccruedUponDefault"
                (ngModelChange)="doModelChange()"
                name="accrued-upon-default"/>
              <label
                class="k-checkbox-label"
                lvId="accruedUpon"
                [attr.for]="accruedUponDefaultCheckboxId">
              </label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-997">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="recoveryUponDefaultSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'recoveryUponDefaultSourceTooltip'">
                Recovery Upon Default
              </span>
              <lv-xl-label [field]="'RCVR_DEFAULT'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist
                lvId="lv-general-recovery"
                name="underlying-ccy"
                [data]="recoveryUponDefaultLookup.items"
                valueField="id"
                textField="text"
                valuePrimitive="true"
                [popupSettings]="{ width: 128 }"
                [(ngModel)]="model.recoveryUponDefault"
                (ngModelChange)="doModelChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-402">
            <div
              class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="sinkableSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'SinkableSourceTooltip'">
                Sinkable
              </span>
              <lv-xl-label [field]="'SINKABLE'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="sinkableCheckboxId"
                [(ngModel)]="model.sinkable"
                (ngModelChange)="doModelChange()"
                name="sinkable"/>
              <label
                lvId="sinkableCheckboxGeneral"
                class="k-checkbox-label"
                [attr.for]="sinkableCheckboxId"
              ></label>
            </div>
          </lv-flex-box>
        </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box class="lv-bottom-sections lv-bond-general-bottom-section" direction="row">
    <lv-flex-box
      data-cy="DM-1002"
      *ngIf="model.sinkable"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="sinkingScheduleTooltipTemplate"
      filter="lv-fieldset-panel">
      <lv-fieldset-panel legend="SINKING SCHEDULE">
        <lv-flex-box direction="row">
          <lv-bond-general-sinking-schedule
            [sinkingSchedule]="model.sinkingFundSchedule"
            (didSetSchedule)="doSetSchedule($event)">
          </lv-bond-general-sinking-schedule>
        </lv-flex-box>
      </lv-fieldset-panel>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #sinkingScheduleTooltipTemplate>
  <lv-tooltip dm="dM-1002"></lv-tooltip>
</ng-template>
<ng-template #instrumentNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-334"></lv-tooltip>
</ng-template>
<ng-template #instrumentShortNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-681"></lv-tooltip>
</ng-template>
<ng-template #issuerNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-348"></lv-tooltip>
</ng-template>
<ng-template #issuerNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-348"></lv-tooltip>
</ng-template>
<ng-template #underlyingNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-1670"></lv-tooltip>
</ng-template>
<ng-template #convertibleTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-335"></lv-tooltip>
</ng-template>
<ng-template #convertibleTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-335"></lv-tooltip>
</ng-template>
<ng-template #CCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-379"></lv-tooltip>
</ng-template>
<ng-template #underlyingCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-380"></lv-tooltip>
</ng-template>
<ng-template #maturityCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-989"></lv-tooltip>
</ng-template>
<ng-template #firstSettlementSourceTooltipTemplate>
  <lv-tooltip dm="dM-370"></lv-tooltip>
</ng-template>
<ng-template #maturityDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-371"></lv-tooltip>
</ng-template>
<ng-template #fiscalYearStartDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-996"></lv-tooltip>
</ng-template>
<ng-template #exchangeableTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-999"></lv-tooltip>
</ng-template>
<ng-template #percentOfPledgedSharesSourceTooltipTemplate>
  <lv-tooltip dm="dM-401"></lv-tooltip>
</ng-template>
<ng-template #statusSourceTooltipTemplate>
  <lv-tooltip dm="dM-1403"></lv-tooltip>
</ng-template>
<ng-template #statusSourceTooltipTemplate>
  <lv-tooltip dm="dM-1403"></lv-tooltip>
</ng-template>
<ng-template #nominalValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-385"></lv-tooltip>
</ng-template>
<ng-template #issueValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-383"></lv-tooltip>
</ng-template>
<ng-template #redemptionValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-384"></lv-tooltip>
</ng-template>
<ng-template #quotedSourceTooltipTemplate>
  <lv-tooltip dm="dM-374"></lv-tooltip>
</ng-template>
<ng-template #priceAsParSourceTooltipTemplate>
  <lv-tooltip dm="dM-382"></lv-tooltip>
</ng-template>
<ng-template #accruedUponDefaultSourceTooltipTemplate>
  <lv-tooltip dm="dM-998"></lv-tooltip>
</ng-template>
<ng-template #recoveryUponDefaultSourceTooltipTemplate>
  <lv-tooltip dm="dM-997"></lv-tooltip>
</ng-template>
<ng-template #percentageOfCoveredWarrantsSourceTooltipTemplate>
  <lv-tooltip dm="dM-1001"></lv-tooltip>
</ng-template>
<ng-template #sinkableSourceTooltipTemplate>
  <lv-tooltip dm="dM-402"></lv-tooltip>
</ng-template>
<ng-template #fxQuantoSourceTooltipTemplate>
  <lv-tooltip dm="dM-994"></lv-tooltip>
</ng-template>
<ng-template #undCCYLinkedSourceTooltipTemplate>
  <lv-tooltip dm="dM-995"></lv-tooltip>
</ng-template>
<ng-template #premiumLabelSourceTooltipTemplate>
  <lv-tooltip dm="dM-357"></lv-tooltip>
</ng-template>
<ng-template #couponPriceRangeSourceTooltiTemplate>
  <lv-tooltip dm="dM-1304"></lv-tooltip>
</ng-template>
<ng-template #issuePricePRSourceTooltipTemplate>
  <lv-tooltip dm="dM-1305"></lv-tooltip>
</ng-template>
<ng-template #redemptionValuePRSourceTooltipTemplate>
  <lv-tooltip dm="dM-1306"></lv-tooltip>
</ng-template>
<ng-template #issueYieldPRSourceTooltipTemplate>
  <lv-tooltip dm="dM-358"></lv-tooltip>
</ng-template>
<ng-template #stockRefPRSourceTooltipTemplate>
  <lv-tooltip dm="dM-360"></lv-tooltip>
</ng-template>
<ng-template #countryTooltipTemplate>
  <lv-tooltip dm="dM-349"></lv-tooltip>
</ng-template>
<ng-template #riskCountryTooltipTemplate>
  <lv-tooltip dm="dM-350"></lv-tooltip>
</ng-template>
<ng-template #issueSeriesTooltipTemplate>
  <lv-tooltip dm="dM-354"></lv-tooltip>
</ng-template>
<ng-template #daystoSettleTooltipTemplate>
  <lv-tooltip dm="dM-993"></lv-tooltip>
</ng-template>
<ng-template #exchangeTooltipTemplate>
  <lv-tooltip dm="dM-1287"></lv-tooltip>
</ng-template>
<ng-template #underlyingTypeTooltipTemplate>
  <lv-tooltip dm="dM-1288"></lv-tooltip>
</ng-template>
<ng-template #fXPrevailingRateTooltipTemplate>
  <lv-tooltip dm="dM-1292"></lv-tooltip>
</ng-template>
<ng-template #fXTimeTooltipTemplate>
  <lv-tooltip dm="dM-1293"></lv-tooltip>
</ng-template>
<ng-template #fXTimeZoneTooltipTemplate>
  <lv-tooltip dm="dM-1294"></lv-tooltip>
</ng-template>
<ng-template #underlyingMICTooltipTemplate>
  <lv-tooltip dm="dM-346"></lv-tooltip>
</ng-template>
<ng-template #fXRatePriceTypeTooltipTemplate>
  <lv-tooltip dm="dM-1295"></lv-tooltip>
</ng-template>
<ng-template #fXSourceTooltipTemplate>
  <lv-tooltip dm="dM-1296"></lv-tooltip>
</ng-template>
<ng-template #fXPageTooltipTemplate>
  <lv-tooltip dm="dM-1297"></lv-tooltip>
</ng-template>
<ng-template #perpetualTooltipTemplate>
  <lv-tooltip dm="dM-990"></lv-tooltip>
</ng-template>
<ng-template #instrumentStatusTooltipTemplate>
  <lv-tooltip dm="dM-1286"></lv-tooltip>
</ng-template>

