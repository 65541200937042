import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IEquitySettings } from '../../../models/equity-terms/equity-settings';
import { v4 } from 'uuid';

@Component({
  selector: 'app-lv-equity-side-panel-modal',
  templateUrl: './lv-equity-side-panel-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LvEquitySidePanelModalComponent implements OnInit {
  @Input() equitySettings: IEquitySettings;

  @Output() doCancel: EventEmitter<void>;
  @Output() doSave: EventEmitter<IEquitySettings>;

  syncAllEquityDataCheckboxId: string;
  syncAllDataCheckboxValue: boolean;

  constructor() {
    this.equitySettings = {} as IEquitySettings;
    this.syncAllEquityDataCheckboxId = v4();
    this.syncAllDataCheckboxValue = false;

    this.doCancel = new EventEmitter<void>();
    this.doSave = new EventEmitter<IEquitySettings>();
  }
  ngOnInit(): void {
    this.syncAllDataCheckboxValue = this.equitySettings.syncAllEquityData;
  }

  onCancel() {
    this.doCancel.emit();
  }

  onSave() {
    this.equitySettings.syncAllEquityData = this.syncAllDataCheckboxValue;
    this.doSave.emit(this.equitySettings);
  }
}
