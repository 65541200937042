import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IAccretionSectionSettings, IBaseTermsSettings } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { AccretionGrossNet, AccretionTypeDesciption, DayCountDescription, FrequencyDescription } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-accretion-section-settings',
  templateUrl: './lv-accretion-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvAccretionSectionSettingsComponent implements OnInit {

  @Input() settings: IBaseTermsSettings<IAccretionSectionSettings>;
  @Input() overrideDefaults: boolean;

  decimalsZero = '0';
  formatZero = 'n0';

  accretionTypeLookup: LvLookupEnum;
  dayCountLookup: LvLookupEnum;
  frequencyLookup: LvLookupEnum;
  grossNetLookup: LvLookupEnum;

  @HostBinding('class.lv-flex-box--column')
  get isFlexComponent() {
    return true;
  }

  constructor() {
    this.accretionTypeLookup = new LvLookupEnum(AccretionTypeDesciption);
    this.dayCountLookup = new LvLookupEnum(DayCountDescription);
    this.frequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.grossNetLookup = new LvLookupEnum(AccretionGrossNet);
    this.overrideDefaults = true;
  }

  ngOnInit() { 
  }

  getAccretionTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
