import { DiscountRateType } from './enums/DiscountRateType';
import { TakeoverProtectionRatchetMatrixItem } from './TakeoverProtectionRatchetMatrixItem';
import { CallMakeWholeType } from './enums/CallEnums';
import { RatchetMatrixOfferValueType } from './enums/RatchetMatrixOfferValueType';
import { RatchetMatrixCompensationValueType } from './enums/RatchetMatrixCompensationValueType';

export class CallMakeWholeData {
    type: CallMakeWholeType;
    startDate: Date;
    endDate: Date;
    initialPremium: number;
    couponsIncludedUntil: Date;
    discountRateType: DiscountRateType;
    discountSpread: number;
    ratchetMatrixMembers: TakeoverProtectionRatchetMatrixItem[];
    ratchetMatrixOfferValueType: RatchetMatrixOfferValueType;
    ratchetMatrixCompensationValueType: RatchetMatrixCompensationValueType;
}

