<lv-settings-widget class="lv-outputs"
  data-cy="DM-4573"
  titleText="Outputs"
  [showSettings]="showSettings"
  [isLoading]="isLoading">
  <ng-container content>
    <kendo-tabstrip
    class="lv-outputs-tabstrip"
    #tabStrip>
  
    <kendo-tabstrip-tab
      cssClass="DM-1203"
       title="Outputs"
       keepTabContent="true"
       *ngIf="isOutputsVisible">
        <ng-template kendoTabContent>
          <ng-template
            [ngTemplateOutlet]="outputs"
            *ngIf="isOutputsVisible">
          </ng-template>
        </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      cssClass="DM-4575"
      title="Tabular Outputs"
      keepTabContent="true"
      *ngIf="isTabularOutputsVisible">
      <ng-template kendoTabContent>

        <lv-tabular-outputs
          data-cy="DM-4575"
          *ngIf="isTabularOutputsVisible"
          [tabularOutputsContent]="view.tabularOutputs"
          [tabularTooltips]="tooltipTemplate"
          [areTenorTabularOutputsVisible]="areTenorTabularOutputsVisible"
          [showAsPriceAsPar]="view.showAsPriceAsParVisible">
        </lv-tabular-outputs>

        </ng-template>
      </kendo-tabstrip-tab>
  
    </kendo-tabstrip>
  </ng-container>

  <ng-container settings>

    <div *ngIf="view.showAsPriceAsParVisible"
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-outputs-show-as-percent-of-par"
      (click)="onShowAsPercentOfPar()">
      <span>Show as Percent of Par</span>
      <i *ngIf="this.view.showAsPriceAsPar" class="k-icon k-i-check"></i>
    </div>

     <div *ngIf="isPricingValid || isBond"
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-outputs-send-valuation-query"
      (click)="onShowSendValuationQueryDialog()">
      <span>Send Valuation Query</span>
    </div>

    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item"
      lvId="lv-outputs-display-default-view"
      (click)="onDisplayDefaultView()">
      <span>Display Default View</span>
      <i *ngIf="view.configuration.useDefault"
        class="k-icon k-i-check"></i>
    </div>

    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-outputs-display-custom-view"
      (click)="onDisplayCustomView()">
      <span>Display Custom View</span>
      <i *ngIf="!view.configuration.useDefault"
        class="k-icon k-i-check"></i>
    </div>

    <div class="lv-side-panel-link-item"
      lvId="lv-outputs-edit-custom-view"
      (click)="onEditCustomView()">
      <span>{{editWidgetButtonText}}</span>
    </div>

    <div
      class="lv-side-panel-link-item"
      *ngIf="!view.configuration.useDefault"
      lvId="lv-tools-edit-custom-view"
      (click)="onSaveAsDefaultView()">
      <span>{{saveWidgetButtonText}}</span>
    </div>

  </ng-container>

</lv-settings-widget>

<ng-template #tooltipTemplate let-anchor>
  <!-- Standard Convertible Outputs -->
  <ng-container *ngIf="getTootlipId(anchor, 'impliedVolatility')">
    <lv-tooltip dm="dM-478"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="getTootlipId(anchor, 'impliedCredit')">
    <lv-tooltip dm="dM-479"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'theoreticalValue')">
    <lv-tooltip dm="dM-480"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'percentOfTheory')">
    <lv-tooltip dm="dM-482"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cheapness')">
    <lv-tooltip dm="dM-483"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cheapnessPercent')">
    <lv-tooltip dm="dM-484"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'expectedLife')">
    <lv-tooltip dm="dM-523"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cheapnessPerYear')">
    <lv-tooltip dm="dM-485"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cheapnessPerExpLife')">
    <lv-tooltip dm="dM-486"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'forwardUnderlyingLevel')">
    <lv-tooltip dm="dM-531"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'parity')">
    <lv-tooltip dm="dM-487"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'parityPremium')">
    <lv-tooltip dm="dM-488"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'parityPremiumPercent')">
    <lv-tooltip dm="dM-489"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'effParityPremiumPercent')">
    <lv-tooltip dm="dM-490"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'parityPremiumClean')">
    <lv-tooltip dm="dM-491"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'parityPremiumCleanPercent')">
    <lv-tooltip dm="dM-492"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'bondValue')">
    <lv-tooltip dm="dM-495"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'bondPremium')">
    <lv-tooltip dm="dM-496"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'bondPremiumPercent')">
    <lv-tooltip dm="dM-497"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'currentNominalValue')">
    <lv-tooltip dm="dM-501"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'riskFreeRate')">
    <lv-tooltip dm="dM-529"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'redemptionValue')">
    <lv-tooltip dm="dM-500"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'accretingYield')">
    <lv-tooltip dm="dM-499"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'currentYield')">
    <lv-tooltip dm="dM-502"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'yieldToMaturity')">
    <lv-tooltip dm="dM-503"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'yieldToCall')">
    <lv-tooltip dm="dM-504"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'yieldToPut')">
    <lv-tooltip dm="dM-505"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'yieldToWorst')">
    <lv-tooltip dm="dM-506"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'yieldToOptimal')">
    <lv-tooltip dm="dM-507"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'divYieldEst')">
    <lv-tooltip dm="dM-508"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'breakeven')">
    <lv-tooltip dm="dM-524"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'incomePickup')">
    <lv-tooltip dm="dM-525"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'zSpreadMaturity')">
    <lv-tooltip dm="dM-511"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'zSpreadOptimal')">
    <lv-tooltip dm="dM-4551"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'zSpreadCall')">
    <lv-tooltip dm="dM-4552"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'zSpreadWorst')">
    <lv-tooltip dm="dM-4550"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'zSpreadNextPut')">
    <lv-tooltip dm="dM-512"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'aSWSpreadMaturity')">
    <lv-tooltip dm="dM-513"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'aSWSpreadNextPut')">
    <lv-tooltip dm="dM-514"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'netParity')">
    <lv-tooltip dm="dM-493"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'accruedDays')">
    <lv-tooltip dm="dM-510"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'netParityPremium')">
    <lv-tooltip dm="dM-494"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'accretedValue')">
    <lv-tooltip dm="dM-498"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'lastFixDate')">
    <lv-tooltip dm="dM-515"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'nextFixDate')">
    <lv-tooltip dm="dM-516"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'modConvexity')">
    <lv-tooltip dm="dM-519"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'modDuration')">
    <lv-tooltip dm="dM-517"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'effDuration')">
    <lv-tooltip dm="dM-518"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'effConvexity')">
    <lv-tooltip dm="dM-520"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'accruedInterest')">
    <lv-tooltip dm="dM-509"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'yearsToCall')">
    <lv-tooltip dm="dM-522"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'yearsToPut')">
    <lv-tooltip dm="dM-521"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'currentEffectiveCallTrigger')">
    <lv-tooltip dm="dM-526"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'riskVolatility')">
    <lv-tooltip dm="dM-530"></lv-tooltip>
  </ng-container>  
  <!-- Outputs for Mandatory Convertibles (PEPS) -->
  <ng-container  *ngIf="getTootlipId(anchor, 'upImpVol')">
    <lv-tooltip dm="dM-421"></lv-tooltip>
  </ng-container> 
  <ng-container  *ngIf="getTootlipId(anchor, 'dnImpVol')">
    <lv-tooltip dm="dM-422"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'totalSkew')">
    <lv-tooltip dm="dM-427"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'skewPerThen')">
    <lv-tooltip dm="dM-428"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'lowerCall')">
    <lv-tooltip dm="dM-423"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'higherCall')">
    <lv-tooltip dm="dM-424"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'fwdStock')">
    <lv-tooltip dm="dM-425"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'carryValue')">
    <lv-tooltip dm="dM-426"></lv-tooltip>
  </ng-container> 
  <!-- Sensitivities (Greeks) -->
  <ng-container  *ngIf="getTootlipId(anchor, 'delta')">
    <lv-tooltip dm="dM-26"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'deltaImplVol')">
    <lv-tooltip dm="dM-29"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'deltaImplSpread')">
    <lv-tooltip dm="dM-30"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'deltaShares')">
    <lv-tooltip dm="dM-27"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'deltaSharesImplSpread')">
    <lv-tooltip dm="dM-461"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'deltaSharesImplVol')">
    <lv-tooltip dm="dM-444"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'fXDelta')">
    <lv-tooltip dm="dM-28"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'deltaFXImplVol')">
    <lv-tooltip dm="dM-445"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'deltaFXImplSpread')">
    <lv-tooltip dm="dM-462"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'eqSensitivity')">
    <lv-tooltip dm="dM-429"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'eqSensitivityImplSpread')">
    <lv-tooltip dm="dM-463"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'eqSensitivityImplVol')">
    <lv-tooltip dm="dM-446"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'gamma')">
    <lv-tooltip dm="dM-430"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'gammaImplVol')">
    <lv-tooltip dm="dM-447"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'gammaImplSpread')">
    <lv-tooltip dm="dM-464"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'gammaShares')">
    <lv-tooltip dm="dM-431"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'gammaSharesImplSpread')">
    <lv-tooltip dm="dM-465"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'gammaSharesImplVol')">
    <lv-tooltip dm="dM-448"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'vega')">
    <lv-tooltip dm="dM-432"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'vegaImplSpread')">
    <lv-tooltip dm="dM-466"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'vegaImplVol')">
    <lv-tooltip dm="dM-449"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'volga')">
    <lv-tooltip dm="dM-433"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'volgaImplSpread')">
    <lv-tooltip dm="dM-467"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'volgaImplVol')">
    <lv-tooltip dm="dM-450"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'vanna')">
    <lv-tooltip dm="dM-434"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'vannaImplSpread')">
    <lv-tooltip dm="dM-468"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'vannaImplVol')">
    <lv-tooltip dm="dM-451"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'theta')">
    <lv-tooltip dm="dM-435"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'thetaImplSpread')">
    <lv-tooltip dm="dM-469"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'thetaImplVol')">
    <lv-tooltip dm="dM-452"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'rho')">
    <lv-tooltip dm="dM-436"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'rhoImplVol')">
    <lv-tooltip dm="dM-453"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'rhoImplSpread')">
    <lv-tooltip dm="dM-470"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cSSensIss')">
    <lv-tooltip dm="dM-438"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cSSensIssImplSpread')">
    <lv-tooltip dm="dM-472"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cSSensIssImplVol')">
    <lv-tooltip dm="dM-455"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'creditVega')">
    <lv-tooltip dm="dM-440"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'creditVegaImplVol')">
    <lv-tooltip dm="dM-457"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'creditVegaImplSpread')">
    <lv-tooltip dm="dM-474"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'rhoUnd')">
    <lv-tooltip dm="dM-437"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'rhoUndImplVol')">
    <lv-tooltip dm="dM-454"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'rhoUndImplSpread')">
    <lv-tooltip dm="dM-471"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cSSens')">
    <lv-tooltip dm="dM-439"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cSSensImplSpread')">
    <lv-tooltip dm="dM-473"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'cSSensImplVol')">
    <lv-tooltip dm="dM-456"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'stockBorrowSens')">
    <lv-tooltip dm="dM-442"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'stockBorrowSensImplVol')">
    <lv-tooltip dm="dM-459"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'stockBorrowSensImplSpread')">
    <lv-tooltip dm="dM-476"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'dividendSens')">
    <lv-tooltip dm="dM-441"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'dividendSensImplVol')">
    <lv-tooltip dm="dM-458"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'dividendSensImplSpread')">
    <lv-tooltip dm="dM-475"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'recoveryRateSens')">
    <lv-tooltip dm="dM-443"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'recoveryRateSensImplVol')">
    <lv-tooltip dm="dM-460"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'recoveryRateSensImplSpread')">
    <lv-tooltip dm="dM-477"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'remainingMW')">
    <lv-tooltip dm="dM-528"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'probBreachCallTrigger')">
    <lv-tooltip dm="dM-527"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'conversionRatioProjected')">
    <lv-tooltip dm="dM-532"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'conversionRebateProjected')">
    <lv-tooltip dm="dM-533"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'ratchetMatrixMultiple')">
    <lv-tooltip dm="dM-534"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'riskyDiscountFactor')">
    <lv-tooltip dm="dM-4579"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'riskFreeDiscountFactor')">
    <lv-tooltip dm="dM-4580"></lv-tooltip>
  </ng-container>
  <ng-container  *ngIf="getTootlipId(anchor, 'forwardFXRate')">
    <lv-tooltip dm="dM-4581"></lv-tooltip>
  </ng-container>
 <!-- Tabular Outputs -->
 <ng-container  *ngIf="getTootlipId(anchor, 'rhoBucket')">
  <lv-tooltip dm="dM-4565"></lv-tooltip>
</ng-container>
<ng-container  *ngIf="getTootlipId(anchor, 'presentValuePartialPuts')">
  <lv-tooltip dm="dM-4566"></lv-tooltip>
</ng-container>
<ng-container  *ngIf="getTootlipId(anchor, 'accretedPutValue')">
  <lv-tooltip dm="dM-4564"></lv-tooltip>
</ng-container>
<ng-container  *ngIf="getTootlipId(anchor, 'cSSensBucket')">
  <lv-tooltip dm="dM-4614"></lv-tooltip>
</ng-container>
</ng-template>

<ng-template #outputs>
  <lv-grid-layout
    data-cy="DM-1203"
    [cells]="view.regularOutputs"
    [columnWidth]="265">
      <div *lvGridLayoutCell="let cell = cell">
        <lv-flex-box
        class="lv-outputs-row"
        direction="row"
        kendoTooltip
        showAfter="1500"
        filter=".lv-label"
        [tooltipTemplate]="tooltipTemplate">
          <div class="lv-label"
            [attr.tooltip-id]="cell.type">
            {{ cell.name }}
          </div>
          <div class="lv-outputs-row-value">
            <ng-container *ngIf="cell.type !== 'lastFixDate' && cell.type !== 'nextFixDate'">
              <lv-negative-label [value]="cell.value" [suffix]="cell.suffix" [lvId]="cell.type"></lv-negative-label>
            </ng-container>
            <ng-container *ngIf="cell.type === 'lastFixDate' || cell.type === 'nextFixDate'">
              <div class="lv-date-label" [lvId]="cell.type">
                <span class="lv-date-value prefix">{{cell.value | lvLocalDate}}</span>
                <span class="lv-date-suffix sufix">{{cell.suffix}}</span>
              </div>
            </ng-container>
          </div>
        </lv-flex-box>
      </div>
  </lv-grid-layout>
</ng-template>