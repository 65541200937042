<lv-flex-box
  direction="row"
  data-cy="DM-1473">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="volatilitRiskyTypeSettingsTooltip"
    filter="span">
    <span> Risky / Risk Free </span>
  </div>

  <div class="lv-input-field">
    <kendo-dropdownlist
      name="equityVolatilitySettingsRiskyType"
      valueField="id"
      textField="text"
      [data]="riskyTypeEnum.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.riskyType"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<ng-template #volatilitRiskyTypeSettingsTooltip>
  <lv-tooltip dm="dM-1473"></lv-tooltip>
</ng-template>
