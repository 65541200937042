<lv-flex-box class="lv-borrow">
  <lv-environment-settings
    [section]="borrowSection"
    lvId="marketDataBorrowEnvironmentSettings"
    instrumentType="ConvertibleBond"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-borrow-content lv-market-data-section-content">
    <lv-flex-box data-cy="DM-307" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="borrowTooltipTemplate"
        filter="span">
          <span [attr.borrow-tooltip-id]="'borrowSourceTooltip'">
            Borrow Source
          </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="cbBorrowSource"
          valueField="id"
          textField="text"
          [data]="borrowSource.items"
          [popupSettings]="popupSettings"
          [valuePrimitive]="true"
          [disabled]="isBorrowSectionDisabled"
          [(ngModel)]="borrowSettings.borrowSource"
          (ngModelChange)="onBorrowSourceChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <ng-container *ngIf="!isBorrowSectionDisabled">
      <lv-borrow-flat *ngIf="isFlatSelected"
        [borrowSettings]="borrowSettings"
        (borrowValueChange)="onBorrowSectionChanged()">
      </lv-borrow-flat>
      <lv-borrow-rebate *ngIf="isRebateSelected"
        [borrowSettings]="borrowSettings"
        (rebateValueChange)="onBorrowSectionChanged()">
      </lv-borrow-rebate>

      <lv-flex-box *ngIf="isTermStructureSelected">
        <lv-flex-box direction="row" class="lv-borrow-grid-title">
          <div showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="borrowTermStructureTooltipTemplate"
            filter=".lv-label" 
            class="lv-label lv-label-field lv-label--title">
            <span [attr.borrow-tooltip-id]="'borrowTermStructureTooltip'">
              BORROW TERM STRUCTURE
            </span> 
          </div>
        </lv-flex-box>
        <lv-borrow-term-structure class="lv-borrow-term-structure"
          [scheduleItems]="borrowSettings.termStructureItems"
          (borrowScheduleValueChange)="onBorrowSectionChanged()">
        </lv-borrow-term-structure>
      </lv-flex-box>

      <lv-flex-box *ngIf="isUnderlyingEquityDataFlatSelected">
        <lv-equity-borrow-flat
          [borrowSettings]="underlyingEquityBorrow"
          [isEditable]="isEditable">
        </lv-equity-borrow-flat>
      </lv-flex-box>
    
      <lv-flex-box *ngIf="isUnderlyingEquityDataRebateSelected">
        <lv-equity-borrow-rebate
          [borrowSettings]="underlyingEquityBorrow"
          [isEditable]="isEditable">
        </lv-equity-borrow-rebate>
      </lv-flex-box>
    
      <lv-flex-box *ngIf="isUnderlyingEquityDataTermsStructureSelected">
        <lv-equity-borrow-term-structure
          [scheduleItems]="underlyingEquityBorrow.termStructureItems"
          [isEditable]="isEditable">
        </lv-equity-borrow-term-structure>
      </lv-flex-box>
    </ng-container>
  </lv-flex-box>
</lv-flex-box>

<ng-template #borrowTooltipTemplate>
  <lv-tooltip dm="dM-307"></lv-tooltip>
</ng-template>
<ng-template #borrowTermStructureTooltipTemplate>
  <lv-tooltip dm="dM-305"></lv-tooltip>
</ng-template>
<ng-template #borrowTooltipTemplate>
  <lv-tooltip dm="dM-302"></lv-tooltip>
</ng-template>
