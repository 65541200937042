import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBaseBondTermsSettings, IBondMakeWholeSectionSettings } from '../../../../models/bond-terms-settings';
import { LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';
import { CallMakeWholeTypeBondDescription, DiscountRateType } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-bond-make-whole-section-settings',
  templateUrl: './lv-bond-make-whole-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondMakeWholeSectionSettingsComponent {
  
  @Input() settings: IBaseBondTermsSettings<IBondMakeWholeSectionSettings>;
  @Input() overrideDefaults: boolean;

  callMakeWholeCheckBoxId: string;
 
  makeWholeTypeLookup: LvLookupEnum;
  discountRateTypeLookup: LvLookupEnum;

  decimalsZero = '0';
  formatZero = 'n0';

  constructor() {
    this.callMakeWholeCheckBoxId = v4();

    this.makeWholeTypeLookup = new LvLookupEnum(CallMakeWholeTypeBondDescription);
    this.discountRateTypeLookup = new LvLookupEnum(DiscountRateType);
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }
  
  getMakeWholeSectionSettingsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
