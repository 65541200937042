<lv-flex-box class="lv-equity-sync-settings-modal">
  <lv-flex-box class="lv-equity-sync-settings-modal-content" data-cy="DM-4794">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="syncAllEquityDataTooltipTemplate"
      filter="span">
      <span> Sync All Equity Data</span>
    </div>

    <input class="k-checkbox"
      type="checkbox"
      [attr.id]="syncAllEquityDataCheckboxId"
      name="sync-all-equity-data"
      [(ngModel)]="syncAllDataCheckboxValue"
    >
    <label class="k-checkbox-label"
      lvId="syncAllEquityData"
      [attr.for]="syncAllEquityDataCheckboxId">
    </label>
  </lv-flex-box>
  <lv-flex-box class="lv-equity-sync-settings-modal-footer" direction="row">
      <button kendoButton
        [primary]="true"
        lvId="lv-equity-sync-settings-modal-cancel-button"
        (click)="onCancel()">
          Cancel
      </button>
      <button kendoButton
        [primary]="true"
        lvId="lv-equity-sync-settings-modal-save-button"
        (click)="onSave()">
          Save
      </button>
    </lv-flex-box>
</lv-flex-box>

<ng-template #syncAllEquityDataTooltipTemplate>
  <lv-tooltip dm="dM-4794"></lv-tooltip>
</ng-template>