import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FixedCouponData } from '@lv-instrument-common/FixedCouponData';
import { SetupStatus } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-fixed-coupon',
  templateUrl: './lv-fixed-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFixedCouponComponent implements OnInit {
  @Input() model: FixedCouponData;
  @Input() instrumentStatus: SetupStatus;
  @Output() didFixedCouponModelChange: EventEmitter<FixedCouponData>;

  get isNewIssueRange(): boolean {
    return this.instrumentStatus === SetupStatus.NewIssue || this.model.useStepupCouponSchedule;
  }

  useStepupCouponScheduleCheckBoxId: string;

  formatNumber = '#,###.####';
  decimalsNumber = '4';

  constructor() {
    this.useStepupCouponScheduleCheckBoxId = v4();
    this.didFixedCouponModelChange = new EventEmitter<FixedCouponData>();
  }

  onFixedCouponModelChange() {
    this.didFixedCouponModelChange.next(this.model);
  }

  ngOnInit() {
  }

  getFixedCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
