import { Injectable } from '@angular/core';
import { ICountry } from '..';
import countries from '../mocks/countries-mock.json';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  constructor() {
  }

  /**
   * Find country object based on country code inside mocked data
   * Also transform country name to capitilize first letter since it's all uppercase
   * @param countryCode Country short code
   * @returns ICountry object
   */
  getCountry(countryCode: string): ICountry {
    const selectedCountry = countries.countries.filter((country: ICountry) => country.countryCode === countryCode)[0];
    selectedCountry.countryName = selectedCountry.countryName.toLowerCase()
                                                             .split(' ')
                                                             .map((word: string) => word[0].toUpperCase() + word.substring(1))
                                                             .join(' ');
    return selectedCountry;
  }

  /**
   * Method retrieves all countries from mocked list.
   */
  getAllCountries(): string[] {
    const allCountries:string[] = [];
    countries.countries.forEach(el => 
      allCountries.push(el.countryName.toLowerCase()
      .split(' ')
      .map((word: string) => word[0].toUpperCase() + word.substring(1))
      .join(' '))
    );
    
    return allCountries;
  }

  /**
   * Get country code by country name
   * @param countryName country name
   * @returns country code
   */
  getCountryCodeByCountryName(countryName: string): string {   
    const result = countries.countries.find(el => el.countryName.toLocaleUpperCase() === countryName.toLocaleUpperCase());
    return result.countryCode;
  }

  /**
   * Get country name by country code
   * @param countryCode country code
   * @returns country name
   */
  getCountryNameByCountryCode(countryCode: string): string {
    let countryName = '';
    countries.countries.forEach(el => el.countryCode === countryCode.toLocaleUpperCase() ?
                                      countryName = el.countryName.toLowerCase()
                                      .split(' ')
                                      .map((word: string) => word[0].toUpperCase() + word.substring(1))
                                      .join(' ') : '');
    return countryName;
  }
}
