<lv-flex-box class="lv-asw-terms-identifiers">
  <lv-flex-box class="lv-asw-terms-identifiers-read-only">
    <lv-flex-box direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="leversysIDTooltip"
      filter=".lv-asw-terms-identifiers-read-only-description">
      <label class="lv-asw-terms-identifiers-read-only-label
                    lv-asw-terms-identifiers-read-only-description">
        Leversys ID
      </label>
      <label class="lv-asw-terms-identifiers-read-only-label
      lv-asw-terms-identifiers-read-only-value">
        {{leversysId}}
      </label>
    </lv-flex-box>
    <lv-flex-box
    direction="row">
    <div
      class="lv-asw-save-reload-controls"
      kendoTooltip 
      data-cy="">
      <div
        class="lv-icon-square"
        title="Save"
        lvId="equitySaveAll"
        (click)="onSave()">
        <i class="k-icon k-i-save"></i>
      </div>
      <div
        class="lv-icon-square"
        title="Reload"
        lvId="equityReloadAll"
        (click)="onReload()">
        <i class="k-icon k-i-reload"></i>
      </div>
  </div>
  </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box direction="row">
    <lv-advanced-grid
      data-cy=""
      [columns]="columns"
      [records]="identifiersItems"
      [createFormGroup]="createFormGroup"
      [parseFn]="parseFn"
      lvId="lvAswIdentifiersSchedule"
      class="lv-asw-identifiers-schedule">
    </lv-advanced-grid>
  </lv-flex-box>
</lv-flex-box>

<ng-template #leversysIDTooltip>
<lv-tooltip dm="dM-336"></lv-tooltip>
</ng-template>
