export enum EquityTypeEnum {
    Equity = 'Equity',
    ETF = 'ETF',
    Fund = 'Fund',
    InternalFund = 'Internal Fund',
    DepositaryReceipt = 'Depositary Receipt',
    Misc = 'Misc'
}

export enum EquityTypeEnumDescription {
    Equity = 'Equity',
    ETF = 'ETF',
    Fund = 'Fund',
    InternalFund = 'Internal Fund',
    DepositaryReceipt = 'Depositary Receipt',
    Misc = 'Misc'
}