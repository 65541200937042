import { v4 } from 'uuid';
import { DecimalPipe } from '@angular/common';

import { constants } from '@lv-core-ui/util';
import { ConvertibleHelper } from '@lv-analytics/helpers';
import { INewIssueAssumptions, IConvertible, StockReferenceType, ConvertibleSubType } from '@lv-analytics/models';
import { IPriceTalk } from '@lv-analytics/models/convertible/price-talk';
import { LvExcelService } from '@lv-excel/services';
import { ObjectUnsubscribedError } from 'rxjs';
import { LvDateService } from '@lv-core-ui/services';
import { IPricing } from '@lv-analytics/models';

export interface ILvNewIssueAssumptionsViewRadio {
  id: string;
  label: string;
  value: string;
  lvId: string;
}

export interface ILvNewIssueAssumptionsViewInputField {
  fieldName: string;
  checkboxData?: any;
  label: string;
  outputs: string[];
  visible: boolean;
}

export const getDefaultNewIssueAssumptions = (assumptions?: INewIssueAssumptions): INewIssueAssumptions => {
  const model = {
    pricingTypeSelected: 'assumed'
  } as INewIssueAssumptions;

  if (assumptions) {
    Object.assign(model, assumptions);
  }

  if (!model.usedStockRef) {
    if (!model.stockRefCBCcy) {
      model.stockRefCBCcy = null;
    }

    if (!model.stockRef) {
      model.stockRef = null;
    }

    if (!model.fx) {
      model.fx = null;
    }
  }

  return model;
};

/**
 * New issue assumptions view.
 */
export class LvNewIssueAssumptionsView {

  get isNewIssue(): boolean {
    return this._cHelper.isNewIssue;
  }

  get isPricingTypeAssumed(): boolean {
    return this.model.pricingTypeSelected === 'assumed';
  }

  inputFields: ILvNewIssueAssumptionsViewInputField[];
  radioFields: ILvNewIssueAssumptionsViewRadio[];

  model: INewIssueAssumptions; //what will be saved to db
  placeHolderModel: INewIssueAssumptions; //what will be shown on UI as place holder
  modelPriceTalk: IPriceTalk;

  private _radioFeieldsDict: {
    [fieldName: string]: {
      label: string;
      lvId: string;
    };
  };

  private _inputFieldsDict: {
    [fieldName: string]: {
      label: string;
      visible: boolean;
    };
  };

  private _inputFieldsOutputs: string[];
  private _cHelper: ConvertibleHelper;

  constructor(
    public decimalPipe: DecimalPipe,
    private _lvDateService: LvDateService
  ) {
    this._radioFeieldsDict = {
      'assumed': {
        label: 'Assumed',
        lvId: this.getInputFieldLvId('assumed', 'rb')
      },
      'best': {
        label: 'Best',
        lvId: this.getInputFieldLvId('best', 'rb')
      },
      'mid': {
        label: 'Mid',
        lvId: this.getInputFieldLvId('mid', 'rb')
      },
      'worst': {
        label: 'Worst',
        lvId: this.getInputFieldLvId('worst', 'rb')
      }
    };

    this._inputFieldsOutputs = ['Best', 'Mid', 'Worst'];

    this._cHelper = new ConvertibleHelper(_lvDateService);

    this.init();
  }

  /**
   * Fields initialization.
   * @param inputModel INewIssueAssumptions object.
   * @param convertible IConvertible object.
   * @param stockSlippage Stock slippage value.
   * @param pricingStockReference Pricing stock reference value.
   * @param usedStockRef Used stock reference value from terms.
   * @param isDeleteEvent In case of deleting values from stock reference eq or cb this flag would be true.
   */
  init(inputModel?: INewIssueAssumptions,
    convertible?: IConvertible,
    stockSlippage?: number,    
    pricing?: IPricing)    
    {    
    const fixedOnTerms = convertible?.stockPriceReference?.referenceType === StockReferenceType.Fixed;
    this.model = getDefaultNewIssueAssumptions(inputModel);

    /**
     * This flag describes case when we need to detect whether
     * checkbox for fixed reference is changed.
     * Because used stock reference flag is set by that field,
     * when value is changed on terms we will have difference at
     * the beginning of this function.
     */
    let isFixedOnTermsChanged = false;
    
    if (!!this.model) {
      isFixedOnTermsChanged = fixedOnTerms !== this.model.usedStockRef;
      this.model.usedStockRef = fixedOnTerms;
    }  

    this.placeHolderModel = {} as INewIssueAssumptions;

    let referenceFx = 1;

    if (convertible?.isCrossFx) {
      referenceFx = pricing?.crossFx;

      if (convertible?.fixedFxRate && convertible?.fixedFxRate !== 0) {
        referenceFx = convertible?.fixedFxRate;
        this.model.fx = referenceFx;
      }
      else if (this.model.fx && this.model.fx != 0) {
        referenceFx = this.model.fx;
      }
    }

    /**
     * Keep full value of stock ref and stock ref cb because these two values are 
     * used in effective premium calculations.
     */
    const modelStockRefFullValue = pricing?.stockPriceUndCcy ? pricing?.stockPriceUndCcy : null;
    const modelStockRefCBFullValue = convertible?.isCrossFx ? pricing?.stockPriceUndCcy / referenceFx : pricing?.stockPriceCbCcy;

    const placeHolderModelStockRef = +this.decimalPipe.transform(pricing?.stockPriceUndCcy, constants.numberFormat.fourDigits);
    const placeHolderModelStockRefCBCcy = +this.decimalPipe.transform(convertible?.isCrossFx ? pricing?.stockPriceUndCcy / referenceFx : pricing?.stockPriceCbCcy, constants.numberFormat.fourDigits);
    const placeHolderModelFx = +this.decimalPipe.transform(referenceFx, constants.numberFormat.fourDigits);

    this.placeHolderModel.stockRef = placeHolderModelStockRef === 0 ? null : placeHolderModelStockRef;
    this.placeHolderModel.stockRefCBCcy = placeHolderModelStockRefCBCcy === 0 ? null : placeHolderModelStockRefCBCcy;
    this.placeHolderModel.fx = placeHolderModelFx === 0 ? null : placeHolderModelFx;

    if(fixedOnTerms){     
      this.model.stockRef = convertible?.stockPriceReference?.fixedStockRef;
      this.model.fx = convertible?.fixedFxRate;
      this.model.stockRefCBCcy = (convertible?.isCrossFx) ? convertible?.stockPriceReference?.fixedStockRef/ convertible?.fixedFxRate : convertible?.stockPriceReference?.fixedStockRef;      
    }
    else if (convertible?.isCrossFx) {      
      if(this.model.stockRef){
        this.model.stockRefCBCcy = this.model.stockRef / referenceFx;
      }
      else if(this.model.stockRefCBCcy){
        this.model.stockRef = this.model.stockRefCBCcy * referenceFx;
      }
    }
    /**
     * If isFixedOnTermsChanged flag is true we need to set values on pricing to null.
     */
    else if (isFixedOnTermsChanged) {
      this.model.stockRef = null;
      this.model.stockRefCBCcy = null;
    }

    let referenceStockPrice = convertible?.isCrossFx ? modelStockRefFullValue : modelStockRefCBFullValue;

    const pricingStockReference = convertible?.isCrossFx ? pricing?.stockPriceUndCcy : pricing?.stockPriceCbCcy;

    if(convertible?.isCrossFx && this.model?.stockRef || this.model?.stockRefCBCcy){
      referenceStockPrice =  convertible?.isCrossFx ? this.model?.stockRef: this.model?.stockRefCBCcy;
    }

    if (!pricingStockReference) {
      this.model.effectivePremium = this.model.premium;
    }
    else {
      this.model.effectivePremium = this.calculateEffectivePremium(
        this.model.premium,
        referenceStockPrice,
        pricingStockReference,
        stockSlippage);
    }

    this.updateConvertible(convertible, stockSlippage, pricingStockReference);

    this.setInputFields();
    this.radioFields = this.getRadioFields();
    this.inputFields = this.getInputFields();
  }

  /**
   * Updates convertible.
   * @param convertible IConvertible object.
   * @param isPrivateInstrument A flag indicating if instrument is private.
   */
  updateConvertible(convertible?: IConvertible, stockSlippage?: number, pricingStockReference?: number) {
    this._cHelper.init(convertible);

    if (this._cHelper.isValid && this._cHelper.convertible.priceTalk) {
      this.modelPriceTalk = this._cHelper.convertible.priceTalk;
      this.updatePriceTalkEffectivePremium(convertible?.isCrossFx, stockSlippage, pricingStockReference)
    }

    if (this.isNewIssue) {
      this.calculateMiddlePrices();
    }
  }

  /**
   * Updates assumed new issue assumptions.
   */
  updateAssumedNewIssueAssumptions() {
    if (this._cHelper.priceTalk) {
      this.model.premium = this._cHelper.priceTalk.premiumAssumed;
      this.model.coupon = this._cHelper.priceTalk.couponAssumed;
      this.model.redemptionValue = this._cHelper.priceTalk.redemptionValueAssumed;
      this.model.issueYield = this._cHelper.priceTalk.issueYieldAssumed;
      this.model.issuePrice = this._cHelper.priceTalk.issuePriceAssumed;
      if (this._cHelper.isPeps) {
        this.model.higherStrikePremium = this._cHelper.priceTalk.higherStrikePremiumAssumed;
      }
    }
  }

  /**
   * Updates new issue assumptions model.
   * @param model INewIssueAssumptions object.
   */
  update(model: INewIssueAssumptions) {
    this.model = getDefaultNewIssueAssumptions(model);
  }

  /**
   * Gets model.
   * @returns INewIssueAssumptions object.
   */
  getModel(): INewIssueAssumptions {   
    return this.model;
  }

  /**
   * Gets issue price value.
   * @param type Issue price type.
   * @returns Issue price value.
   */
  getIssuePriceValue(type: string): number {
    if (type === 'assumed') {
      return this.model.issuePrice;
    }
    if (type === 'best') {
      return this.modelPriceTalk.issuePriceBest;
    }
    if (type === 'mid') {
      return this.modelPriceTalk.issuePriceMid;
    }
    if (type === 'worst') {
      return this.modelPriceTalk.issuePriceWorst;
    }
  }

  /**
   * Gets price talk.
   * @param suffix Suffix.
   * @returns Price talk.
   */
  getPriceTalk(suffix: string): string {
    if (!this._cHelper.isValid || suffix.startsWith('stockRef')) {
      return null;
    }

    if (suffix.startsWith('effectivePremium') && !this.modelPriceTalk[suffix]) {
      return ' ';
    }

    return this.decimalPipe.transform(!!this.modelPriceTalk[suffix] ? this.modelPriceTalk[suffix] : 0, constants.numberFormat.threeDigits);
  }

  /**
   * Checks if field is disabled.
   * @param fieldName Field name.
   * @returns A flag indicating if field is disabled.
   */
  isFieldDisabled(fieldName: string) {

    if (fieldName === 'redemptionValue' || fieldName === 'issueYield') {
      return (this._cHelper.convertible.subType === ConvertibleSubType.PEPS)
      || !this.isPricingTypeAssumed || this._cHelper.convertible.isPerpetual;
    }

    if (fieldName === 'effectivePremium') {
      return true;
    }

    return !this.isPricingTypeAssumed;
  }

  /**
   * Gets input field Leversys ID.
   * @param fieldName Field name.
   * @param suffix Suffix.
   * @returns Input field Leversys ID.
   */
  getInputFieldLvId(fieldName, suffix) {
    return `lv-pricing-nia-${fieldName}-${suffix}`;
  }

  /**
   * Calculates middle prices.
   */
  private calculateMiddlePrices() {
    Object.keys(this._inputFieldsDict)
      .forEach(a => {
        if (a !== 'stockRef') {
          this.modelPriceTalk[`${a}Mid`] =
            this.calculateMiddleValue(this.modelPriceTalk[`${a}Worst`], this.modelPriceTalk[`${a}Best`]);
        }
      });
  }

  /**
   * Calculates middle value.
   * @param worst Worst value.
   * @param best Best value.
   * @returns Middle value.
   */
  private calculateMiddleValue(worst: number, best: number): number {
    let middle;

    try {
      middle = parseFloat(((worst + best) / 2).toFixed(4));
    }
    catch (e) {
      middle = 0;
    }

    return middle;
  }

  /**
   * Gets radio fields.
   * @returns List of ILvNewIssueAssumptionsViewRadio objects.
   */
  private getRadioFields(): ILvNewIssueAssumptionsViewRadio[] {
    return Object.keys(this._radioFeieldsDict)
      .map(a => ({
        id: `nia-rb-id-${v4()}`,
        label: this._radioFeieldsDict[a].label,
        lvId: this._radioFeieldsDict[a].lvId,
        value: a
      } as ILvNewIssueAssumptionsViewRadio));
  }

  /**
   * Gets input fields.
   * @returns List of ILvNewIssueAssumptionsViewInputField objects.
   */
  private getInputFields(): ILvNewIssueAssumptionsViewInputField[] {
    const temp = Object.keys(this._inputFieldsDict)
      .map(a => ({
        fieldName: a,
        checkboxData: {
          id: `nia-cb-id-${v4()}`,
          visible: a === 'stockRef'
        },
        label: this._inputFieldsDict[a].label,
        outputs: this._inputFieldsOutputs.map(s => `${a}${s}`),
        visible: this._inputFieldsDict[a].visible
      } as ILvNewIssueAssumptionsViewInputField));

    return temp;
  }

  /**
   * Sets input fields.
   */
  private setInputFields() {
    this._inputFieldsDict = {
      'higherStrikePremium': {
        label: 'Higher Strike Premium',
        visible: this._cHelper.isPeps
      },
      'premium': {
        label: 'Premium',
        visible: !this._cHelper.isPeps
      },
      'coupon': {
        label: 'Coupon',
        visible: !this._cHelper.isFloatingCoupon
      },
      'spread': {
        label: 'Spread',
        visible: this._cHelper.isFloatingCoupon
      },
      'issuePrice': {
        label: 'Issue Price',
        visible: true
      },
      'redemptionValue': {
        label: 'Redemption Value',
        visible: true
      },
      'issueYield': {
        label: 'Issue Yield',
        visible: true
      },
      'effectivePremium': {
        label: 'Effective Premium',
        visible: true
      },
      'stockRef': {
        label: this._cHelper.isValid ? `Stock Ref (${this._cHelper.currencyCode})` : 'Stock Ref',
        visible: true
      }
    };
  }

  /**
   * Update price talk effective premium values.
   * @param isCrossFx Is convertible cross fx convertible.
   * @param stockSlippage Stock slippage value.
   */
  private updatePriceTalkEffectivePremium(isCrossFx: boolean, stockSlippage: number, pricingStockReference?: number): void {
    if (pricingStockReference === null || pricingStockReference === undefined) {
      this.modelPriceTalk.effectivePremiumWorst = this.modelPriceTalk.premiumWorst;
      this.modelPriceTalk.effectivePremiumMid = this.modelPriceTalk.premiumMid;
      this.modelPriceTalk.effectivePremiumBest = this.modelPriceTalk.premiumBest;
    }
    else {
      this.modelPriceTalk.effectivePremiumWorst = this.calculateEffectivePremium(
        this.modelPriceTalk.premiumWorst,
        isCrossFx ? this.model.stockRef ?? pricingStockReference : this.model.stockRefCBCcy ?? pricingStockReference,
        pricingStockReference,
        stockSlippage);
  
      this.modelPriceTalk.effectivePremiumMid = this.calculateEffectivePremium(
        this.modelPriceTalk.premiumAssumed,
        isCrossFx ? this.model.stockRef ?? pricingStockReference : this.model.stockRefCBCcy ?? pricingStockReference,
        pricingStockReference,
        stockSlippage);
  
      this.modelPriceTalk.effectivePremiumBest = this.calculateEffectivePremium(
        this.modelPriceTalk.premiumBest,
        isCrossFx ? this.model.stockRef ?? pricingStockReference : this.model.stockRefCBCcy ?? pricingStockReference,
        pricingStockReference,
        stockSlippage);
    }
  }

  /**
   * Calculate effectivePremium value.
   * @param premium Premium value.
   * @param stockRef Stock reference value.
   * @param stockRefFromPricing Stock reference value.
   * @param stockSlippage Stock slippage value.
   * @returns Value of effectivePremium.
   */
  private calculateEffectivePremium(
    premium: number,
    stockRef: number,
    stockRefFromPricing: number,
    stockSlippage: number
  ): number | null {
    let result = (((((premium ?? 0) + 100)) * (stockRef ?? 0)) / (((stockRefFromPricing ?? 0) * (100 + (stockSlippage ?? 0))) / 100)) - 100;
    if (!isFinite(result)) {
      result = null;
    }
    return result;
  }
}
