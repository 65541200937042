<lv-flex-box class="lv-convertible-bond-terms-identifiers">
	<ng-container *ngIf="isPrivate">
		<lv-flex-box class="lv-convertible-bond-terms-identifiers-read-only">
			<lv-flex-box direction="row" class="lv-convertible-bond-terms-identifiers-read-only-header">
				<label class="lv-convertible-bond-terms-identifiers-read-only-label">Identifier type</label>
				<label class="lv-convertible-bond-terms-identifiers-read-only-label">Identifier</label>
			</lv-flex-box>

			<lv-flex-box *ngFor="let item of readonlyIdentifiers"direction="row" 
				kendoTooltip
				showAfter="1500"
				filter=".lv-convertible-bond-terms-identifiers-read-only-description"
				[tooltipTemplate]="tooltipTemplate">
				<label class="lv-convertible-bond-terms-identifiers-read-only-label
					lv-convertible-bond-terms-identifiers-read-only-description" [attr.tooltip-id]="item.identifierType">
					{{mapIdentifierTypeToIdentifierTypeDesciption(item.identifierType)}}
				</label>
				<label class="lv-convertible-bond-terms-identifiers-read-only-label
					lv-convertible-bond-terms-identifiers-read-only-value">
					{{item.identifier}}
				</label>
			</lv-flex-box>
		</lv-flex-box>
		<lv-flex-box direction="row">
			<lv-advanced-grid
				data-cy="DM-537"
				[columns]="columns"
				[records]="identifiersItems"
				[createFormGroup]="createFormGroup"
				[parseFn]="parseFn"
				lvId="lvIdentifiersSchedule"
				class="lv-identifiers-schedule">
			</lv-advanced-grid>
		</lv-flex-box>
	</ng-container>
	<ng-container *ngIf="!isPrivate">
		<lv-flex-box class="lv-convertible-bond-terms-identifiers-read-only">
			<lv-flex-box direction="row" class="lv-convertible-bond-terms-identifiers-read-only-header">
				<label class="lv-convertible-bond-terms-identifiers-read-only-label">Identifier type</label>
				<label class="lv-convertible-bond-terms-identifiers-read-only-label">Identifier</label>
			</lv-flex-box>

			<lv-flex-box *ngFor="let item of identifiersItems"direction="row" 
				kendoTooltip
				showAfter="1500"
				filter=".lv-convertible-bond-terms-identifiers-read-only-description"
				[tooltipTemplate]="tooltipTemplate">
				<label class="lv-convertible-bond-terms-identifiers-read-only-label
					lv-convertible-bond-terms-identifiers-read-only-description" [attr.tooltip-id]="item.identifierType">
					{{mapIdentifierTypeToIdentifierTypeDesciption(item.identifierType)}}
				</label>
				<label class="lv-convertible-bond-terms-identifiers-read-only-label
					lv-convertible-bond-terms-identifiers-read-only-value">
					{{item.identifier}}
				</label>
			</lv-flex-box>
		</lv-flex-box>
	</ng-container>
</lv-flex-box>

<ng-template #leversysIDTooltip>
    <lv-tooltip dm="dM-336"></lv-tooltip>
</ng-template>
<ng-template #tooltipTemplate let-anchor>
	<ng-container *ngIf="getTootlipId(anchor, 'ISIN')">
	  <lv-tooltip dm="dM-337"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'CUSIP')">
		<lv-tooltip dm="dM-339"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'FIGI')">
		<lv-tooltip dm="dM-341"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'PERMID')">
		<lv-tooltip dm="dM-342"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'LeversysID')">
		<lv-tooltip dm="dM-336"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingISIN')">
		<lv-tooltip dm="dM-338"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingCUSIP')">
		<lv-tooltip dm="dM-340"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingFIGI')">
		<lv-tooltip dm="dM-344"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingPERMID')">
		<lv-tooltip dm="dM-345"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingBBGTicker')">
		<lv-tooltip dm="dM-343"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'BondCode')">
		<lv-tooltip dm="dM-4589"></lv-tooltip>
	  </ng-container>
</ng-template>