import { Injectable } from '@angular/core';

import { ITermStructureItem } from './yield-curve-term-structure';
import { LvLocalDatePipe } from '@lv-core-ui/pipes';
import { DateExtensions } from '@lv-core-ui/util';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Yield curve mapper.
 */
@Injectable()
export class YieldCurveMapper {

  constructor(
    private lvLocalDatePipe: LvLocalDatePipe,
    private _lvDateService: LvDateService
  ) {}

  /**
   * Maps yield curve to UI.
   * @param scheduleItems List of ITermStructureItem objects.
   * @returns List of ITermStructureItem objects.
   */
  mapYieldCurveToUi(scheduleItems?: ITermStructureItem[]): ITermStructureItem[] {
    if (!scheduleItems) {
      return null;
    }

    const uiTermStructure = [] as ITermStructureItem[];
    scheduleItems.forEach(a => {
      const scheduleItemRequest = {
        tenor: a.tenor,
        rate: a.rate,
        type: a.type,
        updatedRateDate: this.getTermStructureItemUpdateValue(a.updatedRateDate as number)
      } as ITermStructureItem;
      uiTermStructure.push(scheduleItemRequest);
    });

    return uiTermStructure;
  }

  /**
   * Gets term structure item update value.
   * @param apiDateTime API date and time.
   * @returns Term structure item update value.
   */
  private getTermStructureItemUpdateValue(apiDateTime: number): string {
    if (apiDateTime) {
      const updateDateInClientTZ = new Date(apiDateTime);
      const updateDateUTC = DateExtensions.getDateWithoutOffset(apiDateTime);

      if (updateDateUTC.getHours() === 0 && updateDateUTC.getMinutes() === 0) {
        return this.lvLocalDatePipe.transform(updateDateInClientTZ);
      }

      const currentDateInUTC = DateExtensions.getDateWithoutOffset(Date.now());
      // tslint:disable-next-line: max-line-length
      if (currentDateInUTC.getFullYear() === updateDateUTC.getFullYear() && currentDateInUTC.getMonth() === updateDateUTC.getMonth() && currentDateInUTC.getDate() === updateDateUTC.getDate()) {
        const hourMinuteValue = this._lvDateService.getHoursAndMinutes(updateDateInClientTZ);
        return hourMinuteValue;
      } else {
        return this.lvLocalDatePipe.transform(updateDateInClientTZ);
      }
    }

    return '';
  }
}
