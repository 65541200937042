import { Injectable } from '@angular/core';
import { ITimeZone } from '@lv-core-ui/models';

import { TimeZone, getTimeZones } from '@vvo/tzdb';

@Injectable()
export class LvTimeZoneService {

  private _timeZones: ITimeZone[];
  private _tzTimeZones: TimeZone[];

  constructor() {
    this._tzTimeZones = getTimeZones();
    this._timeZones = this.filterTimeZones();
  }

  getTimeZones(): ITimeZone[] {
    return this._timeZones;
  }

  /**
   * If saved timezone does not exist, then find original offset
   * and use filtered with that offset
   */
  getTimeZoneKey(timeZone: string): string {
    if (!timeZone) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const offset = this._tzTimeZones.find(x => x.name === timeZone).rawOffsetInMinutes;
    const zone = this._tzTimeZones.find(x => x.rawOffsetInMinutes === offset);

    return zone.name;
  }

  private filterTimeZones(): ITimeZone[] {
    return [
      this.getZone(this._tzTimeZones, '+00:00', 'Greenwich Mean Time'),
      this.getZone(this._tzTimeZones, '+01:00', 'Central European Time'),
      this.getZone(this._tzTimeZones, '+02:00', 'Eastern European Time'),
      this.getZone(this._tzTimeZones, '+03:00', 'East African Time'),
      this.getZone(this._tzTimeZones, '+04:00', 'Mauritius Time'),
      this.getZone(this._tzTimeZones, '+05:00', 'Pakistan Standard Time'),
      this.getZone(this._tzTimeZones, '+06:00', 'Bangladesh Standard Time'),
      this.getZone(this._tzTimeZones, '+07:00', 'Christmas Island Time'),
      this.getZone(this._tzTimeZones, '+08:00', 'China Standard Time'),
      this.getZone(this._tzTimeZones, '+09:00', 'Japan Standard Time'),
      this.getZone(this._tzTimeZones, '+10:00', 'East Australian Time'),
      this.getZone(this._tzTimeZones, '+11:00', 'Sakhalin Time'),
      this.getZone(this._tzTimeZones, '+12:00', 'New Zealand Standard Time'),
      this.getZone(this._tzTimeZones, '-11:00', 'Midway Islands Time'),
      this.getZone(this._tzTimeZones, '-10:00', 'Hawaii Standard Time'),
      this.getZone(this._tzTimeZones, '-09:00', 'Alaska Standard Time'),
      this.getZone(this._tzTimeZones, '-08:00', 'Pacific Standard Time'),
      this.getZone(this._tzTimeZones, '-07:00', 'Mountain Standard Time'),
      this.getZone(this._tzTimeZones, '-06:00', 'Central Standard Time'),
      this.getZone(this._tzTimeZones, '-05:00', 'Eastern Standard Time'),
      this.getZone(this._tzTimeZones, '-04:00', 'Atlantic Standard Time'),
      this.getZone(this._tzTimeZones, '-03:00', 'Argentina Standard Time'),
      this.getZone(this._tzTimeZones, '-02:00', 'Azores Time'),
      this.getZone(this._tzTimeZones, '-01:00', 'West Africa Time')
    ];
  }



  private getZone(timeZones: TimeZone[], offset: string, displayText: string, zone: string = ''): ITimeZone {
    const tz = timeZones.find(x => x.rawFormat.indexOf(offset) >= 0 && x.name.indexOf(zone) >= 0);

    return {
      text: `GMT ${offset} - ${displayText}`,
      value: tz.name,
      utcOffsetInMinutes: tz.rawOffsetInMinutes
    };
  }
}
