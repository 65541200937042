import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Optional, ViewEncapsulation } from '@angular/core';
import { LvBaseWidgetComponent } from '@lv-analytics/lv-base-widget.component';
import { IBaseAssetSwapWidgetState } from '@lv-analytics/models/asset-swap/base-asset-swap-widget-state';
import { WidgetStateManagerService } from '@lv-application-settings/services/widget-state-manager/widget-state-manager.service';
import { LvExcelService } from '@lv-excel/services/excel-service';
import { DefaultWidgetType } from '@lv-shared/models/default-widget-state';


@Component({
  selector: 'asset-swap',
  templateUrl: './asset-swap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class AssetSwapComponent extends LvBaseWidgetComponent<IBaseAssetSwapWidgetState> {

  onDestroy(): void {
  }

  onInit(): void {
  }

  constructor(
    public _changeDetectorRef: ChangeDetectorRef,
    public _widgetStateManagerService: WidgetStateManagerService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    super(
      _changeDetectorRef,
      _widgetStateManagerService,
      getInitialState(!!_excelSvc?.isInitialized()),
      DefaultWidgetType.ASW
    );
  }

  @HostBinding('class.asset-swap')
    get isAssetSwapComponent() {
      return true;
    }
}

/**
 * Get initial state.
 * @param isOpenedFromExcel A flag indicating if instrument is opened from Excel.
 * @returns IBaseAssetSwapWidgetState object.
 */
function getInitialState(isOpenedFromExcel: boolean): IBaseAssetSwapWidgetState {
  return {
    isOpenedFromExcel: isOpenedFromExcel,
    useDefaultView: true,
    instrumentType: 'ASW'
  };
}
