<lv-flex-box class="lv-other-market-data">
  <lv-environment-settings
    [section]="otherMarketDataSection"
    instrumentType="ConvertibleBond"
    lvId="marketDataOtherSettings"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-other-market-data-content lv-market-data-section-content">
    <lv-flex-box data-cy="DM-4629" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="stockSlippageTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span>
              Stock Slippage
          </span>
          <lv-xl-label [field]="'ISSUE_STK_SLIPPAGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="stockSlippage"
          [format]="numberFormatPercentage"
          [decimals]="numberOfDecimalsPercentage"         
          [(ngModel)]="otherMarketData.stockSlippage"
          (didKeyDownEnter)="onOtherChange()"
          (didBlur)="onOtherChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>   
</lv-flex-box>

<ng-template #stockSlippageTooltipTemplate>
  <lv-tooltip dm="dM-4629"></lv-tooltip>
</ng-template>
