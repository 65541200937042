import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { LvConvertibleBondTermsError } from "@lv-convertible-bond/models";
import { HttpClientBase } from "@lv-core-ui/api";
import { LvDateService } from "@lv-core-ui/services";
import { IAssetSwapTermsSettings } from "src/app/modules/instrument/asset-swap-terms/models/asset-swap-terms-settings/asset-swap-terms-settings";

@Injectable({
    providedIn: 'root'
  })
  export class AssetSwapSettingsService extends HttpClientBase {

    constructor(
        @Inject(HttpClient) http: HttpClient,
        lvDateService: LvDateService,
      ) {
        super(lvDateService, http, '/privateInstruments/aswTermsSettings');
      }

  /**
   * Gets terms settings.
   * @returns IAsseSwapTermsSettings object.
   */
  async getAssetSwapTermsSettings(): Promise<IAssetSwapTermsSettings> {
    try {
        const result = await this.getAsync<IAssetSwapTermsSettings>(null, '');

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Resets terms settings.
   * @returns IAssetSwapTermsSectionSettings object.
   */
  async resetAssetSwapTermsSettings(): Promise<IAssetSwapTermsSettings> {
      try {
          const result = await this.getAsync<IAssetSwapTermsSettings>(null, '/default');
          return result;
      }
      catch (error) {
        throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
      }
  }

  /**
   * Saves terms settings.
   * @param request IAssetSwapTermsSectionSettings object.
   */
  async saveAssetSwapTermsSettings(request: IAssetSwapTermsSettings): Promise<void> {
    try {
      await this.postAsync(request, '/save');
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }
}
