<lv-flex-box
  direction="row"
  data-cy = "DM-1476">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="dividendFrequencyTooltipTemplate"
    filter="span">
    <span> Dividend Frequency </span>
  </div>

  <div class="lv-input-field">
    <kendo-dropdownlist
      name="equityDividendFrequencySettings"
      data-cy="DM-1476"
      valueField="id"
      textField="text"
      [data]="dividendsFrequency.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.frequency"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<ng-template #dividendFrequencyTooltipTemplate>
  <lv-tooltip dm="dM-1476"></lv-tooltip>
</ng-template>
