import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output,
  EventEmitter, ChangeDetectorRef, HostBinding, 
  Input} from '@angular/core';
import { IEstimatesSettings, IEstimatesSectionSettings, ISaveEstimatesSettingsRequest } from '@lv-analytics/models';
import { InstrumentType } from '@lv-analytics/models/enum/instrument-type';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvTermsGeneralIssueInformationComponent } from '@lv-convertible-bond/components/lv-convertible-bond-terms/lv-terms-general/lv-terms-general-issue-information/lv-terms-general-issue-information.component';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';
import * as _ from 'lodash';

/**
 * Estimates settings component.
 */
@Component({
  selector: 'lv-estimates-settings',
  templateUrl: './lv-estimates-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEstimatesSettingsComponent implements OnInit, IDiscardable {
  @Input() instrumentType: string

  @Output() didCancel: EventEmitter<void>;
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;

  settings: IEstimatesSettings;
  originalSettings: IEstimatesSettings;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _discardChangesService: DiscardChangesService
  ) {
    this.instrumentType = '';
    this.didCancel = new EventEmitter<void>();
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {} as IEstimatesSectionSettings,
      environments: []
    } as IEstimatesSettings;
    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('Estimates', this);
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-estimates-settings')
  get isLvEstimatesSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-section-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  @HostBinding('class.lv-section-settings-estimates-section-settings-additional-margin-environment-defaults')
  get isMyDefaultTitleAdjusted() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.getEstimatesSettings();
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.getEstimatesAswSettings();
      }
      
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves estimate settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        await this._companyAndUserSettingsService.saveEstimatesSettings(this.getSaveRequest());
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        await this._companyAndUserSettingsService.saveEstimatesAswSettings(this.getSaveRequest());
      }
      

      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1813'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * Occurs on reset to default and resets estimates settings.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.resetEstimatesSettings();
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.resetEstimatesAswSettings();
      }

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1815'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.next();
  }

  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Gets save request.
   * @returns ISaveEstimatesSettingsRequest object.
   */
  private getSaveRequest(): ISaveEstimatesSettingsRequest {
    return LvUtil.jsonParse(LvUtil.jsonStringify(this.settings)) as ISaveEstimatesSettingsRequest;
  }
}
