/**
 * Instrument Type Description
 */
export enum InstrumentTypeDescription {
  Convertible = 'Convertible',
  Bond = 'Bond',
  AssetSwap = 'Asset Swap',
  Equity = 'Equity'
}

/**
 * Instrument Type
 */
export enum InstrumentType {
  Convertible = 'Convertible',
  Bond = 'Bond',
  AssetSwap = 'AssetSwap',
  Equity = 'Equity'
}