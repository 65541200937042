import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { IDividendsSectionSettings, DividendsSourceEnum, DividendsSourceEnumDescription,
         DividendFrequencyEnum } from '@lv-analytics/models';
import { DividendsSourceAswEnumDescription } from '@lv-analytics/models/market-data/dividends/enum/dividends-source-asw-enum';
import { LvLookupEnum } from '@lv-core-ui/util';

/**
 * Dividends section settings component.
 */
@Component({
  selector: 'lv-dividends-section-settings',
  templateUrl: './lv-dividends-section-settings.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDividendsSectionSettingsComponent implements OnInit {
  @Input() instrumentType: string; 
  @Input() settings: IDividendsSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  get isFrequencyDisabled(): boolean {
    return this.settings.dividendsSource !== DividendsSourceEnum.Parameters || !this.overrideSystemDefaults;
  }

  dividendSource: LvLookupEnum;
  dividendFrequency: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  constructor(
  ) {
    this.instrumentType = '';
    this.dividendSource = new LvLookupEnum(DividendsSourceEnumDescription);
    this.dividendFrequency = new LvLookupEnum(DividendFrequencyEnum);
    this.overrideSystemDefaults = true;

    this.settings = {} as IDividendsSectionSettings;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    if (this.isInstrumentTypeAsw()) {
      this.dividendSource = new LvLookupEnum(DividendsSourceAswEnumDescription);
    }
  }

  /**
   * check if instrument type is Asset Swap
   */
  isInstrumentTypeAsw() {
    return this.instrumentType === 'ASW'
  }

  /**
   * Function setup PopupSettings width based on instrument type
   * @returns width
   */
  get popupWidth() {
    return { width: this.isInstrumentTypeAsw() ? 230 : 100 };
  }
}
