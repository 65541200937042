import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { ConvertibleBondSubTypeDescription, ExchangeableTypeDescription, IBaseTermsSettings, IGeneralSectionSettings,
  InstrumentStatusDescription, SetupStatusQuickTerms,
  SetupStatusQuickTermsDescription } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';
import { Currency, IsQuotedClean, RecoveryUponDefaultDescription } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-general-section-settings',
  templateUrl: './lv-general-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvGeneralSectionSettingsComponent implements OnInit, OnChanges {

  @Input() settings: IBaseTermsSettings<IGeneralSectionSettings>;
  @Input() overrideDefaults: boolean;

  formatZero = 'n0';
  decimalsZero = '0';
  decimalsTwo = '2';
  formatTwo = '#,###.##';
  decimalsFour = '4';
  formatFour = '#,###.####';

  instrumentSubTypeLookup: LvLookupEnum;
  currencyLookup: LvLookupEnum;
  exchangeableTypeLookup: LvLookupEnum;
  isQuotedCleanLookup: LvLookupEnum;
  recoveryUponDefaultLookup: LvLookupEnum;
  setupStatusLookup: LvLookupEnum;

  issueValueNominalCheckboxId: string;
  issueValueRedemptionCheckboxId: string;
  priceAsParCheckboxId: string;
  accruedUponDefaultCheckboxId: string;

  status: InstrumentStatusDescription;
  quotedValue: string;

  constructor() {
    this.instrumentSubTypeLookup = new LvLookupEnum(ConvertibleBondSubTypeDescription);
    this.currencyLookup = new LvLookupEnum(Currency);
    this.exchangeableTypeLookup = new LvLookupEnum(ExchangeableTypeDescription);
    this.isQuotedCleanLookup = new LvLookupEnum(IsQuotedClean);
    this.recoveryUponDefaultLookup = new LvLookupEnum(RecoveryUponDefaultDescription);
    this.setupStatusLookup = new LvLookupEnum(SetupStatusQuickTermsDescription);

    this.issueValueNominalCheckboxId = v4();
    this.issueValueRedemptionCheckboxId = v4();
    this.priceAsParCheckboxId = v4();
    this.accruedUponDefaultCheckboxId = v4();
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.onInit();
  }

  getGeneralSectionSettingsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  quotedValueChange() {
    this.settings.settings.isQuotedClean = this.quotedValue === IsQuotedClean.Clean;
  }

  private onInit() {
    this.settings.settings.status = this.settings.settings.status ?? SetupStatusQuickTerms.NewIssue;
    this.quotedValue = this.settings.settings.isQuotedClean ? IsQuotedClean.Clean : IsQuotedClean.Dirty;
  }
}
