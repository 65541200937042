import { CallTriggerPeriodType, CallTriggerType, CallValueType, CurrencyType,
  NoticePeriod } from '@lv-instrument-common/index';
import { ICallSectionSettings } from '../call-settings/call-section-settings';
import { DayCount } from '@lv-instrument-common/enums/CouponDayCount';
import { Frequency } from '@lv-instrument-common/enums/Frequency';

export const getDefaultCallSectionSettings = (): ICallSectionSettings => {
  return {
    valueTypeRegular: CallValueType.PerOfPar,
    valueTypePeps: CallValueType.PepsThresholdDependentRatio,
    noticePeriod: NoticePeriod.Calendar,
    yieldDayCount: DayCount.DayCount_ACT_ACT_ICMA,
    yieldFrequency: Frequency.SemiAnnual,
    considerationDaysBusinessCalendar: NoticePeriod.Calendar,
    callNoticeDaysMax: null,
    considerationDaysMax: null,
    forfeitCoupon: false,
    keepAccruedConversion: false,
    notice: null,
    softCall: {
        triggerPeriodType: CallTriggerPeriodType.NOutOfMDays,
        triggerType: CallTriggerType.AsPerOfPar,
        currencyOfTrigger: CurrencyType.Convertible,
        triggerPeriodDays: null,
        outOfDays: null,
        callTriggerBasedOnFullExchgProperty: true
    },
  };
};
