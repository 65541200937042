/**
 * Types of dividend frequency.
 */
export enum DividendFrequencyEnum {
  Monthly = 'Monthly',
  Bimonthly = 'Bimonthly',
  Quarterly = 'Quarterly',
  Triannual = 'Triannual',
  SemiAnnual = 'Semi Annual',
  Annual = 'Annual',
}

/**
 * EquityDividendEnum enum.
 * It is used for equity dividends parameters and DividendFrequencyEnum as its description enum for displaying in ui.
 */
export enum EquityDividendEnum {
  Monthly = 'Monthly',
  Bimonthly = 'Bimonthly',
  Quarterly = 'Quarterly',
  Triannual = 'Triannual',
  SemiAnnual = 'SemiAnnual',
  Annual = 'Annual',
}