import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IReferenceIRSwap } from '../../../../models/aswReferenceIrSwap';
import { LvLookupEnum } from '@lv-core-ui/util';
import { PayLegFrequencyDescription } from '../../../../models/asset-swap-terms-settings/enums/asset-swap-pay-leg-frequency';
import { DayCountDescription } from '@lv-instrument-common/enums/CouponDayCount';
import { AswType } from '../../../../models/asset-swap-terms-settings/enums/asset-swap-type';
@Component({
  selector: 'lv-asw-reference-ir-swap',
  templateUrl: './lv-asw-reference-ir-swap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvAswReferenceIrSwapComponent {
  @Input() model: IReferenceIRSwap;
  @Input() callableASW: string;
  @Output() didAwsReferenceIRChange: EventEmitter<IReferenceIRSwap>;

  get isAswTypeCallable () {
    //to do: remove setTimeOut once override in session is called via API.
    setTimeout(() => {
    }, 1000);
    return this.callableASW === AswType.Callable;
  }

  payLegFrequencyTypeLookup: LvLookupEnum;
  payLegDayCountTypeLookup: LvLookupEnum;
  dpCommencementDateCheckBoxId:string;
  dpCommencementDatePickerId: string;
  commencementDateEnabled: boolean;

  decimalsTwo = '2';
  formatTwo = '#,###.##';

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    this.didAwsReferenceIRChange = new EventEmitter<IReferenceIRSwap>();
    this.payLegFrequencyTypeLookup = new LvLookupEnum(PayLegFrequencyDescription);
    this.payLegDayCountTypeLookup = new LvLookupEnum(DayCountDescription);
    this.commencementDateEnabled = false;
    this.dpCommencementDateCheckBoxId = 'dpCommencementDateCheckBoxId';
    this.dpCommencementDatePickerId = 'dpCommencementDatePickerId';
  }

  @HostBinding('class.lv-asw-reference-ir-swap')
    get isLvAswReferenceIrSwapComponent () {
      return true;
    }

  /**
   * Method triggers when field value changes.
   */
  onReferenceIrSwapChange() {
    this.didAwsReferenceIRChange.next(this.model);
    this._changeDetectorRef.detectChanges();
  }

  
   /**
   * Method triggers when commencement date checkbox changes state.
   * @param value Flag if checkbox is checked or not.
   */
   aswCommencementCheckBoxChanged(value: boolean): void {
    this.commencementDateEnabled = value;
    this.model.interestPaymentCommencementDate = value ? this.model.interestPaymentCommencementDate: null;
    this.onReferenceIrSwapChange();
  }

}
