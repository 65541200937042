<lv-flex-box class="lv-dividends-schedule-dividends">
  <lv-flex-box direction="row" class="lv-dividends-grid-title">
    <div showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="dividendsScheduleTooltipTemplate"
      filter=".lv-label" 
      class="lv-label lv-label-field lv-label--title">
      <span [attr.volatility-tooltip-id]="'dividendsScheduleTooltip'">
        DIVIDENDS SCHEDULE
      </span> 
    </div>
  </lv-flex-box>
  <lv-schedule-dividends-table
    [scheduleDividends]="dividendsSettings.dividendsSchedule.dividendSchedule"
    [dividendsCopyAndPasteSettings]="dividendsCopyAndPasteSettings"
    (dividendScheduleChange)="doDividendScheduleChange()"
    [editEnabled]="true">
  </lv-schedule-dividends-table>
  <lv-flex-box direction="row" class="lv-dividends-schedule-source-controls">
    <button kendoButton
      lvId="marketDataDividendsScheduleDisplayDividendsUsed"
      (click)="doDisplayDividendUseButtonClick()">
        Display Dividends Used
    </button>
  </lv-flex-box>
</lv-flex-box>

<ng-template #dividendsScheduleTooltipTemplate>
  <lv-tooltip dm="dM-315"></lv-tooltip>
</ng-template>
