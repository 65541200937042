import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { IBaseBondTermsSettings } from '../../../../models/bond-terms-settings';
import { IBondGeneralSectionSettings } from '../../../../models/bond-terms-settings/bond-general-settings/bond-general-section-settings';
import { Currency, IsQuotedClean, RecoveryUponDefaultDescription } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-bond-general-section-settings',
  templateUrl: './lv-bond-general-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondGeneralSectionSettingsComponent implements OnChanges {
  @Input() settings: IBaseBondTermsSettings<IBondGeneralSectionSettings>;
  @Input() overrideDefaults: boolean;

  formatZero = 'n0';
  decimalsZero = '0';
  decimalsTwo = '2';
  formatTwo = '#,###.##';
  decimalsFour = '4';
  formatFour = '#,###.####';
  
  currencyLookup: LvLookupEnum;
  isQuotedCleanLookup: LvLookupEnum;
  recoveryUponDefaultLookup: LvLookupEnum;
  quotedValue: string;

  issueValueNominalCheckboxId: string;
  issueValueRedemptionCheckboxId: string;
  priceAsParCheckboxId: string;
  accruedUponDefaultCheckboxId: string;

  constructor() {
    this.currencyLookup = new LvLookupEnum(Currency);
    this.overrideDefaults = true;
    this.isQuotedCleanLookup = new LvLookupEnum(IsQuotedClean);
    this.recoveryUponDefaultLookup = new LvLookupEnum(RecoveryUponDefaultDescription);
    this.issueValueNominalCheckboxId = v4();
    this.issueValueRedemptionCheckboxId = v4();
    this.priceAsParCheckboxId = v4();
    this.accruedUponDefaultCheckboxId = v4();
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  
  /**
   * Set is quoted value changed
   */
  quotedValueChange() {
    this.settings.settings.isQuotedClean = this.quotedValue === IsQuotedClean.Clean;
  }

  ngOnChanges() {
    this.quotedValue = this.settings.settings.isQuotedClean ? IsQuotedClean.Clean : IsQuotedClean.Dirty;
  }
}
