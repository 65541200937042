import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEquityScheduledDividend } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-dividends/equity-schedule';
import { DividendTypeEnum, DividendTypeEnumDescription } from '@lv-analytics/models/market-data/dividends/enum/dividend-type-enum';
import { LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridEnumColumn, LvAdvancedGridNumericColumn, LvAdvancedGridTextColumn } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { LvAdvancedGridComponent } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid.component';
import { LvError } from '@lv-core-ui/models/lv-error';
import { LvErrorService } from '@lv-core-ui/services';
import { constants } from '@lv-core-ui/util/constants';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'lv-equity-dividends-schedule',
  templateUrl: './lv-equity-dividends-schedule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityDividendsScheduleComponent implements OnInit {
  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() scheduleDividends: IEquityScheduledDividend[];
  @Input() isEditable: boolean;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get isGridDisabled (): boolean {
    return !this.isEditable;
  }

  get addStripedGridClass (): string {
   return !this.isEditable ? 'lv-advanced-grid-stripped' : '';
  }

  columns: LvAdvancedGridColumn[];
  parseFn: any;

  private _destroyRef = inject(DestroyRef)

  constructor(
    private _errorService: LvErrorService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.scheduleDividends = [];
    this.didSessionUpdatedEvent = new EventEmitter();
    this.isEditable= true;
  }

  @HostBinding('class.schedule-dividends-table-wrapper')
  get isScheduleDividendTable() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.advancedGrid.didDataChange
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((records: IEquityScheduledDividend[]) => this.onDataChange(records)),
    this.advancedGrid.doReload
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.onReload()),
    this.advancedGrid.didError
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((error: LvError) => this.onError(error))

    this.initColumns();
  }

  /**
   * Occurs on data change.
   * @param records List of IScheduledDividend objects.
   */
  public onDataChange(records: IEquityScheduledDividend[]) {
    this.applyRecords(records);
    this.didSessionUpdatedEvent.next();
  }

  /**
   * Applies advanced grid changes.
   */
  public applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on reload.
   */
  public onReload() {
    this.scheduleDividends = this.scheduleDividends.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'date': new FormControl(args.dataItem.date || new Date(), Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required),
      'type': new FormControl(args.dataItem.type || DividendTypeEnum.Cash, Validators.nullValidator),
      'netFactor': new FormControl(args.dataItem.netFactor, Validators.nullValidator)
    });
  }

  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.scheduleDividends.splice(0, this.scheduleDividends.length);
    this.scheduleDividends.push(...records);
  }

  /**
   * Columns initialization.
   */
  private initColumns() {
    this.columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.title = 'Date';
    dateColumn.field = 'date';
    dateColumn.width = this.isEditable ? 103 : 120;
    dateColumn.dmKey = 'DM-601';
    dateColumn.editable = this.isEditable;

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Value';
    valueColumn.field = 'value';
    valueColumn.width = this.isEditable ? 84 : 115;
    valueColumn.decimals = '4';
    valueColumn.format = '#.####';
    valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
    valueColumn.dmKey = 'DM-602';
    valueColumn.editable = this.isEditable;

    const typeColumn = new LvAdvancedGridEnumColumn();
    typeColumn.enumDescription = DividendTypeEnumDescription;
    typeColumn.title = 'Type';
    typeColumn.field = 'type';
    typeColumn.displayField = 'text',
    typeColumn.valueField = 'id',
    typeColumn.valuePrimitive = true,
    typeColumn.width = this.isEditable ? 138 : 139;
    typeColumn.data = new LvLookupEnum(DividendTypeEnumDescription).items;
    typeColumn.dmKey = 'DM-603';
    typeColumn.editable = this.isEditable;
    typeColumn.alignment = this.isEditable ? 'left' : 'right';

    const netFactorColumn = new LvAdvancedGridNumericColumn();
    netFactorColumn.title  = 'Net Factor';
    netFactorColumn.field = 'netFactor';
    netFactorColumn.width = this.isEditable ? 59 : 118;
    netFactorColumn.decimals = '3';
    netFactorColumn.format = '#.###';
    netFactorColumn.outputFormat = constants.numberFormat.upToThreeDigits;
    netFactorColumn.dmKey = 'DM-604';
    netFactorColumn.editable = this.isEditable;

    this.columns.push(dateColumn);
    this.columns.push(valueColumn);
    this.columns.push(typeColumn);
    this.columns.push(netFactorColumn);

  }
}
