import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IEquityVolatility } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-volatility.ts/equity-volatility';
import { VolatilitySurfaceRiskyTypeDescription } from '@lv-analytics/models/market-data/volatility/volatility-enums';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import _ from 'lodash';
import { LvEquityVolatilitySurfaceComponent } from './lv-equity-volatility-surface/lv-equity-volatility-surface.component';
import { LvEquityVolatilityTermsStructureComponent } from './lv-equity-volatility-terms-structure/lv-equity-volatility-terms-structure.component';
import { AnalyticsSettingsEvents } from '@lv-analytics/models';

@Component({
  selector: 'lv-equity-market-data-volatility',
  templateUrl: 'lv-equity-market-data-volatility.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityMarketDataVolatilityComponent implements OnInit {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild(LvEquityVolatilitySurfaceComponent) volatilitySurfaceGrid: LvEquityVolatilitySurfaceComponent;
  @ViewChild(LvEquityVolatilityTermsStructureComponent) volatilityTermStructureGrid: LvEquityVolatilityTermsStructureComponent;

  @Input() equityTermsSaved: boolean;
  @Input() ccyCodeChanged: string;

  get currentEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  get isVolatilitySectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  volatility: IEquityVolatility;
  originalValue: IEquityVolatility;
  volatilitySection = PricingEnvironmentSections.Volatility;
  isLoading: boolean;
  riskyType: LvLookupEnum;

  constructor (
    private _changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _destroyRef: DestroyRef,
    private _errorService: LvErrorService,
  ) {
    this.volatility =  {
      volatilityTermStructureSchedule: [],
      volatilitySurface: {
        volatilitySurfacePoints: []
      }
    } as IEquityVolatility;

    this.riskyType = new LvLookupEnum(VolatilitySurfaceRiskyTypeDescription);
  }
  
  @HostBinding ('class.lv-equity-market-data-volatility')
  get isLvEquityMarketDataVolatilityComponent (){
    return true;
  }

  /**
   * Method handles initialization.
   */
  ngOnInit(): void {
    this._analyticsPresenter.onModelLoading
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(isLoading => this.setLoadingState(isLoading));    

    this._analyticsPresenter.onAnalyticsSettingsUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.load();        
      });

      this._analyticsPresenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))      
      .subscribe((evt) => 
        {
         if (evt && evt.eventId === AnalyticsSettingsEvents.EquityVolatiityUpdated) {
            this.load();        
         }
      });

    this.load();
  }

  private load() {
    if (!!this._analyticsPresenter?.equitySession?.marketData?.volatility) {
      this.volatility = _.cloneDeep(this._analyticsPresenter?.equitySession?.marketData?.volatility);
      this.originalValue = _.cloneDeep(this.volatility);
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Save all equity volatility tabs.
   */
  async onSaveSection() {
    try {
      this.setLoadingState(true);

      this.volatilitySurfaceGrid.applyAdvancedGridChanges();
      this.volatilityTermStructureGrid.applyAdvancedGridChanges();

      await this._analyticsPresenter.saveEquityVolatility(this.volatility, this.envSettings.getSelectedEnvironment().id);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388', {'value': 'Volatility'}));

      this.originalValue = _.cloneDeep(this.volatility);

    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Reload equity volatility.
   */
  async onReloadSection() {
    await this.loadEquityVolatility(this.envSettings.getSelectedEnvironment());
    this.load();
  }

  /**
   * Occurs on environment change and loads market data volatility.
   * @param environment IEnvironmentSettingsItem object.
   */
  async onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    await this.loadEquityVolatility(environment);
    this.load();
  }

  /**
   * Loads market data volatility
   * @param {IEnvironmentSettingsItem} environment pass environment settings
   */
  async loadEquityVolatility(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      await this._analyticsPresenter.loadEquityVolatility(environment);
      this.originalValue = _.cloneDeep(this.volatility);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on environments reorder and updates market data volatility model.
   * @param environments List of IEnvironmentSettingsItem object.
   */
  onReorderEnvironments(environments: IEnvironmentSettingsItem[]) {
   
  }

  /**
   * Method handles updating model in session.
   * @param sessionStateChange A flag indicating if session state is changed.
   */
  updateEquityVolatilityModel() {
    // this._analyticsPresenter.overrideEquityInSession(equityValuationSession => {
    //   equityValuationSession.marketData.volatility = this.volatility;
    // });

    this._changeDetectorRef.detectChanges();
  }
  
  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.envSettings.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
