<lv-flex-box
  direction="row"
  data-cy="DM-4837"
  class="lv-asw-conversion-exercise-dates">
  <div class="lv-label lv-label-field lv-label-short"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="conversionStartDateSourceTooltip"
    filter="span:not(lv-xl-label span)">
    <span>
      Exercise Start Date
    </span>
    <lv-xl-label [field]="'EXC_START_DT'"></lv-xl-label>
  </div>

  <lv-datepicker
    name="asw-exercise-start-date"
    [checkBoxLvId]="dpStartCheckBoxId"
    [datePickerLvId]="dpStartDatePickerId"
    [(ngModel)]="model.exerciseStartDate"
    [checked]="startDateEnabled"
    (ngModelChange)="onAwsConversionChange()"
    (didCheckboxValueChange)="aswStartDateCheckBoxChanged($event)">
  </lv-datepicker>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-4838"
  class="lv-asw-conversion-exercise-dates">
  <div class="lv-label lv-label-field lv-label-short"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="conversionEndDateSourceTooltip"
    filter="span:not(lv-xl-label span)">
    <span>
      Exercise End Date
    </span>
    <lv-xl-label [field]="'EXC_END_DT'"></lv-xl-label>
  </div>

  <lv-datepicker
    name="asw-exercise-end-date"
    [checkBoxLvId]="dpEndCheckBoxId"
    [datePickerLvId]="dpEndDatePickerId"
    [(ngModel)]="model.exerciseEndDate"
    [checked]="endDateEnabled"
    (ngModelChange)="onAwsConversionChange()"
    (didCheckboxValueChange)="aswEndDateCheckBoxChanged($event)">
  </lv-datepicker>
</lv-flex-box>

<lv-flex-box 
  direction="row"
  class="lv-asw-early-exercise-fee-title">
  <div class="lv-label lv-label-field lv-label--title">EARLY EXERCISE FEE</div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-4841"
  class="lv-asw-early-exercise-fee-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="earlyExerciseFeeApplicableSourceTooltip"
    filter="span">
    <span>
      Early Exercise Fee Applicable
    </span>
    <lv-xl-label [field]="'USE_EARLY_EXC_FEE'"></lv-xl-label>
  </div>
  <div class="lv-field lv-field--checkbox">
    <input class="k-checkbox"
      type="checkbox"
      [attr.id]="earlyExerciseFeeCheckboxId"
      [(ngModel)]="model.earlyExerciseFeeApplicable"
      (ngModelChange)="onAwsConversionChange()"
      name="asw-price-as-par">
    <label class="k-checkbox-label"
      lvId="earlyExerciseFeeAsw"
      [attr.for]="earlyExerciseFeeCheckboxId">
    </label>
  </div>
</lv-flex-box>

<lv-flex-box
  *ngIf="isEarlyExerciseFeeApplicable"
  data-cy="DM-4842"
  direction="row"
  class="lv-asw-early-exercise-fee-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="earlyExerciseFeeTooltip"
    filter="span">
    <span> Early Exercise Fee </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      name="asw-recall-spread"
      [format]="formatTwo"
      [decimals]="decimalsTwo"
      [(ngModel)]="model.earlyExerciseFee"
      (didBlur)="onAwsConversionChange()"
      (didKeyDownEnter)="onAwsConversionChange()">
    </lv-numeric-text-box>
  </div>
  <div class="lv-suffix">
    <span>bps</span>
  </div>
  </lv-flex-box>
  
  <lv-flex-box
    *ngIf="isEarlyExerciseFeeApplicable"
    direction="row"
    data-cy="DM-4843"
    class="lv-asw-early-exercise-fee-field lv-early-exercise-date">
    <div class="lv-label lv-label-field lv-label-short"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionEndDateEarlyFeeSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Exercise End Date
      </span>
      <lv-xl-label [field]="'EARLY_EXC_END_DT'"></lv-xl-label>
    </div>

    <lv-datepicker
      name="asw-exercise-end-date"
      [checkBoxLvId]="dpEarlyEndCheckBoxId"
      [datePickerLvId]="dpEarlyEndDatePickerId"
      [(ngModel)]="model.earlyExerciseEndDate"
      [checked]="endDateEnabled"
      (ngModelChange)="onAwsConversionChange()"
      (didCheckboxValueChange)="aswEarlyEndDateCheckBoxChanged($event)">
  </lv-datepicker>
</lv-flex-box>

<lv-flex-box
  *ngIf="isEarlyExerciseFeeApplicable"
  data-cy="DM-4844"
  direction="row"
  class="lv-asw-early-exercise-fee-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="feeFormulaTypeTooltipTemplate"
    filter="span">
    <span> Fee Formula Type </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      [data]="feeFormulaTypeLookup.items"
      class="lv-asw-type"
      name="asw-fee-formula-type"
      valueField="text"
      textField="text"
      valuePrimitive="true"
      [(ngModel)]="model.feeFormulaType"
      (ngModelChange)="onAwsConversionChange()">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  *ngIf="isEarlyExerciseFeeApplicable"
  data-cy="DM-4845"
  direction="row"
  class="lv-asw-early-exercise-fee-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="daysInYearSourceTooltipTemplate"
    filter="span">
    <span> Days in Year </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      name="asw-days-in-year"
      [format]="formatZero"
      [decimals]="decimalsZero"
      [(ngModel)]="model.daysInYear"
      (didBlur)="onAwsConversionChange()"
      (didKeyDownEnter)="onAwsConversionChange()">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box
  *ngIf="isEarlyExerciseFeeApplicable"
  data-cy="DM-4846"
  direction="row"
  class="lv-asw-early-exercise-fee-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="maxIntervalDaysSourceTooltip"
    filter="span">
    <span> Max Interval Days </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      name="asw-days-in-year"
      [format]="formatZero"
      [decimals]="decimalsZero"
      [(ngModel)]="model.maxIntervalDays"
      (didBlur)="onAwsConversionChange()"
      (didKeyDownEnter)="onAwsConversionChange()">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box
  *ngIf="isEarlyExerciseFeeApplicable"
  data-cy="DM-4847"
  direction="row"
  class="lv-asw-early-exercise-fee-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="busDaysToRepaymentSourceTooltip"
    filter="span">
    <span> Bus. Days to Early Repayment </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      name="asw-days-in-year"
      [format]="formatZero"
      [decimals]="decimalsZero"
      [(ngModel)]="model.busDaysToEarlyRepayment"
      (didBlur)="onAwsConversionChange()"
      (didKeyDownEnter)="onAwsConversionChange()">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<ng-template #conversionStartDateSourceTooltip>
  <lv-tooltip dm="dM-4837"></lv-tooltip>
</ng-template>
<ng-template #conversionEndDateSourceTooltip>
  <lv-tooltip dm="dM-4838"></lv-tooltip>
</ng-template>
<ng-template #earlyExerciseFeeApplicableSourceTooltip>
  <lv-tooltip dm="dM-4841"></lv-tooltip>
</ng-template>
<ng-template #earlyExerciseFeeTooltip>
  <lv-tooltip dm="dM-4842"></lv-tooltip>
</ng-template>
<ng-template #conversionEndDateEarlyFeeSourceTooltip>
  <lv-tooltip dm="dM-4843"></lv-tooltip>
</ng-template>
<ng-template #feeFormulaTypeTooltipTemplate>
  <lv-tooltip dm="dM-4844"></lv-tooltip>
</ng-template>
<ng-template #daysInYearSourceTooltipTemplate>
  <lv-tooltip dm="dM-4845"></lv-tooltip>
</ng-template>
<ng-template #maxIntervalDaysSourceTooltip>
  <lv-tooltip dm="dM-4846"></lv-tooltip>
</ng-template>
<ng-template #busDaysToRepaymentSourceTooltip>
  <lv-tooltip dm="dM-4847"></lv-tooltip>
</ng-template>
