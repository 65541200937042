import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { DividendFrequencyEnum, IEquityDividendsSectionSettingsContent } from '@lv-analytics/models';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-dividends-equity-section-settings',
  templateUrl: './lv-dividends-equity-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvDividendsEquitySectionSettingsComponent {
  @Input() settings: IEquityDividendsSectionSettingsContent;
  @Input() overrideSystemDefaults: boolean;

  dividendsFrequency: LvLookupEnum;

  constructor() {
    this.dividendsFrequency = new LvLookupEnum(DividendFrequencyEnum);
    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-dividends-equity-section-settings')
  get isLvVolatilityEquitySectionSettingsComponent() {
    return true;
  }
}
