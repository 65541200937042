import { ConvertibleBondSubType } from '../convertible-bond-terms/models/convertible-bond-terms/ConvertibleBondSubType';
import { EquityData } from '../convertible-bond-terms/models/convertible-bond-terms/EquityData';
import { ExhangeableParameters } from '../convertible-bond-terms/models/convertible-bond-terms/ExhangeableParameters';
import { SinkingFundScheduleItem } from './SinkingFundScheduleItem';
import { RecoveryUponDefault } from './enums/RecoveryUponDefault';
import { SetupStatus } from './enums/SetupStatus';

export class IssueAndRedemption {

    subType: ConvertibleBondSubType;
    name: string;
    shortName: string;
    issuerName: string;
    underlyingEquity: EquityData;
    countryCode: string;
    riskCountryCode: string;
    currencyCode: string;
    maturingCurrencyCode: string;
    firstSettlementDate: Date;
    maturityDate: Date;
    isPerpetual: boolean;
    nominalValue: number;
    issueValue: number;
    issueValueIsPercentOfNominal: boolean;
    redemptionValue: number;
    redemptionValueIsPercentOfNominal: boolean;
    isPriceAsPar: boolean;
    isQuotedClean: boolean;
    fixedFXRate: number;
    underlyingCurrencyLinked: boolean;
    exhangeableParameters: ExhangeableParameters;
    isin: string;
    bloomberUniqueID: string;
    cusip: string;
    figi: string;
    issueSeries: string;
    exhangeCode: string;
    daysToSettle: number;
    shareRedemption: boolean;
    fiscalYearStartDate: Date;
    recoveryUponDefault: RecoveryUponDefault;
    isAccruedUponDefault: boolean;
    isCallable: boolean;
    isPuttable: boolean;
    useDividendProtection: boolean;
    fxQuanto: boolean;
    setupStatus: SetupStatus;
    percCoveredWarrants: number;
    sinkable: boolean;
    sinkingFundSchedule: SinkingFundScheduleItem[];
    isCleanUpCall: boolean;
    notes: string;
}

