import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { HttpClientBase } from "@lv-core-ui/api";
import { LvDateService } from "@lv-core-ui/services";
import { IEquityDto } from "./equity-dto";
import { IBondDto } from "./bond-dto";
import { LvBondTermsError } from "../instrument/bond-terms/models/error";
import { LvEquityInstrumentError } from "../instrument/equity/models/error";
import { IEquityIdentifiers } from "./models/equity-identifiers";
import { IEquitySettings } from "../instrument/equity/models/equity-terms/equity-settings";
import { IInstrumentView } from "./models/instrument-view";
import { CustomInstrument } from "@lv-custom-instruments/models";

@Injectable()
export class InstrumentsService extends HttpClientBase {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService
  ) {
    super(lvDateService, http, "/instruments/");
  }

  /**
   * Method handles save equity API call
   * @param request IEquityDto object
   */
  async saveEquity(request: IEquityDto, sourceId: string): Promise<IEquityDto> {
    try {
      return await this.postAsync<IEquityDto>(
        request,
        "equity",
        {
          sourceId: sourceId
        });
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  /**
   * Copy equity instrument.
   * @param leversysLocalId Leversys local identifier.
   */
  async copyEquity(leversysLocalId: string): Promise<IEquityDto> {
    try {
      return await this.postAsync<IEquityDto>(null, "equity/copy", {
        leversysLocalId: leversysLocalId,
      });
    } catch (error) {
      throw this.handleError(error, (e) => new LvBondTermsError(e.message));
    }
  }

  /**
   * Method handles save equity API call
   * @param id Equity id.
   */
  async getEquity(id: number): Promise<IEquityDto> {
    try {
      return await this.getAsync<IEquityDto>({ id: id }, "equity");
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  /**
   * Method handles save equity API call
   * @param leversysLocalId Leversys local identifier.
   */
  async getEquityByLeversysLocalId(
    leversysLocalId: string
  ): Promise<IEquityDto> {
    try {
      return await this.getAsync<IEquityDto>(
        { leversysLocalId: leversysLocalId },
        "equity/byLeversysLocalId"
      );
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  /**
   * Method handles save bond API call
   * @param request IBondDto object
   */
  async saveBond(request: IBondDto): Promise<IBondDto> {
    try {
      return await this.postAsync<IBondDto>(request, "bonds");
    } catch (error) {
      throw this.handleError(error, (e) => new LvBondTermsError(e.message));
    }
  }

  /**
   * Copy bond instrument.
   * @param leversysLocalId Leversys local identifier.
   */
  async copyBond(leversysLocalId: string): Promise<IBondDto> {
    try {
      return await this.postAsync<IBondDto>(null, "bonds/copy", {
        leversysLocalId: leversysLocalId,
      });
    } catch (error) {
      throw this.handleError(error, (e) => new LvBondTermsError(e.message));
    }
  }

  /**
   * Deletes bond.
   * @param leversysLocalId Draft ID.
   */
  async deleteBond(leversysLocalId: string): Promise<void> {
    try {
      await this.deleteAsync<any>(`bonds`, {
        leversysLocalId: leversysLocalId,
      });
    } catch (error) {
      throw this.handleError(error, (e) => new LvBondTermsError(e.message));
    }
  }

  async getEquityIdentifiers(
    leversysLocalId: string
  ): Promise<IEquityIdentifiers[]> {
    try {
      return await this.getAsync<IEquityIdentifiers[]>(
        { leversysLocalId: leversysLocalId },
        "equity/identifiers"
      );
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  async saveIdentifiers(
    leversysId: string,
    identifiersItems: IEquityIdentifiers[],
    sourceId: string
  ) {
    try {
      return await this.postAsync<any>(
        {
          leversysLocalId: leversysId,
          identifiers: identifiersItems,
          sourceId: sourceId,
        },
        "equity/identifiers"
      );
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  /**
   * Get equity settings.
   * @param leversysLocalId Leversys local identifier.
   * @returns
   */
  async getEquitySettings(leversysLocalId: string): Promise<IEquitySettings> {
    try {
      return await this.getAsync<any>(
        {
          leversysLocalId: leversysLocalId,
        },
        "equity/settings"
      );
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  /**
   * Save equity settings.
   * @param leversysLocalId Leversys local identifier.
   * @returns
   */
  async saveEquitySettings(
    equitySettings: IEquitySettings,
    leversysLocalId: string
  ): Promise<IEquitySettings> {
    try {
      return await this.postAsync<any>(
        {
          leversysLocalId: leversysLocalId,
          equitySettings: equitySettings,
        },
        "equity/settings"
      );
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  /**
   * Get all convertibles instrument infos.
   * @returns
   */
  async getEquitiesViews(): Promise<IInstrumentView[]> {
    try {
      return await this.getAsync<IInstrumentView[]>(
        null,
        "convertibleBond/underlyingInstruments"
      );
    } catch (error) {
      throw this.handleError(
        error,
        (e) => new LvEquityInstrumentError(e.message)
      );
    }
  }

  /**
   * Copy convertible bond instrument.
   * @param leversysLocalId Leversys local identifier.
   */
  async copyConvertibleBond(
    leversysLocalId: string,
    isUserAccessScope: boolean,
  ): Promise<CustomInstrument> {
    try {
      return await this.postAsync<CustomInstrument>(
        null,
        "convertibleBond/copy",
        {
          leversysLocalId: leversysLocalId,
          isUserAccessScope: isUserAccessScope,
          isFixedDatesSelected: false
        }
      );
    } catch (error) {
      throw this.handleError(error, (e) => new LvBondTermsError(e.message));
    }
  }

  /**
   * Copy convertible bond instrument.
   * @param leversysLocalId Leversys local identifier.
   */
  async copyConvertibleBondAsNewIssue(
    leversysLocalId: string,
    isUserAccessScope: boolean,
  ): Promise<CustomInstrument> {
    try {
      return await this.postAsync<CustomInstrument>(
        null,
        "convertibleBond/copyAsNewIssue",
        {
          leversysLocalId: leversysLocalId,
          isUserAccessScope: isUserAccessScope,
          isFixedDatesSelected: true
        }
      );
    } catch (error) {
      throw this.handleError(error, (e) => new LvBondTermsError(e.message));
    }
  }

  /**
   * Delete convertible bond instrument.
   * @param leversysLocalId Leversys local identifier.
   */
  async deleteConvertibleBond(
    leversysLocalId: string
  ): Promise<CustomInstrument> {
    try {
      return await this.deleteAsync<CustomInstrument>(
        "convertibleBond/delete",
        {
          leversysLocalId: leversysLocalId,
        }
      );
    } catch (error) {
      throw this.handleError(error, (e) => new LvBondTermsError(e.message));
    }
  }
}
