import { Component, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { LvConvertibleBondTermsPresenter } from '../../lv-convertible-bond-terms.presenter';
import { StockReferenceSignalStore } from '../../stores/lv-stock-reference.store';

@Component({
  selector: 'lv-terms-general-fx-information',
  templateUrl: './lv-terms-general-fx-information.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvTermsGeneralFxInformationComponent {
  @Input() stockReferenceSignalStore: StockReferenceSignalStore;
  @Output() didSetFxRate: EventEmitter<number>;

  constructor(private _presenter: LvConvertibleBondTermsPresenter) {
    this.didSetFxRate = new EventEmitter<number>();
  }

  doEmitFxRate(value: number): void {
    this.stockReferenceSignalStore.setFx(value);
    this.didSetFxRate.next(this.stockReferenceSignalStore.fx());
  }

  getGeneralFXRateTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
