import { AccretionType } from '@lv-instrument-common/enums/AccretionType';
import { IAccretionSectionSettings } from '../accreation-settings/accreation-section-settings';
import { DayCount, Frequency } from '@lv-analytics/models';
import { AccretionGrossNet } from '@lv-instrument-common/enums/AccretionGrossNet';

export const getDefaultAccretionSectionSettings = (): IAccretionSectionSettings => {
  return {
    type: AccretionType.UseRedemptionPrice,
    dayCount: DayCount.DayCount_ACT_ACT_ICMA,
    frequency: Frequency.SemiAnnual,
    grossNet: AccretionGrossNet.Gross,
    accretionEndDate: null,
    accretionStartDate: null
  };
};
