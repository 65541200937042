<lv-flex-box direction="row">
  <div showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="bucketingTooltipTemplate"
    filter=".lv-label" 
    class="lv-label lv-label-field lv-label--title">
    <span [attr.volatility-tooltip-id]="'bucketingTooltip'">
      BUCKETING
    </span> 
  </div>
</lv-flex-box>
<lv-advanced-grid
  data-cy="DM-4560"
  [columns]="columns"
  [records]="settings.bucketList"
  lvId="lvBucketingSettingsGrid"
  [createFormGroup]="createFormGroup"
  [editable]="true"
  sortBy="tenor"
  [sortComparerFn]="convertTenorToNumber"
  class="lv-bucketing-section-settings-table">
</lv-advanced-grid>

<ng-template #bucketingTooltipTemplate>
  <lv-tooltip dm="dM-4560"></lv-tooltip>
</ng-template>