import * as _ from 'lodash';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { PricingEnvironmentSections } from '@lv-analytics/models';
import { IBondWithholdingTax } from '@lv-analytics/models/bond/market-data/withholding-tax/bond-withholding-tax';
import { LvErrorService } from '@lv-core-ui/services';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ISaveBondWithholdingTaxRequest } from '@lv-analytics/models/bond/market-data/withholding-tax/save-bond-withholding-tax-request';
import { filter } from 'rxjs';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';

@Component({
  selector: 'lv-bond-withholding-tax',
  templateUrl: './lv-bond-withholding-tax.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LvBondWithholdingTaxComponent implements OnInit {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;

  get cbCcy(): string {
    return 'USD';
    // return this._analyticsPresenter.cHelper.currencyCode;
  }

  get isWitholdingTaxSectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;
  @Output() didSessionDataLoadedEvent: EventEmitter<void>;
  @Output() isSectionLoading: EventEmitter<boolean>;

  withholdingTaxSection = PricingEnvironmentSections.WitholdingTax;
  withholdingTaxSettings: IBondWithholdingTax;
  originalValue: IBondWithholdingTax;

  numberOfDecimalsPercentage = '2';
  numberFormatPercentage = '#.##';

  constructor(
    private _destroyRef: DestroyRef,
    private _errorService: LvErrorService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
  ) {
    this.withholdingTaxSettings = {} as IBondWithholdingTax;
    this.didSessionUpdatedEvent = new EventEmitter<void>();
    this.didSessionDataLoadedEvent = new EventEmitter();
    this.isSectionLoading = new EventEmitter<boolean>();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {

    if (!!this._analyticsPresenter.getModelData()?.bondValuationSession) {
      this.withholdingTaxSettings = this._analyticsPresenter.getModelData().bondValuationSession.marketData?.witholdingTax || {} as IBondWithholdingTax;
      this.originalValue = _.cloneDeep(this.withholdingTaxSettings);
    }

    this._analyticsPresenter.onAnalyticsSettingsUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        if(!!this._analyticsPresenter.getModelData()?.bondValuationSession?.marketData){
          this.setWithholdingTax();
        }
      });

      this._analyticsPresenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        if (evt) {
          if (!!this._analyticsPresenter.getModelData()?.bondValuationSession?.marketData) {
            this.withholdingTaxSettings = this._analyticsPresenter.getModelData().bondValuationSession.marketData?.witholdingTax;
            this.originalValue = _.cloneDeep(this.withholdingTaxSettings); 
          }        
        }
  
        this._changeDetectorRef.detectChanges();
    }); 
  }

  /**
   * Occurs on change environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadWithHoldingTax(environment);
    this.didSessionDataLoadedEvent.next();
  }

  /**
   * Gets selected environment ID.
   * @returns Selected environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();

    if (env) {
      return env.id;
    }

    return null;
  }

  /**
   * Gets settings.
   * @returns IWitholdingTaxSaveRequest object.
   */
  getSettings(): ISaveBondWithholdingTaxRequest {
    return this.getWitholdingTaxSaveRequest();
  }

  /**
   * Set withholding tax model.
   *
   * @private
   * @memberof LvBondWithholdingTaxComponent
   */
  private setWithholdingTax() {
    if (!!this._analyticsPresenter.getModelData()?.bondValuationSession) {
      this.withholdingTaxSettings = this._analyticsPresenter.getModelData().bondValuationSession.marketData?.witholdingTax;
    }
    this.originalValue = _.cloneDeep(this.withholdingTaxSettings);

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Gets witholding tax save request.
   * @returns IWitholdingTaxSaveRequest object.
   */
  private getWitholdingTaxSaveRequest(): ISaveBondWithholdingTaxRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      environmentId: env.id,
      witholdingTax: this.withholdingTaxSettings
    } as ISaveBondWithholdingTaxRequest;
  }

  /**
  * Gets section.
  * @returns PricingEnvironmentSections object.
  */
  getSection(): PricingEnvironmentSections {
    return this.withholdingTaxSection;
  }

  /**
 * Applies current changes.
 */
  applyCurrentChanges() {

  }



  /**
   * Loads with holding tax.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadWithHoldingTax(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      this._analyticsPresenter.loadBondWitholdingTax(environment);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Overrides withholding tax.
   */
  async overrideWithholdingTax() {
    this._analyticsPresenter.overrideBondInSession(bondValuationSession => {
      bondValuationSession.marketData.witholdingTax = this.withholdingTaxSettings;
    }, true);

    if (!_.isEqual(this.withholdingTaxSettings, this.originalValue)) {
      this.didSessionUpdatedEvent.next();
      this.originalValue = _.cloneDeep(this.withholdingTaxSettings);
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this.isSectionLoading.next(isLoading);

    this._changeDetectorRef.detectChanges();
  }
}
