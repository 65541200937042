import {
  Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef,
  OnDestroy, ViewChild, ElementRef, Optional
} from '@angular/core';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { LvDividendProtectionView } from './lv-dividend-protection.view';
import { LvDividendProtectionScheduleComponent } from './lv-dividend-protection-schedule/lv-dividend-protection-schedule.component';
import { ConvertibleBondTermsSectionEvent, DividendProtectionTresholdType } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

@Component({
  selector: 'lv-dividend-protection',
  templateUrl: './lv-dividend-protection.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDividendProtectionComponent implements OnInit, OnDestroy {

  @ViewChild('dividendProtectionSchedule') dividendProtectionSchedule: LvDividendProtectionScheduleComponent;

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  private subscriptions: Subscription;
  view: LvDividendProtectionView;

  constructor(
    private _presenter: LvConvertibleBondTermsPresenter,
    private _changeDetectorRef: ChangeDetectorRef,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.view = new LvDividendProtectionView(_lvConvertibleBondTermsService);
  }

  ngOnInit() {
    this.subscriptions = this._presenter.onModelUpdated
      .pipe(
        filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.DividendsProtectionEvent &&
          event.eventId !== ConvertibleBondTermsSectionEvent.DividendProtectionIssueAndRedemption &&
          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
      )
      .subscribe(model => {
        if (model.data) {
          this.view.init(model.data.fullTerms.dividendProtection, model.data.fullTerms.issueAndRedemption);
          this._changeDetectorRef.detectChanges();
        }
      });

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(fullTerms.dividendProtection,
        fullTerms.issueAndRedemption);
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  dividendStartDateCheckBoxChanged(value: boolean) {
    this.view.dividendStartDateCheckBoxChanged(value);
    this.onModelChange();
  }

  dividendEndDateCheckBoxChanged(value: boolean) {
    this.view.dividendEndDateCheckBoxChanged(value);
    this.onModelChange();
  }

  onDividendProtectionChange() {
    this.view.dividendProtectionChange();

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.DividendProtectionIssueAndRedemption,
      data: this.view.issueAndRedemption,
      sourceOfUpdate: 'other'
    });
    this.onModelChange();
  }

  onIncludeColumnsChange() {
    this.view.setDividendProtectionCustom();
    this.onModelChange();
  }

  applyScheduleChanges() {
    if (this.view.dividendProtection && this.dividendProtectionSchedule) {
      this.dividendProtectionSchedule.applyAdvancedGridChanges();
    }
  }

  onThresholdTypeOrScheduleChange() {
    if (this.view.dividendProtection.tresholdType === DividendProtectionTresholdType.Schedule) {
      this._presenter.setDividendThresholdOnQuickTerms();
    }
    this.onModelChange();
  }

  onModelChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.DividendsProtectionEvent,
      data: this.view.dividendProtection,
      sourceOfUpdate: 'other'
    });
  }

  getDividendProtectionTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
