import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LvAdvancedGridColumn, LvAdvancedGridComponent, LvAdvancedGridEnumColumn, LvAdvancedGridTextColumn } from '@lv-core-ui/components';
import { Identifiers } from '@lv-instrument-common/Identifiers';
import { Subscription } from 'rxjs';
import { LvBondTermsPresenter } from '../lv-bond-terms.presenter';
import { LvErrorService } from '@lv-core-ui/services';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { IdentifierType, BondIdentifierTypeDescription } from '@lv-instrument-common/enums/identifier-type-enum';
import { LvLookupEnum } from '@lv-core-ui/util';
import { MarketDataClipboard } from '@lv-analytics/components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { BondTermsDocument } from '../../../models/bond-terms/BondTermsDocument';
import { BondTermsSectionEvent } from '../../../models/bond-terms/enums/bond-terms-section-events';

@Component({
  selector: 'lv-bond-identifiers',
  templateUrl: './lv-bond-identifiers.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBondIdentifiersComponent implements OnInit, OnDestroy {
  @ViewChild(LvAdvancedGridComponent, { static: false }) advancedGrid: LvAdvancedGridComponent;
  @Input() identifiersItems: Identifiers[];
  @Input() leversysLocalId: string;

  get leversysId(): string {
    return this.leversysLocalId;
  }

  tenorFilterDict: {
    [code: string]: boolean
  };

  columns: LvAdvancedGridColumn[];

  parseFn: any;

  private _modelSubscription: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,
    private _errorService: LvErrorService
  ) {

    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);
    this.identifiersItems = [];
  }

  ngOnInit() {
    this._changeDetectorRef.detectChanges();
    this.setupGridSubscriptions();
  }

  private setupGridSubscriptions() {
    if (this.advancedGrid) {
      this._modelSubscription = [
        this.advancedGrid.didDataChange.subscribe((records: Identifiers[]) => this.onScheduleChange(records)),
        this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
        this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
      ];
    }
  }

  private setTenorFilterDict(records: Identifiers[]) {
    this.tenorFilterDict = {};
    records?.forEach(a => this.tenorFilterDict[a.identifierType] = true);
  }

  private initColumns() {
    this.columns = [];

    const identifierColumn = new LvAdvancedGridTextColumn();
    identifierColumn.title = 'IDENTIFIER';
    identifierColumn.field = 'identifier';
    identifierColumn.placeholder = '';
    identifierColumn.isOutputColumn = false;
    identifierColumn.editable = true;
    identifierColumn.width = 120;

    const identifierTypeColumn = new LvAdvancedGridEnumColumn();
    identifierTypeColumn.enumDescription = BondIdentifierTypeDescription;
    identifierTypeColumn.title = 'IDENTIFIER TYPE';
    identifierTypeColumn.field = 'identifierType';
    identifierTypeColumn.displayField = 'text';
    identifierTypeColumn.valueField = 'id';
    identifierTypeColumn.valuePrimitive = true;
    identifierTypeColumn.data = new LvLookupEnum(BondIdentifierTypeDescription).items.filter(x => x.id !== IdentifierType.LeversysLocalID);
    identifierTypeColumn.width = 140;
    identifierTypeColumn.setFilterFn(item => {
      if (this.tenorFilterDict) {
        return !this.tenorFilterDict[item.id];
      }

      return true;
    });
    identifierTypeColumn.isOutputColumn = false;
    identifierTypeColumn.editable = true;

    this.columns.push(identifierTypeColumn);
    this.columns.push(identifierColumn);
  }

  private parserFunction(pastedDataRecords: string[]): Identifiers[] {
    const scheduleItems: Identifiers[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const identifierTypeValue = items[0];
      const identifierValue = items[1];

      const identifierType = this.parseIdentifierType(identifierTypeValue);
      const identifier = identifierValue;


      scheduleItems.push({
        identifierType: identifierType,
        identifier: identifier
      } as Identifiers);
    });

    return scheduleItems;
  }

  private parseIdentifierType(identifierTypeValue: string): IdentifierType {

    if (MarketDataClipboard.isEmpty(identifierTypeValue)) {
      throw new LvError(`Value should not be empty`);
    }

    const identifierTypeEnum = IdentifierType[identifierTypeValue];
    if (!identifierTypeEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{value}', identifierTypeValue], ['{field}', 'Identifier Type']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return identifierTypeEnum;
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  private onScheduleReload() {
    this.identifiersItems = this.identifiersItems.map(a => ({ ...a }));
  }

  private onScheduleChange(scheduleItems: Identifiers[]) {
    if (!this.checkDuplicateInObject('identifierType', scheduleItems)) {
      this.applyRecords(scheduleItems);
      this.setTenorFilterDict(scheduleItems);

      this._presenter.updateModel((terms: BondTermsDocument) => {
        terms.identifiers = this.identifiersItems;
      }, BondTermsSectionEvent.BondIdentifiersEvent,
        'other');

    } else {
      this._errorService.toastrService.warning(LvDataMaster.getWarning('dM-1860'));

      const filteredArr = this.removeDuplicateItem(scheduleItems);
      this.applyRecords(filteredArr);
      this.identifiersItems = this.identifiersItems.map(a => ({ ...a }));
      this._changeDetectorRef.detectChanges();
    }
  }

  private applyRecords(records: any[]) {
    this.identifiersItems.splice(0, this.identifiersItems.length);
    this.identifiersItems.push(...records);
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

  applyScheduleChanges() {
    if (this.identifiersItems && this.advancedGrid) {
      this.advancedGrid.applyChanges(records => this.applyRecords(records));
      this._changeDetectorRef.detectChanges();
    }
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'identifierType': new FormControl(args.dataItem.identifierType, Validators.required),
      'identifier': new FormControl(args.dataItem.identifier, Validators.required),
    });
  }

  checkDuplicateInObject(propertyName, inputArray) {
    let seenDuplicate = false;
    const testObject = {};

    inputArray?.map(item => {
      const itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      }
      else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  removeDuplicateItem(scheduleItems: Identifiers[]) {
    return scheduleItems?.reduce((acc, current) => {
      const x = acc.find(item => item.identifierType === current.identifierType);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  mapIdentifierTypeToIdentifierTypeDesciption(identifierType: string) {
    return BondIdentifierTypeDescription[identifierType];
  }

  /**
   * Gets element tooltip ID.
   * @param anchor Anchor.
   * @param element Element.
   * @returns Tooltip ID.
   */
  getTootlipId(anchor: ElementRef, element: string) {
    return anchor.nativeElement.getAttribute('tooltip-id') === element;
  }
}
