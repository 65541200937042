/**
 * Volatility types.
 */
export enum VolatilityTypeAsw {
    Flat = 'Flat',
    TermStructure = 'TermStructure',
    VolatilitySurface = 'VolatilitySurface',
    UnderlyingCBData = 'UnderlyingCBData',
    UnderlyingEquityDataFlat = 'UnderlyingEquityDataFlat',
    UnderlyingEquityDataTermStructure = 'UnderlyingEquityDataTermStructure',
    UnderlyingEquityDataSurface = 'UnderlyingEquityDataSurface',
}

/**
 * Volatility type descriptions.
 */
export enum VolatilityTypeAswDescription {
    Flat = 'Flat',
    TermStructure = 'Term Structure',
    VolatilitySurface = 'Surface',
    UnderlyingCBData = 'Underlying CB Data',
    UnderlyingEquityDataFlat = 'Underlying Equity Data - Flat',
    UnderlyingEquityDataTermStructure = 'Underlying Equity Data - Term Structure',
    UnderlyingEquityDataSurface = 'Underlying Equity Data - Surface',
}