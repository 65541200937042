export enum BondTermsSectionEvent {
    ModelLoadedEvent = 'ModelLoadedEvent',
    TermsGeneralEvent = 'TermsGeneralEvent',
    TermsGeneralDatesEvent = 'TermsGeneralDatesEvent',
    TermsAccretionEvent = 'TermsAccretionEvent',
    TermsCallEvent = 'TermsCallEvent',
    CallIssueAndRedemptionEvent = 'CallIssueAndRedemptionEvent',
    TermsCleanUpCallEvent = 'TermsCleanUpCallEvent',
    TermsPutEvent = 'TermsPutEvent',
    PutIssueAndRedemptionEvent = 'PutIssueAndRedemptionEvent',
    MWCallsEvent = 'MWCallsEvent',
    MakeWholeEvent = 'MakeWholeEvent',
    TermsCouponEvent = 'TermsCouponEvent',
    BondIdentifiersEvent = 'BondIdentifiersEvent',
}