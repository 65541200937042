
export enum AdjustmentReason {
	CashDividend = 'CashDividend',
	StockDividend = 'StockDividend',
	StockSplit = 'StockSplit',
	RightsIssue = 'RightsIssue',
	CarryForwardReset = 'CarryForwardReset',
	SpinOff = 'SpinOff',
	ConversionPriceReset = 'ConversionPriceReset',
	Other = 'Other',
	Initial = 'Initial',
	BonusIssue = 'BonusIssue',
	ReturnOfCapital = 'ReturnOfCapital',
	Takeover = 'Takeover',
	Merger = 'Merger',
	CapitalIncrease = 'CapitalIncrease',
	Restructuring = 'Restructuring',
	CorporateAction = 'CorporateAction'
}
