<lv-loader *ngIf="isLoading"></lv-loader>

<lv-flex-box *ngIf="isBondSelected" class="lv-private-instrument">
  <lv-loader-panel *ngIf="isLoading"></lv-loader-panel>
  <lv-flex-box direction="row">
    <div class="lv-environment-settings-controls" kendoTooltip>
      <div class="lv-icon-square" title="Save" lvId="bondTermsSaveAll" (click)="onSaveInstrument()">
        <i class="k-icon k-i-save"></i>
      </div>
      <div class="lv-icon-square" title="Reload" lvId="bondTermsReloadAll" (click)="onReload()">
        <i class="k-icon k-i-reload"></i>
      </div>
      <div class="lv-dates-logic" lvId="privateInstrumentSettings">
        <i class="k-icon k-i-gear"
          (click)="onShowSettings()">
        </i>
      </div>
    </div>
  </lv-flex-box>
  <lv-flex-box class="lv-convertible-bond-terms-wrapper">
    <lv-loader *ngIf="isLoading"></lv-loader>
    <kendo-tabstrip class="lv-convertible-bond-terms" #tabStrip 
      [class.lv-convertible-bond-terms-two-rows]="lvBondTermsUtil.hasconvertibleBondTermsTwoRows"
      [class.lv-convertible-bond-terms-three-rows]="lvBondTermsUtil.hasconvertibleBondTermsThreeRows"
      [class.lv-convertible-bond-terms-four-rows]="lvBondTermsUtil.hasconvertibleBondTermsFourRows"
      [class.lv-convertible-bond-terms-five-rows]="lvBondTermsUtil.hasconvertibleBondTermsFiveRows"
      [class.lv-convertible-bond-terms-six-rows]="lvBondTermsUtil.hasconvertibleBondTermsSixRows"
      [class.lv-convertible-bond-terms-seven-rows]="lvBondTermsUtil.hasconvertibleBondTermsSevenRows"
      [class.lv-convertible-bond-terms-eight-rows]="lvBondTermsUtil.hasconvertibleBondTermsEightRows"
      [keepTabContent]="true">
      
      <kendo-tabstrip-tab
        title="General"
        [selected]="true"
        cssClass="DM-5437">
        <ng-template kendoTabContent>
          <lv-bond-general
            [model]="bondDocument.general"
            data-cy="DM-5437">
          </lv-bond-general>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="Coupon"
        cssClass="DM-5438">
        <ng-template kendoTabContent>
          <lv-bond-coupon
          #couponComponent
          data-cy="DM-5438"
          [model]="bondDocument.coupon">
          </lv-bond-coupon>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="Accretion"
        cssClass="DM-5439">
        <ng-template kendoTabContent>
          <lv-bond-accretion
          [model]="bondDocument.accretion"
          data-cy="DM-5439">
          </lv-bond-accretion>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="Calls"
        cssClass="DM-5440">
        <ng-template kendoTabContent>
          <lv-bond-calls
            #callComponent
            [model]="bondDocument.call"
            data-cy="DM-5440">
          </lv-bond-calls>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="Puts"
        cssClass="DM-5441">
        <ng-template kendoTabContent>
          <lv-bond-puts
            #putComponent
            [model]="bondDocument.put"
            [isPuttable]="bondDocument.general.isPuttable"
            data-cy="DM-5441">
          </lv-bond-puts>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="Make-Whole"
        [disabled]="!isMakeWholeEnabled"
        cssClass="DM-5442">
        <ng-template kendoTabContent>
          <lv-bond-make-whole
            [model]="bondDocument.call.callMakeWhole"
            [recieveMakeWhole]="bondDocument.call.recieveMakeWhole"
            [isCallable]="bondDocument.general.isCallable"
            data-cy="DM-5442">
          </lv-bond-make-whole>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="Identifiers"
        cssClass="DM-5443">
        <ng-template kendoTabContent>
          <lv-bond-identifiers
            [identifiersItems]="bondDocument.identifiers"
            [leversysLocalId]="leversysLocalId"
            data-cy="DM-5443">
          </lv-bond-identifiers>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </lv-flex-box>

  <lv-side-panel
    width="246"
    height="74"
    position="right"
    [floating]="true"
    data-cy="DM-5444">
    <ng-container>
      <div *ngIf="isFixDatesVisible" class="lv-side-panel-link-item"
        lvId="lv-tools-display-fixed-dates">
        <input class="k-radio"
          type="radio"
          [attr.id]="fixDatesId"
          [attr.name]="fixDatesId"
          [value]="true"
          [(ngModel)]="isFixDates"
          (ngModelChange)="onSaveInstrument()">
        <label class="k-radio-label"
          [attr.for]="fixDatesId"
          lvId="lv-quick-terms-fix-dates"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="privateInstrumentTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'rollingDatesSourceTooltip'">
            Rolling Dates
          </span>   
        </label>
      </div>

      <div class="lv-side-panel-link-item"
        lvId="lv-tools-display-update-dates">
        <input class="k-radio"
          type="radio"
          [attr.id]="updateDatesId"
          [attr.name]="updateDatesId"
          [value]="false"
          [(ngModel)]="isFixDates"
          (ngModelChange)="onSaveInstrument()">
        <label class="k-radio-label"
          [attr.for]="updateDatesId"
          lvId="lv-quick-terms-update-dates"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="privateInstrumentTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'fixedDatesSourceTooltip'">
            Fixed Dates
          </span>   
        </label>
      </div>
    </ng-container>
  </lv-side-panel>
</lv-flex-box>

<div *ngIf="!isBondSelected" class="lv-instrument-no-data">
  Please select an instrument.
</div>


<ng-template #privateInstrumentTooltipTemplate let-anchor>
  <div class="popup-private-instrument-content">
    <ng-container *ngIf="getPrivateInstrumentTootlipId(anchor, 'rollingDatesSourceTooltip')">
      Dates will be rolled as time passes, using today as the first settlement date.
    </ng-container>
    <ng-container *ngIf="getPrivateInstrumentTootlipId(anchor, 'fixedDatesSourceTooltip')">
      Dates will be fixed as time passes.
    </ng-container>
  </div>
</ng-template>