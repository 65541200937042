import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
  ElementRef,
  DestroyRef} from '@angular/core';
import { NgForm } from '@angular/forms';

import { filter, Subscription } from 'rxjs';

import { DeltaNeutralView } from '../../views/delta-neutral';
import { LvAnalyticsPresenter } from '../../lv-analytics.presenter';
import { LvDateService, ResizeHandlerService } from '@lv-core-ui/services';
import { LvFormUtil } from '@lv-core-ui/util';
import { SectionUsed, IToolsLogEvent } from '@lv-shared/models';
import { LvSharedUserActivityService } from '@lv-shared/services';
import { IDeltaNeutralState, AnalyticsEvents, AnalyticsCommands, AnalyticsSettingsEvents } from '@lv-analytics/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Delta neutral component.
 */
@Component({
  selector: 'lv-delta-neutral',
  templateUrl: './lv-delta-neutral.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDeltaNeutralComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form: NgForm;
  @ViewChild('deltaNeutral', { static: true }) deltaNeutralElement: ElementRef;

  @Input()
  set state(value: IDeltaNeutralState) {
    this._state = value;
    this._changeDetectorRef.detectChanges();
  }
  get state(): IDeltaNeutralState {
    return this._state;
  }
  _state: IDeltaNeutralState;

  view: DeltaNeutralView;
  isLoading: boolean;
  isNarrow: boolean;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public _presenter: LvAnalyticsPresenter,
    private _resizeHandlerService: ResizeHandlerService,
    private _userActivityService: LvSharedUserActivityService,
    private _lvDateService: LvDateService,
    private _destroyRef: DestroyRef,
  ) {
    this.view = new DeltaNeutralView(_lvDateService);
    this.isLoading = false;
    this.isNarrow = false;
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._presenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
            this.view.init(this._presenter.getModelData());
        }
        else {
          this.view.init(null);
        }

        this._changeDetectorRef.detectChanges();
      }),
      this._presenter.onPricingModelUpdated.subscribe(evt => {
        if (evt) {
          this.view.onPricingInputsChanged(evt.data.pricing);
          this.onDeltaChange(true);
          this._changeDetectorRef.detectChanges();
        }
      }),

      this._presenter.onEventPublished.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsEvents.ValuationCompleted) {
            this.view.onValuationUpdateChange(evt.data);
            this.view.setDeltaStock();
            this.view.calculateViewOutputs();
            this._changeDetectorRef.detectChanges();
          }
        }
      }),

      this._presenter.onCommandExecuted.subscribe(command => {
        if (command === AnalyticsCommands.ReloadPricingAndTools
          || command === AnalyticsCommands.ReloadAssumptionsAndToolsAndAnalysis) {
            this.view.init(this._presenter.getModelData());
            this._changeDetectorRef.detectChanges();
          }
      }),
      this._presenter.onEventPublished.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsSettingsEvents.OtherSettingsUpdated) {
            this.view.init(this._presenter.getModelData());
            this._changeDetectorRef.detectChanges();
          }
        }
      })
    ];

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(event => {
        this.view.init(this._presenter.getModelData());
        this._changeDetectorRef.detectChanges();
      });

    this._presenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event?.eventId === 'ValuationSettingsModelUpdated'))
      .subscribe(async event => {
        this.view.init();
        this._changeDetectorRef.detectChanges();
      });

    if (this._presenter.isConvertibleModelLoaded()) {
      this.view.init(this._presenter.getModelData());
      this._changeDetectorRef.detectChanges();
    }

    this._resizeHandlerService.listenTo(this.deltaNeutralElement, elementRect => {
      this.isNarrow = elementRect.width < 450;
      this._changeDetectorRef.detectChanges();
    });
  }

  /**
   * Occurs on delta change and sets delta stock and calculates view outputs.
   * @param isFromEvent A flag indicating if event should be logged in user activity.
   */
  onDeltaChange(isFromEvent: boolean = false) {
    if (this.form.valid) {
      this.view.setDeltaStock();
      this.view.calculateViewOutputs();

      if (!isFromEvent) {
        this.logEventToUsetActivity();
      }
    }
    else {
      LvFormUtil.markAllControlsAsTouched(this.form);
    }
  }

  /**
   * Occurs on delta convertible change and sets delta stock and PNL.
   */
  onDeltaConvertibleChange() {
    this.view.setDeltaStock();
    this.view.setPnL();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on stock change and sets delta and PNL.
   */
  onStockChange() {
    this.view.setDelta();
    this.view.setPnL();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on stock EQT0 change and sets stock CBT0 and calculates view outputs.
   */
  onStockEQT0Change() {
    this.view.calculateStockCBT0();
    this.view.calculateViewOutputs();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on stock EQT1 change and sets stock CBT1 and calculates view outputs.
   */
  onStockEQT1Change() {
    if (this.form.valid) {
      this.view.calculateStockCBT1();
      this.view.calculateViewOutputs();
      this.logEventToUsetActivity();
    }
    else {
      LvFormUtil.markAllControlsAsTouched(this.form);
    }
  }

  /**
   * Occurs on stock FxT0 change and sets stock CBT0 and calculates view outputs.
   */
  onCrossFxT0Change() {
    this.view.calculateStockCBT0();
    this.view.calculateViewOutputs();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on stock FxT1 change and sets stock CBT1 and calculates view outputs.
   */
  onCrossFxT1Change() {
    this.view.calculateStockCBT1();
    this.view.calculateViewOutputs();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on stock CBT1 change and sets stock EQT1 and calculates view outputs.
   */
  onStockCBT1Change() {
    this.view.calculateStockEQT1();
    this.view.calculateViewOutputs();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on stock CBT0 change and sets stock EQT0 and calculates view outputs.
   */
  onStockCBT0Change() {
    this.view.calculateStockEQT0();
    this.view.calculateViewOutputs();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on convertible T0 change and sets delta stock and calculates view outputs.
   */
  onConvertibleT0Change() {
    if (this.form.valid) {
      this.view.setDeltaStock();
      this.view.calculateViewOutputs();
      this.logEventToUsetActivity();
    }
    else {
      LvFormUtil.markAllControlsAsTouched(this.form);
    }
  }

  /**
   * Occurs on convertible T1 change and calculates view outputs.
   */
  onConvertibleT1Change() {
    this.view.calculateViewOutputs();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on delta neutral CCY change and sets delta stock and PNL.
   */
  onDeltaNeutralCCYChange() {
    this.view.setDeltaStock();
    this.view.setPnL();
    this.logEventToUsetActivity();
  }

  /**
   * Occurs on delta FX change and sets delta stock and calculates view outputs.
   */
  onDeltaFXChange() {
    if (this.form.valid) {
      this.view.setDeltaStock();
      this.view.calculateViewOutputs();
      this.logEventToUsetActivity();
    }
    else {
      LvFormUtil.markAllControlsAsTouched(this.form);
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  setLoadingState(isLoading) {
    this.isLoading = isLoading;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Logs event to user activity.
   */
  private logEventToUsetActivity() {
    this._userActivityService.logToolsEvent({
      sectionUsed: SectionUsed.DELTA_NEUTRAL,
      bondCountry: this.view.convertibleCountryCode,
      bondType: this.view.convertibleStatus,
      isPrivateInstrument: this.view.isPrivate
    } as IToolsLogEvent);
  }
}
