export class LvLocalization {
  public static getBrowserLocale(): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return 'en-US';
    }

    return window.navigator.language || window.navigator['browserLanguage'];
  }

  public static getLocalDateFormat(): string {
    return this.getLocalizedDateFormat(false);
  }

  public static getLocalDateTimeFormat(): string {
    return this.getLocalizedDateFormat(true);
  }

  public static getLocalizedDateFormat(formatWithTime: boolean): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    if (formatWithTime) {
        options.hour = 'numeric';
        options.minute = 'numeric';
    }

    const formatter = new Intl.DateTimeFormat(LvLocalization.getBrowserLocale(), options);
    const parts = formatter.formatToParts();
    let dateFormat = '';

    for (const part of parts) {
        switch (part.type) {
            case 'year':
                dateFormat += 'YYYY';
                break;
            case 'month':
                dateFormat += 'MM';
                break;
            case 'day':
                dateFormat += 'DD';
                break;
            case 'hour':
                if (formatWithTime) {
                    dateFormat += 'HH';
                }
                break;
            case 'minute':
                if (formatWithTime) {
                    dateFormat += 'mm';
                }
                break;
            default:
                dateFormat += part.value;
        }
    }

    return dateFormat;
  }
}

