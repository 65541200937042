<lv-flex-box
  class="lv-create-instrument-modal"
  data-cy="DM-4689">
  <lv-flex-box
    class="lv-create-instrument-modal-content">
    <lv-flex-box direction="row">
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="useConvertibleRadioButtonId"
          name="convertible"
          [(ngModel)]="instrumentType"
          value="Convertible">
        <label class="k-radio-label"
          [attr.for]="useConvertibleRadioButtonId"
          lvId="lv-create-instrument-convertible">
            Convertible
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row">
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="useEquityRadioButtonId"
          name="equity"
          [(ngModel)]="instrumentType"
          value="Equity">
        <label class="k-radio-label"
          [attr.for]="useEquityRadioButtonId"
          lvId="lv-create-instrument-equity">
            Equity
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row">
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="useBondRadioButtonId"
          name="bond"
          [(ngModel)]="instrumentType"
          value="Bond">
        <label class="k-radio-label"
          [attr.for]="useBondRadioButtonId"
          lvId="lv-create-instrument-bond">
            Bond
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box *ngIf="hasBetaAccessPermission" direction="row">
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="useAssetSwapRadioButtonId"
          name="assetSwap"
          value="Asset Swap"
          [(ngModel)]="instrumentType">
        <label class="k-radio-label"
          [attr.for]="useAssetSwapRadioButtonId"
          lvId="lv-create-instrument-asset-swap">
          Asset Swap
        </label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box class="lv-create-instrument-modal-footer" direction="row">
      <button kendoButton
        [primary]="true"
        lvId="createInstrumentCancel"
        (click)="onCancel()">
          Cancel
      </button>
      <button kendoButton
        [primary]="true"
        lvId="createInstrumentSave"
        (click)="onCreate()">
          Create
      </button>
    </lv-flex-box>
</lv-flex-box>
