import { v4 } from 'uuid';

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpClientBase } from '@lv-core-ui/api';
import { LvErrorType } from '@lv-core-ui/models';
import { LvLocalization, LvUtil } from '@lv-core-ui/util';
import { ITermsSummarySection, LvReferenceDataError, ITermsSummarySections } from '@lv-reference-data/models';
import { LvDateService } from '@lv-core-ui/services';

@Injectable()
export class TermsSummaryService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/referenceData/termsSummary');
  }

  getSections(): ITermsSummarySection[] {
    const config = this.getSectionsConfig();
    return Object.keys(config).map(a => ({
      id: v4(),
      url: a,
      title: config[a],
      base64: ''
    } as ITermsSummarySection));
  }

  async loadSectionImage(sectionUrl: string, leversysLocalId: string, width: number = 1300): Promise<string> {
    try {
      const blob = await this.downloadImage({
        width: width,
        browserDateFormat: LvLocalization.getLocalDateFormat(),
        devicePixelRatio: this.getPixelRatio()
      }, `/${leversysLocalId}/${sectionUrl}`);

      const b64 = await LvUtil.convertToBase64(blob.body);
      return b64;
    }
    catch (error) {
      throw this.handleError(error, e => {
        const err = new LvReferenceDataError(e.message);

        if (e.type === LvErrorType.AUTHORIZATION) {
          err.type = e.type;
        }

        return err;
      });
    }
  }

  private getSectionsConfig(): ITermsSummarySections {
    return {
      issueDetails: `Issuer Details`,
      conversionDetails: `Conversion Details`,
      coupon: `Coupon`,
      contingentConversion: `Contingent Conversion`,
      conversionPriceReset: `Conversion Price Reset`,
      variableConversion: `Variable Conversion`,
      call: `Call`,
      put: `Put`,
      dividendProtection: `Dividend Protection`,
      takeoverProtection: `Takeover Protection`,
      makeWhole: `Make Whole`,
      issuance: `Issuance`,
    };
  }

  private getPixelRatio(): number {
    return Math.ceil(window.devicePixelRatio);
  }
}
