/**
 * Credit model type description.
 */
export enum CreditModelTypeDescription {
  None = 'None',
  ReducedFormModel = 'Reduced Form Model',
  StructuralModel = 'Structural Model',
  Hybrid = 'Hybrid'
}

/**
 * Types of credit models.
 */
export enum CreditModelType {
  None = 'None',
  ReducedFormModel = 'ReducedFormModel',
  StructuralModel = 'StructuralModel',
  Hybrid = 'Hybrid'
}

/**
 * Equity model type description.
 */
export enum EquityModelTypeDescription {
  RiskyEquity = 'Risky Equity',
  RiskFreeEquity = 'Risk Free Equity',
  RiskyDiscounting = 'Risky Discounting'
}

/**
 * Types of equity model.
 */
export enum EquityModelType {
  RiskyEquity = 'RiskyEquity',
  RiskFreeEquity = 'RiskFreeEquity',
  RiskyDiscounting = 'RiskyDiscounting'
}

export enum BondCreditModelType {
  None = 'None',
  ReducedFormModel = 'ReducedFormModel',
}

export enum BondCreditModelTypeDescription {
  None = 'None',
  ReducedFormModel = 'Reduced Form Model',
}

/**
 * Stochastic credit model type description.
 */
export enum StochasticCreditModelTypeDescription {
  CoxIngersollRoss = 'Cox Ingersoll Ross',
  Lognormal = 'Lognormal'
}

/**
 * Types of stochastic credit model.
 */
export enum StochasticCreditModelType {
  CoxIngersollRoss = 'CoxIngersollRoss',
  Lognormal = 'Lognormal'
}

/**
 * Types of stochastic credit BC.
 */
export enum StochasticCreditBCType {
  Neumann = 'Neumann',
  Dirichlet = 'Dirichlet'
}

/**
 * Dividend protection model description.
 */
export enum DividendProtectionModelDescription {
  Deterministic = 'Deterministic',
  GridImplementation = 'Grid Implementation',
  Default = 'Default',
  EffectiveDividends = 'Effective Dividends'
}

/**
 * Dividend protection model.
 */
export enum DividendProtectionModel {
  Deterministic = 'Deterministic',
  GridImplementation = 'GridImplementation',
  Default = 'Default',
  EffectiveDividends = 'EffectiveDividends'
}

/**
 * Peps parity convention description.
 */
export enum PepsParityConventionDescription {
  MinConversionRatio = 'Min Conversion Ratio',
  MaxConversionRatio = 'Max Conversion Ratio',
  VariableConversionRatio = 'Variable Conversion Ratio'
}

/**
 * Peps parity convention.
 */
export enum PepsParityConvention {
  MinConversionRatio = 'MinConversionRatio',
  MaxConversionRatio = 'MaxConversionRatio',
  VariableConversionRatio = 'VariableConversionRatio'
}

/**
 * Hard call model type description.
 */
export enum HardCallModelTypeDescription {
  BarrierModel = 'Barrier Model',
  WithCallNoticePeriod = 'With Call Notice Period'
}

/**
 * Hard call model type.
 */
export enum HardCallModelType {
  BarrierModel = 'BarrierModel',
  WithCallNoticePeriod = 'WithCallNoticePeriod'
}

/**
 * Soft call model type description.
 */
export enum SoftCallModelTypeDescription {
  SoftCallTrigger = 'Soft Call Trigger',
  SimpleTwentyOutOfThirty = 'Simple 20 out of 30',
  FullImplementation = 'Full Implementation',
  SimpleQuadratic = 'Simple Quadratic'
}

/**
 * Types of soft call model.
 */
export enum SoftCallModelType {
  SoftCallTrigger = 'SoftCallTrigger',
  SimpleTwentyOutOfThirty = 'SimpleTwentyOutOfThirty',
  FullImplementation = 'FullImplementation',
  SimpleQuadratic = 'SimpleQuadratic'
}

/**
 * Trigger condition Monotoring.
 */
export enum TriggerConditionMonitoring {
  UseStockFixingMonitoring = 'Use Stock Fixing History',
  ManualMonitoring = 'Manual Monitoring'
}

/**
 * Pricer version.
 */
export enum PricerVersion {
  Default = 'Default',
  Beta = 'Beta'
}
