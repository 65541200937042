import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import _ from 'lodash';
import { CompanyAndUserSettingsService } from '@lv-analytics/services/company-settings/company-settings.service';
import { IMarketDataBondSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-settings';
import { ISystemYieldCurvesBondSettingsView } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-interest-rates-settings/system-yield-curves-bond-settings-view';
import { IBondInterestRatesSectionSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-interest-rates-settings/market-data-bond-interest-rates-section-settings';
import { IBondInterestRatesSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-interest-rates-settings/market-data-bond-interest-rates-settings';
import { IBondCreditSectionSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-credit-settings/market-data-bond-credit-section-settings';
import { IBondCreditSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-credit-settings/market-data-bond-credit-settings';
import { IBondWithholdingTaxSectionSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-withholding-tax-settings/market-data-bond-withholding-tax-section';
import { IBondWithholdingTaxSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-withholding-tax-settings/market-data-bond-withholding-tax-settings';

@Component({
  selector: 'lv-market-data-bond-settings',
  templateUrl: './lv-market-data-bond-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvMarketDataBondSettingsComponent implements OnInit, IDiscardable {
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  settings: IMarketDataBondSettings;
  originalSettings: IMarketDataBondSettings;

  systemYieldCurvesSettingsData: ISystemYieldCurvesBondSettingsView;

  constructor( private _companyAndUserSettingsService: CompanyAndUserSettingsService,
              private _errorService: LvErrorService,
               private _changeDetectorRef: ChangeDetectorRef,
               private _discardChangesService: DiscardChangesService) {
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      interestRatesSettings: {
        systemDefaults: {
          yieldCurveSettings: []
        } as IBondInterestRatesSectionSettings,
        environments: []
      } as IBondInterestRatesSettings,
      creditSettings: {
        systemDefaults: {} as IBondCreditSectionSettings,
        environments: []
      } as IBondCreditSettings,
      withholdingTaxSettings: {
          systemDefaults: {} as IBondWithholdingTaxSectionSettings,
          environments: []
      } as IBondWithholdingTaxSettings,
    } as IMarketDataBondSettings;

    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('MarketData', this);
    this.systemYieldCurvesSettingsData = {} as ISystemYieldCurvesBondSettingsView;
  }

  @HostBinding('class.lv-market-data-bond-setting')
  get isLvMarketDataBondSettingsComponent () {
    return true;
  }

  ngOnInit(): void {
    this.getMarketDataBondSettings();
  }


  /**
   * Get Market Data Bond Settings
   */
  async getMarketDataBondSettings () {
    try {
      this.startLoading.next();
      this.settings = await this._companyAndUserSettingsService.getMarketDataBondSettings();
      this.originalSettings = _.cloneDeep(this.settings);
  
      this.systemYieldCurvesSettingsData = await this._companyAndUserSettingsService.getBondYieldCurveSettingsData();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.next();
  }

   /**
   * Occurs on save and saves term settings.
   */
   async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
     
      await this._companyAndUserSettingsService.saveMarketDataBondSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'Market Data'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Resets equity settings to default.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();
      this.settings = await this._companyAndUserSettingsService.resetMarketDataBondSettings();

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Market Data'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

}
