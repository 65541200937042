<lv-flex-box class="lv-equity-terms-identifiers">
  <lv-flex-box class="lv-equity-terms-identifiers-read-only">
    <lv-flex-box direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="leversysIDTooltip"
      filter=".lv-equity-terms-identifiers-read-only-description">
      <label class="lv-equity-terms-identifiers-read-only-label
                    lv-equity-terms-identifiers-read-only-description">
        Leversys ID
      </label>
      <label class="lv-equity-terms-identifiers-read-only-label
                    lv-equity-terms-identifiers-read-only-value">
        {{leversysId}}
      </label>
    </lv-flex-box>
    <lv-flex-box
    direction="row">
      <div
        class="lv-equity-save-reload-controls"
        kendoTooltip 
        data-cy="">
        <div
          class="lv-icon-square"
          title="Save"
          lvId="equitySaveAll"
          (click)="onSave()">
          <i class="k-icon k-i-save"></i>
        </div>
        <div
          class="lv-icon-square"
          title="Reload"
          lvId="equityReloadAll"
          (click)="onReload()">
          <i class="k-icon k-i-reload"></i>
        </div>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box class="lv-equity-terms-identifiers-read-only">
    <lv-flex-box *ngFor="let item of identifiersReadOnlyItems"direction="row" 
      kendoTooltip
      showAfter="1500"
      filter=".lv-equity-terms-identifiers-read-only-description"
      [tooltipTemplate]="tooltipTemplate">
      <label class="lv-equity-terms-identifiers-read-only-label
        lv-equity-terms-identifiers-read-only-description" [attr.tooltip-id]="item.identifierType">
        {{mapIdentifierTypeToIdentifierTypeDesciption(item.identifierType)}}
      </label>
      <label class="lv-equity-terms-identifiers-read-only-label
        lv-equity-terms-identifiers-read-only-value">
        {{item.identifier}}
      </label>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box direction="row">
    <lv-advanced-grid
      data-cy="DM-537"
      [editDisabled]="!isSectionDisabled"
      [columns]="columns"
      [records]="identifiersItems"
      [createFormGroup]="createFormGroup"
      [parseFn]="parseFn"
      lvId="lvEquityIdentifiersSchedule"
      class="lv-equity-identifiers-schedule">
    </lv-advanced-grid>
  </lv-flex-box>
</lv-flex-box>

<ng-template #leversysIDTooltip>
  <lv-tooltip dm="dM-336"></lv-tooltip>
</ng-template>
<ng-template #tooltipTemplate let-anchor>
	<ng-container *ngIf="getTootlipId(anchor, 'ISIN')">
	  <lv-tooltip dm="dM-337"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'CUSIP')">
		<lv-tooltip dm="dM-339"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'FIGI')">
		<lv-tooltip dm="dM-341"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'PERMID')">
		<lv-tooltip dm="dM-342"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'LeversysId')">
		<lv-tooltip dm="dM-5732"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingISIN')">
		<lv-tooltip dm="dM-338"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingCUSIP')">
		<lv-tooltip dm="dM-340"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingFIGI')">
		<lv-tooltip dm="dM-344"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingPERMID')">
		<lv-tooltip dm="dM-345"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'UnderlyingBBGTicker')">
		<lv-tooltip dm="dM-343"></lv-tooltip>
	</ng-container>
	<ng-container *ngIf="getTootlipId(anchor, 'BondCode')">
		<lv-tooltip dm="dM-4589"></lv-tooltip>
	  </ng-container>
</ng-template>