import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { IBaseBondTermsSettings, IBondCallSectionSettings } from '../../../../models/bond-terms-settings';
import { CallValueTypeBondDescription, DayCountDescription, FrequencyDescription, NoticePeriod } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-bond-call-section-settings',
  templateUrl: './lv-bond-call-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondCallSectionSettingsComponent {
  @Input() settings: IBaseBondTermsSettings<IBondCallSectionSettings>;
  @Input() overrideDefaults: boolean;

  numberOfZeroDecimals = '0';
  numberZeroFormat = 'n0';

  callValueTypeRegularLookup: LvLookupEnum;
  callYieldFrequencyLookup: LvLookupEnum;
  callYieldDayCountLookup: LvLookupEnum;
  callNoticeLookup: LvLookupEnum;

  forfeitCouponId: string;

  constructor() {
    this.callValueTypeRegularLookup = new LvLookupEnum(CallValueTypeBondDescription);

    this.callYieldFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.callYieldDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.callNoticeLookup = new LvLookupEnum(NoticePeriod);

    this.forfeitCouponId = v4();
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

    /**
   * Retrieves element's id
   * @param element reference to given element
   * @param sectionId element's id
   * @returns falg- true if element with given id is found
   */
  getCallTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
