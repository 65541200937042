<lv-flex-box class="lv-pricing-side-panel" direction="column">
  <lv-flex-box direction="column" class="lv-pricing-side-panel-content">
    <ng-container *ngIf="!isBondPricing">

      <div class="lv-label lv-label-field lv-label--title">Conversion Price Estimate</div>
      <lv-flex-box direction="row">
        <div class="lv-field lv-field--radio">
          <input class="k-radio"
            type="radio"
            [attr.id]="displayEstimatesButtonRadioButtonId"
            [name]="displayEstimatesButtonRadioButtonId"
            [value]="true"
            [(ngModel)]="pricingWidgetState.displayEstimatesButton">
          <label class="k-radio-label"
            [attr.for]="displayEstimatesButtonRadioButtonId"
            lvId="lv-pricing-side-panel-deb-ratio-rb">
              Display Estimates Button
          </label>
        </div>
        <div class="lv-field lv-field--radio">
          <input class="k-radio"
            type="radio"
            [attr.id]="displayEstimatesSectionRadioButtonId"
            [name]="displayEstimatesSectionRadioButtonId"
            [value]="false"
            [(ngModel)]="pricingWidgetState.displayEstimatesButton">
          <label class="k-radio-label"
            [attr.for]="displayEstimatesSectionRadioButtonId"
            lvId="lv-pricing-side-panel-des-ratio-rb">
            Display Estimates Section
          </label>
        </div>
      </lv-flex-box>
  
      <div class="lv-label lv-label-field lv-label--title">New Issue</div>
      <lv-flex-box direction="row">
        <div class="lv-field lv-field--radio">
          <input class="k-radio"
            type="radio"
            [attr.id]="displayNewIssueButtonRadioButtonId"
            [name]="displayNewIssueButtonRadioButtonId"
            [value]="true"
            [(ngModel)]="pricingWidgetState.displayNewIssueAssumptionButton">
          <label class="k-radio-label"
            [attr.for]="displayNewIssueButtonRadioButtonId"
            lvId="lv-pricing-side-panel-dnib-ratio-rb">
              Display New Issue Button
          </label>
        </div>
        <div class="lv-field lv-field--radio">
          <input class="k-radio"
            type="radio"
            [attr.id]="displayNewIssueSectionRadioButtonId"
            [name]="displayNewIssueSectionRadioButtonId"
            [value]="false"
            [(ngModel)]="pricingWidgetState.displayNewIssueAssumptionButton">
          <label class="k-radio-label"
            [attr.for]="displayNewIssueSectionRadioButtonId"
            lvId="lv-pricing-side-panel-dnis-ratio-rb">
            Display New Issue Section
          </label>
        </div>
      </lv-flex-box>
    </ng-container>

    <div class="lv-label lv-label-field lv-label--title">Market Data Override</div>
    <lv-flex-box  direction="row">
      <div class="lv-label lv-label-field">
        Display Market Data Overrides
      </div>

      <div class="lv-field lv-field--checkbox">
        <input name="lv-pricing-side-panel-market-data-override"
          class="k-checkbox"
          type="checkbox"
          [attr.id]="marketDataOverrideCheckBoxId"
          [(ngModel)]="pricingWidgetState.displayMarketDataOverrides"/>
        <label class="k-checkbox-label"
          lvId="lv-pricing-side-panel-mdo-cb"
          [attr.for]="marketDataOverrideCheckBoxId">
        </label>
      </div>
    </lv-flex-box>

    <lv-flex-box
      *ngIf="!isBondPricing"
      direction="row">
      <div class="lv-label lv-label-field">
        Display Stock Slippage
      </div>

      <div class="lv-field lv-field--checkbox">
        <input name="lv-pricing-side-panel-market-data-override"
          class="k-checkbox"
          type="checkbox"
          [attr.id]="displayStockSlippageCheckBoxId"
          [(ngModel)]="pricingWidgetState.displayStockSlippage"/>
        <label class="k-checkbox-label"
          lvId="lv-pricing-side-panel-mdo-cb"
          [attr.for]="displayStockSlippageCheckBoxId">
        </label>
      </div>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box class="lv-pricing-side-panel-footer" direction="row">
    <button kendoButton
      [primary]="true"
      lvId="convertibleValuationOutputsCancel"
      (click)="onCancel()">
        Cancel
    </button>
    <button kendoButton
      [primary]="true"
      lvId="convertibleValuationOutputsSave"
      (click)="onSave()">
        Save
    </button>
  </lv-flex-box>
</lv-flex-box>
