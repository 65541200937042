import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, OnDestroy,
  ChangeDetectionStrategy, OnChanges, ViewEncapsulation, Optional} from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { constants } from '@lv-core-ui/util';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvExcelService } from '@lv-excel/services';
import { StepUpCouponScheduleItem } from '@lv-instrument-common/StepUpCouponScheduleItem';

@Component({
  selector: 'lv-step-up-coupon-schedule',
  templateUrl: './lv-step-up-coupon-schedule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvStepUpCouponScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: StepUpCouponScheduleItem[];
  @Output() didStepUpCouponScheduleChange: EventEmitter<StepUpCouponScheduleItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  stepUpCouponScheduleItems: StepUpCouponScheduleItem[];
  parseFn: any;
  excelFieldAlias = 'CPN_SCHED_STEPUP_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {
      this.initColumns();
      this.parseFn = this.parserFunction.bind(this);

      this.stepUpCouponScheduleItems = [];
      this._modelSubscription = [];
      this.didStepUpCouponScheduleChange = new EventEmitter<StepUpCouponScheduleItem[]>();
    }

    ngOnInit() {
      this._modelSubscription = [
          this.advancedGrid.didDataChange.subscribe((records: StepUpCouponScheduleItem[]) => this.onScheduleChange(records)),
          this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
          this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
      ];

      this.stepUpCouponScheduleItems = this.model.map(a => ({ ...a }));
      this._changeDetectorRef.detectChanges();
    }

    ngOnChanges() {
      if (this.hasScheduleInExcelOverride) {
        this.initColumns();
      }
      this.stepUpCouponScheduleItems = this.model.map(a => ({ ...a }));
    }

    createFormGroup(args: CreateFormGroupArgs): FormGroup {
      return  new FormGroup({
        'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate, Validators.required),
        'cashCoupon': new FormControl(args.dataItem.cashCoupon, Validators.required)
      });
    }

    private initColumns() {
      this.columns = [];

      const dateColumn = new LvAdvancedGridDateColumn();
      dateColumn.title = 'Date';
      dateColumn.field = 'endDate';
      dateColumn.dmKey = 'DM-2261';

      const valueColumn = new LvAdvancedGridNumericColumn();
      valueColumn.title = this.hasScheduleInExcelOverride ? 'Cash Cpn. (%)' : 'Cash Coupon (%)';
      valueColumn.field = 'cashCoupon';
      valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
      valueColumn.format = '#,###.####';
      valueColumn.decimals = '4';
      valueColumn.dmKey = 'DM-2262';

      this.columns.push(dateColumn);
      this.columns.push(valueColumn);
    }

    private parserFunction(pastedDataRecords: string[]): StepUpCouponScheduleItem[] {
      const scheduleItems: StepUpCouponScheduleItem[] = [];

      pastedDataRecords.forEach(r => {
        const items = r.split('\t');

        const dateValue = items[0];
        const valueValue = items[1];

        const date = MarketDataClipboard.parseDateValue(dateValue, 'End Date');
        const value = MarketDataClipboard.parseNumberValue(valueValue, 'Cash Coupon (%)');

        scheduleItems.push({
          endDate: date,
          cashCoupon: value
        } as StepUpCouponScheduleItem);
      });

      return scheduleItems;
    }

    private onScheduleChange(scheduleItems: StepUpCouponScheduleItem[]) {
      this.model.splice(0, this.model.length);
      this.model.push(...scheduleItems);
      this.didStepUpCouponScheduleChange.next(this.model);
    }

    private onScheduleReload() {
      this.stepUpCouponScheduleItems = this.stepUpCouponScheduleItems.map(a => ({ ...a }));
    }

    private onError(error: LvError) {
      this._errorService.handleError(error);
    }

    ngOnDestroy() {
      this._modelSubscription.forEach(s => s.unsubscribe());
    }
}
