import { v4 } from 'uuid';
import { LvMath } from '@lv-core-ui/util';
import { ConvertibleHelper, AnalyticsSettingsHelper } from '@lv-analytics/helpers';
import { IDeltaNeutralInput, IDeltaNeutralOutput, ICurrencyRadioButton, IAnalyticsSettings, ConvertibleSubType,
         IPricing, IValuationResult } from '@lv-analytics/models';
import { IConvertible } from '../models/convertible/convertible';
import { LvDateService } from '@lv-core-ui/services';

export interface IDeltaNeutralViewRadio {
  id: string;
  name: string;
  label: string;
  value: string;
}

/**
 * Delta neutral view.
 */
export class DeltaNeutralView {
  input: IDeltaNeutralInput;
  output: IDeltaNeutralOutput;
  radioFields: ICurrencyRadioButton[];

  currentCCY: string;
  stockPriceCBCCY: string;
  stockPriceEQCCY: string;
  suffixDelta: string;
  eqHedge: number;
  crossFXRate: number;
  crossFX: number;

  private radioFieldsDict: {
    [fieldName: string]: string;
  };

  _cHelper: ConvertibleHelper;
  _asHelper: AnalyticsSettingsHelper;

  constructor(
    private _lvDateService: LvDateService
  ) {
    this.radioFieldsDict = {
      'cb': 'CB',
      'eq': 'EQ',
      'usd': 'USD'
    };

    this._cHelper = new ConvertibleHelper(_lvDateService);
    this._asHelper = new AnalyticsSettingsHelper();
    this.init();
  }

  /**
   * Does initialization.
   * @param settings IAnalyticsSettings object.
   */
  init(settings?: IAnalyticsSettings) {
    this._asHelper.init(settings);
    this._cHelper.init(this._asHelper.convertible);

    this.input = {} as IDeltaNeutralInput;
    this.output = {} as IDeltaNeutralOutput;
    this.input.convertible = this.convertiblePosition;
    this.stockPriceCBCCY = this.cbCurrency;
    this.stockPriceEQCCY = this.eqCurrency;
    this.radioFields = this.getRadioFields();
    this.currentCCY = 'cb';
    this.suffixDelta = this.isPeps ? 'Shares' : '%';
    this.crossFX = 0;

    if (this._asHelper.instrumentLoaded) {
      this.onPricingInputsChanged(this._asHelper.pricing);
      this.setCbCcyDetachable(settings);
    }

    this.setCrosFX();
  }

  get hasConvertible(): boolean {
    return !!this._cHelper.convertible;
  }

  get isCrossFx(): boolean {
    if (!this._cHelper.convertible) {
      return false;
    }

    return this._cHelper.convertible.isCrossFx;
  }

  get isPriceAsPar(): boolean {
    if (!this._cHelper.convertible) {
      return false;
    }

    return this._cHelper.convertible.isPriceAsPar;
  }

  get isPeps(): boolean {
    if (!this._cHelper.convertible) {
      return false;
    }

    return this._cHelper.convertible.subType === ConvertibleSubType[ConvertibleSubType.PEPS];
  }

  get isDetachableOrDeltaNeutral(): boolean {
    if (!this._cHelper.convertible) {
      return false;
    }
    return this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral;
  }

  get cbCurrency(): string {
    if (!this._cHelper.convertible) {
      return 'N/A';
    }

    return this._cHelper.convertible.currencyCode;
  }

  get eqCurrency(): string {
    if (!this._cHelper.convertible || !this._cHelper.convertible.underlying) {
      return 'N/A';
    }
    return this._cHelper.convertible.underlying.currency;
  }

  get currentCurrency(): string {
    if (this.currentCCY === 'usd') {
      return 'USD';
    }
    return this.currentCCY === 'cb' ?  this.cbCurrency : this.eqCurrency;
  }

  get nominalFactor(): number {
    return this.isPriceAsPar ? this.getSafeNominal() : 1 / this.getSafeNominal();
  }

  get priceAsParFactor(): number {
    return this.isPriceAsPar ? 0.01 : 1;
  }

  get faceValueFactor(): number {
    return this.isPriceAsPar ? this.getSafeNominal() : 1;
  }

  get convertible(): IConvertible {
    return this._cHelper.convertible;
  }

  get convertiblePosition(): number {
    return this._asHelper.getConvertiblePosition;
  }

  get deltaFX(): number {
    return this.isDetachableOrDeltaNeutral ? this.input.deltaFX : 0;
  }

  get nominal(): number {
    return this._cHelper.convertible.nominal;
  }

  get conversionRatio(): number {
   return this._asHelper.getConversionRatio();
  }

  get crossFxT0() {
    return this.input.crossFxT0;
  }

  get crossFxT1() {
    return this.input.crossFxT1;
  }

  get convertibleCountryCode(): string {
    if (!this._asHelper || !this._asHelper.convertible || !this._asHelper.convertible.countryCode) {
      return '';
    }
    return this._asHelper.convertible.countryCode;
  }

  get convertibleStatus(): string {
    if (!this._asHelper || !this._asHelper.convertible || !this._asHelper.convertible.status) {
      return '';
    }
    return this._asHelper.convertible.status;
  }

  get isPrivate(): boolean {
    if (!this._asHelper || !this._asHelper.settings || !this._asHelper.settings.valuationSession) {
      return false;
    }
    return this._asHelper.settings.valuationSession.isPrivateInstrument;
  }

  get paritySuffix(): string {
    if (!this.convertible) {
      return null;
    }

    return this.isPriceAsPar ? 'pts' : this.convertible.currencyCode;
  }

  /**
   * Gets safe nominal.
   * @returns Safe nominal
   */
  private getSafeNominal(): number {
    return this._cHelper.getSafeNominal();
  }

  /**
   * Occurs on pricing inputs changed.
   * @param pricing IPricing object.
   */
  onPricingInputsChanged(pricing: IPricing) {
    this.input.convertibleT0 = pricing?.price;
    this.input.stockEQT0 = pricing?.stockPriceUndCcy;
    this.input.stockCBT0 = pricing?.stockPriceCbCcy;
    this.input.crossFxT0 = pricing?.crossFx;
    this.input.crossFxT1 = pricing?.crossFx;
    this.crossFXRate = pricing?.crossFx;
    // this.stockRef = valuation.underlyingPrice;
    this.crossFX = pricing?.crossFx;
    this.isDetachableOrDeltaNeutral ? this.calculateStockCBT1() : this.calculateStockEQT1();
    this.setDeltaStock();
    this.calculateViewOutputs();
  }

  /**
   * Occurs on valuation update changed.
   * @param data IValuationResult object.
   */
  onValuationUpdateChange(data: IValuationResult): any {
    if (!LvMath.isNumber(this.input.delta) || LvMath.isZeroNumber(this.input.delta)) {
      if (this.isPeps) {
        this.input.delta = data.outputs.deltaShares;
        this.suffixDelta = 'Shares';
      } else {
        this.input.delta = data.outputs.delta;
        this.suffixDelta = '%';
      }
    } else {
      this.suffixDelta = !this.isPeps ? '%' : 'Shares';
    }
  }

  /**
   * Set Delta Stock outputs
   */
  setDeltaStock() {
    if (!LvMath.isZeroNumber(this.input.delta) && !LvMath.isZeroNumber(this.input.convertible)) {
      if (this.convertiblePosition * this.conversionRatio !== 0) {
        this.setDeltaStockPosition(this.input.convertible, this.input.delta, this.conversionRatio, this.nominal, this.isPeps);
        this.setDeltaConvertibleValue();
        this.setDeltaStockValue();
      }
    }
  }

  /**
   * Set Delta outputs
   */
  setDelta() {
    if (!LvMath.isZeroNumber(this.input.stock) && !LvMath.isZeroNumber(this.input.convertible)) {
      this.setDeltaValue();
      this.setDeltaConvertibleValue();
      this.setDeltaStockValue();
      this.setDeltaNeutral();
      this.setDeltaNeutralStock();
    }
  }

  /**
   * Set Delta Neutral field
   */
  setDeltaNeutral() {
    const deltaStockT0 = !this.isCrossFx ? this.input.stockCBT0 : this.input.stockEQT0;
    const deltaStockT1 = !this.isCrossFx ? this.input.stockCBT1 : this.input.stockEQT1;

    if (!LvMath.isZeroNumber(this.input.convertibleT0) && !LvMath.isZeroNumber(deltaStockT0) && !LvMath.isZeroNumber(deltaStockT1)) {
      if (this.isDetachableOrDeltaNeutral && !LvMath.isZeroNumber(this.nominal) && !LvMath.isZeroNumber(this.crossFxT0)
      && !LvMath.isZeroNumber(this.crossFxT1) && !LvMath.isZeroNumber(deltaStockT1 - deltaStockT0)) {

          this.calculateDeltaNeutralWithDetachable();
      }
      else if (!LvMath.isZeroNumber(this.nominal) && !LvMath.isZeroNumber(this.input.stockCBT1 - this.input.stockCBT0)
            && !LvMath.isZeroNumber(this.crossFxT0) && !LvMath.isZeroNumber(this.crossFxT1)) {

          this.calculateDeltaNeutralWithoutDetachable();
      }
    }
  }
  /**
   * Set Delta Neutral Stock outputs
   */
  setDeltaNeutralStock() {
    let deltaNeutralStockCB;
    let deltaNeutralStockEQ;

    if (!LvMath.isZeroNumber(this.input.stockCBT0) && !LvMath.isZeroNumber(this.input.stockCBT1)) {
      // tslint:disable-next-line:max-line-length
      if (this.isDetachableOrDeltaNeutral && !LvMath.isZeroNumber(this.input.delta) && !LvMath.isZeroNumber(this.crossFxT0) && !LvMath.isZeroNumber(this.crossFxT1)) {

            const fxAdjustmentPart = this.fxAdjustmentPart();

            // tslint:disable-next-line: max-line-length
            const expressionOne = this.isPriceAsPar ? (this.input.convertibleT1 - this.input.convertibleT0 - fxAdjustmentPart) * this.nominal * 0.01 :
                                 (this.input.convertibleT1 - this.input.convertibleT0 - fxAdjustmentPart);

            const expressionTwo = this.input.stockCBT0 * this.crossFxT0;
            const expressionThree = ((this.input.delta / 100) * this.conversionRatio) / this.crossFxT0;

            deltaNeutralStockCB = ((expressionOne / expressionThree) + expressionTwo) / this.crossFxT1;
            deltaNeutralStockCB = Math.round(deltaNeutralStockCB * 1000) / 1000;
            // tslint:disable-next-line: max-line-length
            deltaNeutralStockEQ = this.input.stockEQT0 + ((expressionOne * this.crossFxT0) / ((this.input.delta / 100) * this.conversionRatio));

      }
      else if (!LvMath.isZeroNumber(this.nominal) && !LvMath.isZeroNumber(this.input.stockCBT1 - this.input.stockCBT0)
        && !LvMath.isZeroNumber(this.faceValueFactor) && !LvMath.isZeroNumber(this.input.delta)
        && !LvMath.isZeroNumber(this.conversionRatio)) {

        let conversionRatio = this.conversionRatio;
        let divFactor = 100;

        if (this.isPeps) {
          conversionRatio = 1;
          divFactor = 1;
        }

        if (this.isPriceAsPar) {
          deltaNeutralStockCB = this.input.stockCBT0 + (this.input.convertibleT1 - this.input.convertibleT0)
                            / ((this.input.delta / divFactor) * conversionRatio * 100) * this.nominal;
        }
        else {
          deltaNeutralStockCB = this.input.stockCBT0 + (this.input.convertibleT1 - this.input.convertibleT0)
                            / ((this.input.delta / divFactor) * conversionRatio);
        }

        if (deltaNeutralStockCB) {
          deltaNeutralStockEQ = parseFloat(deltaNeutralStockCB.toFixed(4)) * this.crossFxT1;
        }
      }
      if (deltaNeutralStockCB) {
        this.output.neutralStockCB = parseFloat(deltaNeutralStockCB.toFixed(4));
      }

      if (deltaNeutralStockEQ) {
        this.output.neutralStockEQ = parseFloat(deltaNeutralStockEQ.toFixed(4));
      }
    }
  }

  /**
   * Set Actual Delta output
   */
  setActualDelta() {
    const deltaCrossFx = this.isCrossFx ? this.crossFxT1 / this.crossFxT0 : 1
    let deltaActual;

    if (LvMath.isNumber(this.input.stockCBT1) && LvMath.isNumber(this.input.convertibleT0) && LvMath.isNumber(this.input.convertibleT1)
     && LvMath.isNumber(this.input.stockCBT0) && LvMath.isNumber(this.input.stockCBT1) && !LvMath.isZeroNumber(this.input.crossFxT1)) {

        if (this.isDetachableOrDeltaNeutral) {
          const fxAdjustmentPart = this.fxAdjustmentPart();
          const expressionOne = (this.input.convertibleT1 - this.input.convertibleT0 - fxAdjustmentPart);
          const expressionCREQ = (this.conversionRatio * ((this.input.stockCBT1 * deltaCrossFx - this.input.stockCBT0)));
          const expressionTwo = this.isPriceAsPar ? expressionCREQ / (this.nominal * 0.01) : expressionCREQ;
          deltaActual = Math.round((expressionOne / expressionTwo * 100) * 10000) / 10000;
        }
        // tslint:disable-next-line:max-line-length
        else if (!LvMath.isZeroNumber(this._cHelper.convertible.nominal) && !LvMath.isZeroNumber(this.input.stockCBT1 - this.input.stockCBT0)) {
          let conversionRatio = this.conversionRatio;
          let multFactor = 100;

          if (this.isPeps) {
            conversionRatio = 1;
            multFactor = 1;
          }

          if (this.isPriceAsPar) {
            deltaActual = (this.input.convertibleT1 - this.input.convertibleT0) * this.nominal * multFactor
                       / ((this.input.stockCBT1 - this.input.stockCBT0) * conversionRatio * 100) ;
          }
          else {
            deltaActual = (this.input.convertibleT1 - this.input.convertibleT0) * multFactor
                       / ((this.input.stockCBT1 - this.input.stockCBT0) * conversionRatio);
          }
        }
        if (deltaActual) {
          this.output.actualValue = Math.sign(deltaActual) *  Math.round(Math.abs(deltaActual) * 10000) / 10000;
        }
    }
  }

  /**
   * Set Delta PnL outputs
   */
  setPnL() {
    if (!LvMath.isZeroNumber(this.input.stock)) {
        let fxRate = null;

        if (this.currentCCY === 'usd') {
          fxRate = 1 / this._asHelper.cbCcyUsdFxRate;
        }
        else {
          this.currentCCY === 'eq' ? fxRate = this.input.crossFxT1 : fxRate = 1;
        }

        this.output.convertiblePnL = Math.round((this.input.convertibleT1 - this.input.convertibleT0) * this.input.convertible
                                               * this.priceAsParFactor * fxRate);

        this.output.stockPnL = Math.round(this.input.stock * (this.input.stockCBT1 - this.input.stockCBT0) * fxRate);
        this.output.totalPnL = this.output.convertiblePnL + this.output.stockPnL;
        const factorPts = this.isPriceAsPar ? 100 : 100 / (this.nominal);
        this.output.ptsPnL = (this.output.totalPnL / this.input.convertible) * factorPts * (1 / fxRate);
      }
  }

  /**
   * Function for calculate StockCB in T0 point
   */
  calculateStockCBT0() {
    this.input.stockCBT0 = !LvMath.isZeroNumber(this.crossFxT0) && LvMath.isNumber(this.input.stockEQT0) ?
                           this.input.stockEQT0 / this.crossFxT0 : 0;
    this.setDeltaStockValue();
  }

  /**
   * Function for calculate StockCB in T1 point
   */
  calculateStockCBT1() {
    this.input.stockCBT1 = !LvMath.isZeroNumber(this.crossFxT1) && LvMath.isNumber(this.input.stockEQT1) ?
                           this.input.stockEQT1 / this.crossFxT1 : 0;
  }

  /**
   * Function for calculate StockEQ in T1 point
   */
  calculateStockEQT1() {
    if (LvMath.isNumber(this.input.stockCBT1)) {
      this.input.stockEQT1 = this.input.stockCBT1 * this.crossFxT1;
    }
  }

  /**
   * Function for calculate StockEQ in T0 point
   */
  calculateStockEQT0() {
    this.input.stockEQT0 = this.input.stockCBT0 * this.crossFxT0;
    this.setDeltaStockValue();
  }

  /**
   * Calculates view outputs.
   */
  calculateViewOutputs() {
    this.setActualDelta();
    this.setDeltaNeutral();
    this.setDeltaNeutralStock();
    this.setPnL();
  }

  /**
   * Function for set StockCB field for detachable instruments
   * @param settings new analytics settings
   */
  private setCbCcyDetachable(settings?: IAnalyticsSettings) {
    if (this.crossFX && settings.convertible.subType === ConvertibleSubType.ConvertibleWithDetachableWarrant) {
      this.input.stockCBT0 = this.input.stockEQT0 / this.input.crossFxT0;
    }
  }

  /**
   * Function for calculate Delta Neutral for detachable instruments
   */
  private calculateDeltaNeutralWithDetachable() {
    let deltaNeutral;
    if (!LvMath.isZeroNumber(this.input.delta) && !LvMath.isZeroNumber(this.crossFxT0) && !LvMath.isZeroNumber(this.nominal)) {
          // tslint:disable-next-line: max-line-length
          const deltaCrossFx = this.isCrossFx ? this.crossFxT1 / this.crossFxT0 : 1
          const expressionOne = (this.input.stockCBT1 * deltaCrossFx - this.input.stockCBT0) * this.conversionRatio * (this.input.delta * 0.01);
          const expressionTwo = this.isPriceAsPar ? 100 / this.nominal : 1;
          const adjustmentPart =  this.fxAdjustmentPart();

          deltaNeutral = this.input.convertibleT0  + (expressionOne * expressionTwo) + adjustmentPart;

          this.output.neutral = parseFloat(deltaNeutral.toFixed(4));
       }
  }

  /**
   * Function for calculate Delta Neutral for non-detachable instruments
   */
  private calculateDeltaNeutralWithoutDetachable() {
    let deltaNeutral;
    let conversionRatio = this.conversionRatio;
    let divFactor = 100;

    if (this.isPeps) {
      conversionRatio = 1;
      divFactor = 1;
    }

    if (this.isPriceAsPar) {
      deltaNeutral = this.input.convertibleT0 + conversionRatio * (this.input.delta / divFactor)
                  * (this.input.stockCBT1 - this.input.stockCBT0) * 100 / this.nominal;
    }
    else {
      deltaNeutral = this.input.convertibleT0 + this.input.delta / divFactor * conversionRatio
                  * (this.input.stockCBT1 - this.input.stockCBT0);
    }
    this.output.neutral = parseFloat(deltaNeutral.toFixed(4));
  }

  /**
   * Function for set FXDelta Adjustment
   */
  private fxAdjustmentPart(): number {
    return !LvMath.isZeroNumber(this.crossFxT0) ? this.input.deltaFX * (this.crossFxT1 - this.crossFxT0) / (0.01 * this.crossFxT0) : 0;
  }

  /**
   * Sets cross FX.
   */
  private setCrosFX() {
    if (!this.isCrossFx) {
      this.input.crossFxT0 = 1;
      this.input.crossFxT1 = 1;
    }
  }

  /**
   * Function for set Delta Convertible output
   */
  private setDeltaConvertibleValue() {
    let fxRate;
    const cbPrice = this.input.convertibleT0;

    if (this.currentCCY === 'usd') {
      fxRate = 1 / this._asHelper.cbCcyUsdFxRate;
    }
    else {
      fxRate = this.currentCCY === 'eq' ? this.input.crossFxT0 :  1;
    }
    this.output.convertibleValue =  Math.round(this.input.convertible * cbPrice * (this.isPriceAsPar ? 0.01 : 1) * fxRate);
  }

  /**
   * Function for set Delta Stock output
   */
  private setDeltaStockValue() {
    const deltaStockT0 = this.input.stockEQT0;
    let fxRate;

    if (this.currentCCY === 'usd') {
      fxRate = 1 / this._asHelper.cbCcyUsdFxRate * (1 / this.input.crossFxT0);
    }
    else {
      fxRate = this.currentCCY === 'eq' ?  1 :  1 / this.input.crossFxT0;
    }
    this.output.stockValue = Math.sign(this.input.stock)  * Math.round(Math.abs(this.input.stock * deltaStockT0 * fxRate));
  }

  /**
   * Function for set Delta output
   */
  private setDeltaValue() {
    let factor = 100;
    let conversionRatio = this.conversionRatio;
    if (this.isPeps) {
      conversionRatio = 1;
      factor = 1;
    }
    // tslint:disable-next-line:max-line-length
    this.input.delta = !LvMath.isZeroNumber(conversionRatio) ? -1 * factor * (this.input.stock * this.faceValueFactor) / (this.input.convertible * conversionRatio) : 0;
  }

  /**
   * Function for set Delta Stock position output
   */
  private setDeltaStockPosition(position: number, delta: number, conversionRatio: number, nominal: number, isPeps: boolean) {
    let divFactor = 100;

    if (isPeps) {
      conversionRatio = 1;
      divFactor = 1;
    }

    const eqHedge = nominal !== 0 ? (-1 * (position * delta / divFactor * conversionRatio / this.faceValueFactor )) : 0;
    this.input.stock = Math.sign(eqHedge) *  Math.round(Math.abs(eqHedge));
  }

  /**
   * Get radio button.
   * @returns List of ICurrencyRadioButton objects.
   */
  private getRadioFields(): ICurrencyRadioButton[] {
    let radioFields = Object.keys(this.radioFieldsDict);

    if (this.isCrossFx && (this.cbCurrency === 'USD' || this.eqCurrency === 'USD')) {
      radioFields = radioFields.filter(a => a !== 'usd');
    }

    if (!this.isCrossFx) {
      radioFields = radioFields.filter(a => this.cbCurrency === 'USD' ? a === 'cb' : a !== 'eq');
    }

    return radioFields.map(a => {
      let label = this.radioFieldsDict[a];

      if (a !== 'usd') {
        label = `${a === 'cb' ? this.cbCurrency : this.eqCurrency}`;
      }

      return {
        id: `delta-neutral-radio-id-${v4()}`,
        name: `delta-neutral-radio-name-${v4()}`,
        label: label,
        lvId: `${a}DeltaNeutralRadioButton`,
        value: a
      } as ICurrencyRadioButton;
    });
  }
}
