<lv-advanced-grid
  data-cy="DM-986"
  lvId="pikSchedule"
  [columns]="columns"
  [records]="scheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="date"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  class="lv-pik-custom-schedule">
</lv-advanced-grid>

