import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvAdvancedGridColumn, LvAdvancedGridComponent, LvAdvancedGridEnumColumn, LvAdvancedGridTextColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum } from '@lv-core-ui/util';
import { IEquityIdentifiers } from '@lv-reference-data/models/equity-identifiers';
import { EquityIdentifierType, EquityIdentifierTypeDescription } from '@lv-reference-data/models/equity-identifiers-enum';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import _ from 'lodash';
import { InstrumentsService } from '@lv-reference-data/instruments.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lv-equity-identifiers',
  templateUrl: './lv-equity-identifiers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityIdentifiersComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;
  @Input() leversysId: string;
  @Input() syncAllEquity: boolean;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get isSectionDisabled(): boolean {
    return !!this._analyticsPresenter.equitySession?.terms;
  }

  columns: LvAdvancedGridColumn[];
  identifiersItems: IEquityIdentifiers[];
  identifiersReadOnlyItems: IEquityIdentifiers[];
  originalValue: IEquityIdentifiers[];
  parseFn: any;
  
  identifierFilterDict: {
    [code: string]: boolean
  };

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _instrumentsService: InstrumentsService,
    private _destroyRef: DestroyRef
  ) {
    this.identifiersItems = [];
    this.identifiersReadOnlyItems = [];
    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);
    this.identifiersItems =[];
    this.syncAllEquity = false;
    this.didSessionUpdatedEvent = new EventEmitter();
  }

  /**
   * Handles component initialization 
   */
  ngOnInit(): void {
    this.loadEquityIdentifiers();
    this.advancedGrid.didDataChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((records: IEquityIdentifiers[]) => this.onScheduleChange(records));
    this.advancedGrid.doReload.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => this.onScheduleReload());
    this.advancedGrid.didError.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((error: LvError) => this.onError(error));
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!!changes && (changes.leversysId?.currentValue !== changes.leversysId?.previousValue) || this.syncAllEquity){
      await this.loadEquityIdentifiers();
    }
  }

  ngOnDestroy(): void {    
  }

  /**
   * Method handles process of record applying in schedule.
   */
  applyScheduleChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Method handles creating form group.
   * @param args CreateFormGroupArgs interface
   * @returns FormGroup class.
   */
  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'identifierType': new FormControl(args.dataItem.identifierType, Validators.required),
      'identifier': new FormControl(args.dataItem.identifier, Validators.required),
    });
  }

  /**
   * Method check for identifier type duplicates in schedule
   * @param propertyName identifierType.
   * @param inputArray input array.
   * @returns boolean.
   */
  checkDuplicateInObject(propertyName, inputArray) {
      let seenDuplicate = false;
      const testObject = {};

      inputArray.map(item => {
        const itemPropertyName = item[propertyName];
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
        }
        else {
          testObject[itemPropertyName] = item;
          delete item.duplicate;
        }
      });

      return seenDuplicate;
  }

  /**
   *  Method removes schedule duplicates.
   * @param scheduleItems IEquityIdentifiers array.
   * @returns schedule items without duplicates.
   */
  removeDuplicateItem(scheduleItems: IEquityIdentifiers[]) {
    return scheduleItems.reduce((acc, current) => {
      const x = acc.find(item => item.identifierType === current.identifierType);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  /**
   * Method maps identifier type to corresponds description appropriate for displaying.
   * @param identifierType identifier type.
   * @returns identifier value
   */
  mapIdentifierTypeToIdentifierTypeDesciption(identifierType: string ) {
    return EquityIdentifierTypeDescription[identifierType];
  }

 async onSave() {
    try {
      if (!this.leversysId){
        this._errorService.toastrService.error(LvDataMaster.getError('dM-3385'));
        return;
      }
      this.applyScheduleChanges();
      await this._instrumentsService.saveIdentifiers(
        this.leversysId,
        this.identifiersItems,
        this._analyticsPresenter?.getSourceIdForEquityRequests);

      await this._analyticsPresenter.setupEquityValuationSessionAndInstrument(equityValuationSession => {
      
      });

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388', {'value': 'Identifiers'}));
    }    
    catch(error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Reload equity identifiers.
   */
  async onReload () {
    await this.loadEquityIdentifiers();
  }

  /**
   * Load equity terms identifiers.
   */
  async loadEquityIdentifiers() {
    try {
      if (this.leversysId) {
        const identifiersResult = await this._instrumentsService.getEquityIdentifiers(this.leversysId);
        this.identifiersItems = identifiersResult.filter(x => x.identifierType !== EquityIdentifierType.LeversysId);
        this.identifiersReadOnlyItems = identifiersResult.filter(x => x.identifierType === EquityIdentifierType.LeversysId);
      }
      else {
        this.identifiersItems = [];
        this.identifiersReadOnlyItems = [];
      }

      this.setIdentifierFilterDict(this.identifiersItems);
    } 
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }  
  }
  
  /**
   * Sets section state changed flag.
   * @param sessionStateChange A flag indicating if session state is changed.
   */
  setSectionStateChanged() {
  }

  /**
   * Gets element tooltip ID.
   * @param anchor Anchor.
   * @param element Element.
   * @returns Tooltip ID.
   */
  getTootlipId(anchor: ElementRef, element: string) {
    return anchor.nativeElement.getAttribute('tooltip-id') === element;
  }

  /**
   * Initialize advance grid columns.
   */
  private initColumns() {
    this.columns = [];

    const identifierColumn = new LvAdvancedGridTextColumn();
    identifierColumn.title = 'IDENTIFIER';
    identifierColumn.field = 'identifier';
    identifierColumn.placeholder = '';

    const identifierTypeColumn = new LvAdvancedGridEnumColumn();
    identifierTypeColumn.enumDescription = EquityIdentifierTypeDescription;
    identifierTypeColumn.title = 'IDENTIFIER TYPE';
    identifierTypeColumn.field = 'identifierType';
    identifierTypeColumn.displayField = 'text';
    identifierTypeColumn.valueField = 'id';
    identifierTypeColumn.valuePrimitive = true;
    identifierTypeColumn.data = new LvLookupEnum(EquityIdentifierTypeDescription)
      .items.filter(x => x.id !== EquityIdentifierType.LeversysLocalID && x.id !== EquityIdentifierType.LeversysId);
    identifierTypeColumn.width = 140;
    identifierTypeColumn.setFilterFn(item => {
      if (this.identifierFilterDict) {
        return !this.identifierFilterDict[item.id];
      }

      return true;
    });

    this.columns.push(identifierTypeColumn);
    this.columns.push(identifierColumn);
  }

  /**
   * Method parses pasted data records and populated schedule list.
   * @param pastedDataRecords IEquityIdentifiers object.
   * @returns list of schedule items.
   */
  private parserFunction(pastedDataRecords: string[]): IEquityIdentifiers[] {
    const scheduleItems: IEquityIdentifiers[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const identifierTypeValue = items[0];
      const identifierValue = items[1];

      const identifierType = this.parseIdentifierType(identifierTypeValue);
      const identifier = identifierValue;


      scheduleItems.push({
        identifierType: identifierType,
        identifier: identifier
      } as IEquityIdentifiers);
    });

    return scheduleItems;
  }

  /**
   * Method parses identifierTypeValue.
   * @param identifierTypeValue identifierTypeValue
   * @returns EquityIdentifierType object.
   */
  private parseIdentifierType(identifierTypeValue: string): EquityIdentifierType {

    if (MarketDataClipboard.isEmpty(identifierTypeValue)) {
      throw new LvError(`Value should not be empty`);
    }

    const identifierTypeEnum = EquityIdentifierType[identifierTypeValue];
    if (!identifierTypeEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{value}', identifierTypeValue], ['{field}', 'Identifier Type']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return identifierTypeEnum;
  }

  /**
   * Method handles errors.
   * @param error LvError class
   */
  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Method handles schedule reload.
   */
  private onScheduleReload() {
    this.identifiersItems = this.identifiersItems.map(a => ({ ...a }));
  }

  /**
   * Method handle duplicate entries.
   * @param scheduleItems IEquityIdentifiers object array.
   */
  private onScheduleChange(scheduleItems: IEquityIdentifiers[]) {
    if (!this.checkDuplicateInObject('identifierType', scheduleItems)) {
      this.applyRecords(scheduleItems);
      this.setSectionStateChanged();
      this.setIdentifierFilterDict(scheduleItems);
    }
    else {
      this._errorService.toastrService.warning(LvDataMaster.getWarning('dM-1860'));

      const filteredArr = this.removeDuplicateItem(scheduleItems);
      this.applyRecords(filteredArr);
      this.identifiersItems = this.identifiersItems.map(a => ({ ...a }));
      this._changeDetectorRef.detectChanges();
    }
  }

   /**
   * Method handled applying schedule records.
   * @param records record array.
   */
   private applyRecords(records: any[]) {
    this.identifiersItems.splice(0, this.identifiersItems.length);
    this.identifiersItems.push(...records);
  }

  private setIdentifierFilterDict(records: IEquityIdentifiers[]) {
    this.identifierFilterDict = {};
    records.forEach(a => this.identifierFilterDict[a.identifierType] = true);
  }
}
