import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input, ElementRef } from '@angular/core';
import { IBorrowSettings, IBorrowSectionSettings } from '@lv-analytics/models';

/**
 * Borrow settings component.
 */
@Component({
  selector: 'lv-borrow-settings',
  templateUrl: './lv-borrow-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBorrowSettingsComponent implements OnInit {
  @Input() instrumentType: string;
  @Input() settings: IBorrowSettings;

  constructor() {
    this.instrumentType = '';
    this.settings = {
      systemDefaults: {} as IBorrowSectionSettings,
      environments: []
    };
  }

  @HostBinding('class.lv-borrow-settings')
  get isLvBorrowSettingsComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

    /**
     * Retrieves element's id
     * @param element reference to given element
     * @param id element's id
     * @returns falg- true if element with given id is found
     */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
