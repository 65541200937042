import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBaseBondTermsSettings, IBondPutSectionSettings } from '../../../models/bond-terms-settings';

@Component({
  selector: 'lv-bond-put-settings',
  templateUrl: './lv-bond-put-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondPutSettingsComponent {

  @Input() systemDefaults: IBaseBondTermsSettings<IBondPutSectionSettings>;
  @Input() myDefaults: IBaseBondTermsSettings<IBondPutSectionSettings>;

  constructor() { }

  @HostBinding('class.lv-put-settings')
  get isLvPutSettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
