import { IndexType } from "./enums/CouponEnums";

export class Index {
    name: string;
    type: IndexType;
    currencyCode: string;
    bloombergTicker: string;
    bloombergUniqueId: string;
}

