import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  ChangeDetectorRef, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, 
  DestroyRef} from '@angular/core';

import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { VolatilityView } from './lv-volatility.view';
import { LvVolatilityTermStructureComponent } from './lv-volatility-term-structure/lv-volatility-term-structure.component';
import { LvVolatilitySurfaceComponent } from './lv-volatility-surface/lv-volatility-surface.component';
import { IMarketDataComponent } from '../../market-data-component';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum } from '@lv-core-ui/util';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { IVolatilityRequest, IVolatilitySessionRequest, VolatilitySurfaceRiskyType, VolatilitySurfaceRiskyTypeDescription, VolatilityType,
         VolatilityTypeDescription } from '@lv-analytics/models/market-data/volatility';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IEnvironmentSettingsItem,
         LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { MarketDataService } from '@lv-analytics/services';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Volatility component.
 */
@Component({
  selector: 'lv-volatility',
  templateUrl: './lv-volatility.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvVolatilityComponent implements OnInit, OnDestroy, IMarketDataComponent<IVolatilityRequest> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild(LvVolatilityTermStructureComponent) volatilityTermStructure: LvVolatilityTermStructureComponent;
  @ViewChild(LvVolatilitySurfaceComponent) volatilitySurface: LvVolatilitySurfaceComponent;

  get displayNonEditableVolatilitySurfaceType(): string {
    switch (this.view.underlyingEquityVolatility.riskyType) {
      case VolatilitySurfaceRiskyType.Risky:
        return VolatilitySurfaceRiskyTypeDescription.Risky;
      case VolatilitySurfaceRiskyType.RiskFree:
        return VolatilitySurfaceRiskyTypeDescription.RiskFree; 
    }
  }
  
  get isVolatilitySectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get isCrossFx(): boolean {
    return this._analyticsPresenter.cHelper.isCrossFx;
  }

  get isFxVolatilityAndFxCorrelationShown(): boolean {
  return (this.view.volatilitySettings.volType === VolatilityType.Flat || this.view.volatilitySettings.volType === VolatilityType.UnderlyingEquityDataFlat) &&
    this.isCrossFx && !this.isVolatilitySectionDisabled;
  }

  get isUnderlyingVolatility(): boolean {
    return this.view.volatilitySettings.volType === VolatilityType.UnderlyingEquityDataFlat ||
      this.view.volatilitySettings.volType === VolatilityType.UnderlyingEquityDataSurface ||
      this.view.volatilitySettings.volType === VolatilityType.UnderlyingEquityDataTermStructure;
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get sectionMinWidth(): number {
    return this.view.isSurface ? 680 : 315;
  }

  _isLoading: boolean;

  view: VolatilityView;
  volatilitySource: LvLookupEnum;
  riskyType: LvLookupEnum;
  popupSettings: PopupSettings;
  isEditable: boolean;

  volatilitySection = PricingEnvironmentSections.Volatility;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _marketDataService: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _destroyRef: DestroyRef,
  ) {
    this.view = new VolatilityView();
    this.volatilitySource = new LvLookupEnum(VolatilityTypeDescription);
    this.riskyType = new LvLookupEnum(VolatilitySurfaceRiskyTypeDescription);

    this.volatilitySource.setFilterFn(item => {
      if (!this._analyticsPresenter.cHelper.isPeps && item.id === VolatilityType.UpsideDownside) {
        return false;
      }

      if (!this._analyticsPresenter.asHelper.hasUnderlying() && item.id.startsWith('Underlying')) {
        return false;
      }

      return true;
    });

    this.didSessionUpdatedEvent = new EventEmitter<void>();

    this.popupSettings = {
      width: 251
    };

    this.isEditable = false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt && evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
          this.view.init(evt.data.marketData.volatility);
          if(this._analyticsPresenter?.equitySession?.marketData)
          {
              this.view.initUnderlyingEquity(this._analyticsPresenter?.equitySession?.marketData.volatility);
          }
          this._changeDetectorRef.detectChanges();
        }
      })
    ];

    this._analyticsPresenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(async() => {
      if(this._analyticsPresenter?.equitySession?.marketData){
        this.view.initUnderlyingEquity(this._analyticsPresenter?.equitySession?.marketData.volatility);
        this._changeDetectorRef.detectChanges();
      }
    });

    if(this._analyticsPresenter?.equitySession?.marketData){
      this.view.initUnderlyingEquity( this._analyticsPresenter?.equitySession?.marketData.volatility);
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on change environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  async onChangeEnvironment(environment: IEnvironmentSettingsItem) {
    await this.loadVolatility(environment);
  }

  /**
   * Occurs on volatility source change.
   */
  onVolatilitySourceChange() {
    this.view.onVolatilitySourceChange();
    this.overrideVolatility();
  }

  /**
   * Overrides volatility.
   */
  async overrideVolatility() {
    try {
      if (this.view.isTermStructure && !!this.volatilityTermStructure) {
        this.view.volatilitySettings.volatilityTermStructureSchedule = this.volatilityTermStructure.volatilityTermStructureSchedule;
      }

      if (this.view.isSurface && !!this.volatilitySurface) {
        this.view.volatilitySettings.volatilitySurface = this.volatilitySurface.volatilitySurface;
      }

      const request = {
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        volatility: this.view.volatilitySettings
      } as IVolatilitySessionRequest;

      const underlyingSession = await  this._marketDataService.overrideVolatility(request);

      if(underlyingSession){
        this.view.initUnderlyingEquity(underlyingSession.marketData.volatility);
      }

      this._analyticsPresenter.updateVolatility(this.view.volatilitySettings, underlyingSession);    

      if (!_.isEqual(this.view.volatilitySettings, this.view.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.view.originalValue = _.cloneDeep(this.view.volatilitySettings);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
    }
  }
  

  /**
   * Gets selected environment ID.
   * @returns Environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns IVolatilityRequest object.
   */
  getSettings(): IVolatilityRequest {
    return {
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      environmentId: this.getSelectedEnvironmentId(),
      volatility: this.view.volatilitySettings
    } as IVolatilityRequest;
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {
    if (this.view.volatilitySettings.volType === VolatilityType.TermStructure) {
      this.volatilityTermStructure.applyAdvancedGridChanges();
    }

    if (this.view.volatilitySettings.volType === VolatilityType.VolatilitySurface) {
      this.volatilitySurface.applyAdvancedGridChanges();
    }
  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections
   */
  getSection(): PricingEnvironmentSections {
    return this.volatilitySection;
  }

  /**
   * Gets volatility tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Volatility tooltip ID.
   */
  getVolatilityTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('volatility-tooltip-id') === sectionId;
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Loads volatility.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadVolatility(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      const vData = await this._marketDataService.loadVolatility(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id);

      this._analyticsPresenter.updateVolatility(vData.volatility, vData.underlyingValuationSession);
   
      if(vData.underlyingValuationSession){
        this.view.initUnderlyingEquity(vData.underlyingValuationSession.marketData.volatility);
      }

      this.view.init(vData.volatility);
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
