export const selectedEnvironments = {
  averagingData: '7485b383-9399-42e1-bc28-d46839aa2424',
  borrow: '7485b383-9399-42e1-bc28-d46839aa2424',
  credit: '7485b383-9399-42e1-bc28-d46839aa2424',
  dividends: '4d4ecb43-06c4-40ec-b2dd-389222ce01ee',
  funding: '7485b383-9399-42e1-bc28-d46839aa2424',
  interestRates: '7485b383-9399-42e1-bc28-d46839aa2424',
  modelSettings: '7485b383-9399-42e1-bc28-d46839aa2424',
  pricing: '7485b383-9399-42e1-bc28-d46839aa2424',
  volatility: '7485b383-9399-42e1-bc28-d46839aa2424',
  witholdingTax: '7485b383-9399-42e1-bc28-d46839aa2424'
};
