<kendo-tabstrip class="lv-tabstrip lv-tabstrip-min-height lv-settings-content"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
  selected="true"
  title="General"
  cssClass="DM-4735">
  <ng-template kendoTabContent>
    <lv-bond-general-settings
    data-cy="DM-4735"
    [systemDefaults]="settings.systemDefaults.generalSettings"
    [myDefaults]="settings.myDefaults.generalSettings"></lv-bond-general-settings>
  </ng-template>
</kendo-tabstrip-tab>

<kendo-tabstrip-tab
  title="Coupon"
  cssClass="DM-4736">
  <ng-template kendoTabContent>
    <lv-bond-coupon-settings
    data-cy="DM-4736"
    [systemDefaults]="settings.systemDefaults.couponSettings"
    [myDefaults]="settings.myDefaults.couponSettings"></lv-bond-coupon-settings>
  </ng-template>
</kendo-tabstrip-tab>

<kendo-tabstrip-tab
  title="Accretion"
  cssClass="DM-4737">
  <ng-template kendoTabContent>
    <lv-bond-accretion-settings
      data-cy="DM-4737"
      [systemDefaults]="settings.systemDefaults.accretionSettings"
      [myDefaults]="settings.myDefaults.accretionSettings"></lv-bond-accretion-settings>
  </ng-template>
</kendo-tabstrip-tab>

<kendo-tabstrip-tab
  title="Calls"
  cssClass="DM-4738">
  <ng-template kendoTabContent>
    <lv-bond-call-settings
      data-cy="DM-4738"
      [systemDefaults]="settings.systemDefaults.callSettings"
      [myDefaults]="settings.myDefaults.callSettings"
    ></lv-bond-call-settings>
  </ng-template>
</kendo-tabstrip-tab>

<kendo-tabstrip-tab
title="Puts"
cssClass="DM-4739">
<ng-template kendoTabContent>
  <lv-bond-put-settings
    data-cy="DM-4739"
    [systemDefaults]="settings.systemDefaults.putSettings"
    [myDefaults]="settings.myDefaults.putSettings"
  ></lv-bond-put-settings>
</ng-template>
</kendo-tabstrip-tab>

<kendo-tabstrip-tab
title="Make-Whole"
cssClass="DM-4740">
<ng-template kendoTabContent>
  <lv-bond-make-whole-settings
    data-cy="DM-4740"
    [systemDefaults]="settings.systemDefaults.makeWholeSettings"
    [myDefaults]="settings.myDefaults.makeWholeSettings"
  ></lv-bond-make-whole-settings>
</ng-template>
</kendo-tabstrip-tab>

</kendo-tabstrip>

<lv-flex-box class="lv-settings-footer" direction="row">
    <button kendoButton
      [primary]="true"
      lvId="cancel-terms-settings"
      (click)="onCancel()">
        Cancel
    </button>
    <div class="lv-settings-footer-divider">&nbsp;</div>
  
    <button kendoButton
      class="reset-button"
      [primary]="true"
      lvId="reset-to-default-terms-settings"
      (click)="onResetToDefault()">
      Reset To Default
    </button>
    <button kendoButton
      [primary]="true"
      lvId="save-terms-settings"
      [class.save-settings-btn-disabled]="!isChanged()"
      (click)="onSave()">
        Save
    </button>
  </lv-flex-box>
