import { v4 } from 'uuid';
import { LvMath } from '@lv-core-ui/util';
import { ConvertibleHelper, AnalyticsSettingsHelper } from '@lv-analytics/helpers';
import { ICurrencyRadioButton, IDifferentDeltaExecutionInput, IDifferentDeltaExecutionOutput, IAnalyticsSettings,
         IValuationResult, IPricing, ConvertibleSubType } from '@lv-analytics/models';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Different delta execution view.
 */
export class DifferentDeltaExecutionView {
  currentCCY: string;
  radioFields: ICurrencyRadioButton[];
  sharesToBuySell: string;
  suffixDelta: string;

  input: IDifferentDeltaExecutionInput;
  output: IDifferentDeltaExecutionOutput;

  private _cHelper: ConvertibleHelper;
  private _asHelper = new AnalyticsSettingsHelper();

  private radioFieldsDict: {
    [fieldName: string]: string;
  };

  constructor(
    private _lvDateService: LvDateService
  ) {
    this._cHelper = new ConvertibleHelper(_lvDateService);
    this._asHelper = new AnalyticsSettingsHelper();
    this.init();
  }

  /**
   * Initialize analytics settings
   * @param settings new analytics settings
   */
  init(settings?: IAnalyticsSettings) {

    this._asHelper.init(settings);
    this._cHelper.init(this._asHelper.convertible);

    this.input = {
      convertibleQuantity: this._asHelper.getConvertiblePosition,
      commisionRadio: 'bp'
    } as IDifferentDeltaExecutionInput;

    this.output = {} as IDifferentDeltaExecutionOutput;



    if (!this.radioFields) {
      this.radioFieldsDict = {
        'cb': 'CB',
        'eq': 'EQ',
        'usd': 'USD'
      };
    }

    this.currentCCY = 'cb';
    this.sharesToBuySell = 'Stock to BUY';
    this.radioFields = this.getRadioFields();
    this.setCrossFX();
    if (this._asHelper.instrumentLoaded) {
      this.onPricingUpdated(this._asHelper.pricing);
    }

    this.suffixDelta = this.isPeps ? 'Shares' : '%';
  }

  /**
   * Occurs on valuation update.
   * @param data IValuationResult object.
   */
  onValuationUpdate(data: IValuationResult): any {
    if (!LvMath.isNumber(this.input.myDelta) || LvMath.isZeroNumber(this.input.myDelta)) {

      if (!this.isPeps) {
        this.input.myDelta = LvMath.isNumber(data.outputs.delta) ? data.outputs.delta : 0;
        this.suffixDelta = '%';
      } else {
        this.input.myDelta = LvMath.isNumber(data.outputs.deltaShares) ? data.outputs.deltaShares : 0;
        this.suffixDelta = 'Shares';
      }
    } else {
      this.suffixDelta = !this.isPeps ? '%' : 'Shares';
    }
  }

  /**
   * Occurs on pricing update.
   * @param pricing IPricing object.
   */
  onPricingUpdated(pricing: IPricing) {
    this.input.stockRefEQ = pricing?.stockPriceUndCcy;
    this.input.stockRefCB = pricing?.stockPriceCbCcy;
    this.input.crossFXT0 = pricing?.crossFx;
    this.input.crossFXT1 = pricing?.crossFx;
    this.recalculateLivePrice(this.isDetachableOrDeltaNeutral);
  }

  get isDetachableOrDeltaNeutral(): boolean {
    if (!this._cHelper.convertible) {
      return false;
    }

    return this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral;
  }

  get isPeps(): boolean {
    if (!this._cHelper.convertible) {
      return false;
    }

    return this._cHelper.convertible.subType === ConvertibleSubType[ConvertibleSubType.PEPS];
  }

  get currentCurrency(): string {
    if (this.currentCCY === 'usd') {
      return 'USD';
    }
    return this.currentCCY === 'cb' ?  this._cHelper.currencyCode : this._cHelper.underlyingCurrencyCode;
  }

  get convertibleCountryCode(): string {
    if (!this._asHelper || !this._asHelper.convertible || !this._asHelper.convertible.countryCode) {
      return '';
    }
    return this._asHelper.convertible.countryCode;
  }

  get convertibleStatus(): string {
    if (!this._asHelper || !this._asHelper.convertible || !this._asHelper.convertible.status) {
      return '';
    }
    return this._asHelper.convertible.status;
  }

  get isPrivate(): boolean {
    if (!this._asHelper || !this._asHelper.settings || !this._asHelper.settings.valuationSession) {
      return false;
    }
    return this._asHelper.settings.valuationSession.isPrivateInstrument;
  }

  /**
   * Calculates different delta execution.
   */
  calculateDifferentDeltaExecution() {
    this.calculateStockFields();
    this.calculatePriceFields();
    this.calculatePnLFields();
  }

  /**
   * Occurs on stock ref EQ change.
   */
  stockRefEQChange() {
    if (!LvMath.isZeroNumber(this.input.crossFXT0)) {
      this.input.stockRefCB = this.input.stockRefEQ / this.input.crossFXT0;
      this.calculateDifferentDeltaExecution();
    }
  }

  /**
   * Occurs on stock ref CB change.
   */
  stockRefCBChange() {
    const crossFXT0 = LvMath.isNumber(this.input.crossFXT0) ? this.input.crossFXT0 : 0;
    this.input.stockRefEQ = this.input.stockRefCB * crossFXT0;
    this.calculateDifferentDeltaExecution();
  }

  /**
   * Occurs on live price CB change.
   */
  livePriceCBChange() {
    const crossFXT1 = LvMath.isNumber(this.input.crossFXT1) ? this.input.crossFXT1 : 0;
    this.input.livePriceEQ = this.input.livePriceCB * crossFXT1;
    this.calculateDifferentDeltaExecution();
  }

  /**
   * Occurs on lice price EQ change.
   */
  livePriceEQChange() {
    if (!LvMath.isZeroNumber(this.input.crossFXT1)) {
      this.input.livePriceCB = this.input.livePriceEQ / this.input.crossFXT1;
    }
    this.calculateDifferentDeltaExecution();
  }

  /**
   * Occurs on cross FTT0 change.
   */
  crossFXT0Change() {
    if (!LvMath.isZeroNumber(this.input.crossFXT0) && !LvMath.isZeroNumber(this.input.stockRefEQ)) {
      this.input.stockRefCB = this.input.stockRefEQ / this.input.crossFXT0;
    }
    this.calculateDifferentDeltaExecution();
  }

  /**
   * Occurs on cross FXT1 change.
   */
  crossFXT1Change() {
    if (!LvMath.isZeroNumber(this.input.crossFXT1) && !LvMath.isZeroNumber(this.input.livePriceEQ)) {
      this.input.livePriceCB = this.input.livePriceEQ / this.input.crossFXT1;
    }
    this.calculateDifferentDeltaExecution();
  }

  /**
   * Occurs on Pricing changed, 
   * in case of Regular convertibles, Stock Price CB 1 = Stock Live CB and then event to recalculate Stock Price EQ 1 should be triggered
   * in case of Delta neutrals and DW: Stock Price EQ 1 = Stock Live EQ and then event to recalculate CB 1
   * @param isDetachableOrDeltaNeutral is convertible Detachable Or Delta Neutral.
   */
  recalculateLivePrice(isDetachableOrDeltaNeutral: boolean) {
    if (isDetachableOrDeltaNeutral) {
      if (!LvMath.isZeroNumber(this.input.crossFXT1) && this.input.livePriceEQ) {
        this.input.livePriceCB = this.input.livePriceEQ / this.input.crossFXT1;
      }
    }
    else {
      if (this.input.livePriceCB) {
        const crossFXT1 = LvMath.isNumber(this.input.crossFXT1) ? this.input.crossFXT1 : 0;
        this.input.livePriceEQ = this.input.livePriceCB * crossFXT1;
      }
    }

  }
  
  /**
   * Function for calculate Stock Fields
   */
  private calculateStockFields() {
    const convertibleQuantity = this.input.convertibleQuantity;
    const convRatio = this._cHelper.isPeps ? 1 : this._asHelper.getConversionRatio();
    let cptyDelta = this.input.cptyDelta;
    let myDelta = this.input.myDelta;
    let stockToBuySell;

    if (!this._cHelper.isPeps) {
      cptyDelta /=  100;
      myDelta /= 100;
    }

    if (!LvMath.isZeroNumber(convertibleQuantity) && !LvMath.isZeroNumber(cptyDelta) && !LvMath.isZeroNumber(myDelta)) {
      this.output.cptyStockQuantity = Math.round((-1 * (this.input.convertibleQuantity * cptyDelta * convRatio / this.faceValueFactor)));
      this.output.myStockQuantity = Math.round((-1 * (convertibleQuantity * myDelta * convRatio / this.faceValueFactor)));
      stockToBuySell = this.output.myStockQuantity - this.output.cptyStockQuantity;
      this.output.stockToBuySellQty = Math.abs(stockToBuySell);
      this.sharesToBuySell = stockToBuySell < 0 ? 'Stock to SELL' : 'Stock to BUY';
    }
  }

  /**
   * Function for calculate Price Fields
   */
  private calculatePriceFields() {
    if (this.allNotZero(['stockRefCB', 'stockRefEQ', 'livePriceCB', 'crossFXT0', 'crossFXT1'])) {
      this.output.priceChangeCB = 100 * (this.input.livePriceCB - this.input.stockRefCB) / this.input.stockRefCB;

      if (this._cHelper.isCrossFx) {
        this.output.priceChangeEQ = 100 * (this.input.livePriceEQ - this.input.stockRefEQ) / (this.input.stockRefEQ);
      }
    }
  }

  /**
   * Function for calculate PnL Fields
   */
  private calculatePnLFields() {
    const sharesToBuy = this.output.myStockQuantity - this.output.cptyStockQuantity;
    const displayPrice = this.getPrice();
    let commision;

    if (!LvMath.isZeroNumber(this.input.livePriceCB) && !LvMath.isZeroNumber(this.input.crossFXT1)) {
      commision = this.getCommision(this.input.commision);
      const livePriceCB = parseFloat(this.input.livePriceCB.toFixed(4));
      this.output.stockToBuySellPrice = Math.round(((Math.abs(sharesToBuy) * livePriceCB)) * displayPrice);
      this.output.totalPnL = this.calculateTotalPnL(sharesToBuy, commision, displayPrice,
                             this.output.myStockQuantity, this.output.cptyStockQuantity);
      this.output.ptsPnL = this.calculateTotalPnlPts(displayPrice);
    }
  }

  /**
   * Function for calculate PnL total Field
   */
   // tslint:disable-next-line:max-line-length
   calculateTotalPnL(sharesToBuy: number, commision: number, displayPrice: number, myStockQuantity: number, cptyStockQuantity: number): number {
    let totalPnL = 0;
    let expressionOne = 0;
    let expressionTwo = 0;

    if (this.allNotZero(['stockRefCB', 'crossFXT0'])) {

      if ((Math.abs(myStockQuantity) > Math.abs(cptyStockQuantity) && this.input.convertibleQuantity > 0) ||
          (Math.abs(myStockQuantity) < Math.abs(cptyStockQuantity) && this.input.convertibleQuantity < 0)) {
        expressionOne =  (this.input.livePriceCB  - this.input.stockRefCB) * Math.abs(sharesToBuy);
        expressionTwo = commision * this.input.livePriceCB * Math.abs(sharesToBuy);
        totalPnL = Math.round((expressionOne - expressionTwo) * displayPrice);
      }
      else {
        expressionOne =  (this.input.livePriceCB  - this.input.stockRefCB) * Math.abs(sharesToBuy);
        expressionTwo = commision * this.input.livePriceCB * Math.abs(sharesToBuy);
        totalPnL = Math.round((expressionOne + expressionTwo) * displayPrice) * -1;
      }
    }
    return totalPnL;
  }

  /**
   * Function for calculate PnL total in points
   */
  private calculateTotalPnlPts(displayPrice: number): number {
    let totalPnlPts = 0;
    if (!LvMath.isZeroNumber(this.input.convertibleQuantity)) {
      totalPnlPts = (this.output.totalPnL / displayPrice) * this.priceAsParFactor / (Math.abs(this.input.convertibleQuantity));
    }

    return totalPnlPts;
  }

  get isCrossFx(): boolean {
    return this._cHelper.isCrossFx;
  }

  get cbCurrency(): string {
    if (!this._cHelper.convertible) {
      return 'N/A';
    }
    return this._cHelper.currencyCode;
  }

  get eqCurrency(): string {
    if (!this._cHelper.convertible) {
      return 'N/A';
    }
    return this._cHelper.underlyingCurrencyCode;

  }

  get hasConvertible(): boolean {
    return this._asHelper.instrumentLoaded;
  }

  /**
   * Sets cross FX.
   */
  private setCrossFX() {
    if (!this._cHelper.isCrossFx) {
      this.input.crossFXT0 = 1;
      this.input.crossFXT1 = 1;
    }
  }

  /**
   * Gets price.
   * @returns Price.
   */
  private getPrice(): number {
    let fxRate;
    if (this.currentCCY === 'usd') {
      fxRate = 1 / this._asHelper.cbCcyUsdFxRate;
    }
    else {
      this.currentCCY === 'eq' ? fxRate = this.input.crossFXT1 : fxRate = 1;
    }

    return fxRate;
  }

  get faceValueFactor(): number {
    return this._cHelper.isPriceAsPar ? this._cHelper.getSafeNominal() : 1;
  }

  get priceAsParFactor(): number {
    return this._cHelper.isPriceAsPar ? 100 : 100 / this._cHelper.getSafeNominal();
  }

  /**
   * Gets commision.
   * @param commision Commision.
   * @returns Commision.
   */
  private getCommision(commision: number): number {

    if (!commision) {
      return 0;
    }

    const comm = this.input.commisionRadio === 'bp' ? commision /= 10000 : commision / this.input.livePriceCB;

    return comm;
  }

  /**
   * Gets radio fields.
   * @returns List of ICurrencyRadioButton objects.
   */
  private getRadioFields(): ICurrencyRadioButton[] {
    let radioFields = Object.keys(this.radioFieldsDict);

    if (this._cHelper.isCrossFx && (this._cHelper.currencyCode === 'USD' || this._cHelper.underlyingCurrencyCode  === 'USD')) {
      radioFields = radioFields.filter(a => a !== 'usd');
    }

    if (!this._cHelper.isCrossFx) {
      radioFields = radioFields.filter(a => this._cHelper.currencyCode === 'USD' ? a === 'cb' : a !== 'eq');
    }

    return radioFields.map(a => {
      let label = this.radioFieldsDict[a];

      if (a !== 'usd') {
        label = `${a === 'cb' ? this._cHelper.currencyCode : this._cHelper.underlyingCurrencyCode}`;
      }

      return {
        id: `different-delta-radio-id-${v4()}`,
        name: `different-delta-radio-name-${v4()}`,
        label: label,
        lvId: `${a}DifferentDeltaExecutionRadioButton`,
        value: a
      } as ICurrencyRadioButton;
    });
  }

  /**
   * Checks if all numbers are not equal Zero.
   * @param fields List of fields.
   * @returns A flag indicating if all numebers are not equal to zero.
   */
  allNotZero(fields: string[]) {
    for (let i = 0; i < fields.length; i++) {
      if (LvMath.isZeroNumber(this.input[fields[i]])) {
        return false;
      }
    }

    return true;
  }
}
