import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBondCreditSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-credit-settings/market-data-bond-credit-settings';

@Component({
  selector: 'lv-credit-bond-settings',
  templateUrl: './lv-credit-bond-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCreditBondSettingsComponent {
  @Input() settings: IBondCreditSettings;

  @HostBinding('class.lv-credit-bond-settings')
  get isLvCreditBondSettingsComponent () {
    return true;
  }

}
