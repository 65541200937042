<lv-flex-box class="call-settings-column">
  <div class="lv-label-field lv-label lv-label--title">CALL PARAMETERS</div>

  <lv-flex-box data-cy="DM-1560" direction="row">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callValueTypeRegularSourceTooltipTemplate"
      filter="span"
    >
      <span> Call Value Type (Regular) </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="callValueType"
        [disabled]="!overrideDefaults"
        [data]="callValueTypeRegularLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 216 }"
        [(ngModel)]="settings.settings.valueTypeRegular"
      >
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box data-cy="DM-1562" direction="row">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callYieldFrequencySourceTooltipTemplate"
      filter="span"
    >
      <span> Call Yield Frequency </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="callYieldFrequency"
        [data]="callYieldFrequencyLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.yieldFrequency"
      >
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box data-cy="DM-1563" direction="row" class="space-bottom">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callYieldDayCountSourceTooltipTemplate"
      filter="span"
    >
      <span [attr.data-tooltip-id]="'callYieldDayCountSourceTooltip'">
        Call Yield Day Count
      </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="callYieldDayCount"
        [data]="callYieldDayCountLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.yieldDayCount"
      >
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>
<lv-flex-box class="call-settings-column call-settings-second-column">
  <div class="lv-label-field lv-label lv-label--title">CALL NOTICE</div>

  <lv-flex-box data-cy="DM-1567" direction="row" class="lv-input-field">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callNoticeDaysSourceTooltipTemplate"
      filter="span"
    >
      <span> Call Notice Days (min) </span>
    </div>
    <lv-numeric-text-box
      name="callNoticeDays"
      [disabled]="!overrideDefaults"
      [format]="numberZeroFormat"
      [decimals]="numberOfZeroDecimals"
      [(ngModel)]="settings.settings.notice"
    >
    </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box data-cy="DM-1568" direction="row" class="lv-input-field">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callNoticeDaysMaxSourceTooltipTemplate"
      filter="span"
    >
      <span> Call Notice Days (max) </span>
    </div>
    <lv-numeric-text-box
      name="callNoticeDaysMax"
      [disabled]="!overrideDefaults"
      [format]="numberZeroFormat"
      [decimals]="numberOfZeroDecimals"
      [(ngModel)]="settings.settings.callNoticeDaysMax"
    >
    </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box data-cy="DM-1569" direction="row">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callNoticeDaysTypeSourceTooltipTemplate"
      filter="span"
    >
      <span> Call Notice Days (type) </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="callNoticeBusinessCalendar"
        [data]="callNoticeLookup.items"
        [disabled]="!overrideDefaults"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.noticePeriod"
      >
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box data-cy="DM-1573" direction="row">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="forfeitCouponSourceTooltipTemplate"
      filter="span"
    >
      <span> Forfeit Coupon </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [attr.id]="forfeitCouponId"
        [(ngModel)]="settings.settings.forfeitCoupon"
      />
      <label class="k-checkbox-label" [attr.for]="forfeitCouponId"></label>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #callValueTypeRegularSourceTooltipTemplate>
  <lv-tooltip dm="dM-1560"></lv-tooltip>
</ng-template>

<ng-template #callYieldFrequencySourceTooltipTemplate>
  <lv-tooltip dm="dM-1562"></lv-tooltip>
</ng-template>

<ng-template #callYieldDayCountSourceTooltipTemplate>
  <lv-tooltip dm="dM-1563"></lv-tooltip>
</ng-template>

<ng-template #callNoticeDaysSourceTooltipTemplate>
  <lv-tooltip dm="dM-1567"></lv-tooltip>
</ng-template>

<ng-template #callNoticeDaysMaxSourceTooltipTemplate>
  <lv-tooltip dm="dM-1568"></lv-tooltip>
</ng-template>

<ng-template #callNoticeDaysTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1569"></lv-tooltip>
</ng-template>

<ng-template #forfeitCouponSourceTooltipTemplate>
  <lv-tooltip dm="dM-1573"></lv-tooltip>
</ng-template>
