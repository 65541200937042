<lv-advanced-grid
  data-cy="DM-983"
  lvId ="lv-floating-spread-schedule"
  [columns]="columns"
  [records]="floatingSpreadScheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="endDate"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  class="lv-spread-schedule">
</lv-advanced-grid>

