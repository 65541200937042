<lv-flex-box
  data-cy="DM-4848"
  direction="row"
  class="lv-reference-ir-swap-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="payLegFormulaTypeTooltipTemplate"
    filter="span">
    <span> Pay Leg Frequency </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      [data]="payLegFrequencyTypeLookup.items"
      class="lv-asw-type"
      name="asw-pay-leg-frequency"
      valueField="text"
      textField="text"
      valuePrimitive="true"
      [(ngModel)]="model.payLegFrequency"
      (ngModelChange)="onReferenceIrSwapChange()">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  data-cy="DM-4849"
  direction="row"
  class="lv-reference-ir-swap-field">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="payLegDayCountTooltipTemplate"
    filter="span">
    <span> Pay Leg Day Count </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      [data]="payLegDayCountTypeLookup.items"
      class="lv-asw-type"
      name="asw-pay-leg-frequency"
      valueField="text"
      textField="text"
      valuePrimitive="true"
      [(ngModel)]="model.payLegDayCount"
      (ngModelChange)="onReferenceIrSwapChange()">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-4850"
  class="lv-reference-ir-swap-dates">
  <div class="lv-label lv-label-field lv-label-short"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="interestPaymentCommencementDateSourceTooltip"
    filter="span:not(lv-xl-label span)">
    <span> Interest Payment Commencement Date </span>
    <lv-xl-label [field]="'INT_PAY_COMM_DT'"></lv-xl-label>
  </div>

  <lv-datepicker
    name="asw-interest-payment-commencement-date"
    [checkBoxLvId]="dpCommencementDateCheckBoxId"
    [datePickerLvId]="dpCommencementDatePickerId"
    [(ngModel)]="model.interestPaymentCommencementDate"
    [checked]="commencementDateEnabled"
    (ngModelChange)="onReferenceIrSwapChange()"
    (didCheckboxValueChange)="aswCommencementCheckBoxChanged($event)">
  </lv-datepicker>
</lv-flex-box>

<lv-flex-box
  *ngIf="isAswTypeCallable"
  data-cy="DM-4851"
  direction="row"
  class="lv-reference-ir-swap-additional-payment">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="additionalPaymentAtAswMatSourceTooltipTemplate"
    filter="span">
    <span> Additional Payment at ASW Maturity </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      name="additional-payment-at-asw-mat"
      [format]="formatTwo"
      [decimals]="decimalsTwo"
      [(ngModel)]="model.additionalPaymentAtAswMaturity"
      (didBlur)="onReferenceIrSwapChange()"
      (didKeyDownEnter)="onReferenceIrSwapChange()">
    </lv-numeric-text-box>
  </div>
  <div class="lv-suffix">
    <span>% of Par</span>
  </div>
</lv-flex-box>

<ng-template #payLegFormulaTypeTooltipTemplate>
  <lv-tooltip dm="dM-4848"></lv-tooltip>
</ng-template>
<ng-template #payLegDayCountTooltipTemplate>
  <lv-tooltip dm="dM-4849"></lv-tooltip>
</ng-template>
<ng-template #interestPaymentCommencementDateSourceTooltip>
  <lv-tooltip dm="dM-4850"></lv-tooltip>
</ng-template>
<ng-template #additionalPaymentAtAswMatSourceTooltipTemplate>
  <lv-tooltip dm="dM-4851"></lv-tooltip>
</ng-template>
