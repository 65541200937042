<div class="lv-label lv-label-field lv-label--title">
  Model Type
</div>
<lv-flex-box
  direction="row"
  data-cy="DM-1442">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="creditModelTypeSettingsTooltipTemplate"
    filter="span">
    <span> Credit Model Type </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="modelCustomizationModelTypeCreditModelType"
      valueField="id"
      textField="text"
      [data]="creditModelType.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.creditModelType"
      [disabled]="!overrideSystemDefaults"
      [popupSettings]="{ width: 151 }">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>


<ng-template #creditModelTypeSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1442"></lv-tooltip>
</ng-template>
<ng-template #equityModelTypeSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1443"></lv-tooltip>
</ng-template>
