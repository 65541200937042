/**
 * AswIdentifierType enum.
 */
export enum AswIdentifierType {
  ISIN = 'ISIN',
  CUSIP = 'CUSIP',
  FIGI = 'FIGI',
  PERMID = 'PERMID', 
}

/**
 * AswIdentifierTypeDescription enum.
 */
export enum AswIdentifierTypeDescription {
  ISIN = 'ISIN',
  CUSIP = 'CUSIP',
  FIGI = 'FIGI',
  PERMID = 'Perm ID',
}