import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  HostBinding, Output, EventEmitter, ChangeDetectorRef, ElementRef, 
  Input} from '@angular/core';
import { IModelCustomizationSettings, ICreditModelSettings, ICallAdjustmentModelSettings, IModelCustomizationSectionSettings,
         ISaveModelCustomizationSettingsRequest } from '@lv-analytics/models';
import { InstrumentType } from '@lv-analytics/models/enum/instrument-type';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import * as _ from 'lodash';

/**
 * Model customization settings component.
 */
@Component({
  selector: 'lv-model-customization-settings',
  templateUrl: './lv-model-customization-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvModelCustomizationSettingsComponent implements OnInit, IDiscardable {
  @Input() instrumentType: string

  @Output() didCancel: EventEmitter<void>;
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;

  settings: IModelCustomizationSettings;
  originalSettings: IModelCustomizationSettings;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _discardChangesService: DiscardChangesService
  ) {
    this.instrumentType = '';
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {
        creditModelSettings: {} as ICreditModelSettings,
        callAdjustmentModelSettings: {} as ICallAdjustmentModelSettings
      } as IModelCustomizationSectionSettings,
      environments: []
    } as IModelCustomizationSettings;
    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('ModelCustomization', this);
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-model-customization-settings')
  get isLvModelCustomizationSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-section-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.getModelCustomizationSettings();
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.getModelCustomizationAswSettings();
      }

      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves model customization settings.
   */
  async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next();
      
      if (this.instrumentType === InstrumentType.ConvertibleBond) {
          await this._companyAndUserSettingsService.saveModelCustomizationSettings({settings: this.settings} as ISaveModelCustomizationSettingsRequest);
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        await this._companyAndUserSettingsService.saveModelCustomizationAswSettings({settings: this.settings} as ISaveModelCustomizationSettingsRequest);
      }

      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'Model Customization'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * Resets model customization settings.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();

      if (this.instrumentType === InstrumentType.ConvertibleBond) {
        this.settings = await this._companyAndUserSettingsService.resetModelCustomizationSettings();
      }
      else if (this.instrumentType === InstrumentType.AssetSwap) {
        this.settings = await this._companyAndUserSettingsService.resetModelCustomizationAswSettings();
      }
      
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'Model Customization'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
   this.didCancel.next();
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
  getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }
}
