<lv-settings-widget class="lv-pricing"
  data-cy="DM-1219"
  titleText="Pricing"
  [isLoading]="isLoading"
  [width]="230"
  [class.lv-pricing-hide-cog]="!(showSettings || (!showSettings && view.cpeView.isVisible))"
  sidePanelLvId="lv-pricing-side-panel"
  [showWarrning] = "isWarrningVisible"
  (didClosePanel)="onPanelClosed()"
  (didPanelSlide)="onPanelSlide($event)"
  (didWarrningClicked)="onWarrningClicked()">

  <ng-container content>
    <div class="lv-pricing-content" #pricingWidget>
      <lv-environment-settings
        [lvId]="lvPricingEnvironmentSettings"
        [section]="pricingSection"
        [showControls]="true"
        instrumentType="ConvertibleBond"
        [saveToExcel]="isOpenedFromExcel"
        (didChangeEnvironment)="onChangedEnvironment($event)"
        (didReorderEnvironments)="onReorderEnvironments($event)"
        (doSave)="onSaveSection()"
        (doSaveToExcel)="onSaveSectionToExcel()"
        (doReload)="onReloadSection()">
        <ng-template *ngIf="resizeHandlerView.estimatesButtonWideForm" [ngTemplateOutlet]="settingsButtons"></ng-template>

      </lv-environment-settings>

      <lv-flex-box class="lv-pricing-inner-content">
        <lv-flex-box *ngIf="view.cpeView.isVisible && !widgetState.displayEstimatesButton && view.isExpectedRatioVisible"
          data-cy="DM-225"
          class="lv-conversion-price-estimate"
          [direction]="resizeHandlerView.cpeDirection">
          <div class="lv-field lv-field--radio">
            <input class="k-radio"
              data-cy="DM-225_1"
              type="radio"
              [attr.id]="view.cpeView.currentRatioId"
              [attr.name]="view.cpeView.currentRatioId"
              [value]="false"
              [(ngModel)]="view.cpeView.model.useEstimatedRatio"
              (ngModelChange)="onEstimatedRatioChange()">
            <label class="k-radio-label"
              [attr.for]="view.cpeView.currentRatioId"
              lvId="lv-pricing-cpe-current-ratio-rb">
                {{ view.cpeView.currentRatioLabel }}
            </label>
            <lv-xl-label *ngIf="!view.cpeView.model.useEstimatedRatio" [field]="'EST_USE'"></lv-xl-label>
          </div>
          <div class="lv-field lv-field--radio">
            <input class="k-radio"
              type="radio"
              data-cy="DM-225_2"
              [attr.id]="view.cpeView.expectedRatioId"
              [attr.name]="view.cpeView.expectedRatioId"
              [value]="true"
              [(ngModel)]="view.cpeView.model.useEstimatedRatio"
              (ngModelChange)="onEstimatedRatioChange()">
            <label class="k-radio-label"
              [attr.for]="view.cpeView.expectedRatioId"
              lvId="lv-pricing-cpe-expected-ratio-rb">
                {{ view.cpeView.expectedRatioLabel }}
            </label>
            <lv-xl-label *ngIf="view.cpeView.model.useEstimatedRatio" [field]="'EST_USE'"></lv-xl-label>
          </div>
        </lv-flex-box>

        <ng-template *ngIf="!resizeHandlerView.estimatesButtonWideForm" [ngTemplateOutlet]="settingsButtons"></ng-template>

        <lv-flex-box class="lv-pricing-inner-content-inputs" [direction]="resizeHandlerView.pricingNewIssueSetsDirection">
          <form #valuationInputsForm="ngForm"
            class="lv-valuation-inputs">
            <lv-flex-box [direction]="resizeHandlerView.pricingSetsDirection">

              <lv-flex-box class="lv-valuation-inputs-row lv-valuation-inputs-row--right-margin"

                [style.marginBottom.px]="resizeHandlerView.pricingSectionMarginBottom"
                [direction]="resizeHandlerView.valuationInputDirection">

                <lv-flex-box data-cy="DM-231"
                  [direction]="resizeHandlerView.labelAndInputBoxDirection"
                  [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                  direction="row"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="pricingPriceTooltip"
                  filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label lv-Id="lv-pricing-price-label" class="lv-label">Price ({{ view.viView.priceSuffix }})</label>
                    <lv-xl-label [field]="'PRICE'"></lv-xl-label>
                  </div>
                  <lv-numeric-text-box
                    name="valuationInputsPrice"
                    [lvId]="valuationInputsPrice"
                    [decimals]="8"
                    [(ngModel)]="view.viView.model.price"
                    [disabled]="!view.viView.hasConvertible"
                    (ngModelChange)="onValuationInputChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box data-cy="DM-233"
                  [direction]="resizeHandlerView.labelAndInputBoxDirection"
                  [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                  direction="row"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="pricingStocRefCbCCYTooltip"
                  filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label lv-Id="lv-stock-price-pricing-label" class="lv-label" *ngIf="!isOpenedFromExcel">Stock Price ({{ view.viView.stockPriceCbCcySuffix }})</label>
                    <label lv-Id="lv-stock-price-pricing-label" class="lv-label" *ngIf="isOpenedFromExcel">Stk Price ({{ view.viView.stockPriceCbCcySuffix }})</label>
                    <lv-xl-label
                      [field]="view.stkRefCbCcyAlias">
                    </lv-xl-label>
                  </div>
                  <lv-numeric-text-box
                    name="valuationInputsStockPriceCbCcy"
                    [lvId]="valuationInputsStockPriceCbCcy"
                    [decimals]="8"
                    [(ngModel)]="view.viView.model.stockPriceCbCcy"
                    [disabled]="!view.viView.hasConvertible"
                    (ngModelChange)="onConvertibleStockPriceChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()"
                    required>
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box data-cy="DM-235" *ngIf="view.viView.modelFields.stockPriceUndCcy" [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingStocRefEqCCYTooltip"
                    filter=".lv-label"
                    >
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label" *ngIf="!isOpenedFromExcel">Stock Price ({{ view.viView.stockPriceUndCcySuffix }})</label>
                    <label class="lv-label" *ngIf="isOpenedFromExcel">Stk Price ({{ view.viView.stockPriceUndCcySuffix }})</label>
                    <lv-xl-label *ngIf="!isCrossFxPopulated"
                      [field]="view.stkRefEqCcyAlias">
                    </lv-xl-label>
                    <lv-xl-label *ngIf="isCrossFxPopulated"
                      [field]="view.stkRefEqCrossFxCcyAlias">
                    </lv-xl-label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsStockPriceUndCcy"
                    [lvId]="valuationInputsStockPriceUndCcy"
                    [decimals]="8"
                    [(ngModel)]="view.viView.model.stockPriceUndCcy"
                    [disabled]="!view.viView.hasConvertible"
                    (ngModelChange)="onUnderlyingStockPriceChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()"
                    required>
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box *ngIf="view.viView.modelFields.crossFx" [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    data-cy="DM-239"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingCrossFXTooltip"
                    filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label lv-Id="lv-cross-fx-pricing-label" class="lv-label">Cross FX</label>
                    <lv-xl-label [field]="'FX'"></lv-xl-label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsCrossFx"
                    [lvId]="valuationInputsCrossFX"
                    [decimals]="8"
                    [disabled]="!view.viView.hasConvertible"
                    [(ngModel)]="view.viView.model.crossFx"
                    (ngModelChange)="onCrossFxRateChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box
                    data-cy="DM-1367"
                    *ngIf="view.viView.modelFields.parity"
                    [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingParityTooltip"
                    filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label lv-Id="lv-pricing-parity-label" class="lv-label">Parity ({{ view.viView.priceSuffix }})</label>
                  </div>
                  <lv-numeric-text-box
                    name="valuationInputsParity"
                    [lvId]= "valuationInputsParity"
                    [decimals]="8"
                    [disabled]="!view.viView.hasConvertible"
                    [(ngModel)]="view.viView.model.parity"
                    (ngModelChange)="onParityChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box class="lv-input-field lv-input-field--right lv-input-field--full"
                    [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingValuationDateTooltip"
                    filter=".lv-label"
                    data-cy="DM-229">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label" *ngIf="!isOpenedFromExcel">Valuation Date</label>
                    <label class="lv-label" *ngIf="isOpenedFromExcel">Val Date</label>
                    <lv-xl-label [field]="'VAL_DT'"></lv-xl-label>
                  </div>
                  <kendo-datepicker name="valuationInputsValuationDate" style="width: 104px"
                    tabindex="-1"
                    [lvId]= "valuationInputsValuationDate"
                    [min]="view.viView.minValuationDate"
                    [disabled]="!view.viView.hasConvertible"
                    [(ngModel)]="view.viView.model.valuationDate"
                    (blur)="onValuationDateBlur()"
                    (ngModelChange)="onValuationDateInputChanged($event)"
                    (keydown.enter)="onValuationInputKeyDown()"
                    [attr.aria-valuetext]="dateFormated"
                    required>
                  </kendo-datepicker>
                </lv-flex-box>
              </lv-flex-box>

              <lv-flex-box class="lv-valuation-inputs-row"
                *ngIf="widgetState.displayMarketDataOverrides"
                [direction]="resizeHandlerView.valuationInputDirection">
                <lv-flex-box
                data-cy="DM-1057"
                [direction]="resizeHandlerView.labelAndInputBoxDirection"
                [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                direction="row"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="pricingCreditTooltip"
                filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label">Credit (bps)</label>
                  </div>
                  <lv-numeric-text-box
                    name="valuationInputsFlatCreditSpread"
                    [placeholder]="view.viView.modelFieldsPlaceholders.flatCreditSpread"
                    [disabled]="!view.viView.hasConvertible"
                    [lvDirty]="view.viView.marketData.flatCreditSpread"
                    [(ngModel)]="view.viView.model.flatCreditSpread"
                    (ngModelChange)="onValuationInputChanged()"
                    [format]="'n0'"
                    [decimals]="'0'"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box
                    data-cy="DM-1059"
                    *ngIf="view.viView.modelFields.flatVolatility"
                    [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingVolatilityTooltip"
                    filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label">Volatility (%)</label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsFlatVolatility"
                    [placeholder]="view.viView.modelFieldsPlaceholders.flatVolatility"
                    [disabled]="!view.viView.hasConvertible"
                    [format]="'#.##'"
                    [decimals]="'2'"
                    [lvDirty]="view.viView.marketData.flatVolatility"
                    [(ngModel)]="view.viView.model.flatVolatility"
                    (ngModelChange)="onValuationInputChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box
                    data-cy="DM-1369"
                    *ngIf="view.viView.modelFields.upsideVol"
                    [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingUpsideVolTooltip"
                    filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label" *ngIf="!isOpenedFromExcel">Upside Vol (%)</label>
                    <label class="lv-label" *ngIf="isOpenedFromExcel">Up Vol (%)</label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsUpsideVol"
                    [lvId]="valuationInputsUpsideVol"
                    [placeholder]="view.viView.modelFieldsPlaceholders.upsideVol"
                    [lvDirty]="view.viView.marketData.upsideVol"
                    [disabled]="!view.viView.hasConvertible"
                    [(ngModel)]="view.viView.model.upsideVol"
                    (ngModelChange)="onValuationInputChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box
                    data-cy="DM-1368"
                    *ngIf="view.viView.modelFields.downsideVol"
                    [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingDownsideVolTooltip"
                    filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label" *ngIf="!isOpenedFromExcel">Downside Vol (%)</label>
                    <label class="lv-label" *ngIf="isOpenedFromExcel">Down Vol (%)</label>
                    <lv-xl-label [field]="'VOL_DOWN'"></lv-xl-label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsDownsideVol"
                    [lvId]="valuationInputsDownsideVol"
                    [placeholder]="view.viView.modelFieldsPlaceholders.downsideVol"
                    [lvDirty]="view.viView.marketData.downsideVol"
                    [disabled]="!view.viView.hasConvertible"
                    [(ngModel)]="view.viView.model.downsideVol"
                    (ngModelChange)="onValuationInputChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box
                    data-cy="DM-1058"
                    [direction]="resizeHandlerView.labelAndInputBoxDirection"
                    [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                    direction="row"
                    showAfter="1500"
                    kendoTooltip
                    [tooltipTemplate]="pricingBorrowTooltip"
                    filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label">Borrow (bps)</label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsFlatBorrow"
                    [placeholder]="view.viView.modelFieldsPlaceholders.flatBorrow"
                    [lvDirty]="view.viView.marketData.flatBorrow"
                    [disabled]="!view.viView.hasConvertible"
                    [format]="'n0'"
                    [decimals]="'0'"
                    [(ngModel)]="view.viView.model.flatBorrow"
                    (ngModelChange)="onValuationInputChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box
                  data-cy="DM-308"
                  [direction]="resizeHandlerView.labelAndInputBoxDirection"
                  [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                  direction="row"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="pricingDividendYielsTooltip"
                  filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label" *ngIf="!isOpenedFromExcel">Dividend Yield (%)</label>
                    <label class="lv-label" *ngIf="isOpenedFromExcel">Dvd Yield (%)</label>
                    <lv-xl-label [field]="'DVD_FLAT'"></lv-xl-label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsFlatDividendYield"
                    [placeholder]="view.viView.modelFieldsPlaceholders.flatDividendYield"
                    [lvDirty]="view.viView.marketData.flatDividendYield"
                    [disabled]="!view.viView.hasConvertible"
                    [format]="'#.####'"
                    [(ngModel)]="view.viView.model.flatDividendYield"
                    (ngModelChange)="onValuationInputChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>

                <lv-flex-box *ngIf="widgetState.displayStockSlippage && view.viView.isNewIssue"
                  data-cy="DM-4617"
                  [direction]="resizeHandlerView.labelAndInputBoxDirection"
                  [style.width.px]="resizeHandlerView.labelAndInputBoxWidth"
                  direction="row"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="stockSlippageTooltip"
                  filter=".lv-label">
                  <div class="lv-field" [style.width.px]="resizeHandlerView.labelWidth">
                    <label class="lv-label">Stock Slippage (%)</label>
                  </div>
                  <lv-numeric-text-box name="valuationInputsStockSlippage"
                    [placeholder]="view.viView.modelFieldsPlaceholders.stockSlippage"
                    [lvDirty]="view.viView.marketData.stockSlippage"
                    [disabled]="!view.viView.hasConvertible"
                    [format]="'#.####'"
                    [(ngModel)]="view.viView.model.stockSlippage"
                    (ngModelChange)="onStockSlippageChanged()"
                    (didKeyDownEnter)="onValuationInputKeyDown()">
                  </lv-numeric-text-box>
                </lv-flex-box>
              </lv-flex-box>
            </lv-flex-box>
          </form>

          <ng-template *ngIf="!widgetState.displayNewIssueAssumptionButton" [ngTemplateOutlet]="templateNewIssuePricing"></ng-template>

        </lv-flex-box>
      </lv-flex-box>

    </div>
  </ng-container>

  <ng-container settings>
    <ng-container *ngIf="!view.isNewIssueAssumptionsButtonClick">
      <ng-container *ngIf="view.isEstimateButtonClick">
        <lv-flex-box direction="column">
          <div class="lv-side-panel-link-item" *ngIf="view.isExpectedRatioVisible">
            <input class="k-radio"
              type="radio"
              [attr.id]="view.useCurrentValueSidePanelRadioButtonId"
              name="useCurrentValueSidePanelRadioButtonId"
              lvId="use-current-value-side-panel-radio-button-id"
              [value]="false"
              [(ngModel)]="view.cpeView.model.useEstimatedRatio"
              (ngModelChange)="onEstimatedRatioChange()">
            <label class="k-radio-label lv-pricing-side-panel-label"
              [attr.for]="view.useCurrentValueSidePanelRadioButtonId"
              lvId="lv-pricing-side-panel-current-ratio-rb">
                {{ view.cpeView.currentRatioLabelWithoutCcy }}
            </label>
          </div>
          <div class="lv-side-panel-link-item">
            <input class="k-radio"
              type="radio"
              [attr.id]="view.useEstimateValueSidePanelRadioButtonId"
              name="useEstimateValueSidePanelRadioButton"
              lvId="use-estimate-value-side-panel-radio-button-id"
              [value]="true"
              [(ngModel)]="view.cpeView.model.useEstimatedRatio"
              (ngModelChange)="onEstimatedRatioChange()">
            <label class="k-radio-label lv-pricing-side-panel-label"
              [attr.for]="view.useEstimateValueSidePanelRadioButtonId"
              lvId="lv-pricing-side-panel-expected-ratio-rb">
                {{ view.cpeView.expectedRatioLabelWithoutCcy }}
            </label>
          </div>
        </lv-flex-box>
      </ng-container>

      <ng-container *ngIf="view.cpeView.isVisible">
        <div class="lv-side-panel-link-item"
          lvId="lv-pricing-cpe-conversion-ratio-settings-item"
          (click)="onShowConversionPriceOrRatio(false)">
          <span>Display Conversion Ratio</span>
          <i *ngIf="!this.view.cpeView.model.showConversionPrice" class="k-icon k-i-check"></i>
        </div>
        <div class="lv-side-panel-link-item"
          [class.lv-side-panel-link-item--separator]="!this.view.cpeView.model.showConversionPrice"
          lvId="lv-pricing-cpe-conversion-price-settings-item"
          (click)="onShowConversionPriceOrRatio(true)">
          <span>Display Conversion Price</span>
          <i *ngIf="this.view.cpeView.model.showConversionPrice" class="k-icon k-i-check"></i>
        </div>
        <div *ngIf="view.cpeView.model.showConversionPrice"
          class="lv-side-panel-link-item"
          lvId="lv-pricing-cpe-use-convertible-currency-settings-item"
          (click)="onSetCrEstimateCurrency(crEstimateCurrencyEnum.ConvertibleCurrency)">
          <span>CP in Convertible CCY</span>
          <i *ngIf="view.cpeView.convertibleCurrencyUsed" class="k-icon k-i-check"></i>
        </div>
        <div *ngIf="view.cpeView.model.showConversionPrice"
          class="lv-side-panel-link-item"
          lvId="lv-pricing-cpe-use-underlying-currency-settings-item"
          (click)="onSetCrEstimateCurrency(crEstimateCurrencyEnum.UnderlyingCurrency)">
          <span>CP in Stock CCY</span>
          <i *ngIf="view.cpeView.underlyingCurrencyUsed" class="k-icon k-i-check"></i>
        </div>
        <div *ngIf="view.cpeView.model.showConversionPrice"
          class="lv-side-panel-link-item"
          [class.lv-side-panel-link-item--separator]="showSettings"
          lvId="lv-pricing-cpe-use-conversion-currency-settings-item"
          (click)="onSetCrEstimateCurrency(crEstimateCurrencyEnum.ConversionPriceCurrency)">
          <span>CP in Conversion Price CCY</span>
          <i *ngIf="view.cpeView.conversionPriceCurrencyUsed" class="k-icon k-i-check"></i>
        </div>
      </ng-container>

      <div *ngIf="showSettings"
        class="lv-side-panel-link-item"
        lvId="lv-pricing-show-estimate-history-settings-item"
        (click)="onShowEstimatesHistory()">
        <span>Show Estimates History</span>
      </div>

      <div *ngIf="showSettings"
        class="lv-side-panel-link-item"
        [class.lv-side-panel-link-item--separator]="!view.isEstimateButtonClick"
        lvId="lv-pricing-edit-estimates-settings-side-panel"
        (click)="onShowEstimatesSettingsDialog()">
        <span>Edit Estimates Settings</span>
      </div>

      <ng-container *ngIf="!view.isEstimateButtonClick">
        <div *ngIf="showSettings"
          class="lv-side-panel-link-item"
          [class.lv-side-panel-link-item--separator]="showSettings"
          lvId="lv-pricing-edit-new-issue-settings-side-panel"
          (click)="onShowNewIssueSettingsDialog()">
          <span>Edit New Issue Settings</span>
        </div>

        <div *ngIf="showSettings && !isOpenedFromExcel"
          class="lv-side-panel-link-item"
          lvId="lv-pricing-display-default-view"
          (click)="onDisplayDefaultView()">
          <span>Display Default View</span>
          <i *ngIf="widgetState.useDefaultView"
            class="k-icon k-i-check"></i>
        </div>

        <div *ngIf="showSettings && !isOpenedFromExcel"
          class="lv-side-panel-link-item lv-side-panel-link-item--separator"
          lvId="lv-pricing-display-custom-view"
          (click)="onDisplayCustomView()">
          <span>Display Custom View</span>
          <i *ngIf="!widgetState.useDefaultView"
            class="k-icon k-i-check"></i>
        </div>

        <div *ngIf="showSettings"
          class="lv-side-panel-link-item"
          lvId="lv-pricing-edit-custom-view"
          (click)="onShowEditCustomView()">
          <span>{{editWidgetButtonText}}</span>
        </div>

        <div class="lv-side-panel-link-item"
          *ngIf="!widgetState.useDefaultView"
          lvId="lv-pricing-save-as-default-view"
          (click)="onSaveAsDefaultView()">
          <span>{{saveWidgetButtonText}}</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-template *ngIf="view.isNewIssueAssumptionsButtonClick" [ngTemplateOutlet]="templateNewIssuePricing"></ng-template>

  </ng-container>
</lv-settings-widget>

<ng-template #settingsButtons>
  <lv-flex-box *ngIf="view.cpeView.isVisible && widgetState.displayEstimatesButton && view.isExpectedRatioVisible"
    data-cy="DM-225"
    class="lv-pricing-widget-buttons-section lv-pricing-buttons-xl-label-position">
    <button kendoButton
      class="lv-pricing-buttons-estimates"
      [primary]="true"
      [class.lv-pricing-custom-view-settings-narrow-form]="!resizeHandlerView.estimatesButtonWideForm"
      [class.lv-pricing-custom-view-settings-wide-form]="resizeHandlerView.estimatesButtonWideForm"
      lvId="lv-pricing-conversion-price-estimates-button"
      (click)="showEstimatesSettings('estimatesButton')">
        {{ view.cpeView.estimatesbuttonLabel | titlecase }}
    </button>
    <lv-xl-label [field]="'EST_USE'"></lv-xl-label>
  </lv-flex-box>
  <lv-flex-box
  *ngIf="view.niaView.isNewIssue && widgetState.displayNewIssueAssumptionButton"
    class="lv-pricing-widget-buttons-section lv-pricing-buttons-xl-label-position"
    data-cy="DM-356">
    <button kendoButton
      [primary]="true"
      class="lv-pricing-buttons-new-issue"
      [class.lv-pricing-custom-view-settings-narrow-form]="!resizeHandlerView.estimatesButtonWideForm"
      [class.lv-pricing-custom-view-settings-wide-form]="resizeHandlerView.estimatesButtonWideForm"
      [class.lv-pricing-widget-buttons-sectio-nia]="this.view.niaView.isNewIssue"
      [lvId]="newIssueAssumptionButton"
      (click)="showEstimatesSettings('newIssueAssumptionButton')">
        {{ view.niaView.model.pricingTypeSelected | titlecase }}
    </button>
    <lv-xl-label [field]="'PRICE_TALK'"></lv-xl-label>
  </lv-flex-box>
</ng-template>

<ng-template #templateNewIssuePricing>
  <div *ngIf="!widgetState.displayNewIssueAssumptionButton" class="lv-side-panel lv-side-panel-position">
    <lv-flex-box class="lv-new-issue-assumptions" *ngIf="view.niaView.isNewIssue" direction="column">
      <lv-flex-box direction="row"
        data-cy="DM-356">
        <div class="lv-new-issue-assumption-column lv-new-issue-assumption-first-column">&nbsp;</div>
        <div class="lv-new-issue-assumption-column lv-new-issue-assumption-column-rb lv-label" *ngFor="let rf of view.niaView.radioFields">
          <input type="radio"
            class="k-radio"
            [attr.id]="rf.id"
            [name]="rf.id"
            [attr.name]="rf.id"
            [value]="rf.value"
            [(ngModel)]="view.niaView.model.pricingTypeSelected"
            (ngModelChange)="onRadioButtonNewIssueAssumptionChange(rf.value)" />
          <label class="k-radio-label lv-label-white"
            [attr.for]="rf.id"
            [lvId]="rf.lvId">
            {{ rf.label }}
          </label>
        </div>
        <lv-xl-label [field]="'PRICE_TALK'"></lv-xl-label>
      </lv-flex-box>

      <ng-container *ngTemplateOutlet="newIssueAssumptions"></ng-container>
    
    </lv-flex-box>
  </div>
  <div *ngIf="widgetState.displayNewIssueAssumptionButton">
    <lv-flex-box class="lv-new-issue-assumptions" *ngIf="view.niaView.isNewIssue" direction="column">
      <lv-flex-box direction="row">
        <div class="lv-new-issue-assumption-column lv-new-issue-assumption-first-column">&nbsp;</div>
        <div class="lv-new-issue-assumption-column lv-new-issue-assumption-column-rb lv-label" *ngFor="let rf of view.niaView.radioFields">
          <input type="radio"
            class="k-radio"
            [attr.id]="rf.id"
            [name]="rf.id"
            [attr.name]="rf.id"
            [value]="rf.value"
            [(ngModel)]="view.niaView.model.pricingTypeSelected"
            (ngModelChange)="onRadioButtonNewIssueAssumptionChange(rf.value)" />
          <label class="k-radio-label lv-label-white"
            [attr.for]="rf.id"
            [lvId]="rf.lvId">
            {{ rf.label }}
          </label>
        </div>
        <lv-xl-label [field]="'PRICE_TALK'"></lv-xl-label>
      </lv-flex-box>

      <ng-container *ngTemplateOutlet="newIssueAssumptions"></ng-container>

    </lv-flex-box>
  </div>
</ng-template>

<ng-template #pricingPriceTooltip>
  <lv-tooltip dm="dM-231"></lv-tooltip>
</ng-template>
<ng-template #pricingStocRefCbCCYTooltip>
  <lv-tooltip dm="dM-233"></lv-tooltip>
</ng-template>
<ng-template #pricingStocRefEqCCYTooltip>
  <lv-tooltip dm="dM-235"></lv-tooltip>
</ng-template>
<ng-template #pricingCrossFXTooltip>
  <lv-tooltip dm="dM-239"></lv-tooltip>
</ng-template>
<ng-template #pricingParityTooltip>
  <lv-tooltip dm="dM-1367"></lv-tooltip>
</ng-template>
<ng-template #pricingValuationDateTooltip>
  <lv-tooltip dm="dM-229"></lv-tooltip>
</ng-template>
<ng-template #pricingCreditTooltip>
  <lv-tooltip dm="dM-1057"></lv-tooltip>
</ng-template>
<ng-template #pricingVolatilityTooltip>
  <lv-tooltip dm="dM-1059"></lv-tooltip>
</ng-template>
<ng-template #pricingBorrowTooltip>
  <lv-tooltip dm="dM-1058"></lv-tooltip>
</ng-template>
<ng-template #pricingDividendYielsTooltip>
  <lv-tooltip dm="dM-308"></lv-tooltip>
</ng-template>
<ng-template #pricingUpsideVolTooltip>
  <lv-tooltip dm="dM-1368"></lv-tooltip>
</ng-template>
<ng-template #pricingDownsideVolTooltip>
  <lv-tooltip dm="dM-1369"></lv-tooltip>
</ng-template>
<ng-template #stockSlippageTooltip>
  <lv-tooltip dm="dM-4617"></lv-tooltip>
</ng-template>
<ng-template #stockRefTooltip>
  <lv-tooltip dm="dM-360"></lv-tooltip>
</ng-template>
<ng-template #template let-anchor>
  <ng-container *ngIf="getTooltipId(anchor, 'Premium')">
    <lv-tooltip dm="dM-357"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="getTooltipId(anchor, 'Coupon')">
    <lv-tooltip dm="dM-4631"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="getTooltipId(anchor, 'Issue Price')">
    <lv-tooltip dm="dM-4633"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="getTooltipId(anchor, 'Redemption Value')">
    <lv-tooltip dm="dM-4632"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="getTooltipId(anchor, 'Issue Yield')">
    <lv-tooltip dm="dM-358"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="getTooltipId(anchor, 'Effective Premium')">
    <lv-tooltip dm="dM-4619"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #newIssueAssumptions>
  <ng-container *ngFor="let field of view.niaView.inputFields">
    <lv-flex-box *ngIf="field.visible && !isStockRef(field.fieldName)" direction="row">

      <div class="lv-new-issue-assumption-column lv-new-issue-assumption-first-column">
        <div *ngIf="!field.checkboxData.visible"
          [lvId]="view.niaView.getInputFieldLvId(field.fieldName, 'label-container')">
          <label
          showAfter="1500"
          kendoTooltip
          filter="label[data-tooltip-id]"
          [tooltipTemplate]="template"
          [attr.data-tooltip-id]="field.label"
            class="lv-label"
            [lvId]="view.niaView.getInputFieldLvId(field.fieldName, 'label')">
            {{ field.label }}
          </label>
          <lv-xl-label [field]="view.getFieldAlias(field.fieldName)"></lv-xl-label>
        </div>
      </div>

      <ng-container *ngIf="!isStockRef(field.fieldName)">
        <div
          class="lv-new-issue-assumption-column"
          [class.lv-new-issue-assumption-column--disbled]="view.niaView.isFieldDisabled(field.fieldName)">
          <lv-numeric-text-box
            [name]="field.fieldName"
            [lvId]="view.niaView.getInputFieldLvId(field.fieldName + 'Assumed', 'field')"
            [placeholder]="view.niaView.modelPriceTalk[field.fieldName + 'Mid']"
            [(ngModel)]="view.niaView.model[field.fieldName]"
            (didKeyDownEnter)="onNewIssueAssumptionChange(field.fieldName)"
            (didBlur)="onNewIssueAssumptionChange(field.fieldName)"
            [disabled]="view.niaView.isFieldDisabled(field.fieldName)">
          </lv-numeric-text-box>
        </div>
      </ng-container>

      <ng-container *ngFor="let output of field.outputs">
        <div
          class="lv-new-issue-assumption-column"
          [class.lv-new-issue-assumption-column-value]="view.niaView.getPriceTalk(output)">
          <label
            class="lv-label lv-label-white"
            [lvId]="view.niaView.getInputFieldLvId(output, 'label')">
            {{ view.niaView.getPriceTalk(output) }}
          </label>
        </div>
      </ng-container>

    </lv-flex-box>

    <ng-container *ngIf="isStockRef(field.fieldName)" >
      <ng-container *ngTemplateOutlet="stockRef">
      </ng-container>
    </ng-container>

  </ng-container>
</ng-template>

<ng-template #stockRef>

  <lv-flex-box direction="row">
    <div class="lv-new-issue-assumption-column lv-new-issue-assumption-first-column">
      <label class="lv-label">
        &nbsp;
      </label>
    </div>
    <div
      class="lv-field lv-new-issue-assumption-column"
      [lvId]="view.niaView.getInputFieldLvId('stock-ref', 'label')">
      <label class="lv-label">Stock ({{view.asHelper.convertible.currencyCode}})</label>
      <lv-xl-label [field]="'ISSUE_STK_REF_CBCCY'"></lv-xl-label>
    </div>
    <div 
      *ngIf="view.asHelper.convertible.isCrossFx"
      class="lv-field lv-new-issue-assumption-column"
      [lvId]="view.niaView.getInputFieldLvId('stock-ref-equity', 'label')">
      <label class="lv-label">Stock ({{view.asHelper.convertible.underlying.currency}})</label>
      <lv-xl-label [field]="'ISSUE_STK_REF_EQCCY'"></lv-xl-label>
    </div>
    <div 
      *ngIf="view.asHelper.convertible.isCrossFx"
      class="lv-field lv-new-issue-assumption-column"
      [lvId]="view.niaView.getInputFieldLvId('fx', 'label')">
      <label class="lv-label">FX</label>
      <lv-xl-label [field]="'ISSUE_FX_REF'"></lv-xl-label>
    </div>
  </lv-flex-box>

  <lv-flex-box direction="row">
    <div class="lv-new-issue-assumption-column lv-new-issue-assumption-first-column">
      <label
        class="lv-label"
        showAfter="1500"
        kendoTooltip
        filter="label"
        [tooltipTemplate]="stockRefTooltip">
        Fixed Reference
      </label>
    </div>
    <ng-container *ngIf="!view.niaView.model.usedStockRef">
      <div
        class="lv-new-issue-assumption-column lv-new-issue-assumption-column-stock-ref"
        data-cy="DM-360">

        <lv-numeric-text-box
          name="stockRef"
          [placeholder]="view.niaView.placeHolderModel.stockRefCBCcy"
          [lvId]="view.niaView.getInputFieldLvId('stockRef' + 'Assumed', 'field')"
          [(ngModel)]="view.niaView.model.stockRefCBCcy"
          (didKeyDownEnter)="onNewIssueAssumptionStockRefChange()"
          (didBlur)="onNewIssueAssumptionStockRefChange()">
        </lv-numeric-text-box>
      </div>

      <div *ngIf="isCrossFx"
        class="lv-new-issue-assumption-column  lv-new-issue-assumption-column-stock-ref-equity"
        data-cy="DM-4624">

        <lv-numeric-text-box
          name="stockRefEquity"
          [placeholder]="view.niaView.placeHolderModel.stockRef"
          [lvId]="view.niaView.getInputFieldLvId('stockRef-equity', 'field')"
          [(ngModel)]="view.niaView.model.stockRef"
          (didKeyDownEnter)="onNewIssueAssumptionStockRefEquityChange()"
          (didBlur)="onNewIssueAssumptionStockRefEquityChange()">
        </lv-numeric-text-box>
      </div>

      <div *ngIf="isCrossFx"
        class="lv-new-issue-assumption-column lv-new-issue-assumption-column-stock-ref-fx"
        data-cy="DM-4630">

        <lv-numeric-text-box
          name="stockRefFx"
          [placeholder]="view.niaView.placeHolderModel.fx"
          [lvId]="view.niaView.getInputFieldLvId('stockRef-fx', 'field')"
          [(ngModel)]="view.niaView.model.fx"
          (didKeyDownEnter)="onNewIssueAssumptionFxChange()"
          (didBlur)="onNewIssueAssumptionFxChange()">
        </lv-numeric-text-box>
      </div>
    </ng-container>

    <ng-container *ngIf="view.niaView.model.usedStockRef">
      <div class="lv-new-issue-assumption-column lv-new-issue-assumption-column-value lv-new-issue-assumption-column-stock-ref">
        <label
          class="lv-label lv-label-white"
          [lvId]="'lv-pricing-nia-stock-ref'">
          {{ view.niaView.model.stockRefCBCcy | number:constants.numberFormat.fourDigits }}
        </label>
      </div>

      <div *ngIf="isCrossFx"
        class="lv-new-issue-assumption-column lv-new-issue-assumption-column-value lv-new-issue-assumption-column-stock-ref-equity">
        <label
          class="lv-label lv-label-white"
          [lvId]="'lv-pricing-nia-stock-ref-equity'">
          {{ view.niaView.model.stockRef | number:constants.numberFormat.fourDigits }}
        </label>
      </div>

      <div  *ngIf="isCrossFx"
        class="lv-new-issue-assumption-column lv-new-issue-assumption-column-value lv-new-issue-assumption-column-stock-ref-fx">
        <label
          class="lv-label lv-label-white"
          [lvId]="'lv-pricing-nia-fx'">
          {{ view.niaView.model.fx | number:constants.numberFormat.fourDigits }}
        </label>
      </div>
    </ng-container>

  </lv-flex-box>
</ng-template>

