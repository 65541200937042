import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { v4 } from 'uuid';
import { LvBondTermsPresenter } from '../lv-bond-terms.presenter';
import { DayCountDescription, FrequencyDescription, NoticePeriod, PutScheduleItem, PutValueType, PutValueTypeDescription } from '@lv-instrument-common/index';
import { BondTermsDocument } from '../../../models/bond-terms/BondTermsDocument';
import { BondTermsSectionEvent } from '../../../models/bond-terms/enums/bond-terms-section-events';
import { LvLookupEnum } from '@lv-core-ui/util';
import { LvBondPutsScheduleComponent } from './lv-bond-puts-schedule/lv-bond-puts-schedule.component';
import { Subscription } from 'rxjs';
import { BondPut } from '../../../models/bond-terms/BondPut';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lv-bond-puts',
  templateUrl: './lv-bond-puts.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvBondPutsComponent implements OnInit {

  @ViewChild('putSchedule') putSchedule: LvBondPutsScheduleComponent;

  get isPuttableDuringPeriodDisabled(): boolean {
    return this.model.partialPut
  }

  get isPartialPutDisabled(): boolean {
    return this.model.puttableDuringPeriod;
  }

  /**
  * If checkbox is checked, getter returns true.
  */
  get isPartialPutSelected(): boolean {
    return this.model.partialPut;
  }

  @Input() model: BondPut;
  @Input() isPuttable: boolean;

  private _modelSubscription: Subscription;

  puttableId: string;
  keepAccruedCheckBoxId: string;
  forfeitCouponCheckBoxId: string;
  puttableDuringPeriodCheckBoxId: string;
  partialPutCheckBoxId: string;
  putValueTypeLookup: LvLookupEnum;
  putYieldFrequencyLookup: LvLookupEnum;
  yieldDayCountLookup: LvLookupEnum;
  noticePeriodLookup: LvLookupEnum;

  areYieldFieldsVisible: boolean;
  isKeepAccruedAndForfeitCouponVisible: boolean;

  numberOfDecimals = '0';
  numberFormat = 'n0';

  fourDecimals = '4';
  fourDecimalNumberFormat = '#.####';
  fourDecimalNegativeLabelFormat = '1.0-4';

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,
    private _destroyRef: DestroyRef
  ) {
    this.putValueTypeLookup = new LvLookupEnum(PutValueTypeDescription);
    this.putYieldFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.yieldDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.noticePeriodLookup = new LvLookupEnum(NoticePeriod);
    this.puttableId = v4();
    this.keepAccruedCheckBoxId = v4();
    this.forfeitCouponCheckBoxId = v4();
    this.puttableDuringPeriodCheckBoxId = v4();
    this.partialPutCheckBoxId = v4();
    this.areYieldFieldsVisible = false;
    this.isKeepAccruedAndForfeitCouponVisible = true;
  }

  ngOnInit(): void {

    this._modelSubscription = this._presenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(event => {
      if (event === BondTermsSectionEvent.ModelLoadedEvent) {
        this.model = this._presenter.getModel().put;
        this.setFieldsVisibility();
        this._changeDetectorRef.detectChanges();
      }
    });
    this.setFieldsVisibility();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Fired when put schedule changed
   * @param schedule
   */
  onSchedulePutChange(schedule: PutScheduleItem[]) {
    this.model.scheduleItems = schedule;
    this.onModelChange();
  }

  /**
   * Fired when put model changed
   */
  onModelChange() {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.put = this.model;
    }, BondTermsSectionEvent.TermsPutEvent,
      'other');
  }

  /**
   * Fired when put checkbox model changed
   */
  onPutChanged() {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.put = this.model;
      terms.general.isPuttable = this.isPuttable;
    }, BondTermsSectionEvent.PutIssueAndRedemptionEvent,
      'other');

    this.setFieldsVisibility();
    this.onModelChange();
  }

  /**
   * Fired when put value type changed
   */
  onPutValueTypeChanged() {
    if (!!this.putSchedule) {
      this.putSchedule.resetPutPriceSchedule(this.model.valueType);
    }

    this.setFieldsVisibility();
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.put = this.model;
    }, BondTermsSectionEvent.TermsPutEvent,
      'other');
  }

  getPutParametersTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  /**
 * Method updates TotalTranchePct field every time Tranche % column sum changes
 * @param trancheSum Sum of all values in Tranche % column from put schedule.
 */
  onTotalTranchePctSumChanged(trancheSum: number) {
    this.model = { ...this.model, totalTranchePct: trancheSum };
    this._changeDetectorRef.detectChanges();
  }

  /**
 * Method for Apply schedule changes on Save
 */
  applyScheduleChanges() {
    if (this.model && this.putSchedule) {
      this.putSchedule.applyAdvancedGridChanges();
    }
  }

  /**
 * Set field visibility based on put type
 */
  private setFieldsVisibility() {
    this.areYieldFieldsVisible = this.model?.valueType === PutValueType.GrossYield || this.model?.valueType === PutValueType.NetYield;
    //this.isKeepAccruedAndForfeitCouponVisible = this.couponType !== CouponType.ZeroCoupon;
  }
}
