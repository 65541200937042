<div class="lv-label lv-label-field lv-label--title">
  Call Adjustments
</div>
<lv-flex-box
  direction="row"
  data-cy="DM-1450">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="callModelTypeSettingsTooltipTemplate"
    filter="span">
    <span> Call Model Type </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="callAdjustmentHardCallModelType"
      valueField="id"
      textField="text"
      [data]="hardCallModelType.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.hardCallModelType"
      [disabled]="!overrideSystemDefaults"
      [popupSettings]="{ width: 160 }">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1451">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="callAdjustFactorSettingsTooltipTemplate"
    filter="span">
    <span> Call Adjust Factor </span>
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="callAdjustmentHardCallAdjustFactor"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.hardCallAdjustmentFactor"
      [disabled]="!overrideSystemDefaults || !isBarierModel">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>


  <ng-template #callModelTypeSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1450"></lv-tooltip>
  </ng-template>
  <ng-template #callAdjustFactorSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1451"></lv-tooltip>
  </ng-template>