<lv-flex-box class="lv-asw-terms">
  <lv-flex-box
    direction="row"
    data-cy="DM-772">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="instrumentTypeTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span>
        Instrument Type
      </span>
      <lv-xl-label [field]="'INST_TYPE'"></lv-xl-label>
    </div>
    <div class="lv-asw-terms-instrument-type">
      Asset Swap
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-334">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="instrumentNameTooltipTemplate"
      filter="span">
      <span>
        Instrument Name
      </span>
    </div>
    <div class="lv-input-field lv-input-field--full lv-input-field-instrument">
      <lv-text-box
        name="asw-terms-instrument-name"
        [(ngModel)]="aswTerms.name"
        (didBlur)="updateAswTerms()"
        (didKeyDownEnter)="updateAswTerms()"
        >
      </lv-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-681">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="instrumentShortNameTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'instrumentNameTooltip'">
        Instrument Short Name
      </span>
    </div>
    <div class="lv-input-field lv-input-field--full lv-input-field-instrument">
      <lv-text-box
        name="asw-instrument-short-name"
        [(ngModel)]="aswTerms.shortName"
        (didBlur)="updateAswTerms()"
        (didKeyDownEnter)="updateAswTerms()">
      </lv-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-1670">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="underlyingNameTooltipTemplate"
      filter="span">
      <span>
        Underlying Name
      </span>
    </div>
    <div class="lv-input-field lv-input-field--full lv-input-field-instrument asw-underlying-name">
      <lv-text-box
        name="asw-underlying-name"
        [searchIconVisible]="true"
        [(ngModel)]="aswTerms.underlying"
        (didBlur)="updateAswTerms()"
        (didKeyDownEnter)="updateAswTerms()">
      </lv-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box
    data-cy="DM-4829"
    direction="row"
    class="lv-asw-type">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="assetSwapTypeTooltip"
      filter="span">
      <span> Asset Swap Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        [data]="aswTypeLookup.items"
        name="asw-type"
        valueField="id"
        textField="text"
        valuePrimitive="true"
        [(ngModel)]="aswTerms.aswType"
        (ngModelChange)="updateAswTerms()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="4835">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="effectiveDateSourceTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span>
        Effective Date
      </span>
      <lv-xl-label data-cy="EFF_DT" [field]="'EFF_DT'"></lv-xl-label>
    </div>
    <div class="lv-input-field date-field">
      <kendo-datepicker
        placeholder=""
        name="asw-effective-date"
        [(ngModel)]="aswTerms.effectiveDate"
        (blur)="updateAswTerms()">
      </kendo-datepicker>
    </div>
  </lv-flex-box>
  
  <lv-flex-box
    direction="row"
    data-cy="4836">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="maturityDateSourceTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span>
        Maturity Date
      </span>
      <lv-xl-label data-cy="MAT" [field]="'MAT'"></lv-xl-label>
    </div>
    <div class="lv-input-field date-field">
      <kendo-datepicker
        placeholder=""
        name="asw-maturity-date"
        [(ngModel)]="aswTerms.maturityDate"
        (blur)="updateAswTerms()">
      </kendo-datepicker>
    </div>
  </lv-flex-box>

  <lv-flex-box
    data-cy="DM-4908"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="nominalValueSourceTooltipTemplate"
      filter="span">
      <span> Nominal Value </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="nominal-value-general"
        [(ngModel)]="aswTerms.nominalValue"
        (didBlur)="updateAswTerms()"
        (didKeyDownEnter)="updateAswTerms()">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box
    data-cy="DM-4830"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="payLegTypeTooltipTemplate"
      filter="span">
      <span> Pay Leg Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        [data]="payLegTypeLookup.items"
        class="lv-asw-type"
        name="peg-leg-type"
        valueField="text"
        textField="text"
        valuePrimitive="true"
        [(ngModel)]="aswTerms.payLegType"
        (ngModelChange)="updateAswTerms()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box
    *ngIf="isAswTypeConvertible"
    data-cy="DM-4831"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="strikeTypeTooltipTemplate"
      filter="span">
      <span> Strike Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        class="lv-asw-type"
        name="strike-type"
        valueField="text"
        textField="text"
        valuePrimitive="true"
        [data]="strikeTypeLookup.items"
        [popupSettings]="{ width: 160 }"
        [(ngModel)]="aswTerms.strikeType"
        (ngModelChange)="updateAswTerms()">
      </kendo-dropdownlist>
    </div>
</lv-flex-box>

<lv-flex-box
  *ngIf="isAswTypeConvertible && isStrikeTypeFixed"
  data-cy="DM-4932"
  direction="row"
  class="lv-asw-strike-bond-redemption">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="strikeBondRedemptionSourceTooltipTemplate"
    filter="span">
    <span> Strike / Bond Redemption </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      name="strike-bond-value-general"
      [format]="formatTwo"
      [decimals]="decimalsTwo"
      [(ngModel)]="aswTerms.strikeTypeRedemption"
      (didBlur)="updateAswTerms()"
      (didKeyDownEnter)="updateAswTerms()">
    </lv-numeric-text-box>
    <span>% of Par</span>
  </div>
</lv-flex-box>

  <lv-flex-box
    *ngIf="isPayLegTypeFixed"
    direction="row"
    data-cy="4833">
    <div
      class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="strikeRefDateSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Strike Reference Date
      </span>
      <lv-xl-label data-cy="STRIKE_REF_DT" [field]="'STRIKE_REF_DT'"></lv-xl-label>
    </div>
    <div class="lv-input-field date-field">
      <kendo-datepicker
        placeholder=""
        name="asw-maturity-date"
        [(ngModel)]="aswTerms.strikeReferenceDate"
        (blur)="updateAswTerms()">
      </kendo-datepicker>
    </div>
  </lv-flex-box>

  <lv-flex-box
    data-cy="DM-4934"
    direction="row"
    class="lv-asw-recall-spread">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="recallSpreadSourceTooltipTemplate"
      filter="span">
      <span> Recall Spread </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="recall-spread"
        [format]="formatTwo"
        [decimals]="decimalsTwo"
        [(ngModel)]="aswTerms.recallSpread"
        (didBlur)="updateAswTerms()"
        (didKeyDownEnter)="updateAswTerms()">
      </lv-numeric-text-box>
    </div>
    <div class="lv-suffix">
      <span>bps</span>
    </div>
  </lv-flex-box>
  <!-- to do: CCY should be pulled from underlying CB CCY -->
  <lv-flex-box
    direction="row"
    data-cy="DM-379">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="CCYSourceTooltipTemplate"
      filter="span">
      <span>
        CCY
      </span>
    </div>
    <div class="lv-asw-terms-instrument-type">
      USD
    </div>
  </lv-flex-box>

  <lv-flex-box
    class="lv-asw-fieldset"
    direction="row">
    <lv-fieldset-panel
      legend="CONVERSION"
      [class.lv-asw-conversion-additional-width]="isEarlyExerciseFeeApplicable">
        <lv-asw-conversion
          [model]="aswTerms.conversion"
          (didAwsConversionChange)="updateAswTerms()">
        </lv-asw-conversion>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box
    class="lv-asw-fieldset lv-asw-fieldset-reference-ir-swap"
    direction="row">
    <lv-fieldset-panel
      legend="REFERENCE IR SWAP"
      [class.lv-reference-ir-swap-additional-width]="!isAswTypeConvertible">
        <lv-asw-reference-ir-swap
          [callableASW]="aswTerms.aswType"
          [model]="aswTerms.referenceIrSwap"
          (didAwsReferenceIRChange)="updateAswTerms()">
        </lv-asw-reference-ir-swap>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box
    *ngIf="!isPayLegTypeFixed"
    class="lv-asw-fieldset lv-asw-fieldset-floating-schedule"
    direction="row">
    <lv-fieldset-panel
      legend="FLOATING LEG SCHEDULE"
      kendoTooltip
      showAfter="1500"
      [tooltipTemplate]="floatingLegScheduleTooltip"
      filter=".lv-fieldset-panel-legend">
      <lv-asw-floating-leg-schedule
        [model]="aswTerms.floatingLegSchedule"
        (didFloatingLegScheduleChange)="updateAswTerms()">
      </lv-asw-floating-leg-schedule>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box
    direction="row"
    class="lv-asw-terms-save-reload-controls">
    <div
      kendoTooltip 
      data-cy="">
      <div
        class="lv-icon-square"
        title="Save"
        lvId="aswTermsSaveAll"
        (click)="onSave()">
        <i class="k-icon k-i-save"></i>
      </div>
      <div
        class="lv-icon-square"
        title="Reload"
        lvId="aswTermsReloadAll"
        (click)="onReload()">
        <i class="k-icon k-i-reload"></i>
      </div>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #instrumentTypeTooltipTemplate>
  <lv-tooltip dm="dM-772"></lv-tooltip>
</ng-template>
<ng-template #instrumentNameTooltipTemplate>
  <lv-tooltip dm="dM-334"></lv-tooltip>
</ng-template>
<ng-template #instrumentShortNameTooltipTemplate>
  <lv-tooltip dm="dM-681"></lv-tooltip>
</ng-template>
<ng-template #underlyingNameTooltipTemplate>
  <lv-tooltip dm="dM-1670"></lv-tooltip>
</ng-template>
<ng-template #assetSwapTypeTooltip>
  <lv-tooltip dm="dM-4829"></lv-tooltip>
</ng-template>
<ng-template #maturityDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-4836"></lv-tooltip>
</ng-template>
<ng-template #effectiveDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-4835"></lv-tooltip>
</ng-template>
<ng-template #nominalValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-4908"></lv-tooltip>
</ng-template>
<ng-template #payLegTypeTooltipTemplate>
  <lv-tooltip dm="dM-4830"></lv-tooltip>
</ng-template>
<ng-template #strikeTypeTooltipTemplate>
  <lv-tooltip dm="dM-4831"></lv-tooltip>
</ng-template>
<ng-template #strikeBondRedemptionSourceTooltipTemplate>
  <lv-tooltip dm="dM-4832"></lv-tooltip>
</ng-template>
<ng-template #strikeRefDateSourceTooltip>
  <lv-tooltip dm="dM-4833"></lv-tooltip>
</ng-template>
<ng-template #recallSpreadSourceTooltipTemplate>
  <lv-tooltip dm="dM-4834"></lv-tooltip>
</ng-template>
<ng-template #CCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-379"></lv-tooltip>
</ng-template>
<ng-template #floatingLegScheduleTooltip>
  <lv-tooltip dm="dM-4921"></lv-tooltip>
</ng-template>