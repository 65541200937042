<lv-flex-box class="lv-withholding-tax">
  <lv-environment-settings
    [section]="withholdingTaxSection"
    lvId="marketDataWitholdingTaxEnvironmentSettings"
    instrumentType="Bond"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-withholding-tax-content lv-market-data-section-content">
    <lv-flex-box data-cy="DM-322" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="witholdingTaxCouponNetFactorTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.withholding-tax-tooltip-id]="'instrumentTooltip'">
              Coupon Net Factor &nbsp;({{ cbCcy }})
          </span>
          <lv-xl-label [field]="'NF_COUPON'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="netFactorInstrument"
          [format]="numberFormatPercentage"
          [decimals]="numberOfDecimalsPercentage"
          [disabled]="isWitholdingTaxSectionDisabled"
          [(ngModel)]="withholdingTaxSettings.netFactorInstrumentCCY"
          (didKeyDownEnter)="overrideWithholdingTax()"
          (didBlur)="overrideWithholdingTax()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #witholdingTaxCouponNetFactorTooltipTemplate>
  <lv-tooltip dm="dM-322"></lv-tooltip>
</ng-template>