

import { CallTriggerType } from './enums/CallTriggerType';
import { CurrencyType } from './enums/CurrencyType';
import { CallTriggerPeriodType } from './enums/CallEnums';

export class SoftCallData {
    triggerType: CallTriggerType;
    currencyOfTrigger: CurrencyType;
    triggerPeriodType: CallTriggerPeriodType;
    triggerPeriodDays: number;
    outOfDays: number;
    hasTriggerPeriodActive: boolean;
    currentStockAverage: number;
    daysAboveTrigger: number;
    runningPeriodDays: number;
    callTriggerBasedOnFullExchgProperty: boolean;
}

