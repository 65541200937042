<lv-loader *ngIf="isLoading"></lv-loader>
<lv-flex-box class="lv-dividends">
  <lv-environment-settings
    [section]="dividendsSection"
    instrumentType="Equity"
    [showControls]="true"
    (didChangeEnvironment)="onChangedEnvironment($event)"
    (doSave)="onSaveSection()"
    (doReload)="onReloadSection()">
  </lv-environment-settings>
    <lv-flex-box
      data-cy="DM-314"
      direction="row"
      class="lv-equity-dividends-dividend-ccy">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendCCYTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.dividend-tooltip-id]="'dividendCCYTooltip'">
          Dividend CCY
        </span>
        <lv-xl-label [field]="'DVD_CCY'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          name="dividendCurrency"
          [data]="dividendCurrencySource.items"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [(ngModel)]="dividends.dividendsCcy"
          (ngModelChange)="onDividendCCYChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
  <kendo-tabstrip class="lv-equity-market-data-tabstrip"
    [keepTabContent]="true"
    #tabstrip>
      <kendo-tabstrip-tab
        title="Parameters"
        cssClass="DM-4839"
        [selected]="true">
        <ng-template kendoTabContent>
        <lv-equity-dividends-parameters 
          [dividendsSource]="dividends"
          (didSessionUpdatedEvent)="updateMarketDataDividendsModel()">
        </lv-equity-dividends-parameters>
        </ng-template>
      </kendo-tabstrip-tab>

  <kendo-tabstrip-tab 
    title="Schedule"
    cssClass="DM-4840">
    <ng-template kendoTabContent>
      <lv-equity-dividends-schedule
      [scheduleDividends]="dividends.dividendsSchedule.dividendSchedule"
      (didSessionUpdatedEvent)="updateMarketDataDividendsModel()">
      </lv-equity-dividends-schedule>
    </ng-template>
  </kendo-tabstrip-tab>

  </kendo-tabstrip>
</lv-flex-box>

<ng-template #dividendCCYTooltipTemplate>
  <lv-tooltip dm="dM-314"></lv-tooltip>
</ng-template>