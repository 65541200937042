<div class="lv-edit-environment-settings-dialog-content">
  <lv-flex-box direction="row">
    <div class="lv-label lv-label-field lv-label-field-description">
      Environment Name
    </div>
    <div *ngIf="isSystem" class="lv-label lv-label-field">
      {{pricingEnvironmentSettings.name}}
    </div>
    <lv-text-box *ngIf="!isSystem" 
      class="lv-input-field lv-input-field--full"
      lvId="lv-pricing-environment-name"
      [(ngModel)]="pricingEnvironmentSettings.name">
    </lv-text-box>
  </lv-flex-box>
  <lv-flex-box direction="row">
    <div class="lv-label lv-label-field lv-label-field-description">
      Note
    </div>
    <lv-text-box class="lv-input-field lv-input-field--full"
      lvId="lv-pricing-environment-name"
      [(ngModel)]="pricingEnvironmentSettings.note">
    </lv-text-box>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-1147">
    <div class="lv-label lv-label-field lv-label-field-description">
      Enabled
    </div>
    <input name="edit-environment-is-enabled-checkbox-name"
      class="k-checkbox"
      type="checkbox"
      (ngModelChange)="changePricingEnvironmentAccessibility()"
      attr.id="edit-environment-is-enabled-checkbox-id"
      [(ngModel)]="isEnvironmentEnabled"/>
    <label class="k-checkbox-label"
      lvId="edit-environment-is-enabled-checkbox-lv-id"
      attr.for="edit-environment-is-enabled-checkbox-id">
    </label>
  </lv-flex-box>
  <lv-flex-box direction="row">
    <div class="lv-label lv-label-field lv-label-field-description">
      Type
    </div>
    <div class="lv-label lv-label-field">
      {{pricingEnvironmentSettings.scopeType}}
    </div>
  </lv-flex-box>
</div>

<lv-flex-box 
  class="lv-environment-settings-update-control-panel"
  direction="row"
  data-cy="DM-2061"
  >
  <button kendoButton
    [primary]="true"
    lvId="cancel-market-data-settings"
    (click)="onCancel()">
    Cancel
  </button>
  <button kendoButton
    data-cy="DM-2061"
    [primary]="true"
    lvId="save-environment-setup-data-settings"
    (click)="onSave()">
    Save
  </button>
</lv-flex-box>