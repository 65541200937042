import { ISaveBondCreditRequest } from "./credit/save-bond-credit-request";
import { ISaveBondInterestRatesDataRequest } from "./interest-rates/save-bond-interest-rates";
import { ISaveBondWithholdingTaxRequest } from "./withholding-tax/save-bond-withholding-tax-request";
import { LvBondCreditComponent } from "@lv-analytics/components/market-data/bond/lv-bond-market-data/lv-bond-credit/lv-bond-credit.component";
import { LvBondInterestRatesComponent } from "@lv-analytics/components/market-data/bond/lv-bond-market-data/lv-bond-interest-rates/lv-bond-interest-rates.component";
import { LvBondWithholdingTaxComponent } from "@lv-analytics/components/market-data/bond/lv-bond-market-data/lv-bond-withholding-tax/lv-bond-withholding-tax.component";
import { IMarketDataComponent } from "@lv-analytics/components/market-data/market-data-component";
import { LvAnalyticsError } from "@lv-analytics/models/errors";
import { IDataEntitlement } from "@lv-shared/interfaces/data-entitlement";

export class SaveBondMarketDataRequest  implements IDataEntitlement {
  credit: ISaveBondCreditRequest;
  interestRates: ISaveBondInterestRatesDataRequest;
  witholdingTax: ISaveBondWithholdingTaxRequest;
  sourceId: string;
  sessionId: string;
  leversysLocalId: string;

  constructor() {

  }

  /**
   * Applies market data section.
   * @param marketDataComponent IMarketDataComponent objects.
   */
  applyMarketDataSection(marketDataComponent: IMarketDataComponent<any>) {

    const requestSettings = marketDataComponent.getSettings();

    if (marketDataComponent instanceof LvBondCreditComponent) {
      this.credit = requestSettings;
    } else if (marketDataComponent instanceof LvBondInterestRatesComponent) {
      this.interestRates = requestSettings;
    } else if (marketDataComponent instanceof LvBondWithholdingTaxComponent) {
      this.witholdingTax = requestSettings; 
    }  else {
      throw new LvAnalyticsError('Unknown Market Data component');
    }
  }

  /**
   * Applies current changes.
   * @param marketDataComponent IMarketDataComponent object.
   */
  applyCurrentChanges(marketDataComponent: IMarketDataComponent<any>) {
    marketDataComponent.applyCurrentChanges();
  }
}