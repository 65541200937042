import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy,
  ViewChild, ElementRef, OnDestroy, Output, EventEmitter, 
  DestroyRef } from '@angular/core';

import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { IMarketDataComponent } from '../../market-data-component';
import { LvBorrowTermStructureComponent } from './lv-borrow-terms-structure/lv-borrow-term-structure.component';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum } from '@lv-core-ui/util';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { IBorrowSaveRequest } from '@lv-analytics/models/market-data/borrow/borrow-request';
import { BorrowSource, BorrowSourceDescription } from '@lv-analytics/models/market-data/borrow/borrow-enum';
import { IEnvironmentSettingsItem,
         LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { IBorrow } from '@lv-analytics/models/market-data/borrow/borrow';
import { MarketDataService } from '@lv-analytics/services';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { IEquityBorrow } from '@lv-analytics/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Borrow component.
 */
@Component({
  selector: 'lv-borrow',
  templateUrl: './lv-borrow.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBorrowComponent implements OnInit, OnDestroy, IMarketDataComponent<IBorrowSaveRequest> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild(LvBorrowTermStructureComponent) borrowTermStructureComponent: LvBorrowTermStructureComponent;

  get isBorrowSectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  borrowSettings: IBorrow;
  originalValue: IBorrow;

  underlyingEquityBorrow: IEquityBorrow;
  originalValueUnderlyingEquityBorrow : IEquityBorrow;

  borrowSource: LvLookupEnum;

  isFlatSelected: boolean;
  isRebateSelected: boolean;
  isTermStructureSelected: boolean;
  isUnderlyingEquityDataFlatSelected: boolean;
  isUnderlyingEquityDataRebateSelected: boolean;
  isUnderlyingEquityDataTermsStructureSelected: boolean;
  popupSettings: PopupSettings;
  isEditable: boolean;

  borrowSection = PricingEnvironmentSections.Borrow;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private errorService: LvErrorService,
    private marketDataService: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _destroyRef: DestroyRef,
  ) {
    this.isFlatSelected = true;
    this.isRebateSelected = false;
    this.isTermStructureSelected = false;
    this.isUnderlyingEquityDataFlatSelected = false;
    this.isUnderlyingEquityDataRebateSelected = false;
    this.isUnderlyingEquityDataTermsStructureSelected = false;

    this.borrowSource = new LvLookupEnum(BorrowSourceDescription);

    this.borrowSource.setFilterFn(item => {
      if (!this._analyticsPresenter.asHelper.hasUnderlying() && item.id.startsWith('Underlying')) {
        return false;
      }

      return true;
    });

    this.borrowSettings = {
      borrowSource: BorrowSource.Flat
    } as IBorrow;

    this.underlyingEquityBorrow = {
      borrow: null,
      rebateRate: null,
      fundingRate: null,
      termStructureItems: []
    }as IEquityBorrow;

    this.didSessionUpdatedEvent = new EventEmitter<void>();

    this.popupSettings = {
      width: 251
    };
    this.isEditable = false;
  } 

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt && evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
          this.borrowSettings = evt.data.marketData.borrow;
          this.originalValue = _.cloneDeep(this.borrowSettings);
          this.setBorrowSourceFlags();
          this.setBorrowTermStructureIfNotExists();
          this.borrowTypeEquityFallbackLogic();
          this._changeDetectorRef.detectChanges();
          
          if(this._analyticsPresenter?.equitySession?.marketData){
            this.initUnderyiungEquityBorrow();
          }
        }
      })
    ];

    this._analyticsPresenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(async() => {
      if(this._analyticsPresenter?.equitySession?.marketData){
        this.underlyingEquityBorrow = this._analyticsPresenter?.equitySession?.marketData?.borrow;  
        this.originalValueUnderlyingEquityBorrow = _.cloneDeep(this.underlyingEquityBorrow);
        this.setBorrowSourceFlags();
        this._changeDetectorRef.detectChanges();
      }
    });

    if(this._analyticsPresenter?.equitySession?.marketData){
      this.initUnderyiungEquityBorrow();
    }
  }

  private initUnderyiungEquityBorrow() {
    this.underlyingEquityBorrow = this._analyticsPresenter?.equitySession?.marketData?.borrow;
    this.originalValueUnderlyingEquityBorrow = _.cloneDeep(this.underlyingEquityBorrow);
    this.setBorrowSourceFlags();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on borrow source change.
   */
  onBorrowSourceChange() {
    this.setBorrowSourceFlags();
    this.overrideBorrow();
  }

  /**
   * Occurs on borrow section change.
   */
  onBorrowSectionChanged() {
    this.overrideBorrow();
  }

  /**
   * Occurs on environment change.
   * @param environment IEnvironmentSettingsItem object..
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadBorrow(environment);
  }

  /**
   * Gets selected environment ID.
   * @returns Environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns IBorrowSaveRequest object.
   */
  getSettings(): IBorrowSaveRequest {
    return this.getBorrowSaveRequest();
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {
    if (this.borrowSettings.borrowSource === BorrowSource.TermStructure) {
      this.borrowTermStructureComponent.applyAdvancedGridChanges();
    }
  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections object.
   */
  getSection(): PricingEnvironmentSections {
    return this.borrowSection;
  }

  /**
   * Gets borrow tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Borrow tooltip ID.
   */
  getBorrowTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('borrow-tooltip-id') === sectionId;
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Overrides borrow.
   */
  private async overrideBorrow() {
    try {
      const underlyingSession = await this.marketDataService.overrideBorrow({
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        borrow: this.borrowSettings
      });

      if(underlyingSession){
        this.underlyingEquityBorrow =underlyingSession.marketData.borrow;        
      }

      this._analyticsPresenter.updateBorrow(this.borrowSettings, underlyingSession);         

      if (!_.isEqual(this.borrowSettings, this.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.originalValue = _.cloneDeep(this.borrowSettings);
      }
    }
    catch (e) {
      this.errorService.handleError(e);
    }
  }

  /**
   * Loads borrow.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadBorrow(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      const loadResponse = await this.marketDataService.loadBorrow(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id
      );

      this.borrowSettings = loadResponse.borrow;

      this._analyticsPresenter.updateBorrow(loadResponse.borrow, loadResponse.underlyingValuationSession);     

      if(loadResponse.underlyingValuationSession){
        this.initUnderyiungEquityBorrow();      
      }    

      this.setBorrowTermStructureIfNotExists();  

      this.originalValue = _.cloneDeep(this.borrowSettings);
      this.setBorrowSourceFlags();
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Gets borrow save request.
   * @returns IBorrowSaveRequest object.
   */
  private getBorrowSaveRequest(): IBorrowSaveRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      environmentId: env.id,
      borrow: this.borrowSettings
    } as IBorrowSaveRequest;
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Sets borrow source flags.
   */
  private setBorrowSourceFlags() {
    this.isFlatSelected = false;
    this.isRebateSelected = false;
    this.isTermStructureSelected = false;
    this.isUnderlyingEquityDataFlatSelected = false;
    this.isUnderlyingEquityDataRebateSelected = false;
    this.isUnderlyingEquityDataTermsStructureSelected = false;

    switch (this.borrowSettings.borrowSource) {
      case BorrowSource.Flat:
        this.isFlatSelected = true;
        break;
      case BorrowSource.Rebate:
        this.isRebateSelected = true;
        break;
      case BorrowSource.TermStructure:
        this.isTermStructureSelected = true;
        break;
      case BorrowSource.UnderlyingEquityDataFlat:
        this.isUnderlyingEquityDataFlatSelected = true;
        break;
      case BorrowSource.UnderlyingEquityDataRebate:
        this.isUnderlyingEquityDataRebateSelected = true;
        break;
      case BorrowSource.UnderlyingEquityDataTermStructure:
        this.isUnderlyingEquityDataTermsStructureSelected = true;
        break;
      default:
        break;
    }
  }

  /**
   * Sets borrow term structure if not exists.
   */
  private setBorrowTermStructureIfNotExists() {
    if (!this.borrowSettings.termStructureItems) {
      this.borrowSettings.termStructureItems = [];
    }
  }

  /**
   * Set borrow source based on underlying equity linked.
   */
  private borrowTypeEquityFallbackLogic() {
    if (!this._analyticsPresenter.asHelper.hasUnderlying() && this.borrowSettings.borrowSource.toLowerCase().startsWith('underlying')) {

      switch (this.borrowSettings.borrowSource) {
        case BorrowSource.UnderlyingEquityDataFlat: {
          this.borrowSettings.borrowSource = BorrowSource.Flat;
          break;
        }
        case BorrowSource.UnderlyingEquityDataRebate: {
          this.borrowSettings.borrowSource = BorrowSource.Rebate;
          break;
        }
        case BorrowSource.UnderlyingEquityDataTermStructure: {
          this.borrowSettings.borrowSource = BorrowSource.TermStructure;
          break;
        }
      }
    }
  }
}
