<lv-terms-section-settings
  [systemDefault]="systemDefaults"
  [myDefault]="myDefaults"
  showAfter="1500"
  kendoTooltip
  [tooltipTemplate]="generalOverrideSystemDefaultsSettingsTooltip"
  filter="div[data-tooltip-id]">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-asset-swap-terms-section-settings
    [settings]="systemDefaults">
    </lv-asset-swap-terms-section-settings>
  </ng-template>
  
  <ng-template myDefaultsSettings let-settings>
    <lv-asset-swap-terms-section-settings
      [settings]="myDefaults"
      [overrideDefaults]="myDefaults.overrideSystemDefaults">
    </lv-asset-swap-terms-section-settings>
  </ng-template>
</lv-terms-section-settings>

<ng-template #generalOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideTermsSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1507"></lv-tooltip>
  </ng-container>
</ng-template>
