<lv-loader *ngIf="isLoading"></lv-loader>

<lv-environment-settings
  [section]="borrowSection"
  instrumentType="Equity"
  [showControls]="true"
  (didChangeEnvironment)="onChangedEnvironment($event)"    
  (doSave)="onSaveSection()"
  (doReload)="onReloadSection()">
</lv-environment-settings>


<kendo-tabstrip class="lv-equity-market-data-tabstrip"
  [keepTabContent]="true"
  #tabstrip>
  <kendo-tabstrip-tab
    title="Flat"
    cssClass="DM-4826"
    [selected]="true">
    <ng-template kendoTabContent>
     <lv-equity-borrow-flat
      data-cy="DM-4826"
      [borrowSettings]="borrowSettings"
      (didSessionUpdatedEvent)="setSectionStateChanged()">
     </lv-equity-borrow-flat>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab 
    title="Rebate"
    cssClass="DM-4827">
    <ng-template kendoTabContent>
      <lv-equity-borrow-rebate
        data-cy="DM-4827"
        [borrowSettings]="borrowSettings"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-equity-borrow-rebate>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab 
    title="Term Structure"
    cssClass="DM-4828">
    <ng-template kendoTabContent>
      <lv-equity-borrow-term-structure
        [scheduleItems]="borrowSettings.termStructureItems"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-equity-borrow-term-structure>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
