export enum BondBasicTerms {
  shortName = 'Inst Short Name',
  issuerName = 'Issuer',
  currencyCode = 'CCY',
  firstSettlement = 'First Settlement',
  maturity = 'Maturity',
  nominal = 'Nominal',
  issueValue = 'Issue Price',
  redemptionValue = 'Redemption Price',
  sinkable = 'Sinkable',
  couponType = 'Coupon Type',
  coupon = 'Coupon',
  couponFrequency = 'Coupon Frequency',
  couponDayCount = 'Coupon Day Count',
  accrualMethod = 'Accrual Method',
  firstCouponPaymentDate = 'First Coupon',
  call = 'Callable',
  put = 'Puttable',
  leversysId = 'Leversys ID',
  isin = 'ISIN',
  cusip = 'CUSIP',
  figi = 'FIGI',
  permId = 'Perm ID',
  bondCode = 'Bond Code',
}
