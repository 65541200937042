import { Injectable, Optional } from "@angular/core";
import { IBondAccretionSectionSettings } from "../models/bond-terms-settings/bond-accreation-settings/bond-accreation-section-settings";
import { LvExcelService } from "@lv-excel/services";
import { IBondTermsSectionSettings } from "../models/bond-terms-settings";
import { IBondMakeWholeSectionSettings } from "../models/bond-terms-settings/bond-make-whole-settings/bond-make-whole-section-settings";
import { IBondCouponSectionSettings } from "../models/bond-terms-settings/bond-coupon-settings/bond-coupon-section-settings";
import { BondTermsDocument } from "../models";
import { LvDateUtil } from "@lv-core-ui/util";

@Injectable()
export class BondTermsService {

  get accretionSettings(): IBondAccretionSectionSettings {
    if (
      this._bondTermsSettings?.myDefaults.accretionSettings
        .overrideSystemDefaults
    ) {
      return this._bondTermsSettings.myDefaults.accretionSettings.settings;
    }

    return this._bondTermsSettings?.systemDefaults.accretionSettings.settings;
  }

  get makeWholeSettings(): IBondMakeWholeSectionSettings {
    if (
      this._bondTermsSettings?.myDefaults.makeWholeSettings
        .overrideSystemDefaults
    ) {
      return this._bondTermsSettings.myDefaults.makeWholeSettings.settings;
    }

    return this._bondTermsSettings?.systemDefaults.makeWholeSettings.settings;
  }

  get couponSettings(): IBondCouponSectionSettings {
    if (this._bondTermsSettings?.myDefaults.couponSettings.overrideSystemDefaults) {
      return this._bondTermsSettings.myDefaults.couponSettings.settings;
    }

    return this._bondTermsSettings?.systemDefaults.couponSettings.settings;
  }

  private _bondTermsSettings: IBondTermsSectionSettings;

  constructor(@Optional() private _excelSvc: LvExcelService) {}

  /**
   * Set convertible bond terms data from settings
   * @param convertibleBondTermsDocumentFromSettings Convertible bond terms data
   */
  setBondTermsFromSettings(termsSettigns: IBondTermsSectionSettings): void {
    this._bondTermsSettings = termsSettigns;
  }

  mapBondToUi(model: BondTermsDocument ) {
    // Accretion
    if (model.accretion) {
      model.accretion.accretionEndDate = LvDateUtil.parse(model.accretion.accretionEndDate);
      model.accretion.accretionStartDate = LvDateUtil.parse(model.accretion.accretionStartDate);
    }

    // IsssueAndRedemption
    model.general.firstSettlementDate = LvDateUtil.parse(model.general.firstSettlementDate);
    model.general.maturityDate = LvDateUtil.parse(model.general.maturityDate);

    if (model.general.sinkingFundSchedule) {
      model.general.sinkingFundSchedule.forEach(a => a.sinkingDate = LvDateUtil.parse(a.sinkingDate));
    }

    // Coupon
    model.coupon.accrualEndDate = LvDateUtil.parse(model.coupon.accrualEndDate);
    model.coupon.typeChangeDate = LvDateUtil.parse(model.coupon.typeChangeDate);
    model.coupon.accrualStartDate = LvDateUtil.parse(model.coupon.accrualStartDate);
    model.coupon.guaranteedStartDate = LvDateUtil.parse(model.coupon.guaranteedStartDate);
    model.coupon.guaranteedStartEndDate = LvDateUtil.parse(model.coupon.guaranteedStartEndDate);
    model.coupon.firstCouponPaymentDate = LvDateUtil.parse(model.coupon.firstCouponPaymentDate);
    model.coupon.penultimateCouponPaymentDate = LvDateUtil.parse(model.coupon.penultimateCouponPaymentDate);

    if (model.coupon.fixed && model.coupon.fixed.stepUpCouponSchedule) {
      model.coupon.fixed.stepUpCouponSchedule.forEach(a => a.endDate = LvDateUtil.parse(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.floatingSpreadSchedule) {
      model.coupon.floating.floatingSpreadSchedule.forEach(a => a.endDate = LvDateUtil.parse(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.fixingHistoryItems) {
      model.coupon.floating.fixingHistoryItems.forEach(a => a.date = LvDateUtil.parse(a.date));
    }

    if (model.coupon.floating && model.coupon.floating.floatingCustomDates) {
      model.coupon.floating.floatingCustomDates = model.coupon.floating.floatingCustomDates.map(a => LvDateUtil.parse(a));
    }

    if (model.coupon.custom && model.coupon.custom.schedule) {
      model.coupon.custom.schedule.forEach(a => a.date = LvDateUtil.parse(a.date));
    }

    if (model.coupon.pik) {
      model.coupon.pik.pikEndDate = LvDateUtil.parse(model.coupon.pik.pikEndDate);
      if (model.coupon.pik.pikCustomSchedule) {
        model.coupon.pik.pikCustomSchedule.forEach(a => a.date = LvDateUtil.parse(a.date));
      }
    }

    // Call
    if (model.call) {
      model.call.callDate = LvDateUtil.parse(model.call.callDate);
      model.call.callFirstCallConsiderationDate = LvDateUtil.parse(model.call.callFirstCallConsiderationDate);
      if (model.call.scheduleItems) {
        model.call.scheduleItems.forEach(a => {
          a.startDate = LvDateUtil.parse(a.startDate);
          a.endDate = LvDateUtil.parse(a.endDate);
        });
      }

      if (model.call.callMakeWhole) {
        model.call.callMakeWhole.endDate = LvDateUtil.parse(model.call.callMakeWhole.endDate);
        model.call.callMakeWhole.startDate = LvDateUtil.parse(model.call.callMakeWhole.startDate);
        model.call.callMakeWhole.couponsIncludedUntil = LvDateUtil.parse(model.call.callMakeWhole.couponsIncludedUntil);
      }
    }

    // Put
    if (model.put && model.put.scheduleItems) {
      model.put.scheduleItems.forEach( a => {
        a.startDate = LvDateUtil.parse(a.startDate);
        a.endDate = LvDateUtil.parse(a.endDate);
      });
    }
  }

  mapToApi(model: BondTermsDocument) {

    // Accretion
    if (model.accretion) {
      model.accretion.accretionStartDate = LvDateUtil.toUtcDate(model.accretion.accretionStartDate);
      model.accretion.accretionEndDate = LvDateUtil.toUtcDate(model.accretion.accretionEndDate);
      model.accretion.accretionStartDate = LvDateUtil.toUtcDate(model.accretion.accretionStartDate);
    }

    // IssueAndRedemption
    model.general.maturityDate = LvDateUtil.toUtcDate(model.general.maturityDate);
    model.general.firstSettlementDate = LvDateUtil.toUtcDate(model.general.firstSettlementDate);

    if (model.general.sinkingFundSchedule) {
      model.general.sinkingFundSchedule.forEach(a => a.sinkingDate = LvDateUtil.toUtcDate(a.sinkingDate));
    }

    // Coupon
    model.coupon.accrualEndDate = LvDateUtil.toUtcDate(model.coupon.accrualEndDate);
    model.coupon.typeChangeDate = LvDateUtil.toUtcDate(model.coupon.typeChangeDate);
    model.coupon.accrualStartDate = LvDateUtil.toUtcDate(model.coupon.accrualStartDate);
    model.coupon.guaranteedStartDate = LvDateUtil.toUtcDate(model.coupon.guaranteedStartDate);
    model.coupon.guaranteedStartEndDate = LvDateUtil.toUtcDate(model.coupon.guaranteedStartEndDate);
    model.coupon.firstCouponPaymentDate = LvDateUtil.toUtcDate(model.coupon.firstCouponPaymentDate);
    model.coupon.penultimateCouponPaymentDate = LvDateUtil.toUtcDate(model.coupon.penultimateCouponPaymentDate);

    if (model.coupon.fixed && model.coupon.fixed.stepUpCouponSchedule) {
      model.coupon.fixed.stepUpCouponSchedule.forEach(a => a.endDate = LvDateUtil.toUtcDate(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.floatingSpreadSchedule) {
      model.coupon.floating.floatingSpreadSchedule.forEach(a => a.endDate = LvDateUtil.toUtcDate(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.fixingHistoryItems) {
      model.coupon.floating.fixingHistoryItems.forEach(a => a.date = LvDateUtil.toUtcDate(a.date));
    }

    if (model.coupon.floating && model.coupon.floating.floatingCustomDates) {
      model.coupon.floating.floatingCustomDates = model.coupon.floating.floatingCustomDates.map(a => LvDateUtil.toUtcDate(a));
    }

    if (model.coupon.custom && model.coupon.custom.schedule) {
      model.coupon.custom.schedule.forEach(a => a.date = LvDateUtil.toUtcDate(a.date));
    }

    if (model.coupon.pik) {
      model.coupon.pik.pikEndDate = LvDateUtil.toUtcDate(model.coupon.pik.pikEndDate);
      if (model.coupon.pik.pikCustomSchedule) {
        model.coupon.pik.pikCustomSchedule.forEach(a => a.date = LvDateUtil.toUtcDate(a.date));
      }
    }

    // Call
    if (model.call) {
      model.call.callDate = LvDateUtil.toUtcDate(model.call.callDate);

      if (model.call.scheduleItems) {
        model.call.scheduleItems.forEach(a => {
          a.startDate = LvDateUtil.toUtcDate(a.startDate);
          a.endDate = LvDateUtil.toUtcDate(a.endDate);
        });
      }

      if (model.call.callMakeWhole) {
        model.call.callMakeWhole.endDate = LvDateUtil.toUtcDate(model.call.callMakeWhole.endDate);
        model.call.callMakeWhole.startDate = LvDateUtil.toUtcDate(model.call.callMakeWhole.startDate);
        model.call.callMakeWhole.couponsIncludedUntil = LvDateUtil.toUtcDate(model.call.callMakeWhole.couponsIncludedUntil);
      }
    } else {
      model.call = null;
    }

    // Put
    if (!!model.put) {
      if (model.put.scheduleItems) {
        model.put.scheduleItems.forEach( a => {
          a.startDate = LvDateUtil.toUtcDate(a.startDate);
          a.endDate = LvDateUtil.toUtcDate(a.endDate);
        });
      }
    } else {
      model.put = null;
    }
  }
}
