<lv-flex-box class="lv-convertible-bond-terms-terms-general">
  <lv-flex-box direction="row" data-cy="DM-1223">
    <lv-terms-general-issue-information
      [isLvsConvertible]="isLvsConvertible"
      [model]= "view.issueAndRedemption"
      [other]="view.other"
      [priceTalk]="view.priceTalk"
      [stockReference]="view.stockReference"
      [stockReferenceSignalStore]="stockReferenceSignalStore"
      (didSetGeneralInformation)="doSetGeneralInformation($event)"
      (didNominalChanged)="onNominalChanged($event)"
      (doEquityOpenAsTab)="onEquityOpenAsTab($event)">
    </lv-terms-general-issue-information>
  </lv-flex-box>
  <lv-flex-box class="lv-bottom-sections" direction="row">
    <lv-flex-box class="lv-notes-section">
      <lv-fieldset-panel
        legend="NOTES" data-cy="DM-1290">
        <div class="lv-notes-text-area">
          <div class="lv-input-field">
            <textarea kendoTextArea
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              class="k-textarea notes-text-area"
              placeholder="Notes"
              name="notesField"
              (blur)="onIssueAndRedemptionChange()"
              [(ngModel)]="view.issueAndRedemption.notes">
            </textarea>
          </div>
        </div>
      </lv-fieldset-panel>
    </lv-flex-box>
    <lv-flex-box
        data-cy="DM-1002"
        *ngIf="view.issueAndRedemption.sinkable"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="sinkingScheduleTooltipTemplate"
        filter="lv-fieldset-panel">
      <lv-fieldset-panel legend="SINKING SCHEDULE">
        <lv-flex-box  direction="row">
          <lv-terms-general-sinking-schedule
            [sinkingSchedule]="view.issueAndRedemption.sinkingFundSchedule"
            (didSetSchedule)="doSetSchedule($event)">
          </lv-terms-general-sinking-schedule>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
</lv-flex-box>
</lv-flex-box>

<ng-template #sinkingScheduleTooltipTemplate>
  <lv-tooltip dm="dM-1002"></lv-tooltip>
</ng-template>