import { IMakeWholeSectionSettings } from '../make-whole-settings/make-whole-section-settings';
import { CallMakeWholeType, DiscountRateType, RatchetMatrixOfferValueType,
  RatchetMatrixCompensationValueType } from '@lv-instrument-common/index';

export const getDefaultMakeWholeSectionSettings = (): IMakeWholeSectionSettings => {
  return {
    callMakeWhole: false,
    conversionMakeWhole: false,
    type: CallMakeWholeType.None,
    ratchetMatrixCompensationValueType: RatchetMatrixCompensationValueType.AdditionalSharesPercentOfCR,
    ratchetMatrixOfferValueType: RatchetMatrixOfferValueType.StockPrice,
    discountRateType: DiscountRateType.Spread,
    endDate: null,
    startDate: null
  };
};
