<div class="settings-confirm-dialog">
  <div class="settings-confirm-content">
    <div class="settings-confirm-content-row">You have unsaved changes to <span class="settings-confirm-continue-editing">{{ componentName }}</span>.</div>
    <div class="settings-confirm-content-row">You can continue to edit <span class="settings-confirm-continue-editing">{{ componentName }}</span> or discard any changes made.</div>

    <div class="settings-confirm-options">
        <button kendoButton
          [primary]="true"
          lvId="settings-confirm-lists-continue-editing"
          (click)="onContinueEditing()">Continue editing</button>
        <button kendoButton
          [primary]="true"
          lvId="settings-confirm-discard-changes"
          (click)="onDiscardChanges()">Discard changes</button>
      </div>
  </div>
</div>