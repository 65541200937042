<lv-loader *ngIf="isLoading"></lv-loader>

<div class="lv-market-data-controls" data-cy="DM-1204" kendoTooltip>
  <div *ngIf="isOpenedFromExcel"
    class="lv-icon-square"
    lvId="marketDataSaveAll"
    [class.lv-icon-square--state-changed]="isSessionStateChanged"
    title="Save all sections"
    [class.lv-icon-square--disabled]="isDisabled"
    (click)="onMarketDataSaveAllExcel()">
    <i class="k-icon k-i-save"></i>
  </div>
  <div *ngIf="!isOpenedFromExcel"
    class="lv-icon-square"
    lvId="marketDataSaveAll"
    [class.lv-icon-square--state-changed]="isSessionStateChanged"
    title="Save all sections"
    [class.lv-icon-square--disabled]="isDisabled"
    (click)="onMarketDataSaveAll()">
    <i class="k-icon k-i-save"></i>
  </div>
  <div class="lv-icon-square"
    [class.lv-icon-square--state-changed]="isModelStateChanged"
    lvId="marketDataReloadAll"
    title="Reload all sections"
    [class.lv-icon-square--disabled]="isDisabled"
    (click)="onReloadAll()">
    <i class="k-icon k-i-reload"></i>
  </div>
</div>

<kendo-tabstrip class="lv-market-data-tabstrip"
  [keepTabContent]="true"
  #tabstrip>
  <kendo-tabstrip-tab title="Credit"
    cssClass="DM-1205"
    [selected]="true">
    <ng-template kendoTabContent>
      <lv-credit #lvCredit
        data-cy="DM-1205"
        [marketDataComponent]="lvCredit"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-credit>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Volatility"
    cssClass="DM-1206">
    <ng-template kendoTabContent>
      <lv-volatility #lvVolatility
        data-cy="DM-1206"
        [marketDataComponent]="lvVolatility"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-volatility>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Borrow"
    cssClass="DM-1207">
    <ng-template kendoTabContent>
      <lv-borrow #lvBorrow
        data-cy="DM-1207"
        [marketDataComponent]="lvBorrow"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-borrow>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Dividends"
    cssClass="DM-1208">
    <ng-template kendoTabContent>
      <lv-dividends #lvDividends
        data-cy="DM-1208"
        [marketDataComponent]="lvDividends"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-dividends>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Funding"
    cssClass="DM-1209">
    <ng-template kendoTabContent>
      <lv-funding #lvFunding
        data-cy="DM-1209"
        [marketDataComponent]="lvFunding"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-funding>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Withholding Tax"
    cssClass="DM-1210">
    <ng-template kendoTabContent>
      <lv-withholding-tax #lvWitholdingTax
        data-cy="DM-1210"
        [marketDataComponent]="lvWitholdingTax"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-withholding-tax>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab title="Interest Rates"
    cssClass="DM-1211">
    <ng-template kendoTabContent>
      <lv-interest-rates #lvInterestRates
        data-cy="DM-1211"
        [marketDataComponent]="lvInterestRates"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-interest-rates>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
    title="Averaging Data"
    cssClass="DM-1212">
    <ng-template kendoTabContent>
      <lv-averaging-data #lvAveragingData
        data-cy="DM-1212"
        [marketDataComponent]="lvAveragingData"
        (didSessionUpdatedEvent)="setSectionStateChanged()">
      </lv-averaging-data>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Other"
  cssClass="DM-4627">
  <ng-template kendoTabContent>
    <lv-other-market-data #lvOtherMarketData
      data-cy="DM-4627"
      [marketDataComponent]="lvOtherMarketData"
      (didSessionUpdatedEvent)="setSectionStateChanged()">
    </lv-other-market-data>
  </ng-template>
</kendo-tabstrip-tab>
</kendo-tabstrip>
