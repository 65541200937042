import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { PikCouponType, PikCouponTypeDescription } from '@lv-instrument-common/index';
import { PikCouponData } from '../../../../models/bond-terms/BondCoupon';
import { LvBondPikCustomScheduleComponent } from './lv-bond-pik-custom-schedule/lv-bond-pik-custom-schedule.component';

@Component({
  selector: 'lv-bond-pik-coupon',
  templateUrl: './lv-bond-pik-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBondPikCouponComponent implements OnInit {
  @Input() model: PikCouponData;
  @Output() didPikCouponChange: EventEmitter<PikCouponData>;
  @ViewChild('pikSchedule', { static: false }) pikSchedule: LvBondPikCustomScheduleComponent;

  formatFour = '#,###.####';
  decimalsFour = '4';

  get isCustomPikCouponType(): boolean {
    return this.model.pikCouponType === PikCouponType.Custom;
  }

  get isPikOnlyPikCouponType(): boolean {
    return this.model.pikCouponType === PikCouponType.PIKOnly;
  }

  get isNonePikCouponType(): boolean {
    return this.model.pikCouponType === PikCouponType.None;
  }

  pikCouponTypeLookup: LvLookupEnum;

  constructor() {
    this.pikCouponTypeLookup = new LvLookupEnum(PikCouponTypeDescription);
    this.didPikCouponChange = new EventEmitter<PikCouponData>();
  }

  ngOnInit() {
  }

  onPikCouponChange() {
    this.didPikCouponChange.next(this.model);
  }

  getPIKCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
