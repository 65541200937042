import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBondCreditSectionSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-bond-credit-settings/market-data-bond-credit-section-settings';
import { AccruedOnDefaultDescription } from '@lv-analytics/models/market-data/credit/enums/accrued-on-default';
import { CreditSourceDescription } from '@lv-analytics/models/market-data/credit/enums/credit-source';
import { DateAdjustmentDescription } from '@lv-analytics/models/market-data/credit/enums/date-adjustment';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';

@Component({
  selector: 'lv-credit-bond-section-settings',
  templateUrl: './lv-credit-bond-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCreditBondSectionSettingsComponent {
  @Input() settings: IBondCreditSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  creditSource: LvLookupEnum;
  badDayConvention: LvLookupEnum;
  accruedOnDefault: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  constructor() {
    this.creditSource = new LvLookupEnum(CreditSourceDescription);
    this.badDayConvention = new LvLookupEnum(DateAdjustmentDescription);
    this.accruedOnDefault = new LvLookupEnum(AccruedOnDefaultDescription);

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return false;
  }
}