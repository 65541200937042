<lv-flex-box
  direction="row"
  data-cy="DM-1481">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="couponNetFactorSettingsTooltipTemplate"
    filter="span">
    <span> Coupon Net Factor </span>
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="netFactorInstrumentCCY"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.couponNetFactor"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<ng-template #couponNetFactorSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1481"></lv-tooltip>
</ng-template>
