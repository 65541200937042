import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, OnInit,
  Optional, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { LvYieldCurveTermsStructureComponent } from '@lv-analytics/components/market-data/lv-market-data';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import {
  IInterestRatesCopyAndPasteSectionSettings, ITenor, ITermStructureItem, InterestRateSource,
  InterestRateSourceDescription, PricingEnvironmentSections, YieldCurveSource, YieldCurveTermsStructureSettings
} from '@lv-analytics/models';
import { IBondInterestRate } from '@lv-analytics/models/bond/market-data/interest-rates/bond-interest-rates';
import { ISaveBondInterestRatesDataRequest } from '@lv-analytics/models/bond/market-data/interest-rates/save-bond-interest-rates';
import { ISaveBondInterestRatesRequest } from '@lv-analytics/models/bond/market-data/interest-rates/save-bond-interest-rates-request';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';
import { InterestRatesMapper } from '@lv-analytics/models/market-data/interest-rates/interest-rates-mapper';
import { ISystemYieldCurveInfo } from '@lv-analytics/models/market-data/yield-curve/system-yield-curve-info';
import { MarketDataService } from '@lv-analytics/services';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';
import * as _ from 'lodash';
import { filter } from 'rxjs';

@Component({
  selector: 'lv-bond-interest-rates',
  templateUrl: './lv-bond-interest-rates.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LvBondInterestRatesComponent implements OnInit {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild('instrumentYieldCurveTerms') instrumentYieldCurveTerms: LvYieldCurveTermsStructureComponent;

  get instrumentLoaded(): boolean {
    return this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get isInstrumentInterestRateSourceFlat(): boolean {
    return this.interestRatesSettings.instrumentInterestRateSource === InterestRateSource.Flat;
  }

  get cbCurrency(): string {
    return this._analyticsPresenter.bondSession?.terms?.general.currencyCode;
  }

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField('IR_CURVE_RANGE_INST')
      || (!!this._excelSvc?.containsField('IR_CURVE_RANGE_T_INST')
        && !!this._excelSvc?.containsField('IR_CURVE_RANGE_R_INST')
        && !!this._excelSvc?.containsField('IR_CURVE_RANGE_DS_INST'));
  }

  get isScheduleInExcelEditable(): boolean {
    return !!this._excelSvc?.getField('IR_CURVE_RANGE_INST')?.editable
      || (!!this._excelSvc?.getField('IR_CURVE_RANGE_T_INST')?.editable
        && !!this._excelSvc?.getField('IR_CURVE_RANGE_R_INST')?.editable
        && !!this._excelSvc?.getField('IR_CURVE_RANGE_DS_INST')?.editable);
  }

  get scheduleInExcelOverrideAlias(): string {
    if (!!this._excelSvc?.containsField('IR_CURVE_RANGE_INST')) {
      return 'IR_CURVE_RANGE_INST';
    }

    if (!!this._excelSvc?.containsField('IR_CURVE_RANGE_T_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_R_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_DS_INST')) {
      return 'IR_CURVE_RANGE_T_INST,IR_CURVE_RANGE_R_INST,IR_CURVE_RANGE_DS_INST';
    }

    return '';
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;
  @Output() didSessionDataLoadedEvent: EventEmitter<void>;
  @Output() isSectionLoading: EventEmitter<boolean>;

  interestRatesSettings: IBondInterestRate;
  instrumentYieldCurve: ISystemYieldCurveInfo[];
  instrumentYieldCurveSettings: YieldCurveTermsStructureSettings;
  instrumentYieldCurveScheduledItems: ITermStructureItem[];
  originalValue: ISaveBondInterestRatesRequest;

  interestRatesSection = PricingEnvironmentSections.InterestRates;
  numberOfDecimalsPercentage = '4';
  numberFormatPercentage = '#.####';
  instrumentInterestRatesSource: LvLookupEnum;
  interestRatesCopyAndPasteSettings: IInterestRatesCopyAndPasteSectionSettings;

  private readonly _customYieldCurveId = -1;
  private _isInitialization = true;
  private _tenors: ITenor[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _destroyRef: DestroyRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _marketDataService: MarketDataService,
    private _interestRatesMapper: InterestRatesMapper,
    @Optional() private _excelSvc: LvExcelService,
  ) {
    this.interestRatesSettings = {
      instrumentTermsStructure: [],
    } as IBondInterestRate;

    this.instrumentYieldCurve = [];

    this.instrumentInterestRatesSource = new LvLookupEnum(InterestRateSourceDescription);
    this.interestRatesCopyAndPasteSettings = {} as IInterestRatesCopyAndPasteSectionSettings;
    this.didSessionUpdatedEvent = new EventEmitter<void>();
    this.didSessionDataLoadedEvent = new EventEmitter();
    this.isSectionLoading = new EventEmitter<boolean>();
    this.instrumentYieldCurveScheduledItems = [];
  }

  ngOnInit(): void {
    this._analyticsPresenter.onModelLoading.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(isLoading => this.setLoadingState(isLoading));

    if (!!this._analyticsPresenter.getModelData()?.bondValuationSession) {
        this.init(this._analyticsPresenter.getModelData().bondValuationSession.marketData?.interestRates || {} as IBondInterestRate);
    }

    this.loadSettings();

      this._analyticsPresenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        if (evt) {
          if (!!this._analyticsPresenter.getModelData()?.bondValuationSession && !this._isInitialization) {
            this.init(this._analyticsPresenter.getModelData().bondValuationSession.marketData?.interestRates || {} as IBondInterestRate);
            this.loadSettings();
          }
        }
    });

    this._analyticsPresenter.onAnalyticsSettingsUpdated
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe(evt => {
      if (evt) {
        if (!!this._analyticsPresenter.getModelData()?.bondValuationSession) {
            this.init(this._analyticsPresenter.getModelData().bondValuationSession.marketData?.interestRates || {} as IBondInterestRate);
            this.loadSettings();
      }
      else {
        // this.init(null);
      }
      
      this._changeDetectorRef.detectChanges();
    }});    
    
    this._isInitialization = false;
  }

  /**
   * Loads copy and paste settings.
   */
  loadSettings() {
    const modelData = this._analyticsPresenter.getModelData();

    if (this._analyticsPresenter.bondSession
      && modelData.otherSettings
      && modelData.otherSettings.copyAndPasteSettings) {

      this.interestRatesCopyAndPasteSettings = modelData.otherSettings.copyAndPasteSettings.interestRates;
    }

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Does initialization.
   * @param settings IBondInterestRate object
   */
  private async init(settings: IBondInterestRate) {
    try {
      this.setLoadingState(true);

      this.interestRatesSettings = settings;
      const availableInstrumentSystemYielCurves = await this._marketDataService.getAvailableSystemYieldCurves(this.cbCurrency);

      //When you click on reload on draft private instrument that is not saved so it results with empty instrument
      if (availableInstrumentSystemYielCurves.length === 0) {
        return;
      }

      if (settings.yieldCurveId && settings.yieldCurveId !== -1
        && availableInstrumentSystemYielCurves.findIndex(x => x.id === settings.yieldCurveId) < 0) {
        settings.yieldCurveId = availableInstrumentSystemYielCurves.find(x => x.isDefault).id;
      }

      if (!settings.yieldCurveId) {
        settings.yieldCurveId = availableInstrumentSystemYielCurves.find(x => x.isDefault)?.id;
      }

      this.initAvailableSystemYieldCurves(availableInstrumentSystemYielCurves);

      this._tenors = await this._marketDataService.getAllTenors();

      this.setInstrumentYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());

      await this.loadInstrumentYieldCurves();

      if (this._analyticsPresenter.asHelper.instrumentLoaded) {
        this.originalValue = _.cloneDeep(this.getInterestRatesRequest());
      }

      await this.updateYieldCurveModel();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Initializes available system yield curves.
   * @param availableInstrumentSystemYielCurves List of ISystemYieldCurveInfo objects.
   */
  initAvailableSystemYieldCurves(availableInstrumentSystemYielCurves: ISystemYieldCurveInfo[]) {
    this.instrumentYieldCurve = [];
    availableInstrumentSystemYielCurves.forEach(item => {
      item.name = item.name + ' - ' + item.leversysId;
      this.instrumentYieldCurve.push(item);
    });

    this.instrumentYieldCurve.push({
      id: -1,
      name: 'Custom'
    } as ISystemYieldCurveInfo);
  }

  /**
   * Occurs on environment change and loads interest rates.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangeEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadInterestRates(environment);
    this.didSessionDataLoadedEvent.next();
  }

  /**
   * Occurs on instrument interest rate source change.
   */
  async onInstrumentInterestRateSourceChange() {
    try {
      this.setLoadingState(true);

      this.setInstrumentYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());
      await this.loadInstrumentYieldCurves();

      this.updateInterestRatesModel();

      await this.onInterestRatesSectionChange();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Loads interest rates.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadInterestRates(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);
      this._analyticsPresenter.loadBondInterestRates(environment);
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on instrument yield curve change.
   */
  async onInstrumentYieldCurveChange() {
    try {
      this.setLoadingState(true);
      await this.loadInstrumentYieldCurves();

      this.setInstrumentYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());
      this.updateInterestRatesYieldCurveModel();

      await this.onInterestRatesSectionChange();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Loads instrument yield curves.
   * @param init
   */
  private async loadInstrumentYieldCurves(): Promise<void> {
    try {
      this.setLoadingState(true);

      if (!!this.interestRatesSettings
        && this.interestRatesSettings.instrumentInterestRateSource === InterestRateSource.YieldCurve) {

        if (this.interestRatesSettings.yieldCurveId !== this._customYieldCurveId) {
          this.instrumentYieldCurveScheduledItems =
            await this._marketDataService.getSystemYieldCurve(this.cbCurrency, this.interestRatesSettings.yieldCurveId);
        }
        else {
          if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_T_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_T_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_R_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_R_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_DS_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_DS_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_INST_SPECIAL')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_INST_SPECIAL') as ITermStructureItem[];
          }
          else {
            this.instrumentYieldCurveScheduledItems =
              await this._marketDataService.getCustomYieldCurve(
                this.cbCurrency,
                this.getSelectedEnvironmentId()
              );
          }

          if (!this.instrumentYieldCurveScheduledItems) {
            this.instrumentYieldCurveScheduledItems = [];
          }
        }
      }
    }
    catch (e) {
      this.instrumentYieldCurveScheduledItems = [];
      throw e;
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Sets instrument yield curve terms structure settings.
   * @param environmentId Environment ID.
   */
  setInstrumentYieldCurveTermsStructureSettings(environmentId: string) {
    if (this.isInstrumentInterestRateSourceFlat) {
      return;
    }

    this.instrumentYieldCurveSettings = new YieldCurveTermsStructureSettings();
    this.instrumentYieldCurveSettings.curveId = this.interestRatesSettings.yieldCurveId;
    this.instrumentYieldCurveSettings.source = this.interestRatesSettings.instrumentYieldCurveSource;
    // this.instrumentYieldCurveSettings.setInstrumentTypeTitle(this._analayticsPresenter.cHelper.currencyCode);
    this.instrumentYieldCurveSettings.environmentId = environmentId;
    this.instrumentYieldCurveSettings.tenors = this._tenors;
  }

  /**
   * Gets selected environment ID.
   * @returns Selected environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();

    if (env) {
      return env.id;
    }

    return null;
  }

  /**
  * Gets settings.
  * @returns ISaveInterestRatesRequest object.
  */
  getSettings(): ISaveBondInterestRatesDataRequest {
    return this.getInterestRatesRequest(false);
  }

  /**
 * Gets section.
 * @returns PricingEnvironmentSections object.
 */
  getSection(): PricingEnvironmentSections {
    return this.interestRatesSection;
  }

  /**
* Applies current changes.
*/
  applyCurrentChanges() {
    if (this.interestRatesSettings.instrumentInterestRateSource ===
      InterestRateSource.YieldCurve && this.instrumentYieldCurveTerms.advancedGrid) {
      this.instrumentYieldCurveTerms.applyAdvancedGridChanges();
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  setLoadingState(isLoading: boolean) {
    this.isSectionLoading.next(isLoading);
    this.envSettings.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on interest rates section change.
   * @param sendEvent A flag indicating if event should be sent.s
   */
  async onInterestRatesSectionChange(sendEvent: boolean = true) {
    try {
      this.setInstrumentYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());

      this.updateInterestRatesModel();

      const request = this.getInterestRatesRequest();

      if (!_.isEqual(request, this.originalValue)) {
        if (sendEvent) {
          this.didSessionUpdatedEvent.next();
        }

        this.originalValue = _.cloneDeep(request);
      }
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
    }
  }

  /**
   * Updates interest rates model.
   */
  private async updateInterestRatesModel() {
    this._analyticsPresenter.overrideBondInSession(bondValuationSession => {
      bondValuationSession.marketData.interestRates = this.interestRatesSettings;
      bondValuationSession.instrumentYieldCurve = this.instrumentYieldCurveScheduledItems;
    }, true);
  }

  /**
   * Updates yield curve model.
   */
  private async updateYieldCurveModel() {
    this._analyticsPresenter.overrideBondInSession(bondValuationSession => {
      bondValuationSession.instrumentYieldCurve = this.instrumentYieldCurveScheduledItems;
    }, true);
  }

  /**
   * Updates interest rates yield curve in session.
   */
  private async updateInterestRatesYieldCurveModel() {
    this._analyticsPresenter.overrideBondInSession(bondValuationSession => {
      bondValuationSession.instrumentYieldCurve = this._interestRatesMapper
        .mapInterestRatesTermStructureToApi(JSON.parse(JSON.stringify(this.instrumentYieldCurveScheduledItems)));
    });
  }

  /**
 * Gets interest rates request.
 * @param isSessionSave A flag indicating if session is saved.
 * @returns ISaveInterestRatesRequest object.
 */
  getInterestRatesRequest(isSessionSave: boolean = true) {
    const request = {
      environmentId: this.getSelectedEnvironmentId(),
      instrumentCurrencyCode: this._analyticsPresenter.bondSession?.terms.general.currencyCode,
      interestRates: this.interestRatesSettings,
      sessionId: this._analyticsPresenter.asHelper.sessionId,
      instrumentTermsStructure: null,

    } as ISaveBondInterestRatesRequest;

    if (!!this.instrumentYieldCurveTerms) {
      if (!(this.interestRatesSettings.instrumentYieldCurveSource === YieldCurveSource.Leversys && isSessionSave === false)) {
        request.instrumentTermsStructure = this._interestRatesMapper
          .mapInterestRatesTermStructureToApi(JSON.parse(JSON.stringify(this.instrumentYieldCurveScheduledItems)));
      }
    }

    return request;
  }
}
