import { FrequencyDescription } from '@lv-analytics/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { Accretion, AccretionGrossNet, AccretionType, AccretionTypeDesciption, DayCountDescription, IssueAndRedemption } from '@lv-instrument-common/index';

export class LvAccretionView {

  private _accretionStartDate: Date;
  private _accretionEndDate: Date;
  public model: Accretion;

  accretionTypeLookup: LvLookupEnum;
  dayCountLookup: LvLookupEnum;
  frequencyLookup: LvLookupEnum;
  grossNetLookup: LvLookupEnum;

  numberOfDecimalsPercentage = '4';
  numberFormat = '#,###.####';

  get isAccretionTypeFixed(): boolean {
    return this.model.type === AccretionType.Fixed;
  }

  get accretionStartDate(): Date {
    return this._accretionStartDate;
  }

  set accretionStartDate(value: Date) {
    this._accretionStartDate = value;

    if (this.model && this.useAccretionStartDate) {
      this.model.accretionStartDate = value;
    }
  }

  get accretionEndDate(): Date {
    return this._accretionEndDate;
  }

  set accretionEndDate(value: Date) {
    this._accretionEndDate = value;

    if (this.model && this.useAccretionEndDate) {
      this.model.accretionEndDate = value;
    }
  }

  useAccretionStartDate: boolean;
  useAccretionEndDate: boolean;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.accretionTypeLookup = new LvLookupEnum(AccretionTypeDesciption);
    this.dayCountLookup = new LvLookupEnum(DayCountDescription);
    this.frequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.grossNetLookup = new LvLookupEnum(AccretionGrossNet);

    this.init({} as Accretion, {} as IssueAndRedemption);
  }

  init(model?: Accretion, issueAndRedemption?: IssueAndRedemption) {
    this.model = model ?? this._lvConvertibleBondTermsService?.convertibleBondDocument.accretion;
    this.setDefaultFields(issueAndRedemption);
  }

  accretionStartDateCheckBoxChanged(value: boolean): void {
    this.useAccretionStartDate = value;

    if (this.model) {
      this.model.accretionStartDate = value ? this._accretionStartDate : null;
    }
  }

  accretionEndDateCheckBoxChanged(value: boolean): void {
    this.useAccretionEndDate = value;

    if (this.model) {
      this.model.accretionEndDate = value ? this._accretionEndDate : null;
    }
  }

  private setDefaultFields(issueAndRedemption: IssueAndRedemption) {
    if (issueAndRedemption) {

      const accretionSettings = this._lvConvertibleBondTermsService.accretionSettings;

      if (!this.model.accretionStartDate) {
        this._accretionStartDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, accretionSettings?.accretionStartDate);
        this.useAccretionStartDate = false;
      } else {
        this._accretionStartDate = this.model.accretionStartDate;
        this.useAccretionStartDate = true;
      }

      if (!this.model.accretionEndDate) {
        this._accretionEndDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -accretionSettings?.accretionEndDate);

        this.useAccretionEndDate = !!accretionSettings?.accretionEndDate;
      } else {
        this._accretionEndDate = this.model.accretionEndDate;
        this.useAccretionEndDate = true;
      }
    }
    if (this.model.type === AccretionType.Floating) {
      this.model.type = AccretionType.UseRedemptionPrice;
    }
  }
}
