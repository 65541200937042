import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'settings-confirm-dialog',
  templateUrl: './settings-confirm-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsConfirmDialogComponent {

  @Input() componentName: string;
  @Output() didContinueEditing: EventEmitter<void>;
  @Output() didDiscardChanges: EventEmitter<void>;

  constructor(
  ) {
    this.didContinueEditing = new EventEmitter<void>();
    this.didDiscardChanges = new EventEmitter<void>();
    this.componentName = '';
   }

  /**
   * Occurs on continue editing.
   */
  onContinueEditing() {
    this.didContinueEditing.emit();
  }

  /**
   * Occurs on discard changes.
   */
  onDiscardChanges() {
    this.didDiscardChanges.emit();
  }
}
