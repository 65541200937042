// tslint:disable-next-line: max-line-length
import { CreditModelTypeDescription, EquityModelTypeDescription, StochasticCreditBCType, StochasticCreditModelTypeDescription } from 'src/app/modules/analytics/models/model-customization/model-customization-enum';

export const creditModelSettings = {
  creditModelType: CreditModelTypeDescription.ReducedFormModel,
  equityModelType: EquityModelTypeDescription.RiskyEquity,
  numPointsStochasticCredit: 40,
  stochasticCreditBCType: StochasticCreditBCType.Neumann,
  stochasticCreditModelType: StochasticCreditModelTypeDescription.CoxIngersollRoss,
  underlyingCreditModelType: CreditModelTypeDescription.ReducedFormModel
};
