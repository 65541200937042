import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { CallValueType, CallValueTypeBondDescription, CleanUpCall, DayCountDescription, FrequencyDescription, NoticePeriod } from '@lv-instrument-common/index';
import { LvBondTermsPresenter } from '../lv-bond-terms.presenter';
import { Subscription } from 'rxjs';
import { BondTermsSectionEvent } from '../../../models/bond-terms/enums/bond-terms-section-events';
import { v4 } from 'uuid';
import { BondTermsDocument } from '../../../models/bond-terms/BondTermsDocument';
import { LvLookupEnum } from '@lv-core-ui/util';
import { LvBondCallsScheduleComponent } from './lv-bond-calls-schedule/lv-bond-calls-schedule.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BondGeneral } from '../../../models';
import { BondCall } from '../../../models/bond-terms/BondCall';

@Component({
  selector: 'lv-bond-calls',
  templateUrl: './lv-bond-calls.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvBondCallsComponent implements OnInit {
  @ViewChild('callSchedule') callSchedule: LvBondCallsScheduleComponent;

  get isYieldFieldVisible(): boolean {
    return this.model?.valueType === CallValueType.GrossYield
      || this.model?.valueType === CallValueType.NetYield;
  }

  @Input() model: BondCall;
  issueAndRedemption: BondGeneral;
  cleanUpCall: CleanUpCall;
  callableId: string;
  cleanUpCallId: string;
  keepAccruedRedemptionId: string;
  forfeitCouponId: string;
  noticeGivenId: string;
  dpCallFirstCallConsiderationDatePickerId: string;
  numberOfDecimalsPercentage = '4';
  numberFormat = '#,###.####';
  numberOfZeroDecimals = '0';
  numberZeroFormat = 'n0';
  callValueTypeLookup: LvLookupEnum;
  callYieldFrequencyLookup: LvLookupEnum;
  callYieldDayCountLookup: LvLookupEnum;
  callNoticeLookup: LvLookupEnum;
  isZeroCouponType: boolean;
  private _modelSubscription: Subscription;
  private _destroyRef = inject(DestroyRef);


  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,
  ) {
    this.callableId = v4();
    this.cleanUpCallId = v4();
    this.keepAccruedRedemptionId = v4();
    this.forfeitCouponId = v4();
    this.noticeGivenId = v4();
    this.isZeroCouponType = false;
    this.callValueTypeLookup = new LvLookupEnum(CallValueTypeBondDescription);
    this.callYieldFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.callYieldDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.callNoticeLookup = new LvLookupEnum(NoticePeriod);
    this.dpCallFirstCallConsiderationDatePickerId = 'callFirstCallConsiderationDatePickerId';
  }

  ngOnInit(): void {
    this._modelSubscription = this._presenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(event => {
      if (event === BondTermsSectionEvent.MWCallsEvent || event === BondTermsSectionEvent.ModelLoadedEvent) {
        this.model = this._presenter.getModel().call;
        this.setDefaultFields({ ...this._presenter.getModel().general }, { ...this._presenter.getModel().cleanUpCall });
        this._changeDetectorRef.detectChanges();
      }
    });

    this.setDefaultFields({ ...this._presenter.getModel().general }, { ...this._presenter.getModel().cleanUpCall });
  }


  /**
   * On call elemnets changed
   */
  onCallChanged() {
    if (this.model) {
      if (this.issueAndRedemption.isCallable) {
        this.model.callDate = this.model.callDate ? new Date(this.model.callDate) : this.model.callNoticeGiven ? new Date() : null;
      }
    }

    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.call = this.model;
      terms.general.isCallable = this.issueAndRedemption.isCallable;
    }, BondTermsSectionEvent.CallIssueAndRedemptionEvent,
      'other');
  }

  /**
   * Fired when Value Type Changed
   */
  onCallValueTypeChanges() {
    if (!!this.callSchedule) {
      this.callSchedule.resetPriceCallSchedule(this.model.valueType);
    }

    this.onModelChange();
  }

  /**
 * Fired when Clean Up Call Changed
 */
  onCleanUpCallChanged() {
    if (this.issueAndRedemption.isCleanUpCall) {
      if (!this.cleanUpCall) {
        this.cleanUpCall = new CleanUpCall();
      }
      this.cleanUpCall.isCleanUpCall = this.issueAndRedemption.isCleanUpCall;
    } else {
      this.cleanUpCall = null;
    }

    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.cleanUpCall = this.cleanUpCall;
      terms.general.isCleanUpCall = this.issueAndRedemption.isCleanUpCall;
    }, BondTermsSectionEvent.TermsCleanUpCallEvent,
      'other');
  }

  /**
   * Fired when Call fields changed
   */
  onModelChange() {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.call = this.model;
    }, BondTermsSectionEvent.TermsCallEvent,
      'other');
  }


  /**
   * Calculated based on following logic:
   * First Call Consideration Date:
   * First Call Date from Call Schedule
   * minus Call Notice days (max) (business or calendar depending on Call Notice Days (business/calendar) value)
   * minus Consideration days (max) (business or calendar depending on Consideration Days (bus/cal) value)
   *
   * Call Trigger Observation Start Date:
   * First Call Date from Call Schedule
   * minus Call Notice days (max) (business or calendar depending on Call Notice Days (business/calendar) value)
   * minus Consideration days (max) (business or calendar depending on Consideration Days (bus/cal) value)
   * minus Out of Days (use business days)
   */
  recalculateReadOnlyDates() {
    if (this.model) {
      // tslint:disable-next-line: max-line-length
      const firstCallScheduleDate = this.model.scheduleItems && this.model.scheduleItems.length > 0 ? this.model.scheduleItems[0].startDate : null;

      if (firstCallScheduleDate && this.model.callNoticeDaysMax > 0) {
        // tslint:disable-next-line: max-line-length
        const dateAdjustedNoticedDays = this.calculateReadOnlyDate(firstCallScheduleDate, this.model.callNoticeDaysMax, this.model.noticePeriod);
        // tslint:disable-next-line: max-line-length
        //const dateAdjustedConsiderationDays = this.calculateReadOnlyDate(dateAdjustedNoticedDays, this.model.considerationDaysMax, this.model.considerationDaysBusinessCalendar);

        this.model.callFirstCallConsiderationDate = dateAdjustedNoticedDays;

      }
      else {
        this.model.callFirstCallConsiderationDate = null;
      }
    }

    this._changeDetectorRef.detectChanges();
    this.onModelChange();
  }

  /**
 * Calculate read-only date for Notice Period
 */
  calculateReadOnlyDate(firstCallScheduleDate: Date, callNoticeDaysMax: number, noticePeriod: NoticePeriod): Date {
    const result = this.getNoticePeriodDays(noticePeriod, firstCallScheduleDate, callNoticeDaysMax);
    return result;
  }

  /**
 * Auxiliary function does not take the weekend into account when calculating date. Always use NoticePeriod == Business
 * function return date = FirstCallConsideration Date - OutOfDays
 * numberToAdjust = Out Of Day from Call Trigger section
 */
  calculateDateForBusinessDate(dateForAdjust: Date, numberToAdjust: number) {
    const endDate = new Date(dateForAdjust);
    const addDays = numberToAdjust > 0 ? 1 : -1;
    numberToAdjust = Math.abs(numberToAdjust);

    while (numberToAdjust > 0) {
      endDate.setDate(endDate.getDate() + addDays);

      const dayOfWeek = endDate.getDay();
      if (!(dayOfWeek === 0 /* Sunday */ || dayOfWeek === 6 /* Saturday */)) {
        --numberToAdjust;
      }
    }

    return endDate;
  }

  onCallNoticeGivenChange() {
    this.model.callDate = this.model.callDate ? new Date(this.model.callDate) : this.model.callNoticeGiven ? new Date() : null;
    this.onModelChange();
    this._changeDetectorRef.detectChanges();
  }

  applyScheduleChanges() {
    if (this.model && this.callSchedule) {
      this.callSchedule.applyAdvancedGridChanges();
    }
  }

  /**
 * Calculate Notice period Days based on input parameters
 * @param noticePeriod notice period
 * @param firstCallScheduleDate Call schedule date from first row
 * @param callNoticeDaysMax Call Notice Days Max
 * @returns falg- true if element with given id is found
 */
  private getNoticePeriodDays(noticePeriod: NoticePeriod, firstCallScheduleDate: Date, callNoticeDaysMax: number) {
    let mFirst: Date;
    let mSecond: Date;

    if (noticePeriod === NoticePeriod.Calendar) {
      mFirst = new Date(firstCallScheduleDate);
      mFirst.setDate(mFirst.getDate() - callNoticeDaysMax);
    } else {
      mSecond = this.calculateDateForBusinessDate(firstCallScheduleDate, -1 * callNoticeDaysMax);
      const tempDate = new Date(mSecond);
      tempDate.setDate(tempDate.getDate() + 1);
      mFirst = this.calculateDateForBusinessDate(tempDate, -1);
    }

    return mFirst;
  }

  /**
   * Set default fields
   */
  private setDefaultFields(issueAndRedemption: BondGeneral, cleanUpCall: CleanUpCall) {
    this.issueAndRedemption = issueAndRedemption;
    this.cleanUpCall = cleanUpCall;
  }
}
