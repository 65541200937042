export enum PutValueType {
	PerOfPar = 'PerOfPar',
	GrossYield = 'GrossYield',
	NetYield = 'NetYield',
	AccretedValue = 'AccretedValue'
}

export enum PutValueTypeDescription {
	PerOfPar = '% of Par',
	GrossYield = 'Gross Yield',
	NetYield = 'Net Yield',
	AccretedValue = 'Accreted Value'
}