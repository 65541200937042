import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBaseBondTermsSettings, IBondAccretionSectionSettings } from '../../../models/bond-terms-settings';

@Component({
  selector: 'lv-bond-accretion-settings',
  templateUrl: './lv-bond-accretion-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondAccretionSettingsComponent {
  @Input() systemDefaults: IBaseBondTermsSettings<IBondAccretionSectionSettings>;
  @Input() myDefaults: IBaseBondTermsSettings<IBondAccretionSectionSettings>;

  constructor() { }

  @HostBinding('class.lv-accretion-settings')
  get isLvAccretionSettingsComponent() {
    return true;
  }

 /**
  * Retrieves element's id
  * @param element reference to given element
  * @param id element's id
  * @returns falg- true if element with given id is found
  */
  getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
