<lv-flex-box class="lv-convertible-bond-terms-wrapper">
  <lv-loader *ngIf="isLoading"></lv-loader>
  <kendo-tabstrip class="lv-convertible-bond-terms" #tabStrip
    [class.lv-convertible-bond-terms-two-rows]="lvConvertibleBondTermsUtil.hasconvertibleBondTermsTwoRows"
    [class.lv-convertible-bond-terms-three-rows]="lvConvertibleBondTermsUtil.hasconvertibleBondTermsThreeRows"
    [class.lv-convertible-bond-terms-four-rows]="lvConvertibleBondTermsUtil.hasconvertibleBondTermsFourRows"
    [class.lv-convertible-bond-terms-five-rows]="lvConvertibleBondTermsUtil.hasconvertibleBondTermsFiveRows"
    [class.lv-convertible-bond-terms-six-rows]="lvConvertibleBondTermsUtil.hasconvertibleBondTermsSixRows"
    [class.lv-convertible-bond-terms-seven-rows]="lvConvertibleBondTermsUtil.hasconvertibleBondTermsSevenRows"
    [class.lv-convertible-bond-terms-eight-rows]="lvConvertibleBondTermsUtil.hasconvertibleBondTermsEightRows"
    [keepTabContent]="true">

    <kendo-tabstrip-tab *ngIf="isQuickTermsVisible" 
      title="Quick Terms Entry"
      cssClass="quick-terms DM-1222">
      <ng-template kendoTabContent>
        <lv-quick-terms-entry
          data-cy="DM-1222"
          [stockReferenceSignalStore]="stockReferenceSignalStore">
        </lv-quick-terms-entry>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="General"
      cssClass="DM-1223">
      <ng-template kendoTabContent>
        <lv-terms-general
          data-cy="DM-1223"
          [isLvsConvertible]="!isPrivateInstrument"
          [stockReferenceSignalStore]="stockReferenceSignalStore"
          (doEquityOpenAsTab)="doEquityOpenAsTab($event)">
        </lv-terms-general>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Coupon"
      cssClass="DM-1225">
      <ng-template kendoTabContent>
        <lv-coupon #couponComponent data-cy="DM-1225">
        </lv-coupon>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Accretion"
      cssClass="DM-1226">
      <ng-template kendoTabContent>
        <lv-accretion data-cy="DM-1226">
        </lv-accretion>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Calls"
      cssClass="DM-1227">
      <ng-template kendoTabContent>
        <lv-calls #callComponent data-cy="DM-1227">
        </lv-calls>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Puts"
      cssClass="DM-1228">
      <ng-template kendoTabContent>
        <lv-puts #putComponent data-cy="DM-1228">
        </lv-puts>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Conversion"
      cssClass="DM-1229">
      <ng-template kendoTabContent>
        <lv-conversion #conversionComponent data-cy="DM-1229">
        </lv-conversion>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Dividend Protection"
      cssClass="DM-1231">
      <ng-template kendoTabContent>
        <lv-dividend-protection #dividendProtectionComponent data-cy="DM-1231">
        </lv-dividend-protection>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Make-Whole" [disabled]="!isMakeWholeEnabled"
      cssClass="DM-1232">
      <ng-template kendoTabContent>
        <lv-make-whole data-cy="DM-1232">
        </lv-make-whole>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Takeover Protection" *ngIf="!isDraftFromExcel"
      cssClass="DM-1233">
      <ng-template kendoTabContent>
        <lv-takeover-protection data-cy="DM-1233">
        </lv-takeover-protection>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Identifiers" *ngIf="!isDraftFromExcel"
      cssClass="DM-1234">
      <ng-template kendoTabContent>
        <lv-identifiers #identifiersComponent [isPrivate]="isPrivateInstrument" data-cy="DM-1234">
        </lv-identifiers>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      title="Estimates"
      *ngIf="!isPrivateInstrument"
      cssClass="DM-1236">
      <ng-template kendoTabContent>
        <lv-estimates data-cy="DM-1236">
        </lv-estimates>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      title="Other"
      *ngIf="!isPrivateInstrument"
      cssClass="DM-1237">
      <ng-template kendoTabContent>
        <lv-other data-cy="DM-1237">
        </lv-other>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</lv-flex-box>

