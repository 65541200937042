import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges,
  ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings, ITakeoverProtectionSectionSettings, OfferAndCompensation, PremiumCompensationFormulaTypeDescription,
  TakeoverCompensationTypeDescription, TakeoverPutValueTypeDescription } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { RatchetMatrixCompensationValueTypeDescription, RatchetMatrixOfferValueTypeDescription } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-takeover-protection-section-settings',
  templateUrl: './lv-takeover-protection-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvTakeoverProtectionSectionSettingsComponent implements OnInit, OnChanges {

  @Input() settings: IBaseTermsSettings<ITakeoverProtectionSectionSettings>;
  @Input() overrideDefaults: boolean;
  @Input() makeWholeOfferAndCompensation: OfferAndCompensation;

  takeOverCompensationCheckBoxId: string;
  takeoverPutCheckBoxId: string;

  takeoverPutValueTypeLookup: LvLookupEnum;
  takeoverCompensationTypeLookup: LvLookupEnum;
  offerValueTypeLookup: LvLookupEnum;
  compensationValueTypeLookup: LvLookupEnum;
  premiumCompensationFormulaTypeLookup: LvLookupEnum;

  decimalsZero = '0';
  formatZero = 'n0';

  constructor() {
    this.takeOverCompensationCheckBoxId = v4();
    this.takeoverPutCheckBoxId = v4();

    this.takeoverPutValueTypeLookup = new LvLookupEnum(TakeoverPutValueTypeDescription);
    this.takeoverCompensationTypeLookup = new LvLookupEnum(TakeoverCompensationTypeDescription);
    this.offerValueTypeLookup = new LvLookupEnum(RatchetMatrixOfferValueTypeDescription);
    this.compensationValueTypeLookup = new LvLookupEnum(RatchetMatrixCompensationValueTypeDescription);
    this.premiumCompensationFormulaTypeLookup = new LvLookupEnum(PremiumCompensationFormulaTypeDescription);
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.makeWholeOfferAndCompensation && !changes.makeWholeOfferAndCompensation.firstChange &&
        // tslint:disable-next-line: max-line-length
        (changes.makeWholeOfferAndCompensation.currentValue.offerValueType !== changes.makeWholeOfferAndCompensation.previousValue.offerValueType
        // tslint:disable-next-line: max-line-length
        || changes.makeWholeOfferAndCompensation.currentValue.compensationValueType !== changes.makeWholeOfferAndCompensation.previousValue.compensationValueType)) {
          this.settings.settings.ratchetMatrixOfferValueType = this.makeWholeOfferAndCompensation.offerValueType;
          this.settings.settings.ratchetMatrixCompensationValueType = this.makeWholeOfferAndCompensation.compensationValueType;
          }
  }

  getTakeoverProtectionSectionSettingsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
