import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';

export function calculateDateFromYear(year: number, firstSettlementDate: Date): Date {
  let mFirst;
  const inputYear = Math.floor(year);
  const inputMonth = Math.floor(12 * (year - inputYear));
  const diff = (12 * ((Math.round(year * 10) / 10) - inputYear)) - inputMonth;

  const today = new Date();
  const adjustDays = getAdjustDays(today.getDay());

  mFirst = firstSettlementDate ? new Date(firstSettlementDate) : new Date(today.getTime() + adjustDays * 24 * 60 * 60 * 1000);

  const dateValue = new Date(mFirst);
  mFirst = new Date(dateValue.getFullYear() + inputYear, dateValue.getMonth() + inputMonth, dateValue.getDate());

  if (diff !== 0) {
      const days = diff * mFirst.getDate();
      mFirst.setDate(mFirst.getDate() + Math.floor(days));
  }

  return mFirst;
}

export function calculateEndDateFromYear(year: number, firstSettlementDate: Date): Date {
  let mFirst;
  const inputYear = Math.floor(year);
  const inputMonth = Math.floor(12 * (year - inputYear));
  const diff = (12 * ((Math.round(year * 10) / 10) - inputYear)) - inputMonth;

  const today = new Date();
  const adjustDays = getAdjustDays(today.getDay());

  mFirst = firstSettlementDate ? new Date(firstSettlementDate) : new Date(today.getTime() + adjustDays * 24 * 60 * 60 * 1000);

  const dateValue = new Date(mFirst);
  mFirst = new Date(dateValue.getFullYear() + inputYear, dateValue.getMonth() + inputMonth, dateValue.getDate());

  if (diff !== 0) {
      mFirst.setDate(mFirst.getDate() + diff * mFirst.getDate());
  }

  const minus = -1;
  const endDate = new Date(mFirst);
  mFirst = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + minus);

  return mFirst;
}

export function getAdjustDays(weekDay: number): number {
    if (weekDay === 6) {
      return 5;
    }
    if (weekDay === 7) {
      return 4;
    }

    return 3;
  }

/**
 * Return date for Terms date fields.
 * @param date - the date from terms.
 * If this parameter is null  we will return null.
 */ 
export function getDateValue(date: Date) {
  const result = date ? new Date(LvDateUtil.toUtcDate(date)) : date;
  return result;
}

/**
 * Return First Settlement Date like number.
 * @param firstSettlement - the parameter from override lists.
 * If this parameter is null  we will use Today.
 */
export function getFirstSettlementDate(firstSettlement: any): number {
  const today = new Date(LvDateUtil.toUtcDate(new Date()));
  return firstSettlement ? DateExtensions.getTimeAtStartOfDay(new Date(firstSettlement)) : DateExtensions.getTimeAtStartOfDay(today);
}

/**
 * Return Maturity Date like number.
 * @param maturity - the parameter from override lists.
 * If this parameter is null  we will return null.
 */
export function getMaturityDate(maturity: any): number {
  return maturity ? DateExtensions.getTimeAtStartOfDay(new Date(maturity)) : null;
}