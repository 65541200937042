import { ElementRef, ChangeDetectorRef, ViewRef } from '@angular/core';
import { ResizeHandlerService } from '@lv-core-ui/services';

export type Direction = 'row' | 'column';

/**
 * Pricing util.
 */
export class LvBondPricingUtil {

  valuationInputDirection: Direction;
  labelAndInputBoxDirection: Direction;
  pricingSetsDirection: Direction;
  pricingNewIssueSetsDirection: Direction;

  labelAndInputBoxWidth: number;
  labelWidth: number;

  inputFieldWidth: number;
  marginWidth: number;
  baseNiaGridWidth: number;
  offsetWidth: number;

  twoColumnsMaxWidth: number;
  oneColumnMaxWidth: number;

  elementReactWidth: number;

  pricingSectionMarginBottom: number;

  constructor(
    private _resizeHandlerService: ResizeHandlerService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _isFromExcel: boolean = false
  ) {
    this.valuationInputDirection = 'row';
    this.labelAndInputBoxDirection = 'column';
    this.pricingSetsDirection = 'row';
    this.pricingNewIssueSetsDirection = 'column';

    this.labelAndInputBoxWidth = 104;
    this.labelWidth = 104;

    this.inputFieldWidth = 104;
    this.marginWidth = 10;
    this.offsetWidth = 4;

    this.twoColumnsMaxWidth = 350;
    this.oneColumnMaxWidth = 240;

    this.pricingSectionMarginBottom = 0;
  }

  /**
   * Initialize custom resize handler.
   */
  init(
  ) {
    this._resizeHandlerService.listenTo(this._elementRef, elementRect => {

      if (elementRect.width !== 0) {
        this.elementReactWidth = elementRect.width;
      }

      if (this.elementReactWidth < this.twoColumnsMaxWidth) {
        this.pricingSetsDirection = 'column';
      }
      else {
        this.pricingSetsDirection = 'row';
      }

      if (this.elementReactWidth < this.oneColumnMaxWidth) {
        this.valuationInputDirection = 'column';
        this.labelAndInputBoxDirection = 'row';
        this.labelAndInputBoxWidth = 208;
        this.pricingSectionMarginBottom = 10;
      }
      else {
        this.valuationInputDirection = 'row';
        this.labelAndInputBoxDirection = 'column';
        this.labelAndInputBoxWidth = 104
        this.pricingSectionMarginBottom = 0;
      }

      if (!(this._changeDetectorRef as ViewRef).destroyed) {
        this._changeDetectorRef.detectChanges();
      }
    });
  }
}
