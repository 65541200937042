import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConversionSettlementDescripton, IBaseTermsSettings, IConversionSectionSettings,
  PepsConversionBeforeMaturityTypeDescription } from '@lv-convertible-bond/models';
// tslint:disable-next-line: max-line-length
import { AcquisitionOptionDateInputTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionOptionDateInputType';
// tslint:disable-next-line: max-line-length
import { AcquisitionSharesSettlTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionSharesSettlType';
// tslint:disable-next-line: max-line-length
import { AcquisitionVWAPPeriodInputTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionVWAPPeriodInputType';
import { LvLookupEnum } from '@lv-core-ui/util';
import { IssueAndRedemption } from '@lv-instrument-common/IssueAndRedemption';
import { CurrencyType } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-conversion-section-settings',
  templateUrl: './lv-conversion-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvConversionSectionSettingsComponent implements OnInit {

  @Input() settings: IBaseTermsSettings<IConversionSectionSettings>;
  @Input() overrideDefaults: boolean;

  conversionSettlementLookup: LvLookupEnum;
  conversionPriceCCYLookup: LvLookupEnum;
  rebateCCYLookup: LvLookupEnum;
  conversionRebateCCYLookup: LvLookupEnum;
  conversionBeforeMaturityLookup: LvLookupEnum;
  strikeCCYLookup: LvLookupEnum;
  optionalDateInputTypeLookup: LvLookupEnum;
  acquisitionVWAPPeriodInputTypeLookup: LvLookupEnum;
  acquisitionSharesSettlTypeLookup: LvLookupEnum;

  paralelPepsId: string;
  contigentConversionCheckboxId: string;
  resetableCheckboxId: string;
  keepAccruedCheckboxId: string;
  forfeitCouponOnConversionAtMaturityCheckboxId: string;
  forfeitCouponOnOptionalConversionCheckboxId: string;
  conversionIntoNewSharesCheckboxId: string;
  acquisitionSharesSettlementId: string;
  fxAdjustedConversionAmountCheckboxId: string;
  model: IssueAndRedemption;

  formatThree = '#,###.###';
  decimalsThree = '3';
  decimalsZero = '0';
  formatZero = 'n0';

  constructor() {
    this.conversionSettlementLookup = new LvLookupEnum(ConversionSettlementDescripton);
    this.conversionPriceCCYLookup = new LvLookupEnum(CurrencyType);
    this.rebateCCYLookup = new LvLookupEnum(CurrencyType);
    this.conversionRebateCCYLookup = new LvLookupEnum(CurrencyType);
    this.conversionBeforeMaturityLookup = new LvLookupEnum(PepsConversionBeforeMaturityTypeDescription);
    this.strikeCCYLookup = new LvLookupEnum(CurrencyType);
    this.optionalDateInputTypeLookup = new LvLookupEnum(AcquisitionOptionDateInputTypeDescription);
    this.acquisitionVWAPPeriodInputTypeLookup = new LvLookupEnum(AcquisitionVWAPPeriodInputTypeDescription);
    this.acquisitionSharesSettlTypeLookup = new LvLookupEnum(AcquisitionSharesSettlTypeDescription);
    this.model = new IssueAndRedemption();

    this.paralelPepsId = v4();
    this.contigentConversionCheckboxId = v4();
    this.resetableCheckboxId = v4();
    this.keepAccruedCheckboxId = v4();
    this.forfeitCouponOnConversionAtMaturityCheckboxId = v4();
    this.forfeitCouponOnOptionalConversionCheckboxId = v4();
    this.conversionIntoNewSharesCheckboxId = v4();
    this.acquisitionSharesSettlementId = v4();
    this.fxAdjustedConversionAmountCheckboxId = v4();
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  ngOnInit() {
  }

  getConversionTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
