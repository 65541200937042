import { Component, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { IPricingWidgetState } from '@lv-analytics/models';
import { v4 } from 'uuid';

/**
 * Pricing side panel component.
 */
@Component({
  selector: 'lv-pricing-side-panel',
  templateUrl: './lv-pricing-side-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPricingSidePanelComponent {

  @Input() pricingWidgetState: IPricingWidgetState;
  @Input() isBondPricing: boolean;

  @Output() didCancelEditing: EventEmitter<void>;
  @Output() didSaveChanges: EventEmitter<IPricingWidgetState>;

  displayEstimatesButtonRadioButtonId: string;
  displayEstimatesSectionRadioButtonId: string;
  displayNewIssueButtonRadioButtonId: string;
  displayNewIssueSectionRadioButtonId: string;
  marketDataOverrideCheckBoxId: string;
  displayStockSlippageCheckBoxId: string;

  constructor() {
    this.displayEstimatesButtonRadioButtonId = v4();
    this.displayEstimatesSectionRadioButtonId = v4();
    this.displayNewIssueButtonRadioButtonId = v4();
    this.displayNewIssueSectionRadioButtonId = v4();
    this.marketDataOverrideCheckBoxId = v4();
    this.displayStockSlippageCheckBoxId = v4();

    this.isBondPricing = false;

    this.didSaveChanges = new EventEmitter<IPricingWidgetState>();
    this.didCancelEditing = new EventEmitter<void>();
  }

  /**
   * Saves pricing widget state.
   */
  onSave() {
    this.didSaveChanges.emit(this.pricingWidgetState);
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancelEditing.next();
  }
}
