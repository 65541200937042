import { DateExtensions, LvMath, LvUtil } from '@lv-core-ui/util';

import { StockReferenceType, Frequency, CouponType, ExchangeableType } from '@lv-analytics/models';
import { AdjustmentFormulaType, CashDistribution, CashDistributionDateType, ContingentConversion, ContingentConversionScheduleItem,
  ConversionRatioAdjustment, ConvertibleBondNewIssueDocument, ConvertibleBondSubType, ConvertibleBondTermsDocument,
  DividendProtectionData, DividendProtectionScheduleItem, DividendProtectionTresholdFormulaType,
  DividendProtectionTresholdType, DividendProtectionTresholdValueType, DividendProtectionType, DividendThreshold, ExhangeableParameters,
  IConversionSectionSettings, InstrumentStatus,
  MinAdjustmentType,
  QuickAndFullTermsDocument, Reset, ResetScheduleItem,
  SetupStatusQuickTerms, SpotLevelAtAdjustmentFormulaType,
  StockPriceReference, VariableConversion } from '@lv-convertible-bond/models';
import { PepsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PEPSConversion';
import { PercsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PERCSConversion';
import { CapExpressedAs, ContingentConversionPeriodType, ContingentConversionScheduleItemType, ContingentConversionScheduleTriggerType,
         ContingentConversionTriggerParityLimit, FloorExpressedAs, PepsConversionBeforeMaturityType,
         QuarterType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { PriceTalk } from '@lv-convertible-bond/models/convertible-bond-terms/PriceTalk';
import { ResetAveragingData } from '../models/convertible-bond-terms/ResetAveragingData';
import { LvDateService } from '@lv-core-ui/services';
import { Call, CallMakeWholeData, CallMakeWholeType, CallScheduleItem, CallTriggerPeriodType, CallTriggerType, FixedCouponData, SetupStatus,
  CallValueType, CurrencyType, DiscountRateType, InterpolationType, NoticePeriod, SoftCallData, PutValueType, Put, PutScheduleItem } from '@lv-instrument-common/index';

export class ConvertibleTermsMapHelper {

  constructor(private _lvDateService: LvDateService) {}

  // TODO: Refactor this method to multiple private methods mapping each section individually.
  // tslint:disable-next-line:max-line-length
  getConvertibleBondFromQuickTermsEntry(
    document: QuickAndFullTermsDocument,
    conversionSettings: IConversionSectionSettings,
    isOpenedFromExcel: boolean = false
  ): ConvertibleBondNewIssueDocument {
    // tslint:disable-next-line:max-line-length
    const firstSettlementDate = document.fullTerms.issueAndRedemption.firstSettlementDate;
    document.fullTerms.issueAndRedemption.name = document.quickTerms.issueName;
    document.fullTerms.issueAndRedemption.subType = document.quickTerms.convertibleType;
    document.fullTerms.issueAndRedemption.currencyCode = document.quickTerms.currency;

    document.fullTerms.issueAndRedemption.maturingCurrencyCode = document.quickTerms.currency;
    document.fullTerms.issueAndRedemption.underlyingEquity.currencyCode = document.quickTerms.underlyingCurrency;
    document.fullTerms.issueAndRedemption.redemptionValue = document.quickTerms.redemption;
    if (!isOpenedFromExcel) {
      document.fullTerms.issueAndRedemption.maturityDate = document.quickTerms.maturityDateYears ?
      this.calculateDateFromYear(document.quickTerms.maturityDateYears, firstSettlementDate) : null;
    }
    document.fullTerms.issueAndRedemption.setupStatus = this.getSetupStatusTerms(document.quickTerms.setupStatus);
    document.fullTerms.issueAndRedemption.isPerpetual = document.quickTerms.perpetual;

    if (document.quickTerms.currency && document.quickTerms.underlyingCurrency &&
        document.quickTerms.currency === document.quickTerms.underlyingCurrency) {
      document.fullTerms.issueAndRedemption.fixedFXRate = 1;
    }

    if (!isOpenedFromExcel) {
      document.fullTerms.conversion.schedule = this.generateConversionSchedule(firstSettlementDate, document, conversionSettings);
    }

    if (document.quickTerms.useDividendProtection) {
      if (!document.fullTerms.dividendProtection) {
        this.setDefaultDividendProtection(document.fullTerms);
      }
      document.fullTerms.issueAndRedemption.useDividendProtection = true;
      document.fullTerms.dividendProtection.type = document.quickTerms.dividendProtection;
    } else {
      if (document.fullTerms.dividendProtection) {
        document.fullTerms.dividendProtection.schedule = [];
      }
      document.fullTerms.issueAndRedemption.useDividendProtection = false;
    }

    // tslint:disable-next-line:max-line-length
    if (document.quickTerms.setupStatus === SetupStatusQuickTerms.NewIssue || document.quickTerms.setupStatus === SetupStatusQuickTerms.Fixed) {
      document.fullTerms.other.instrumentStatus = InstrumentStatus.Active;
    } else {
      if (!document.fullTerms.coupon.fixed) {
        document.fullTerms.coupon.fixed = new FixedCouponData();
      }
      document.fullTerms.coupon.type = CouponType.Fixed;
    }

    if (document.quickTerms.puts && document.quickTerms.putPrice) {
      if (!document.fullTerms.put) {
        this.setDefaultPut(document.fullTerms);
      }
      document.fullTerms.issueAndRedemption.isPuttable = true;
      // tslint:disable-next-line:max-line-length
      document.fullTerms.put.scheduleItems = this.setPutScheduleDates(document.quickTerms.puts, document.quickTerms.putPrice, document.fullTerms.put.scheduleItems, firstSettlementDate);
    } else {
      if (document.fullTerms.put) {
        document.fullTerms.put.scheduleItems = [];
      }
      document.fullTerms.issueAndRedemption.isPuttable = false;
    }

    if ((document.quickTerms.softCall && document.quickTerms.softCallTriggers)) {
      if (!document.fullTerms.call) {
        this.setDefaultCall(document.fullTerms);
      }
      document.fullTerms.issueAndRedemption.isCallable = true;
      // tslint:disable-next-line:max-line-length
      document.fullTerms.call.scheduleItems = this.setCallScheduleDates(document.quickTerms.softCall, document.quickTerms.softCallTriggers, document.fullTerms.call.scheduleItems, firstSettlementDate);
    } else {
      if (document.fullTerms.call) {
        document.fullTerms.call.scheduleItems = [];
      }
      document.fullTerms.issueAndRedemption.isCallable = false;
    }

    if (document.quickTerms.callProtection) {
      if (!document.fullTerms.call) {
        this.setDefaultCall(document.fullTerms);
        this.setDefaultCallProtection(document.fullTerms.call);
      }
      document.fullTerms.issueAndRedemption.isCallable = true;

      // tslint:disable-next-line:max-line-length
      document.fullTerms.call.scheduleItems = this.setCallProtectionScheduleDates(document.quickTerms.callProtection, document.fullTerms.call.scheduleItems, firstSettlementDate);
    }
    else {
      let schedule = null;
      if (document.fullTerms.call) {
        schedule = document.fullTerms.call.scheduleItems;
      }
      if (schedule && schedule.find(a => a.trigger === 0)) {
        document.fullTerms.call.scheduleItems = schedule.filter(a => a.trigger !== 0);
      }
    }

    if (document.fullTerms.call) {
      if (document.fullTerms.call.callMakeWhole) {
        document.fullTerms.call.callMakeWhole.type = document.quickTerms.callMakeWholeType;
      } else {
        if (document.quickTerms.callMakeWholeType !== CallMakeWholeType.None) {
          document.fullTerms.call.callMakeWhole = new CallMakeWholeData();
          document.fullTerms.call.callMakeWhole.type = document.quickTerms.callMakeWholeType;
          document.fullTerms.call.callMakeWhole.discountRateType = DiscountRateType.Spread;
        }
      }
      if (document.quickTerms.callMakeWholeType === CallMakeWholeType.CouponPV) {
        document.fullTerms.call.callMakeWhole.discountSpread = document.quickTerms.callMakeWholeSpread;
      }
      document.fullTerms.call.recieveMakeWhole = document.quickTerms.callMakeWholeType !== CallMakeWholeType.None;
    }

    if ( document.quickTerms.convertibleType === ConvertibleBondSubType.Regular &&
        (document.quickTerms.hyperIncrementalFactor || document.quickTerms.hyperStrike)) {
          if (!document.fullTerms.conversion.variableConversion) {
            document.fullTerms.conversion.variableConversion = new VariableConversion();
            document.fullTerms.conversion.variableConversion.strike = 100;
            document.fullTerms.conversion.variableConversion.incrementalShareFactor = 100;
            document.fullTerms.conversion.variableConversion.strikeCurrency = CurrencyType.Convertible;
          }
          document.fullTerms.conversion.isVariableConversion = true;
          document.fullTerms.conversion.variableConversion.incrementalShareFactor = document.quickTerms.hyperIncrementalFactor;
          document.fullTerms.conversion.variableConversion.strike = document.quickTerms.hyperStrike;
        } else {
          document.fullTerms.conversion.isVariableConversion = false;
          if (!!document.fullTerms.conversion.variableConversion) {
            document.fullTerms.conversion.variableConversion.incrementalShareFactor = null;
            document.fullTerms.conversion.variableConversion.strike = null;
          }
      }

    if (LvMath.isNumber(document.quickTerms.coupon)) {
      if (LvMath.isZeroNumber(parseFloat(document.quickTerms.coupon.toString()))) {
        document.fullTerms.coupon.type = CouponType.ZeroCoupon;
        document.fullTerms.coupon.fixed.rate = 0;
      } else {
        if (!document.fullTerms.coupon.fixed) {
          document.fullTerms.coupon.fixed = new FixedCouponData();
        }
        document.fullTerms.coupon.type = CouponType.Fixed;
        document.fullTerms.coupon.fixed.rate = parseFloat(document.quickTerms.coupon.toString());
      }
    }

    if (document.quickTerms.conversionPrice > 0) {
      if (document.fullTerms.conversion.schedule && document.fullTerms.conversion.schedule.length > 0) {
        document.fullTerms.conversion.schedule[0].conversionPrice = document.quickTerms.conversionPrice;
        // tslint:disable-next-line:max-line-length
        const termsDoc = Object.assign(new ConvertibleBondTermsDocument(), document.fullTerms);
        document.fullTerms.conversion.schedule[0].ratio = termsDoc.calculateConversionRatioFromCP(document.quickTerms.conversionPrice);
      } else {
        document.fullTerms.conversion.schedule = [];
        document.fullTerms.conversion.schedule.push({
          startDate: firstSettlementDate,
          endDate: document.fullTerms.issueAndRedemption.maturityDate,
          conversionPrice: document.quickTerms.conversionPrice,
          conversionPriceCCY: CurrencyType.Convertible,
          rebate: 0,
          rebateCurrencyType: null,
          ratio: document.fullTerms.issueAndRedemption.nominalValue / document.quickTerms.conversionPrice
        });
      }
    }

    if (document.quickTerms.convertibleType === ConvertibleBondSubType.PEPS) {
      if (!document.fullTerms.conversion.pepsConversion) {
        document.fullTerms.conversion.pepsConversion = new PepsConversion();
        document.fullTerms.conversion.pepsConversion.conversionBeforeMaturityType = PepsConversionBeforeMaturityType.MinimumRatio;
        document.fullTerms.conversion.pepsConversion.conversionRebateCurrency = CurrencyType.Convertible;
      }
      document.fullTerms.conversion.pepsConversion.higherStrike = document.quickTerms.higherStrike ? document.quickTerms.higherStrike : 0;
      document.fullTerms.conversion.pepsConversion.lowerStrike = document.quickTerms.lowerStrike ? document.quickTerms.lowerStrike : 0;
      // tslint:disable-next-line:max-line-length
      document.fullTerms.conversion.pepsConversion.maxRatio = document.quickTerms.lowerStrike > 0 ? document.fullTerms.issueAndRedemption.nominalValue / document.quickTerms.lowerStrike : 0;
      // tslint:disable-next-line:max-line-length
      document.fullTerms.conversion.pepsConversion.customMinRatio = document.quickTerms.higherStrike > 0 ? document.fullTerms.issueAndRedemption.nominalValue / document.quickTerms.higherStrike : 0;
      document.fullTerms.conversion.useAcquisitionSharesSettlement = false;
    }

    if (document.quickTerms.convertibleType === ConvertibleBondSubType.PERCS) {
      if (!document.fullTerms.conversion.percsConversion) {
        document.fullTerms.conversion.percsConversion = new PercsConversion();
      }
      document.fullTerms.conversion.percsConversion.conversionRatio = document.quickTerms.conversionRatio;
      document.fullTerms.conversion.percsConversion.capPrice = document.quickTerms.conversionRatioCap;
      document.fullTerms.conversion.useAcquisitionSharesSettlement = false;
    }

    if (document.quickTerms.coCoEndDate && document.quickTerms.coCoTrigger) {
      if (!document.fullTerms.conversion.contingentConversion) {
        document.fullTerms.conversion.contingentConversion = new ContingentConversion();
        document.fullTerms.conversion.contingentConversion.schedule = [];
        document.fullTerms.conversion.contingentConversion.convertibleOnCall = true;
        document.fullTerms.conversion.contingentConversion.quarterType = QuarterType.Calendar;
        document.fullTerms.conversion.contingentConversion.triggerPeriodType = ContingentConversionPeriodType.NOutOfMDays;
        document.fullTerms.conversion.contingentConversion.cbPriceTriggerParityLimit = ContingentConversionTriggerParityLimit.None;
      }
      document.fullTerms.conversion.isContigentConversion = true;
      // tslint:disable-next-line:max-line-length
      const startDate = document.fullTerms.conversion.schedule && document.fullTerms.conversion.schedule.length > 0 ? document.fullTerms.conversion.schedule[0].startDate : firstSettlementDate;
      // tslint:disable-next-line:max-line-length
      document.fullTerms.conversion.contingentConversion.schedule = this.setCoCoSchedule(document.quickTerms.coCoEndDate, document.quickTerms.coCoTrigger, document.fullTerms.conversion.contingentConversion.schedule, firstSettlementDate, startDate);
    } else {
      if (document.fullTerms.conversion.contingentConversion) {
        document.fullTerms.conversion.contingentConversion.schedule = [];
      }
      document.fullTerms.conversion.isContigentConversion = false;
    }

    if (!document.fullTerms.issueAndRedemption.exhangeableParameters) {
      document.fullTerms.issueAndRedemption.exhangeableParameters = new ExhangeableParameters();
    }

    if (!document.quickTerms.exchangeable)  {
      document.fullTerms.issueAndRedemption.exhangeableParameters.exhangeableType = ExchangeableType.NonExchangeable;
    } else {
      if (document.quickTerms.pledgedShares) {
        document.fullTerms.issueAndRedemption.exhangeableParameters.exhangeableType = ExchangeableType.PledgedShares;
        document.fullTerms.issueAndRedemption.exhangeableParameters.percentOfPledgedShares = document.quickTerms.pledgedShares;
      } else {
        document.fullTerms.issueAndRedemption.exhangeableParameters.exhangeableType = ExchangeableType.RecoveryValueMax;
      }
    }

    if (document.quickTerms.reset) {
      if (!document.fullTerms.conversion.reset) {
        this.setDefaultReset(document.fullTerms);
      }
      if (document.fullTerms.conversion.reset && !document.fullTerms.conversion.reset.resetAveraging) {
        document.fullTerms.conversion.reset.resetAveraging = new ResetAveragingData();
      }
      document.fullTerms.conversion.isResetable = true;
      // tslint:disable-next-line:max-line-length
      document.fullTerms.conversion.reset.schedule = this.setResetSchedule(document.quickTerms.reset, document.quickTerms.resetFloor, document.quickTerms.resetMultiplier, document.fullTerms.conversion.reset.schedule, firstSettlementDate);
    } else {
      if (document.fullTerms.conversion.reset) {
        document.fullTerms.conversion.reset.schedule = [];
      }
      document.fullTerms.conversion.isResetable = false;
    }

    if (!LvMath.isZeroNumber(document.quickTerms.dividendTresholdValue) && document.quickTerms.dividendTresholdType) {
      const dividendTresholdValue = parseFloat(document.quickTerms.dividendTresholdValue.toString());

      if (!document.fullTerms.dividendProtection) {
        this.setDefaultDividendProtection(document.fullTerms);
      }

      if (dividendTresholdValue !== 0) {
        document.fullTerms.dividendProtection.tresholdType = DividendProtectionTresholdType.FlatForPeriod;
        document.fullTerms.dividendProtection.tresholdPeriodFrequency = document.quickTerms.tresholdFrequency;

        if (document.quickTerms.tresholdGrowth) {
          document.fullTerms.dividendProtection.tresholdType = DividendProtectionTresholdType.Schedule;
        }
        const maturity = document.fullTerms.issueAndRedemption.isPerpetual ? 10 : document.quickTerms.maturityDateYears;
        document.fullTerms.dividendProtection.schedule = this.setDividendProtectionSchedule(dividendTresholdValue,
                                            document.quickTerms.dividendTresholdType,
                                            document.quickTerms.tresholdGrowth, firstSettlementDate,
                                            maturity, document.fullTerms.dividendProtection.schedule,
                                            document.fullTerms.dividendProtection.tresholdPeriodFrequency);
      }
      else {
        document.fullTerms.dividendProtection.tresholdType = DividendProtectionTresholdType.FullProtection;
        document.fullTerms.dividendProtection.schedule = [];
      }
    }
    else {
      if (!document.fullTerms.dividendProtection) {
        this.setDefaultDividendProtection(document.fullTerms);
      }

      if (document.quickTerms.dividendTresholdValue !== 'Schedule') {
        document.fullTerms.dividendProtection.schedule = [];
        document.fullTerms.dividendProtection.tresholdType = DividendProtectionTresholdType.FullProtection;
      }
    }

    if (document.quickTerms.tresholdFrequency) {
      if (!document.fullTerms.dividendProtection) {
        this.setDefaultDividendProtection(document.fullTerms);
      }

      document.fullTerms.dividendProtection.tresholdPeriodFrequency = document.quickTerms.tresholdFrequency;
    }

    if (document.quickTerms.priceTalk) {
      const newissuePrice = (document.fullTerms as ConvertibleBondNewIssueDocument);
      if (!newissuePrice.priceTalk) {
        newissuePrice.priceTalk = new PriceTalk();
      }
      if (!newissuePrice.stockPriceReference) {
        newissuePrice.stockPriceReference = new StockPriceReference();
      }

      document.fullTerms.issueAndRedemption.issueValue = (document.quickTerms.priceTalk.issuePriceWorst + document.quickTerms.priceTalk.issuePriceBest) / 2;

      // tslint:disable-next-line:max-line-length
      newissuePrice.priceTalk.couponBest = document.quickTerms.priceTalk.couponBest ? document.quickTerms.priceTalk.couponBest : 0;
      newissuePrice.priceTalk.couponWorst = document.quickTerms.priceTalk.couponWorst ? document.quickTerms.priceTalk.couponWorst : 0;

      // tslint:disable-next-line:max-line-length
      newissuePrice.priceTalk.issueYieldBest = document.quickTerms.priceTalk.issueYieldBest ? document.quickTerms.priceTalk.issueYieldBest : 0;
      // tslint:disable-next-line:max-line-length
      newissuePrice.priceTalk.issueYieldWorst = document.quickTerms.priceTalk.issueYieldWorst ? document.quickTerms.priceTalk.issueYieldWorst : 0;

      newissuePrice.priceTalk.redemptionValueBest = document.quickTerms.priceTalk.redemptionValueBest;
      newissuePrice.priceTalk.redemptionValueWorst = document.quickTerms.priceTalk.redemptionValueWorst;

      newissuePrice.priceTalk.issuePriceBest = document.quickTerms.priceTalk.issuePriceBest;
      newissuePrice.priceTalk.issuePriceWorst = document.quickTerms.priceTalk.issuePriceWorst;

      newissuePrice.priceTalk.higherStrikePremiumBest = document.quickTerms.priceTalk.higherStrikePremiumBest;
      newissuePrice.priceTalk.higherStrikePremiumWorst = document.quickTerms.priceTalk.higherStrikePremiumWorst;

      newissuePrice.priceTalk.premiumBest = document.quickTerms.priceTalk.premiumBest;
      newissuePrice.priceTalk.premiumWorst = document.quickTerms.priceTalk.premiumWorst;

      // tslint:disable-next-line:max-line-length
      if (document.fullTerms.coupon && (newissuePrice.priceTalk.couponWorst > 0 || newissuePrice.priceTalk.couponBest > 0)) {
        if (!document.fullTerms.coupon.fixed) {
          document.fullTerms.coupon.fixed = new FixedCouponData();
          document.fullTerms.coupon.fixed.stepUpCouponSchedule = [];
        }

        if (document.fullTerms.coupon.type === CouponType.Floating ||
            document.fullTerms.coupon.type === CouponType.Custom) {
            document.fullTerms.coupon.type = CouponType.Fixed;
        }
        if (document.fullTerms.issueAndRedemption.setupStatus === SetupStatus.NewIssue) {
          document.fullTerms.coupon.fixed.rate = (newissuePrice.priceTalk.couponBest + newissuePrice.priceTalk.couponWorst) / 2;
        }
      }

      newissuePrice.priceTalk.spreadBest = 0;
      newissuePrice.priceTalk.spreadWorst = 0;

      if (document.quickTerms.fixedStockRef) {
        newissuePrice.stockPriceReference.referenceType = StockReferenceType.Fixed;
      } else {
        newissuePrice.stockPriceReference.referenceType = StockReferenceType.NotFixed;
      }

    }
    return document.fullTerms;
  }

  //#region DividendProtection

  setDefaultDividendProtection(terms: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument) {
    terms.dividendProtection = new DividendProtectionData();
    terms.dividendProtection.type = DividendProtectionType.ConversionRatioAdjustment;
    terms.dividendProtection.tresholdType = DividendProtectionTresholdType.FullProtection;
    terms.dividendProtection.tresholdPeriodFrequency = Frequency.Annual;
    terms.dividendProtection.cashTresholdCurrencyType = CurrencyType.Underlying;
    terms.dividendProtection.tresholdFormulaType = DividendProtectionTresholdFormulaType.Single;
    terms.dividendProtection.minAdjustmentType = MinAdjustmentType.None;
    terms.dividendProtection.addCarryForwardOnConversion = true;
    terms.dividendProtection.schedule = [];
    terms.dividendProtection.conversionRatioAdjustment = {
      formulaType: AdjustmentFormulaType.Type2,
      spotLevelAtAdjustmentFormula: SpotLevelAtAdjustmentFormulaType.BeforeDividendExDate,
    } as ConversionRatioAdjustment;
    terms.dividendProtection.cashDistribution = {} as CashDistribution;
    terms.dividendProtection.cashDistribution.percentOfPassThrough = 100;
    terms.dividendProtection.cashDistribution.dateType = CashDistributionDateType.AtConversion;
  }

  // tslint:disable-next-line:max-line-length
  setDividendProtectionSchedule(thresholdValue: number, thresholdType: DividendThreshold, growth: number, firstDate: Date, maturityNumber: number, schedule: DividendProtectionScheduleItem[], frequency: Frequency): DividendProtectionScheduleItem[] {
    let thresholdValues = [];
    const lengthFrequency = this.mapThresholdFrequency(frequency);
    const length = lengthFrequency * maturityNumber;
    const divideNumber = this.mapThresholdFrequencyMonths(frequency);

    const thresholdValueType = this.mapThresholdValueType(thresholdType);
    thresholdValues = this.getThresholdValues(thresholdValue, growth, length);
    schedule = [];

    if (growth && growth > 0) {
      for (let i = 0; i < length; i++) {
        schedule.push({
          startDate: this.calculateDateForMonth(i * divideNumber, firstDate),
          tresholdValue: growth > 0 ? thresholdValues[i] : thresholdValue,
          tresholdValueType: thresholdValueType,
          triggerValue: 0,
          tresholdValue2: 0,
          tresholdValue2Type: thresholdValueType,
          triggerValue2: 0
        });
      }
    } else {
      schedule.push({
        startDate: this.calculateDateFromYear(0, firstDate),
        tresholdValue: thresholdValue,
        tresholdValueType: thresholdValueType,
        triggerValue: 0,
        tresholdValue2: 0,
        tresholdValue2Type: thresholdValueType,
        triggerValue2: 0
      });
    }

    return schedule;
  }

  getThresholdValues(thresholdValue: number, growth: number, length: number): number[] {
    const thresholdValues = [];

    for (let i = 0; i < length; i++) {
      if (i === 0 ) {
        thresholdValues.push(thresholdValue);
      }
      else {
        const percent = thresholdValue * growth / 100;
        thresholdValue = thresholdValue + percent;
        thresholdValues.push(thresholdValue);
      }
    }

    return thresholdValues;
  }

  setStartEndDate(document: QuickAndFullTermsDocument, conversionSettings: IConversionSectionSettings): ConvertibleBondNewIssueDocument {
    const firstSettlementDate = document.fullTerms.issueAndRedemption.firstSettlementDate;

    document.fullTerms.conversion.schedule = this.generateConversionSchedule(firstSettlementDate, document, conversionSettings);

    return document.fullTerms;
  }

  /**
   * Changed Call yield frequency and Call yield day count field based on changing Coupon Frequncy and Coupon Day Count from Coupon tab
   */
  setCallYieldFields(fullTerms: ConvertibleBondNewIssueDocument): ConvertibleBondNewIssueDocument {
    if (fullTerms.issueAndRedemption.isCallable && fullTerms.call) {
      fullTerms.call.yieldFrequency = fullTerms.coupon.frequency;
      fullTerms.call.yieldDayCount = fullTerms.coupon.dayCount;
    }

    return fullTerms;
  }

  /**
   * Return date for Dividend schedule in case when we have growt.
   * @param month - month for recalculate.
   * @param firstSettlementDate - First settlement date
   */
  calculateDateForMonth(month: number, firstSettlementDate: Date): Date {
    const today = new Date();
    const adjustDays = this.getAdjustDays(today.getDay());

    const dateValue = new Date(firstSettlementDate);
    const todayAdjust = new Date();

    const monthDate = new Date(dateValue.getFullYear(), dateValue.getMonth() + month, dateValue.getDate());
    const adjustDate = new Date(todayAdjust.getFullYear(), todayAdjust.getMonth() + month, todayAdjust.getDate() + adjustDays);
    const mFirst = firstSettlementDate ? monthDate : adjustDate;

    return mFirst;
  }

  private generateConversionSchedule(
    firstSettlementDate: Date,
    document: QuickAndFullTermsDocument,
    conversionSettings: IConversionSectionSettings
  ) {
    const maturityDate = document.fullTerms.issueAndRedemption.maturityDate;
    if (!maturityDate) {
      if (document.fullTerms.issueAndRedemption.isPerpetual) {
        return document.fullTerms.conversion.schedule;
      }
 
      return;
    }

    const endDate = this._lvDateService.getUtcDateValue(maturityDate);
    const endDateConversion = this._lvDateService.getUtcDateValue(new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() -
        (conversionSettings?.conversionEndDate !== null
        && conversionSettings?.conversionEndDate !== undefined
        ? conversionSettings?.conversionEndDate : 7)));


    if (!document.fullTerms.conversion.schedule) {
      document.fullTerms.conversion.schedule = [];
    }

    if (document.fullTerms.issueAndRedemption && document.fullTerms.conversion.schedule.length === 0) {
      document.fullTerms.conversion.schedule.push({
        startDate: DateExtensions.addDays(firstSettlementDate, conversionSettings?.conversionStartDate),
        endDate: endDateConversion,
        conversionPrice: 0,
        conversionPriceCCY: conversionSettings.conversionPriceCCY,
        rebate: 0,
        rebateCurrencyType: conversionSettings.rebateCCY,
        ratio: 0
      });
    } else if (document.fullTerms.issueAndRedemption && document.fullTerms.conversion.schedule.length > 0) {
      document.fullTerms.conversion.schedule[document.fullTerms.conversion.schedule.length - 1] = {
        ...document.fullTerms.conversion.schedule[document.fullTerms.conversion.schedule.length - 1],
        endDate: endDateConversion
      };
    }
    return document.fullTerms.conversion.schedule;
  }

  private mapThresholdValueType(thresholdType: DividendThreshold): DividendProtectionTresholdValueType {

    switch (thresholdType) {
      case DividendThreshold.Absolute:
      return DividendProtectionTresholdValueType.Cash;
      case DividendThreshold.Percentage:
      return DividendProtectionTresholdValueType.PercentOfStockPrice;
      case DividendThreshold.PercentOfEps:
      return DividendProtectionTresholdValueType.PercentOfEPS;
      case DividendThreshold.CashPerBond:
        return DividendProtectionTresholdValueType.CashPerBond;
    }

    return DividendProtectionTresholdValueType.Cash;
  }
  //#endregion

  //#region Reset
  // tslint:disable-next-line:max-line-length
  private setResetSchedule(years: string, floors: string, multipliers: string, schedule: ResetScheduleItem[], firstDate: Date): ResetScheduleItem[] {
    const yearsArray = years.split(',').map(Number);
    const floorsArray = floors.split(',').map(Number);
    const multipliersArray = multipliers ? multipliers.split(',').map(Number) : [];
    const cloneResetRows = [...schedule];
    schedule = [];

    yearsArray.forEach((value, i) => {
      if (LvMath.isNumber(value)) {
        schedule.push({
          startDate: this.calculateDateFromYear(value, firstDate),
          endDate: cloneResetRows[i] ? cloneResetRows[i].endDate : null,
          cap: 100,
          floor: floorsArray[i],
          multiplier: multipliersArray.length > 0 ? multipliersArray[i] : 1,
          trigger: cloneResetRows[i] ? cloneResetRows[i].trigger : 0
        });
      }
  });

    return schedule;
  }

  private setDefaultReset(terms: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument) {
    terms.conversion.reset = new Reset();
    terms.conversion.reset.schedule = [];
    terms.conversion.reset.resetCurrencyType = CurrencyType.Convertible;
    terms.conversion.reset.conversionPriceCurrencyType = CurrencyType.Convertible;
    terms.conversion.reset.floorExpressedRelativeTo = FloorExpressedAs.PercOfInitialConversionPrice;
    terms.conversion.reset.capExpressedRelativeTo = CapExpressedAs.PercOfInitialConversionPrice;
  }
  //#endregion

  //#region Put
  private setPutScheduleDates(years: string, prices: string, schedule: PutScheduleItem[], firstDate: Date): PutScheduleItem[] {
    const yearsArray = years.split(',').map(Number);
    const pricesArray = prices.split(',').map(Number);
    const cloneSchedule = schedule ? [...schedule] : [];
    schedule = [];

    yearsArray.forEach((value, i) => {
      if (LvMath.isNumber(value)) {
        schedule.push({
          startDate: this.calculateDateFromYear(value, firstDate),
          endDate: cloneSchedule[i] ? cloneSchedule[i].endDate : null,
          price: pricesArray[i] ? pricesArray[i] : 0,
          trigger: cloneSchedule[i] ? cloneSchedule[i].trigger : 0,
          tranche: cloneSchedule[i]?.tranche,
          index: cloneSchedule[i]?.index
        });
      }
    });

    return schedule;
  }

  private setDefaultPut(terms: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument) {
    terms.put = new Put();
    terms.put.valueType = PutValueType.PerOfPar;
    terms.put.noticePeriod = NoticePeriod.Calendar;
    terms.put.keepAccrued = true;
    terms.put.scheduleItems = [];
  }
  //#endregion

  //#region Call
  private setCallScheduleDates(years: string, triggers: string, schedule: CallScheduleItem[], firstDate: Date): CallScheduleItem[] {
    const yearsArray = years.split(',').map(Number);
    const triggersArray = triggers.split(',').map(Number);
    const cloneSoftRows = schedule.filter(event => event.trigger !== 0);
    schedule = schedule.filter(event => event.trigger === 0);

    if (yearsArray.length === 1) {
      yearsArray.forEach((value, i) => {
        if (LvMath.isNumber(value)) {
          schedule.push({
            startDate: this.calculateDateFromYear(value, firstDate),
            interpolation: InterpolationType.Staircase,
            price: 100,
            trigger: triggersArray[i],
            endDate: cloneSoftRows[i] ? cloneSoftRows[i].endDate : null
          });
        }
      });
    } else {
      yearsArray.forEach((value, i) => {
        if (LvMath.isNumber(value)) {
          schedule.push({
            startDate: this.calculateDateFromYear(value, firstDate),
            interpolation: InterpolationType.Staircase,
            price: 100,
            trigger: triggersArray[i],
            endDate: yearsArray[i + 1] ? this.calculateDateFromYear(yearsArray[i + 1], firstDate, true) : null
          });
        }
      });
    }

    return schedule.sort(LvUtil.sortBy('startDate', false));
  }

  private setCallProtectionScheduleDates(years: string, schedule: CallScheduleItem[], firstDate: Date): CallScheduleItem[] {
    const yearsArray = years.split(',').map(Number);
    const cloneProtectionRows = schedule.filter(event => event.trigger === 0);
    schedule = schedule.filter(event => event.trigger !== 0);

    yearsArray.forEach((value, i) => {
      if (LvMath.isNumber(value)) {
        schedule.push({
          startDate: this.calculateDateFromYear(value, firstDate),
          interpolation: InterpolationType.Staircase,
          price: 100,
          trigger: 0,
          endDate: cloneProtectionRows[i] ? cloneProtectionRows[i].endDate : null
        });
      }
    });

    return schedule.sort(LvUtil.sortBy('startDate', false));
  }

  private setDefaultCall(terms: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument) {
    terms.call = new Call();
    terms.call.noticePeriod = NoticePeriod.Calendar;
    terms.call.valueType = CallValueType.PerOfPar;
    terms.call.scheduleItems = [];
    terms.call.considerationDaysBusinessCalendar = NoticePeriod.Calendar;
    terms.call.softCall = new SoftCallData();
    terms.call.softCall.currencyOfTrigger = CurrencyType.Convertible;
    terms.call.softCall.triggerType = CallTriggerType.AsPerOfPar;
    terms.call.softCall.triggerPeriodType = CallTriggerPeriodType.NOutOfMDays;
  }

  private setDefaultCallProtection(call: Call) {
    call.keepAccruedRedemption = true;
    call.notice = 30;
  }
  //#endregion

  //#region ContingentConversion
  // tslint:disable-next-line:max-line-length
  private setCoCoSchedule(years: string, triggers: string, schedule: ContingentConversionScheduleItem[], firstDate: Date, startDate: Date): ContingentConversionScheduleItem[] {
    const yearsArray = years.split(',').map(Number);
    const triggersArray = triggers.split(',').map(Number);
    const cloneCoCoRows = [...schedule];
    schedule = [];

    if (yearsArray.length === 1) {
      yearsArray.forEach((value, i) => {
        if (LvMath.isNumber(value)) {
          schedule.push({
            startDate: startDate,
            endDate: this.calculateDateFromYear(value, startDate),
            type: ContingentConversionScheduleItemType.Quarterly_NextQuarter,
            triggerType: ContingentConversionScheduleTriggerType.AsPercentOfPar,
            initialTrigger: triggersArray[i],
            changeRate: cloneCoCoRows[i] ? cloneCoCoRows[i].changeRate : 0,
            triggerCapFloor: cloneCoCoRows[i] ? cloneCoCoRows[i].triggerCapFloor : 0,
          });
        }
      });
    } else {
      yearsArray.forEach((value, i) => {
        if (LvMath.isNumber(value)) {
          schedule.push({
            startDate: this.calculateDateFromYear(value, startDate),
            endDate: yearsArray[i + 1] ? this.calculateDateFromYear(yearsArray[i + 1], startDate, true) : null,
            type: ContingentConversionScheduleItemType.Quarterly_NextQuarter,
            triggerType: ContingentConversionScheduleTriggerType.AsPercentOfPar,
            initialTrigger: triggersArray[i],
            changeRate: cloneCoCoRows[i] ? cloneCoCoRows[i].changeRate : 0,
            triggerCapFloor: cloneCoCoRows[i] ? cloneCoCoRows[i].triggerCapFloor : 0,
          });
        }
      });
    }

    return schedule;
  }

  /**
   * Return date for from input Year from Quick Terms
   * @param year - year for recalculate.
   * @param firstSettlementDate - First settlement date
   * @param isEndDate - boolean is EndDate should be calculated, in that case calculated date we need decrease for 1 day
   */
  private calculateDateFromYear(year: number, firstSettlementDate: Date, isEndDate: boolean = false): Date {
    let mFirst;
    const inputYear = Math.floor(year);
    const inputMonth = Math.floor(12 * (year - inputYear));
    const diff = (12 * ((Math.round(year * 10) / 10) - inputYear)) - inputMonth;

    const today = new Date();
    const adjustDays = this.getAdjustDays(today.getDay());

    mFirst = firstSettlementDate ? new Date(firstSettlementDate) : new Date(today.getTime() + adjustDays * 24 * 60 * 60 * 1000);

    const dateValue = new Date(mFirst);
    mFirst = new Date(dateValue.getFullYear() + inputYear, dateValue.getMonth() + inputMonth, dateValue.getDate());

    if (isEndDate) {
        if (diff !== 0) {
            mFirst.setDate(mFirst.getDate() + diff * mFirst.getDate());
        }
        mFirst.setDate(mFirst.getDate() - 1);
    }
    else {
        if (diff !== 0) {
            const days = diff * mFirst.getDate();
            mFirst.setDate(mFirst.getDate() + Math.floor(days));
        }
    }

    return mFirst;
  }

  private getSetupStatusTerms(setupStatus: SetupStatusQuickTerms): SetupStatus {
    return setupStatus === SetupStatusQuickTerms.NewIssue ? SetupStatus.NewIssue : SetupStatus.PartiallyCompleted;
  }

  mapThresholdFrequency(frequency: Frequency): number {
    switch (frequency) {
      case Frequency.Annual:
        return 1;
      case Frequency.SemiAnnual:
        return 2;
      case Frequency.Quarterly:
        return 4;
      case Frequency.Bimonthly:
        return 6;
      case Frequency.Monthly:
        return 12;
    }

    return 1;
  }

  mapThresholdFrequencyMonths(frequency: Frequency): number {
    switch (frequency) {
      case Frequency.Annual:
        return 12;
      case Frequency.SemiAnnual:
        return 6;
      case Frequency.Quarterly:
        return 3;
      case Frequency.Bimonthly:
        return 2;
      case Frequency.Monthly:
        return 1;
    }

    return 12;
  }

  private getAdjustDays(weekDay: number): number {
    if (weekDay === 6) {
      return 5;
    }
    if (weekDay === 7) {
      return 4;
    }

    return 3;
  }
}
