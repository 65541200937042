import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, AfterViewInit, OnDestroy,
  Optional, AfterViewChecked} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LvNotificationManagerService, LvLoggerService } from '@lv-core-ui/services';
import { InstrumentMonitorService } from '@lv-instrument-monitor/instrument-monitor.service';
import { IInstrumentLookup } from '@lv-instrument-monitor/models';
import { InstrumentComponent } from '@lv-views/internal/instrument/instrument.component';
import { WebViewService } from '@lv-core-ui/services/webview2/webview2-service';
import { LvExcelService } from 'src/app/modules/excel/services/excel-service';
import { InstrumentSourceType } from '@lv-instrument-monitor/models/enums/instrument-source';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvSharedUserActivityService } from '@lv-shared/services';
import { IOpenInExcelFormEvent, IOpenInstrumentEvent } from '@lv-shared/models';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'external-instrument',
  templateUrl: './external-instrument-view.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LvNotificationManagerService,
    WebViewService
  ]
})
export class ExternalInstrumentViewComponent implements OnInit, OnDestroy, AfterViewChecked  {

  @ViewChild(InstrumentComponent, { static: true }) instrumentComponent: InstrumentComponent;

  get isOpenedFromExcel(): boolean {
    return !!this._excelService?.isInitialized();
  }

  instrument: IInstrumentLookup;
  identifierType: string;
  originSource: string;
  logUserActivity: boolean;
  sub: Subscription;
  constructor(
    private _imService: InstrumentMonitorService,
    private _activatedRoute: ActivatedRoute,
    private _logger: LvLoggerService,
    private _csService: CompanyAndUserSettingsService,
    private _userActivityService: LvSharedUserActivityService,
    @Optional() private _excelService: LvExcelService) { }

  /**
   * Method activated on component initialization.*
   * Extracts instrument identifier from url and
   * tries to find and load requested instrument.
   */
  async ngOnInit() {
    this.sub = this._activatedRoute.params.subscribe(async params => {
      const identifier = params['id'] ?? this._activatedRoute.snapshot.queryParamMap.get('id');
      this._logger.log(`external-instrument identifier: ${identifier}`);

      let instSource = this.getInstrumentSource(params['instSource']);

      if (!instSource) {
        const otherSettings = await this._csService.getOtherEnvironmentSettings();
        instSource = otherSettings.instrumentSourceSettings.instrumentSource;
      }

      this.instrument = {} as IInstrumentLookup;

      if (identifier.toLowerCase() !== 'draft') {
        try {
          this.instrument = await this._imService.lookupService.getInstrument(identifier, InstrumentSourceType[instSource]);
        }
        catch (error) {
          this._logger.logError(error);
        }
      }
      else {
        this.instrument.isPrivateInstrument = true;
        this.instrument.lwsIdentifier = '';
        //this.instrument.leversysId = '';
        this.instrument.text = '';
      }

      if (this.instrument || identifier.toLowerCase() !== 'draft') {
        this.instrumentComponent.state = { ... this.instrumentComponent.state,          
          leversysId: this.instrument.leversysId,
          isPrivateInstrument: this.instrument.isPrivateInstrument };
        

        this.instrumentComponent.state.region = this.instrument.region;

        this.instrumentComponent.loadInstrument(this.instrument.text, this.instrument.region);
      }
      else {
        await this._logger.logError(`externel-instrument: instrument not found for identifier: ${identifier}`);
        alert('Instrument not found!');
      }

      const isOpenFromEmail = this._activatedRoute.snapshot.queryParamMap.get('openFromEmail');
      this.originSource = isOpenFromEmail ? 'From Email' :
        (this._excelService?.isInitialized() ? 'From Excel Form' : 'From Open Instrument');
      sessionStorage.setItem('originSource', this.originSource);

      if (identifier.toUpperCase() === 'DRAFT') {
        this.identifierType = 'DRAFT';
      }
      else if (identifier && identifier.startsWith('LVS')) {
        this.identifierType = 'LVS_ID';
      }
      else {
        this.identifierType = 'ISIN';
      }

      this.logUserActivity = true;
    });
  }

  ngAfterViewChecked(): void {
    if (this.logUserActivity && this.instrumentComponent._presenter.cHelper.convertible) {
      if (this._excelService?.isInitialized()) {
        this._userActivityService.logOpenInExcelFormEvent({
          bondCountry: this.instrumentComponent._presenter.cHelper.convertible.countryCode,
          bondType:  this.instrumentComponent._presenter.cHelper.convertible.status,
          isCustomInstrument: this.instrument?.isPrivateInstrument,
          identifierType: this.identifierType,
          overrides: this._excelService.getData().fields.reduce((map, obj) => {
            map[obj.alias] = obj.value != null;
            return map;
          }, {})
        } as IOpenInExcelFormEvent);
      }
      else {
        this._userActivityService.logOpenInstrumentEvent({
          isCustomInstrument: this.instrument?.isPrivateInstrument,
          identifierType: this.identifierType,
          originSource: this.originSource
        } as IOpenInstrumentEvent);
      }

      this.logUserActivity = false;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getInstrumentSource(instrumentSource: string): string {
    if (!!instrumentSource && instrumentSource.toUpperCase() === 'CUSTOM' || !!instrumentSource && instrumentSource.toUpperCase() === 'C') {
      return 'Custom';
    } else {
     return 'Leversys';
    }
  }
}
