import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IEquityDividendsSettingsContent } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-dividends-settings/equity-dividends-settings-content/equity-dividends-settings-content';

@Component({
  selector: 'lv-dividends-equity-settings',
  templateUrl: './lv-dividends-equity-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvDividendsEquitySettingsComponent {
  @Input() settings: IEquityDividendsSettingsContent;

  @HostBinding('class.lv-dividends-equity-settings')
  get isLvVolatilityEquitySettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns flag- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
