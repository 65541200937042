import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBondWithholdingTaxSettings } from '@lv-analytics/models/bond/market-data/market-data-bond-settings/market-data-withholding-tax-settings/market-data-bond-withholding-tax-settings';

@Component({
  selector: 'lv-withholding-tax-bond-settings',
  templateUrl: './lv-withholding-tax-bond-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvWithholdingTaxBondSettingsComponent {
  @Input() settings: IBondWithholdingTaxSettings;

  @HostBinding('class.lv-withholding-tax-bond-settings')
  get isLvWithholdingTaxBondSettingsComponent () {
    return true;
  }
}
