import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IUserSettings, IUpdateUserSettingsRequest } from '../../models/notifications-settings/user-settings';
import { LvNotificationsError } from '../../models/errors';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateUtil } from '@lv-core-ui/util';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Service responisble for managing user settings
 */
@Injectable()
export class UserSettingsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/notifications/userSettings');
  }

  /**
   * Get user settings from api
   * @param name User name
   * @param email User email
   * @param dateFormat Date format
   * @returns User settings data
   */
  async getUserSettings(name: string, email: string, dateFormat: string): Promise<IUserSettings> {
    try {
      const response = await this.getAsync<IUserSettings>({
        name: name,
        email: email,
        dateFormat: dateFormat
      });

      response.document.triggerBulkAt = LvDateUtil.parseDateTime(response.document.triggerBulkAt);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvNotificationsError(e.message));
    }
  }

  /**
   * Reset user settings to default values
   * @param name User name
   * @param email User email
   * @param dateFormat Date format
   * @returns Default user settings data
   */
  async resetUserSettings(name: string, email: string, dateFormat: string): Promise<IUserSettings> {
    try {
      const response = await this.getAsync<IUserSettings>({
        name: name,
        email: email,
        dateFormat: dateFormat
      }, '/default');

      response.document.triggerBulkAt = LvDateUtil.parseDateTime(response.document.triggerBulkAt);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvNotificationsError(e.message));
    }
  }

  /**
   * Update user settings data
   * @param request Update user settings request
   */
  async updateUserSettings(request: IUpdateUserSettingsRequest): Promise<void> {
    try {
      request.document.triggerBulkAt = LvDateUtil.toISODateTimeString(request.document.triggerBulkAt);

      await this.putAsync(request);
    }
    catch (error) {
      throw this.handleError(error, e => new LvNotificationsError(e.message));
    }
  }
}
