import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef  } from '@angular/core';
import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { FrequencyDescription } from '@lv-analytics/models';
import { CoPayCouponTypeDescription, CoPayTriggerTypeDescription } from '@lv-convertible-bond/models';
import { DayCountDescription, CouponCoPay } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-co-pay-coupon',
  templateUrl: './lv-co-pay-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCoPayCouponComponent implements OnInit {
  @Input() model: CouponCoPay;
  @Input() accrualStartDateCoPay: Date;
  @Output() didCouponCoPayChange: EventEmitter<CouponCoPay>;

  upsideCoPayCheckboxId: string;
  downsideCoPayCheckboxId: string;
  downsideUseScheduleCoPayCheckboxId: string;

  coPayFrequencyLookup: LvLookupEnum;
  coPayDayCountLookup: LvLookupEnum;
  coPayCouponTypeLookup: LvLookupEnum;
  coPayTriggerTypeLookup: LvLookupEnum;

  downsideUseSchedule: boolean;

  formatNumber = '#,###.####';
  decimalsNumber = '4';

  constructor() {
    this.upsideCoPayCheckboxId = v4();
    this.downsideCoPayCheckboxId = v4();
    this.downsideUseScheduleCoPayCheckboxId = v4();

    this.coPayFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.coPayDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.coPayCouponTypeLookup = new LvLookupEnum(CoPayCouponTypeDescription);
    this.coPayTriggerTypeLookup = new LvLookupEnum(CoPayTriggerTypeDescription);

    this.downsideUseSchedule = false;

    this.didCouponCoPayChange = new EventEmitter<CouponCoPay>();
  }

  ngOnInit() {
    if (!this.model.coPayAccrualStartDate) {
      this.model.coPayAccrualStartDate = this.accrualStartDateCoPay;
    }
  }

  coPayModelChange() {
    this.didCouponCoPayChange.next(this.model);
  }

  getCoPayCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
