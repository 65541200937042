<lv-flex-box direction="row" class="lv-borrow-grid-title">
  <div showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="borrowTermStructureTooltipTemplate"
    filter=".lv-label" 
    class="lv-label lv-label-field lv-label--title">
    <span>
      BORROW TERM STRUCTURE
    </span> 
  </div>
</lv-flex-box>

<lv-advanced-grid
  data-cy="DM-305"
  [columns]="columns"
  [records]="scheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="endDate"
  class="lv-equity-borrow-term-structure-table"
  lvId="lvMarketDataEquityBorrowTermStructuresGrid"
  [excelFieldAlias]="excelFieldAlias"
  [ngClass]="addStripedGridClass"
  [editDisabled]="isGridDisabled">
</lv-advanced-grid>

<ng-template #borrowTermStructureTooltipTemplate>
  <lv-tooltip dm="dM-305"></lv-tooltip>
</ng-template>
