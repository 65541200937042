
export interface IDefaultWidgetState {
  widgetType: DefaultWidgetType;
  state: any;
  isOpenedFromExcel: boolean;
  instrumentType: string;
}

export enum DefaultWidgetType {
  Instrument = 'instrument',
  Tools = 'tools',
  ModelCustomization = 'model_customization',
  Pricing = 'pricing',
  InstrumentInfo = 'instrument_info',
  Underlying = 'underlying',
  BondPricing = 'bond_pricing',
  BondModelCustomization = 'bond_model_customization',
  ASW = 'asset_swap',
}

export enum DefaultWidgetTypeDescription {
  instrument = 'Instrument',
  tools = 'tools',
  model_customization = 'Model Customization',
  pricing = 'Pricing',
  instrument_info = 'Instrument Info',
  underlying = 'underlying',
  bond_pricing = 'Bond Pricing',
  bond_model_customization = 'Bond Model Customization',
  assetSwap = 'asset_swap',
}
