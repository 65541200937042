export enum PayLegFrequency {
    Annual = 'Annual',
    SemiAnnual = 'SemiAnnual',
    Quarterly = 'Quarterly',
    Bimonthly = 'Bimonthly',
    Monthly = 'Monthly',
}

export enum PayLegFrequencyDescription {
    Annual = 'Annual',
    SemiAnnual = 'Semi Annual',
    Quarterly = 'Quarterly',
    Bimonthly = 'Bimonthly',
    Monthly = 'Monthly',
}