import * as _ from 'lodash';
import { DateExtensions } from '@lv-core-ui/util';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { IField } from '@lv-excel/models/field';

export abstract class FieldMapper<T> {
  protected _model: T;
  protected _initialModel: T;
  protected _fieldDictionary: {
    [key: string]: any
  };

  constructor() {
    this._fieldDictionary = {};
    this._model = {} as T;
  }

  /**
   * Init module which is data holder
   * @param model Existing module
   * @param isDraft is Draft instrument
   */
  init(model: any, isDraft: boolean = false, additionalModelSetup: (() => void) = null) {
    this._model = model;
    // create copy for saving initial values
    this._initialModel = _.cloneDeep(model);

    if (additionalModelSetup !== null && additionalModelSetup !== undefined) {
      additionalModelSetup();
      this._initialModel = _.cloneDeep(this._model);
    }

    this.createMap(isDraft);
  }

  /**
   * Map fields from json array representation
   * to specified field in model (data holder)
   * @param inputData List of key value fields sent from excel
   */
  mapp(inputData: any[], isV31: boolean = false, marketDataId: number = 0) {
    if (!inputData) {
      return;
    }

    /**
     * Some fields need to be mapped before others.
     * so this logic would first map that fields and then others.
     * Fields are filtered so every field will be mapped once.
     * For example if we want to map NI_PREM_PRC_RANGE, with CB_TYPE, PRICE_TALK
     * at the same time, we need to have this two fields mapped before
     * NI_PREM_PRC_RANGE so we can properly map premium values.
     */
    const elementsWithPriority = ['CB_TYPE', 'PRICE_TALK'];

    const fieldElementsWithPriority = inputData.filter(x => elementsWithPriority.findIndex(y => y === x.key) > -1);

    if (fieldElementsWithPriority.length > 0) {
      this.mappFields(fieldElementsWithPriority);

      inputData = inputData.filter(x => !fieldElementsWithPriority.includes(x));
    }

    this.mappFields(inputData);

    this.customMapp(inputData, isV31, marketDataId);
  }

  private mappFields(inputData: any[]) {
    inputData.forEach(element => {
      const field = this._fieldDictionary[element.key];

      if (field && element.value !== null && element.value !== undefined) {
        switch (field.type) {
          case ExcelFieldDataTypes.Number:
            field.mapFn(Number.parseFloat(element.value));
            break;

          case ExcelFieldDataTypes.Date:
            field.mapFn(new Date(element.value));
            break;

          case ExcelFieldDataTypes.Enum:
            field.mapFn(this.mappEnumTypeV3(element.value, field.enumType));
            break;
          case ExcelFieldDataTypes.Boolean:
            field.mapFn(element.value === 'YES' ? true : false);
            break;

          case ExcelFieldDataTypes.String:
          case ExcelFieldDataTypes.Schedule:
            field.mapFn(element.value);
            break;

          default:
            break;
        }
      }
    });
  }

  /**
   * Map fields from data holder to json fields array
   * @param inputData list of all data from one module
   * @param group specific module
   * @returns Result created to specific format to comunicate with excel
   */
  reverseMap(inputData: any[], group: string): IField[] {
    const result = [];

    if (!inputData) {
      return;
    }

    let currentField;

    try {

      inputData.forEach(element => {
        const field = this._fieldDictionary[element.key];

        currentField = element.key;

        if (field) {
          const value = field.type === ExcelFieldDataTypes.Date ? DateExtensions.toOADate(field.reverseMapFn()) : field.reverseMapFn();

          result.push({
            alias: element.key,
            group: group,
            editable: element.editable,
            value: value
          } as IField);
        }
      });
    }
    catch (err) {
      alert(`Field with issue:${currentField}`);
      throw err;
    }

    return result;
  }

  getFields(inputData: any[], group: string): IField[] {
    return inputData.filter(f => this._fieldDictionary[f.key]);
  }

  /**
   * Retrurns enum key based on enum value
   * @param enumType Enum type
   * @param enumValue Enum value
   * @returns Enum key
   */
  getEnumKeyByEnumValue(enumType: any, enumValue: number | string): string {
    const keys = Object.keys(enumType).filter((x) => enumType[x].toLowerCase() === enumValue.toString().toLowerCase());
    return keys.length > 0 ? keys[0] : '';
  }

  /**
   * Creates maps for excel aliases which have paths to project modeels and types
   */
  abstract createMap(isDraft: boolean);

  /**
   * Map custom enum type
   * @param value Field value
   * @param enumType Defined enum type
   */
  abstract mappEnumType(value: any, enumType: any);

  /**
   * Map custom enum type for V3
   * @param value Field value
   * @param enumType Defined enum type
   */
  abstract mappEnumTypeV3(value: any, enumType: any);


  /**
   * If some field have complex mapping creates custom mapp functionality
   * @param inputData all fields for one module
   */
  abstract customMapp(inputData: any[], isV3: boolean, marketDataId: number);
}
