export enum StrikeType {
    Standard = 'Standard',
    FixedStrike = 'FixedStrike',
    DiscountedRedemption = 'DiscountedRedemption'
}

export enum StrikeTypeDescription {
    Standard = 'Standard (Par - PV Swap)',
    FixedStrike = 'Fixed Strike',
    DiscountedRedemption = 'Discounted Redemption'
}