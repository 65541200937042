import { Component, EventEmitter, Output, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { InstrumentTypeDescription } from '@lv-custom-instruments/models/instrument-type';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-create-instrument-modal',
  templateUrl: './lv-create-instrument-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCreateInstrumentModalComponent {
  @Input() hasBetaAccessPermission: boolean;

  @Output() didCancel: EventEmitter<void>;
  @Output() didCreate: EventEmitter<InstrumentTypeDescription>;

  useConvertibleRadioButtonId: string;
  useEquityRadioButtonId: string;
  useBondRadioButtonId: string;
  useAssetSwapRadioButtonId: string;
  instrumentType: InstrumentTypeDescription;

  constructor() {
    this.didCancel = new EventEmitter<void>();
    this.didCreate = new EventEmitter<InstrumentTypeDescription>();
    this.useConvertibleRadioButtonId = v4();
    this.useEquityRadioButtonId = v4();
    this.useBondRadioButtonId = v4();
    this.useAssetSwapRadioButtonId = v4();
    this.instrumentType = InstrumentTypeDescription.Convertible;
  }

   /**
   * Occurs on cancel.
   */
   onCancel() {
    this.didCancel.emit();
  }

  onCreate() {
    this.didCreate.emit(this.instrumentType);
  }
}
