import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IEquityBorrow } from '@lv-analytics/models/equity/market-data/market-data-equity/equity-borrow/equity-borrow';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import _ from 'lodash';
import { LvEquityBorrowTermStructureComponent } from './lv-equity-borrow-term-structure/lv-equity-borrow-term-structure.component';

@Component({
  selector: 'lv-equity-market-data-borrow',
  templateUrl: './lv-equity-market-data-borrow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvMarketDataEquityBorrowComponent implements OnInit {
  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild(LvEquityBorrowTermStructureComponent) borrowTermStructure: LvEquityBorrowTermStructureComponent;

  get currentEnvironmentId(): string {
    return this.envSettings.getSelectedEnvironment().id;
  }

  borrowSettings: IEquityBorrow;
  originalValue: IEquityBorrow;
  borrowSection = PricingEnvironmentSections.Borrow;
  isLoading: boolean;

  constructor (
    private _changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _destroyRef: DestroyRef,
    private _errorService: LvErrorService,
  ) {

    this.borrowSettings = {
      termStructureItems: []
    } as IEquityBorrow;
  }

  @HostBinding ('class.lv-market-data-equity-borrow')
  get isLvMarketDataEquityBorrowComponent (){
    return true;
  }

  ngOnInit(): void {
    this._analyticsPresenter.onModelLoading
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(isLoading => 
        this.setLoadingState(isLoading)
      );

    this._analyticsPresenter.onAnalyticsSettingsUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.load();
      });

    this.load();
  }

  private load() {
    if (this._analyticsPresenter?.equitySession?.marketData) {
      this.borrowSettings = _.cloneDeep(this._analyticsPresenter?.equitySession?.marketData?.borrow);
      this.originalValue = _.cloneDeep(this.borrowSettings);
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Save all borrow equity
   */
  async onSaveSection() {
    try {
      this.setLoadingState(true);

      this.borrowTermStructure.applyAdvancedGridChanges();
      await this._analyticsPresenter.saveEquityBorrow(this.borrowSettings, this.envSettings.getSelectedEnvironment().id);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388', {'value': 'Borrow'}));
      
      this.originalValue = _.cloneDeep(this.borrowSettings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Reload equity borrow
   */
  async onReloadSection() {
    await this.loadEquityBorrow(this.envSettings.getSelectedEnvironment());
    this.load();
  }

  /**
   * Occurs on environment change and loads market data borrow.
   * @param environment IEnvironmentSettingsItem object.
   */
  async onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    await this.loadEquityBorrow(environment);
    this.load();
  }

  /**
   * Loads market data borrow
   * @param {IEnvironmentSettingsItem} environment pass environment settings
   */
  async loadEquityBorrow(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      await this._analyticsPresenter.loadEquityBorrow(environment);
      this.originalValue = _.cloneDeep(this.borrowSettings);    
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }   

  /**
   * Sets section state changed flag.
   * @param sessionStateChange A flag indicating if session state is changed.
   */
  setSectionStateChanged() {
    // this._analyticsPresenter.overrideEquityInSession(equityValuationSession => {
    //   equityValuationSession.marketData.borrow = this.borrowSettings;
    // });

    this._changeDetectorRef.detectChanges();
  }
  
  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.envSettings.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
