import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBaseBondTermsSettings, IBondCouponSectionSettings } from '../../../../models/bond-terms-settings';
import { LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';
import { AccrualMethodDescription, CouponReferenceTenorDescription, CouponTypeDescription, DayCountDescription,
  ExCouponPeriodType, FrequencyDescription, PikCouponTypeDescription } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-bond-coupon-section-settings',
  templateUrl: './lv-bond-coupon-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondCouponSectionSettingsComponent {
  @Input() settings: IBaseBondTermsSettings<IBondCouponSectionSettings>;
  @Input() overrideDefaults: boolean;

  couponTypeLookup: LvLookupEnum;
  couponDayCountLookup: LvLookupEnum;
  couponFrequencyLookup: LvLookupEnum;
  accrualMethodLookup: LvLookupEnum;
  exCouponPerLookup: LvLookupEnum;
  referenceTenor: LvLookupEnum;
  pikCouponTypeLookup: LvLookupEnum;

  couponDatesEomCheckboxId: string;
  guaranteedCouponsCheckboxId: string;

  decimalsZero = '0';
  formatZero = 'n0';
  formatFour = '#,###.####';
  decimalsFour = '4';

  constructor() {
    this.couponTypeLookup = new LvLookupEnum(CouponTypeDescription);
    this.couponDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.couponFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.accrualMethodLookup = new LvLookupEnum(AccrualMethodDescription);
    this.exCouponPerLookup = new LvLookupEnum(ExCouponPeriodType);
    this.referenceTenor = new LvLookupEnum(CouponReferenceTenorDescription);
    this.pikCouponTypeLookup = new LvLookupEnum(PikCouponTypeDescription);

    this.couponDatesEomCheckboxId = v4();
    this.guaranteedCouponsCheckboxId = v4();
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  
  
    /**
   * Retrieves element's id
   * @param element reference to given element
   * @param sectionId element's id
   * @returns falg- true if element with given id is found
   */
  getCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
