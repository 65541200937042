/**
 * Credit source descriptions.
 */
export enum CreditSourceAswDescription {
  FlatSpread = 'Flat Spread',
  TermStructure = 'Term Structure',
  CDSFlat = 'CDS Flat',
  CDSTermStructure = 'CDS Term Structure',
  DefaultRateFlat = 'Default Rate Flat',
  DefaultRateTermStructure = 'Default Rate Term Structure',
  SurvivalProbabilityTermStructure = 'Survival Probability Term Structure',
  UnderlyingCBData = 'Underlying CB Data'
}

/**
 * Credit sources.
 */
export enum CreditSourceAsw {
  FlatSpread = 'FlatSpread',
  TermStructure = 'TermStructure',
  CDSFlat = 'CDSFlat',
  CDSTermStructure = 'CDSTermStructure',
  DefaultRateFlat = 'DefaultRateFlat',
  DefaultRateTermStructure = 'DefaultRateTermStructure',
  SurvivalProbabilityTermStructure = 'SurvivalProbabilityTermStructure',
  UnderlyingCBData  = 'UnderlyingCBData'
}
