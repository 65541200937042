export class IBondModelCustomizationState {
  useDefaultView: boolean;
  instrumentType: string;
  excludeTerms: {
    isExcludeTerms: boolean;
    isCall: boolean;
    isPut: boolean;
  };
  modelType: {
    isModelType: boolean;
    isCreditModelType: boolean;
    isPricerVersion: boolean;
  };
  callAdjustments: {
    isCallAdjustments: boolean;
    isHardCallModelType: boolean;
    isHardCallAdjustFactor: boolean;
    isNotCallableDate: boolean;
  };
  otherParameters: {
    isOtherParameters: boolean;
    isExpectedLifeExcludingDefault: boolean;
    isMaturityDateOverride: boolean;
  };
  isOpenedFromExcel: boolean;
}

export function getInitialBondModelCustomizationState(): IBondModelCustomizationState {
  return {
    useDefaultView: true,
    instrumentType: 'Bond',
    excludeTerms: {
      isExcludeTerms: true,
      isCall: true,
      isPut: true,
    },
    modelType: {
      isModelType: true,
      isCreditModelType: true,
      isPricerVersion: true,
    },
    callAdjustments: {
      isCallAdjustments: true,
      isHardCallAdjustFactor: true,
      isHardCallModelType: true,
      isNotCallableDate: true,
    },
    otherParameters: {
      isOtherParameters: true,
      isExpectedLifeExcludingDefault: true,
      isMaturityDateOverride: true,
    },
    isOpenedFromExcel: false,
  } as IBondModelCustomizationState;
}