// tslint:disable-next-line: max-line-length
import { PepsParityConventionDescription, DividendProtectionModelDescription } from 'src/app/modules/analytics/models/model-customization/model-customization-enum';
import { callAdjustmentModelSettings } from './callAdjustmentModelSettingsMock';
import { creditModelSettings } from './creditModelSettingsMock';

export const modelCustomization = {
  callAdjustmentModelSettings: callAdjustmentModelSettings,
  creditModelSettings: creditModelSettings,
  defaultOnLowParity: false,
  dividendProtectionModel: DividendProtectionModelDescription.Default,
  excludeCall: false,
  excludeContigentConversion: false,
  excludeConversionPriceReset: false,
  excludeDividendProtection: false,
  excludePut: false,
  excludeVariableConversion: false,
  expLifeExcludesDefault: false,
  forceOptionalReset: false,
  includeCashRebateInParity: false,
  maturityDateOverride: null,
  pepsParityConvention: PepsParityConventionDescription.MinConversionRatio,
  priceExchangeableAsConvertible: false
};
