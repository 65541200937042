import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IAswConversion } from '../../../../models/aswConversion';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import { FeeFormulaType } from '../../../../models/asset-swap-terms-settings/enums/asset-swap-fee-formula-type';

@Component({
  selector: 'lv-asw-conversion',
  templateUrl: './lv-asw-conversion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvAswConversionComponent {
  @Input() model: IAswConversion;
  @Output() didAwsConversionChange: EventEmitter<IAswConversion>;

  get isEarlyExerciseFeeApplicable () {
    //to do: remove setTimeOut once override in session is called via API.
    setTimeout(() => {
    }, 1000);
    return !!this.model.earlyExerciseFeeApplicable;
  }

  dpStartCheckBoxId: string;
  dpStartDatePickerId : string;
  dpEndCheckBoxId: string;
  dpEndDatePickerId : string;
  earlyExerciseFeeCheckboxId: string;
  dpEarlyEndCheckBoxId: string;
  dpEarlyEndDatePickerId : string;
  endDateEnabled: boolean;
  startDateEnabled: boolean;
  earlyEndDateEnabled: boolean;

  feeFormulaTypeLookup: LvLookupEnum;

  formatTwo = '#,###.##';
  decimalsTwo = '2';
  formatZero = 'n0';
  decimalsZero = '0';

  constructor ( private _changeDetectorRef: ChangeDetectorRef,) {
    this.didAwsConversionChange = new EventEmitter<IAswConversion>();
    this.dpStartCheckBoxId = 'startDateCheckBoxId';
    this.dpStartDatePickerId = 'startDatePickerId';
    this.earlyExerciseFeeCheckboxId = 'earlyExerciseFeeCheckboxId';
    this.dpEndCheckBoxId = 'endDateCheckBoxId';
    this.dpEndDatePickerId = 'endDatePickerId';
    this.dpEarlyEndCheckBoxId = 'earlyEndCheckBoxId';
    this.dpEarlyEndDatePickerId = 'earlyEndDatePickerId';
    this.endDateEnabled = false;
    this.startDateEnabled = false;
    this.earlyEndDateEnabled = false;

    this.feeFormulaTypeLookup = new LvLookupEnum(FeeFormulaType);
  }

  @HostBinding('class.lv-asw-conversion')
    get isLvAswConversionComponent () {
      return true;
    }

  /**
   * Method triggers when field value changes.
   */
  onAwsConversionChange() {
    this.didAwsConversionChange.next(this.model);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Method triggers when start date checkbox changes state.
   * @param value Flag if checkbox is checked or not.
   */
  aswStartDateCheckBoxChanged(value: boolean): void {
    this.startDateEnabled = value;
    this.model.exerciseStartDate = value ? this.model.exerciseStartDate : null;
    this.onAwsConversionChange();
  }

  
  /**
   * Method triggers when end date checkbox changes state.
   * @param value Flag if checkbox is checked or not.
   */
  aswEndDateCheckBoxChanged(value: boolean): void {
    this.endDateEnabled = value;
    this.model.exerciseEndDate = value ? this.model.exerciseEndDate: null;
    this.onAwsConversionChange();
  }

   /**
   * Method triggers when early exercise end date checkbox changes state.
   * @param value Flag if checkbox is checked or not.
   */
   aswEarlyEndDateCheckBoxChanged(value: boolean): void {
    this.earlyEndDateEnabled = value;
    this.model.earlyExerciseEndDate = value ? this.model.earlyExerciseEndDate: null;
    this.onAwsConversionChange();
  }
}
