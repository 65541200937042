import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { IntlModule, NumberPipe } from '@progress/kendo-angular-intl';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LayoutModule } from '@progress/kendo-angular-layout';
// Charts
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';

// Services
import { ConvertiblesService } from './services/convertibles/convertibles.service';
import { ValuationSessionService } from './services/valuation-session/valuation-session.service';
import { AnalyticsService } from './analytics.service';
import { CompanyAndUserSettingsService } from './services/company-settings/company-settings.service';
import { EnvironmentSettingsService } from './services/environment-settings/environment-settings.service';
import { AnalyticsHubService } from './hubs/analytics-hub.service';
import { OutputsService } from './services/outputs/outputs.service';
import { CommonDataService } from './services/common-data/common-data.service';
import { ModelCustomizationService } from './services/model-customization/model-customization.service';

// tslint:disable-next-line:max-line-length
import { LvModelCustomizationComponent } from './components/model-customization/lv-model-customization/lv-model-customization.component';
// tslint:disable-next-line:max-line-length
import { LvDeltaNeutralComponent } from './components/lv-delta-neutral/lv-delta-neutral.component';
import { LvBreakEvenComponent } from './components/lv-break-even/lv-break-even.component';
import { LvDifferentDeltaExecutionComponent } from './components/lv-different-delta-execution/lv-different-delta-execution.component';
import { LvToolsWidgetComponent } from './components/tools/lv-tools-widget/lv-tools-widget.component';
import { LvToolsComponent } from './components/tools/lv-tools/lv-tools.component';

// tslint:disable-next-line:max-line-length
import { LvEstimatesHistoryDialogComponent } from './components/lv-pricing/lv-estimates-history-dialog/lv-estimates-history-dialog.component';


// Sensitivity
import { ScenarioTemplateService } from './services/sensitivity-analysis/scenario-template/scenario-template.service';
import { ScenarioCalculationService } from './services/sensitivity-analysis/scenario-calculation/scenario-calculation.service';
import { LvSensitivityAnalysisPanelComponent } from './components/lv-sensitivity-analysis-panel/lv-sensitivity-analysis-panel.component';
// tslint:disable-next-line:max-line-length
import { LvSensitivityAnalysisPanelDialogComponent } from './components/lv-sensitivity-analysis-panel/lv-sensitivity-analysis-panel-dialog/lv-sensitivity-analysis-panel-dialog.component';
import { LvScenarioComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioInputsComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-inputs/lv-scenario-inputs.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioGridComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-grid/lv-scenario-grid.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioOneDimGridComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-grid/lv-scenario-one-dim-grid/lv-scenario-one-dim-grid.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioTwoDimGridComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-grid/lv-scenario-two-dim-grid/lv-scenario-two-dim-grid.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioTwoDimPriceTalkGridComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-grid/lv-scenario-two-dim-price-talk-grid/lv-scenario-two-dim-price-talk-grid.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioOneDimPriceTalkGridComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-grid/lv-scenario-one-dim-price-talk-grid/lv-scenario-one-dim-price-talk-grid.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioChartComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-chart/lv-scenario-chart.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioOneDimChartComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-chart/lv-scenario-one-dim-chart/lv-scenario-one-dim-chart.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioOneDimPriceTalkChartComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-chart/lv-scenario-one-dim-price-talk-chart/lv-scenario-one-dim-price-talk-chart.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioTwoDimChartComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-chart/lv-scenario-two-dim-chart/lv-scenario-two-dim-chart.component';
// tslint:disable-next-line:max-line-length
import { LvScenarioTwoDimPriceTalkChartComponent } from './components/lv-sensitivity-analysis-panel/lv-scenario/lv-scenario-chart/lv-scenario-two-dim-price-talk-chart/lv-scenario-two-dim-price-talk-chart.component';

// Environment Settings
// tslint:disable-next-line:max-line-length
import { LvEnvironmentSettingsDialogComponent } from './components/lv-environment-settings/lv-environment-settings-dialog/lv-environment-settings-dialog.component';
import { LvEnvironmentSettingsComponent } from './components/lv-environment-settings/lv-environment-settings.component';

// Market Data
import { MarketDataService } from './services/market-data/market-data.service';
import { LvMarketDataComponent } from './components/market-data/lv-market-data/lv-market-data.component';
import { LvMarketDataWidgetComponent } from './components/market-data/lv-market-data-widget/lv-market-data-widget.component';
import { LvDividendsComponent } from './components/market-data/lv-market-data/lv-dividends/lv-dividends.component';

// tslint:disable-next-line:max-line-length
import { LvParametersDividendsComponent } from './components/market-data/lv-market-data/lv-dividends/lv-parameters-dividends/lv-parameters-dividends.component';
// tslint:disable-next-line:max-line-length
import { LvScheduleDividendsComponent } from './components/market-data/lv-market-data/lv-dividends/lv-schedule-dividends/lv-schedule-dividends.component';
import { LvInterestRatesComponent } from './components/market-data/lv-market-data/lv-interest-rates/lv-interest-rates.component';

// tslint:disable-next-line:max-line-length
import { LvScheduleDividendsTableComponent } from './components/market-data/lv-market-data/lv-dividends/lv-schedule-dividends-table/lv-schedule-dividends-table.component';
import { LvFundingComponent } from './components/market-data/lv-market-data/lv-funding/lv-funding.component';
import { LvWithholdingTaxComponent } from './components/market-data/lv-market-data/lv-withholding-tax/lv-withholding-tax.component';
import { LvCreditComponent } from './components/market-data/lv-market-data/lv-credit/lv-credit.component';
// tslint:disable-next-line:max-line-length
import { LvAssetParametersComponent } from './components/market-data/lv-market-data/lv-credit/lv-asset-parameters/lv-asset-parameters.component';
// tslint:disable-next-line:max-line-length
import { LvCreditParametersComponent } from './components/market-data/lv-market-data/lv-credit/lv-credit-parameters/lv-credit-parameters.component';
// tslint:disable-next-line:max-line-length
import { LvEquityStochasticCreditModelComponent } from './components/market-data/lv-market-data/lv-credit/lv-equity-stochastic-credit-model/lv-equity-stochastic-credit-model.component';
// tslint:disable-next-line:max-line-length
import { LvEquityLinkedCreditComponent } from './components/market-data/lv-market-data/lv-credit/lv-equity-linked-credit/lv-equity-linked-credit.component';

// tslint:disable-next-line:max-line-length
import { LvYieldCurveTermsStructureComponent } from './components/market-data/lv-market-data/lv-interest-rates/lv-yield-curve-terms-structure/lv-yield-curve-terms-structure.component';
import { LvVolatilityComponent } from './components/market-data/lv-market-data/lv-volatility/lv-volatility.component';
// tslint:disable-next-line:max-line-length
import { LvVolatilityTermStructureComponent } from './components/market-data/lv-market-data/lv-volatility/lv-volatility-term-structure/lv-volatility-term-structure.component';
// tslint:disable-next-line:max-line-length
import { LvVolatilitySurfaceComponent } from './components/market-data/lv-market-data/lv-volatility/lv-volatility-surface/lv-volatility-surface.component';
import { LvBorrowComponent } from './components/market-data/lv-market-data/lv-borrow/lv-borrow.component';
import { LvBorrowFlatComponent } from './components/market-data/lv-market-data/lv-borrow/lv-borrow-flat/lv-borrow-flat.component';
import { LvBorrowRebateComponent } from './components/market-data/lv-market-data/lv-borrow/lv-borrow-rebate/lv-borrow-rebate.component';
// tslint:disable-next-line:max-line-length
import { LvBorrowTermStructureComponent } from './components/market-data/lv-market-data/lv-borrow/lv-borrow-terms-structure/lv-borrow-term-structure.component';
import { BorrowMapper } from './models/market-data/borrow/borrow-mapper';

import { LvPricingComponent } from './components/lv-pricing/lv-pricing.component';
import { LvPricingSidePanelComponent } from './components/lv-pricing/lv-pricing-side-panel/lv-pricing-side-panel.component';
import { LvOutputsWidgetComponent } from './components/lv-outputs/lv-outputs-widget/lv-outputs-widget.component';
import { LvOutputsDialogComponent } from './components/lv-outputs/lv-outputs-dialog/lv-outputs-dialog.component';

// tslint:disable-next-line:max-line-length
import { LvCreditTermsStructureComponent } from './components/market-data/lv-market-data/lv-credit/lv-credit-parameters/lv-credit-terms-structure/lv-credit-terms-structure.component';
import { DividendsMapper } from './models/market-data/dividends/dividends-mapper';
import { MarketDataComponentDirective } from './directives/market-data-component/market-data-component.directive';
import { CreditMapper } from './models/market-data/credit/credit-mapper';
import { VolatilityMapper } from './models/market-data/volatility/volatility-mapper';

// Basic Terms
import { LvBasicTermsComponent } from './components/lv-basic-terms/lv-basic-terms.component';
import { LvBasicTermsDialogComponent } from './components/lv-basic-terms/lv-basic-terms-dialog/lv-basic-terms-dialog.component';
import { BasicTermsSettingsService } from './services/basic-terms-settings/basic-terms-settings.service';
// tslint:disable-next-line:max-line-length
import { LvNewIssueSettingsDialogComponent } from './components/lv-pricing/lv-new-issue-settings-dialog/lv-new-issue-settings-dialog.component';
// tslint:disable-next-line:max-line-length
import { LvEstimatesSettingsDialogComponent } from './components/lv-pricing/lv-estimates-settings-dialog/lv-estimates-settings-dialog.component';
// tslint:disable-next-line:max-line-length
import { LvEstimatesSettingsSectionComponent } from './components/lv-pricing/lv-estimates-settings-dialog/lv-estimates-settings-section/lv-estimates-settings-section.component';
// tslint:disable-next-line:max-line-length
import { LvToolsCustomSelectionComponent } from './components/tools/lv-tools-custom-selection/lv-tools-custom-selection.component';
// tslint:disable-next-line:max-line-length
import { LvModelCustomizationCustomSelectionComponent } from './components/model-customization/lv-model-customization-custom-selection/lv-model-customization-custom-selection.component';
import { YieldCurveMapper } from './models/market-data/yield-curve/yield-curve-mapper';
import { LvAveragingDataComponent } from './components/market-data/lv-market-data/lv-averaging-data/lv-averaging-data.component';
import { ModelCustomizationMapper } from './models/model-customization/model-customization-mapper';

// Company And User Settings
// tslint:disable-next-line:max-line-length
import { LvDividendsSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-dividends-settings/lv-dividends-settings.component';
import { LvMarketDataSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-market-data-settings.component';
// tslint:disable-next-line:max-line-length
import { LvDividendsSectionSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-dividends-settings/lv-dividends-section-settings/lv-dividends-section-settings';
// tslint:disable-next-line:max-line-length
import { LvInterestRatesSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-interest-rates-settings/lv-interest-rates-settings.component';
// tslint:disable-next-line:max-line-length
import { LvInterestRatesSectionSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-interest-rates-settings/lv-interest-rates-section-settings/lv-interest-rates-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCreditSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-credit-settings/lv-credit-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCreditSectionSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-credit-settings/lv-credit-section-settings/lv-credit-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvSectionSettingsComponent } from './components/section-settings/lv-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvFundingSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-funding-settings/lv-funding-settings.component';
// tslint:disable-next-line: max-line-length
import { LvFundingSectionSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-funding-settings/lv-funding-section-settings/lv-funding-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvSystemDefaultsSettingsDirective } from './components/section-settings/directives/lv-system-defaults.directive';
// tslint:disable-next-line: max-line-length
import { LvEnvironmentSettingsDirective } from './components/section-settings/directives/lv-environment-settings.directive';
// tslint:disable-next-line: max-line-length
import { LvBorrowSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-borrow-settings/lv-borrow-settings.component';
// tslint:disable-next-line: max-line-length
import { LvBorrowSectionSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-borrow-settings/lv-borrow-section-settings/lv-borrow-section-settings.component';
// tslint:disable-next-line:max-line-length
import { LvWithholdingTaxSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-withholding-tax-settings/lv-withholding-tax-settings.component';
// tslint:disable-next-line:max-line-length
import { LvWithholdingTaxSectionSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-withholding-tax-settings/lv-withholding-tax-section-settings/lv-withholding-tax-section-settings.component';
// tslint:disable-next-line:max-line-length
import { LvVolatilitySettingsComponent } from './components/market-data/lv-market-data-settigns/lv-volatility-settings/lv-volatility-settings.component';
// tslint:disable-next-line:max-line-length
import { LvVolatilitySectionSettingsComponent } from './components/market-data/lv-market-data-settigns/lv-volatility-settings/lv-volatility-section-settings/lv-volatility-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvValuationSettingsComponent } from './components/company-and-user-settings/lv-valuation-settings/lv-valuation-settings.component';
// tslint:disable-next-line: max-line-length
import { LvShiftParametersSettingsComponent } from './components/company-and-user-settings/lv-valuation-settings/lv-shift-parameters-settings/lv-shift-parameters-settings.component';
// tslint:disable-next-line: max-line-length
import { LvShiftParametersSectionSettingsComponent } from './components/company-and-user-settings/lv-valuation-settings/lv-shift-parameters-settings/lv-shift-parameters-section-settings/lv-shift-parameters-section-settings.component';
// tslint:disable-next-line:max-line-length
import { LvModelCustomizationSettingsComponent } from './components/model-customization/lv-model-customization-settings/lv-model-customization-settings.component';
// tslint:disable-next-line:max-line-length
import { LvModelCustomizationSectionSettingsComponent } from './components/model-customization/lv-model-customization-settings/lv-model-customization-section-settings/lv-model-customization-section-settings.component';
// tslint:disable-next-line:max-line-length
import { LvCallAdjustmentSectionSettingsComponent } from './components/model-customization/lv-model-customization-settings/lv-model-customization-section-settings/lv-call-adjustment-section-settings/lv-call-adjustment-section-settings.component';
// tslint:disable-next-line:max-line-length
import { LvModelTypeSectionSettingsComponent } from './components/model-customization/lv-model-customization-settings/lv-model-customization-section-settings/lv-model-type-section-settings/lv-model-type-section-settings.component';

// Other
import { LvXigniteComponent } from './components/lv-xignite/lv-xignite.component';
// tslint:disable-next-line:max-line-length
import { LvNewIssueSettingsComponent } from './components/lv-pricing/lv-pricing-settings/lv-new-issue-settings/lv-new-issue-settings.component';
// tslint:disable-next-line:max-line-length
import { LvEstimatesSettingsComponent } from './components/lv-pricing/lv-pricing-settings/lv-estimates-settings/lv-estimates-settings.component';
// tslint:disable-next-line:max-line-length
import { LvNewIssueSectionSettingsComponent } from './components/lv-pricing/lv-pricing-settings/lv-new-issue-settings/lv-new-issue-section-settings/lv-new-issue-section-settings.component';
// tslint:disable-next-line:max-line-length
import { LvEstimatesSectionSettingsComponent } from './components/lv-pricing/lv-pricing-settings/lv-estimates-settings/lv-estimates-section-settings/lv-estimates-section-settings.component';
// tslint:disable-next-line:max-line-length
import { LvSendValuationQueryDialogComponent } from './components/lv-outputs/lv-send-valuation-query-dialog/lv-send-valuation-query-dialog.component';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';
// tslint:disable-next-line: max-line-length
import { LvWarningsSectionSettingsComponent } from './components/company-and-user-settings/lv-valuation-settings/lv-warnings-settings/lv-warnings-section-settings/lv-warnings-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvWarningsSettingsComponent } from './components/company-and-user-settings/lv-valuation-settings/lv-warnings-settings/lv-warnings-settings.component';
import { LvOtherSettingsComponent } from './components/other/lv-other-settings/lv-other-settings.component';
// tslint:disable-next-line: max-line-length
import { LvQuantitySectionSettingsComponent } from './components/other/lv-other-settings/lv-quantity-settings/lv-quantity-section-settings/lv-quantity-section-settings.component';
import { LvQuantitySettingsComponent } from './components/other/lv-other-settings/lv-quantity-settings/lv-quantity-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCopyAndPasteSettingsComponent } from './components/other/lv-other-settings/lv-copy-and-paste-settings/lv-copy-and-paste-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCopyAndPasteSectionSettingsComponent } from './components/other/lv-other-settings/lv-copy-and-paste-settings/lv-copy-and-paste-section-settings/lv-copy-and-paste-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCopyAndPasteDialogComponent } from './components/market-data/lv-market-data/lv-copy-and-paste-dialog/lv-copy-and-paste-dialog.component';
import { InterestRatesMapper } from './models/market-data/interest-rates/interest-rates-mapper';
import { ExcelModule } from '@lv-excel/excel.module';
import { LvEnvironmentsSettingsComponent } from './components/company-and-user-settings/lv-environments-settings/lv-environments-settings.component';
import { LvEnvironmentSetupSettingsComponent } from './components/company-and-user-settings/lv-environments-settings/lv-environment-setup-settings/lv-environment-setup-settings.component';
import { MenusModule } from '@progress/kendo-angular-menu';
import { LvEditEnvironmentSettingsDialogComponent } from './components/company-and-user-settings/lv-environments-settings/lv-environment-setup-settings/lv-edit-environment-settings-dialog/lv-edit-environment-settings-dialog.component';
import { LvEnvironmentSystemSettingsComponent } from './components/company-and-user-settings/lv-environments-settings/lv-environment-system-settings/lv-environment-system-settings.component';
import { LvEditSystemEnvironmentSettingsDialogComponent } from './components/company-and-user-settings/lv-environments-settings/lv-environment-system-settings/lv-edit-system-environment-settings-dialog/lv-edit-system-environment-settings-dialog.component';
import { LvBucketingSettingsComponent } from './components/company-and-user-settings/lv-valuation-settings/lv-bucketing-settings/lv-bucketing-settings.component';
import { LvBucketingSectionSettingsComponent } from './components/company-and-user-settings/lv-valuation-settings/lv-bucketing-settings/lv-bucketing-section-settings/lv-bucketing-section-settings.component';
import { LvTabularOutputsComponent } from './components/lv-outputs/lv-tabular-outputs/lv-tabular-outputs.component';
import { LvTabularScheduleComponent } from './components/lv-outputs/lv-tabular-outputs/lv-tabular-outputs-schedule/lv-tabular-schedule/lv-tabular-schedule.component';
import { LVOtherMarketDataComponent } from './components/market-data/lv-market-data/lv-other/lv-other-market-data.component';
import { LvInstrumentSourceSettingsComponent } from './components/other/lv-instrument-source-settings/lv-instrument-source-settings.component';
import { LvInstrumentSourceSectionSettingsComponent } from './components/other/lv-instrument-source-settings/lv-instrument-source-section-settings/lv-instrument-source-section-settings.component';
import { LvBondPricingComponent } from './components/lv-bond/lv-bond-pricing/lv-bond-pricing.component';
import { LvBondMarketDataComponent } from './components/market-data/bond/lv-bond-market-data/lv-bond-market-data.component';
import { LvBondMarketDataWidgetComponent } from './components/market-data/bond/lv-bond-market-data-widget/lv-bond-market-data-widget.component';
import { LvBondCreditComponent } from './components/market-data/bond/lv-bond-market-data/lv-bond-credit/lv-bond-credit.component';
import { LvBondInterestRatesComponent } from './components/market-data/bond/lv-bond-market-data/lv-bond-interest-rates/lv-bond-interest-rates.component';
import { LvBondWithholdingTaxComponent } from './components/market-data/bond/lv-bond-market-data/lv-bond-withholding-tax/lv-bond-withholding-tax.component';
import { LvBondCreditParametersComponent } from './components/market-data/bond/lv-bond-market-data/lv-bond-credit/lv-bond-credit-parameters/lv-bond-credit-parameters.component';
import { LvMarketDataBondSettingsComponent } from './components/market-data/bond/lv-bond-market-data-settings/lv-market-data-bond-settings.component';
import { LvCreditBondSettingsComponent } from './components/market-data/bond/lv-bond-market-data-settings/lv-credit-bond-settings/lv-credit-bond-settings/lv-credit-bond-settings.component';
import { LvCreditBondSectionSettingsComponent } from './components/market-data/bond/lv-bond-market-data-settings/lv-credit-bond-settings/lv-credit-bond-section-settings/lv-credit-bond-section-settings/lv-credit-bond-section-settings.component';
import { LvWithholdingTaxBondSettingsComponent } from './components/market-data/bond/lv-bond-market-data-settings/lv-withholding-tax-bond-settings/lv-withholding-tax-bond-settings/lv-withholding-tax-bond-settings.component';
import { LvWithholdingTaxBondSectionComponent } from './components/market-data/bond/lv-bond-market-data-settings/lv-withholding-tax-bond-settings/lv-withholding-tax-section/lv-withholding-tax-section.component';
import { LvInterestRatesBondSettingsComponent } from './components/market-data/bond/lv-bond-market-data-settings/lv-interest-rates-bond-settings/lv-interest-rates-bond-settings/lv-interest-rates-bond-settings.component';
import { LvInterestRatesBondSectionComponent } from './components/market-data/bond/lv-bond-market-data-settings/lv-interest-rates-bond-settings/lv-interest-rates-bond-section/lv-interest-rates-bond-section.component';
import { LvVolatilityEquitySettingsComponent } from './components/market-data/equity/lv-equity-market-data-settings/lv-equity-volatility-settings/lv-volatility-equity-settings/lv-volatility-equity-settings.component';
import { LvEquityVolatilitySettingsComponent } from './components/market-data/equity/lv-equity-market-data-settings/lv-equity-volatility-settings/lv-equity-volatility-settings.component';
import { LvVolatilityEquitySectionSettingsComponent } from './components/market-data/equity/lv-equity-market-data-settings/lv-equity-volatility-settings/lv-volatility-equity-section-settings/lv-volatility-equity-section-settings.component';
import { LvEquityDividendsSettingsComponent } from './components/market-data/equity/lv-equity-market-data-settings/lv-equity-dividends-settings/lv-equity-dividends-settings.component';
import { LvDividendsEquitySettingsComponent } from './components/market-data/equity/lv-equity-market-data-settings/lv-equity-dividends-settings/lv-dividends-equity-settings/lv-dividends-equity-settings.component';
import { LvDividendsEquitySectionSettingsComponent } from './components/market-data/equity/lv-equity-market-data-settings/lv-equity-dividends-settings/lv-dividends-equity-section-settings/lv-dividends-equity-section-settings.component';
import { LvMarketDataEquityBorrowComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-borrow/lv-equity-market-data-borrow.component';
import { LvEquityBorrowFlatComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-borrow/lv-equity-borrow-flat/lv-equity-borrow-flat.component';
import { LvEquityBorrowRebateComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-borrow/lv-equity-borrow-rebate/lv-equity-borrow-rebate.component';
import { LvEquityBorrowTermStructureComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-borrow/lv-equity-borrow-term-structure/lv-equity-borrow-term-structure.component';
import { LvModelCustomizationBondSettingsComponent } from './components/model-customization/bond/bond-model-customization-settings/lv-bond-model-customization-settings/lv-model-customization-bond-settings.component';
import { LvModelCustomizationBondSectionComponent } from './components/model-customization/bond/bond-model-customization-settings/lv-bond-model-customization-section/lv-model-customization-bond-section.component';
import { LvCallAdjustmentBondSectionSettingsComponent } from './components/model-customization/bond/bond-model-customization-settings/lv-bond-model-customization-section/lv-call-adjustment-bond/lv-call-adjustment-bond.component';
import { LvModelTypeBondSectionSettingsComponent } from './components/model-customization/bond/bond-model-customization-settings/lv-bond-model-customization-section/lv-model-type-bond/lv-model-type-bond.component';

import { BondCreditMapper } from './models/bond/market-data/credit/credit-mapper';
import { BondService } from './services/bond/bond.service';
import { LvEquityDividendsParametersComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-dividends/lv-equity-dividends-parameters/lv-equity-dividends-parameters.component';
import { LvEquityDividendsScheduleComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-dividends/lv-equity-dividends-schedule/lv-equity-dividends-schedule.component';
import { LvEquityMarketDataDividendsComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-dividends/lv-equity-market-data-dividends.component';
import { LvBondModelCustomizationComponent } from './components/model-customization/bond/lv-bond-model-customization/lv-bond-model-customization.component';
import { EquityService } from './services/equity/equity.service';
import { BondModelCustomizationMapper } from './models/model-customization/bond/bond-model-customization-mapper';
import { LvEquityMarketDataVolatilityComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-volatility/lv-equity-market-data-volatility.component';
import { LvEquityVolatilityFlatComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-volatility/lv-equity-volatility-flat/lv-equity-volatility-flat.component';
import { LvEquityVolatilityTermsStructureComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-volatility/lv-equity-volatility-terms-structure/lv-equity-volatility-terms-structure.component';
import { LvEquityVolatilitySurfaceComponent } from './components/market-data/equity/lv-equity-market-data/lv-equity-market-data-volatility/lv-equity-volatility-surface/lv-equity-volatility-surface.component';
import { ConvertiblesSessionService } from './services/convertibles/convertibles-session.service';


/**
 * Analytics module definition that contains registration of providers, imports, exports declarations, entry components.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Kendo modules
    LabelModule,
    PopupModule,
    InputsModule,
    DropDownsModule,
    ButtonsModule,
    DatePickerModule,
    DialogModule,
    SortableModule,
    GridModule,
    IntlModule,
    LayoutModule,
    TooltipModule,
    ChartsModule,
    LeversysCoreUIModule,
    ExcelModule,
    MenusModule
  ],
  declarations: [
    LvModelCustomizationComponent,
    LvModelCustomizationCustomSelectionComponent,
    LvDeltaNeutralComponent,
    LvBreakEvenComponent,
    LvDifferentDeltaExecutionComponent,
    LvEstimatesHistoryDialogComponent,
    LvOutputsDialogComponent,
    LvOutputsWidgetComponent,
    LvSendValuationQueryDialogComponent,
    // Basic terms
    LvBasicTermsComponent,
    LvBasicTermsDialogComponent,

    // Sensitivity Analysis
    LvSensitivityAnalysisPanelComponent,
    LvSensitivityAnalysisPanelDialogComponent,
    LvScenarioComponent,
    LvScenarioInputsComponent,
    LvScenarioGridComponent,
    LvScenarioOneDimGridComponent,
    LvScenarioOneDimPriceTalkGridComponent,
    LvScenarioTwoDimGridComponent,
    LvScenarioTwoDimPriceTalkGridComponent,
    LvScenarioChartComponent,
    LvScenarioOneDimChartComponent,
    LvScenarioOneDimPriceTalkChartComponent,
    LvScenarioTwoDimChartComponent,
    LvScenarioTwoDimPriceTalkChartComponent,

    // Environment Settings
    LvEnvironmentSettingsDialogComponent,
    LvEnvironmentSettingsComponent,

    // Market Data
    LvDividendsComponent,
    LvParametersDividendsComponent,
    LvScheduleDividendsComponent,
    LvMarketDataComponent,
    LvMarketDataWidgetComponent,
    LvInterestRatesComponent,
    LvScheduleDividendsTableComponent,
    LvFundingComponent,
    LvWithholdingTaxComponent,
    LVOtherMarketDataComponent,
    LvCreditComponent,
    LvAssetParametersComponent,
    LvCreditParametersComponent,
    LvEquityStochasticCreditModelComponent,
    LvEquityLinkedCreditComponent,
    LvYieldCurveTermsStructureComponent,
    LvYieldCurveTermsStructureComponent,
    LvVolatilityComponent,
    LvVolatilityTermStructureComponent,
    LvVolatilitySurfaceComponent,
    LvBorrowComponent,
    LvBorrowFlatComponent,
    LvBorrowRebateComponent,
    LvBorrowTermStructureComponent,
    LvCreditTermsStructureComponent,
    LvPricingComponent,
    MarketDataComponentDirective,
    LvToolsComponent,
    LvToolsWidgetComponent,
    LvPricingSidePanelComponent,
    LvNewIssueSettingsDialogComponent,
    LvEstimatesSettingsDialogComponent,
    LvEstimatesSettingsSectionComponent,
    LvToolsCustomSelectionComponent,
    LvAveragingDataComponent,

    // Company And User Settings
    LvSectionSettingsComponent,
    LvSystemDefaultsSettingsDirective,
    LvEnvironmentSettingsDirective,
    LvDividendsSettingsComponent,
    LvMarketDataSettingsComponent,
    LvDividendsSectionSettingsComponent,
    LvInterestRatesSettingsComponent,
    LvInterestRatesSectionSettingsComponent,
    LvCreditSettingsComponent,
    LvCreditSectionSettingsComponent,
    LvFundingSettingsComponent,
    LvFundingSectionSettingsComponent,
    LvBorrowSettingsComponent,
    LvBorrowSectionSettingsComponent,
    LvWithholdingTaxSettingsComponent,
    LvWithholdingTaxSectionSettingsComponent,
    LvVolatilitySettingsComponent,
    LvVolatilitySectionSettingsComponent,
    LvValuationSettingsComponent,
    LvShiftParametersSettingsComponent,
    LvShiftParametersSectionSettingsComponent,
    LvModelCustomizationSettingsComponent,
    LvModelCustomizationSectionSettingsComponent,

    LvCallAdjustmentSectionSettingsComponent,
    LvModelTypeSectionSettingsComponent,
    LvNewIssueSettingsComponent,
    LvEstimatesSettingsComponent,
    LvNewIssueSectionSettingsComponent,
    LvEstimatesSectionSettingsComponent,
    LvWarningsSettingsComponent,
    LvWarningsSectionSettingsComponent,
    LvVolatilityEquitySettingsComponent,
    LvEquityVolatilitySettingsComponent,
    LvVolatilityEquitySectionSettingsComponent,

    // Other
    LvXigniteComponent,

    LvOtherSettingsComponent,

    LvQuantitySettingsComponent,

    LvQuantitySectionSettingsComponent,

    LvCopyAndPasteSettingsComponent,

    LvCopyAndPasteSectionSettingsComponent,

    LvCopyAndPasteDialogComponent,
    LvEnvironmentsSettingsComponent,
    LvEnvironmentSetupSettingsComponent,
    LvEditEnvironmentSettingsDialogComponent,
    LvInstrumentSourceSettingsComponent,
    LvInstrumentSourceSectionSettingsComponent,
    LvEnvironmentSystemSettingsComponent,
    LvEditSystemEnvironmentSettingsDialogComponent,
    LvBucketingSettingsComponent,
    LvBucketingSectionSettingsComponent,
    LvTabularOutputsComponent,
    LvTabularScheduleComponent,
    LvEquityDividendsSettingsComponent,
    LvDividendsEquitySettingsComponent,
    LvDividendsEquitySectionSettingsComponent,
    LvMarketDataBondSettingsComponent,
    LvCreditBondSettingsComponent,
    LvCreditBondSectionSettingsComponent,
    LvWithholdingTaxBondSettingsComponent,
    LvWithholdingTaxBondSectionComponent,
    LvInterestRatesBondSettingsComponent,
    LvInterestRatesBondSectionComponent,
    LvModelCustomizationBondSettingsComponent,
    LvModelCustomizationBondSectionComponent,
    LvCallAdjustmentBondSectionSettingsComponent,
    LvModelTypeBondSectionSettingsComponent,
    
    LvBondPricingComponent,
    LvBondMarketDataComponent,
    LvBondMarketDataWidgetComponent,
    LvBondCreditComponent,
    LvBondInterestRatesComponent,
    LvBondWithholdingTaxComponent,
    LvBondCreditParametersComponent,
    LvMarketDataEquityBorrowComponent,
    LvEquityBorrowFlatComponent,
    LvEquityBorrowRebateComponent,
    LvEquityBorrowTermStructureComponent,
    LvEquityMarketDataDividendsComponent,
    LvEquityDividendsParametersComponent,
    LvEquityDividendsScheduleComponent,
    LvBondModelCustomizationComponent,
    LvEquityMarketDataVolatilityComponent,
    LvEquityVolatilityFlatComponent,
    LvEquityVolatilityTermsStructureComponent,
    LvEquityVolatilitySurfaceComponent,
  ],
  exports: [
    LvPricingComponent,
    LvModelCustomizationComponent,
    LvModelCustomizationCustomSelectionComponent,
    LvDeltaNeutralComponent,
    LvBreakEvenComponent,
    LvDifferentDeltaExecutionComponent,
    LvOutputsDialogComponent,
    LvOutputsWidgetComponent,
    LvSendValuationQueryDialogComponent,
    LvBondPricingComponent,

    // Sensitivity Analysis
    LvSensitivityAnalysisPanelComponent,

    // Market Data
    LvDividendsComponent,
    LvMarketDataComponent,
    LvMarketDataWidgetComponent,
    LvInterestRatesComponent,
    LvVolatilityComponent,
    LvBorrowComponent,
    LvBorrowFlatComponent,
    LvBorrowRebateComponent,
    LvBorrowTermStructureComponent,
    LvCreditTermsStructureComponent,
    LvToolsComponent,
    LvToolsWidgetComponent,
    LvToolsCustomSelectionComponent,
    LvBondMarketDataWidgetComponent,
    LvEquityMarketDataDividendsComponent,
    LvEquityDividendsScheduleComponent,
    LvEquityDividendsParametersComponent,
    LvEquityMarketDataVolatilityComponent,

    LvBondModelCustomizationComponent,
    // Basic Terms
    LvBasicTermsComponent,
    LvBasicTermsDialogComponent,

    // Company And User Settings
    LvMarketDataSettingsComponent,
    LvValuationSettingsComponent,
    LvModelCustomizationSettingsComponent,
    LvEstimatesSettingsComponent,
    LvNewIssueSettingsComponent,
    LvOtherSettingsComponent,
    LvEnvironmentsSettingsComponent,
    LvInstrumentSourceSettingsComponent,
    LvVolatilityEquitySettingsComponent,
    LvEquityVolatilitySettingsComponent,
    LvVolatilityEquitySectionSettingsComponent,
    LvEquityDividendsSettingsComponent,
    LvDividendsEquitySettingsComponent,
    LvDividendsEquitySectionSettingsComponent,
    LvMarketDataEquityBorrowComponent,
    LvEquityBorrowFlatComponent,
    LvEquityBorrowRebateComponent,
    LvEquityBorrowTermStructureComponent,

    LvMarketDataBondSettingsComponent,
    LvCreditBondSettingsComponent,
    LvCreditBondSectionSettingsComponent,
    LvWithholdingTaxBondSettingsComponent,
    LvWithholdingTaxBondSectionComponent,
    LvInterestRatesBondSettingsComponent,
    LvModelCustomizationBondSettingsComponent,
    LvModelCustomizationBondSectionComponent,
    LvCallAdjustmentBondSectionSettingsComponent,
    LvModelTypeBondSectionSettingsComponent,
    // Other
    LvXigniteComponent
  ],
  providers: [
    // Pipes
    NumberPipe,

    // Services
    ConvertiblesService,
    ConvertiblesSessionService,
    ValuationSessionService,
    ScenarioTemplateService,
    ScenarioCalculationService,
    MarketDataService,
    AnalyticsService,
    BondService,
    EquityService,
    BorrowMapper,
    CreditMapper,
    BondCreditMapper,
    VolatilityMapper,
    DividendsMapper,
    YieldCurveMapper,
    ModelCustomizationMapper,
    CompanyAndUserSettingsService,
    EnvironmentSettingsService,
    AnalyticsHubService,
    CommonDataService,
    BasicTermsSettingsService,
    OutputsService,
    ModelCustomizationService,
    InterestRatesMapper,
    BondModelCustomizationMapper
  ]
})
export class AnalyticsModule { }
