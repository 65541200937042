import { ChangeDetectorRef, ElementRef, Injectable, OnDestroy } from '@angular/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ResizeHandlerService } from 'src/app/leversys-core-ui/services/resize-handler/resize-handler.service';

/**
 * This class hanles resize on tabstrip component
 * and manage whitch class should be used in style calculation
 */
@Injectable()
export class InstrumentUtil implements OnDestroy {

  public hasInstrumentTwoRows: boolean;
  public hasInstrumentThreeRows: boolean;
  public hasInstrumentFourRows: boolean;
  public hasInstrumentFiveRows: boolean;
  public hasInstrumentSixRows: boolean;
  public hasInstrumentSevenRows: boolean;
  public listening: boolean;
  public listenerId: string;

  private _element: TabStripComponent;
  private _elementRef: ElementRef;
  private _changeDetectorRef: ChangeDetectorRef;
  private _resizeHandlerService: ResizeHandlerService;

  constructor(resizeHandlerService: ResizeHandlerService, element: TabStripComponent, changeDetectorRef: ChangeDetectorRef) {
    this._elementRef = (element as any)?.wrapper;
    this._changeDetectorRef = changeDetectorRef;
    this._resizeHandlerService = resizeHandlerService;
    this._element = element;

    this.hasInstrumentTwoRows = false;
    this.hasInstrumentThreeRows = false;
    this.hasInstrumentFourRows = false;
    this.hasInstrumentFiveRows = false;
    this.hasInstrumentSixRows = false;
    this.hasInstrumentSevenRows = false;
  }

  /**
   * Start listening to resize event
   */
  startListening() {
    this._resizeHandlerService.listenTo(this._elementRef, (elementRect, listenerId) => {
      this.listenerId = listenerId;
      this.listening = true;

      const instrumentElements = Array.from(document.getElementsByClassName('instrument-tab'));
      const nativeElement = this._elementRef?.nativeElement;
      const nativeElementIndex = instrumentElements.findIndex(x => x === nativeElement);

      if (nativeElementIndex < 0) {
        return;
      }

      let rows = 0;
      let top = 0;

      const elements = instrumentElements[nativeElementIndex].getElementsByTagName('ul')[0].getElementsByTagName('li');

      for (let i = 0; i < elements.length; i++) {
        const elementPosition = elements[i].getBoundingClientRect();
        if (top !== elementPosition.top) {
          rows++;
          top = elementPosition.top;
        }
      }

      switch (rows) {
        case 1: {
          this.hasInstrumentTwoRows = false;
          this.hasInstrumentThreeRows = false;
          this.hasInstrumentFourRows = false;
          this.hasInstrumentFiveRows = false;
          this.hasInstrumentSixRows = false;
          this.hasInstrumentSevenRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 2: {
          this.hasInstrumentTwoRows = true;
          this.hasInstrumentThreeRows = false;
          this.hasInstrumentFourRows = false;
          this.hasInstrumentFiveRows = false;
          this.hasInstrumentSixRows = false;
          this.hasInstrumentSevenRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 3: {
          this.hasInstrumentThreeRows = true;
          this.hasInstrumentTwoRows = false;
          this.hasInstrumentFourRows = false;
          this.hasInstrumentFiveRows = false;
          this.hasInstrumentSixRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 4: {
          this.hasInstrumentFourRows = true;
          this.hasInstrumentTwoRows = false;
          this.hasInstrumentThreeRows = false;
          this.hasInstrumentFiveRows = false;
          this.hasInstrumentSixRows = false;
          this.hasInstrumentSevenRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 5: {
          this.hasInstrumentFiveRows = true;
          this.hasInstrumentTwoRows = false;
          this.hasInstrumentThreeRows = false;
          this.hasInstrumentFourRows = false;
          this.hasInstrumentSixRows = false;
          this.hasInstrumentSevenRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 6: {
          this.hasInstrumentSixRows = true;
          this.hasInstrumentTwoRows = false;
          this.hasInstrumentThreeRows = false;
          this.hasInstrumentFourRows = false;
          this.hasInstrumentFiveRows = false;
          this.hasInstrumentSevenRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 7: {
          this.hasInstrumentSevenRows = true;
          this.hasInstrumentTwoRows = false;
          this.hasInstrumentThreeRows = false;
          this.hasInstrumentFourRows = false;
          this.hasInstrumentFiveRows = false;
          this.hasInstrumentSixRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        default: {
          this.hasInstrumentTwoRows = false;
          this.hasInstrumentThreeRows = false;
          this.hasInstrumentFourRows = false;
          this.hasInstrumentFiveRows = false;
          this.hasInstrumentSixRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
      }
    });
  }

  stopListening() {
    this._resizeHandlerService.removeListener(this.listenerId);
  }

  ngOnDestroy() {
    this.stopListening();
  }

}
