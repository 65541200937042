<lv-flex-box class="lv-dividends-dividend-parameters">
    <lv-flex-box
      class="lv-equity-dividend-type"
      data-cy="DM-31"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendTypeTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendTypeTooltip'">
            Dividend Type
          </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          *ngIf="isEditable"
          name="dividendType"
          [data]="dividendTypeSource.items"
          valueField="id"
          textField="text"
          [(ngModel)]="dividendsSource.dividendParameters.dividendType"
          (ngModelChange)="onDividendParametersChange()"
          [valuePrimitive]="true"
          [popupSettings]="{ width: 131 }">
        </kendo-dropdownlist>
        <span *ngIf="!isEditable">
          {{displayNonEditableDividendType}}
        </span>
      </div>
    </lv-flex-box>
  <ng-container *ngIf="!isContinousYieldDividendType">
    <lv-flex-box
      class="lv-equity-dividend-value"
      data-cy="DM-309"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendValueTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendValueTooltip'">
          {{dividendValueLabel}}
          </span>
      </div>
      <div class="lv-input-field lv-input-field--right">
        <lv-numeric-text-box
          *ngIf="isEditable"
          name="dividendValue"
          [format]="fourNumberFormat"
          [decimals]="fourNumberOfDecimals"
          [(ngModel)]="dividendsSource.dividendParameters.dividendValue"
          (didBlur)="onDividendParametersChange()"
          (didKeyDownEnter)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span *ngIf="!isEditable">
          {{dividendsSource.dividendParameters.dividendValue}}
        </span>
        <span
          class="lv-sufix-label"
          [class.lv-suffix-no-value]="!dividendsSource.dividendParameters.dividendValue">
          {{dividendValueSuffix}}
        </span>
      </div>
    </lv-flex-box>
    <lv-flex-box
      class="lv-equity-dividend-frequency"
      data-cy="DM-310"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendFrequencyTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendFrequencyTooltip'">
            Dividend Frequency
          </span>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          *ngIf="isEditable"
          name="dividendFrequency"
          [data]="dividendFrequencySource.items"
          valueField="id"
          textField="text"
          [(ngModel)]="dividendsSource.dividendParameters.frequency"
          (ngModelChange)="onDividendParametersChange()"
          [valuePrimitive]="true">
        </kendo-dropdownlist>
        <span *ngIf="!isEditable">
          {{displayNonEditableDividendFrequency}}
        </span>
      </div>
    </lv-flex-box>
    <lv-flex-box
      class="lv-equity-dividend-value-growth"
      data-cy="DM-311"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendGrowthTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendGrowthTooltip'">
            Dividend Growth
          </span>
      </div>
      <div class="lv-input-field lv-input-field--right">
        <lv-numeric-text-box
          *ngIf="isEditable"
          name="dividendGrowth"
          [format]="fourNumberFormat"
          [decimals]="fourNumberOfDecimals"
          [(ngModel)]="dividendsSource.dividendParameters.dividendGrowth"
          (didBlur)="onDividendParametersChange()"
          (didKeyDownEnter)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span *ngIf="!isEditable">
          {{dividendsSource.dividendParameters.dividendGrowth}}
        </span>
        <span class="lv-sufix-label"
          [class.lv-suffix-no-value]="!dividendsSource.dividendParameters.dividendGrowth">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box
      class="lv-equity-dividend-date"
      data-cy="DM-312"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="firstDividendDateTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'firstDividendDateTooltip'">
            First Dividend Date
          </span>
      </div>
      <div class="lv-input-field lv-datePicker-container">
        <kendo-datepicker
          *ngIf="isEditable"
          name="firsDividendDate"
          [(ngModel)]="dividendsSource.dividendParameters.firstDividendDate"
          [lvMinDate]="dividendsSource.dividendParameters.firstDividendDate"
          [(ngModel)]="dividendsSource.dividendParameters.firstDividendDate"
          (blur)="onDividendParametersChange()"
          (keydown.enter)="onDividendParametersChange()">
        </kendo-datepicker>
        <span *ngIf="!isEditable">
          {{dateFormated}}
        </span>
      </div>
    </lv-flex-box>
    <lv-flex-box
      class="lv-equity-dividend-fixed-start-time"
      data-cy="DM-313"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="fixedYieldStartTimeTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'fixedYieldStartTimeTooltip'">
            Fixed Yield Start Time
          </span>
      </div>
      <div class="lv-input-field lv-input-field--right ">
        <lv-numeric-text-box
          *ngIf="isEditable"
          name="fixedDividendStartTime"
          [format]="fourNumberFormat"
          [decimals]="fourNumberOfDecimals"
          [(ngModel)]="dividendsSource.dividendParameters.fixedDividendStartTime"
          (didKeyDownEnter)="onDividendParametersChange()"
          (didBlur)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span *ngIf="!isEditable">
          {{dividendsSource.dividendParameters.fixedDividendStartTime}}
        </span>
        <span class="lv-sufix-label" [class.lv-suffix-no-value]="!dividendsSource.dividendParameters.fixedDividendStartTime">yrs</span>
      </div>
    </lv-flex-box>

  </ng-container>
  <ng-container *ngIf="isContinousYieldDividendType">
    <lv-flex-box
      class="lv-equity-dividend-value"
      data-cy="DM-309"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendYieldTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendYieldTooltip'">
            Dividend Value
          </span>
      </div>
      <div class="lv-input-field lv-input-field--right">
        <lv-numeric-text-box
          *ngIf="isEditable"
          name="dividendYield"
          [format]="fourNumberFormat"
          [decimals]="fourNumberOfDecimals"
          [(ngModel)]="dividendsSource.dividendParameters.dividendYield"
          (didBlur)="onDividendParametersChange()"
          (didKeyDownEnter)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span *ngIf="!isEditable">
          {{dividendsSource.dividendParameters.dividendYield}}
        </span>
        <span class="lv-sufix-label"
          [class.lv-suffix-no-value]="!dividendsSource.dividendParameters.dividendYield">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box
      class="lv-dividend-yield-note"
      *ngIf="!isEditable">
      <lv-flex-box>
        <div class="lv-label lv-label-field lv-header-label">IMPORTANT NOTE</div>
      </lv-flex-box>
      <lv-flex-box direction="row">
        <div class="lv-label lv-label-field lv-dividend-yield-note-content">
          Please note that Continuous Yield option does not take into account dividend protection.
          To use dividend protection with dividend yield as input, please select Discrete Yield option.
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </ng-container>
</lv-flex-box>

<ng-template #dividendTypeTooltipTemplate>
  <lv-tooltip dm="dM-31"></lv-tooltip>
</ng-template>
<ng-template #dividendValueTooltipTemplate>
  <lv-tooltip dm="dM-309"></lv-tooltip>
</ng-template>
<ng-template #dividendFrequencyTooltipTemplate>
  <lv-tooltip dm="dM-310"></lv-tooltip>
</ng-template>
<ng-template #dividendGrowthTooltipTemplate>
  <lv-tooltip dm="dM-311"></lv-tooltip>
</ng-template>
<ng-template #firstDividendDateTooltipTemplate>
  <lv-tooltip dm="dM-312"></lv-tooltip>
</ng-template>
<ng-template #fixedYieldStartTimeTooltipTemplate>
  <lv-tooltip dm="dM-313"></lv-tooltip>
</ng-template>
<ng-template  #dividendYieldTooltipTemplate>
  <lv-tooltip dm="dM-309"></lv-tooltip>
</ng-template>

