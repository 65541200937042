import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
   ChangeDetectorRef, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Optional, OnChanges } from '@angular/core';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { constants } from '@lv-core-ui/util';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvExcelService } from '@lv-excel/services';
import { FloatingSpreadScheduleItem } from '@lv-instrument-common/FloatingSpreadScheduleItem';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';

@Component({
  selector: 'lv-floating-spread-schedule',
  templateUrl: './lv-floating-spread-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFloatingSpreadScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: FloatingSpreadScheduleItem[];
  @Output() didFloatingSpreadScheduleChange: EventEmitter<FloatingSpreadScheduleItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  floatingSpreadScheduleItems: FloatingSpreadScheduleItem[];
  parseFn: any;
  excelFieldAlias = 'CPN_SCHED_FLOAT_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {
      this.initColumns();
      this.parseFn = this.parserFunction.bind(this);

      this.floatingSpreadScheduleItems = [];
      this._modelSubscription = [];
      this.didFloatingSpreadScheduleChange = new EventEmitter<FloatingSpreadScheduleItem[]>();
    }

  ngOnInit() {
    this._modelSubscription = [
        this.advancedGrid.didDataChange.subscribe((records: FloatingSpreadScheduleItem[]) => this.onScheduleChange(records)),
        this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.floatingSpreadScheduleItems.splice(0, this.floatingSpreadScheduleItems.length);
    this.floatingSpreadScheduleItems.push(...this.model);

    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
    this.floatingSpreadScheduleItems = this.floatingSpreadScheduleItems.map(a => ({ ...a }));
    this.floatingSpreadScheduleItems.splice(0, this.floatingSpreadScheduleItems.length);
    this.floatingSpreadScheduleItems.push(...this.model);
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate, Validators.required),
      'spread': new FormControl(args.dataItem.spread, Validators.required),
      'floor': new FormControl(args.dataItem.floor),
      'cap': new FormControl(args.dataItem.cap),
    });
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.onScheduleChange(records));
  }

  private initColumns() {
    this.columns = [];

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'endDate';
    endDateColumn.dmKey = 'DM-2263';

    const spreadColumn = new LvAdvancedGridNumericColumn();
    spreadColumn.title = 'Spread (%)';
    spreadColumn.field = 'spread';
    spreadColumn.outputFormat = constants.numberFormat.upToFourDigits;
    spreadColumn.format = '#.####';
    spreadColumn.decimals = '4';
    spreadColumn.dmKey = 'DM-2264';

    const floorColumn = new LvAdvancedGridNumericColumn();
    floorColumn.title = 'Floor (%)';
    floorColumn.field = 'floor';
    floorColumn.outputFormat = constants.numberFormat.upToFourDigits;
    floorColumn.format = '#,###.####';
    floorColumn.decimals = '4';
    floorColumn.dmKey = 'DM-2265';

    const capColumn = new LvAdvancedGridNumericColumn();
    capColumn.title = 'Cap (%)';
    capColumn.field = 'cap';
    capColumn.outputFormat = constants.numberFormat.upToFourDigits;
    capColumn.format = '#,###.####';
    capColumn.decimals = '4';
    capColumn.dmKey = 'DM-2266';

    this.columns.push(endDateColumn);
    this.columns.push(spreadColumn);
    this.columns.push(floorColumn);
    this.columns.push(capColumn);
  }

  private parserFunction(pastedDataRecords: string[]): FloatingSpreadScheduleItem[] {
    const scheduleItems: FloatingSpreadScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const endDateValue = items[0];
      const spreadValue = items[1];
      const floorValue = items[2];
      const capValue = items[3];

      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
      const spread = MarketDataClipboard.parseNumberValue(spreadValue, 'Spread (%)');
      const floor = MarketDataClipboard.parseNumberValue(floorValue, 'Floor (%)');
      const cap = MarketDataClipboard.parseNumberValue(capValue, 'Cap (%)');

      scheduleItems.push({
        endDate: endDate,
        spread: spread,
        floor: floor,
        cap: cap
      } as FloatingSpreadScheduleItem);
    });

    return scheduleItems;
  }

  private onScheduleChange(scheduleItems: FloatingSpreadScheduleItem[]) {
    this.model.splice(0, this.model.length);
    this.model.push(...scheduleItems);
    this.didFloatingSpreadScheduleChange.next(this.model);
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

}
