import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBaseBondTermsSettings, IBondCallSectionSettings } from '../../../models/bond-terms-settings';

@Component({
  selector: 'lv-bond-call-settings',
  templateUrl: './lv-bond-call-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondCallSettingsComponent {
  @Input() systemDefaults: IBaseBondTermsSettings<IBondCallSectionSettings>;
  @Input() myDefaults: IBaseBondTermsSettings<IBondCallSectionSettings>;

  constructor() { }

  @HostBinding('class.lv-call-settings')
  get isLvCallSettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
