/**
 * Dividend source description.
 */
export enum DividendsSourceAswEnumDescription {
  NoDividends = 'No Dividends',
  Parameters = 'Parameters',
  Schedule = 'Schedule',
  UnderlyingCBData = 'Underlying CB Data',
  UnderlyingEquityDataParameters = 'Underlying Equity Data - Parameters',
  UnderlyingEquityDataSchedule = 'Underlying Equity Data - Schedule',
}

/**
 * Types of dividends source/
 */
export enum DividendsSourceAswEnum {
  NoDividends = 'NoDividends',
  Parameters = 'Parameters',
  Schedule = 'Schedule',
  UnderlyingCBData = 'UnderlyingCBData',
  UnderlyingEquityDataParameters = 'UnderlyingEquityDataParameters',
  UnderlyingEquityDataSchedule = 'UnderlyingEquityDataSchedule',
}

