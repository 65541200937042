<lv-flex-box *ngIf="isSectionVisible" class="lv-equity-terms">
  <lv-flex-box
    direction="row"
    data-cy="DM-772">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="instrumentTypeTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span>
        Instrument Type
      </span>
      <lv-xl-label [field]="'INST_TYPE'"></lv-xl-label>
    </div>
    <div class="lv-equity-terms-instrument-type-output">
      Equity
    </div>
  </lv-flex-box>
  <lv-flex-box
    data-cy="DM-4899"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="equityTypeTooltip"
      filter="span">
      <span> Equity Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        [popupSettings]="{ width: 126 }"
        class="lv-equity-type"
        name="terms-equity-type"
        valueField="id"
        textField="text"
        [data]="equityTypeEnum.items"
        [valuePrimitive]="true"
        [(ngModel)]="equityTerms.terms.type"
        (ngModelChange)="setSectionStateChanged()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box
    direction="row"
    data-cy="DM-334">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="instrumentNameTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'instrumentNameTooltip'">
        Instrument Name
      </span>
    </div>
    <div class="lv-input-field lv-input-field--full lv-input-field-instrument">
      <lv-text-box
        class="lv-equity-terms-instrument-name"
        [(ngModel)]="equityTerms.terms.name"
        name="equity-terms-instrument-name"
        (didBlur)="setSectionStateChanged()"
        (didKeyDownEnter)="setSectionStateChanged()">
      </lv-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box
    direction="row"
    data-cy="DM-4860">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="bloombergTickerTooltipTemplate"
      filter="span">
      <span>
        Bloomberg Ticker
      </span>
    </div>
    <div class=" lv-input-field lv-input-field--full lv-input-field-instrument">
      <lv-text-box
        class="lv-equity-terms-bloomberg-ticker"
        [(ngModel)]="equityTerms.terms.bloombergTicker"
        name="equity-terms-bloomberg-ticker"
        (didBlur)="setSectionStateChanged()"
        (didKeyDownEnter)="setSectionStateChanged()">
      </lv-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box
    direction="row"
    data-cy="DM-348">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="issuerNameTooltipTemplate"
      filter="span">
      <span>
        Issuer Name
      </span>
    </div>
    <div class=" lv-input-field lv-input-field--full lv-input-field-instrument">
      <lv-text-box
        [(ngModel)]="equityTerms.terms.issuerName"
        class="lv-equity-terms-issuer-name"
        name="equity-terms-issuer-name"
        (didBlur)="setSectionStateChanged()"
        (didKeyDownEnter)="setSectionStateChanged()">
      </lv-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box
    data-cy="DM-349"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="equityTermsCountryTooltip"
      filter="span">
      <span> Country </span>
    </div>
    <div class="lv-input-field lv-input-field--auto lv-equity-terms-country">
      <kendo-dropdownlist
          name="equity-terms-country"
          [popupSettings]="{ width: 128 }"
          [data]="countries"        
          [(ngModel)]="country"
          (ngModelChange)="onCountryChanged()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
    <lv-flex-box
      data-cy="DM-350"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="equityTermsRiskCountryTooltip"
        filter="span">
        <span> Risk Country </span>
      </div>
      <div class="lv-input-field lv-input-field--auto lv-equity-terms-risk-country">
        <kendo-dropdownlist
          name="risk-equity-country"
          [popupSettings]="{ width: 128 }"
          [data]="countries"
          [(ngModel)]="riskCountry"
          (ngModelChange)="onRiskCountryChanged()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
  <lv-flex-box
    data-cy="DM-1287"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="exchangeEquityTooltip"
      filter="span">
      <span> Exchange </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        class="lv-equity-exchange"
        name="equity-country"
        [data]="exchanges"
        [(ngModel)]="equityTerms.terms.exchangeCode"
        (ngModelChange)="setSectionStateChanged()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box
    data-cy="DM-379"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="ccyEquityTooltip"
      filter="span:not(lv-xl-label span)">
      <span> CCY </span>
      <lv-xl-label [field]="'CCY'"></lv-xl-label>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        class="lv-equity-ccy"
        name="equity-ccy"
        valueField="id"
        textField="text"
        valuePrimitive="true"
        [data]="currencyLookup.items"
        [(ngModel)]="equityTerms.terms.currencyCode"
        (ngModelChange)="setSectionStateChanged()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box  
    direction="row"
    data-cy="DM-4861">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="priceScaleEquityTooltip"
      filter="span">
      <span>
        Price Scale
      </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        class="lv-equity-price-scale"
        name="price-scale-equity"
        [format]="formatTwo"
        [decimals]="decimalsTwo"
        [(ngModel)]="equityTerms.terms.priceScale"
        (didBlur)="setSectionStateChanged()"
        (didKeyDownEnter)="setSectionStateChanged()">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  
  <lv-flex-box
  direction="row">
  <div
    class="lv-equity-terms-save-reload-controls"
    kendoTooltip 
    data-cy="">
    <div
      class="lv-icon-square"
      title="Save"
      lvId="equityTermsSaveAll"
      (click)="onSave()">
      <i class="k-icon k-i-save"></i>
    </div>
    <div
      class="lv-icon-square"
      title="Reload"
      lvId="equityTermsReloadAll"
      (click)="onReload()">
      <i class="k-icon k-i-reload"></i>
    </div>
</div>
</lv-flex-box>
</lv-flex-box>

<div *ngIf="!isSectionVisible" class="lv-instrument-no-data">
  Please select an instrument.
</div>

<ng-template #instrumentTypeTooltipTemplate>
  <lv-tooltip dm="dM-772"></lv-tooltip>
</ng-template>
<ng-template #instrumentNameTooltipTemplate>
  <lv-tooltip dm="dM-334"></lv-tooltip>
</ng-template>
<ng-template #exchangeEquityTooltip>
  <lv-tooltip dm="dM-1287"></lv-tooltip>
</ng-template>
<ng-template #ccyEquityTooltip>
  <lv-tooltip dm="dM-379"></lv-tooltip>
</ng-template>
<ng-template #priceScaleEquityTooltip>
  <lv-tooltip dm="dM-4861"></lv-tooltip>
</ng-template>
<ng-template #equityTermsCountryTooltip>
  <lv-tooltip dm="dM-349"></lv-tooltip>
</ng-template>
<ng-template #equityTermsRiskCountryTooltip>
  <lv-tooltip dm="dM-350"></lv-tooltip>
</ng-template>
<ng-template #equityTypeTooltip>
  <lv-tooltip dm="dM-4899"></lv-tooltip>
</ng-template>
<ng-template #bloombergTickerTooltipTemplate>
  <lv-tooltip dm="dM-4860"></lv-tooltip>
</ng-template>
<ng-template #issuerNameTooltipTemplate>
  <lv-tooltip dm="dM-348"></lv-tooltip>
</ng-template>