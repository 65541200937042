/**
 * Types of borrow source.
 */
export enum BorrowSource {
  Flat = 'Flat',
  Rebate = 'Rebate',
  TermStructure = 'TermStructure',
  UnderlyingEquityDataFlat = 'UnderlyingEquityDataFlat',
  UnderlyingEquityDataRebate = 'UnderlyingEquityDataRebate',
  UnderlyingEquityDataTermStructure = 'UnderlyingEquityDataTermStructure'
}

/**
 * Borrow source descriptions.
 */
export enum BorrowSourceDescription {
  Flat = 'Flat',
  Rebate = 'Rebate',
  TermStructure = 'Term Structure',
  UnderlyingEquityDataFlat = 'Underlying Equity Data - Flat',
  UnderlyingEquityDataRebate = 'Underlying Equity Data - Rebate',
  UnderlyingEquityDataTermStructure = 'Underlying Equity Data - Term Structure'
}
