<lv-flex-box class="lv-borrow-rebate">
  <lv-flex-box direction="row" data-cy="DM-303">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="borrowRebateTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.borrow-tooltip-id]="'rebateRateTooltip'">
          Rebate Rate
        </span>
        <lv-xl-label [field]="'BRW_REBATE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        #borrowRebateRate
        *ngIf="isEditable"
        name="rebateRate"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="borrowSettings.rebateRate"
        (didBlur)="onRebateSectionChanged()"
        (didKeyDownEnter)="onRebateSectionChanged()">
      </lv-numeric-text-box>
      <span *ngIf="!isEditable">
        {{borrowSettings.rebateRate}}
      </span>
      <span class="lv-sufix-label"
        [class.lv-suffix-no-value]="!borrowSettings.rebateRate">%</span>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-304">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="borrowFoundingRateTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.borrow-tooltip-id]="'fundingRateTooltip'">
          Funding Rate
        </span>
        <lv-xl-label [field]="'BRW_FUND_RATE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        #borrowFundingRate
        *ngIf="isEditable"
        name="fundingRate"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="borrowSettings.fundingRate"
        (didBlur)="onRebateSectionChanged()"
        (didKeyDownEnter)="onRebateSectionChanged()">
      </lv-numeric-text-box>
      <span *ngIf="!isEditable">
        {{borrowSettings.fundingRate}}
      </span>
      <span class="lv-sufix-label"
        [class.lv-suffix-no-value]="!borrowSettings.fundingRate">%</span>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #borrowRebateTooltipTemplate>
  <lv-tooltip dm="dM-303"></lv-tooltip>
</ng-template>
<ng-template #borrowFoundingRateTooltipTemplate>
  <lv-tooltip dm="dM-304"></lv-tooltip>
</ng-template>
