import { v4 } from 'uuid';

import { LvConversionPriceEstimateView } from './view/lv-conversion-price-estimate.view';
import { LvNewIssueAssumptionsView } from './view/lv-new-issue-assumptions.view';
import { LvValuationInputsView } from './view/lv-valuation-inputs.view';
import { DateExtensions } from '@lv-core-ui/util';
import { AnalyticsSettingsHelper } from '@lv-analytics/helpers';
import { IPricingWidgetState, IAnalyticsSettings, IConvertible, ILoadPricingResponse, ICrEstimateSettings, IValuationInputs,
         INewIssueAssumptions, IMarketData, IPricing, ISavePricingRequest, IOverrideCrEstimateRequest, IOverrideNewIssueAssumptionsRequest,
         ICalculateYieldOrRedemptionRequest, IValuation, IConvertibleValuation, StockReferenceType } from '@lv-analytics/models';
import { LvExcelService } from '@lv-excel/services';
import { environment } from 'src/environments/environment';
import { LvDateService } from '@lv-core-ui/services';
import { DatePipe, DecimalPipe } from '@angular/common';

/**
 * Pricing view.
 */
export class LvPricingView {

  asHelper: AnalyticsSettingsHelper;
  cpeView: LvConversionPriceEstimateView;
  viView: LvValuationInputsView;
  niaView: LvNewIssueAssumptionsView;
  pricingWidgetState: IPricingWidgetState;

  isModalOpened: boolean;
  isEstimateButtonClick: boolean;
  isNewIssueAssumptionsButtonClick: boolean;

  useCurrentValueSidePanelRadioButtonId: string;
  useEstimateValueSidePanelRadioButtonId: string;

  private _excelService: LvExcelService;

  get isExpectedRatioVisible(): boolean {
    return this.viView.isCrEffectiveDateInFutureAndIsLessThanValuationDate;
  }

  get stkRefCbCcyAlias(): string {
    return !this._excelService.isV31() ? 'STK_REF,STK_REF_CBCCY,STK_REF_CPCCY' : 'UND_PRICE,UND_PRICE_INST_CCY,UND_PRICE_CP_CCY';
  }

  get stkRefEqCcyAlias(): string {
    return !this._excelService.isV31() ? 'STK_REF_EQCCY' : 'UND_PRICE_UND_CCY';
  }

  get stkRefEqCrossFxCcyAlias(): string {
    return !this._excelService.isV31() ? 'STK_REF,STK_REF_EQCCY,STK_REF_CPCCY' : 'UND_PRICE,UND_PRICE_UND_CCY,UND_PRICE_CP_CCY';
  }

  constructor(
    decimalPipe: DecimalPipe,
    datePipe: DatePipe,
    excelService: LvExcelService,
    private _lvDateService: LvDateService
  ) {
    this.asHelper = new AnalyticsSettingsHelper();
    this.cpeView = new LvConversionPriceEstimateView(decimalPipe, _lvDateService);
    this.viView = new LvValuationInputsView(decimalPipe, datePipe, _lvDateService, excelService);
    this.niaView = new LvNewIssueAssumptionsView(decimalPipe, _lvDateService);
    this.isEstimateButtonClick = false;
    this.isNewIssueAssumptionsButtonClick = false;
    this.isModalOpened = false;
    this._excelService = excelService;

    this.useCurrentValueSidePanelRadioButtonId = v4();
    this.useEstimateValueSidePanelRadioButtonId = v4();
  }

  /**
   * View initialization.
   * @param settings IAnalyticsSettings object.
   * @param pricingWidgetState IPricingWidgetState object.
   */
  init(settings: IAnalyticsSettings, pricingWidgetState: IPricingWidgetState) {
    this.asHelper.init(settings);
    this.pricingWidgetState = pricingWidgetState;
    this.initSubViews();
  }

  /**
   * Subview initialization.
   */
  initSubViews() {
    this.cpeView.init(
      this.asHelper.cpeSettings,
      this.asHelper.convertible,
      this.pricingWidgetState);

    this.viView.init(
      this.asHelper.valuationSettings,
      this.asHelper.pricing,
      this.asHelper.convertible,
      this.asHelper.cpeSettings,
      this.asHelper.valuationSession?.modelCustomization?.includeCashRebateInParity);


      //TODO: this is dirty workaround. When open in excel form only STK_REF or STK_REF_CBY_CCY is provided then stockPriceUndCcy is empty 
      // and it is recalculated as part of viView init (and shown for UI) but niaView init needs this value in pricing in order to populate placeholders for issue stock ref
      if (this.asHelper.pricing) {
        this.asHelper.pricing.stockPriceUndCcy = this.viView.model?.stockPriceUndCcy;
      }

    this.niaView.init(
      this.asHelper.niaSettings,
      this.asHelper.convertible,
      this.asHelper.pricing?.stockSlippage ?? this.viView.marketData?.stockSlippage,
      this.asHelper.pricing
    );
  }
    
   

  /**
   * Updates convertible.
   * @param convertible IConvertible object.
   */
  updateConvertible(convertible?: IConvertible) {
    this.asHelper.updateConvertilbe(convertible);
    this.cpeView.updateConvertible(convertible);
    this.niaView.updateConvertible(convertible, this.asHelper.pricing?.stockSlippage);
    this.niaView.updateAssumedNewIssueAssumptions();
    this.viView.updateConvertible(convertible);
  }

  /**
   * Loads pricing.
   * @param response ILoadPricingResponse object.
   */
  loadPricing(response: ILoadPricingResponse) {
    this.asHelper.settings.valuationSession.pricing = response.pricingSettings;
    this.asHelper.settings.valuationSession.valuationSettings.marketData = response.marketDataBase;

    this.initSubViews();
  }

  /**
   * Applies pricing.
   * @param crEstimateSettings ICrEstimateSettings objects.
   * @param valuationInputs IValuationInputs objects.
   * @param newIssueAssumptions INewIssueAssumptions objects.
   */
  applyPricing(
    crEstimateSettings: ICrEstimateSettings,
    valuationInputs: IValuationInputs,
    newIssueAssumptions: INewIssueAssumptions
  ) {
    this.cpeView.update(crEstimateSettings);
    this.viView.update(valuationInputs);
    this.niaView.update(newIssueAssumptions);
  }

  /**
   * Applies settings.
   * @param settings IMarketData object.
   */
  applyMarketData(settings: IMarketData) {
    
    this.niaView.init(
      this.asHelper.niaSettings,
      this.asHelper.convertible,
      this.asHelper.pricing?.stockSlippage ?? settings.other?.stockSlippage,      
      this.asHelper.pricing
     );

    this.viView.handleDividendsChanged(settings.dividends);
    this.viView.handleBorrowChanged(settings.borrow);
    this.viView.handleCreditChanged(settings.credit);
    this.viView.handleVolatilityChanged(settings.volatility);
    this.viView.handleOtherMarketDataChanged(settings.other);
  }

  /**
   * Gets pricing.
   * @returns IPricing object.
   */
  getPricing(): IPricing {
    return {
      valuationDate: {... new Date(this.viView.model.valuationDate) },
      price: this.viView.model.price,
      crossFx: this.viView.model.crossFx,
      stockPriceCbCcy: this.viView.model.stockPriceCbCcy,
      stockPriceUndCcy: this.viView.model.stockPriceUndCcy,
      flatBorrow: this.viView.model.flatBorrow,
      flatCreditSpread: this.viView.model.flatCreditSpread,
      flatDividendYield: this.viView.model.flatDividendYield,
      flatInterestRate: this.viView.model.flatInterestRate,
      flatVolatility: this.viView.model.flatVolatility,
      stockSlippage: this.viView.model.stockSlippage,
      upsideVol: this.viView.model.upsideVol,
      downsideVol: this.viView.model.downsideVol,
      crEstimateSettings: { ...this.cpeView.model },
      newIssueAssumptions: { ...this.niaView.model },
    } as IPricing;
  }

  /**
   * Gets save pricing request.
   * @param environmentId Environment ID.
   * @returns ISavePricingRequest object.
   */
  getSavePricingRequest(environmentId: string): ISavePricingRequest {
    return {
      environmentId: environmentId,
      lwsIdentifier: this.asHelper.valuationSession.lwsIdentifier,
      pricingSettings: this.getPricing(),
      leversysLocalId: this.asHelper.settings.valuationSession.leversysLocalId,
    } as ISavePricingRequest;
  }

  /**
   * Gets Cpe request.
   * @param environmentId Environment ID.
   * @param sourceId Source ID.
   * @returns IOverrideCrEstimateRequest object.
   */
  getCpeRequest(environmentId: string, sourceId: string): IOverrideCrEstimateRequest {
    return {
      sessionId: this.asHelper.sessionId,
      lwsIdentifier: this.asHelper.valuationSession.lwsIdentifier,
      environmentId: environmentId,
      crEstimateSettings: { ...this.cpeView.model },
      sourceId: sourceId,
      isOpenInstrumentFromExcelRequest: !!this._excelService?.isInitialized(),
    } as IOverrideCrEstimateRequest;
  }

  /**
   * Gets Nia request.
   * @param environmentId Environment ID.
   * @returns IOverrideNewIssueAssumptionsRequest object.
   */
  getNiaRequest(environmentId: string): IOverrideNewIssueAssumptionsRequest {
    return {
      sessionId: this.asHelper.valuationSession.sessionId,
      lwsIdentifier: this.asHelper.valuationSession.lwsIdentifier,
      environmentId: environmentId,
      userAssumptions: { ...this.niaView.model }
    } as IOverrideNewIssueAssumptionsRequest;
  }

  /**
   * Gets calculate yield or redemption request.
   * @param environmentId Environment ID.
   * @returns ICalculateYieldOrRedemptionRequest object.
   */
  getCalculateYieldOrRedemptionRequest(environmentId: string): ICalculateYieldOrRedemptionRequest {
    return {
      sessionId: this.asHelper.valuationSession.sessionId,
      lwsIdentifier: this.asHelper.valuationSession.lwsIdentifier,
      environmentId: environmentId,
      isPrivateInstrument: this.asHelper.valuationSession.isPrivateInstrument,
      userAssumptions: { ...this.niaView.model }
    } as ICalculateYieldOrRedemptionRequest;
  }

  /**
   * Gets nia model.
   * @returns INewIssueAssumptions object.
   */
  getNiaModel(): INewIssueAssumptions {
    return this.niaView.getModel();
  }

  /**
   * Sets user assumptions issue yield.
   * @param issueYield Issue yield.
   */
  setUserAssumptionsIssueYield(issueYield: number) {
    this.niaView.model.issueYield = issueYield;
  }

  /**
   * Sets user assumptions redemption.
   * @param redemptionValue Redemption value.
   */
  setUserAssumptionsRedemption(redemptionValue: number) {
    this.niaView.model.redemptionValue = redemptionValue;
  }

  /**
   * Returns Valuation object with data collected from the view.
   * @param {boolean} isFromExcel flag showing that app is runded from excel
   */
  getValuation(isFromExcel: boolean = false): IValuation {
    return {
      date: DateExtensions.getTimeWithOffset(this.viView.model.valuationDate),
      stockPriceCB: this.viView.model.stockPriceCbCcy,
      underlyingPrice: this.viView.model.stockPriceUndCcy,
      parity: this.viView.model.parity,
      convertiblePrice: this.viView.model.price,
      crossFx: this.viView.model.crossFx,
      creditSpread: this.viView.model.flatCreditSpread,
      flatInterestRate: isFromExcel ? null : this.viView.model.flatInterestRate,
      volatility: this.viView.model.flatVolatility,
      borrow: this.viView.model.flatBorrow,
      dividendYield: this.viView.model.flatDividendYield,
      upsideVolatility: this.viView.model.upsideVol,
      downsideVolatility: this.viView.model.downsideVol,
      percentOfPar: this.asHelper.convertible.isPriceAsPar,
      stockSlippage: this.viView.model.stockSlippage,
    };
  }

  /**
   * Returns ConvertibleValuation object with data collected from the view.
   * @param {boolean} logRequestXml flag showing whTether user has requested Send Valuation Query or not
   * @param {string} valuationQueryNotes notes that user entered on requested Send Valuation Query
   * @param {string} draftId draft identifier
   * @param {boolean} isFromExcel flag showing that app is runded from excel
   */
  getConvertibleValuation(logRequestXml: boolean,
                          valuationQueryNotes: string,
                          draftId: string,
                          isFromExcel: boolean = false): IConvertibleValuation {
    const valuation = this.getValuation(isFromExcel);
    return {
      instrumentId: this.asHelper.convertible.convertibleId,
      lwsIdentifier: this .asHelper.valuationSession.lwsIdentifier,
      sessionId: this.asHelper.valuationSession.sessionId,
      logRequestXml: logRequestXml,
      valuationQueryNotes: valuationQueryNotes,
      draftId: draftId,
      correlationId: (window as any).Cypress !== undefined || environment.playwrightVariable ? sessionStorage.getItem('isDisplayPricer') === 'true' ? 'cypressDisplayPricerCorrelationId' : 'cypress' + v4() : '',
      ...valuation
    };
  }

  /**
   * Sets a estimate button click flag.
   * @param isEstimateButtonClick A flag indicating if estimate button is clicked.
   */
  setIsEstimateButtonClick(isEstimateButtonClick: boolean) {
    if (this.isEstimateButtonClick && isEstimateButtonClick) {
      this.isEstimateButtonClick = false;
      return;
    }

    if (this.isModalOpened) {
       // this timeouts is only to prevent user to see other content on panels while panel slidig
      setTimeout(() => {
        this.isEstimateButtonClick = isEstimateButtonClick;
      });
    }
    else {
      this.isEstimateButtonClick = isEstimateButtonClick;
    }
  }

  /**
   * Sets new issue assumptions button click flag.
   * @param isNewIssueAssumptionsButtonClick A flag indicating if new issue assumptions button is clicked.
   */
  setIsNewIssueAssumptionsButtonClick(isNewIssueAssumptionsButtonClick: boolean) {
    if (this.isNewIssueAssumptionsButtonClick && isNewIssueAssumptionsButtonClick) {
      setTimeout(() => {
        this.isNewIssueAssumptionsButtonClick = false;
        return;
      });
    }

    if (this.isModalOpened) {
      setTimeout(() => {
        this.isNewIssueAssumptionsButtonClick = isNewIssueAssumptionsButtonClick;
      });
    }
    else {
      this.isNewIssueAssumptionsButtonClick = isNewIssueAssumptionsButtonClick;
    }
  }

  /**
   * Occurs on panel closed.
   */
  onPanelClosed() {

    setTimeout(() => {
      this.isEstimateButtonClick = false;
      this.isNewIssueAssumptionsButtonClick = false;
    });
  }

  /**
   * Occurs on panel slide.
   * @param isExpanded A flag indicating if panel is expanded.
   */
  onPanelSlide(isExpanded: boolean) {
    if (!isExpanded) {
      setTimeout(() => {
        this.isEstimateButtonClick = false;
        this.isNewIssueAssumptionsButtonClick = false;
      });
    }

    this.isModalOpened = isExpanded;
  }

  /**
   * Return alias value for xl label tooltip
   * @param value field name
   * @returns Alias string representation
   */
  getFieldAlias(value: string): string {
    switch (value) {
      case 'premium':
        return 'ASSUMED_PREM_PRC';
      case 'coupon':
        return 'ASSUMED_CPN_RT';
      case 'issuePrice':
        return 'ASSUMED_ISSUE_PX';
      case 'redemptionValue':
        return 'ASSUMED_REDEM_PX';
      case 'issueYield':
        return 'ASSUMED_YLD';
    }
  }
}
