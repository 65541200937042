export enum TermsFieldsIdentifiers {
    CCY = 'DM-379',
    UND_CCY = 'DM-380',
    SetupStatus = 'DM-1403',
    CBType = 'DM-335',
    ExchangeableType = 'DM-999',
    DeltaNeutral = 'DM-897',
    ConversionRatio = 'DM-362',
    HigherStrikePrice = 'DM-839',
    LowerStrikePrice = 'DM-841',
    FixedFXRate = 'DM-355',
    PremiumBestMidWorst = 'DM-4348',
    CouponBestMidWorst = 'DM-1304',
    IssuePriceBestMidWorst = 'DM-1305',
    RedemptionValueBestMidWorst = 'DM-1306',
    IssueYieldBestMidWorst = 'DM-4349',
    FixedStockReferenceCBCCY = 'DM-4618',
    FixedStockReferenceEQCCY = 'DM-4636',
    RebateCCY = 'DM-895',
    PriceAsPar = 'DM-382',
    Nominal = 'DM-385',
    FirstSettlementDate = 'DM-370',
    MaturityDate = 'DM-371',
    CouponType = 'DM-24',
    Perpetual = 'DM-990',
}
