import { Component, ChangeDetectionStrategy, ViewEncapsulation, EventEmitter, Output, HostBinding, ViewChild, ChangeDetectorRef } from '@angular/core';
import { LvMailNotificationsSettingsComponent } from '../lv-mail-notifications-settings/lv-mail-notifications-settings.component';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import * as _ from 'lodash';

/**
 * Component responsible for notification settings management
 */
@Component({
  selector: 'lv-notifications-settings',
  templateUrl: './lv-notifications-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvNotificationsSettingsComponent implements IDiscardable {

  @Output() didCancel: EventEmitter<void>;

  @ViewChild('lvMailNotificationsSettingsComponent') lvMailNotificationsSettingsComponent: LvMailNotificationsSettingsComponent;

  constructor(
    private _discardChangesService: DiscardChangesService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    this.didCancel = new EventEmitter<void>();    
    this._discardChangesService.register('Notifications', this);
  }

  @HostBinding('class.lv-notifications-settings')
  get isLvNotificationsSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-section-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  get isComponentChanged() {
    return this.isChanged();
  }

  /**
   * Cancel editing notification settings
   */
  onCancel() {
    this.didCancel.next();
  }

  /**
   * Detect changes.
   */
  detectChanges() {
    this._changeDetectorRef.detectChanges();
  }

  isChanged(): boolean {
    return !_.isEqual(this.lvMailNotificationsSettingsComponent?.userSettingsData, this.lvMailNotificationsSettingsComponent?.originalSettingsData);
  }

  discardChanges(): void {
    this.lvMailNotificationsSettingsComponent.userSettingsData = _.cloneDeep(this.lvMailNotificationsSettingsComponent.originalSettingsData);
    this.lvMailNotificationsSettingsComponent.detectChanges();
  }
}
