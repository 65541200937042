import { PutScheduleItem } from './PutScheduleItem';
import { PutValueType } from './enums/PutValueType';
import { NoticePeriod } from './enums/PutNoticePeriod';
import { DayCount, Frequency } from '@lv-instrument-common/index';

export class Put {
    valueType: PutValueType;
    yieldFrequency: Frequency;
    keepAccrued: boolean;
    forfeitCoupon: boolean;
    notice: number;
    noticePeriod: NoticePeriod;
    yieldDaysCount: DayCount;
    puttableDuringPeriod: boolean;
    scheduleItems: PutScheduleItem[];
    partialPut: boolean
    currentNotionalPct: number;
    totalTranchePct: number;
}
