import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService, LvLoggerService } from '@lv-core-ui/services';
import { IToolsLogEvent, SectionUsed, ITermsSummaryLogEvent, IBaseLogEvent,
  IOpenInExcelFormEvent, IOpenInstrumentEvent } from '@lv-shared/models';
import { LvSharedUserActivityService } from '@lv-shared/services';
import { LvUserActivityError } from '@lv-user-activity/models/errors';

/**
 * User Activity service for managing user activities.
 */
@Injectable()
export class UserActivityService extends HttpClientBase implements LvSharedUserActivityService, OnDestroy {

  deltaNeutralSubject: Subject<IToolsLogEvent>;
  breakEvenSubject: Subject<IToolsLogEvent>;
  differentDeltaExecutionSubject: Subject<IToolsLogEvent>;

  subscriptions: Subscription;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
    private loggerService: LvLoggerService
  ) {
    super(lvDateService, http, '/userActivity');
    this.subscriptions = new Subscription();

    this.deltaNeutralSubject = this.initSubject();
    this.breakEvenSubject = this.initSubject();
    this.differentDeltaExecutionSubject = this.initSubject();
  }

  /**
   * Invoked immediately before a service instance is destroyed.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Logs tools event.
   * @param event Tools log event.
   */
  logToolsEvent(event: IToolsLogEvent) {
    switch (event.sectionUsed) {
      case SectionUsed.BREAK_EVEN: {
        this.breakEvenSubject.next(event);
        return;
      }
      case SectionUsed.DELTA_NEUTRAL: {
        this.deltaNeutralSubject.next(event);
        return;
      }
      default: {
        this.differentDeltaExecutionSubject.next(event);
        return;
      }
    }
  }

  /**
   * Logs terms summary event.
   * @param termsSummaryLogEvent Terms summary log event.
   */
  logTermsSummaryEvent(termsSummaryLogEvent: ITermsSummaryLogEvent) {
    this.log('/termsSummary', termsSummaryLogEvent);
  }

  /**
   * Logs Open in Excel Form event.
   * @param openInExcelFormEvent Open In Excel Form event.
   */
  logOpenInExcelFormEvent(openInExcelFormEvent: IOpenInExcelFormEvent) {
    this.log('/openInExcelForm', openInExcelFormEvent);
  }

  /**
   * Logs Open Instrument event.
   * @param openInstrumentEvent Open Instrument event.
   */
  logOpenInstrumentEvent(openInstrumentEvent: IOpenInstrumentEvent) {
    this.log('/openInstrument', openInstrumentEvent);
  }

  /**
   * Logs event.
   * @param endpoint Endpoint.
   * @param event Event type.
   */
  private async log(endpoint: string, event: IBaseLogEvent) {
    try {
      await this.postAsync(event, endpoint);
    }
    catch (error) {
      this.loggerService.logError(new LvUserActivityError(error.message));
    }
  }

  /**
   * Initializes subject.
   * @returns New subject.
   */
  private initSubject(): Subject<IToolsLogEvent> {
    const subject = new Subject<IToolsLogEvent>();
    this.toolsLogEventSubscribe(subject);
    return subject;
  }

  /**
   * Subscribes tools log event.
   * @param subject Subject.
   */
  private toolsLogEventSubscribe(subject: Subject<IToolsLogEvent>) {
    this.subscriptions.add(subject.asObservable()
      .pipe(
        debounceTime(30 * 1000)
      )
      .subscribe((result) => this.log('/toolsUse', result))
    );
  }
}
