import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISaveModelCustomizationRequest } from '../../models/model-customization/model-customization-request';
import { LvAnalyticsError } from '../../models/errors';
import { ModelCustomizationMapper } from '../../models/model-customization/model-customization-mapper';
import { v4 } from 'uuid';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';

/**
 * Model customization service.
 */
@Injectable()
export class ModelCustomizationService extends HttpClientBase {

  get instanceId(): string {
    return this._svcInstanceId;
  }

  private _svcInstanceId: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
    private modelCustomizationMapper: ModelCustomizationMapper,
  ) {
    super(lvDateService, http, '/analytics/modelCustomization');
    this._svcInstanceId = v4();
  }

  /**
   * Saves model customization.
   * @param request ISaveModelCustomizationRequest object.
   */
  async saveModelCustomization(request: ISaveModelCustomizationRequest) {
    try {
      request.modelCustomization = this.modelCustomizationMapper.mapModelCustomizationToApi(request.modelCustomization);
      request.sourceId = this._svcInstanceId;
      await this.postAsync<ISaveModelCustomizationRequest>(request, '/saveModelCustomization');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

}
