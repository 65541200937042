import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { CommonDataService } from '@lv-common/common-data/services/common-data.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import { Currency } from '@lv-instrument-common/index';
import { IEquityDto } from '@lv-reference-data/equity-dto';
import { InstrumentsService } from '@lv-reference-data/instruments.service';
import { EquityTypeEnumDescription } from '@lv-reference-data/models';
import { LeversysReferenceDataSettingsService } from '@lv-reference-data/services/reference-data-settings/reference-settings.service';
import { EquityTermsSettingsService } from '@lv-custom-instruments/services/equity-terms-settings/equity-terms-settings.service';
import { EquityDocument, getDefaultEquityDocument } from 'src/app/modules/instrument/equity/models/equity-terms/EquityTermsDocument';
import _ from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvEquitySidePanelModalComponent } from '../lv-equity-side-panel-modal/lv-equity-side-panel-modal.component';
import { IEquitySettings } from '../../../models/equity-terms/equity-settings';
import { IEquityGeneralSettings } from '../../../models/equity-terms-settings';

@Component({
  selector: 'lv-equity-terms',
  templateUrl: './lv-equity-terms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityTermsComponent implements OnInit{

  @Output() didSave: EventEmitter<boolean>;
  @Output() didCCYCodeChanged: EventEmitter<string>;
  @Output() didInstrumentNameSet: EventEmitter<string>;
  @Output() didSyncAllEquityDataTrue: EventEmitter<boolean>;

  get isSectionVisible(): boolean {
    return !!this._analyticsPresenter.equitySession?.terms || this._analyticsPresenter.isFirstLoadOfSession;
  }

  equityTerms: EquityDocument;
  originalValue: EquityDocument;
  instrumentType: string;
  equityTypeEnum: LvLookupEnum;
  currencyLookup: LvLookupEnum;
  countries:string[];
  exchanges: string[];

  decimalsTwo = '2';
  formatTwo = 'n2';

  equitySettings: IEquitySettings;
  leversysLocalId: string;

  country: string;
  riskCountry: string;

  private _destroyRef = inject(DestroyRef)

  constructor(private _commonService: CommonDataService,
              private _referenceDataSettingsService: LeversysReferenceDataSettingsService,
              private _errorService: LvErrorService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _instrumentsService: InstrumentsService,
              private _equityTermsSettingsService: EquityTermsSettingsService,
              private _analyticsPresenter: LvAnalyticsPresenter,
              private _dialogService: DialogService,
  ) {
    this.instrumentType = 'Equity';
    this.equityTerms = getDefaultEquityDocument();
  
    this.equityTypeEnum = new LvLookupEnum(EquityTypeEnumDescription);
    this.currencyLookup = new LvLookupEnum(Currency);
    this.equitySettings = {} as IEquitySettings;

    this.getAllExchanges();
    this.didSave = new EventEmitter<boolean>();
    this.didCCYCodeChanged = new EventEmitter<string>();
    this.didInstrumentNameSet = new EventEmitter<string>();
    this.didSyncAllEquityDataTrue = new EventEmitter<boolean>();
  }
  
  /**
   * Method handles component initialization.
   */
   async ngOnInit() {
    this.countries = await this._commonService.getAllCountries();
    this._analyticsPresenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.loadEquityTerms();
    });

    await this.loadEquityTerms();  
  }

  /**
   * Method handles saving Terms Equity section.
   */
  async onSave() {
   
    try {
      // Country Code and Risk country code properties are displayed in UI as country/risk country names;
      // However, they should be mapped back to country/risk country codes before save.

      const equity = {
        terms: this.equityTerms.terms,        
      } as IEquityDto

      if (this._analyticsPresenter.equitySession?.leversysLocalId){
        equity.leversysLocalId = this._analyticsPresenter.equitySession?.leversysLocalId;
      }     
  
      this.countries = await this._commonService.getAllCountries();

      this.equityTerms.terms.countryCode = await this._commonService.getCountryCodeByCountryName(this.country);
      this.equityTerms.terms.riskCountryCode = await this._commonService.getCountryCodeByCountryName(this.riskCountry);
      
      const savedEquity = await this._instrumentsService.saveEquity(equity, this._analyticsPresenter.getSourceIdForEquityRequests);

      await this._analyticsPresenter.setupEquityValuationSessionAndInstrument(equityValuationSession => {
        equityValuationSession.terms = savedEquity.terms;
        equityValuationSession.leversysLocalId = savedEquity.leversysLocalId;
      });
      
      this.equitySettings = await this._instrumentsService.getEquitySettings(this._analyticsPresenter?.equitySession?.leversysLocalId);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388',{ 'value': 'Terms' }));
      this.didSave.next(true);
      this.didInstrumentNameSet.next(this.equityTerms.terms.name);
    } catch (err) {
      this._errorService.toastrService.error(err);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }

  }

  /**
   * Method handles reload Terms Equity section.
   */
  async onReload () {
    await this._analyticsPresenter.reloadEquityTermsInSession();
    await this.loadEquityTerms();
  }

  /**
   * Method gets all exchanges.
   */
  async getAllExchanges(): Promise<void>{
    try {
      const allExchanges = await this._referenceDataSettingsService.getAllExchanges();
      this.exchanges = allExchanges.map(x => x.code);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Load equity terms
   */
  async loadEquityTerms(): Promise<void> {
    try {
      this.countries = await this._commonService.getAllCountries();
      
      if (!this._analyticsPresenter?.equitySession?.leversysLocalId) {
        this.equityTerms = getDefaultEquityDocument();

        const equityTermsFromSettings = await this._equityTermsSettingsService.getEquityTermsSettings();
        let termsSettings: IEquityGeneralSettings;

        equityTermsFromSettings.my.termsSettings.overrideSystemDefaults ?
          termsSettings = equityTermsFromSettings.my.termsSettings : termsSettings = equityTermsFromSettings.system.termsSettings;

          this.country = this._commonService.getCountryNameByCountryCode(termsSettings.settings.country);
          this.riskCountry = this._commonService.getCountryNameByCountryCode(termsSettings.settings.riskCountry);
          this.equityTerms.terms.priceScale = termsSettings.settings.priceScale;
          this.equityTerms.terms.exchangeCode = termsSettings.settings.exchange;
          this.equityTerms.terms.currencyCode = termsSettings.settings.currency;
      }
      else {
        if (this._analyticsPresenter?.equitySession) {        
          this.equityTerms.terms = this._analyticsPresenter?.equitySession?.terms;
          this.getCountry();
          this.equitySettings = await this._instrumentsService.getEquitySettings(this._analyticsPresenter?.equitySession?.leversysLocalId);
        }
      }

      this.getCountry();
      this._changeDetectorRef.detectChanges();

    } catch (err) {
      this._errorService.handleError(err);
    }
  }

  /**
   * On country changed.
   */
  onCountryChanged(): void {
    this.equityTerms.terms.countryCode = this._commonService.getCountryCodeByCountryName(this.country);
  }

  /**
   * On risk country changed.
   */
  onRiskCountryChanged(): void {
    this.equityTerms.terms.riskCountryCode = this._commonService.getCountryCodeByCountryName(this.riskCountry);
  }

  /**
   * Sets section state changed flag.
   */
  setSectionStateChanged() {
    // this._analyticsPresenter.overrideEquityInSession(equityValuationSession => {
    //   equityValuationSession.terms = this.equityTerms;
    // });
    // if (!_.isEqual(this.equityTerms, this.originalValue)) {
    //   this.originalValue = _.cloneDeep(this.equityTerms);
    // }
    // this._changeDetectorRef.detectChanges();
    // this.didCCYCodeChanged.next(this.originalValue.terms.currencyCode);
  }

  getCountry() {
    if (this.equityTerms && this.equityTerms.terms.countryCode) {
      const country = this._commonService.getCountryNameByCountryCode(this.equityTerms.terms.countryCode);
      this.country = country;
    }

    if (this.equityTerms && this.equityTerms.terms.riskCountryCode) {
      const riskCountry = this._commonService.getCountryNameByCountryCode(this.equityTerms.terms.riskCountryCode);
      this.riskCountry = riskCountry;
    }
  }

  openSidePanelModal() {
    const dialogRef = this._dialogService.open({
      title: 'Leversys Data Sync Settings',
      content: LvEquitySidePanelModalComponent,
      width: 405,
      height: 114,
    });

    const instance = dialogRef.content.instance as  LvEquitySidePanelModalComponent;
    instance.equitySettings = this.equitySettings;

    instance.doSave
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => {
        this.saveEquitySettings(data);
        dialogRef.close();
        this._changeDetectorRef.detectChanges();
      });

    instance.doCancel
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.dialog.location.nativeElement.classList.add('lv-equity-sync-settings-modal-container');
  }

  /**
   * Save equity settings.
   * @param settings Equity settings.
   */
  async saveEquitySettings(settings: IEquitySettings) {
    try {
      await this._instrumentsService.saveEquitySettings(settings, this._analyticsPresenter?.equitySession?.leversysLocalId);
      this.didSyncAllEquityDataTrue.next(settings.syncAllEquityData);

      if (settings.syncAllEquityData) {
        await this.onReload();
        this.didInstrumentNameSet.next(this.equityTerms.terms.name);
      }
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388',{ 'value': 'Leversys Data Sync Settings' }));
    }
    catch(error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }
}
