import { CurrencyType } from '../../../common/enums/CurrencyType';
import { IncrementalShareFactorType, VariableConversionStrikeType } from './Enums (2)';

export class VariableConversion {
    
    incrementalShareFactor: number;
    incrementalShareFactorType: IncrementalShareFactorType;
    strikeType: VariableConversionStrikeType;
    strike: number;
    capRatio: number;
    lockinDate: Date;
    strikeCurrency: CurrencyType;
}

