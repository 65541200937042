import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CommonDataService } from '@lv-common/common-data';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LeversysReferenceDataSettingsService } from '@lv-reference-data/services/reference-data-settings/reference-settings.service';
import { Currency } from '@lv-instrument-common/index';
import { IBaseEquityTermsSettings } from '../../../models/equity-terms-settings/base-equity-terms-settings';
import { IEquityTermsSettingsContent } from '../../../models/equity-terms-settings/equity-terms-settings-content/equity-terms-section-settings-content';

@Component({
  selector: 'lv-equity-terms-section-settings',
  templateUrl: './lv-equity-terms-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityTermsSectionSettingsComponent implements OnInit, OnChanges {
  @Input() settings: IBaseEquityTermsSettings<IEquityTermsSettingsContent>;
  @Input() overrideDefaults: boolean;

  countries:string[];
  exchanges: string[];
  currencyLookup: LvLookupEnum;

  decimalsZero = '0';
  formatZero = 'n0';
  decimalsTwo = '2';
  formatTwo = 'n2';

  country: string;
  riskCountry: string;

  constructor (private _commonService: CommonDataService,
               private _referenceDataSettingsService: LeversysReferenceDataSettingsService,
               private _errorService: LvErrorService,

  ) {
    this.countries = this._commonService.getAllCountries();
    this.overrideDefaults = true;
    this.currencyLookup = new LvLookupEnum(Currency);
    this.exchanges= [];
  }

  ngOnInit(): void {
    this.getAllExchanges();
    this.getCountry();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings?.currentValue !== changes.settings?.previousValue) {
      this.getCountry();
    }
  }

  /**
   * Method gets all exchanges.
   */
  async getAllExchanges(): Promise<void>{
    try {
      const allExchanges = await this._referenceDataSettingsService.getAllExchanges();
      this.exchanges = allExchanges.map(x => x.code);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  getCountry() {
    if (this.settings && this.settings.settings && this.settings.settings.country) {
      const countryName = this._commonService.getCountryNameByCountryCode(this.settings.settings.country);
      this.country = countryName;
    }

    if (this.settings && this.settings.settings && this.settings.settings.country) {
      const riskCountryName = this._commonService.getCountryNameByCountryCode(this.settings.settings.riskCountry);
      this.riskCountry = riskCountryName;
    }
  }

  setRiskCountryChanged() {
    this.settings.settings.riskCountry = this._commonService.getCountryCodeByCountryName(this.riskCountry);
  }

  setCountryChanged() {
    this.settings.settings.country = this._commonService.getCountryCodeByCountryName(this.country);
  }
}
