import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { EquityTermsSettingsService } from '@lv-custom-instruments/services/equity-terms-settings/equity-terms-settings.service';
import _ from 'lodash';
import { IEquityGeneralSettings, IEquityTermsSectionSettings } from 'src/app/modules/instrument/equity/models/equity-terms-settings';

@Component({
  selector: 'lv-equity-terms-settings',
  templateUrl: './lv-equity-terms-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityTermsSettingsComponent implements OnInit, IDiscardable {

  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  settings: IEquityTermsSectionSettings;
  originalSettings: IEquityTermsSectionSettings;

  constructor(private _errorService: LvErrorService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _discardChangesService: DiscardChangesService,
              private _equityTermsSettingsService: EquityTermsSettingsService) {
  
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      system: {
        termsSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IEquityGeneralSettings
      },
      my: {
        termsSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IEquityGeneralSettings
      }
    };

    this.originalSettings = _.cloneDeep(this.settings);

    this._discardChangesService.register('Terms', this);
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-equity-terms-settings')
  get isLvEquityTermsSettingsComponent() {
    return true;
  }

  ngOnInit() {
    this.getEquityTermsSettings();
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.next();
  }

  /**
   * Gets Equity terms settings.
   */
  async getEquityTermsSettings() {
    try {
      this.startLoading.next();
      this.settings = await this._equityTermsSettingsService.getEquityTermsSettings();
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

   /**
   * Resets equity settings to default.
   */
   async onResetToDefault() {
    try {
      this.startLoading.next();
      this.settings = await this._equityTermsSettingsService.resetEquityTermsSettings();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Terms'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

   /**
   * Occurs on save and saves equity term settings.
   */
   async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
      await this._equityTermsSettingsService.saveEquityTermsSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
      {'settings_section': 'Terms'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }
}
