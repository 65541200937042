export enum IdentifierType {
    ISIN = 'ISIN',
    CUSIP = 'CUSIP',
    FIGI = 'FIGI',
    PERMID = 'PERMID',
    UnderlyingISIN = 'UnderlyingISIN',
    UnderlyingCUSIP = 'UnderlyingCUSIP',
    UnderlyingFIGI = 'UnderlyingFIGI',
    UnderlyingPERMID = 'UnderlyingPERMID',
    UnderlyingBBGTicker = 'UnderlyingBBGTicker',
    LeversysLocalID = 'LeversysLocalID',
    BondCode = 'BondCode',
    LeversysID = 'LeversysID'    
}

export enum IdentifierTypeDescription {
    ISIN = 'ISIN',
    CUSIP = 'CUSIP',
    FIGI = 'FIGI',
    PERMID = 'PERMID',
    UnderlyingISIN = 'Underlying ISIN',
    UnderlyingCUSIP = 'Underlying CUSIP',
    UnderlyingFIGI = 'Underlying FIGI',
    UnderlyingPERMID = 'Underlying PERM ID',  
    UnderlyingBBGTicker = 'Underlying BBG Ticker',
    LeversysLocalID = 'Leversys ID',
    BondCode = 'Bond Code',
    LeversysID = 'LeversysID'
}

export enum BondIdentifierTypeDescription {
    ISIN = 'ISIN',
    CUSIP = 'CUSIP',
    FIGI = 'FIGI',
    PERMID = 'PERMID',
    LeversysLocalID = 'Leversys ID',
    BondCode = 'Bond Code'  
}