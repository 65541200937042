<lv-flex-box class="lv-equity-schedule-dividends">
  <lv-flex-box direction="row" class="lv-dividends-grid-title">
    <div showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="dividendsScheduleTooltipTemplate"
      filter=".lv-label" 
      class="lv-label lv-label-field lv-label--title">
      <span [attr.volatility-tooltip-id]="'dividendsScheduleTooltip'">
        DIVIDENDS SCHEDULE
      </span> 
    </div>
  </lv-flex-box>
  <lv-advanced-grid
    class="lv-schedule-dividends-table"
    data-cy="DM-315"
    [columns]="columns"
    [records]="scheduleDividends"
    lvId="lvMarketDataDividendsScheduleGrid"
    [createFormGroup]="createFormGroup"
    [parseFn]="parseFn"
    sortBy="date"
    [ngClass]="addStripedGridClass"
    [editDisabled]="isGridDisabled">
  </lv-advanced-grid>
</lv-flex-box>

<ng-template #dividendsScheduleTooltipTemplate>
  <lv-tooltip dm="dM-315"></lv-tooltip>
</ng-template>
