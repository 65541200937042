import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { EquityComponent } from '@lv-views/internal/equity/equity.component';

@Component({
  selector: 'underlying',
  templateUrl: './underlying.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class UnderlyingComponent {
  @ViewChild(EquityComponent) equityComponent: EquityComponent;

  @Input() instrumentName: string;

  @Output() didEquitySaved: EventEmitter<void>;

  constructor() {
    this.didEquitySaved = new EventEmitter();
  }

  /**
   * Send event when equity terms is saved.
   *
   * @memberof UnderlyingComponent
   */
  onEquitySaved() {
    this.didEquitySaved.next();
  }
}
