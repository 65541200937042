<lv-flex-box class="lv-coupon-pik">
    <lv-flex-box direction="row">
      <lv-flex-box class="lv-pik-coupon-first-column">
        <lv-flex-box direction="row" data-cy="DM-956">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="PIKCouponTypeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              PIK Coupon Type
            </span>
            <lv-xl-label [field]="'CPN_PIK_TYPE'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
            <kendo-dropdownlist
              name="terms-general-pik-coupon-coupon-type"
              [data]="pikCouponTypeLookup.items"
              valueField="id"
              textField="text"
              valuePrimitive="true"
              [(ngModel)]="model.pikCouponType"
              (ngModelChange)="onPikCouponChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" *ngIf="!isCustomPikCouponType && !isNonePikCouponType" data-cy="DM-957">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="PIKCouponRateSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                PIK Coupon Rate
              </span>
              <lv-xl-label [field]="'CPN_PIK_RATE'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="terms-general-pik-coupon-coupon-rate"
                [format]="formatFour"
                [decimals]="decimalsFour"
                [(ngModel)]="model.pikCouponRate"
                (didBlur)="onPikCouponChange()"
                (didKeyDownEnter)="onPikCouponChange()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" *ngIf="!isPikOnlyPikCouponType && !isCustomPikCouponType && !isNonePikCouponType" data-cy="DM-958">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="cashCouponRateSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Cash Coupon Rate
              </span>
              <lv-xl-label [field]="'CPN_PIK_CASH_RATE'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="terms-general-pik-coupon-cash-coupon-type"
                [format]="formatFour"
                [decimals]="decimalsFour"
                [(ngModel)]="model.cashCouponRate"
                (didBlur)="onPikCouponChange()"
                (didKeyDownEnter)="onPikCouponChange()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
            </div>
          </lv-flex-box>
      </lv-flex-box>
      <lv-flex-box class="lv-pik-right-side">
        <lv-flex-box direction="row" data-cy="DM-959">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="PIKNominalAdjustmentSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              PIK Nominal Adjustment
            </span>
            <lv-xl-label [field]="'CPN_NMNL_ADJ'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatFour"
            [decimals]="decimalsFour"
            name="terms-general-pik-coupon-pik-nominal-adjusted"
            [(ngModel)]="model.pikNominalAdjustment"
            (didBlur)="onPikCouponChange()"
            (didKeyDownEnter)="onPikCouponChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-960">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="PIKEndDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              PIK End Date
            </span>
            <lv-xl-label [field]="'CPN_PIK_END_DATE'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
            <kendo-datepicker
              name="terms-general-pik-coupon-pik-end-date"
              placeholder=""
              [(ngModel)]="model.pikEndDate"
              (ngModelChange)="onPikCouponChange()">
            </kendo-datepicker>
          </div>
        </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box *ngIf="isCustomPikCouponType">
        <lv-flex-box direction="row">
          <div showAfter="1500"
            kendoTooltip 
            [tooltipTemplate]="customPIKScheduleTooltip"
            class="lv-label lv-label-field lv-label--title"
            filter=".lv-label">CUSTOM SCHEDULE</div>
          </lv-flex-box>
          <lv-flex-box direction="row">
            <lv-flex-box direction="row">
            <lv-bond-pik-custom-schedule #pikSchedule
              [model]="model.pikCustomSchedule"
              (didPikCustomScheduleChange)="onPikCouponChange()">
            </lv-bond-pik-custom-schedule>
            </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>
</lv-flex-box>

<ng-template #PIKCouponTypeSourceTooltip>
  <lv-tooltip dm="dM-956"></lv-tooltip>
</ng-template>
<ng-template #PIKCouponRateSourceTooltip>
  <lv-tooltip dm="dM-957"></lv-tooltip>
</ng-template>
<ng-template #cashCouponRateSourceTooltip>
  <lv-tooltip dm="dM-958"></lv-tooltip>
</ng-template>
<ng-template #PIKNominalAdjustmentSourceTooltip>
  <lv-tooltip dm="dM-959"></lv-tooltip>
</ng-template>
<ng-template #PIKEndDateSourceTooltip>
  <lv-tooltip dm="dM-960"></lv-tooltip>
</ng-template>
<ng-template #customPIKScheduleTooltip>
  <lv-tooltip dm="dM-986"></lv-tooltip>
</ng-template>
