<lv-flex-box class=" lv-settings-content">
  <lv-section-settings [settings]="settings"
  [showSingleEnvironment]="true"
  showAfter="1500"
  kendoTooltip
  [tooltipTemplate]="instrumentSourceSystemDefaultsSettingsTooltip"
  filter="div[data-tooltip-id]">
<ng-template systemDefaultsSettings let-systemSettings>
  <lv-instrument-source-section-settings
    [settings]="systemSettings"
    [lvId]="'lv-instrument-source-section-settings-system-defaults'">
  </lv-instrument-source-section-settings>
</ng-template>

<ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
  <lv-instrument-source-section-settings
    [lvId]="'lv-instrument-source-section-settings-my-defaults'"
    [settings]="settings"
    [overrideSystemDefaults]="overrideSystemDefaults">
  </lv-instrument-source-section-settings>
</ng-template>
</lv-section-settings>
</lv-flex-box>
<lv-flex-box class="lv-settings-footer" direction="row">
  <button kendoButton
    [primary]="true"
    lvId="cancel-other-settings"
    (click)="onCancel()">
    Cancel
  </button>
  <div class="lv-settings-footer-divider">&nbsp;</div>
  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-quantity-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    [class.save-settings-btn-disabled]="!isChanged()"
    lvId="save-other-settings"
    (click)="onSave()">
    Save
  </button>
</lv-flex-box>

<ng-template #instrumentSourceSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1114"></lv-tooltip>
  </ng-container>
</ng-template>