import { Frequency } from 'src/app/modules/analytics/models/convertible/enum/frequency';
import { CouponType } from 'src/app/modules/analytics/models/convertible/enum/coupon-type';
import { ConvertibleSetupStatus } from 'src/app/modules/analytics/models/convertible/enum/convertible-setup-status';
import { ConvertibleSubType } from 'src/app/modules/analytics/models/convertible/enum/convertible-sub-type';
import { AdjustmentReason, CRHistoryStatusType } from '@lv-convertible-bond/models';

export const convertible = {
  conversionPrice: 100,
  conversionRatio: 1000,
  conversionRatioHistory: [
    {
      adjustmentReason: AdjustmentReason.Initial,
      confirmedRatio: 1000,
      conversionRebate: 0,
      conversionRebateCurrencyID: 1,
      effectiveDate: new Date('2018-05-03T00:00:00Z'),
      leversysEstimate: 0,
      maximumConversionPriceInsCcy: 0,
      maximumConversionRatio: 0,
      minimumConversionPriceInsCcy: 0,
      relativeDiff: -100,
      status: CRHistoryStatusType.Confirmed
    }
  ],
  convertibleId: 0,
  convertibleInfoId: 2061,
  countryCode: 'ES',
  coupon: 0,
  couponFrequency: Frequency.SemiAnnual,
  couponType: CouponType.ZeroCoupon,
  currencyCode: 'EUR',
  deltaNeutralConversionAmount: false,
  expectedCP: null,
  expectedCR: null,
  firstSettlementDate: new Date('2018-05-03T00:00:00Z'),
  fixedFxRate: 0,
  fxAdjustedConversionAmount: false,
  isCallable: false,
  isCrossFx: true,
  isDeltaNeutral: false,
  isDetachable: true,
  isDraft: false,
  isExchangeable: false,
  isPerpetual: false,
  isPriceAsPar: true,
  isQuotedClean: true,
  isin: 'ISINSTR1',
  issueValue: 100,
  issueValueIsPercentOfNominal: true,
  lwsIdentifier: '7f4c242f-8d1d-415d-a948-803f37c10560',
  maturityDate: new Date('2023-05-03T00:00:00Z'),
  name: 'STRUCTURE 1',
  nominal: 100000,
  priceTalk: null,
  rebate: 0,
  rebateCCYID: 0,
  redemptionValueIsPercentOfNominal: true,
  status: ConvertibleSetupStatus.FullyCompleted,
  stockPriceReference: null,
  subType: ConvertibleSubType.ConvertibleWithDetachableWarrant,
  underlying: {name: 'DUMMY 1', currency: 'EUR'},
  underlyingCurrencyLinked: false
};
