import { ChangeDetectorRef, OnInit, Directive, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { ILvNotifierState } from '@lv-core-ui/services';
import { LvGlComponent, LvGlOnDestroy } from '@lv-golden-layout/models';

import { concatMap, debounceTime, exhaustMap, of, Subject, Subscription, switchMap, take, takeLast } from 'rxjs';


// tslint:disable-next-line:max-line-length
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class NotificationPanelComponent<T> extends LvGlComponent implements OnInit, LvGlOnDestroy {

  stateId: string;
  state: T;
  
  private _workspaceRemovedSusbscription: Subscription;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public applicationSettingsService: ApplicationSettingsService,
    public _destroyRef: DestroyRef
  ) {
    super();

    this._workspaceRemovedSusbscription = this.applicationSettingsService.didRemoveWorkspace
      .subscribe((removedComponetsMap: any) => {
        if (removedComponetsMap[this.stateId]) {
          this.didlvGlOnDestroy();
        }
      });    
  }

  ngOnInit() {
    const wgt = this.applicationSettingsService.getWidget(this.stateId);

    if (wgt) {
      this.state = { ...wgt.state } as any;
    }
    this.doOnInit();
  }

  /**
   * Adds event into queue.
   *
   * @param {*} message
   * @memberof NotificationPanelComponent
   */
  async enqueueMessage(message: any) {
    await this.onMessageReceived(message);
  }

  setStateId(stateId: string) {
    this.stateId = stateId;
  }

  saveState(state?: T) {
    if (state) {
      Object.assign(this.state, state);
    }

    this.applicationSettingsService.updateWidget(this.stateId, wgt => {
      wgt.state = {
        ...wgt.state,
        ...this.state
      };
    });
  }

  // Notification Panel Events
  onStateChange(state: ILvNotifierState) {
    this.saveState(state as any);
  }

  async onMessageReceived(message: any) {
    await this.didReceiveMessage(message);

    this.saveState();

    this.changeDetectorRef.markForCheck();
  }

  // Golden Layout Component Events
  lvGlOnDestroy(): void {
    this.didlvGlOnDestroy();
  }

  unsubscribeFromWorkspaceRemoved() {
    this._workspaceRemovedSusbscription.unsubscribe();
  }

  abstract doOnInit(): any;
  abstract didReceiveMessage(message: any): Promise<void>;
  abstract didlvGlOnDestroy(): void;
}
