import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvAdvancedGridColumn, LvAdvancedGridComponent, LvAdvancedGridDateColumn, LvAdvancedGridEnumColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum, constants } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';
import { PikType, PikTypeDescription } from '@lv-instrument-common/index';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { PikCustomScheduleItem } from 'src/app/modules/instrument/bond-terms/models/bond-terms/BondCoupon';

@Component({
  selector: 'lv-bond-pik-custom-schedule',
  templateUrl: './lv-bond-pik-custom-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBondPikCustomScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: PikCustomScheduleItem[];
  @Output() didPikCustomScheduleChange: EventEmitter<PikCustomScheduleItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  scheduleItems: PikCustomScheduleItem[];
  parseFn: any;

  formatFour = '#,###.####';
  decimalsFour = '4';
  excelFieldAlias = 'CPN_PIK_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {
      this.initColumns();
      this.parseFn = this.parserFunction.bind(this);

      this.scheduleItems = [];
      this._modelSubscription = [];
      this.didPikCustomScheduleChange = new EventEmitter<PikCustomScheduleItem[]>();
    }

  ngOnInit() {
    this._modelSubscription = [
        this.advancedGrid.didDataChange.subscribe((records: PikCustomScheduleItem[]) => this.onScheduleChange(records)),
        this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
        this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.scheduleItems.splice(0, this.scheduleItems.length);
    this.scheduleItems.push(...this.model);

    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }

    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
    this.scheduleItems.splice(0, this.scheduleItems.length);
    this.scheduleItems.push(...this.model);

    // this.scheduleItems = this.model.map(a => {
    //   return { ...a };
    // });

    //this._changeDetectorRef.detectChanges();
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
      'pikCouponOne': new FormControl(args.dataItem.pikCouponOne, Validators.required),
      'pikCouponTwo': new FormControl(args.dataItem.pikCouponTwo, Validators.required),
      'cashCouponOne': new FormControl(args.dataItem.cashCouponOne, Validators.required),
      'cashCouponTwo': new FormControl(args.dataItem.cashCouponTwo, Validators.required),
      'pikType': new FormControl(args.dataItem.pikType, Validators.required),
    });
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.onScheduleChange(records));
  }

  private initColumns() {
    this.columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.title = 'End Date';
    dateColumn.field = 'date';
    dateColumn.dmKey = 'DM-2270';

    const pikCouponOneColumn = new LvAdvancedGridNumericColumn();
    pikCouponOneColumn.title = this.hasScheduleInExcelOverride ? 'PIK Cpn. 1 (%)' : 'PIK Coupon 1 (%)';
    pikCouponOneColumn.field = 'pikCouponOne';
    pikCouponOneColumn.outputFormat = constants.numberFormat.upToFourDigits;
    pikCouponOneColumn.format = this.formatFour;
    pikCouponOneColumn.decimals = this.decimalsFour;
    pikCouponOneColumn.dmKey = 'DM-2271';

    const cashCouponOneColumn = new LvAdvancedGridNumericColumn();
    cashCouponOneColumn.title = this.hasScheduleInExcelOverride ? 'Cash Cpn. 1 (%)' : 'Cash Coupon 1 (%)';
    cashCouponOneColumn.field = 'cashCouponOne';
    cashCouponOneColumn.width = 110;
    cashCouponOneColumn.outputFormat = constants.numberFormat.upToFourDigits;
    cashCouponOneColumn.format = this.formatFour;
    cashCouponOneColumn.decimals = this.decimalsFour;
    cashCouponOneColumn.dmKey = 'DM-2272';

    const pikCouponTwoColumn = new LvAdvancedGridNumericColumn();
    pikCouponTwoColumn.title = this.hasScheduleInExcelOverride ? 'PIK Cpn. 2 (%)' : 'PIK Coupon 2 (%)';
    pikCouponTwoColumn.field = 'pikCouponTwo';
    pikCouponTwoColumn.width = 110;
    pikCouponTwoColumn.outputFormat = constants.numberFormat.upToFourDigits;
    pikCouponTwoColumn.format = this.formatFour;
    pikCouponTwoColumn.decimals = this.decimalsFour;
    pikCouponTwoColumn.dmKey = 'DM-2273';

    const cashCouponTwoColumn = new LvAdvancedGridNumericColumn();
    cashCouponTwoColumn.title = this.hasScheduleInExcelOverride ? 'Cash Cpn. 2 (%)' : 'Cash Coupon 2 (%)';
    cashCouponTwoColumn.field = 'cashCouponTwo';
    cashCouponTwoColumn.width = 110;
    cashCouponTwoColumn.outputFormat = constants.numberFormat.upToFourDigits;
    cashCouponTwoColumn.format = this.formatFour;
    cashCouponTwoColumn.decimals = this.decimalsFour;
    cashCouponTwoColumn.dmKey = 'DM-2274';

    const pikTypeColumn = new LvAdvancedGridEnumColumn();
    pikTypeColumn.enumDescription = PikTypeDescription;
    pikTypeColumn.title = 'PIK Type';
    pikTypeColumn.field = 'pikType';
    pikTypeColumn.displayField = 'text';
    pikTypeColumn.valueField = 'id';
    pikTypeColumn.valuePrimitive = true;
    pikTypeColumn.width = 135;
    pikTypeColumn.data = new LvLookupEnum(PikTypeDescription).items;
    pikTypeColumn.dmKey = 'DM-2275';

    this.columns.push(dateColumn);
    this.columns.push(pikCouponOneColumn);
    this.columns.push(cashCouponOneColumn);
    this.columns.push(pikCouponTwoColumn);
    this.columns.push(cashCouponTwoColumn);
    this.columns.push(pikTypeColumn);
  }

  private parserFunction(pastedDataRecords: string[]): PikCustomScheduleItem[] {
    const scheduleItems: PikCustomScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const dateValue = items[0];
      const pikCouponOneValue = items[1];
      const cashCouponOneValue = items[2];
      const pikCouponTwoValue = items[3];
      const cashCouponTwoValue = items[4];
      const pikTypeValue = items[5];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'Date');
      const pikCouponOne = MarketDataClipboard.parseNumberValue(pikCouponOneValue, '1. PIK Coupon (%)');
      const cashCouponOne = MarketDataClipboard.parseNumberValue(cashCouponOneValue, '1. Cash Coupon (%)');
      const pikCouponTwo = MarketDataClipboard.parseNumberValue(pikCouponTwoValue, '2. PIK Coupon (%)');
      const cashCouponTwo = MarketDataClipboard.parseNumberValue(cashCouponTwoValue, '2. Cash Coupon (%)');
      const pikType = this.parsePikTypeValue(pikTypeValue);

      scheduleItems.push({
        date: date,
        pikCouponOne: pikCouponOne,
        cashCouponOne: cashCouponOne,
        pikCouponTwo: pikCouponTwo,
        cashCouponTwo: cashCouponTwo,
        pikType: pikType
      } as PikCustomScheduleItem);
    });

    return scheduleItems;
  }

  private parsePikTypeValue(pikTypeValue: string): PikType {

    if (MarketDataClipboard.isEmpty(pikTypeValue)) {
      return PikType.PIKOnly;
    }

    const lvPikTypeDescription = new LvLookupEnum(PikTypeDescription);
    const lvPikTypeDescriptionEnum = lvPikTypeDescription.items.find(
      a => a.text === pikTypeValue
    );
    if (!lvPikTypeDescriptionEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', 
        new Map([['{ value}', pikTypeValue], ['{field}', 'PIK Value Type']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return PikType[lvPikTypeDescriptionEnum.id];
  }

  private onScheduleChange(scheduleItems: PikCustomScheduleItem[]) {
    this.model.splice(0, this.model.length);
    this.model.push(...scheduleItems);
    this.didPikCustomScheduleChange.next(this.model);
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  private onScheduleReload() {
    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }
}
