<lv-flex-box class="lv-averaging-data">
  <lv-environment-settings 
    [section]="averagingDataSection"
    instrumentType="ConvertibleBond"
    lvId="marketDataAveragingDataEnvironmentSettings"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-averaging-data-content lv-market-data-section-content">
    <!-- call section -->
    <lv-flex-box *ngIf="view.isCallable">
      <div class="lv-label lv-label-field lv-label--title">Call</div>
    </lv-flex-box>

    <!-- Current Stock Average -->
    <lv-flex-box *ngIf="view.isCallable && view.isCallAverageOfConsecutiveDays"
      data-cy="DM-325"
      direction="row">
      <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="currentStockAverageTooltip"
      filter="span:not(lv-xl-label span)">
      <span *ngIf="!isOpenedFromExcel"
         [attr.averaging-data-tooltip-id]="'currentStockAverageTooltip'">
          Current Stock Average ({{ view.cbCcy }})
      </span>
      <span *ngIf="isOpenedFromExcel"
         [attr.averaging-data-tooltip-id]="'currentStockAverageTooltip'">
          Current Stk Avg &nbsp;({{ view.cbCcy }})
      </span>
        <lv-xl-label
          [field]="'CURR_STK_AVG_CALL'">
        </lv-xl-label>
      </div>
      <lv-numeric-text-box name="callCurrentStockAverage"
        format="n6"
        decimals="6"
        [(ngModel)]="averagingDataSettings.callCurrentStockAveraging"
        (didKeyDownEnter)="onAveragingDataSectionChange()"
        (didBlur)="onAveragingDataSectionChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <!-- Days Above Trigger -->
    <lv-flex-box *ngIf="view.isCallable && !view.isCallAverageOfConsecutiveDays"
      data-cy="DM-326"
      direction="row">
      <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="daysAboveTriggerTooltip"
      filter="span:not(lv-xl-label span)">
      <span [attr.averaging-data-tooltip-id]="'daysAboveTriggerTooltip'">
        Days Above Trigger
      </span>
        <lv-xl-label [field]="'CALL_DAYS_ABOVE'"></lv-xl-label>
      </div>
      <lv-numeric-text-box name="callDaysAboveTrigger"
        format="n0"
        decimals="0"
        [(ngModel)]="averagingDataSettings.callDaysAboveTrigger"
        (didKeyDownEnter)="onAveragingDataSectionChange()"
        (didBlur)="onAveragingDataSectionChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <!-- Running Period Days -->
    <lv-flex-box *ngIf="view.isCallable"
      data-cy="DM-327"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="runningPeriodDaysTooltip"
        filter="span:not(lv-xl-label span)">
        <span [attr.averaging-data-tooltip-id]="'runningPeriodDaysTooltip'">
          Running Period Days
        </span>
        <lv-xl-label [field]="'CALL_RUNNING_DAYS'"></lv-xl-label>
      </div>
      <lv-numeric-text-box name="callRunningPeriodDays"
        format="n0"
        decimals="0"
        [(ngModel)]="averagingDataSettings.callRunningPeriodDays"
        (didKeyDownEnter)="onAveragingDataSectionChange()"
        (didBlur)="onAveragingDataSectionChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <!-- conversion section -->
    <lv-flex-box *ngIf="(view.isCash || view.isCombination || view.isSharesBasedOnAvg) || view.isAcquisitionSharesSettlement">
      <div class="lv-label lv-label-field lv-label--title">CONVERSION</div>
    </lv-flex-box>
    <lv-flex-box direction="row"
    data-cy="DM-324"
      *ngIf="(view.isCash || view.isCombination || view.isSharesBasedOnAvg) || view.isAcquisitionSharesSettlement">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="currentStockAverageConversionTooltip"
        filter="span:not(lv-xl-label span)">
        <span *ngIf="!isOpenedFromExcel"
         [attr.averaging-data-tooltip-id]="'currentStockAverageSpreadTooltip'">
          Current Stock Average ({{ view.cbCcy }})
        </span>
        <span *ngIf="isOpenedFromExcel"
          [attr.averaging-data-tooltip-id]="'currentStockAverageSpreadTooltip'">
          Current Stk Avg &nbsp;({{ view.cbCcy }})
        </span>
        <lv-xl-label [field]="'CURR_STK_AVG'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box name="conversionCurrentStockAverage"
          format="n6"
          decimals="6"
          [(ngModel)]="averagingDataSettings.conversionCurrentStockAveraging"
          (didKeyDownEnter)="onAveragingDataSectionChange()"
          (didBlur)="onAveragingDataSectionChange()">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row"
    data-cy="DM-328"
      *ngIf="view.isCombination || view.isSharesBasedOnAvg">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="conversionSpecifiedCashAmountTooltip"
        filter="span:not(lv-xl-label span)">
        <span [attr.averaging-data-tooltip-id]="'conversionSpecifiedCashAmountTooltip'">
          Specified Cash Amount
        </span>
        <lv-xl-label [field]="'SPEC_CASH_AMT'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box name="conversionSpecifiedCashAmount"
          [format]="formatTwo"
          [decimals]="decimalsTwo"
          [(ngModel)]="averagingDataSettings.conversionSpecifiedCashAmount"
          (didKeyDownEnter)="onAveragingDataSectionChange()"
          (didBlur)="onAveragingDataSectionChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>

    <!--COCO section-->
    <lv-flex-box *ngIf="view.isContingentConversion">
      <div class="lv-label lv-label-field lv-label--title">CO-CO</div>
    </lv-flex-box>

    <!-- Current Stock Average --> 
    <lv-flex-box *ngIf="view.isContingentConversion && view.isCocoAverageOfConsecutiveDays"
      data-cy="DM-329"
      direction="row">
      <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="cocoCurrentStockAverageTooltip"
      filter="span:not(lv-xl-label span)">
      <span *ngIf="!isOpenedFromExcel"
       [attr.funding-tooltip-id]="'cocoCurrentStockAverageTooltip'">
          Current Stock Average ({{ view.cbCcy }})
       </span>
      <span *ngIf="isOpenedFromExcel"
       [attr.funding-tooltip-id]="'cocoCurrentStockAverageTooltip'">
          Current Stk Avg &nbsp;({{ view.cbCcy }})
      </span>
          <lv-xl-label [field]="'CURR_STK_AVG_COCO'"></lv-xl-label>
          </div>
      <lv-numeric-text-box name="cocoCurrentStockAverage"
        format="n6"
        decimals="6"
        [(ngModel)]="averagingDataSettings.cocoCurrentStockAveraging"
        (didKeyDownEnter)="onAveragingDataSectionChange()"
        (didBlur)="onAveragingDataSectionChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <!-- Days Above Trigger -->
    <lv-flex-box *ngIf="view.isContingentConversion && !view.isCocoAverageOfConsecutiveDays"
      data-cy="DM-330"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="cocoDaysAboveTriggerTooltip"
        filter="span:not(lv-xl-label span)">
        <span [attr.funding-tooltip-id]="'cocoDaysAboveTriggerTooltip'">
          Days Above Trigger
        </span>
        <lv-xl-label [field]="'COCO_DAYS_ABOVE'"></lv-xl-label>
      </div>
      <lv-numeric-text-box name="cocoDaysAboveTrigger"
        format="n0"
        decimals="0"
        [(ngModel)]="averagingDataSettings.cocoDaysAboveTrigger"
        (didKeyDownEnter)="onAveragingDataSectionChange()"
        (didBlur)="onAveragingDataSectionChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <!-- Running Period Days -->
    <lv-flex-box *ngIf="view.isContingentConversion"
      data-cy="DM-331"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="cocoRunningPeriodDaysTooltip"
        filter="span:not(lv-xl-label span)">
        <span [attr.averaging-data-tooltip-id]="'cocoRunningPeriodDaysTooltip'">
          Running Period Days
        </span>
        <lv-xl-label [field]="'COCO_RUNNING_DAYS'"></lv-xl-label>
      </div>
      <lv-numeric-text-box name="cocoRunningPeriodDays"
        format="n0"
        decimals="0"
        [(ngModel)]="averagingDataSettings.cocoRunningPeriodDays"
        (didKeyDownEnter)="onAveragingDataSectionChange()"
        (didBlur)="onAveragingDataSectionChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <lv-flex-box *ngIf="view.isContingentConversion && !view.isCocoAverageOfConsecutiveDays"
      data-cy="DM-332"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="cocoLastCocoTriggerSatisfied"
        filter="span:not(lv-xl-label span)"
        lvId="lbLastCoCoTriggerSatisfied">
        <span [attr.averaging-data-tooltip-id]="'lastCocoTriggerSatisfiedTooltip'">
          Last CoCo Trigger Satisfied
        </span>
        <lv-xl-label [field]="'COCO_LAST_TRIGGER'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox  lv-volatility-checkbox">
        <input name="lbLastCoCoTriggerSatisfied"
          class="k-checkbox"
          type="checkbox"
          [attr.id]="lbLastCoCoTriggerSatisfiedId"
          [(ngModel)]="averagingDataSettings.lastCocoTriggerSatisfied"
          (change)="onAveragingDataSectionChange()" />
        <label class="k-checkbox-label"
          lvId="lastCocoTriggerSatisfied"
          [attr.for]="lbLastCoCoTriggerSatisfiedId">
        </label>
      </div>
    </lv-flex-box>

    <!-- reset section -->
    <lv-flex-box *ngIf="view.isResettable">
      <div class="lv-label lv-label-field lv-label--title">
        RESET
      </div>
    </lv-flex-box>
    <lv-flex-box *ngIf="view.isResettable"
      data-cy="DM-333"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="currentStockAverageResetTooltip"
        filter="span:not(lv-xl-label span)">
        <span *ngIf="!isOpenedFromExcel"
          [attr.averaging-data-tooltip-id]="'currentStockAverageSpreadTooltip'">
          Current Stock Average ({{ view.cbCcy }})
        </span>
        <span *ngIf="isOpenedFromExcel"
          [attr.averaging-data-tooltip-id]="'currentStockAverageSpreadTooltip'">
          Current Stk Avg &nbsp;({{ view.cbCcy }})
        </span>
        <lv-xl-label [field]="'CURR_STK_AVG_RESET'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box name="resetCurrentStockAverage"
          format="n6"
          decimals="6"
          [(ngModel)]="averagingDataSettings.resetCurrentStockAveraging"
          (didKeyDownEnter)="onAveragingDataSectionChange()"
          (didBlur)="onAveragingDataSectionChange()">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #currentStockAverageConversionTooltip>
  <lv-tooltip dm="dM-324"></lv-tooltip>
</ng-template>
<ng-template #conversionSpecifiedCashAmountTooltip>
  <lv-tooltip dm="dM-328"></lv-tooltip>
</ng-template>
<ng-template #currentStockAverageTooltip>
  <lv-tooltip dm="dM-325"></lv-tooltip>
</ng-template>
<ng-template #daysAboveTriggerTooltip>
  <lv-tooltip dm="dM-326"></lv-tooltip>
</ng-template>
<ng-template #runningPeriodDaysTooltip>
  <lv-tooltip dm="dM-327"></lv-tooltip>
</ng-template>
<ng-template #cocoCurrentStockAverageTooltip>
  <lv-tooltip dm="dM-329"></lv-tooltip>
</ng-template>
<ng-template #cocoDaysAboveTriggerTooltip>
  <lv-tooltip dm="dM-330"></lv-tooltip>
</ng-template>
<ng-template #cocoRunningPeriodDaysTooltip>
  <lv-tooltip dm="dM-331"></lv-tooltip>
</ng-template>
<ng-template #cocoLastCocoTriggerSatisfied>
  <lv-tooltip dm="dM-332"></lv-tooltip>
</ng-template>
<ng-template #currentStockAverageResetTooltip>
  <lv-tooltip dm="dM-333"></lv-tooltip>
</ng-template>