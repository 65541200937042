import { Component, OnInit, Input, Output, EventEmitter, OnChanges,
   OnDestroy, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, Optional } from '@angular/core';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { constants } from '@lv-core-ui/util';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvExcelService } from '@lv-excel/services';
import { CoPayDownsideTriggerScheduleMember } from '@lv-instrument-common/CoPayDownsideTriggerScheduleMember';

@Component({
  selector: 'lv-co-pay-schedule',
  templateUrl: './lv-co-pay-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCoPayScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: CoPayDownsideTriggerScheduleMember[];
  @Output() didCoPayScheduleChange: EventEmitter<CoPayDownsideTriggerScheduleMember[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  scheduleItems: CoPayDownsideTriggerScheduleMember[];
  parseFn: any;
  excelFieldAlias = 'CPN_COPAY_DWNSIDE_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);
    this.scheduleItems = [];

    this.didCoPayScheduleChange = new EventEmitter<CoPayDownsideTriggerScheduleMember[]>();
  }

  ngOnInit() {
    this._modelSubscription = [
      this.advancedGrid.didDataChange.subscribe((records: CoPayDownsideTriggerScheduleMember[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.init();
  }

  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
    this.init();
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required),
    });
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

  private init() {
    this.scheduleItems = this.model.map(a => ({ ...a }));
  }

  private initColumns() {
    this.columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.title = 'Date';
    dateColumn.field = 'startDate';
    dateColumn.dmKey = 'DM-2276';

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Trigger Value';
    valueColumn.field = 'value';
    valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
    valueColumn.format = '#,###.####';
    valueColumn.decimals = '4';
    valueColumn.dmKey = 'DM-2277';

    this.columns.push(dateColumn);
    this.columns.push(valueColumn);
  }

  private parserFunction(pastedDataRecords: string[]): CoPayDownsideTriggerScheduleMember[] {
    const scheduleItems: CoPayDownsideTriggerScheduleMember[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const dateValue = items[0];
      const valueValue = items[1];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'Date');
      const value = MarketDataClipboard.parseNumberValue(valueValue, 'Trigger Value');

      scheduleItems.push({
        startDate: date,
        value: value
      } as CoPayDownsideTriggerScheduleMember);
    });

    return scheduleItems;
  }

  private onScheduleChange(scheduleItems: CoPayDownsideTriggerScheduleMember[]) {
    this.model.splice(0, this.model.length);
    this.model.push(...scheduleItems);
    this.didCoPayScheduleChange.next(this.model);
  }

  private onScheduleReload() {
    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

}
