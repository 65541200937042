import { Component, ViewEncapsulation, ChangeDetectionStrategy, Output,
   EventEmitter, Input, OnChanges, ElementRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CouponType, FrequencyDescription } from '@lv-analytics/models';
import { Put, PutValueTypeDescription, PutValueType } from '@lv-instrument-common/index';
import { DayCountDescription, NoticePeriod } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-puts-put-parameters',
  templateUrl: './lv-puts-put-parameters.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPutsPutParametersComponent implements OnChanges {
  @ViewChild('partialPut', {static: true}) partialPutCheckbox: ElementRef<any>;

  @Input() model: Put;
  @Input() couponType: CouponType;

  @Output() didSetParameters: EventEmitter<Put>;
  @Output() didSetPutValueType: EventEmitter<Put>;

  putValueTypeLookup: LvLookupEnum;
  putYieldFrequencyLookup: LvLookupEnum;
  yieldDayCountLookup: LvLookupEnum;
  noticePeriodLookup: LvLookupEnum;

  keepAccruedCheckBoxId: string;
  forfeitCouponCheckBoxId: string;
  puttableDuringPeriodCheckBoxId: string;
  partialPutCheckBoxId: string;

  areYieldFieldsVisible: boolean;
  isKeepAccruedAndForfeitCouponVisible: boolean;
  isLoading: boolean;

  numberOfDecimals = '0';
  numberFormat = 'n0';

  fourDecimals = '4';
  fourDecimalNumberFormat = '#.####';
  fourDecimalNegativeLabelFormat = '1.0-4';

  /**
   * If checkbox is checked, getter returns true.
   */
  get isPartialPutSelected(): boolean {
    return this.model.partialPut;
  }

  get isPuttableDuringPeriodDisabled(): boolean {
    return this.model.partialPut
  }

  get isPartialPutDisabled(): boolean {
    return this.model.puttableDuringPeriod;
  }

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    if (!this.model) {
      this.model = new Put();
    }

    this.areYieldFieldsVisible = false;
    this.isKeepAccruedAndForfeitCouponVisible = false;

    this.didSetParameters = new EventEmitter<Put>();
    this.didSetPutValueType = new EventEmitter<Put>();

    this.putValueTypeLookup = new LvLookupEnum(PutValueTypeDescription);
    this.putYieldFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.yieldDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.noticePeriodLookup = new LvLookupEnum(NoticePeriod);

    this.keepAccruedCheckBoxId = v4();
    this.forfeitCouponCheckBoxId = v4();
    this.puttableDuringPeriodCheckBoxId = v4();
    this.partialPutCheckBoxId = v4();

    this.isLoading = false;

  }

  ngOnChanges() {
    this.setFieldsVisibility();
  }

  onPutValueTypeChanged() {
    this.setFieldsVisibility();
    this.doPutValueTypeChange();
  }

  private setFieldsVisibility() {
    this.areYieldFieldsVisible = this.model?.valueType === PutValueType.GrossYield ||
                                  this.model?.valueType === PutValueType.NetYield;
    this.isKeepAccruedAndForfeitCouponVisible = this.couponType !== CouponType.ZeroCoupon;
  }

  doModelChange() {
    this.didSetParameters.next(this.model);
  }

  doPutValueTypeChange() {
    this.didSetPutValueType.next(this.model);
  }

  getPutParametersTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
