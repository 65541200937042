import { ConvertibleHelper } from '@lv-analytics/helpers/convertible';
import { IConvertible } from '@lv-analytics/models/convertible';
import { ConversionSettlement } from '@lv-convertible-bond/models/convertible-bond-terms';
import { ContingentConversionPeriodType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { LvDateService } from '@lv-core-ui/services';
import { CallTriggerPeriodType } from '@lv-instrument-common/index';

/**
 * Averaging data view.
 */
export class AveragingDataView {

  cHelper: ConvertibleHelper;

  get cbCcy(): string {
    return this.cHelper.currencyCode;
  }

  get isCallable(): boolean {
    return this.cHelper.isCallable;
  }

  get isCallAverageOfConsecutiveDays(): boolean {
    return this.cHelper.callTriggerPeriodType === CallTriggerPeriodType.AverageOfConsecutiveDays;
  }

  get isCocoAverageOfConsecutiveDays(): boolean {
    return this.cHelper.contingentConversionPeriodType === ContingentConversionPeriodType.AverageOfConsecutiveDays;
  }

  get isCash(): boolean {
    if (this.cHelper.conversionSettlement === null) {
      return false;
    }
    return this.cHelper.conversionSettlement === ConversionSettlement.Cash;
  }

  get isCombination(): boolean {
    if (this.cHelper.conversionSettlement === null) {
      return false;
    }
    return this.cHelper.conversionSettlement === ConversionSettlement.CashOrShares;
  }

  get isSharesBasedOnAvg(): boolean {
    if (this.cHelper.conversionSettlement === null) {
      return false;
    }
    return this.cHelper.conversionSettlement === ConversionSettlement.SharesBasedOnAveraging;  
  }




  get isResettable(): boolean {
    return this.cHelper.resettable;
  }

  get isContingentConversion(): boolean {
    return this.cHelper.contingentConversion;
  }

  get isAcquisitionSharesSettlement(): boolean {
    return this.cHelper.useAcquisitionSharesSettlement;
  }

  constructor(
    private _lvDateService: LvDateService
  ) {
    this.cHelper = new ConvertibleHelper(_lvDateService);
  }

  /**
   * Does initialization.
   * @param convertible IConvertible object.
   */
  init(convertible?: IConvertible) {
    if (convertible) {
      this.cHelper.init(convertible);
    }
  }
}
