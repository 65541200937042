import { LvUtil } from '@lv-core-ui/util';
import * as _ from 'lodash';
import { IInstrumentWidgetState, IInstrumentWidgetVisibility } from '.';
import { defaultWidgetsConvertibleBond, defaultWidgetsBond, defaultWidgetsEquity, defaultWidgetsAssetSwap } from './instrument.widgets';

export class InstrumentView {
  public widgets: IInstrumentWidgetState[];
  public widgetDict: {
    [id: string]: IInstrumentWidgetState;
  };
  currentlySelectedTab: string;

  private sortedTabInstrumentWidgetIds: Map<string, number>;

  constructor(instrumentType?: string) {
    this.init([], instrumentType);
    this.sortedTabInstrumentWidgetIds = new Map();
    this.currentlySelectedTab = null;
  }

  /**
   * Init instrument view.
   */
  init(widgets: IInstrumentWidgetState[], instrumentType: string) {
    this.widgets = widgets.map(a => _.cloneDeep(a));
    let isInstrumentTypeAndWidgetsMatch = true;
    
    /**
     * This covers the case when bond was selected and someone clear selected instrument
     * Then widgets be populated for cb because if instrument type is null by default 
     * widgets for convertible bond will be retrieved. 
     * So we need detect that case and forbid selecting of default widgets later in code.
     */
    if (!instrumentType) {
      isInstrumentTypeAndWidgetsMatch = !this.widgets.find(widget => widget.id.includes('bond_'));
    }

    this.widgets.forEach(w => {
      if (isInstrumentTypeAndWidgetsMatch && !!w?.gridsterItemConfig) {
        const defaultWidget = this.getInstrumentTypeDefaultWidgets(instrumentType).find(dw => dw.id === w.id);
        w.gridsterItemConfig.maxItemCols = defaultWidget.gridsterItemConfig.maxItemCols;
        w.gridsterItemConfig.maxItemRows = defaultWidget.gridsterItemConfig.maxItemCols;

        w.gridsterItemConfig.minItemCols = defaultWidget.gridsterItemConfig.minItemCols;
        w.gridsterItemConfig.minItemRows = defaultWidget.gridsterItemConfig.minItemRows;
      }
    });
    this.widgetDict = LvUtil.toDictionary(this.widgets, 'id');
    this.setSortedTabWidgets();
  }

  setSelectedTab(position: number) {
    this.sortedTabInstrumentWidgetIds.forEach((tabIndex, widgetId) => {
      if (tabIndex === position) {
        this.currentlySelectedTab = widgetId;
      }
    });
  }

  getIsVisibilityOptionSelected(vo: IInstrumentWidgetVisibility, widget: IInstrumentWidgetState): boolean {
    const found = this.widgetDict[widget.id];

    if (found) {
      return found.visibility === vo;
    }

    return false;
  }

  clearTabSelection() {
    this.currentlySelectedTab = null;
  }

  setWidgetVisibility(
    instrumentWidgetVisibility: IInstrumentWidgetVisibility,
    widget: IInstrumentWidgetState,
    shouldActivateFirstTabFn: (shouldActivateFirstTab: boolean) => void) {
    const found = this.widgetDict[widget.id];

    if (found) {
      found.visibility = instrumentWidgetVisibility;

      if (instrumentWidgetVisibility !== IInstrumentWidgetVisibility.AS_TAB && this.sortedTabInstrumentWidgetIds.has(widget.id)) {
        this.sortedTabInstrumentWidgetIds.delete(widget.id);
      }
    }

    this.setSortedTabWidgets();

    shouldActivateFirstTabFn(!this.sortedTabInstrumentWidgetIds.has(this.currentlySelectedTab));
  }

  getWidgetVisibilityAsTab(widgetId: string) {
    const found = this.widgetDict[widgetId];
    if (found) {
      return found.visibility === IInstrumentWidgetVisibility.AS_TAB;
    }

    return false;
  }

  getVisibilityLvId(vo: IInstrumentWidgetVisibility) {
    switch (vo) {
      case IInstrumentWidgetVisibility.AS_TAB: {
        return 'instrument-display-as-tab';
      }
      case IInstrumentWidgetVisibility.AS_WIDGET: {
        return 'instrument-display-as-widget';
      }
      default: {
        return 'instrument-display-none';
      }
    }
  }

  getVisibilityIconClass(vo: IInstrumentWidgetVisibility) {
    switch (vo) {
      case IInstrumentWidgetVisibility.AS_TAB: {
        return 'lv-font-icon lv-font-icon-tabs';
      }
      case IInstrumentWidgetVisibility.AS_WIDGET: {
        return 'lv-font-icon lv-font-icon-modal-list';
      }
      default: {
        return 'k-icon k-i-cancel';
      }
    }
  }

  isOnlyWidget(widget: IInstrumentWidgetState): boolean {
    return !widget.visibilityOptions.find(x => x === IInstrumentWidgetVisibility.AS_TAB);
  }

  private setSortedTabWidgets() {
    this.sortedTabInstrumentWidgetIds = Object.keys(this.widgetDict)
      .filter(k => this.widgetDict[k].visibility === IInstrumentWidgetVisibility.AS_TAB)
      .map(k => this.widgetDict[k])
      .reduce((sortedWidgets: Map<string, number>, instrumentWidget: IInstrumentWidgetState, index: number) => {
        sortedWidgets.set(instrumentWidget.id, index);
        return sortedWidgets;
      }, new Map<string, number>());
  }

  private getInstrumentTypeDefaultWidgets(instrumentType): IInstrumentWidgetState[]{
    switch(instrumentType) {
      case 'ConvertibleBond': {
        return defaultWidgetsConvertibleBond;
      }
      case 'Bond': {
        return defaultWidgetsBond;
      }
      case 'Equity': {
        return defaultWidgetsEquity;
      }
      case 'ASW': {
        return defaultWidgetsAssetSwap;
      }
      default: {
        return defaultWidgetsConvertibleBond;
      }
    }
  }
}
