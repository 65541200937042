<lv-flex-box class="lv-interest-rates">
  <lv-environment-settings
    [section]="interestRatesSection"
    lvId="marketDataInterestRatesEnvironmentSettings"
    instrumentType="Bond"
    (didChangeEnvironment)="onChangeEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-interest-rates-content lv-market-data-section-content" direction="row">
    <lv-flex-box class="lv-interest-rates-currency-section">
      <lv-flex-box data-cy="DM-553" direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="interestRatesSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.interest-rates-tooltip-id]="'interestRatesTooltip'">
            Interest Rate Source
          </span>
          <lv-xl-label [field]="'IR_CURVE_TYPE_INST'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="cbInstrumentInterestRatesSource"
            valueField="id"
            textField="text"
            [data]="instrumentInterestRatesSource.items"
            [disabled]="!instrumentLoaded"
            [valuePrimitive]="true"
            [(ngModel)]="interestRatesSettings.instrumentInterestRateSource"
            (ngModelChange)="onInstrumentInterestRateSourceChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>

      <lv-flex-box *ngIf="isInstrumentInterestRateSourceFlat"
        data-cy="DM-551"
        direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="interestRatesFlatRateTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.interest-rates-tooltip-id]="'flatRateTooltip'">
            Flat Rate
          </span>
          <lv-xl-label [field]="'IR_FLAT_INST'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box name="instrumentFlatRate"
            [format]="numberFormatPercentage"
            [decimals]="numberOfDecimalsPercentage"
            [(ngModel)]="interestRatesSettings.instrumentFlatRate"
            (didBlur)="onInstrumentInterestRateSourceChange()"
            (didKeyDownEnter)="onInstrumentInterestRateSourceChange()"
            required>
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        *ngIf="!isInstrumentInterestRateSourceFlat"
        data-cy="DM-554">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="interestRatesYieldCurveTooltipTemplate"
          filter="span">
          <span [attr.interest-rates-tooltip-id]="'yieldCurveTooltip'">
            Yield Curve
          </span>
          <lv-xl-label [field]="'IR_YC_INST'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            class="lv-interest-rates-currency-section-yield-curve"
            name="cbInstrumentYieldCurve"
            valueField="id"
            textField="name"
            [data]="instrumentYieldCurve"
            [disabled]="!instrumentLoaded"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 210 }"
            [(ngModel)]="interestRatesSettings.yieldCurveId"
            (ngModelChange)="onInstrumentYieldCurveChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>

      <lv-flex-box
        *ngIf="!isInstrumentInterestRateSourceFlat && instrumentLoaded"
        data-cy="DM-561">
        <lv-flex-box>
          <lv-flex-box direction="row" class="lv-interest-rates-grid-title">
            <div showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="instrumentYieldCurveScheduleTooltipTemplate"
              filter=".lv-label" 
              class="lv-label lv-label-field lv-label--title">
              <span [attr.interest-rates-tooltip-id]="'instrumentYieldCurveScheduleTooltip'">
                YIELD CURVE
              </span> 
            </div>
          </lv-flex-box>
          <lv-yield-curve-terms-structure #instrumentYieldCurveTerms
            [settings]="instrumentYieldCurveSettings"
            [yieldCurveScheduledItems]="instrumentYieldCurveScheduledItems"
            [interestRatesCopyAndPasteSettings]="interestRatesCopyAndPasteSettings"
            (yieldCurveScheduleChange)="onInterestRatesSectionChange()"
            (isLoadingStateChange)="setLoadingState($event)"
            [hasOverrideFromExcel]="hasScheduleInExcelOverride"
            [isFieldFromExcelEnabled]="isScheduleInExcelEditable"
            [excelFieldAlias]="scheduleInExcelOverrideAlias"
            [lvId]="'lv-instrument-yield-curve-term-structure'">
          </lv-yield-curve-terms-structure>
        </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #interestRatesSourceTooltipTemplate>
  <lv-tooltip dm="dM-553"></lv-tooltip>
</ng-template>

<ng-template #interestRatesFlatRateTooltipTemplate>
  <lv-tooltip dm="dM-551"></lv-tooltip>
</ng-template>

<ng-template #interestRatesYieldCurveTooltipTemplate>
  <lv-tooltip dm="dM-554"></lv-tooltip>
</ng-template>

<ng-template #instrumentYieldCurveScheduleTooltipTemplate>
  <lv-tooltip dm="dM-561"></lv-tooltip>
</ng-template>
