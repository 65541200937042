<kendo-tabstrip class="lv-tabstrip lv-settings-content"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="Shift Parameters">
    <ng-template kendoTabContent>
      <lv-shift-parameters-settings [settings]="settings.shiftParametersSettings"></lv-shift-parameters-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Warnings">
    <ng-template kendoTabContent>
      <lv-warnings-settings [settings]="settings.warningsSettings"></lv-warnings-settings>
    </ng-template>
  </kendo-tabstrip-tab>
  
  <kendo-tabstrip-tab
    title="Bucketing"
    cssClass="DM-4558">
    <ng-template kendoTabContent>
      <lv-bucketing-settings
        [tenors]="tenors"
        [settings]="settings.bucketingSettings"
        (listChanged)="detectChanges()"
        data-cy="DM-4558">
      </lv-bucketing-settings>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>

<lv-flex-box class="lv-settings-footer" direction="row">
  <div class="lv-settings-footer-divider">&nbsp;</div>
  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-valuation-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    [class.save-settings-btn-disabled]="!isChanged()"
    lvId="save-valuation-settings"
    (click)="onSave()">
    Save</button>
</lv-flex-box>
