<lv-flex-box class="lv-volatility" [style.min-width.px]="sectionMinWidth">
  <lv-environment-settings
    [section]="volatilitySection"
    instrumentType="ConvertibleBond"
    lvId="marketDataVolatilityEnvironmentSettings"
    (didChangeEnvironment)="onChangeEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-volatility-content lv-market-data-section-content">

    <lv-flex-box data-cy="DM-301" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="volatilitySourceTooltipTemplate"
        filter="span"
        lvId="lbVolatilitySource">
        <span [attr.volatility-tooltip-id]="'volatilitySourceTooltip'">
          Volatility Source
        </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="cbVolatilitySource"
          valueField="id"
          textField="text"
          [data]="volatilitySource.items"
          [valuePrimitive]="true"
          [disabled]="isVolatilitySectionDisabled"
          [(ngModel)]="view.volatilitySettings.volType"
          (ngModelChange)="onVolatilitySourceChange()"
          [popupSettings]="popupSettings">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <ng-container *ngIf="!isVolatilitySectionDisabled">
      <div *ngIf="isUnderlyingVolatility">
        <lv-flex-box *ngIf="view.isUnderlyingVolatilityFlat">
          <lv-equity-volatility-flat
            [volatilityFlat]="view.underlyingEquityVolatility"
            [isEditable]="isEditable">
          </lv-equity-volatility-flat>
        </lv-flex-box>
        <lv-flex-box data-cy="DM-292"
          direction="row"
          class="lv-equity-risky-risk-free">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="riskyTooltipTemplate"
            filter="span:not(lv-xl-label span)"
            lvId="lbCbRisk">
            <span>
              Risky / Risk Free
            </span>
            <lv-xl-label [field]="'VOL_RISK_TYPE'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
            <span>{{displayNonEditableVolatilitySurfaceType}}</span>
          </div>
        </lv-flex-box>
        <lv-flex-box *ngIf="view.isUnderlyingVolatilityTermStructure">
          <lv-equity-volatility-terms-structure
           [volatilityTermStructureSchedule]="view.underlyingEquityVolatility.volatilityTermStructureSchedule"
           [isEditable]="isEditable"
          ></lv-equity-volatility-terms-structure>
         </lv-flex-box>
         <lv-flex-box *ngIf="view.isUnderlyingVolatilitySurface">
           <lv-equity-volatility-surface
              [volatilitySurface]="view.underlyingEquityVolatility.volatilitySurface"
              [isEditable]="isEditable">
            </lv-equity-volatility-surface>
         </lv-flex-box>
      </div>
      <lv-flex-box *ngIf="view.isFlatVolatility"
        data-cy="DM-286"
        direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="volatilityFlatTooltipTemplate"
          filter="span:not(lv-xl-label span)"
          lvId="lbVolatility">
          <span [attr.volatility-tooltip-id]="'volatilityTooltip'">
            Volatility
          </span>
          <lv-xl-label [field]="'VOL_FLAT'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box name="volatility"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.volatilitySettings.flatVol"
            (didBlur)="onVolatilitySourceChange()"
            (didKeyDownEnter)="onVolatilitySourceChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label" lvId="volatilitySuffix">%</span>
        </div>
      </lv-flex-box>

      <lv-flex-box direction="row" *ngIf="view.isUpsideDownside" data-cy="DM-287">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="upsideVolatilityTooltipTemplate"
          filter="span:not(lv-xl-label span)"
          lvId="lbUpsideVolatility">
          <span [attr.volatility-tooltip-id]="'upsideVolatilityTooltip'">
            Upside Volatility
          </span>
          <lv-xl-label [field]="'VOL_UP'"></lv-xl-label>
        </div>
        <div class="lv-display-table-cell lv-input-field">
          <lv-numeric-text-box name="upsideVolatility"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.volatilitySettings.upsideVol"
            (didBlur)="onVolatilitySourceChange()"
            (didKeyDownEnter)="onVolatilitySourceChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label" lvId="upsideVolatilitySuffix">%</span>
        </div>
      </lv-flex-box>

      <lv-flex-box direction="row" *ngIf="view.isUpsideDownside" data-cy="DM-288">
        <div class="lv-display-table-cell lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="downsideVolatilityTooltipTemplate"
          filter="span:not(lv-xl-label span)"
          lvId="lbDownsideVolatility">
          <span class="lv-label"
            [attr.volatility-tooltip-id]="'downsideVolatilityTooltip'">
            Downside Volatility
          </span>
          <lv-xl-label [field]="'VOL_DOWN'"></lv-xl-label>
        </div>
        <div class="lv-display-table-cell lv-input-field">
          <lv-numeric-text-box name="downsideVolatility"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.volatilitySettings.downsideVol"
            (didBlur)="onVolatilitySourceChange()"
            (didKeyDownEnter)="onVolatilitySourceChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label" lvId="downsideVolatilitySuffix">%</span>
        </div>
      </lv-flex-box>
    </ng-container>

    <lv-flex-box direction="row" *ngIf="view.isUpsideDownside && !isVolatilitySectionDisabled" data-cy="DM-291">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="localVolFrom2VolsTooltipTemplate"
        filter="span:not(lv-xl-label span)"
        lvId="lbUseLocalVolFromTwoVols">
        <span [attr.volatility-tooltip-id]="'localVolFrom2VolsTooltip'">
          Local Vol. from 2-vols
        </span>
        <lv-xl-label [field]="'VOL_LCL'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox  lv-volatility-checkbox">
        <input name="useLocalVolFromTwoVols"
          class="k-checkbox"
          type="checkbox"
          [attr.id]="view.useLocalVolFromTwoVolsId"
          [(ngModel)]="view.volatilitySettings.useLocalVolFromTwoVols"
          (change)="onVolatilitySourceChange()"/>
        <label class="k-checkbox-label"
          lvId="useLocalVolFromTwoVols"
          [attr.for]="view.useLocalVolFromTwoVolsId">
        </label>
      </div>
    </lv-flex-box>

    <lv-flex-box
      *ngIf="!isUnderlyingVolatility"
      data-cy="DM-292"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="riskyTooltipTemplate"
        filter="span:not(lv-xl-label span)"
        lvId="lbCbRisk">
        <span [attr.volatility-tooltip-id]="'riskyTooltip'">
          Risky / Risk Free
        </span>
        <lv-xl-label [field]="'VOL_RISK_TYPE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          name="cbRisk"
          valueField="id"
          textField="text"
          [data]="riskyType.items"
          [disabled]="isVolatilitySectionDisabled"
          [valuePrimitive]="true"
          [(ngModel)]="view.volatilitySettings.riskyType"
          (ngModelChange)="onVolatilitySourceChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <ng-container *ngIf="isFxVolatilityAndFxCorrelationShown">
      <lv-flex-box data-cy="DM-289" direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="fxVolatilityTooltipTemplate"
          filter="span:not(lv-xl-label span)"
          lvId="lbFxVolatility">
          <span [attr.volatility-tooltip-id]="'fxVolatilityTooltip'">
            FX Volatility
          </span>
          <lv-xl-label [field]="'VOL_FX'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box name="fxVolatility"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.volatilitySettings.fxVolatility"
            (didBlur)="onVolatilitySourceChange()"
            (didKeyDownEnter)="onVolatilitySourceChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label" lvId="fxVolatilitySuffix">%</span>
        </div>
      </lv-flex-box>

      <lv-flex-box
        data-cy="DM-290"
        direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="fxCorrelationTooltipTemplate"
          filter="span:not(lv-xl-label span)"
          lvId="lbFxCorrelation">
          <span [attr.volatility-tooltip-id]="'fxCorrelationTooltip'">
            FX Correlation
          </span>
          <lv-xl-label [field]="'VOL_FX_CORR'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box name="fxCorrelation"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.volatilitySettings.fxCorrelation"
            (didBlur)="onVolatilitySourceChange()"
            (didKeyDownEnter)="onVolatilitySourceChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label" lvId="fxCorrelationSuffix">%</span>
        </div>
      </lv-flex-box>
    </ng-container>

    <ng-container *ngIf="!isVolatilitySectionDisabled">
      <lv-flex-box *ngIf="view.isTermStructure">
        <lv-flex-box direction="row" class="lv-volatility-grid-title">
          <div showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="volatilityTermStructureTooltipTemplate"
            filter=".lv-label" 
            class="lv-label lv-label-field lv-label--title">
            <span [attr.volatility-tooltip-id]="'volatilityTermStructureTooltip'">
              VOLATILITY TERM STRUCTURE
            </span> 
          </div>
        </lv-flex-box>
        <lv-volatility-term-structure
          [volatilityTermStructureSchedule]="view.volatilitySettings.volatilityTermStructureSchedule"
          (volatilityTermStructureChange)="onVolatilitySourceChange()">
        </lv-volatility-term-structure>
      </lv-flex-box>
      <div *ngIf="view.isSurface">
        <lv-volatility-surface
          [volatilitySurface]="view.volatilitySettings.volatilitySurface"
          (volatilitySurfaceChange)="onVolatilitySourceChange()">
        </lv-volatility-surface>
      </div>
    </ng-container>
  </lv-flex-box>
</lv-flex-box>

<ng-template #volatilitySourceTooltipTemplate>
  <lv-tooltip dm="dM-301"></lv-tooltip>
</ng-template>
<ng-template #volatilityFlatTooltipTemplate>
  <lv-tooltip dm="dM-286"></lv-tooltip>
</ng-template>
<ng-template #upsideVolatilityTooltipTemplate>
  <lv-tooltip dm="dM-287"></lv-tooltip>
</ng-template>
<ng-template #downsideVolatilityTooltipTemplate>
  <lv-tooltip dm="dM-288"></lv-tooltip>
</ng-template>
<ng-template #localVolFrom2VolsTooltipTemplate>
  <lv-tooltip dm="dM-291"></lv-tooltip>
</ng-template>
<ng-template #riskyTooltipTemplate>
  <lv-tooltip dm="dM-292"></lv-tooltip>
</ng-template>
<ng-template #fxVolatilityTooltipTemplate>
  <lv-tooltip dm="dM-289"></lv-tooltip>
</ng-template>
<ng-template #fxCorrelationTooltipTemplate>
  <lv-tooltip dm="dM-290"></lv-tooltip>
</ng-template>
<ng-template #volatilityTermStructureTooltipTemplate>
  <lv-tooltip dm="dM-293"></lv-tooltip>
</ng-template>
