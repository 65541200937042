import { PriceTalk } from './PriceTalk';
import { StockPriceReference } from './StockPriceReference';
import { ConvertibleBondTermsDocument } from './ConvertibleBondTermsDocument';
import { ConvertibleBondSubType } from './ConvertibleBondSubType';
import { LvMath } from '@lv-core-ui/util';
import { LvDateService } from '@lv-core-ui/services';
import { SetupStatus } from '@lv-instrument-common/index';

export class ConvertibleBondNewIssueDocument extends ConvertibleBondTermsDocument {    
  priceTalk: PriceTalk;
  stockPriceReference: StockPriceReference;

  /**
   * Get Conversion ratio from conersion schedule or calculate for New Issue instrument
   * @param crossFx value for crossFx in case of cross currency instruments
   */
    public getConversionRatio(lvDateService: LvDateService, crossFx: number): number {
      let ratio = 0;
      const today = lvDateService.getUtcDate(new Date());
  
      //TODO: Only for Non New Issue because in case of New Issue status we need data from Pricing and Valuation section
      if (this.issueAndRedemption && this.issueAndRedemption.setupStatus !== SetupStatus.NewIssue) {
        if (this.issueAndRedemption.subType === ConvertibleBondSubType.Regular ||
            this.issueAndRedemption.subType === ConvertibleBondSubType.ConvertibleWithDetachableWarrant) {
  
            if (this.conversion.schedule && this.conversion.schedule.length > 0) {
              //this.conversion.schedule.sort(LvUtil.sortBy('startDate', true));
              const row = this.conversion.schedule.find(x => x.startDate <= today);
              ratio = row ? row.ratio : 0;
            }
        }
        if (this.issueAndRedemption.subType === ConvertibleBondSubType.PEPS &&
           this.conversion.pepsConversion &&
           !LvMath.isZeroNumber(this.conversion.pepsConversion.higherStrike)) {
          ratio = this.issueAndRedemption.nominalValue / this.conversion.pepsConversion.higherStrike;
        }
        if (this.issueAndRedemption.subType === ConvertibleBondSubType.PERCS &&
            this.conversion.percsConversion) {
          ratio = this.conversion.percsConversion.conversionRatio;
        }
      }
  
      return ratio;
    } 

  }
