/**
 * Dividend source description.
 */
export enum DividendsSourceEnumDescription {
  NoDividends = 'No Dividends',
  // FlatYield = 'Flat Yield',
  Parameters = 'Parameters',
  Schedule = 'Schedule',
  UnderlyingEquityDataParameters = 'Underlying Equity Data - Parameters',
  UnderlyingEquityDataSchedule = 'Underlying Equity Data - Schedule'
  // historyProjection = 'Leversys - History Projection'
}

/**
 * Types of dividends source/
 */
export enum DividendsSourceEnum {
  NoDividends = 'NoDividends',
  // FlatYield = 'FlatYield',
  Parameters = 'Parameters',
  Schedule = 'Schedule',
  UnderlyingEquityDataParameters = 'UnderlyingEquityDataParameters',
  UnderlyingEquityDataSchedule = 'UnderlyingEquityDataSchedule'
  // historyProjection = 'Leversys - HistoryProjection'
}

