<lv-flex-box class="credit-settings-column">

  <lv-flex-box
    direction="row"
    data-cy="DM-1462">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditSourceSettingsTooltipTemplate"
      filter="span">
      <span> Credit Source </span>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="creditSource"
        lvId="credit-settings-credit-source"
        valueField="id"
        textField="text"
        [data]="creditSource.items"
        [valuePrimitive]="true"
        [(ngModel)]="settings.creditSource"
        [disabled]="!overrideSystemDefaults"
        [popupSettings]="{
          width: 226
        }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-1465">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditRecoveryBondSettingsTooltipTemplate"
      filter="span">
      <span> Recovery (Bond) </span>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="recoveryBond"
        lvId="credit-settings-recovery-bond"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="settings.recoveryBond"
        [disabled]="!overrideSystemDefaults">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-1466">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditRecoveryCDSSettingsTooltipTemplate"
      filter="span">
      <span> Recovery (CDS) </span>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="recoveryCds"
        lvId="credit-settings-recovery-cds"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="settings.recoveryCds"
        [disabled]="!overrideSystemDefaults">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-1467">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditBadDayConventionSettingsTooltipTemplate"
      filter="span">
      <span>  Bad Day Convention </span>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="badDayConvention"
        lvId="credit-settings-bad-day-convention"
        valueField="id"
        textField="text"
        [data]="badDayConvention.items"
        [valuePrimitive]="true"
        [(ngModel)]="settings.badDayConvention"
        [disabled]="!overrideSystemDefaults"
        [popupSettings]="{
          width: 137
        }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-1468">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditAccruedOnDefaultSettingsTooltipTemplate"
      filter="span">
      <span> Accrued on Default </span>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="accruedOnDefault"
        lvId="credit-settings-accrued-on-default"
        valueField="id"
        textField="text"
        [data]="accruedOnDefault.items"
        [valuePrimitive]="true"
        [(ngModel)]="settings.accruedOnDefault"
        [disabled]="!overrideSystemDefaults">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>

<lv-flex-box class="credit-settings-column">

  <lv-flex-box
    direction="row"
    data-cy="DM-1469">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditCreditFactorModelTooltipTemplate"
      filter="span">
      <span> Credit Factor Model </span>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="creditFactorModel"
        lvId="credit-settings-credit-factor-model"
        valueField="id"
        textField="text"
        [data]="creditFactorModel.items"
        [valuePrimitive]="true"
        [(ngModel)]="settings.creditFactorModel"
        [disabled]="!overrideSystemDefaults">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-1470">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditCalibrationTypeSettingsTooltipTemplate"
      filter="span">
      <span> Calibration Type </span>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="calibrationType"
        lvId="credit-settings-calibration-type"
        valueField="id"
        textField="text"
        [data]="calibrationType.items"
        [valuePrimitive]="true"
        [(ngModel)]="settings.equityToCreditCalibrationType"
        [disabled]="!overrideSystemDefaults"
        [popupSettings]="{
          width: 141
        }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box
    direction="row"
    data-cy="DM-1471">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="globalRecoverySettingsTooltipTemplate"
      filter="span">
      <span> Global Recovery </span>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="globalRecovery"
        lvId="credit-settings-global-recovery"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="settings.globalRecovery"
        [disabled]="!overrideSystemDefaults">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #creditSourceSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1462"></lv-tooltip>
</ng-template>
<ng-template #creditFrequencySettingsTooltipTemplate>
  <lv-tooltip dm="dM-1463"></lv-tooltip>
</ng-template>
<ng-template #creditBasisSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1464"></lv-tooltip>
</ng-template>
<ng-template #creditRecoveryBondSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1465"></lv-tooltip>
</ng-template>
<ng-template #creditRecoveryCDSSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1466"></lv-tooltip>
</ng-template>
<ng-template #creditBadDayConventionSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1467"></lv-tooltip>
</ng-template>
<ng-template #creditAccruedOnDefaultSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1468"></lv-tooltip>
</ng-template>
<ng-template #creditCreditFactorModelTooltipTemplate>
  <lv-tooltip dm="dM-1469"></lv-tooltip>
</ng-template>
<ng-template #creditCalibrationTypeSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1470"></lv-tooltip>
</ng-template>
<ng-template #globalRecoverySettingsTooltipTemplate>
  <lv-tooltip dm="dM-1471"></lv-tooltip>
</ng-template>
