import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { VolatilitySurfaceRiskyTypeDescription } from '@lv-analytics/models';
import { IEquityVolatilitySectionSettingsContent } from '@lv-analytics/models/equity/market-data/market-data-equity-settings/market-data-equity-volatility-settings/equity-volatility-settings-content/equity-volatility-section-settings-content';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-volatility-equity-section-settings',
  templateUrl: './lv-volatility-equity-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvVolatilityEquitySectionSettingsComponent {
  @Input() settings: IEquityVolatilitySectionSettingsContent;
  @Input() overrideSystemDefaults: boolean;

  riskyTypeEnum: LvLookupEnum;

  constructor() {
    this.riskyTypeEnum = new LvLookupEnum(VolatilitySurfaceRiskyTypeDescription);
    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-volatility-equity-section-settings')
  get isLvVolatilityEquitySectionSettingsComponent() {
    return true;
  }
}
