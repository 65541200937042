import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components';
import { LvAdvancedGridColumn, LvAdvancedGridComponent, LvAdvancedGridDateColumn, LvAdvancedGridEnumColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum, constants } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';
import { CallScheduleItem, CallValueType, CallValueTypeDescription, InterpolationType } from '@lv-instrument-common/index';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lv-bond-calls-schedule',
  templateUrl: './lv-bond-calls-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBondCallsScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: CallScheduleItem[];
  @Input() valueType: CallValueType;
  @Output() didCallsScheduleChange: EventEmitter<CallScheduleItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  columns: LvAdvancedGridColumn[];
  scheduleItems: CallScheduleItem[];
  parseFn: any;

  formatFour = '#,###.####';
  decimalsFour = '4';
  excelFieldAlias = 'CALL_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  private subscriptions: Subscription[];

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {

      this.parseFn = this.parserFunction.bind(this);
      this.scheduleItems = [];
      this.model = [];

      this.didCallsScheduleChange = new EventEmitter<CallScheduleItem[]>();
    }

    ngOnInit() {
      this.subscriptions = [
        this.advancedGrid.didDataChange.subscribe((records: CallScheduleItem[]) => this.onScheduleChange(records)),
        this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
        this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
      ];
    }
  
    ngOnDestroy() {
      this.subscriptions.forEach(a => a.unsubscribe());
    }
  
    ngOnChanges() {
      this.init();
      this.initColumns();
    }
  
    applyAdvancedGridChanges() {
      this.advancedGrid.applyChanges(records => this.applyRecords(records));
    }
  
    createFormGroup(args: CreateFormGroupArgs): FormGroup {
      args.dataItem.interpolation = args.dataItem.interpolation ? args.dataItem.interpolation : InterpolationType.Staircase;
      return  new FormGroup({
        'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
        'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate),
        'price': new FormControl(args.dataItem.price),
        'trigger': new FormControl(args.dataItem.trigger),
        'interpolation': new FormControl(args.dataItem.interpolation, Validators.required)
      });
    }
  
    /**
   * Reset function for price Call in schedule in case when type is AccretedValue.
   */
    public resetPriceCallSchedule(callValueType: CallValueType) {
      this.scheduleItems = this.model.map(a => {
        if (callValueType === CallValueType.AccretedValue) {
          a.price = null;
        }
  
        return { ...a };
      });
  
      if (callValueType === CallValueType.AccretedValue) {
        this.onScheduleChange(this.scheduleItems);
      }
    }
  
    private init() {
      this.scheduleItems = this.model.map(a => {
        if (this.isAccretedCall()) {
          a.price = null;
        }
  
        return { ...a };
      });
    }
  
    private onScheduleChange(scheduleItems: CallScheduleItem[]) {
      this.applyRecords(scheduleItems);
    }
  
    private applyRecords(records: any[]) {
      this.model.splice(0, this.model.length);
      this.model.push(...records);
      this.didCallsScheduleChange.next(this.model);
    }
  
    private onScheduleReload() {
      this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
    }
  
    private onError(error: LvError) {
      this._errorService.handleError(error);
    }
  
    /**
     * Initializes grid for calls schedule
     */
    private initColumns() {
      this.columns = [];
  
      const startDateColumn = new LvAdvancedGridDateColumn();
      startDateColumn.title = 'Start Date';
      startDateColumn.field = 'startDate';
      startDateColumn.dmKey = 'DM-2278';
  
      const endDateColumn = new LvAdvancedGridDateColumn();
      endDateColumn.title = 'End Date';
      endDateColumn.field = 'endDate';
      endDateColumn.dmKey = 'DM-2279';
  
      const priceColumn = new LvAdvancedGridNumericColumn();
      priceColumn.title = this.priceColumnLabel();
      priceColumn.field = 'price';
      priceColumn.width = 90;
      priceColumn.outputFormat = constants.numberFormat.upToFourDigits;
      priceColumn.format = this.formatFour;
      priceColumn.decimals = this.decimalsFour;
      priceColumn.editable = !this.isAccretedCall();
      priceColumn.dmKey = 'DM-2280';
  
      const triggerColumn = new LvAdvancedGridNumericColumn();
      triggerColumn.title = 'Trigger';
      triggerColumn.field = 'trigger';
      triggerColumn.width = 90;
      triggerColumn.outputFormat = constants.numberFormat.upToFourDigits;
      triggerColumn.format = this.formatFour;
      triggerColumn.decimals = this.decimalsFour;
      triggerColumn.dmKey = 'DM-2281';
      triggerColumn.visible = false;
  
      const interpolationColumn = new LvAdvancedGridEnumColumn();
      interpolationColumn.enumDescription = InterpolationType;
      interpolationColumn.title = 'Interpolation';
      interpolationColumn.field = 'interpolation';
      interpolationColumn.displayField = 'text';
      interpolationColumn.valueField = 'id';
      interpolationColumn.valuePrimitive = true;
      interpolationColumn.data = new LvLookupEnum(InterpolationType).items;
      interpolationColumn.width = 97;
      interpolationColumn.dmKey = 'DM-2282';
  
      this.columns.push(startDateColumn);
      this.columns.push(endDateColumn);
      this.columns.push(priceColumn);
      this.columns.push(triggerColumn);
      this.columns.push(interpolationColumn);
    }
  
  /**
 * Parse schedule columns.
 */
    private parserFunction(pastedDataRecords: string[]): CallScheduleItem[] {
      const scheduleItems: CallScheduleItem[] = [];
  
      pastedDataRecords.forEach(r => {
        const items = r.split('\t');
  
        const startDateValue = items[0];
        const endDateValue = items[1];
        const priceValue = items[2];
        const triggerValue = items[3];
        const interpolationTypeValue = items[4];
  
        const startDate = MarketDataClipboard.parseDateValue(startDateValue, 'Start Date');
        const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
        const price = MarketDataClipboard.parseNumberValue(priceValue, 'Value');
        const trigger = MarketDataClipboard.tryParseNumberValue(triggerValue, 'Threshold Value');
        const interpolationType = this.parseInterpolationType(interpolationTypeValue);
  
  
        scheduleItems.push({
          startDate: startDate,
          endDate: endDate,
          price: price,
          trigger: trigger,
          interpolation: interpolationType
        } as CallScheduleItem);
      });
  
      return scheduleItems;
    }
  
  /**
 * Parse interpolation type.
 */
    private parseInterpolationType(interpolationTypeValue: string): InterpolationType {
  
      if (MarketDataClipboard.isEmpty(interpolationTypeValue)) {
        return InterpolationType.Staircase;
      }
  
      const interpolationTypeEnum = InterpolationType[interpolationTypeValue];
      if (!interpolationTypeEnum) {
        const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{ value}', interpolationTypeValue], ['{field}', 'Interpolation Type']]));
        throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
      }
  
      return interpolationTypeEnum;
    }
  
    /**
     * Sets column label depending on callValueType selection
     */
    private priceColumnLabel(): string {
      if (this.valueType === CallValueType.PerOfPar) {
  
        return CallValueTypeDescription[this.valueType];
      }
      else if (this.valueType === CallValueType.GrossYield || this.valueType === CallValueType.NetYield) {
        return `${CallValueTypeDescription[this.valueType]} (%)`;
      }
      return 'Value';
    }
  
    /**
     * Checks if callValueType is equal to Accreated
     */
    private isAccretedCall(): boolean {
      return this.valueType === CallValueType.AccretedValue;
    }
}
