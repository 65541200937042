

import { CallValueType } from './enums/CallValueType';
import { Frequency } from './enums/Frequency';
import { SoftCallData } from './SoftCallData';
import { CallMakeWholeData } from './CallMakeWholeData';
import { CallScheduleItem } from './CallScheduleItem';
import { NoticePeriod } from './enums/PutNoticePeriod';
import { DayCount } from '@lv-instrument-common/enums/CouponDayCount';

export class Call {
    valueType: CallValueType;
    yieldFrequency: Frequency;
    yieldDayCount: DayCount;
    keepAccruedRedemption: boolean;
    keepAccruedConversion: boolean;
    forfeitCoupon: boolean;
    notice: number;
    noticePeriod: NoticePeriod;
    callDate: Date;
    callNoticeGiven: boolean;
    softCall: SoftCallData;
    callMakeWhole: CallMakeWholeData;
    scheduleItems: CallScheduleItem[];
    recieveMakeWhole: boolean;
    callNoticeDaysMax: number;
    considerationDaysMax: number;
    considerationDaysBusinessCalendar: NoticePeriod;
    callFirstCallConsiderationDate: Date;
    callTriggerObservationStartDate: Date;
}

