<kendo-tabstrip class="lv-tabstrip lv-tabstrip-min-height lv-settings-content"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="General"
    cssClass="DM-1267">
    <ng-template kendoTabContent>
      <lv-general-settings
        data-cy="DM-1267"
        [systemDefaults]="settings.systemDefaults.generalSettings"
        [myDefaults]="settings.myDefaults.generalSettings"></lv-general-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Coupon"
    cssClass="DM-1268">
    <ng-template kendoTabContent>
      <lv-coupon-settings
        data-cy="DM-1268"
        [systemDefaults]="settings.systemDefaults.couponSettings"
        [myDefaults]="settings.myDefaults.couponSettings"></lv-coupon-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Accretion"
    cssClass="DM-1269">
    <ng-template kendoTabContent>
      <lv-accreation-settings
        data-cy="DM-1269"
        [systemDefaults]="settings.systemDefaults.accretionSettings"
        [myDefaults]="settings.myDefaults.accretionSettings"></lv-accreation-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Calls"
    cssClass="DM-1270">
    <ng-template kendoTabContent>
      <lv-call-settings
        data-cy="DM-1270"
        [systemDefaults]="settings.systemDefaults.callSettings"
        [myDefaults]="settings.myDefaults.callSettings"
      ></lv-call-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Puts"
    cssClass="DM-1271">
    <ng-template kendoTabContent>
      <lv-put-settings
        data-cy="DM-1271"
        [systemDefaults]="settings.systemDefaults.putSettings"
        [myDefaults]="settings.myDefaults.putSettings"
      ></lv-put-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Conversion"
    cssClass="DM-1272">
    <ng-template kendoTabContent>
      <lv-conversion-settings
        data-cy="DM-1272"
        [systemDefaults]="settings.systemDefaults.conversionSettings"
        [myDefaults]="settings.myDefaults.conversionSettings"
      ></lv-conversion-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Dividend Protection"
    cssClass="DM-1273">
    <ng-template kendoTabContent>
      <lv-dividend-protection-settings
        data-cy="DM-1273"
        [systemDefaults]="settings.systemDefaults.dividendProtectionSettings"
        [myDefaults]="settings.myDefaults.dividendProtectionSettings"
      ></lv-dividend-protection-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Make-Whole"
    cssClass="DM-1274">
    <ng-template kendoTabContent>
      <lv-make-whole-settings
        data-cy="DM-1274"
        [systemDefaults]="settings.systemDefaults.makeWholeSettings"
        [myDefaults]="settings.myDefaults.makeWholeSettings"
        [systemTakeOverOfferAndCompensation]="systemTakeOverOfferAndCompensation"
        [myTakeOverOfferAndCompensation]="myTakeOverOfferAndCompensation"
      ></lv-make-whole-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Takeover Protection"
    cssClass="DM-1275">
    <ng-template kendoTabContent>
      <lv-takeover-protection-settings
        data-cy="DM-1275"
        [systemDefaults]="settings.systemDefaults.takeoverProtectionSettings"
        [myDefaults]="settings.myDefaults.takeoverProtectionSettings"
        [systemMakeWholeOfferAndCompensation]="systemMakeWholeOfferAndCompensation"
        [myMakeWholeOfferAndCompensation]="myMakeWholeOfferAndCompensation"
      ></lv-takeover-protection-settings>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>

<lv-flex-box class="lv-settings-footer" direction="row">
  <button kendoButton
    [primary]="true"
    lvId="cancel-terms-settings"
    (click)="onCancel()">
      Cancel
  </button>
  <div class="lv-settings-footer-divider">&nbsp;</div>

  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-terms-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    lvId="save-terms-settings"
    [class.save-settings-btn-disabled]="!isChanged()"
    (click)="onSave()">
      Save
  </button>
</lv-flex-box>
