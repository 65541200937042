import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IBondCallAdjustmentModelSettings } from '@lv-analytics/models/bond/model-customization/model-customization-bond-settings/call-adjustment-model-settings';
import { HardCallModelTypeDescription } from '@lv-analytics/models/model-customization/model-customization-enum';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';

@Component({
  selector: 'lv-call-adjustment-bond',
  templateUrl: './lv-call-adjustment-bond.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCallAdjustmentBondSectionSettingsComponent {
  @Input() settings: IBondCallAdjustmentModelSettings;
  @Input() overrideSystemDefaults: boolean;

  get isBarierModel(): boolean {
    return this.settings.hardCallModelType === HardCallModelTypeDescription.BarrierModel.split(' ').join('');
  }

  numberOfDecimals = '4';
  numberFormat = '#.####';

  hardCallModelType: LvLookupEnum;

  constructor() {
    this.hardCallModelType = new LvLookupEnum(HardCallModelTypeDescription);
  }

  @HostBinding('class.lv-call-adjustment-bond-section')
    get isLvCallAdjustmentBondSectionSettingsComponent() {
    return true;
  }
}
