<lv-flex-box class="lv-credit-parameters">
  <lv-flex-box *ngIf="IsCdsFlat"
    data-cy="DM-241"
    data-cy-second="DM-258"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditCdsSpreadTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'cdsSpreadTooltip'">
          CDS Spread
        </span>
        <lv-xl-label [field]="'CREDIT_CDS'"></lv-xl-label>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="cdsSpread"
        [format]="numberFormatBps"
        lvId="lv-bond-cds-spread"
        [decimals]="numberOfDecimalsBps"
        [(ngModel)]="creditParameters.cdsSpread"
        (didBlur)="onCreditParametersChange()"
        (didKeyDownEnter)="onCreditParametersChange()"
        required>
      </lv-numeric-text-box>
      <span class="lv-sufix-label">bps</span>
    </div>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsCdsFlat"
    data-cy="DM-242"
    data-cy-second="DM-259"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditCdsUpfrontTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'cdsUpfrontTooltip'">
          CDS Upfront
        </span>
        <lv-xl-label [field]="'CREDIT_CDS_UPFRONT'"></lv-xl-label>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="cdsUpfront"
        lvId="lv-bond-cds-up-front"
        [format]="numberFormat"
        [decimals]="numberOfDecimalsPercentage"
        [(ngModel)]="creditParameters.cdsUpfront"
        (didBlur)="onCreditParametersChange()"
        (didKeyDownEnter)="onCreditParametersChange()"
        required>
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsCds"
    data-cy="DM-243"
    data-cy-second="DM-260"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditRecoveryCdsTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'recoveryCdsTooltip'">
          Recovery (CDS)
        </span>
        <lv-xl-label [field]="'CREDIT_REC_CDS'"></lv-xl-label>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="recoveryRateCDS"
        lvId="lv-recovery-rate-cds"
        [format]="numberFormat"
        [decimals]="numberOfDecimalsPercentage"
        [(ngModel)]="creditParameters.recoveryRateCDS"
        (didBlur)="onCreditParametersChange()"
        (didKeyDownEnter)="onCreditParametersChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box  *ngIf="IsCds"
    data-cy="DM-276"
    data-cy-second="DM-284"
    class="lv-input-field"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditBadDayConventionTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span [attr.credit-tooltip-id]="'badDayConventionTooltip'">
        Bad Day Convention
      </span>
      <lv-xl-label [field]="'CREDIT_BAD_DAY'"></lv-xl-label>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        [data]="badDayConversion.items"
        lvId="lv-bad-day-convention"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        name="badDayConversion"
        [(ngModel)]="creditParameters.badDayConversion"
        (ngModelChange)="onCreditParametersChange()"
        [popupSettings]="{ width: 136 }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsCds"
    data-cy="DM-277"
    data-cy-second="DM-285"
    class="lv-input-field"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditAccuredOnDefaultTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'accuredOnDefaultTooltip'">
          Accrued on Default
        </span>
        <lv-xl-label [field]="'CREDIT_ACC_DFLT'"></lv-xl-label>
    </div>

    <kendo-dropdownlist name="accruedOnDefault"
      [data]="creditAccuredOnDefaultSource.items"
      lvId="lv-bond-accrued-on-default"
      valueField="id"
      textField="text"
      [valuePrimitive]="true"
      [(ngModel)]="creditParameters.accruedOnDefault"
      (ngModelChange)="onCreditParametersChange()">
    </kendo-dropdownlist>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsDefaultRateFlat"
    data-cy="DM-246"
    data-cy-second="DM-261"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditDefaultRateTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'defaultRateTooltip'">
          Default Rate
        </span>
        <lv-xl-label [field]="'CREDIT_DFLT_FLAT'"></lv-xl-label>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="defaultRate"
        lvId="lv-bond-default-rate"
        [format]="numberFormat"
        [decimals]="numberOfDecimalsPercentage"
        [(ngModel)]="creditParameters.defaultRate"
        (didBlur)="onCreditParametersChange()"
        (didKeyDownEnter)="onCreditParametersChange()"
        required>
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsFlatSpread"
    data-cy="DM-240"
    data-cy-second="DM-257"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'flatSpreadTooltip'">
          Flat Spread
        </span>
        <lv-xl-label data-cy="CREDIT_FLAT" [field]="'CREDIT_FLAT'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="flatCreditSpread"
        lvId="lv-bond-flat-spread"
        [format]="numberFormatBps"
        [decimals]="numberOfDecimalsBps"
        [(ngModel)]="creditParameters.flatCreditSpread"
        (didBlur)="onCreditParametersChange()"
        (didKeyDownEnter)="onCreditParametersChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">bps</span>
    </div>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsNotSurProb"
    data-cy="DM-274"
    data-cy-second="DM-282"
    class="lv-input-field"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditFrequencyTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'frequencyTooltip'">
          Frequency
        </span>
        <lv-xl-label [field]="'CREDIT_FREQ'"></lv-xl-label>
    </div>

    <kendo-dropdownlist name="frequency"
      [data]="creditFrequencySource.items"
      lvId="lv-bond-frequency"
      valueField="id"
      textField="text"
      [(ngModel)]="creditParameters.frequency"
      [valuePrimitive]="true"
      (ngModelChange)="onCreditParametersChange()">
    </kendo-dropdownlist>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsNotSurProb"
    data-cy="DM-275"
    data-cy-second="DM-283"
    class="lv-input-field"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditbasisTooltipTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'basisTooltip'">
          Basis
        </span>
        <lv-xl-label [field]="'CREDIT_BASIS'"></lv-xl-label>
    </div>

    <kendo-dropdownlist name="basis"
      [data]="creditBasisSource.items"
      lvId="lv-bond-basis"
      valueField="id"
      textField="text"
      [(ngModel)]="creditParameters.basis"
      [valuePrimitive]="true"
      (ngModelChange)="onCreditParametersChange()">
    </kendo-dropdownlist>
  </lv-flex-box>

  <lv-flex-box
    data-cy-second="DM-245"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditJumpToUndDefaultTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'jumpToUndDefaultTooltip'">
          Jump to Und. Default
        </span>
        <lv-xl-label [field]="'CREDIT_UND_DEF_JUMP'"></lv-xl-label>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="jumpOnUnderlyingDefault"
        lvId="lv-bond-jump-on-underlying-default"
        [format]="numberFormat"
        [decimals]="numberOfDecimalsPercentage"
        [(ngModel)]="creditParameters.jumpOnUnderlyingDefault"
        (didBlur)="onCreditParametersChange()"
        (didKeyDownEnter)="onCreditParametersChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsRecoveryBondVisible"
    data-cy="DM-244"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="creditrecoveryBondTooltipTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'recoveryBondTooltip'">
          Recovery (Bond)
        </span>
        <lv-xl-label [field]="'CREDIT_REC_BOND'"></lv-xl-label>
    </div>

    <div class="lv-input-field">
      <lv-numeric-text-box
        name="recoveryRateBond"
        lvId="lv-bond-recovery-bond"
        [format]="numberFormat"
        [decimals]="numberOfDecimalsPercentage"
        [(ngModel)]="creditParameters.recoveryRateBond"
        (didBlur)="onCreditParametersChange()"
        (didKeyDownEnter)="onCreditParametersChange()"
        required>
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsTermStructure">
    <lv-flex-box direction="row" class="lv-credit-grid-title">
      <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditTermStructureTooltipTemplate"
        filter=".lv-label" 
        class="lv-label lv-label-field lv-label--title">
        <span [attr.credit-tooltip-id]="'creditTermStructureTooltip'">
          CREDIT TERM STRUCTURE
        </span> 
      </div>
    </lv-flex-box>
    <lv-credit-terms-structure
      data-cy="DM-262"
      data-cy-second="DM-266"
      lvId="lv-bond-credit-term-structure"
      [scheduleItems]="creditParameters.creditTermStructure"
      [creditSource]="creditSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnTermsStructureVisible">
    </lv-credit-terms-structure>
  </lv-flex-box>
 
  <lv-flex-box *ngIf="IsCDSTermStructure">
    <lv-flex-box direction="row" class="lv-credit-grid-title">
      <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditCDSTermStructureTooltipTemplate"
        filter=".lv-label" 
        class="lv-label lv-label-field lv-label--title">
        <span [attr.credit-tooltip-id]="'creditCDSTermStructureTooltip'">
          CDS TERM STRUCTURE
        </span> 
      </div>
    </lv-flex-box>
    <lv-credit-terms-structure
      data-cy="DM-263"
      data-cy-second="DM-267"
      lvId="lv-bond-cds-credit-term-structure"
      [scheduleItems]="creditParameters.creditCDSTermStructure"
      [creditSource]="creditSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnCDSTermsStructureVisible">
    </lv-credit-terms-structure>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsDefaultRateTermStructure">
    <lv-flex-box direction="row" class="lv-credit-grid-title">
      <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditDefaultRateTermStructureTooltipTemplate"
        filter=".lv-label" 
        class="lv-label lv-label-field lv-label--title">
        <span [attr.credit-tooltip-id]="'creditDefaultRateTermStructureTooltip'">
          DEFAULT RATE TERM STRUCTURE
        </span> 
      </div>
    </lv-flex-box>
    <lv-credit-terms-structure
      data-cy="DM-264"
      data-cy-second="DM-268"
      lvId="lv-bond-default-rate-credit-term-structure"
      [scheduleItems]="creditParameters.creditDefaultRateTermStructure"
      [creditSource]="creditSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnDefaultRateTermsStructureVisible">
    </lv-credit-terms-structure>
  </lv-flex-box>

  <lv-flex-box *ngIf="IsSurvivalProbabilityTermStructure">
    <lv-flex-box direction="row" class="lv-credit-grid-title">
      <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditSurvivalProbabilityTermStructureTooltipTemplate"
        filter=".lv-label" 
        class="lv-label lv-label-field lv-label--title">
        <span [attr.credit-tooltip-id]="'creditSurvivalProbabilityTermStructureTooltip'">
          SURVIVAL PROBABILITY TERM STRUCTURE
        </span> 
      </div>
    </lv-flex-box>
    <lv-credit-terms-structure
      data-cy="DM-265"
      data-cy-second="DM-269"
      lvId="lv-bond-survival-probability-credit-term-structure"
      [scheduleItems]="creditParameters.creditSurvivalProbabilityTermStructure"
      [creditSource]="creditSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnSurvivalProbabilityTermsStructureVisible">
    </lv-credit-terms-structure>
  </lv-flex-box>

</lv-flex-box>

<ng-template #creditCdsSpreadTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-241"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditCdsUpfrontTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-242"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditRecoveryCdsTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-243"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditBadDayConventionTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-276"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditAccuredOnDefaultTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-277"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditDefaultRateTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-246"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-240"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditFrequencyTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-274"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditJumpToUndDefaultTooltipTemplate>
  <lv-tooltip dm="dM-245"></lv-tooltip>
</ng-template>

<ng-template #creditrecoveryBondTooltipTooltipTemplate>
  <lv-tooltip dm="dM-244"></lv-tooltip>
</ng-template>

<ng-template #creditbasisTooltipTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-275"></lv-tooltip>
  </ng-container>
</ng-template>

<ng-template #creditTermStructureTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-262"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditCDSTermStructureTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-263"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditDefaultRateTermStructureTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-264"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditSurvivalProbabilityTermStructureTooltipTemplate>
  <ng-container>
    <lv-tooltip dm="dM-265"></lv-tooltip>
  </ng-container>
</ng-template>