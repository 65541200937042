import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { LvAccretionView } from './lv-accretion.view';
import { ConvertibleBondTermsSectionEvent } from '../../../models/convertible-bond-terms/custom-enum/convertible-bond-terms-section-event';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

@Component({
  selector: 'lv-accretion',
  templateUrl: './lv-accretion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAccretionComponent implements OnInit, OnDestroy {

  public view: LvAccretionView;  

  dpAccrualStartDatePickerId: string;
  dpAccrualEndDatePickerId: string;
  private _modelSubscription: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.view = new LvAccretionView(_lvConvertibleBondTermsService);
    this.dpAccrualStartDatePickerId = 'accrualStartDatePickerId';
    this.dpAccrualEndDatePickerId = 'accrualEndDatePickerId';
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated
      .pipe(
        filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.AccretionEvent &&
          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
      )
      .subscribe(event => {
        if (event.data) {
          this.view.init(event.data.fullTerms.accretion, event.data.fullTerms.issueAndRedemption);
          this._changeDetectorRef.detectChanges();
        }
      });

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(fullTerms.accretion,
        fullTerms.issueAndRedemption);
      this._changeDetectorRef.detectChanges();
    }
  }

  accretionStartDateCheckBoxChanged(value: boolean) {
    this.view.accretionStartDateCheckBoxChanged(value);
    this.onModelChange();
  }

  accretionEndDateCheckBoxChanged(value: boolean) {
    this.view.accretionEndDateCheckBoxChanged(value);
    this.onModelChange();
  }

  onModelChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.AccretionEvent,
      data: this.view.model,
      sourceOfUpdate: 'other'
    });
  }

  ngOnDestroy() {
    this._modelSubscription.unsubscribe();
  }

  getAccretionTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
