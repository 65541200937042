import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AdjustmentFormulaTypeDescription, DividendProtectionTresholdFormulaType, DividendProtectionTypeDescription,
IBaseTermsSettings, IDividendProtectionSectionSettings, MinAdjustmentTypeDescripton,
SpotLevelAtAdjustmentFormulaTypeDescription,
DividendProtectionTresholdTypeDescription as DividendProtectionThresholdTypeDescription,
CashDistributionDateTypeDescription} from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CurrencyType, FrequencyDescription } from '@lv-instrument-common/index';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-dividend-protection-section-settings',
  templateUrl: './lv-dividend-protection-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvDividendProtectionSectionSettingsComponent implements OnInit {

  @Input() settings: IBaseTermsSettings<IDividendProtectionSectionSettings>;
  @Input() overrideDefaults: boolean;

  protectionTypeLookup: LvLookupEnum;
  adjustmentFormulaTypeLookup: LvLookupEnum;
  spotLevelAtAdjustmentFormulaLookup: LvLookupEnum;
  minimumAdjustmentType: LvLookupEnum;
  thresholdTypeLookup: LvLookupEnum;
  thresholdPeriodFrequencyLookup: LvLookupEnum;
  cashThresholdCurrencyTypeLookup: LvLookupEnum;
  thresholdFormulaTypeLookup: LvLookupEnum;
  cashDistributionDateTypeLookup: LvLookupEnum;

  useDividendProtectionId: string;
  twoWayConversionPriceAdjustmentId: string;
  includeTriggerId: string;
  addCarryForwardOnConversionId: string;

  formatZero = 'n0';
  decimalsZero = '0';
  formatFour = '###,#.####';
  decimalsFour = '4';

  constructor() {
    this.protectionTypeLookup = new LvLookupEnum(DividendProtectionTypeDescription);
    this.adjustmentFormulaTypeLookup = new LvLookupEnum(AdjustmentFormulaTypeDescription);
    this.spotLevelAtAdjustmentFormulaLookup = new LvLookupEnum(SpotLevelAtAdjustmentFormulaTypeDescription);
    this.minimumAdjustmentType = new LvLookupEnum(MinAdjustmentTypeDescripton);
    this.thresholdTypeLookup = new LvLookupEnum(DividendProtectionThresholdTypeDescription);
    this.thresholdPeriodFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.cashThresholdCurrencyTypeLookup = new LvLookupEnum(CurrencyType);
    this.thresholdFormulaTypeLookup = new LvLookupEnum(DividendProtectionTresholdFormulaType);
    this.cashDistributionDateTypeLookup = new LvLookupEnum(CashDistributionDateTypeDescription);
    this.useDividendProtectionId = v4();
    this.twoWayConversionPriceAdjustmentId = v4();
    this.includeTriggerId = v4();
    this.addCarryForwardOnConversionId = v4();
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  ngOnInit() {
  }

  getDividendProtectionSettingsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
