
export enum AccretionType {
	UseRedemptionPrice = 'UseRedemptionPrice',
	Fixed = 'Fixed',
	Floating = 'Floating'
}

export enum AccretionTypeDesciption {
	UseRedemptionPrice = 'Redemption Price',
	Fixed = 'Fixed Accretion Rate'
}