<lv-flex-box class="lv-credit"
  [style.min-width.px]="sectionMinWidth">

  <lv-environment-settings
    [section]="creditSection"
    lvId="marketDataCreditEnvironmentSettings"
    instrumentType="Bond"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>
  
  <lv-flex-box class="lv-market-data-section-content">
    <lv-flex-box data-cy="DM-280" direction="row">
      <div class="lv-label lv-label-field"
        kendoTooltip
        [showAfter]="1500"
        filter="span"
        [tooltipTemplate]="creditSourceTooltipTemplate">
          <span>Credit Source</span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist name="creditSource"
          class="lv-credit-credit-source"
          lvId="lv-issuer-credit-source"
          [data]="creditSource.items"
          valueField="id"
          textField="text"
          [disabled]="isCreditSectionDisabled"
          [(ngModel)]="credit.creditSource"
          [valuePrimitive]="true"
          (valueChange)="onCreditSectionChanged()"
          [popupSettings]="{
            width: 225
          }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-bond-credit-parameters
      *ngIf="!isCreditSectionDisabled"
      class="lv-credit-content"
      [creditSource]="credit.creditSource"
      [creditParameters]="credit.issuerCreditParameters"
      (didCreditParametersChanged)="onCreditParametersChange($event)"
      #bondCreditParameters>
    </lv-bond-credit-parameters>
  </lv-flex-box>
</lv-flex-box>

<ng-template #creditSourceTooltipTemplate>
  <lv-tooltip dm="dM-254"></lv-tooltip>
</ng-template>