/**
 * Analitycs settings events.
 */
export enum AnalyticsSettingsEvents {
  PricingEnvironmentUpdated = 'PricingEnvironmentUpdated',
  // Sections
  ModelCustomizationUpdated = 'ModelCustomizationUpdated',
  // Market Data
  MarketDataUpdated = 'MarketDataUpdated',
  BorrowUpdated = 'BorrowUpdated',
  CreditUpdated = 'CreditUpdated',
  DividendsUpdated = 'DividendsUpdated',
  FundingUpdated = 'FundingUpdated',
  InterestRatesUpdated = 'InterestRatesUpdated',
  VolatilityUpdated = 'VolatilityUpdated',
  WithholdingTaxUpdated = 'WithholdingTaxUpdated',
  ValuationSettingsModelUpdated = 'ValuationSettingsModelUpdated',
  AveragingDataUpdated = 'AveragingDataUpdated',
  OtherSettingsUpdated = 'OtherSettingsUpdated',
  OtherMarketDataUpdated = 'OtherMarketDataUpdated',
  BondPricingEnvironmentUpdated = 'BondPricingEnvironmentUpdated',
  EquityEnvironmentUpdated = 'EquityEnvironmentUpdated',
  BondInstrumentUpdated = 'BondInstrumentUpdated',
  AswInstrumentUpdated = 'AswInstrumentUpdated',
  EquityInstrumentUpdated = 'EquityInstrumentUpdated',
  EquityVolatiityUpdated = 'EquityVolatiityUpdated',
  BondInstrumentSaved = 'BondInstrumentSaved',
  BondInstrumentLoaded = 'BondInstrumentLoaded',
  ConvertibleBondInstrumentSaved = 'ConvertibleBondInstrumentSaved',
  ConvertibleBondInstrumentLoaded = 'ConvertibleBondInstrumentLoaded',
}

/**
 * Analytics events.
 */
export enum AnalyticsEvents {
  ValuationStarted = 'ValuationStarted',
  ValuationCompleted = 'ValuationCompleted',
  SendValuationQueryStarted = 'SendValuationQueryStarted',
  ConvertibleSettingsUpdated = 'ConvertibleSettingsUpdated',
}

/**
 * Analytics commands.
 */
export enum AnalyticsCommands {
  ReloadInstrumentInfo = 'ReloadInstrumentInfo',
  ReloadPricingAndTools = 'ReloadPricingAndTools',
  ReloadAssumptionsAndToolsAndAnalysis = 'ReloadAssumptionsAndToolsAndAnalysis'
}
