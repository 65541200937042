import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { AccretionGrossNet, AccretionType, AccretionTypeDesciption, DayCountDescription, FrequencyDescription } from '@lv-instrument-common/index';
import { BondTermsDocument } from '../../../models/bond-terms/BondTermsDocument';
import { BondTermsSectionEvent } from '../../../models/bond-terms/enums/bond-terms-section-events';
import { LvBondTermsPresenter } from '../lv-bond-terms.presenter';
import { Subscription, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BondTermsService } from '../../../services/bond-terms.services';
import { BondAccretion, BondGeneral } from '../../../models';

@Component({
  selector: 'lv-bond-accretion',
  templateUrl: './lv-bond-accretion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvBondAccretionComponent implements OnInit, OnDestroy {
  @Input() model: BondAccretion;

  private _accretionStartDate: Date;
  private _accretionEndDate: Date;
  private _modelSubscription: Subscription;
  private _destroyRef = inject(DestroyRef)

  dpAccrualStartDatePickerId: string;
  dpAccrualEndDatePickerId: string;
  numberOfDecimalsPercentage = '4';
  numberFormat = '#,###.####';
  accretionTypeLookup: LvLookupEnum;
  dayCountLookup: LvLookupEnum;
  frequencyLookup: LvLookupEnum;
  grossNetLookup: LvLookupEnum;

  get isAccretionTypeFixed(): boolean {
    return this.model.type === AccretionType.Fixed;
  }

  get accretionStartDate(): Date {
    return this._accretionStartDate;
  }

  set accretionStartDate(value: Date) {
    this._accretionStartDate = value;

    if (this.model && this.useAccretionStartDate) {
      this.model.accretionStartDate = value;
    }
  }

  get accretionEndDate(): Date {
    return this._accretionEndDate;
  }

  set accretionEndDate(value: Date) {
    this._accretionEndDate = value;

    if (this.model && this.useAccretionEndDate) {
      this.model.accretionEndDate = value;
    }
  }

  useAccretionStartDate: boolean;
  useAccretionEndDate: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,
    private _lvBondTermsService: BondTermsService
  ) {
    this.accretionTypeLookup = new LvLookupEnum(AccretionTypeDesciption);
    this.dayCountLookup = new LvLookupEnum(DayCountDescription);
    this.frequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.grossNetLookup = new LvLookupEnum(AccretionGrossNet);
    this.dpAccrualStartDatePickerId = 'accrualStartDatePickerId';
    this.dpAccrualEndDatePickerId = 'accrualEndDatePickerId';
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef)).pipe(
      filter(event => event === BondTermsSectionEvent.TermsGeneralDatesEvent || event === BondTermsSectionEvent.ModelLoadedEvent)
    ).subscribe(event => {
      this.model = this._presenter.getModel().accretion;
      this.setDefaultFields(this._presenter.getModel().general);
      this._changeDetectorRef.detectChanges();
    });

    this.setDefaultFields(this._presenter.getModel().general);
    this._changeDetectorRef.detectChanges();
  }

  accretionStartDateCheckBoxChanged(value: boolean): void {
    this.useAccretionStartDate = value;

    if (this.model) {
      this.model.accretionStartDate = value ? this._accretionStartDate : null;
    }

    this.onModelChange();
  }

  accretionEndDateCheckBoxChanged(value: boolean): void {
    this.useAccretionEndDate = value;

    if (this.model) {
      this.model.accretionEndDate = value ? this._accretionEndDate : null;
    }

    this.onModelChange();
  }

  onModelChange() {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.accretion = this.model;
    }, BondTermsSectionEvent.TermsAccretionEvent,
      'other');
  }

  getAccretionTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  ngOnDestroy() {
    this._modelSubscription.unsubscribe();
  }

  /**
 * Set default date fields function.
 */
  private setDefaultFields(issueAndRedemption: BondGeneral) {
    if (issueAndRedemption) {
      const accretionSettings = this._lvBondTermsService.accretionSettings;

      if (!this.model.accretionStartDate) {
        this._accretionStartDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, accretionSettings?.accretionStartDate);
        this.useAccretionStartDate = false;
      } else {
        this._accretionStartDate = this.model.accretionStartDate;
        this.useAccretionStartDate = true;
      }

      if (!this.model.accretionEndDate) {
        this._accretionEndDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -accretionSettings?.accretionEndDate);

        this.useAccretionEndDate = false;
      } else {
        this._accretionEndDate = this.model.accretionEndDate;
        this.useAccretionEndDate = true;
      }
    }
    if (this.model.type === AccretionType.Floating) {
      this.model.type = AccretionType.UseRedemptionPrice;
    }
  }
}
