<lv-flex-box direction="row">
  <lv-flex-box class="lv-asset-swap-terms-settings-column">
      <lv-flex-box data-cy="DM-4910" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="aswType"
               filter="span">
              <span> ASW Type </span>
          </div>
          <div class="lv-input-field lv-bond-general-ccy">
              <kendo-dropdownlist
                  lvId="lv-asw-type"
                  class="lv-terms-general-small-input"
                  [data]="aswTypeLookup.items"
                  [(ngModel)]="settings.settings.aswType"
                  [disabled]="!overrideDefaults"
                  name="asw-type"
                  valueField="id"
                  textField="text"
                  valuePrimitive="true"
                  [popupSettings]="{ width: 120 }">
              </kendo-dropdownlist>
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-4911" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="payLegType"
               filter="span">
              <span> Pay Leg Type </span>
          </div>
          <div class="lv-input-field lv-bond-general-ccy">
              <kendo-dropdownlist
                  lvId="lv-payLegDayCount-leg-type"
                  class="lv-terms-general-small-input"
                  [data]="payLegTypeLookup.items"
                  [(ngModel)]="settings.settings.payLegType"
                  [disabled]="!overrideDefaults"
                  name="payLegDayCount-leg-type"
                  valueField="id"
                  textField="text"
                  valuePrimitive="true">
              </kendo-dropdownlist>
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-4912" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="strikeType"
               filter="span">
              <span> Strike Type </span>
          </div>
          <div class="lv-input-field lv-bond-general-ccy">
              <kendo-dropdownlist
                  lvId="lv-strike-type"
                  class="lv-terms-general-small-input"
                  [data]="strikeTypeLookup.items"
                  [(ngModel)]="settings.settings.strikeType"
                  [disabled]="!overrideDefaults"
                  name="strike-type"
                  valueField="id"
                  textField="text"
                  valuePrimitive="true"
                  [popupSettings]="{ width: 160 }">
              </kendo-dropdownlist>
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-4913" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="nominalValue"
               filter="span">
              <span> Nominal Value (ASW) </span>
          </div>
          <div class="lv-input-field">
              <lv-numeric-text-box
                  [format]="formatFour"
                  [decimals]="decimalsFour"
                  [disabled]="!overrideDefaults"
                  [(ngModel)]="settings.settings.nominalValue"
                  name="nominal-value-asw">
              </lv-numeric-text-box>
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-1530" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="daysToSettle"
               filter="span">
              <span> Days To Settle </span>
          </div>
          <div class="lv-input-field">
              <lv-numeric-text-box
                  [format]="formatFour"
                  [decimals]="decimalsFour"
                  [disabled]="!overrideDefaults"
                  [(ngModel)]="settings.settings.daysToSettle"
                  name="daysToSettle">
              </lv-numeric-text-box>
          </div>
      </lv-flex-box>

      <div class="lv-label-field lv-label lv-label--title lv-asset-swap-section-title">
          Conversion
      </div>

      <lv-flex-box data-cy="DM-4914" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="exerciseStartDate"
               filter="span">
              <span> Exercise Start Date </span>
          </div>
          <div class="lv-input-field align-sufix">
              <lv-numeric-text-box
                  placeholder="T + days"
                  class="lv-input-field"
                  [disabled]="!overrideDefaults"
                  [format]="formatZero"
                  [decimals]="decimalsZero"
                  [(ngModel)]="settings.settings.exerciseStartDate"
                  name="exerciseStartDate">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">From Effective Date</span>
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-4915" direction="row" class="lv-coupon-date">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="exerciseEndDate"
               filter="span">
              <span> Exercise End Date </span>
          </div>
          <div class="lv-input-field align-sufix">
              <lv-numeric-text-box
                  placeholder="T - days"
                  class="lv-input-field"
                  [disabled]="!overrideDefaults"
                  [format]="formatZero"
                  [decimals]="decimalsZero"
                  [(ngModel)]="settings.settings.exerciseEndDate"
                  name="exerciseEndDate">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">From Maturity Date</span>
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-4916" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="earlyExerciseFeeApplicable"
               filter="span">
              <span> Early Exercise Fee Applicable </span>
          </div>
          <div class="lv-field lv-field--checkbox">
              <input class="k-checkbox"
                     type="checkbox"
                     [disabled]="!overrideDefaults"
                     [(ngModel)]="settings.settings.earlyExerciseFeeApplicable"
                     name="earlyExercieFeeApplicable">
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-4917" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="earlyExerciseEndDate"
               filter="span">
              <span> Early Exercise End Date </span>
          </div>
          <div class="lv-input-field align-sufix">
              <lv-numeric-text-box
                  placeholder="T - days"
                  class="lv-input-field"
                  [disabled]="!overrideDefaults"
                  [format]="formatZero"
                  [decimals]="decimalsZero"
                  [(ngModel)]="settings.settings.earlyExerciseEndDate"
                  name="earlyExerciseEndDate">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">From Maturity Date</span>
          </div>
      </lv-flex-box>

      <lv-flex-box data-cy="DM-4918" direction="row">
          <div class="lv-label lv-label-field"
               showAfter="1500"
               kendoTooltip
               [tooltipTemplate]="feeFormulaType"
               filter="span">
              <span> Fee Formula Type </span>
          </div>
          <div class="lv-input-field lv-bond-general-ccy">
              <kendo-dropdownlist
                  lvId="lv-fee-formula-type"
                  class="lv-terms-general-small-input"
                  [data]="feeFormulaTypeLookup.items"
                  [(ngModel)]="settings.settings.feeFormulaType"
                  [disabled]="!overrideDefaults"
                  name="feeFormulaType"
                  valueField="id"
                  textField="text"
                  valuePrimitive="true">
              </kendo-dropdownlist>
          </div>
      </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<lv-flex-box class="lv-asset-swap-terms-settings-column lv-asset-swap-terms-settigs-second-column">
  <div class="lv-label-field lv-label lv-label--title lv-asset-swap-title">
      Reference IR Swap
  </div>

  <lv-flex-box data-cy="DM-4919" direction="row">
      <div class="lv-label lv-label-field"
           showAfter="1500"
           kendoTooltip
           [tooltipTemplate]="payLegDayCount"
           filter="span">
          <span> Pay Leg Day Count </span>
      </div>
      <div class="lv-input-field lv-asset-swap-reference-ir-swap-dropdown">
          <kendo-dropdownlist
              name="payLegDayCount"
              lvId="lv-pay-leg-day-count"
              [disabled]="!overrideDefaults"
              [(ngModel)]="settings.settings.payLegDayCount"
              [data]="payLegDayCountTypeLookup.items"
              valueField="id"
              textField="text"
              valuePrimitive="true">
          </kendo-dropdownlist>
      </div>
  </lv-flex-box>

  <lv-flex-box data-cy="DM-4920" direction="row">
      <div class="lv-label lv-label-field"
           showAfter="1500"
           kendoTooltip
           [tooltipTemplate]="payLegFrequency"
           filter="span">
          <span> Pay Leg Frequency </span>
      </div>
      <div class="lv-input-field lv-asset-swap-reference-ir-swap-dropdown">
          <kendo-dropdownlist
              name="payLegFrequency"
              lvId="lv-pay-leg-frequency"
              [disabled]="!overrideDefaults"
              [(ngModel)]="settings.settings.payLegFrequency"
              [data]="payLegFrequencyTypeLookup.items"
              valueField="id"
              textField="text"
              valuePrimitive="true">
          </kendo-dropdownlist>
      </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #aswType>
  <lv-tooltip dm="dM-4910"></lv-tooltip>
</ng-template>
<ng-template #payLegType>
  <lv-tooltip dm="dM-4911"></lv-tooltip>
</ng-template>
<ng-template #strikeType>
  <lv-tooltip dm="dM-4912"></lv-tooltip>
</ng-template>
<ng-template #nominalValue>
  <lv-tooltip dm="dM-4913"></lv-tooltip>
</ng-template>
<ng-template #daysToSettle>
  <lv-tooltip dm="dM-1530"></lv-tooltip>
</ng-template>
<ng-template #exerciseStartDate>
  <lv-tooltip dm="dM-4914"></lv-tooltip>
</ng-template>
<ng-template #exerciseEndDate>
  <lv-tooltip dm="dM-4915"></lv-tooltip>
</ng-template>
<ng-template #earlyExerciseFeeApplicable>
  <lv-tooltip dm="dM-4916"></lv-tooltip>
</ng-template>
<ng-template #earlyExerciseEndDate>
  <lv-tooltip dm="dM-4917"></lv-tooltip>
</ng-template>
<ng-template #feeFormulaType>
  <lv-tooltip dm="dM-4918"></lv-tooltip>
</ng-template>
<ng-template #payLegDayCount>
  <lv-tooltip dm="dM-4919"></lv-tooltip>
</ng-template>
<ng-template #payLegFrequency>
  <lv-tooltip dm="dM-4920"></lv-tooltip>
</ng-template>
