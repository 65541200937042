<div class="lv-instrument-monitor-panel">
  <lv-loader *ngIf="isLoading"></lv-loader>
  <div class="lv-instrument-monitor-panel-header"
    [class.loading]="isLoading">
    <lv-instrument-monitor-filter [filter]="widgetData?.state"
      (didFilterChange)="onFilterChanged($event)">
    </lv-instrument-monitor-filter>

    <div class="lv-instrument-monitor-panel-header-spacer"></div>

    <div class="lv-instrument-monitor-panel-header-settings">
      <div class="lv-instrument-monitor-panel-header-setting"
        [class.loading]="isLoading"
        (click)="onShowSettings()">
        <i class="k-icon k-i-cog"></i>
      </div>
    </div>
  </div>
  <div class="lv-instrument-monitor-panel-content"
    [class.loading]="isLoading">
    <lv-instrument-monitor [filter]="gridFilter"
      (didSelectInstrument)="onSelectInstrument($event)"
      (doOpenInstrument)="onOpenInstrument($event)"
      (doOpenDocuments)="onOpenDocuments($event)"
      (doCreateNewIssueFromExistingInstrument)="onCreateNewIssueFromExistingInstrument($event)"
      (doCopyInstrument)="onCopyInstrument($event)"
      (doDeleteInstrument)="onDeleteInstrument($event)" >
    </lv-instrument-monitor>
  </div>

  <lv-side-panel
    position="right">
    <div class="lv-side-panel-link-item"
      (click)="onEditWatchLists()">
      <span>Edit Watchlists</span>
    </div>
  </lv-side-panel>
</div>