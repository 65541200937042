import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { IBaseAssetSwapTermsSettings } from '../../../models/asset-swap-terms-settings/base-asset-swap-terms-settings';
import { LvLookupEnum } from '@lv-core-ui/util';
import { AswTypeDescription } from '../../../models/asset-swap-terms-settings/enums/asset-swap-type';
import { PayLegType } from '../../../models/asset-swap-terms-settings/enums/asset-swap-pay-leg-type';
import { StrikeTypeDescription } from '../../../models/asset-swap-terms-settings/enums/asset-swap-strike-type';
import { FeeFormulaType } from '../../../models/asset-swap-terms-settings/enums/asset-swap-fee-formula-type';
import { DayCountDescription } from '@lv-instrument-common/enums/CouponDayCount';
import { PayLegFrequencyDescription } from '../../../models/asset-swap-terms-settings/enums/asset-swap-pay-leg-frequency';
import { IAssetSwapTermsSectionSettings } from '../../../models/asset-swap-terms-settings/asset-swap-terms-section-settings';


@Component({
  selector: 'lv-asset-swap-terms-section-settings',
  templateUrl: './lv-asset-swap-terms-section-settings.component.html',
})
export class LvAssetSwapSectionTermsSettingsComponent implements OnInit {
  @Input() settings: IBaseAssetSwapTermsSettings<IAssetSwapTermsSectionSettings>;
  @Input() overrideDefaults: boolean;

  formatZero = 'n0';
  decimalsZero = '0';
  decimalsTwo = '2';
  formatTwo = '#,###.##';
  decimalsFour = '4';
  formatFour = '#,###.####';

  aswTypeLookup: LvLookupEnum;
  payLegTypeLookup: LvLookupEnum;
  strikeTypeLookup: LvLookupEnum;
  feeFormulaTypeLookup: LvLookupEnum;
  payLegDayCountTypeLookup: LvLookupEnum;
  payLegFrequencyTypeLookup: LvLookupEnum;
  
  ngOnInit() {
    this.overrideDefaults = true;
    this.aswTypeLookup = new LvLookupEnum(AswTypeDescription);
    this.payLegTypeLookup = new LvLookupEnum(PayLegType);
    this.strikeTypeLookup = new LvLookupEnum(StrikeTypeDescription);
    this.feeFormulaTypeLookup = new LvLookupEnum(FeeFormulaType);
    this.payLegDayCountTypeLookup = new LvLookupEnum(DayCountDescription);
    this.payLegFrequencyTypeLookup = new LvLookupEnum(PayLegFrequencyDescription);

    this.settings = {
          overrideSystemDefaults: true,
          settings: {},
        } as IBaseAssetSwapTermsSettings<IAssetSwapTermsSectionSettings>
  }
  
  @HostBinding('class.lv-asset-swap-terms-section-settings')
    get isLvAssetSwapSectionTermsSettingsComponent() {
      return true;
    }
}