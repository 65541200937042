import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';
import { IInstrumentComponentState, IInstrumentWidgetState } from '.';
import { defaultWidgetsConvertibleBond, defaultWidgetsBond, defaultWidgetsEquity, defaultWidgetsAssetSwap } from './instrument.widgets';

@Injectable()
export class DefaultComponentStateService {

  constructor(
    private _defaultWidgetStateService: LvSharedDefaultWidgetStateService
  ) { }

  getDefaultInstrumentComponentState = (instrumentType?: string, update?: (s: IInstrumentComponentState) => void) => {
    const dfState = this._defaultWidgetStateService.getDefaultWidgetState(DefaultWidgetType.Instrument, instrumentType);

    if (dfState && dfState.state) {
      dfState.state.forEach(element => {
        element.state = null;
      });
    }

    const initial = {
      id: null,
      instrumentId: null,
      instrumentName: null,
      instrumentLwsIdentifier: null,
      instrumentIsin: null,
      sessionId: null,
      widgets: dfState ? dfState.state : _.cloneDeep(this.getInstrumentTypeDefaultWidgets(instrumentType)),
      sensitivityAnalysisState: {
        scenarioTemplateId: null,
        isSystem: false
      },
      isPrivateInstrument: false,
      useDefaultView: true,
      accessScope: null,
      instrumentType: dfState?.instrumentType.toString() || instrumentType,
    } as IInstrumentComponentState;

    if (update) {
      update(initial);
    }

    return initial;
  }

  private getInstrumentTypeDefaultWidgets(instrumentType): IInstrumentWidgetState[]{
    switch(instrumentType) {
      case 'ConvertibleBond': {
        return defaultWidgetsConvertibleBond;
      }
      case 'Bond': {
        return defaultWidgetsBond;
      }
      case 'Equity': {
        return defaultWidgetsEquity;
      }
      case 'ASW': {
        return defaultWidgetsAssetSwap;
      }
      default: {
        return defaultWidgetsConvertibleBond;
      }
    }
  }
}
