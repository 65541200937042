import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, HostBinding, inject,
  OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LvSidePanelComponent } from '@lv-core-ui/components/lv-side-panel/lv-side-panel.component';
import { LvEquityTermsComponent } from 'src/app/modules/instrument/equity/components/lv-equity-terms/lv-equity-terms/lv-equity-terms.component';
import { DefaultWidgetType } from '@lv-shared/models/default-widget-state';
import { WidgetStateManagerService } from '@lv-application-settings/services/widget-state-manager/widget-state-manager.service';
import { LvExcelService } from '@lv-excel/services/excel-service';
import { LvBaseWidgetComponent } from '@lv-analytics/lv-base-widget.component';
import { IBaseEquityWidgetState } from '@lv-analytics/models/equity/equity-widget-state/base-equity-widget-state';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IEquity } from '@lv-reference-data/models';

@Component({
  selector: 'equity',
  templateUrl: './equity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EquityComponent extends LvBaseWidgetComponent<IBaseEquityWidgetState> implements OnInit{
  
  @ViewChild('equityTerms') equityTermsComponent: LvEquityTermsComponent;
  @ViewChild('leversysDataSyncSettings') leversysDataSyncSettings: LvSidePanelComponent;

  @Output() didEquityNameSet: EventEmitter<string>;
  @Output() didEquitySaved: EventEmitter<void>;

  get isDisabled(): boolean {
    return  !this.isEquityTermsSaved && !this._analyticsPresenter.equitySession?.terms?.name;
  }

  isEquityTermsSaved: boolean;
  isLeversysDataSyncSettingsOpened: boolean;
  isCcyCodeChanged: boolean;
  equity: IEquity;
  leversysLocalId: string;
  syncAllEquityDataSettingsValue: boolean;

  private _destroyRef = inject(DestroyRef)

  constructor(
    public _changeDetectorRef: ChangeDetectorRef,
    public _widgetStateManagerService: WidgetStateManagerService,
    private _analyticsPresenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    super(
      _changeDetectorRef,
      _widgetStateManagerService,
      getInitialState(!!_excelSvc?.isInitialized()),
      DefaultWidgetType.Underlying
    );

    this.isEquityTermsSaved = false;
    this.isLeversysDataSyncSettingsOpened = false;
    this.isCcyCodeChanged = false;
    this.syncAllEquityDataSettingsValue = false;
    this.didEquityNameSet = new EventEmitter<string>();
    this.didEquitySaved = new EventEmitter();
  }

  onInit() {  
    this._analyticsPresenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      if (this._analyticsPresenter?.equitySession?.terms) {
         this.equity = this._analyticsPresenter?.equitySession?.terms;
         this.leversysLocalId = this._analyticsPresenter?.equitySession?.leversysLocalId;        
      }
      else {
        this.leversysLocalId = null;
      }
    });

    this.leversysLocalId = this._analyticsPresenter?.equitySession?.leversysLocalId;        
  }

  onDestroy(): void {    
  } 

  @HostBinding('class.lv-equity')
  get isEquityComponent() {
    return true;
  }

  onShowLeversysDataSyncSettings() {
    this.hideLeversysDataSyncSidePanel();
    this.leversysDataSyncSettings.togglePanel();
  }

  /**
   * Method checks if Terms Equity is saved
   * @param isEquityTermsSaved boolean flag
   */
  equityTermsSave(isEquityTermsSaved: boolean) {
    this.isEquityTermsSaved = true;
    this.leversysLocalId = this._analyticsPresenter.equitySession.leversysLocalId;
    this.didEquitySaved.next();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Track CCY code change.
   * @param isCcyCodeChange boolean flag.
   */
  ccyCodeChange(isCcyCodeChange: boolean) {
    this.isCcyCodeChanged = isCcyCodeChange;
  }

  /**
   * Set equity name upon save.
   * @param equityName 
   */
  setEquityName(equityName: string): void {
    this.didEquityNameSet.next(equityName);
  }

  /**
   * Hide Leversys DataSync Side Panel.
   */
  hideLeversysDataSyncSidePanel() {
    if(this.leversysDataSyncSettings.expanded) {
      this.leversysDataSyncSettings.togglePanel();
    }
  }

  /**
   * Edit leversys data sync settings open popup.
   */
  editLeversysDataSyncSettings() {
    this.equityTermsComponent.openSidePanelModal();
  }

  /**
   * Track SyncAll Equity data Change after  change is saved.
   * @param syncData boolean flag for Sync All Equity Data
   */
  syncAllEquityDataChanged (syncData: boolean) {
    this.syncAllEquityDataSettingsValue = syncData;
    this._changeDetectorRef.detectChanges();
  }
}

/**
 * Get initial state.
 * @param isOpenedFromExcel A flag indicating if instrument is opened from Excel.
 * @returns IBaseEquityWidgetState object.
 */
function getInitialState(isOpenedFromExcel: boolean): IBaseEquityWidgetState {
  return {
    isOpenedFromExcel: isOpenedFromExcel,
    useDefaultView: true,
    instrumentType: 'Equity'
  };
}