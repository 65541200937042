import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { AdjustmentReasonDescription, ConversionRatioHistoryItem, CRHistoryStatusType } from '@lv-convertible-bond/models';
import {
  LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridEnumColumn,
  LvAdvancedGridNumericColumn
} from '@lv-core-ui/components';
import { constants } from '@lv-core-ui/util';

@Component({
  selector: 'lv-conversion-ratio-history',
  templateUrl: './lv-conversion-ratio-history.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvConversionRatioHistoryComponent implements OnChanges {
  @Input() model: ConversionRatioHistoryItem[];
  @Input() isPeps: boolean; 
  columns: LvAdvancedGridColumn[];
  editDisabled: boolean;

  get shouldRebateBeVisible(): boolean {
    return this.model.some(el => el.conversionRebate !== 0);
  }

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    this.columns = [];
    this.model = [];
    this.editDisabled = true;
  }

  ngOnChanges() {
    this.initColumns();
    this._changeDetectorRef.detectChanges();
  }

  private initColumns(): void {
    const status = new LvAdvancedGridEnumColumn();
    status.isOutputColumn = true;
    status.title = 'Status';
    status.field = 'status';
    status.enumDescription = CRHistoryStatusType;
    status.valuePrimitive = true;
    status.width = 71;
    status.editable = false;
    status.dmKey = 'DM-2295';

    const effectiveDate = new LvAdvancedGridDateColumn();
    effectiveDate.isOutputColumn = true;
    effectiveDate.title = 'Effect. Date';
    effectiveDate.field = 'effectiveDate';
    effectiveDate.width = 74;
    effectiveDate.editable = false;
    effectiveDate.dmKey= 'DM-2296';

    const adjustmentReason = new LvAdvancedGridEnumColumn();
    adjustmentReason.enumDescription = AdjustmentReasonDescription;
    adjustmentReason.isOutputColumn = true;
    adjustmentReason.title = 'Adjustment Reason';
    adjustmentReason.field = 'adjustmentReason';
    adjustmentReason.valuePrimitive = true;
    adjustmentReason.width = 138;
    adjustmentReason.editable = false;
    adjustmentReason.dmKey = 'DM-2297';

    const maxConversionRatio = new LvAdvancedGridNumericColumn();
    maxConversionRatio.isOutputColumn = true;
    maxConversionRatio.title = 'Max Conv. Ratio';
    maxConversionRatio.field = 'maxConversonRatio';
    maxConversionRatio.outputFormat = constants.numberFormat.sixDigits;
    maxConversionRatio.width = 104;
    maxConversionRatio.visible = this.isPeps;
    maxConversionRatio.alignment = 'left';
    maxConversionRatio.editable = false;
    maxConversionRatio.dmKey = 'DM-2302';

    const minConversionPrice = new LvAdvancedGridNumericColumn();
    minConversionPrice.isOutputColumn = true;
    minConversionPrice.title = 'Min Conv. Price';
    minConversionPrice.field = 'minConversionPrice';
    minConversionPrice.outputFormat = constants.numberFormat.sixDigits;
    minConversionPrice.width = 103;
    minConversionPrice.visible = this.isPeps;
    minConversionPrice.alignment = 'left';
    minConversionPrice.editable = false;
    minConversionPrice.dmKey = "DM-2303";

    const minConversionRatio = new LvAdvancedGridNumericColumn();
    minConversionRatio.isOutputColumn = true;
    minConversionRatio.title = 'Min Conv. Ratio';
    minConversionRatio.field = 'minConversionRatio';
    minConversionRatio.outputFormat = constants.numberFormat.sixDigits;
    minConversionRatio.width = 102;
    minConversionRatio.visible = this.isPeps;
    minConversionRatio.alignment = 'left';
    minConversionRatio.editable = false;
    minConversionRatio.dmKey = 'DM-2304';

    const maxConversionPrice = new LvAdvancedGridNumericColumn();
    maxConversionPrice.isOutputColumn = true;
    maxConversionPrice.title = 'Max Conv. Price';
    maxConversionPrice.field = 'maxConversionPrice';
    maxConversionPrice.outputFormat = constants.numberFormat.sixDigits;
    maxConversionPrice.width = 104;
    maxConversionPrice.visible = this.isPeps;
    maxConversionPrice.alignment = 'left';
    maxConversionPrice.editable = false;
    maxConversionPrice.dmKey = 'DM-2305';


    const conversionRatio = new LvAdvancedGridNumericColumn();
    conversionRatio.isOutputColumn = true;
    conversionRatio.title = 'Conversion Ratio';
    conversionRatio.field = 'conversionRatio';
    conversionRatio.outputFormat = constants.numberFormat.sixDigits;
    conversionRatio.width = 104;
    conversionRatio.visible = !this.isPeps;
    conversionRatio.alignment = 'left';
    conversionRatio.editable = false;
    conversionRatio.dmKey = 'DM-2298';

    const conversionPrice = new LvAdvancedGridNumericColumn();
    conversionPrice.isOutputColumn = true;
    conversionPrice.title = 'Conversion Price';
    conversionPrice.field = 'conversionPrice';
    conversionPrice.outputFormat = constants.numberFormat.sixDigits;
    conversionPrice.width = 102;
    conversionPrice.visible = !this.isPeps;
    conversionPrice.alignment = 'left';
    conversionPrice.editable = false;
    conversionPrice.dmKey = 'DM-2299';

    const conversionRebate = new LvAdvancedGridNumericColumn();
    conversionRebate.isOutputColumn = true;
    conversionRebate.title = 'Rebate';
    conversionRebate.field = 'conversionRebate';
    conversionRebate.outputFormat = constants.numberFormat.twoDigits;
    conversionRebate.visible = this.shouldRebateBeVisible;
    conversionRebate.width = 71;
    conversionRebate.alignment = 'left';
    conversionRebate.editable = false;
    conversionRebate.dmKey = 'DM-2306';

    const leversysEstimate = new LvAdvancedGridNumericColumn();
    leversysEstimate.isOutputColumn = true;
    leversysEstimate.title = 'Leversys Estimate';
    leversysEstimate.field = 'leversysEstimate';
    leversysEstimate.outputFormat = constants.numberFormat.sixDigits;
    leversysEstimate.width = 110;
    leversysEstimate.alignment = 'left';
    leversysEstimate.editable = false;
    leversysEstimate.dmKey = 'DM-2300';

    const relativeDiffPercent = new LvAdvancedGridNumericColumn();
    relativeDiffPercent.isOutputColumn = true;
    relativeDiffPercent.title = 'Error (%)';
    relativeDiffPercent.field = 'relativeDiffPercent';
    relativeDiffPercent.outputFormat = constants.numberFormat.twoDigits;
    relativeDiffPercent.width = 61;
    relativeDiffPercent.alignment = 'left';
    relativeDiffPercent.editable = false;
    relativeDiffPercent.dmKey = 'DM-2301';

    this.columns = [status,
      effectiveDate,
      adjustmentReason,
      conversionRatio,
      conversionPrice,
      maxConversionRatio,
      minConversionPrice,
      minConversionRatio,
      maxConversionPrice,
      conversionRebate,
      leversysEstimate,
      relativeDiffPercent];
  }

}
