import { v4 } from 'uuid';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { PriceTalk } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { DayCountDescription, FrequencyDescription, IssueAndRedemption, Coupon, CustomCouponData, ExCouponPeriodType,
  CouponCoPay, CouponType, FixedCouponData, Index, CouponTypeDescription, AccrualMethodDescription, SetupStatus } from '@lv-instrument-common/index';
export class LvCouponView {

  get isZeroCouponSelected(): boolean {
    return this.model.type === CouponType.ZeroCoupon;
  }

  get isFixedTypeVisible(): boolean {
    return this.model.type === CouponType.Fixed
      || this.model.type === CouponType.FixToFloating
      || this.model.type === CouponType.FloatingToFix;
  }

  get isCustomTypeVisible(): boolean {
    return this.model.type === CouponType.Custom;
  }

  get isPikTypeVisible(): boolean {
    return this.model.type === CouponType.PIK;
  }

  get isFloatingTypeVisible(): boolean {
    return this.model.type === CouponType.Floating
      || this.model.type === CouponType.FixToFloating
      || this.model.type === CouponType.FloatingToFix;
  }

  get isCoPayVisible(): boolean {
    return this.model.hasCoPay === true;
  }

  //#region DateTime Properties
  get couponChangedDate(): Date {
    return this._couponChangedDate;
  }

  set couponChangedDate(value: Date) {
    this._couponChangedDate = value;

    if (this.model && this.couponChangedDateEnabled) {
      this.model.typeChangeDate = value;
    }
  }

  get pikEndDate(): Date {
    return this._pikEndDate;
  }

  set pikEndDate(value: Date) {
    this._pikEndDate = value;

    if (this.model.pik) {
      this.model.pik.pikEndDate = value
    }
  }

  get accrualEndDate(): Date {
    return this._accrualEndDate;
  }

  set accrualEndDate(value: Date) {
    this._accrualEndDate = value;

    if (this.model && this.accrualEndDateEnabled) {
      this.model.accrualEndDate = value;
    }
  }

  get accrualStartDate(): Date {
    return this._accrualStartDate;
  }

  set accrualStartDate(value: Date) {
    this._accrualStartDate = value;

    if (this.model && this.accrualStartDateEnabled) {
      this.model.accrualStartDate = value;
    }
  }

  get accrualStartDateCoPay(): Date {
    return this._accrualStartDateCoPay;
  }
  set accrualStartDateCoPay(value: Date) {
    this._accrualStartDateCoPay = value;
  }

  get firstCouponPaymentDate(): Date {
    return this._firstCouponPaymentDate;
  }

  set firstCouponPaymentDate(value: Date) {
    this._firstCouponPaymentDate = value;

    if (this.model && this.firstCouponPaymentDateEnabled) {
      this.model.firstCouponPaymentDate = value;
    }
  }

  get penultimateCouponPaymentDate(): Date {
    return this._penultimateCouponPaymentDate;
  }

  set penultimateCouponPaymentDate(value: Date) {
    this._penultimateCouponPaymentDate = value;

    if (this.model && this.penultimateCouponPaymentDateEnabled) {
      this.model.penultimateCouponPaymentDate = value;
    }
  }

  get guaranteedStartDate(): Date {
    return this._guaranteedStartDate;
  }
  set guaranteedStartDate(value: Date) {
    this._guaranteedStartDate = value;

    if (this.model && this.guaranteedStartDateEnabled) {
      this.model.guaranteedStartDate = value;
    }
  }

  get guaranteedEndDate(): Date {
    return this._guaranteedEndDate;
  }
  set guaranteedEndDate(value: Date) {
    this._guaranteedEndDate = value;

    if (this.model && this.guaranteedEndDateEnabled) {
      this.model.guaranteedStartEndDate = value;
    }
  }

  couponChangedDateEnabled: boolean;
  accrualEndDateEnabled: boolean;
  accrualStartDateEnabled: boolean;
  firstCouponPaymentDateEnabled: boolean;
  penultimateCouponPaymentDateEnabled: boolean;

  private _couponChangedDate: Date;
  private _accrualEndDate: Date;
  private _accrualStartDate: Date;
  private _accrualStartDateCoPay: Date;
  private _firstCouponPaymentDate: Date;
  private _penultimateCouponPaymentDate: Date;
  private _guaranteedStartDate: Date;
  private _guaranteedEndDate: Date;
  private _pikEndDate: Date;

  // #end region
  
  model: Coupon;
  priceTalk: PriceTalk;

  instrumentStatus: SetupStatus;
  couponTypeLookup: LvLookupEnum;
  couponDayCountLookup: LvLookupEnum;
  accrualMethodLookup: LvLookupEnum;
  couponFrequencyLookup: LvLookupEnum;
  exCouponPerLookup: LvLookupEnum;

  hasCoPayCheckboxId: string;
  couponDatesEomCheckboxId: string;
  guaranteedCouponsCheckboxId: string;

  guaranteedStartDateEnabled: boolean;
  guaranteedEndDateEnabled: boolean;
  typeChangeDateEnabled: boolean;

  numberOfDecimals = '0';
  numberFormat = 'n0';

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
  ) {
    this.couponTypeLookup = new LvLookupEnum(CouponTypeDescription);
    this.couponDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.accrualMethodLookup = new LvLookupEnum(AccrualMethodDescription);
    this.couponFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.exCouponPerLookup = new LvLookupEnum(ExCouponPeriodType);

    this.hasCoPayCheckboxId = v4();
    this.couponDatesEomCheckboxId = v4();
    this.guaranteedCouponsCheckboxId = v4();

    this.firstCouponPaymentDateEnabled = false;
    this.penultimateCouponPaymentDateEnabled = false;
    this.accrualStartDateEnabled = false;
    this.accrualEndDateEnabled = false;
    this.guaranteedStartDateEnabled = false;
    this.guaranteedEndDateEnabled = false;
    this.typeChangeDateEnabled = false;

    this.init({} as Coupon, {} as IssueAndRedemption, {} as PriceTalk);
  }

  init(coupon?: Coupon, issueAndRedemption?: IssueAndRedemption, priceTalk?: PriceTalk) {
    this.model = coupon ?? this._lvConvertibleBondTermsService.convertibleBondDocument.coupon;
    if (priceTalk) {
      this.priceTalk = priceTalk;
    }
    else if (!!this._lvConvertibleBondTermsService.convertibleBondNewIssueDocument?.priceTalk) {
      this.priceTalk = this._lvConvertibleBondTermsService.convertibleBondNewIssueDocument.priceTalk;
    }
    else {
      this.priceTalk = {} as PriceTalk;
    }

    if (!coupon.accrualStartDate) {
      this.accrualStartDateEnabled = false;
    }

    if (!coupon.accrualEndDate) {
      this.accrualEndDateEnabled = false;
    }

    if (!coupon.firstCouponPaymentDate) {
      this.firstCouponPaymentDateEnabled = false;
    }

    if (!coupon.penultimateCouponPaymentDate) {
      this.penultimateCouponPaymentDateEnabled = false;
    }

    if (!this.model.typeChangeDate) {
      this.couponChangedDateEnabled = false;
    }

    this.setDefaultDateValues(issueAndRedemption);
  }

  couponTypeChange() {
    this.typeChangeDateEnabled = false;
    if (this.model.type === CouponType.Custom) {
      if (!this.model.custom) {
        this.model.custom = new CustomCouponData();
        this.model.custom.schedule = [];
      }
      this.priceTalk.couponBest = 0;
      this.priceTalk.couponWorst = 0;
    } else if (this.model.type === CouponType.FixToFloating
      || this.model.type === CouponType.FloatingToFix) {
        this.typeChangeDateEnabled = true;
        if (!this.model.fixed) {
          this.model.fixed = this._lvConvertibleBondTermsService.convertibleBondDocument.coupon.fixed;
        }
        if (!this.model.floating) {
          this.model.floating = this._lvConvertibleBondTermsService.convertibleBondDocument.coupon.floating;
        }
    } else if (this.model.type === CouponType.Floating) {
      if (!this.model.floating) {
        this.model.floating = this._lvConvertibleBondTermsService.convertibleBondDocument.coupon.floating;
      }
      this.priceTalk.couponBest = 0;
      this.priceTalk.couponWorst = 0;
    } else if (this.model.type === CouponType.Fixed) {
      if (!this.model.fixed) {
        this.model.fixed = new FixedCouponData();
        this.model.fixed.stepUpCouponSchedule = [];
      }
    } else if (this.model.type === CouponType.PIK) {
      if (!this.model.pik) {
        const couponPikData = this._lvConvertibleBondTermsService.convertibleBondDocument.coupon.pik;
        if (!(couponPikData.pikEndDate instanceof Date)) {
          couponPikData.pikEndDate = new Date(Date.now());
        }

        this.model.pik = this._lvConvertibleBondTermsService.convertibleBondDocument.coupon.pik;
      }
    } else if (this.model.type === CouponType.ZeroCoupon) {
        this.priceTalk.couponBest = 0;
        this.priceTalk.couponWorst = 0;
    }
  }

  setDefaultDateValues(issueAndRedemption: IssueAndRedemption) {
    if (this.model && issueAndRedemption) {

      const couponSettings = this._lvConvertibleBondTermsService.couponSettings;

      if (!this.model.firstCouponPaymentDate) {
        this.firstCouponPaymentDate = issueAndRedemption.firstSettlementDate;
        this.firstCouponPaymentDateEnabled = false;
      } else {
        this.firstCouponPaymentDate = this.model.firstCouponPaymentDate;
        this.firstCouponPaymentDateEnabled = true;
      }

      if (!this.model.penultimateCouponPaymentDate) {
        this.penultimateCouponPaymentDate = issueAndRedemption.maturityDate;
        this.penultimateCouponPaymentDateEnabled = false;
      } else {
        this.penultimateCouponPaymentDate = this.model.penultimateCouponPaymentDate;
        this.penultimateCouponPaymentDateEnabled = true;
      }

      if (!this.model.accrualStartDate) {
        this.accrualStartDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, couponSettings?.accrualStartDate);
        this.accrualStartDateEnabled = false;
      } else {
        this.accrualStartDate = this.model.accrualStartDate;
        this.accrualStartDateEnabled = true;
      }

      if (!this.model.accrualEndDate) {
        this.accrualEndDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -couponSettings?.accrualEndDate);
        this.accrualEndDateEnabled = !!couponSettings?.accrualEndDate;
      } else {
        this.accrualEndDate = this.model.accrualEndDate;
        this.accrualEndDateEnabled = true;
      }

      if (!this.model.typeChangeDate) {
        this.couponChangedDate = new Date();
        this.couponChangedDateEnabled = false;
      } else {
        this.couponChangedDate = this.model.typeChangeDate;
        this.couponChangedDateEnabled = true;
      }

      if (!this.model.guaranteedStartDate) {
        this.guaranteedStartDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, couponSettings?.guaranteedStartDate);
        this.guaranteedStartDateEnabled = !!couponSettings?.guaranteedStartDate;
      } else {
        this.guaranteedStartDate = this.model.guaranteedStartDate;
        this.guaranteedStartDateEnabled = true;
      }

      if (!this.model.guaranteedStartEndDate) {
        this.guaranteedEndDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -couponSettings?.guaranteedStartEndDate);
        this.guaranteedEndDateEnabled = !!couponSettings?.guaranteedStartEndDate;
      } else {
        this.guaranteedEndDate = this.model.guaranteedStartEndDate;
        this.guaranteedEndDateEnabled = true;
      }

      this.typeChangeDateEnabled = this.model.type === CouponType.FloatingToFix || this.model.type === CouponType.FixToFloating;
      this._accrualStartDateCoPay = issueAndRedemption.firstSettlementDate;
      this.instrumentStatus = issueAndRedemption.setupStatus;

      if ((this.model.type === CouponType.Floating || this.model.type === CouponType.FixToFloating ||
          this.model.type === CouponType.FloatingToFix) && this.model.floating && !this.model.floating.index) {
            this.model.floating.index = new Index();
      }

      if (!this.model.pik?.pikEndDate) {
        if (!!issueAndRedemption.maturityDate && !issueAndRedemption.isPerpetual) {
          this.pikEndDate = issueAndRedemption.maturityDate;
        }
        else if(!!issueAndRedemption.isPerpetual) {
          this.pikEndDate = issueAndRedemption.firstSettlementDate;
        }
      }
    }
  }

  penultimatePaymentDateChanged() {
    const today = new Date();
    const numberOfDays = new Date(today.getFullYear(), today.getMonth(), 0).getDate();

    if (this.model.penultimateCouponPaymentDate && this.model.penultimateCouponPaymentDate.getDate() === numberOfDays) {
      this.model.couponDatesEOM = true;
    } else {
      this.model.couponDatesEOM = false;
    }
  }

  hasCoPayChange() {
    if (!this.model.coPay && this.model.hasCoPay) {
      this.model.coPay = {
        coPayDownsideTriggerSchedule: [],
      } as CouponCoPay;
    }
  }

  accrualStartDateCheckboxChanged(value: boolean): void {
    this.accrualStartDateEnabled = value;

    if (this.model) {
      this.model.accrualStartDate = value ? this._accrualStartDate : null;
    }
  }

  accrualEndDateCheckboxChanged(value: boolean): void {
    this.accrualEndDateEnabled = value;

    if (this.model) {
      this.model.accrualEndDate = value ? this._accrualEndDate : null;
    }
  }

  firstCouponPaymentDateCheckboxChanged(value: boolean): void {
    this.firstCouponPaymentDateEnabled = value;

    if (this.model) {
      this.model.firstCouponPaymentDate = value ? this.firstCouponPaymentDate : null;
    }
  }

  penultimateCouponPaymentDateCheckboxChanged(value: boolean): void {
    this.penultimateCouponPaymentDateEnabled = value;

    if (this.model) {
      this.model.penultimateCouponPaymentDate = value ? this.penultimateCouponPaymentDate : null;
    }
  }

  couponChangedDateCheckboxChanged(value: boolean): void {
    this.couponChangedDateEnabled = value;

    if (this.model) {
      this.model.typeChangeDate = value ? this._couponChangedDate : null;
    }
  }

  guaranteedStartCheckBoxChange(value: boolean): void {
    this.guaranteedStartDateEnabled = value;

    if (this.model) {
      this.model.guaranteedStartDate = value ? this._guaranteedStartDate : null;
    }
  }

  guaranteedEndCheckBoxChange(value: boolean): void {
    this.guaranteedEndDateEnabled = value;

    if (this.model) {
      this.model.guaranteedStartEndDate = value ? this._guaranteedEndDate : null;
    }
  }
}
