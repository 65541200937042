 
 <lv-flex-box class="lv-equity-container">
  <kendo-tabstrip class="lv-equity-content"
    [keepTabContent]="true">

    <kendo-tabstrip-tab
      selected="true"
      title="Terms"
      cssClass="DM-4871">
      <ng-template kendoTabContent>
        <lv-equity-terms #equityTerms
          data-cy="DM-4871"
          (didSave)="equityTermsSave($event)"
          (didCCYCodeChanged)="ccyCodeChange($event)"
          (didInstrumentNameSet)="setEquityName($event)"
          (didSyncAllEquityDataTrue)="syncAllEquityDataChanged($event)">
        </lv-equity-terms>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      title="Volatility"
      cssClass="DM-4872"
      [disabled]="isDisabled">
      <ng-template kendoTabContent>
        <div class="lv-equity-market-data-container">
          <lv-equity-market-data-volatility
          [ccyCodeChanged]="isCcyCodeChanged"
          data-cy="DM-4872">
        </lv-equity-market-data-volatility>
        </div>
      </ng-template>
    </kendo-tabstrip-tab> 
    <kendo-tabstrip-tab
      title="Borrow"
      cssClass="DM-4873"
      [disabled]="isDisabled">
      <ng-template kendoTabContent>
        <div class="lv-equity-market-data-container">
          <lv-equity-market-data-borrow
            data-cy="DM-4873">
        </lv-equity-market-data-borrow>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      title="Dividends"
      cssClass="DM-4874"
      [disabled]="isDisabled">
      <ng-template kendoTabContent>
        <div class="lv-equity-market-data-container">
          <lv-equity-market-data-dividends
            data-cy="DM-4874">
        </lv-equity-market-data-dividends>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab 
      title="Identifiers"
      cssClass="DM-4875">
      <ng-template kendoTabContent>
        <lv-equity-identifiers
          [leversysId]="leversysLocalId"
          [syncAllEquity]="syncAllEquityDataSettingsValue"
          data-cy="DM-4875">
        </lv-equity-identifiers>
      </ng-template>
    </kendo-tabstrip-tab> 
  </kendo-tabstrip>
  <div class="lv-equity-cog">
    <i class="k-icon k-i-gear"
      (click)="onShowLeversysDataSyncSettings()">
    </i>
  </div>
</lv-flex-box>

<lv-side-panel
  class="lv-equity-side-panel"
  [width]="250"
  position="right"
  [floating]="true"
  #leversysDataSyncSettings
  (click)="editLeversysDataSyncSettings()">

  <lv-flex-box
    class="lv-sync-all-equity-data"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      lvId="lv-edit-equity-data-sync"
      kendoTooltip
      [tooltipTemplate]="syncAllEquityDataTooltipTemplate"
      filter="span">
      <span>Edit Leversys Data Sync Settings</span>
    </div>
  </lv-flex-box>
</lv-side-panel>

<ng-template #syncAllEquityDataTooltipTemplate>
  <lv-tooltip dm="dM-4794"></lv-tooltip>
</ng-template>
