/**
 * Credit source descriptions.
 */
export enum CreditSourceDescription {
  FlatSpread = 'Flat Spread',
  TermStructure = 'Term Structure',
  CDSFlat = 'CDS Flat',
  CDSTermStructure = 'CDS Term Structure',
  DefaultRateFlat = 'Default Rate Flat',
  DefaultRateTermStructure = 'Default Rate Term Structure',
  SurvivalProbabilityTermStructure = 'Survival Probability Term Structure',
}

/**
 * Credit sources.
 */
export enum CreditSource {
  FlatSpread = 'FlatSpread',
  TermStructure = 'TermStructure',
  CDSFlat = 'CDSFlat',
  CDSTermStructure = 'CDSTermStructure',
  DefaultRateFlat = 'DefaultRateFlat',
  DefaultRateTermStructure = 'DefaultRateTermStructure',
  SurvivalProbabilityTermStructure = 'SurvivalProbabilityTermStructure',
}
